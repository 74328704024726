import { createTheme } from '@mui/material/styles';
import { theme } from '../../../../shared/theme';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        xxl: true;
    }
}

export const BeddingCustomTheme = createTheme({
    ...theme,
    breakpoints: {
        values: {
            xs: 0,
            sm: 520,
            md: 770,
            lg: 900,
            xl: 1200,
            xxl: 1536,
        },
    },
});

import { Grid, Typography } from '@mui/material';
import React from 'react';

import { Link } from 'react-router-dom';

export default function TermsOfService() {
    return (
        <Grid sx={{ marginTop: '30px' }}>
            <Grid sx={{ paddingLeft: '0px' }}>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Effective starting: September 20, 2023
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        These terms are effective as of the date you register an
                        account with us or use or access a cloud product owned
                        by 7DAY. These Terms do not have to be signed in order
                        to be binding. You indicate your assent to these Terms
                        by clicking “I agree” (or similar button or checkbox) at
                        the time you register for a Cloud Product, create a
                        Cloud Product account, or place an Order. For No-Charge
                        Products, you also indicate your assent to these Terms
                        by accessing or using the applicable No-Charge Product.
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        End user consent and responsibility
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        Our Cloud Products have various user onboarding flows.
                        Some Cloud Products require users to be designated by
                        Administrators; some allow users to sign up for
                        individual accounts which can become associated with
                        teams or organizations at a later time; and some may
                        allow users to invite other users. You are responsible
                        for understanding the settings and controls for each
                        Cloud Product you use and for controlling whom you allow
                        to become an End User. If payment is required for End
                        Users to use or access a Cloud Product, then we are only
                        required to provide the Cloud Products to those End
                        Users for whom you have paid the applicable fees, and
                        only such End Users are permitted to access and use the
                        Cloud Products.
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        Credentials
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        You must require that all End Users keep their user IDs
                        and passwords for the Cloud Products strictly
                        confidential and do not share such information with any
                        unauthorized person. User IDs are granted to individual,
                        named persons and may not be shared. You are responsible
                        for any and all actions taken using End User Accounts
                        and passwords, and you agree to immediately notify us of
                        any unauthorized use of which you become aware.
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        Age requirements for end users
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        The Cloud Products are not intended for, and should not
                        be used by, anyone under the age of 16. You are
                        responsible for ensuring that all End Users are at least
                        16 years old.
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        Access to cloud products
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        Subject to these Terms and during the applicable
                        Subscription Term, you may access and use the Cloud
                        Products for your own business purposes or personal use,
                        as applicable, all in accordance with these Terms, the
                        applicable Order and the Documentation.
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        Support
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        During the Subscription Term, we will provide Support
                        for the Cloud Products if your account is registered
                        with 7DAY and enabled by your organisation to access the
                        application.
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        Restrictions
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        Except as otherwise expressly permitted in these Terms,
                        you will not: (a) reproduce, modify, adapt or create
                        derivative works of the Cloud Products; (b) rent, lease,
                        distribute, sell, sublicense, transfer or provide access
                        to the Cloud Products to a third party; (c) use the
                        Cloud Products for the benefit of any third party; (d)
                        incorporate any Cloud Products into a product or service
                        you provide to a third party; (e) interfere with or
                        otherwise circumvent mechanisms in the Cloud Products
                        intended to limit your use; (f) reverse engineer,
                        disassemble, decompile, translate or otherwise seek to
                        obtain or derive the source code, underlying ideas,
                        algorithms, file formats or non-public APIs to any Cloud
                        Products, except to the extent expressly permitted by
                        applicable law (and then only upon advance notice to us
                        (7DAY)); (g) remove or obscure any proprietary or other
                        notices contained in any Cloud Product; (h) use the
                        Cloud Products for competitive analysis or to build
                        competitive products; or (i) encourage or assist any
                        third party to do any of the foregoing.
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        Privacy
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        We collect certain data and information about you and
                        your End Users in connection with your and your End
                        Users’ use of the Cloud Products and otherwise in
                        connection with these Terms. We collect and use all such
                        data and information in accordance with our Privacy
                        Policy, which you acknowledge.
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        Subpoenas
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        Nothing in these Terms prevents us from disclosing Your
                        Data to the extent required by law, subpoenas or court
                        orders, but we will use commercially reasonable efforts
                        to notify you where permitted to do so. 7DAY strives to
                        balance your privacy rights with other legal
                        requirements.
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        Termination
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        You may choose to stop using the Cloud Products and
                        terminate these Terms (including all Orders/Reservations) at
                        any time for any reason upon written notice to us. Upon
                        any such termination (i) you will not be entitled to a
                        refund of any pre-paid fees and (ii) if you have not
                        already paid all applicable fees for the then-current
                        Subscription Term or related services period (as
                        applicable), any such fees that are outstanding will
                        become immediately due and payable.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        Upon any expiration or termination of these Terms, you
                        must cease using all Cloud Products and delete (or at
                        our request, return) all Confidential Information or
                        other materials of ours in your possession, including on
                        any third-party systems operated on your behalf. You
                        will certify such deletion upon our request. You will
                        not have access to Your Data (and we may delete all of
                        Your Data unless legally prohibited) after expiration or
                        termination of these Terms (or its applicable
                        Subscription Term), so you should make sure to export
                        Your Data using the functionality of the Cloud Products
                        during the applicable Subscription Term. In no event
                        will termination relieve you of your obligation to pay
                        any fees payable to us for the period prior to the
                        effective date of termination. Except where an exclusive
                        remedy may be specified in these Terms, the exercise by
                        either party of any remedy, including termination, will
                        be without prejudice to any other remedies it may have
                        under these Terms, by law or otherwise.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

import { Box, CircularProgress, Grid, Skeleton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';

const theme = createTheme({
    palette: {
        // @ts-ignore
        neutral: {
            main: '#fff',
            contrastText: '#5cb5b3',
        },
        green: {
            main: '#5cb5b3',
            contrastText: '#fff',
        },
        other: {
            main: '#5cb5b3',
            contrastText: '#5cb5b3',
        },
        roundButton: {
            main: '#addad9',
            contrastText: '#5cb5b3',
        },
    },
});

export default function ReportLoadingComponent() {
    return (
        <>
            {' '}
            {Array.from(new Array(10)).map((item, index) => (
                <Grid
                    item
                    xs={60}
                    sm={30}
                    md={20}
                    lg={15}
                    xl={12}
                    sx={{
                        textDecoration: 'none',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <Box
                        key={index}
                        sx={{ width: '100%', marginRight: 0.5, my: 5 }}
                    >
                        <Box sx={{ pt: 0.5 }}>
                            {Array.from({ length: 20 }, (_, index) => (
                                <Skeleton key={index} />
                            ))}
                        </Box>
                    </Box>
                </Grid>
            ))}
        </>
    );
}

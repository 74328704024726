import { z } from 'zod';
import { validatePhone } from '../../../../shared/utils';
import { PricingSchema } from './Pricing/schema';

const ExperienceCharge = z
    .object({
        feeId: z.string().or(z.number()).optional(),
        type: z.enum(
            [
                'fixed',
                'perHour',
                'perDay',
                'perNight',
                'perHourPerGuest',
                'perDayPerGuest',
                'perNightPerGuest',
                'perDeskPerDay',
                'perOfficePerDay',
                'perMeetingRoomPerDay',
                'entireVenuePerDay',
                'entireVenuePerNight',
            ],
            { message: 'Required' }
        ),
        unitPrice: z.coerce
            .number()
            .min(1, { message: 'At least A$1.00 required' })
            .multipleOf(0.01, { message: 'Invalid currency format' }),
        chargeType: z.enum(['fee', 'price']),
        weekendPrice: z
            .object({
                days: z
                    .array(z.enum(['friday', 'saturday', 'sunday']))
                    .min(1, { message: 'Required' }),
                unitPrice: z.coerce
                    .number()
                    .multipleOf(0.01, { message: 'Invalid currency format.' })
                    .min(1, { message: 'At least A$1.00 required' }),
            })
            .optional()
            .nullable(),
    })
    .superRefine((data, ctx) => {
        if (data.chargeType === 'fee' && !data.feeId) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Required',
                path: ['feeId'],
            });
        }
    });

const BaseSpace = z.object({
    spaceType: z.enum([
        'bedroom',
        'gameRoom',
        'bathroom',
        'boardroom',
        'diningArea',
        'kitchen',
        'livingRoom',
    ]),
});

const GameRoomSchema = z.object({
    spaceType: z.literal('gameRoom'),
    gameConsole: z.boolean().default(false),
    poolTable: z.boolean().default(false),
    soundSystem: z.boolean().default(false),
    tv: z.boolean().default(false),
});

const GarageSchema = z.object({
    spaceType: z.literal('garage'),
    parkingCapacity: z.number().default(0),
    evChargingStation: z.boolean().default(false),
    securitySystem: z.boolean().default(false),
    automaticDoor: z.boolean().default(false),
    dehumidifier: z.boolean().default(false),
    carwashStation: z.boolean().default(false),
    bicycleRack: z.boolean().default(false),
});

const BedroomSchema = z.object({
    spaceType: z.literal('bedroom'),
    blackOutCurtains: z.boolean().default(false),
    wardrobe: z.boolean().default(false),
    extraPillowsAndBlankets: z.boolean().default(false),
    iron: z.boolean().default(false),
    safe: z.boolean().default(false),
    tv: z.boolean().default(false),
    ensuite: z.boolean().default(false),
    singleBed: z.number().default(0),
    doubleBed: z.number().default(0),
    queenBed: z.number().default(0),
    kingBed: z.number().default(0),
    bunkBed: z.number().default(0),
    sofaBed: z.number().default(0),
    couch: z.number().default(0),
    babyCot: z.number().default(0),
    desk: z.number().default(0),
});

const BathroomSchema = z.object({
    spaceType: z.literal('bathroom'),
    bathtub: z.boolean().default(false),
    babyBath: z.boolean().default(false),
    hairdryer: z.boolean().default(false),
    heating: z.boolean().default(false),
    bidet: z.boolean().default(false),
    toiletries: z.boolean().default(false),
});

const BoardroomSchema = z.object({
    spaceType: z.literal('boardroom'),
    seatingCapacity: z.number().default(0),
    projector: z.boolean().default(false),
    whiteboard: z.boolean().default(false),
    soundSystem: z.boolean().default(false),
    tv: z.boolean().default(false),
});

const DiningAreaSchema = z.object({
    spaceType: z.literal('diningArea'),
    seatingCapacity: z.number().default(0),
    tv: z.boolean().default(false),
    soundSystem: z.boolean().default(false),
});

const KitchenSchema = z.object({
    spaceType: z.literal('kitchen'),
    fridge: z.boolean().default(false),
    oven: z.boolean().default(false),
    microwave: z.boolean().default(false),
    dishwasher: z.boolean().default(false),
    coffeeMachine: z.boolean().default(false),
    toaster: z.boolean().default(false),
    kettle: z.boolean().default(false),
    cookware: z.boolean().default(false),
    cutlery: z.boolean().default(false),
});

const LivingRoomSchema = z.object({
    spaceType: z.literal('livingRoom'),
    singleBed: z.number().default(0),
    doubleBed: z.number().default(0),
    queenBed: z.number().default(0),
    kingBed: z.number().default(0),
    bunkBed: z.number().default(0),
    sofaBed: z.number().default(0),
    couch: z.number().default(0),
    babyCot: z.number().default(0),
    wardrobe: z.boolean().default(false),
    extraPillowsAndBlankets: z.boolean().default(false),
    iron: z.boolean().default(false),
    safe: z.boolean().default(false),
    tv: z.boolean().default(false),
    fireplace: z.boolean().default(false),
    poolTable: z.boolean().default(false),
});

export const SpaceSchema = z.discriminatedUnion('spaceType', [
    GameRoomSchema,
    GarageSchema,
    BedroomSchema,
    BathroomSchema,
    BoardroomSchema,
    DiningAreaSchema,
    KitchenSchema,
    LivingRoomSchema,
]);

export const SpaceSchemaArray = z.object({
    spaces: z.array(SpaceSchema),
});

export const BasePriceSchema = z.object({
    prices: z.array(ExperienceCharge),
});

export const FeesSchema = z.object({
    fees: z.array(ExperienceCharge),
});

const PropertyDetails = z.object({
    subType: z.enum(['apartment', 'cabin', 'duplex', 'house', 'unit'], {
        message: 'Required',
    }),
    bedrooms: z.number().min(1, { message: 'At least 1 bedroom required' }),
    beds: z.number().min(1, { message: 'At least 1 bed required' }),
    baths: z.number().min(1, { message: 'At least 1 bathroom required' }),
    maxGuests: z.number().min(1, { message: 'At least 1 guest required ' }),
    overnightGuests: z.number(),
    wifiDetails: z.string().optional(),
    catering: z.boolean().default(false),
    alcohol: z.boolean().default(false),
    wifi: z.boolean().default(false),
    boardroom: z.boolean().default(false),
    tv: z.boolean().default(false),
    kitchen: z.boolean().default(false),
    parking: z.boolean().default(false),
    aircon: z.boolean().default(false),
    dedicatedWorkspace: z.boolean().default(false),
    pool: z.boolean().default(false),
    bbq: z.boolean().default(false),
    firepit: z.boolean().default(false),
    washingMachine: z.boolean().default(false),
    gamesRoom: z.boolean().default(false),
    spa: z.boolean().default(false),
    wineCellar: z.boolean().default(false),
    massages: z.boolean().default(false),
});

const CommercialDetails = z.object({
    subType: z.enum(['house', 'office'], { message: 'Required' }),
    desks: z.coerce.number(),
    privateOffices: z.coerce.number(),
    meetingRooms: z.coerce.number(),
    wifiDetails: z.string().optional(),
    wifi: z.boolean().default(false),
    boardroom: z.boolean().default(false),
    tv: z.boolean().default(false),
    kitchen: z.boolean().default(false),
    parking: z.boolean().default(false),
    aircon: z.boolean().default(false),
    dedicatedWorkspace: z.boolean().default(false),
    balcony: z.boolean().default(false),
    bbq: z.boolean().default(false),
    gym: z.boolean().default(false),
    diningOptions: z.boolean().default(false),
    petFriendly: z.boolean().default(false),
    showerRoom: z.boolean().default(false),
    bikeLock: z.boolean().default(false),
    personalStorage: z.boolean().default(false),
    childcare: z.boolean().default(false),
});

const VehicleDetails = z
    .object({
        subType: z.enum(['car', 'motorbike'], { message: 'Required' }),
        make: z.string().min(1, { message: 'Required' }),
        model: z.string().min(1, { message: 'Required' }),
        year: z
            .string()
            .optional()
            .or(z.literal(null))
            .transform((val) => (val ? val : null)),
        transmission: z.enum(['manual', 'automatic', 'cvt', 'electric'], {
            message: 'Required',
        }),
        heatedSeats: z.boolean().default(false),
        appleCarPlay: z.boolean().default(false),
        androidAuto: z.boolean().default(false),
        fuelType: z.enum(
            ['petrol', 'diesel', 'electric', 'hybrid', 'cng', 'lpg'],
            {
                message: 'Required',
            }
        ),
        rearVisionCamera: z.boolean().default(false),
        cruiseControl: z.boolean().default(false),
        touchscreenDisplay: z.boolean().default(false),
        bluetooth: z.boolean().default(false),
        blindSpotAlert: z.boolean().default(false),
        navigation: z.boolean().default(false),
        tyrePressureSensor: z.boolean().default(false),
        airbags: z.boolean().nullable().default(false),
        bodyType: z
            .enum(
                [
                    'bus',
                    'cabChassis',
                    'convertible',
                    'coupe',
                    'fastback',
                    'hatchback',
                    'lightTruck',
                    'limousine',
                    'minivan',
                    'sedan',
                    'sportsCar',
                    'stationWagon',
                    'suv',
                    'ute',
                    'van',
                ],
                { message: 'Required' }
            )
            .or(z.literal('')),
        numberOfDoors: z.coerce.number(),
        numberOfSeats: z.coerce.number(),
        ac: z.boolean().nullable().default(false),
        keylessEntry: z.boolean().nullable().default(false),
        sunroof: z.boolean().nullable().default(false),
        laneAssist: z.boolean().nullable().default(false),
        autoPark: z.boolean().nullable().default(false),
        powerSeats: z.boolean().nullable().default(false),
        remoteStart: z.boolean().nullable().default(false),
        wirelessPhoneCharger: z.boolean().nullable().default(false),
        rearEntertainmentSystem: z.boolean().nullable().default(false),
        engineCC: z.coerce.number(),
        adjustableSeats: z.boolean().nullable().default(false),
        helmet: z.boolean().nullable().default(false),
        gloves: z.boolean().nullable().default(false),
        dropoffLocationSameAsPickup: z.boolean().default(false),
        dropoffStreetAddress1: z.string().min(1, { message: 'Required' }),
        dropoffStreetAddress2: z.string().min(1, { message: 'Required' }),
        dropoffSuburb: z.string().min(1, { message: 'Required' }),
        dropoffState: z.enum(
            ['NSW', 'QLD', 'TAS', 'VIC', 'ACT', 'WA', 'SA', 'NT'],
            { message: 'Required' }
        ),
        dropoffCountryCode: z.enum(['AU'], { message: 'Required' }),
        dropoffPostcode: z
            .string()
            .min(1, { message: 'Required' })
            .max(4, { message: 'Invalid postcode' }),
        dropoffPhone: z
            .string()
            .refine(validatePhone, { message: 'Invalid phone number' }),
        dropoffUsesNirovision: z.boolean({ message: 'Required' }),
        minimumLicenceRequired: z.enum(['full', 'p2', 'p1'], {
            message: 'Required',
        }),
        minimumDriversAge: z.number().optional().nullable(),
        petsAllowed: z.boolean().default(false),
        smokingAllowed: z.boolean().default(false),
        rules: z.string(),
    })
    .superRefine((val, ctx) => {
        if (val.subType === 'car' && !val.bodyType) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Required',
                path: ['bodyType'],
            });
        }

        if (val.subType === 'car' && val.numberOfDoors === 0) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Required',
                path: ['numberOfDoors'],
            });
        }

        if (val.subType === 'car' && val.numberOfSeats === 0) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Required',
                path: ['numberOfSeats'],
            });
        }

        if (val.subType === 'motorbike' && val.engineCC === 0) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Required',
                path: ['engineCC'],
            });
        }
    });

const ActivityDetails = z.object({
    subType: z.enum(['buggies', 'eBikes', 'goKarts'], { message: 'Required' }),
    quantity: z.number(),
});

const BoatDetails = z.object({
    subType: z.enum(
        [
            'bass',
            'cabin cruiser',
            'catamaran',
            'dingy',
            'jetboat',
            'inflatable',
            'sailboat',
            'skiff',
            'superyacht',
            'yacht',
        ],
        { message: 'Required' }
    ),
    bedrooms: z.number().min(1, { message: 'At least 1 bedroom required' }),
    beds: z.number().min(1, { message: 'At least 1 bed required' }),
    baths: z.number().min(1, { message: 'At least 1 bathroom required' }),
    maxGuests: z.number().min(1, { message: 'At least 1 guest required ' }),
    overnightGuests: z.number(),
    wifiDetails: z.string().optional(),
    catering: z.boolean().default(false),
    alcohol: z.boolean().default(false),
    wifi: z.boolean().default(false),
    boardroom: z.boolean().default(false),
    tv: z.boolean().default(false),
    kitchen: z.boolean().default(false),
    aircon: z.boolean().default(false),
    bbq: z.boolean().default(false),
    spa: z.boolean().default(false),
    waterSports: z.boolean().default(false),
});

const BaseSchema = z.object({
    type: z.literal('').refine((val) => val, {
        message: 'Required',
    }),
    visitType: z.enum(['personal', 'business'], { message: 'Required' }),
    name: z
        .string()
        .min(1, { message: 'Required' })
        .max(25, { message: 'Character limit reached' }),
    description: z
        .string()
        .min(1, { message: 'Required' })
        .max(500, { message: 'Character limit reached' }),
    isAvailable: z.boolean({ message: 'Required' }),
    notificationsEmailType: z.enum(['default', 'custom']),
    notificationsEmail: z.string().email().or(z.literal('')),
    // address
    streetAddress1: z.string().min(1, { message: 'Required' }),
    streetAddress2: z.string().min(1, { message: 'Required' }),
    suburb: z.string().min(1, { message: 'Required' }),
    state: z.enum(['NSW', 'QLD', 'TAS', 'VIC', 'ACT', 'WA', 'SA', 'NT'], {
        message: 'Required',
    }),
    countryCode: z.enum(['AU'], { message: 'Required' }),
    postcode: z
        .string()
        .min(1, { message: 'Required' })
        .max(4, { message: 'Invalid postcode' }),
    phone: z
        .string()
        .refine(validatePhone, { message: 'Invalid phone number' }),
    usesNirovision: z.boolean({ message: 'Required' }),
    // hiring details
    minHireHours: z.number(),
    maxHireHours: z.number(),
    bufferHours: z.coerce
        .number({ message: 'Required' })
        .min(0, { message: 'Cleaning buffer cannot be negative' }),

    /** ==================== **/
    checkinTime: z.string().min(1, { message: 'Required' }),
    checkoutTime: z.string().min(1, { message: 'Required' }),
    checkinDetails: z.string().optional(),

    /** ==================== */

    monday: z.boolean(),
    tuesday: z.boolean(),
    wednesday: z.boolean(),
    thursday: z.boolean(),
    friday: z.boolean(),
    saturday: z.boolean(),
    sunday: z.boolean(),
    publicHolidays: z.boolean(),
    // TODO: Make sure to reverse this.
    instantBook: z.boolean(),
    // photos
    photos: z.array(
        z.object({
            id: z.string(),
            src: z.string(),
        })
    ),
    linkedModules: z
        .array(z.number())
        .transform((arr) => arr.map((value) => ({ id: value }))),
    // policies
    policies: z.array(
        z.object({
            policyId: z.number(),
            requiresConsent: z.boolean(),
        })
    ),
    hasPolicies: z.boolean(),
    charges: z.array(ExperienceCharge.or(PricingSchema)),
    spaces: z.array(SpaceSchema),
    discounts: z.array(
        z.object({
            id: z.number().optional(),
            percentage: z.number(),
            name: z.string(),
            module: z
                .object({
                    id: z.number(),
                })
                .optional(),
            dates: z.array(
                z.object({
                    startDate: z.string().date(),
                    endDate: z.string().date(),
                })
            ),
            disabled: z.boolean().optional(),
            expired: z.boolean().optional(),
        })
    ),
});

const PropertyExperience = BaseSchema.extend({
    type: z.literal('property'),
    details: PropertyDetails,
});

const VehicleExperience = BaseSchema.extend({
    type: z.literal('vehicle'),
    details: VehicleDetails,
});

const CommercialExperience = BaseSchema.extend({
    type: z.literal('commercial'),
    details: CommercialDetails,
});

const ActivityExperience = BaseSchema.extend({
    type: z.literal('activity'),
    details: ActivityDetails,
});

const BoatExperience = BaseSchema.extend({
    type: z.literal('boat'),
    details: BoatDetails,
});

export const ExperienceSchema = z
    .discriminatedUnion(
        'type',
        [
            BaseSchema,
            PropertyExperience,
            VehicleExperience,
            CommercialExperience,
            ActivityExperience,
            BoatExperience,
        ],
        { message: 'Required' }
    )
    .superRefine((val, ctx) => {
        if (val.minHireHours > val.maxHireHours) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Min Hire Period must be less than Max Hire Period',
                path: ['minHireHours'],
            });
        }

        if (val.maxHireHours < val.minHireHours) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Max Hire Period must be greater than Min Hire Period',
                path: ['maxHireHours'],
            });
        }

        if (
            !val.monday &&
            !val.tuesday &&
            !val.wednesday &&
            !val.thursday &&
            !val.friday &&
            !val.saturday &&
            !val.sunday
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Required',
                path: ['monday'],
            });
        }

        if (val.isAvailable && val.photos.length < 5) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'At least 5 photos required',
                path: ['photos'],
            });
        }

        if (
            val.notificationsEmailType === 'custom' &&
            !val.notificationsEmail
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Required',
                path: ['notificationsEmail'],
            });
        }
    });

import { z } from 'zod';

export const PricingSchema = z.object({
    chargeType: z.literal('flexiblePrice'),
    nightPrice: z.coerce
        .number()
        .multipleOf(0.01, { message: 'Invalid currency format.' })
        .min(1, { message: 'At least A$1.00 required' }),
    nightCheckin: z.string(),
    nightCheckout: z.string(),
    dayPrice: z.coerce
        .number()
        .multipleOf(0.01, { message: 'Invalid currency format.' })
        .min(1, { message: 'At least A$1.00 required' }),
    dayCheckin: z.string().min(1, { message: 'Required ' }),
    dayCheckout: z.string().min(1, { message: 'Required ' }),
    hourPrice: z.coerce
        .number()
        .multipleOf(0.01, { message: 'Invalid currency format.' })
        .min(1, { message: 'At least A$1.00 required' }),
    weekendPrice: z
        .object({
            days: z
                .array(z.enum(['friday', 'saturday', 'sunday']))
                .min(1, { message: 'At least one day required' }),
            dayPrice: z.coerce
                .number()
                .multipleOf(0.01, { message: 'Invalid currency format.' })
                .min(1, { message: 'At least A$1.00 required' }),
            nightPrice: z.coerce
                .number()
                .multipleOf(0.01, { message: 'Invalid currency format.' })
                .min(1, { message: 'At least A$1.00 required' }),
            hourPrice: z.coerce
                .number()
                .multipleOf(0.01, { message: 'Invalid currency format.' })
                .min(1, { message: 'At least A$1.00 required' }),
        })
        .nullable(),
});

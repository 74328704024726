import {
    default as CloseIcon,
    default as CloseModalIcon,
} from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    List,
    ListItem,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useChangePasswordMutation } from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../../layout/styledcomponents/CustomIconButton';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import toast from '../../../shared/Toast';

export default function EditPasswordModal(props: any) {
    const { onClose, open } = props;
    const [changePassword] = useChangePasswordMutation();
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValidInput, setIsValidInput] = useState(false);
    const [lowerCaseValidation, setLowercaseValidation] = useState(false);
    const [upperCaseValidation, setUppercaseValidation] = useState(false);
    const [pwLengthValidation, setPWLengthValidation] = useState(false);
    const [numberValidation, setNumberValidation] = useState(false);
    const [characterValidation, setCharacterValidation] = useState(false);
    const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);

    const errors: any = [];

    if (oldPassword.length === 0) {
        errors.push('Old password cannot be empty');
    }

    if (newPassword.length === 0) {
        errors.push('New password cannot be empty');
    }

    if (confirmPassword.length === 0) {
        errors.push('Confirm password cannot be empty');
    }

    const handleOldPassword = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setOldPassword(event.target.value);
    };
    const handleNewPassword = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setNewPassword(event.target.value);
        if (validatePassword(event.target.value) === false) {
            setIsValidInput(true);
        } else {
            setIsValidInput(false);
        }
    };
    const handleConfirmPassword = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setConfirmPassword(event.target.value);
        if (newPassword === event.target.value) {
            setPasswordsDontMatch(false);
        } else {
            setPasswordsDontMatch(true);
        }
    };

    const validatePassword = (pw: any) => {
        if (/(?=.*[A-Z])/.test(pw) === true) {
            setUppercaseValidation(true);
        } else {
            setUppercaseValidation(false);
        }
        if (pw.length >= 8 === true) {
            setPWLengthValidation(true);
        } else {
            setPWLengthValidation(false);
        }
        if (/[a-z]/.test(pw) === true) {
            setLowercaseValidation(true);
        } else {
            setLowercaseValidation(false);
        }
        if (/[#?!@$%^&*-]/.test(pw) === true) {
            setCharacterValidation(true);
        } else {
            setCharacterValidation(false);
        }
        if (/[0-9]/.test(pw) === true) {
            setNumberValidation(true);
        } else {
            setNumberValidation(false);
        }

        return (
            /[A-Z]/.test(pw) &&
            pw.length >= 8 &&
            /[a-z]/.test(pw) &&
            /[0-9]/.test(pw) &&
            /[#?!@$%^&*-]/.test(pw)
        );
    };

    const handleClose = () => {
        onClose();
    };

    const onClickChangePassword = () => {
        //Reset password and then redirect to profile
        let requestBody = {
            currentPassword: oldPassword,
            newPassword: newPassword,
            confirmNewPassword: confirmPassword,
        };

        changePassword(requestBody).then((data: any) => {
            if (data) {
                if (data?.error?.data !== 'Password is changed.') {
                    toast.error(
                        `Failed to changed password, please check old password was correct`
                    );
                } else {
                    toast.success(`Password has been changed`);
                    navigate(`/profile`);
                    onClose();
                }
            } else {
                toast.error(
                    `Failed to changed password, please check old password was correct`
                );
            }
        });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid
                sx={{
                    // background: ' pink',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // background: 'yellow',
                        // textAlign: 'center',
                        height: '50px',
                        width: isMobile ? '320px' : '600px',

                        // alignContent: 'center',
                        // alignItems: 'center',
                        borderBottom: ' #f6f8f9 solid 1px',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            // background: 'red',
                            height: '100%',
                            width: isMobile ? '88%' : '90%',
                            // paddingLeft: '18px',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                textAlign: 'center',
                                paddingLeft: '18px',
                            }}
                            variant='h6'
                        >
                            Edit Password
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // backgroundColor: 'blue',
                            height: '100%',
                            width: '10%',
                        }}
                    >
                        <CustomIconButton
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#5cb5b3',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                            onClick={handleClose}
                        >
                            <CloseModalIcon />
                        </CustomIconButton>
                    </Grid>
                </Grid>
                <DialogContent
                    dividers
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: isMobile ? '420px' : 'auto',
                    }}
                >
                    <DialogContentText>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Grid sx={{ width: isMobile ? '25%' : '25%' }}>
                                {' '}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#000',
                                        paddingRight: '10px',
                                    }}
                                >
                                    Old:
                                </Typography>
                            </Grid>
                            <Grid sx={{ width: isMobile ? '75%' : '75%' }}>
                                <CustomTextField
                                    type='password'
                                    onChange={handleOldPassword}
                                    color={'neutral'}
                                    fullWidth
                                    sx={{ marginBottom: '10px' }}
                                    InputProps={{
                                        style: {
                                            height: '50px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {' '}
                            <Grid sx={{ width: isMobile ? '25%' : '25%' }}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#000',
                                        paddingRight: '10px',
                                        width: '100%',
                                    }}
                                >
                                    New:
                                </Typography>
                            </Grid>
                            <Grid sx={{ width: isMobile ? '75%' : '75%' }}>
                                <CustomTextField
                                    error={isValidInput}
                                    label={
                                        isValidInput === true
                                            ? 'Invalid Password'
                                            : ''
                                    }
                                    type='password'
                                    onChange={handleNewPassword}
                                    color={isValidInput ? 'green' : 'neutral'}
                                    fullWidth
                                    sx={{ marginBottom: '10px' }}
                                    InputProps={{
                                        style: {
                                            height: '50px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {' '}
                            <Grid sx={{ width: isMobile ? '25%' : '25%' }}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#000',
                                        paddingRight: '10px',
                                        width: '100%',
                                    }}
                                >
                                    Confirm:
                                </Typography>
                            </Grid>
                            <Grid sx={{ width: isMobile ? '75%' : '75%' }}>
                                <CustomTextField
                                    error={passwordsDontMatch}
                                    label={
                                        passwordsDontMatch === true
                                            ? 'Passwords do not match'
                                            : ''
                                    }
                                    type='password'
                                    onChange={handleConfirmPassword}
                                    color={
                                        passwordsDontMatch ? 'green' : 'neutral'
                                    }
                                    fullWidth
                                    sx={{ marginBottom: '10px' }}
                                    InputProps={{
                                        style: {
                                            height: '50px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {' '}
                            <Grid
                                sx={{ width: isMobile ? '25%' : '25%' }}
                            ></Grid>
                            <Grid sx={{ width: isMobile ? '75%' : '75%' }}>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        flexDirection:
                                            isMobile || isIpad
                                                ? 'column'
                                                : 'row',
                                        width: '100%',
                                        height:
                                            newPassword === '' && isMobile
                                                ? '0px'
                                                : isIpad
                                                ? '260px'
                                                : '200px',
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            width: '100%',
                                            height: 'auto',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '10px',
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                            }}
                                        >
                                            {' '}
                                            Password must:
                                            <List
                                                sx={{ padding: '0!important' }}
                                            >
                                                <ListItem>
                                                    {pwLengthValidation ===
                                                    true ? (
                                                        <DoneIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'green',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'red',
                                                            }}
                                                        />
                                                    )}
                                                    Be a minimum of 8 characters
                                                </ListItem>
                                                <ListItem>
                                                    {lowerCaseValidation ===
                                                    true ? (
                                                        <DoneIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'green',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'red',
                                                            }}
                                                        />
                                                    )}
                                                    Include at least one
                                                    lowercase letter (a-z)
                                                </ListItem>
                                                <ListItem>
                                                    {upperCaseValidation ===
                                                    true ? (
                                                        <DoneIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'green',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'red',
                                                            }}
                                                        />
                                                    )}
                                                    Include at least one
                                                    uppercase letter (A-Z)
                                                </ListItem>
                                                <ListItem>
                                                    {numberValidation ===
                                                    true ? (
                                                        <DoneIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'green',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'red',
                                                            }}
                                                        />
                                                    )}
                                                    Include at least one number
                                                    (0-9)
                                                </ListItem>
                                                <ListItem>
                                                    {characterValidation ===
                                                    true ? (
                                                        <DoneIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'green',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseIcon
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                paddingRight:
                                                                    '5px',
                                                                color: 'red',
                                                            }}
                                                        />
                                                    )}
                                                    Include at least one special
                                                    character (!@#?&)
                                                </ListItem>
                                            </List>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>{' '}
                <DialogActions
                    sx={{
                        borderTop: ' #d8d8d8 solid 1px',
                        // minHeight: '60px',
                        background: ' #eeeeee',
                    }}
                >
                    <Grid>
                        {' '}
                        <CustomButton
                            color='green'
                            // autoFocus
                            onClick={onClickChangePassword}
                            disabled={
                                passwordsDontMatch ||
                                isValidInput ||
                                errors.length > 0
                            }
                        >
                            Update
                        </CustomButton>
                    </Grid>
                </DialogActions>
            </Grid>
        </Dialog>
    );
}

import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    Grid,
    List,
    ListItem,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React from 'react';
import { CustomButton } from '../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../layout/styledcomponents/CustomIconButton';
import { CustomTextField } from '../../layout/styledcomponents/CustomTextField';
import toast from './Toast';

const { REACT_APP_BASE_URL } = process.env;

export default function Modal(props: any) {
    const { onClose, open, onChange, message, component, details } = props;
    const isMobile = useMediaQuery('(max-width: 770px)');
    const urlToCopy = `${window.location.href.split('/')[0]}//${
        window.location.href.split('/')[2]
    }/viewExperiences/${details?.type}/${details?.id}`;

    const calendarURL = `${REACT_APP_BASE_URL}/calendar/${details?.calendarId}.ics`;

    async function copyTextToClipboard(urlRef: any) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(urlRef);
        } else {
            return document.execCommand('copy', true, urlRef);
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard(urlToCopy)
            .then(() => {
                toast.success(`COPIED`);
            })
            .catch((err) => {
                console.error('Failed to copy text:', err);
                toast.error(`Failed to copy. Please try again.`);
            });
    };
    const handleCalendarCopyClick = () => {
        copyTextToClipboard(calendarURL)
            .then(() => {
                toast.success(`COPIED`);
            })
            .catch((err) => {
                console.error('Failed to copy text:', err);
                toast.error(`Failed to copy. Please try again.`);
            });
    };

    const handleClose = () => {
        onClose();
    };

    const handleCloseChangeModal = () => {
        onChange();
    };

    const confirmDelete = () => {
        handleCloseChangeModal();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid
                sx={{
                    width: '100%',
                    height:
                        isMobile && component === 'Calendar' ? '500px' : '100%',
                }}
            >
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // background: 'yellow',
                        textAlign: 'left',
                        height: '50px',
                        width: isMobile ? '97%' : '600px',
                        alignContent: 'center',
                        alignItems: 'center',
                        borderBottom: ' #f6f8f9 solid 1px',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            // background: 'red',
                            height: '100%',
                            width: '90%',
                            paddingLeft: '18px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize:
                                    isMobile && component === 'Calendar'
                                        ? '14px'
                                        : '',
                            }}
                            variant='h6'
                        >
                            {message}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // backgroundColor: 'blue',
                            height: '100%',
                            width: '10%',
                        }}
                    >
                        <CustomIconButton
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#5cb5b3',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </CustomIconButton>
                    </Grid>
                </Grid>
                <DialogContent
                    dividers
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height:
                            component === 'Calendar'
                                ? isMobile
                                    ? '100%'
                                    : '300px'
                                : '200px',

                        // backgroundColor: 'yellow'
                    }}
                >
                    <DialogContentText
                        sx={{
                            display: 'flex',
                            width: '100%',
                            height: component === 'Calendar' ? '100%' : 'auto',
                            flexDirection: isMobile ? 'column' : 'row',
                        }}
                    >
                        {details?.isAvailable === false &&
                        component === 'Share' ? (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: isMobile ? '100%' : '30%',
                                    textAlign: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        // paddingRight: '10px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {' '}
                                    You cannot share a listing that is
                                    unavailable.
                                </Typography>{' '}
                            </Grid>
                        ) : (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                }}
                            >
                                {component === 'Calendar' ? (
                                    <Grid>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                            }}
                                        >
                                            The following link can be used to
                                            add the reservations of this
                                            experience to an external calendar.
                                            Each experience will have its own
                                            unique link that will need to be
                                            added to the external calendar in
                                            order to sync the reservations.
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                paddingTop: '15px',
                                            }}
                                        >
                                            Please Note: This calendar contains
                                            the approved and completed
                                            reservations of each experience and
                                            is refreshed at a frequency defined
                                            by the destination calendars
                                            (Google, Outlook,..). We do not
                                            allow the ability to import
                                            reservations from another calendar.
                                        </Typography>
                                    </Grid>
                                ) : component === 'Team change' ? (
                                    <List>
                                        {' '}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                            }}
                                        >
                                            <ListItem>
                                                This user is a 'Team Admin'.
                                            </ListItem>
                                            <ListItem>
                                                By changing this users 'Team',
                                                they will no longer be able to
                                                see and manage other users under
                                                that team.'
                                            </ListItem>{' '}
                                        </Typography>
                                    </List>
                                ) : null}
                                {component === 'Calendar' ? (
                                    <>
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                paddingTop: isMobile
                                                    ? ' 10px'
                                                    : '20px',

                                                width: isMobile
                                                    ? '100%'
                                                    : '100%',
                                                // backgroundColor: 'yellow',
                                            }}
                                        >
                                            {component === 'Calendar' ? (
                                                <CustomButton
                                                    // disableRipple
                                                    variant='outlined'
                                                    color='green'
                                                    sx={{
                                                        border: '#d8d8d8 solid 1px',
                                                        borderRadius: '5px',
                                                        width: '100%',
                                                        height: '39px',
                                                    }}
                                                    onClick={
                                                        handleCalendarCopyClick
                                                    }
                                                >
                                                    <ContentCopyIcon />
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            // paddingRight: '10px',
                                                        }}
                                                    >
                                                        Copy Link
                                                    </Typography>
                                                </CustomButton>
                                            ) : null}
                                        </Grid>
                                    </>
                                ) : component === 'Team change' ? null : (
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            flexDirection: isMobile
                                                ? 'column'
                                                : 'row',
                                        }}
                                    >
                                        <Grid
                                            sx={{
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                width: isMobile
                                                    ? '100%'
                                                    : '30%',
                                            }}
                                        >
                                            <CustomButton
                                                // disableRipple
                                                variant='outlined'
                                                color='green'
                                                sx={{
                                                    border: '#d8d8d8 solid 1px',
                                                    borderRadius: '5px',
                                                    width: '100%',
                                                    height: '39px',
                                                }}
                                                onClick={handleCopyClick}
                                            >
                                                <ContentCopyIcon />
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        // paddingRight: '10px',
                                                    }}
                                                >
                                                    {component === 'Share'
                                                        ? 'Copy Link'
                                                        : null}
                                                </Typography>
                                            </CustomButton>
                                        </Grid>
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                paddingTop: isMobile
                                                    ? ' 10px'
                                                    : '0px',
                                                paddingLeft: isMobile
                                                    ? '0px'
                                                    : '10px',
                                                width: isMobile
                                                    ? '100%'
                                                    : '70%',
                                                // backgroundColor: 'yellow',
                                            }}
                                        >
                                            {component === 'Share' ? (
                                                <CustomTextField
                                                    type='text'
                                                    fullWidth
                                                    value={urlToCopy}
                                                    readOnly
                                                    onClick={handleCopyClick}
                                                />
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </DialogContentText>
                </DialogContent>{' '}
            </Grid>
        </Dialog>
    );
}

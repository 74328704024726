import {
    Box,
    Container,
    Divider,
    Grid,
    styled,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
} from '@mui/material';
import UserRole from '../../../shared/AuthRoles';
import ReservationHero from '../../components/Reservations/ViewReservations/ReservationsHero';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCalendarViewReservationData } from '../../../app/Slices/CalendarViewSlice';
import ViewReservationListComponent from '../../components/Reservations/ViewReservations/ViewReservationListComponent';
import ViewReservationsCalendarComponent from '../../components/Reservations/ViewReservations/ViewReservationsCalendarComponent';
import SquareIcon from '@mui/icons-material/Square';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import {
    selectReservationFilters,
    setReservationFilters,
} from '../../../app/Slices/ReservationFiltersSlice';
import { useGetAllReservationsQuery } from '../../../app/Slices/SevenDayApiSlice';
import ReportLoadingComponent from '../../../layout/styledcomponents/ReportLoadingComponent';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
    return (
        <div hidden={value !== index}>
            {value === index && <Box pt={3}>{children}</Box>}
        </div>
    );
};

// Custom styles for the Tab component
const CustomTab = styled(Tab)(({ theme }) => ({
    // textTransform: 'none',
    minWidth: 0, // Remove default min-width to eliminate spacing
    // flex: 1, // Ensure tabs take equal width if needed
    paddingRight: theme.spacing(3), // Adjust padding as needed
    textAlign: 'left',
    justifyContent: 'left',
    // Responsive styling

    '@media (max-width: 770px)': {
        maxWidth: '25%',
    },
}));

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className='MuiTabs-indicatorSpan' />,
        }}
    />
))(({ theme }) => ({
    position: 'absolute',
    left: 55,
    transition: 'transform 0.3s ease',
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: 'transparent',
        paddingLeft: '16px',
        transition: 'transform 0.3s ease',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: '80%',
        width: '100%',
        backgroundColor: '#5cb5b3',
        transition: 'transform 0.3s ease',
    },

    // Responsive styling
    '@media (max-width: 1200px)': {
        left: 30,
    },
    '@media (max-width: 770px)': {
        left: 1,
    },
}));

export default function ReservationPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const calendarView: any = useSelector(selectCalendarViewReservationData);
    const [view, setView] = useState(false);
    const calendarViewSelected: any = useSelector(
        selectCalendarViewReservationData
    );
    const [tabValue, setTabValue] = useState<number>(0);
    const dispatch = useDispatch();
    const filters: any = useSelector(selectReservationFilters);
    const [status, setStatus] = useState(filters.status);
    const [startDate, setStartDate] = useState(filters.startDate);
    const [moduleType, setModuleType] = useState(filters.moduleType);
    const [visitType, setVisitType] = useState(filters.visitType);
    const [bookerType, setBookerType] = useState(filters.bookerType);
    const [guest, setGuest] = useState(filters.guest);

    const reservationFilters = useSelector(selectReservationFilters);
    const { data: reservations, isFetching }: any = useGetAllReservationsQuery({
        ...reservationFilters,
        sort: 'startDate',
    });

    useEffect(() => {
        setStartDate(filters.startDate);
        setModuleType(filters.moduleType);
        setVisitType(filters.visitType);
        setBookerType(filters.bookerType);
        setGuest(filters.guest);
    }, [filters]);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        let newStatus = [];
        if (newValue === 0) {
            newStatus = ['pending', 'approved'];
        } else if (newValue === 1) {
            newStatus = ['completed'];
        } else if (newValue === 2) {
            newStatus = ['cancelled', 'declined'];
        } else {
            newStatus = [
                'pending',
                'approved',
                'cancelled',
                'completed',
                'declined',
            ];
        }

        dispatch(
            setReservationFilters({
                startDate,
                moduleType,
                status: newStatus,
                guest,
                visitType,
                bookerType,
            })
        );
    };

    useEffect(() => {
        setView(calendarView.view);
    }, [calendarView.view]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        alignItems: 'left',
                    }}
                >
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        Reservations
                    </Typography>
                </Grid>

                <ReservationHero tabValue={tabValue} />

                <Grid
                    sx={{
                        width: '100%',
                        minHeight: 'auto',
                        display: 'flex',
                    }}
                >
                    {view === false ? (
                        <Grid sx={{ width: '100%' }}>
                            <StyledTabs
                                value={tabValue}
                                onChange={handleChange}
                            >
                                <CustomTab label='Upcoming' />
                                <CustomTab label='Completed' />
                                <CustomTab label='Cancelled' />
                                <CustomTab label='All' />
                            </StyledTabs>

                            <TabPanel value={tabValue} index={0}>
                                <Box
                                    sx={{
                                        height: isFetching ? 500 : 'auto',
                                        width: '100%',
                                        paddingTop: '24px',
                                    }}
                                >
                                    <Divider />
                                    {isFetching ? (
                                        <ReportLoadingComponent />
                                    ) : (
                                        <ViewReservationListComponent
                                            reservations={reservations}
                                        />
                                    )}
                                </Box>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <Box
                                    sx={{
                                        height: isFetching ? 500 : 'auto',
                                        width: '100%',
                                        paddingTop: '24px',
                                    }}
                                >
                                    <Divider />
                                    {isFetching ? (
                                        <ReportLoadingComponent />
                                    ) : (
                                        <ViewReservationListComponent
                                            reservations={reservations}
                                        />
                                    )}
                                </Box>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <Box
                                    sx={{
                                        height: isFetching ? 500 : 'auto',
                                        width: '100%',
                                        paddingTop: '24px',
                                    }}
                                >
                                    <Divider />
                                    {isFetching ? (
                                        <ReportLoadingComponent />
                                    ) : (
                                        <ViewReservationListComponent
                                            reservations={reservations}
                                        />
                                    )}
                                </Box>
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <Box
                                    sx={{
                                        height: isFetching ? 500 : 'auto',
                                        width: '100%',
                                        paddingTop: '24px',
                                    }}
                                >
                                    <Divider />
                                    {isFetching ? (
                                        <ReportLoadingComponent />
                                    ) : (
                                        <ViewReservationListComponent
                                            reservations={reservations}
                                        />
                                    )}
                                </Box>
                            </TabPanel>
                        </Grid>
                    ) : (
                        <ViewReservationsCalendarComponent />
                    )}
                </Grid>
                {calendarViewSelected?.view === true ? (
                    <Grid
                        sx={{
                            width: 'auto',
                            paddingTop: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            // alignItems: isMobile ? 'center' : 'left',
                        }}
                    >
                        <Grid>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    fontSize: '12px',
                                    paddingRight: '5px',
                                    textAlign: 'left',
                                    paddingLeft: isMobile ? '15px' : '0px',
                                }}
                            >
                                Note: 'Calendar View' does not include cancelled
                                or declined reservations.
                            </Typography>
                        </Grid>
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                paddingLeft: isMobile ? '15px' : '0px',
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <SquareIcon
                                    fontSize='small'
                                    sx={{
                                        fontSize: '16px',
                                        color: '#3b6978',
                                        paddingRight: '3px',
                                    }}
                                />{' '}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '12px',
                                        paddingRight: '5px',
                                    }}
                                >
                                    Property
                                </Typography>
                                <SquareIcon
                                    fontSize='small'
                                    sx={{
                                        fontSize: '16px',
                                        color: '#4B0082',
                                        paddingRight: '3px',
                                    }}
                                />{' '}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '12px',
                                        paddingRight: '5px',
                                    }}
                                >
                                    Commercial
                                </Typography>
                                <SquareIcon
                                    fontSize='small'
                                    sx={{
                                        fontSize: '16px',
                                        color: '#A7C7E7',
                                        paddingRight: '3px',
                                    }}
                                />{' '}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '12px',
                                        paddingRight: '5px',
                                    }}
                                >
                                    Boat
                                </Typography>
                                <SquareIcon
                                    fontSize='small'
                                    sx={{
                                        fontSize: '16px',
                                        color: '#006400',
                                        paddingRight: '3px',
                                    }}
                                />{' '}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '12px',
                                        paddingRight: '5px',
                                    }}
                                >
                                    Vehicle
                                </Typography>
                                <SquareIcon
                                    fontSize='small'
                                    sx={{
                                        fontSize: '16px',
                                        color: '#93C572',
                                        paddingRight: '3px',
                                    }}
                                />{' '}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '12px',
                                        paddingRight: '5px',
                                    }}
                                >
                                    Activity
                                </Typography>
                                <SquareIcon
                                    fontSize='small'
                                    sx={{
                                        fontSize: '16px',
                                        color: '#CCCCCC',
                                        paddingRight: '3px',
                                    }}
                                />{' '}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '12px',
                                        paddingRight: '5px',
                                    }}
                                >
                                    Completed
                                </Typography>
                                <CropSquareIcon
                                    fontSize='small'
                                    sx={{
                                        fontSize: '16px',
                                        color: '#CCCCCC',
                                        paddingRight: '3px',
                                    }}
                                />{' '}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                        paddingRight: '5px',
                                        color: '#787878',
                                    }}
                                >
                                    Pending
                                </Typography>
                            </Grid>

                            {loggedInUser?.role.includes('owner') ||
                            loggedInUser?.role === 'admin' ? (
                                <Grid
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <SquareIcon
                                        fontSize='small'
                                        sx={{
                                            fontSize: '16px',
                                            color: '#ffff66',
                                            paddingRight: '3px',
                                        }}
                                    />{' '}
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '12px',
                                            paddingRight: '5px',
                                            color: '#787878',
                                        }}
                                    >
                                        Owner
                                    </Typography>
                                    <SquareIcon
                                        fontSize='small'
                                        sx={{
                                            fontSize: '16px',
                                            color: '#FA8072',
                                            paddingRight: '3px',
                                        }}
                                    />{' '}
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '12px',
                                            paddingRight: '5px',
                                            color: '#787878',
                                        }}
                                    >
                                        Blocked
                                    </Typography>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>
        </Container>
    );
}

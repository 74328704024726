import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import moment from 'moment';
import React from 'react';

import { CustomChip } from '../../../../layout/styledcomponents/CustomChip';

const PaymentHistory = ({
    reservationDetails,
}: {
    reservationDetails: any;
}) => {
    return (
        <TableContainer>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                            }}
                        >
                            Status
                        </TableCell>

                        <TableCell
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                            }}
                        >
                            Amount
                        </TableCell>

                        <TableCell
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                            }}
                        >
                            {!reservationDetails?.invoice?.fullyPaidAt
                                ? 'Due Date'
                                : 'Time'}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                            }}
                        >
                            <CustomChip
                                style={{
                                    fontFamily: 'Open Sans',
                                }}
                                label={
                                    reservationDetails?.invoice?.fullyPaidAt
                                        ? 'Paid'
                                        : 'Outstanding'
                                }
                                color={
                                    reservationDetails?.invoice?.fullyPaidAt
                                        ? 'green'
                                        : 'red'
                                }
                                variant='outlined'
                            />
                        </TableCell>

                        <TableCell
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                            }}
                        >
                            {`$${reservationDetails?.bookingTotal?.total?.toFixed(
                                2
                            )} AUD `}{' '}
                        </TableCell>

                        <TableCell
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                            }}
                        >
                            {' '}
                            {reservationDetails?.invoice?.fullyPaidAt
                                ? moment(
                                      reservationDetails?.invoice?.fullyPaidAt
                                  ).format('DD/MM/YYYY, h:mm a')
                                : moment(
                                      reservationDetails?.invoice?.dueDate
                                  ).format('DD/MM/YYYY')}
                            {}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PaymentHistory;

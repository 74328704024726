import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { useNavigate } from 'react-router-dom';
import UserRole from '../../../../shared/AuthRoles';
import AppFeeForm from '../../../components/7DAY Admin Components/AppFees/AppFeeForm';

const ManageAppFee = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (loggedInUser.role !== 'owner_admin') {
    //         navigate(`/permissionDenied`, {
    //             state: {
    //                 name: 'feesPage',
    //             },
    //         });
    //         ReactGA.send({
    //             hitType: 'pageview',
    //             page: `/permissionDenied`,
    //             title: `Permission Denied`,
    //         });
    //     }
    // }, [loggedInUser.role]);

    const handleBackButton = () => {
        navigate(-1);
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile || isIpad ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <AppFeeForm />
                <CustomButton
                    onClick={handleBackButton}
                    color='green'
                    sx={{
                        marginLeft: !isMobile ? '-8px' : '-18px',
                        '&:hover': {
                            cursor: 'pointer',
                            color: '#5cb5b3',
                            backgroundColor: 'transparent',
                            background: 'none',
                        },
                    }}
                >
                    <ArrowBackIcon />{' '}
                    {isMobile ? null : (
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                paddingLeft: '5px',
                            }}
                        >
                            Back Admin Center
                        </Typography>
                    )}
                </CustomButton>
            </Grid>
        </Container>
    );
};

export default ManageAppFee;

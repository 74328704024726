import { HelpOutline } from '@mui/icons-material';
import { Badge, List, ListItem, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import ProfilePicture from '../Profile/ProfilePicture';
import RoleChip from '../../shared/RoleChip';

interface UserProps {
    user: {
        firstName: string,
        lastName: string,
        email: string,
        role: string,
        viewOnly: boolean,
        profilePicture?: {
            src: string
        },
        department?: {
            name: string
        },
    }
}

const UserSummary = ({ user }: UserProps) => {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <>
            <Stack 
                direction={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'center' : 'left'}
                gap='30px'
            >
                <Badge
                    overlap='circular'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                        <Tooltip
                            title={
                                <Typography
                                    // color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    <List>
                                        <ListItem>
                                            {' '}
                                            Your profile picture will be used 
                                            for facial recognition.
                                        </ListItem>
                                        <ListItem>
                                            {' '}
                                            For security reasons, a profile picture is mandatory for some experiences.
                                        </ListItem>
                                    </List>
                                </Typography>
                            }
                            placement='right'
                        >
                            <HelpOutline
                                sx={{
                                    color: '#787878',
                                    bgcolor: '#fff',
                                    borderRadius: '24px',
                                    padding: '1px',
                                    fontSize: '24px',
                                }}
                            />
                        </Tooltip>
                    }
                >
                    <ProfilePicture profileDetails={user} src={user?.profilePicture?.src}/>
                </Badge>
                <Stack alignItems={isMobile ? 'center' : 'left'}>
                    <Typography
                        variant='h6'
                        color='#787878'
                        fontFamily='Open Sans'
                        fontWeight='bold'
                    >
                        {user?.firstName} {user?.lastName}
                    </Typography>
                    <Typography
                        variant='body1'
                        color='#787878'
                        fontFamily='Open Sans'
                        gutterBottom
                    >
                        {user?.department && `${user?.department?.name} - `}
                        <RoleChip user={user}/>
                    </Typography>
                    <Typography
                        variant='body2'
                        color='#5cb5b3'
                        fontFamily='Open Sans'
                        gutterBottom
                    >
                        {user?.email}
                    </Typography>
                </Stack>
            </Stack>
        </>
    )
}

export default UserSummary
import {
    Alert,
    Box,
    Grid,
    InputLabel,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import FormSelect from '../../../shared/FormSelect';
import GenericModal from '../../../shared/GenericModal';
import { Space } from '../../../types/experience';
import { ModalProps } from '../../../types/modal';
import BedroomForm from './BedroomForm';
import GameRoomForm from './GameRoomForm';
import DiningAreaForm from './DiningAreaForm';
import KitchenForm from './KithcenForm';
import LivingRoomForm from './LivingRoomForm';
import BathroomForm from './BathroomForm';
import BoardRoomForm from './BoardRoomForm';
import { ModifiedSpace } from './IndividualSpace';
import { camelCaseToTitleCase } from '../../../../shared/utils';
import GarageForm from './GarageForm';

interface Props extends ModalProps {
    onSubmit: (data: Space) => void;
    existingSpace: ModifiedSpace | null;
}

export enum SpaceType {
    Bathroom = 'bathroom',
    Bedroom = 'bedroom',
    Boardroom = 'boardroom',
    DiningArea = 'diningArea',
    GameRoom = 'gameRoom',
    Garage = 'garage',
    Kitchen = 'kitchen',
    LivingRoom = 'livingRoom',
}

const SpaceModal = ({ open, onClose, onSubmit, existingSpace }: Props) => {
    const theme = useTheme();
    const defaultValues = {
        spaceType: SpaceType.Bedroom,
        singleBed: 0,
        doubleBed: 0,
        queenBed: 0,
        kingBed: 0,
        bunkBed: 0,
        sofaBed: 0,
        couch: 0,
        babyCot: 0,
        blackOutCurtains: false,
        wardrobe: false,
        extraPillowsAndBlankets: false,
        iron: false,
        safe: false,
        tv: false,
        ensuite: false,
        desk: 0,
        bathtub: false,
        babyBath: false,
        hairdryer: false,
        heating: false,
        bidet: false,
        toiletries: false,
        seatingCapacity: 0,
        projector: false,
        whiteboard: false,
        soundSystem: false,
        gameConsole: false,
        poolTable: false,
        fridge: false,
        oven: false,
        microwave: false,
        dishwasher: false,
        coffeeMachine: false,
        toaster: false,
        kettle: false,
        cookware: false,
        cutlery: false,
        fireplace: false,
        parkingCapacity: 0,
        evChargingStation: false,
        securitySystem: false,
        automaticDoor: false,
        dehumidifier: false,
        carwashStation: false,
        bicycleRack: false,
    };

    const isMobile = useMediaQuery('(max-width: 780px)');
    const methods = useForm<Space>({
        defaultValues,
        // shouldUnregister: true,
    });

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { isDirty, dirtyFields },
    } = methods;

    const spaceType = watch('spaceType');
    const [cancel, setCancel] = useState<boolean>(false);

    const handleCancel = () => {
        if (isDirty) {
            // if form is dirty, display alert
            if (!cancel) {
                setCancel(true);
                return;
            }
        }
        if (cancel) reset();
        setCancel(false);
        onClose();
    };

    const onNestedSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        if (event && typeof event.stopPropagation === 'function') {
            event.stopPropagation();
        }

        return handleSubmit(onSubmit)(event);
    };

    useEffect(() => {
        if (open && !existingSpace) {
            const newDefaultValues = { ...defaultValues, spaceType: spaceType };
            reset(newDefaultValues);
        }
    }, [spaceType]);

    const [isFormDirty, setIsFormDirty] = useState(false);

    const formDirtyFields = Object.keys(dirtyFields);

    useEffect(() => {
        if (isDirty) {
            if (
                formDirtyFields.length === 1 &&
                formDirtyFields.includes('spaceType')
            ) {
                setIsFormDirty(false);
            } else {
                setIsFormDirty(true);
            }
        } else {
            setIsFormDirty(false);
        }
    }, [formDirtyFields]);

    useEffect(() => {
        if (open) {
            if (existingSpace) {
                reset(existingSpace);
            } else reset(defaultValues);
        }
    }, [existingSpace, reset, open]);

    return (
        <>
            <GenericModal open={open} onClose={onClose}>
                <FormProvider {...methods}>
                    <form onSubmit={onNestedSubmit}>
                        <Grid
                            sx={{
                                padding: '30px',
                                // minWidth: isMobile ? 0 : '50vw',
                                minWidth: isMobile ? 0 : '700px',
                            }}
                        >
                            <Stack
                                sx={{
                                    gap: 0.5,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: 'text.primary',
                                    }}
                                >
                                    {existingSpace
                                        ? camelCaseToTitleCase(
                                              existingSpace.name
                                          )
                                        : 'Space Configuration'}
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        pb: 1,
                                    }}
                                >
                                    Configure the selected space by specifying
                                    amenities and features.
                                </Typography>
                            </Stack>

                            <Collapse in={cancel}>
                                <Alert severity='warning'>
                                    You have unsaved changes. Are you sure you
                                    want to cancel?
                                </Alert>
                            </Collapse>
                            <Stack rowGap={1}>
                                {!existingSpace && (
                                    <Grid
                                        container
                                        columnSpacing={5}
                                        rowSpacing={1}
                                    >
                                        <Grid item container xs={12}>
                                            <Grid item xs={12} lg={4}>
                                                <InputLabel required>
                                                    Type
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <FormSelect
                                                    name='spaceType'
                                                    control={control}
                                                    options={[
                                                        {
                                                            value: SpaceType.Bedroom,
                                                            label: 'Bedroom',
                                                        },
                                                        {
                                                            value: SpaceType.LivingRoom,
                                                            label: 'Living Room',
                                                        },
                                                        {
                                                            value: SpaceType.Boardroom,
                                                            label: 'Boardroom',
                                                        },
                                                        {
                                                            value: SpaceType.Bathroom,
                                                            label: 'Bathroom',
                                                        },
                                                        {
                                                            value: SpaceType.DiningArea,
                                                            label: 'Dining Area',
                                                        },
                                                        {
                                                            value: SpaceType.GameRoom,
                                                            label: 'Game Room',
                                                        },
                                                        {
                                                            value: SpaceType.Garage,
                                                            label: 'Garage',
                                                        },
                                                        {
                                                            value: SpaceType.Kitchen,
                                                            label: 'Kitchen',
                                                        },
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {spaceType === SpaceType.Bedroom && (
                                    <BedroomForm control={control} />
                                )}
                                {spaceType === SpaceType.GameRoom && (
                                    <GameRoomForm control={control} />
                                )}
                                {spaceType === SpaceType.Garage && (
                                    <GarageForm control={control} />
                                )}
                                {spaceType === SpaceType.DiningArea && (
                                    <DiningAreaForm control={control} />
                                )}
                                {spaceType === SpaceType.Kitchen && (
                                    <KitchenForm control={control} />
                                )}
                                {spaceType === SpaceType.LivingRoom && (
                                    <LivingRoomForm control={control} />
                                )}
                                {spaceType === SpaceType.Bathroom && (
                                    <BathroomForm control={control} />
                                )}
                                {spaceType === SpaceType.Boardroom && (
                                    <BoardRoomForm control={control} />
                                )}
                            </Stack>
                        </Grid>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: 1.2,
                                gap: 1.2,
                                borderTop: `solid 1px ${theme.palette.custom.modalActionBorder}`,
                                background:
                                    theme.palette.custom.modalActionBackground,
                                flexDirection: isMobile ? 'column' : 'row',
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <CustomButton
                                    width={isMobile ? '50%' : 'auto'}
                                    variant='contained'
                                    color='neutral'
                                    // autoFocus
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </CustomButton>

                                <CustomButton
                                    width={isMobile ? '50%' : 'auto'}
                                    variant='contained'
                                    color='green'
                                    // onClick={handleSave}
                                    disabled={!isFormDirty}
                                    autoFocus
                                    type='submit'
                                >
                                    Save
                                </CustomButton>
                            </Grid>
                        </Box>
                    </form>
                </FormProvider>
            </GenericModal>
        </>
    );
};

export default SpaceModal;

import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SendIcon from '@mui/icons-material/Send';
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    useAiReservationRequestMutation,
    useGetAIThreadIdMutation,
    useGetProfileCompanyInfoQuery,
    useGetProfileDetailsQuery,
    useSubmitRecurringReservationMutation,
    useSubmitReservationMutation,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../../layout/styledcomponents/CustomIconButton';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import UserRole from '../../../../shared/AuthRoles';
import toast from '../../../shared/Toast';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 900,
        color: '#000',
        borderBottom: '1px solid #787878',
        paddingLeft: '0px',
    },
    [`& .${gridClasses.row}`]: {
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                },
            },
        },
    },
}));

export default function AIReservationsAssistant() {
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    // Create a ref for the messages container
    const messagesEndRef = useRef<any>(null);
    const [requestAiReservation] = useSubmitRecurringReservationMutation();
    const [requestIndividualReservation] = useSubmitReservationMutation();
    const [customId, setCustomId] = useState(0);
    const loggedInUser = UserRole();
    const { data: getCompanyDetails } = useGetProfileCompanyInfoQuery(
        `/owners/${loggedInUser?.company}`
    );
    const { data: loggedInUserDetails } = useGetProfileDetailsQuery('/profile');
    const location = useLocation();
    const navigate = useNavigate();
    //------//
    const [moduleId, setModuleId] = useState(undefined);
    const [experienceName, setExperienceName] = useState(undefined);
    const [requestInput, setRequestInput] = useState('');
    const [pendingMessage, setPendingMessage] = useState('');
    const [requestSent, setRequestSent] = useState(false);
    const [nextPageFn, setNextPageFn] = useState<() => void>();

    // const self = loggedInUser?.sub.toString() === creatorId;
    //------//
    //API Calls
    const [threadId]: any = useGetAIThreadIdMutation();
    const [aiReservationRequest, { isLoading }]: any =
        useAiReservationRequestMutation();
    //------//
    //AI Response saved in state
    const [aiThreadId, setAiThreadId] = useState();
    const [aiResponse, setAiResponse]: any = useState();
    const [aiDates, setAiDates]: any = useState();
    //Keep history of messages to display in chat
    const [messages, setMessages] = useState<any>([]);
    const [isTyping, setIsTyping] = useState(false);

    //Checking what page, Reservations or from the experience to obtain moduleId if there is one
    useEffect(() => {
        setNextPageFn(undefined); // Reset nextPageFn when channel changes

        if (location?.state?.page !== undefined) {
            if (location?.state?.page === 'reservationPage') {
                setModuleId(undefined);
            } else {
                setModuleId(location?.state?.id);
                setExperienceName(location?.state?.name);
            }
        }
        //initiate AI threadID
        getThreadId();
        setIsTyping(true);
        chatInitiated();
    }, [location]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const chatInitiated = () => {
        let message = {
            message: `${
                location?.state?.id !== undefined
                    ? `Hello, tell us when you would like to reserve ${location?.state?.name}?`
                    : `Hello, tell us what experience you would like to reserve?`
            }`,
            sentTime: 'just now',
            sender: '7DAY Assistant',
        };

        setTimeout(function () {
            setMessages(() => [message]);
            setIsTyping(false);
        }, 2000);
    };

    const getThreadId = () => {
        //AI thread ID stored in the state to send in the submit post
        threadId().then((data: any) => {
            setAiThreadId(data?.data?.id);
        });
    };
    const handleInput = (event: { target: { value: string } }) => {
        //Save the input
        let message = event?.target?.value;
        setPendingMessage(message);
        setRequestInput(message);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent default form behavior
            onSubmit(); // Trigger the submit action
        }
    };

    const onSubmit = () => {
        setRequestSent(true);
        setPendingMessage('');
        //check if moduleId is undefined (if undefined the user has come from the /Reservation page and not the experience)
        let message = requestInput;
        const newMessage = {
            message,
            direction: 'outgoing',
            sender: loggedInUser?.sub.toString(),
        };
        setMessages((prevMessages: any) => [...prevMessages, newMessage]);
        setIsTyping(true);
        const element: any = document.getElementById('messages');
        element.scroll({
            top: 100,
            left: 100,
            behavior: 'smooth',
        });

        let requestBody = {};
        if (moduleId === undefined) {
            requestBody = {
                threadId: aiThreadId,
                message: requestInput,
            };
        } else {
            requestBody = {
                threadId: aiThreadId,
                message: requestInput,
                moduleId: moduleId,
            };
        }

        //Submit users request to AI. If data, display it to the user, if not initate the AI chat to get more information.
        aiReservationRequest(requestBody).then((data: any) => {
            setIsTyping(true);
            if (data) {
                //set the response to show the user in list format
                if (data?.error?.status === 400) {
                    //Here is where we will create the AI assistant Chat
                    setIsTyping(false);
                    let message = data?.error?.data.message;
                    const newMessage = {
                        message,
                        direction: 'just now',
                        sender: '7DAY Assistant',
                    };
                    setMessages((prevMessages: any) => [
                        ...prevMessages,
                        newMessage,
                    ]);
                    window.scrollTo(0, 500);
                } else {
                    setAiResponse(data?.data);
                    setAiDates(data?.data?.dates);

                    setIsTyping(false);
                }
            } else {
                setIsTyping(false);
            }
        });
    };

    const clearForm = () => {
        //clear the form if the user wants to start again

        setRequestSent(false);
        setAiResponse();
        setRequestInput('');
        let message = {
            message: `${
                location?.state?.id !== undefined
                    ? `Hello, tell us when you would like to reserve ${location?.state?.name}?`
                    : `Hello, tell us what experience you would like to reserve?`
            }`,
            sentTime: 'just now',
            sender: '7DAY Assistant',
        };

        setMessages(() => [message]);

        //Get new thread id
        getThreadId();
    };

    const onClickRequest = () => {
        //if user is okay with response, submit Reservation (note Frontend does not include dates that are actually unavailable)
        let submitedDates = aiDates?.filter(
            (value: any) => value?.isAvailable !== false
        );

        let formatDates = submitedDates?.map((i: any) => {
            return {
                startDate: moment(i.startDate).format('YYYY-MM-DD'),
                endDate: moment(i.endDate).format('YYYY-MM-DD'),
            };
        });

        if (formatDates?.length === 1) {
            // if user has request just one date, we use POST /Reservation rather than POST /recurring-Reservation
            let requestBody = {
                bookerType: 'owner',
                module: {
                    id: aiResponse?.module?.id,
                },
                startDate: formatDates[0]?.startDate,
                endDate: formatDates[0]?.endDate,
                onBehalf: false,
                reason: aiResponse?.reason,
                notes: aiResponse?.notes,
                fees: [],
                policies: [],
            };
            requestIndividualReservation(requestBody).then((data: any) => {
                if (data) {
                    if (data?.error) {
                        if (
                            data?.error?.data.error === 'booking_cap_exceeded'
                        ) {
                            toast.error(`${data?.error?.data.message}`);
                        } else {
                            toast.error(`Failed to submit Reservation`);
                        }
                    } else {
                        toast.success(`Reservation request submitted`);
                        navigate(
                            `/reservationRequest/submitted/${data?.data?.id}`,
                            {
                                state: {
                                    page: 'singleReservation',
                                    id: data?.data?.id,
                                },
                            }
                        );
                    }
                } else {
                    if (data?.error === 'booking_cap_exceeded') {
                        toast.error(`${data?.error?.data.message}`);
                    } else {
                        toast.error(`Failed to submit Reservation`);
                    }
                }
            });
        } else {
            let requestBody = {
                bookerType: 'owner',
                module: {
                    id: aiResponse?.module?.id,
                },
                dates: formatDates,
                onBehalf: false,
                reason: aiResponse?.reason,
                notes: aiResponse?.notes,
                fees: [],
                policies: [],
            };
            requestAiReservation(requestBody).then((data: any) => {
                if (data) {
                    let recurringId = data?.data?.map((i: any) => {
                        return i.recurringId;
                    });

                    if (data?.error) {
                        if (
                            data?.error?.data.error === 'booking_cap_exceeded'
                        ) {
                            toast.error(`${data?.error?.data.message}`);
                        } else {
                            toast.error(`Failed to submit Reservation`);
                        }
                    } else {
                        toast.success(`Reservation request submitted`);
                        navigate(
                            `/reservationRequest/submitted/ai-assistant/${recurringId[0]}`,
                            {
                                state: {
                                    page: 'recurringReservation',
                                    id: recurringId[0],
                                },
                            }
                        );
                    }
                } else {
                    if (data?.error === 'booking_cap_exceeded') {
                        toast.error(`${data?.error?.data.message}`);
                    } else {
                        toast.error(`Failed to submit Reservation`);
                    }
                }
            });
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'moduleId',
            headerName: 'Experience',
            width: isMobile ? 150 : isIpad ? 150 : 200,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return params?.row.isAvailable === true ? (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {aiResponse?.module.name}
                    </Typography>
                ) : (
                    <Typography
                        color='red'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {aiResponse?.module.name}
                    </Typography>
                );
            },
        },
        {
            field: 'moduleType',
            headerName: 'Type',
            width: isMobile ? 150 : isIpad ? 100 : 100,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return params?.row.isAvailable === true ? (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {aiResponse?.module?.type.charAt(0).toUpperCase() +
                            aiResponse?.module?.type.slice(1)}
                    </Typography>
                ) : (
                    <Typography
                        color='red'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {aiResponse?.module?.type.charAt(0).toUpperCase() +
                            aiResponse?.module?.type.slice(1)}
                    </Typography>
                );
            },
        },

        {
            field: 'startDate',
            headerName: 'Start Date',
            width: isMobile ? 150 : isIpad ? 150 : 100,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return params?.row.isAvailable === true ? (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {moment(params?.value).format('DD/MM/YYYY')}
                    </Typography>
                ) : (
                    <Typography
                        color='red'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {moment(params?.value).format('DD/MM/YYYY')}
                    </Typography>
                );
            },
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            width: isMobile ? 150 : isIpad ? 150 : 100,
            type: 'Date',
            sortable: false,
            renderCell: (params) => {
                return params?.row.isAvailable === true ? (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {moment(params?.value).format('DD/MM/YYYY')}
                    </Typography>
                ) : (
                    <Typography
                        color='red'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {moment(params?.value).format('DD/MM/YYYY')}
                    </Typography>
                );
            },
        },
        {
            field: 'company',
            headerName: 'Company',
            width: isMobile ? 150 : isIpad ? 150 : 200,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return params?.row.isAvailable === true ? (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {getCompanyDetails?.name}
                    </Typography>
                ) : (
                    <Typography
                        color='red'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {getCompanyDetails?.name}
                    </Typography>
                );
            },
        },
        {
            field: 'vistType',
            headerName: 'Visit Type',
            width: isMobile ? 150 : isIpad ? 150 : 80,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return params?.row.isAvailable === true ? (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        Blocked
                    </Typography>
                ) : (
                    <Typography
                        color='red'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        Blocked
                    </Typography>
                );
            },
        },
        {
            field: 'reason',
            headerName: 'Reservation Reason',
            width: isMobile ? 150 : isIpad ? 150 : 350,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return params?.row.isAvailable === true ? (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {aiResponse?.reason.charAt(0).toUpperCase() +
                            aiResponse?.reason.slice(1)}
                    </Typography>
                ) : (
                    <Typography
                        color='red'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {aiResponse?.reason.charAt(0).toUpperCase() +
                            aiResponse?.reason.slice(1)}
                    </Typography>
                );
            },
        },
        {
            field: 'bookedBy',
            headerName: 'Reserved By',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return params?.row.isAvailable === true ? (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {loggedInUserDetails?.firstName}{' '}
                        {loggedInUserDetails?.lastName}
                    </Typography>
                ) : (
                    <Typography
                        color='red'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {loggedInUserDetails?.firstName}{' '}
                        {loggedInUserDetails?.lastName}
                    </Typography>
                );
            },
        },
        {
            field: 'isAvailable',
            headerName: 'Availability',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return params?.value === true ? (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        Available
                    </Typography>
                ) : (
                    <Typography
                        color='red'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        Unavailable
                    </Typography>
                );
            },
        },
        {
            field: 'action',
            headerName: '',
            width: isMobile ? 50 : isIpad ? 50 : 50,
            sortable: false,
            renderCell: (params) => {
                const onClick = (e: any) => {
                    const currentRowId = params?.id;

                    let removeReservation = aiDates?.filter(
                        (value: any) => value?.id !== currentRowId
                    );
                    setAiDates(removeReservation);
                };

                return params?.row.isAvailable === true ? (
                    <CustomIconButton
                        sx={{
                            backgroundColor: 'transparent',
                            width: '25px',
                            height: '25px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                        onClick={onClick}
                    >
                        <DeleteForeverIcon
                            fontSize='small'
                            sx={{ fontSize: '16px' }}
                        />
                    </CustomIconButton>
                ) : (
                    <CustomIconButton
                        sx={{
                            backgroundColor: 'transparent',
                            width: '25px',
                            height: '25px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: 'red',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                        onClick={onClick}
                    >
                        <DeleteForeverIcon
                            fontSize='small'
                            sx={{ fontSize: '16px' }}
                        />
                    </CustomIconButton>
                );
            },
        },
    ];

    const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if (!nextPageFn) return;

        const el = e.currentTarget;

        const top = el.scrollHeight - el.clientHeight;
        const scroll = -el.scrollTop; // reversed because of the flex direction

        if (top - scroll <= 1) {
            nextPageFn();
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                height: '80vh',
                width: '100%',
            }}
        >
            {aiResponse === undefined ? (
                <Grid
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Stack
                        overflow='scroll'
                        spacing={2}
                        p={3}
                        height='900px'
                        flex={1}
                        onScroll={onScroll}
                        // hide scrollbar
                        sx={{
                            paddingTop: '50px',
                            '-ms-overflow-style': 'none',
                            '::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        {messages?.map((message: any, i: any) => {
                            return (
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='fit-content'
                                    maxWidth='90%'
                                    alignSelf={
                                        loggedInUser?.sub.toString() ===
                                        message?.sender
                                            ? 'flex-end'
                                            : 'flex-start'
                                    }
                                    alignItems={
                                        loggedInUser?.sub.toString() ===
                                        message?.sender
                                            ? 'flex-end'
                                            : 'flex-start'
                                    }
                                >
                                    <Box
                                        id='messages'
                                        bgcolor={
                                            loggedInUser?.sub.toString() ===
                                            message?.sender
                                                ? '#5cb5b3'
                                                : '#f6f6f6'
                                        }
                                        borderRadius={2}
                                        p={2}
                                        color={
                                            loggedInUser?.sub.toString() ===
                                            message?.sender
                                                ? 'white'
                                                : 'black'
                                        }
                                    >
                                        <Typography
                                            color={
                                                loggedInUser?.sub.toString() ===
                                                message?.sender
                                                    ? 'white'
                                                    : '#787878'
                                            }
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                whiteSpace: 'pre-line',
                                            }}
                                        >
                                            {' '}
                                            {message.message}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant='subtitle2'
                                        color='textSecondary'
                                        sx={{
                                            fontSize: '10px',
                                            padding: '5px',
                                        }}
                                    >
                                        {message?.sender === '7DAY Assistant'
                                            ? message?.sender
                                            : 'You'}
                                    </Typography>
                                </Box>
                            );
                        })}

                        {isTyping ? (
                            <Box
                                display='flex'
                                flexDirection='row'
                                width='fit-content'
                                maxWidth='90%'
                                alignSelf={'flex-start'}
                                alignItems={'flex-start'}
                                gap={0.3}
                                pt={1}
                            >
                                {' '}
                                <Skeleton
                                    variant='circular'
                                    width={8}
                                    height={8}
                                    sx={{ bgcolor: '#787878' }}
                                />
                                <Skeleton
                                    variant='circular'
                                    width={8}
                                    height={8}
                                    sx={{ bgcolor: '#787878' }}
                                />
                                <Skeleton
                                    variant='circular'
                                    width={8}
                                    height={8}
                                    sx={{ bgcolor: '#787878' }}
                                />
                            </Box>
                        ) : null}
                        {/* Reference for scrolling */}
                        <div ref={messagesEndRef} />
                    </Stack>

                    <Box
                        display='flex'
                        gap={1}
                        pt={2}
                        position='fixed'
                        sx={{
                            // left: 240,
                            bottom: 40,
                            // right: 240,
                            backgroundColor: 'white',
                            width: '93%',
                        }}
                    >
                        <Grid
                            sx={{
                                width: '95%',
                            }}
                        >
                            {' '}
                            <CustomTextField
                                type='text'
                                onKeyDown={handleKeyDown}
                                placeholder={
                                    pendingMessage !== ''
                                        ? ''
                                        : moduleId !== undefined
                                        ? 'i.e Book every Tuesday from the 1st of October until the end of March between 9am and 5pm for maintenance'
                                        : 'i.e Book the Resort every Tuesday from the 1st of October until the end of March between 9am and 5pm for maintenance'
                                }
                                fullWidth
                                color='neutral'
                                onChange={handleInput}
                                value={pendingMessage}
                                autoComplete='off'
                                inputProps={{
                                    color: '#787878',
                                    backgroundColor: 'white',
                                    style: {
                                        color: '#787878',
                                        backgroundColor: 'white',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid sx={{ width: 'auto' }}>
                            <CustomButton
                                size='small'
                                color='green'
                                variant='contained'
                                onClick={onSubmit}
                                disabled={isLoading}
                                disableElevation
                                disableRipple
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: 'white',
                                        backgroundColor: '#787878',
                                    },
                                }}
                            >
                                <SendIcon />
                            </CustomButton>
                        </Grid>
                    </Box>
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '30px',
                        paddingBottom: '30px',
                    }}
                >
                    <Grid sx={{ height: '5%' }}>
                        {' '}
                        <Typography
                            component='h4'
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                textAlign: 'left',
                                fontWeight: 900,
                                paddingBottom: '30px',
                            }}
                        >
                            Results
                        </Typography>
                    </Grid>

                    <Grid sx={{ height: '80%' }}>
                        {' '}
                        <StripedDataGrid
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0
                                    ? 'even'
                                    : 'odd'
                            }
                            sx={{
                                boxShadow: 0,
                                border: 0,
                                '& .MuiDataGrid-cell': {
                                    padding: '0',
                                },
                            }}
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            disableDensitySelector
                            disableRowSelectionOnClick
                            rows={aiDates ? aiDates : []}
                            columns={columns}
                            getRowId={(row: any) => row?.id}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack
                                        height='100%'
                                        alignItems='center'
                                        justifyContent='center'
                                    >
                                        <List>
                                            <ListItem
                                                sx={{
                                                    padding: '0!important',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            No Results
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                fontSize:
                                                                    '12px',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            Please try the
                                                            request again
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </Stack>
                                ),
                                NoResultsOverlay: () => (
                                    <Stack
                                        height='100%'
                                        alignItems='center'
                                        justifyContent='center'
                                    >
                                        <List>
                                            <ListItem
                                                sx={{
                                                    padding: '0!important',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            No Results
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                fontSize:
                                                                    '12px',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            Please try again
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </Stack>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            display: 'flex',
                            width: '100%',
                            paddingTop: '50px',
                        }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '50%',
                            }}
                        >
                            <CustomButton
                                id='resetForm'
                                variant='outlined'
                                color='green'
                                onClick={() => clearForm()}
                            >
                                Start Over
                            </CustomButton>
                        </Grid>
                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '50%',
                            }}
                        >
                            <CustomButton
                                id='submitAiReservation'
                                variant='contained'
                                color='green'
                                // loading={isLoading}
                                disabled={isLoading}
                                onClick={() => onClickRequest()}
                                startIcon={<AddCircleIcon />}
                            >
                                Reserve
                            </CustomButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import React from 'react';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { ModalProps } from '../../../types/modal';
import { MessageRepository } from '@amityco/ts-sdk';
import toast from '../../../shared/Toast';

interface DeleteModalProps extends ModalProps {
    messageId: string;
}

const DeleteModal = ({ messageId, open, onClose }: DeleteModalProps) => {
    const deleteMessage = async () => {
        try {
            await MessageRepository.deleteMessage(messageId);
        } catch (error) {
            toast.error(`Failed to delete message.`);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete Message</DialogTitle>
            <DialogContent>
                <DialogContentText variant='body2'>
                    Are you sure you want to delete this message? This cannot be
                    undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    variant='outlined'
                    color='green'
                    onClick={onClose}
                >
                    Cancel
                </CustomButton>
                <CustomButton
                    variant='contained'
                    color='green'
                    onClick={deleteMessage}
                >
                    Delete
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteModal;

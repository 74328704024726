import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReservationPolicy } from "../../feature/types/policy";
import { RootState } from "./store";

const initialState: { value: ReservationPolicy[] } = {
    value: [],
};

export const reservationPoliciesSlice = createSlice({
    name: 'reservationPolicies',
    initialState,
    reducers: {
        setReservationPolicies: (state, action: PayloadAction<ReservationPolicy[]>) => {
            state.value = action.payload;
        },

        toggleConsent: (state, action: PayloadAction<number>) => {
            state.value = state.value.map((policy) =>
                policy.policyId === action.payload
                    ? { 
                        ...policy, 
                        checked: !policy.checked, 
                        checkedAt: !policy.checked ? new Date() : undefined
                     }
                    : policy
            );
        }
    }
});


export const {
    setReservationPolicies,
    toggleConsent,
} = reservationPoliciesSlice.actions;

export const selectReservationPolicies = (state: RootState) => state.reservationPolicies.value;

export const reservationPoliciesReducer = reservationPoliciesSlice.reducer;
    
import { Grid, InputLabel, Typography } from '@mui/material';
import React from 'react';
import FormCheckbox from '../../../shared/FormCheckbox';

const BathroomForm = ({ control }: { control: any }) => {
    return (
        <>
            <Grid item xs={12} sx={{ pt: 2 }}>
                <Typography color='textPrimary' fontWeight='bold'>
                    Amenities
                </Typography>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='bathtub'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Bathtub</InputLabel>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='babyBath'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Baby Bath</InputLabel>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='hairdryer'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Hairdryer</InputLabel>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='heating'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Heating</InputLabel>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='bidet'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Bidet</InputLabel>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='toiletries'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Toiletries</InputLabel>
                </Grid>
            </Grid>
        </>
    );
};

export default BathroomForm;

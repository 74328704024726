import {
    Grid,
    IconButton,
    InputAdornment,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useGetReportQuery } from '../../../../../app/Slices/SevenDayApiSlice';
import { CustomTextField } from '../../../../../layout/styledcomponents/CustomTextField';
import { CustomButton } from '../../../../../layout/styledcomponents/CustomButton';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSelector } from 'react-redux';
import { selectReportAPIRefSliceData } from '../../../../../app/Slices/ReportAPIRefSlice';
import SearchIcon from '@mui/icons-material/Search';
import DropdownFilter from '../../../../../layout/styledcomponents/filters/Dropdown';
import FYReportGraph from './FYReportGraph';
import FYListReport from './FYReportList';

const defaultSearchValueParser = (searchText: any) =>
    searchText.split(' ').filter((word: any) => word !== '');

export default function FYComponentPage(props: any) {
    const {
        quickFilterParser = defaultSearchValueParser,
        debounceMs = 500,
        ...other
    } = props;
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [reportPeriod, setReportPeriod] = useState('prev-fy');
    const [checked, setChecked] = React.useState(false);
    const reportExportAPI: any = useSelector(selectReportAPIRefSliceData);
    // const [groupBy, setGroupBy] = useState('None');
    const [searchValue, setSearchValue] = React.useState('');
    //REPORT VIEW
    const graphView = (value: boolean | ((prevState: boolean) => boolean)) => {
        setChecked(value);
    };

    //GET REPORT
    const { data: getReport } = useGetReportQuery(
        `/reports/completed-bookings?period=${reportPeriod}`
    );

    // const handleGroupBy = (value: any) => {
    //     setGroupBy(value);
    // };

    const handleSearchValueChange = (event: { target: { value: any } }) => {
        const newSearchValue = event.target.value;
        setSearchValue(newSearchValue);
        updateSearchValue(event.target.value);
    };

    const updateSearchValue = React.useCallback(
        (newSearchValue: any) => {
            reportExportAPI?.search(quickFilterParser(newSearchValue));
        },
        [reportExportAPI, quickFilterParser]
    );

    const handleReportPeriod = (value: string) => {
        setReportPeriod(value);
    };

    return (
        <Grid sx={{ Top: isMobile ? '30px' : '0px' }}>
            <Grid
                sx={{
                    textAlign: isMobile ? 'left' : 'right',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'left',
                        // backgroundColor: 'yellow',
                    }}
                >
                    <Grid
                        sx={{
                            width: 'auto',
                            // backgroundColor: 'blue',
                            marginRight: isMobile ? '0px' : '15px',
                        }}
                    >
                        <DropdownFilter
                            id={'fyReport'}
                            options={[
                                // { value: 'All', label: 'All' },
                                {
                                    value: 'prev-fy',
                                    label: 'Previous FY',
                                },
                                {
                                    value: 'current-fy',
                                    label: 'Current FY',
                                },
                            ]}
                     
                            handleChange={handleReportPeriod}
                            selectedValue={
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '16px',
                                            marginRight: '5px',
                                        }}
                                    >
                                        Reporting period:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {reportPeriod === 'prev-fy'
                                            ? 'Previous FY'
                                            : 'Current FY'}
                                    </Typography>
                                </Grid>
                            }
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                            minHeight={'30px'}
                            maxHeight={'33px'}
                        />
                    </Grid>

                    {checked === false ? (
                        <Grid
                            sx={{
                                width: 'auto',
                                marginTop: isMobile ? '10px' : '0px',
                            }}
                        >
                            <CustomTextField
                                fullWidth={isMobile}
                                color='neutral'
                                placeholder='Search...'
                                onChange={handleSearchValueChange}
                                InputProps={{
                                    style: {
                                        minHeight: '30px',
                                        maxHeight: '33px',
                                    },
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton disableRipple>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    ) : null}

                    {/* <Grid sx={{ height: '100%' }}></Grid> */}
                </Grid>

                <Grid
                    sx={{
                        width: isMobile ? '100%' : '50%',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? 'left' : 'right',
                        justifyContent: isMobile ? 'flex-start' : 'flex-end',
                        paddingTop: isMobile ? '10px' : '0px',
                    }}
                >
                    <Grid
                        sx={{
                            width: 'auto',
                            marginRight: isMobile ? '0px' : '15px',
                        }}
                    >
                        {checked === true ? (
                            <CustomButton
                                disableRipple
                                variant='contained'
                                color='green'
                                onClick={() => graphView(false)}
                                sx={{ marginRight: '5px' }}
                            >
                                <FormatListBulletedIcon fontSize='small' />
                            </CustomButton>
                        ) : (
                            <CustomButton
                                disableRipple
                                variant='contained'
                                color='green'
                                onClick={() => graphView(true)}
                                sx={{ marginRight: '5px' }}
                            >
                                <EqualizerIcon fontSize='small' />
                            </CustomButton>
                        )}
                        {checked === false ? (
                            <>
                                {' '}
                                {/* <CustomButton
                                    disableRipple
                                    variant='contained'
                                    color='neutral'
                                    onClick={() =>
                                        reportExportAPI?.printExport()
                                    }
                                    sx={{ marginRight: '5px' }}
                                >
                                    <PrintIcon fontSize='small' />{' '}
                                </CustomButton> */}
                                <CustomButton
                                    disableRipple
                                    variant='contained'
                                    color='neutral'
                                    onClick={() =>
                                        reportExportAPI?.csvExport({
                                            fileName:
                                                `${reportPeriod} Completed Reservations Report`,
                                            columnsStyles: {
                                                createdAt: {
                                                    numFmt: 'dd/mm/yyyy',
                                                },
                                            },
                                            hideFooter: true,
                                            hideToolbar: true,
                                            // pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { fontSize: 50px}',
                                        })
                                    }
                                    sx={{ marginRight: '5px' }}
                                >
                                    <FileDownloadIcon fontSize='small' />{' '}
                                </CustomButton>
                            </>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                {checked === true ? (
                    <FYReportGraph data={getReport} />
                ) : (
                    <FYListReport data={getReport} />
                )}
            </Grid>
        </Grid>
    );
}

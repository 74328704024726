import SendIcon from '@mui/icons-material/Send';
import {
    Box,
    Grid,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
    useAiDiscountsRequestMutation,
    useGetAIThreadIdMutation,
    useSubmitDiscountMutation,
} from '../../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../../../layout/styledcomponents/CustomTextField';
import UserRole from '../../../../../shared/AuthRoles';
import toast from '../../../../shared/Toast';
import { handleHttpError } from '../../../../../shared/utils';

export default function AIDiscountsAssistant(props: any) {
    const { control } = useFormContext();

    const { append, remove } = useFieldArray({
        control,
        name: 'discounts',
    });

    const handleRemove = (index: number) => {
        remove(index);
    };

    let newExperienceDiscounts = props?.newExperienceDiscounts;
    const urlRef = window.location.href.split('/')[4];
    // Create a ref for the messages container
    const messagesEndRef = useRef<any>(null);
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [submitDiscount] = useSubmitDiscountMutation();
    const loggedInUser = UserRole();
    const dispatch = useDispatch();

    //------//
    const [moduleId, setModuleId] = useState<Number | undefined>();

    // const [experienceName, setExperienceName] = useState(undefined);
    const [requestInput, setRequestInput] = useState('');
    const [pendingMessage, setPendingMessage] = useState('');
    const [requestSent, setRequestSent] = useState(false);
    const [appliedDiscount, setAppliedDiscount] = useState(false);
    const [nextPageFn, setNextPageFn] = useState<() => void>();

    // const self = loggedInUser?.sub.toString() === creatorId;
    //------//
    //API Calls
    const [threadId]: any = useGetAIThreadIdMutation();
    const [aiDiscountsRequest, { isLoading }]: any =
        useAiDiscountsRequestMutation();
    //------//
    //AI Response saved in state
    const [aiThreadId, setAiThreadId] = useState();
    const [aiResponse, setAiResponse]: any = useState();
    const [aiDates, setAiDates]: any = useState();

    //Keep history of messages to display in chat
    const [messages, setMessages] = useState<any>([]);
    const [isTyping, setIsTyping] = useState(false);

    //Checking what page, Reservations or from the experience to obtain moduleId if there is one
    useEffect(() => {
        setNextPageFn(undefined); // Reset nextPageFn when channel changes
        let moduleId = parseInt(urlRef ? urlRef : '0');
        setModuleId(moduleId);

        //initiate AI threadID
        getThreadId();
        setIsTyping(true);
        chatInitiated();
    }, [urlRef]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const chatInitiated = () => {
        let message = {
            message: `Hello, can you tell us the details for the discount you'd like to offer on this experience?`,
            sentTime: 'just now',
            sender: '7DAY Assistant',
        };

        setTimeout(function () {
            setMessages(() => [message]);
            setIsTyping(false);
        }, 2000);
    };

    const getThreadId = () => {
        //AI thread ID stored in the state to send in the submit post
        threadId().then((data: any) => {
            setAiThreadId(data?.data?.id);
        });
    };
    const handleInput = (event: { target: { value: string } }) => {
        //Save the input
        let message = event?.target?.value;
        setPendingMessage(message);
        setRequestInput(message);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent default form behavior
            onSubmit(); // Trigger the submit action
        }
    };

    const onSubmit = () => {
        setRequestSent(true);
        setPendingMessage('');
        //check if moduleId is undefined (if undefined the user has come from the /Reservation page and not the experience)
        let message = requestInput;
        const newMessage = {
            message,
            direction: 'outgoing',
            sender: loggedInUser?.sub.toString(),
        };
        setMessages((prevMessages: any) => [...prevMessages, newMessage]);
        setIsTyping(true);
        // const element: any = document.getElementById('messages');
        // element.scroll({
        //     top: 100,
        //     left: 100,
        //     behavior: 'smooth',
        // });

        let requestBody = {};
        if (moduleId === undefined || moduleId === 0) {
            requestBody = {
                threadId: aiThreadId,
                message: requestInput,
                newModule: true,
            };
        } else {
            requestBody = {
                threadId: aiThreadId,
                message: requestInput,
                moduleId: moduleId,
                newModule: false,
            };
        }

        //Submit users request to AI. If data, display it to the user, if not initate the AI chat to get more information.
        aiDiscountsRequest(requestBody).then((data: any) => {
            setIsTyping(true);
            if (data) {
                //set the response to show the user in list format
                if (data?.error?.status === 400) {
                    //Here is where we will create the AI assistant Chat
                    setIsTyping(false);
                    let message = data?.error?.data.message;
                    const newMessage = {
                        message,
                        direction: 'just now',
                        sender: '7DAY Assistant',
                    };
                    setMessages((prevMessages: any) => [
                        ...prevMessages,
                        newMessage,
                    ]);
                    // window.scrollTo(0, 500);
                } else {
                    setAiResponse(data?.data);
                    setAiDates(data?.data?.dates);
                    setIsTyping(false);
                }
            } else {
                setIsTyping(false);
            }
        });
    };

    const clearForm = () => {
        //clear the form if the user wants to start again
        setAppliedDiscount(false);
        setRequestSent(false);
        setAiResponse();
        setRequestInput('');
        let message = {
            message: `Hello, can you tell us the details for the discount you'd like to offer on this experience?`,
            sentTime: 'just now',
            sender: '7DAY Assistant',
        };

        setMessages(() => [message]);

        //Get new thread id
        getThreadId();
    };

    const onClickRequest = () => {
        //if user is okay with response, submit Reservation
        setAppliedDiscount(true);
        let formatDates = aiDates?.map((i: any) => {
            return {
                startDate: moment(i.startDate).format('YYYY-MM-DD'),
                endDate: moment(i.endDate).format('YYYY-MM-DD'),
            };
        });
        let requestBody = {};
        if (moduleId === undefined || moduleId === 0) {
            requestBody = {
                percentage: aiResponse?.percentage,
                name: aiResponse?.name,
                dates: formatDates,
                disabled: false,
                id: 0,
            };
        } else {
            requestBody = {
                module: {
                    id: aiResponse?.module?.id,
                },
                percentage: aiResponse?.percentage,
                name: aiResponse?.name,
                dates: formatDates,
                disabled: false,
            };
        }

        if (!(moduleId === undefined || moduleId === 0)) {
            submitDiscount([loggedInUser?.company, requestBody]).then(
                (data: any) => {
                    if (data) {
                        if (data?.error) {
                            const errorMessage = handleHttpError(data?.error);
                            toast.error(errorMessage);
                        } else {
                            toast.success(`Discount Applied`);
                            append(data.data);
                        }
                    } else {
                        toast.error(`Failed to submit Discount`);
                    }
                }
            );
        } else {
            append(requestBody);
        }
    };

    const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if (!nextPageFn) return;

        const el = e.currentTarget;

        const top = el.scrollHeight - el.clientHeight;
        const scroll = -el.scrollTop; // reversed because of the flex direction

        if (top - scroll <= 1) {
            nextPageFn();
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                height: '50vh',
                // maxHeight: '80vh',
                minWidth: isMobile ? '70vw' : '40vw',
                maxWidth: isMobile ? '70vw' : '40vw',
            }}
        >
            {aiResponse === undefined ? (
                <Grid
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Stack
                        overflow='scroll'
                        spacing={2}
                        p={3}
                        height='900px'
                        flex={1}
                        onScroll={onScroll}
                        // hide scrollbar
                        sx={{
                            paddingTop: '50px',
                            overflowY: 'scroll',
                            '-ms-overflow-style': 'none',
                            '::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        {messages?.map((message: any, i: any) => {
                            return (
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='fit-content'
                                    maxWidth='90%'
                                    alignSelf={
                                        loggedInUser?.sub.toString() ===
                                        message?.sender
                                            ? 'flex-end'
                                            : 'flex-start'
                                    }
                                    alignItems={
                                        loggedInUser?.sub.toString() ===
                                        message?.sender
                                            ? 'flex-end'
                                            : 'flex-start'
                                    }
                                >
                                    <Box
                                        id='messages'
                                        bgcolor={
                                            loggedInUser?.sub.toString() ===
                                            message?.sender
                                                ? '#5cb5b3'
                                                : '#f6f6f6'
                                        }
                                        borderRadius={2}
                                        p={2}
                                        color={
                                            loggedInUser?.sub.toString() ===
                                            message?.sender
                                                ? 'white'
                                                : 'black'
                                        }
                                    >
                                        <Typography
                                            color={
                                                loggedInUser?.sub.toString() ===
                                                message?.sender
                                                    ? 'white'
                                                    : '#787878'
                                            }
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                whiteSpace: 'pre-line',
                                            }}
                                        >
                                            {' '}
                                            {message.message}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant='subtitle2'
                                        color='textSecondary'
                                        sx={{
                                            fontSize: '10px',
                                            padding: '5px',
                                        }}
                                    >
                                        {message?.sender === '7DAY Assistant'
                                            ? message?.sender
                                            : 'You'}
                                    </Typography>
                                </Box>
                            );
                        })}

                        {isTyping ? (
                            <Box
                                display='flex'
                                flexDirection='row'
                                width='fit-content'
                                maxWidth='90%'
                                alignSelf={'flex-start'}
                                alignItems={'flex-start'}
                                gap={0.3}
                                pt={1}
                            >
                                {' '}
                                <Skeleton
                                    variant='circular'
                                    width={8}
                                    height={8}
                                    sx={{ bgcolor: '#787878' }}
                                />
                                <Skeleton
                                    variant='circular'
                                    width={8}
                                    height={8}
                                    sx={{ bgcolor: '#787878' }}
                                />
                                <Skeleton
                                    variant='circular'
                                    width={8}
                                    height={8}
                                    sx={{ bgcolor: '#787878' }}
                                />
                            </Box>
                        ) : null}

                        {/* Reference for scrolling */}
                        <div ref={messagesEndRef} />
                    </Stack>

                    <Box
                        display='flex'
                        gap={1}
                        pt={2}
                        // position='fixed'
                        sx={{
                            // left: 240,
                            // bottom: 40,
                            // right: 240,
                            backgroundColor: 'white',
                            width: '100%',
                        }}
                    >
                        <Grid
                            sx={{
                                width: '95%',
                            }}
                        >
                            {' '}
                            <CustomTextField
                                type='text'
                                onKeyDown={handleKeyDown}
                                placeholder={
                                    'i.e Discount 50% for the month of December 2026'
                                }
                                fullWidth
                                color='neutral'
                                onChange={handleInput}
                                value={pendingMessage}
                                autoComplete='off'
                                inputProps={{
                                    color: '#787878',
                                    backgroundColor: 'white',
                                    style: {
                                        color: '#787878',
                                        backgroundColor: 'white',
                                        fontSize: isMobile ? '9px' : '',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid sx={{ width: 'auto' }}>
                            {' '}
                            <CustomButton
                                size='small'
                                color='green'
                                variant='contained'
                                onClick={onSubmit}
                                disabled={isLoading}
                                disableElevation
                                disableRipple
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: 'white',
                                        backgroundColor: '#787878',
                                    },
                                }}
                            >
                                <SendIcon />
                            </CustomButton>
                        </Grid>
                    </Box>
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '30px',
                        paddingBottom: '30px',
                    }}
                >
                    <Grid sx={{ height: '80%' }}>
                        {aiDates ? (
                            <>
                                {isMobile ? (
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {moduleId === undefined ||
                                        moduleId === 0 ? null : (
                                            <li>
                                                Experience:{' '}
                                                {aiResponse?.module?.name} - (
                                                {aiResponse?.module?.type})
                                            </li>
                                        )}
                                        <li>
                                            Discount: {aiResponse?.percentage}%
                                        </li>
                                        <li>
                                            Period:{' '}
                                            {aiDates?.length === 1
                                                ? `${moment(
                                                      aiDates?.[0].startDate
                                                  ).format(
                                                      'DD/MM/YYYY'
                                                  )} - ${moment(
                                                      aiDates?.[0].endDate
                                                  ).format('DD/MM/YYYY')}`
                                                : aiDates?.map((i: any) => {
                                                      return (
                                                          <li>
                                                              {moment(
                                                                  i?.startDate
                                                              ).format(
                                                                  'DD/MM/YYYY'
                                                              )}{' '}
                                                              -{' '}
                                                              {moment(
                                                                  i?.endDate
                                                              ).format(
                                                                  'DD/MM/YYYY'
                                                              )}
                                                          </li>
                                                      );
                                                  })}
                                        </li>
                                        <li>
                                            Discount Ref: {aiResponse?.name}
                                        </li>
                                    </Typography>
                                ) : (
                                    <TableContainer>
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            aria-label='simple table'
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {moduleId === undefined ||
                                                    moduleId === 0 ? null : (
                                                        <TableCell>
                                                            <Typography
                                                                sx={{
                                                                    fontSize:
                                                                        '12px',
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                Experience
                                                            </Typography>
                                                        </TableCell>
                                                    )}

                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            Discount %
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            Period
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            Discount Ref
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow
                                                    key={aiResponse?.module?.id}
                                                >
                                                    {moduleId === undefined ||
                                                    moduleId === 0 ? null : (
                                                        <TableCell>
                                                            <Typography
                                                                sx={{
                                                                    fontSize:
                                                                        '14px',
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                }}
                                                            >
                                                                {
                                                                    aiResponse
                                                                        ?.module
                                                                        ?.name
                                                                }{' '}
                                                                - (
                                                                {
                                                                    aiResponse
                                                                        ?.module
                                                                        ?.type
                                                                }
                                                                )
                                                            </Typography>
                                                        </TableCell>
                                                    )}

                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            {
                                                                aiResponse?.percentage
                                                            }
                                                            %
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            {aiDates?.length ===
                                                            1
                                                                ? `${moment(
                                                                      aiDates?.[0]
                                                                          .startDate
                                                                  ).format(
                                                                      'DD/MM/YYYY'
                                                                  )} - ${moment(
                                                                      aiDates?.[0]
                                                                          .endDate
                                                                  ).format(
                                                                      'DD/MM/YYYY'
                                                                  )}`
                                                                : aiDates?.map(
                                                                      (
                                                                          i: any
                                                                      ) => {
                                                                          return (
                                                                              <li>
                                                                                  {moment(
                                                                                      i?.startDate
                                                                                  ).format(
                                                                                      'DD/MM/YYYY'
                                                                                  )}{' '}
                                                                                  -{' '}
                                                                                  {moment(
                                                                                      i?.endDate
                                                                                  ).format(
                                                                                      'DD/MM/YYYY'
                                                                                  )}
                                                                              </li>
                                                                          );
                                                                      }
                                                                  )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                fontSize:
                                                                    '12px',
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            {aiResponse?.name}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </>
                        ) : (
                            'Please try again'
                        )}
                        <Grid
                            sx={{
                                display: 'flex',
                                width: '100%',
                                paddingTop: '50px',
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    width: '50%',
                                }}
                            >
                                <CustomButton
                                    id='resetForm'
                                    variant='outlined'
                                    color='green'
                                    onClick={() => clearForm()}
                                >
                                    {appliedDiscount
                                        ? isMobile
                                            ? 'Add More'
                                            : 'Add another discount'
                                        : ' Start Over'}
                                </CustomButton>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '50%',
                                }}
                            >
                                <CustomButton
                                    id='submitAiDiscount'
                                    variant='contained'
                                    color='green'
                                    // loading={isLoading}
                                    disabled={isLoading || appliedDiscount}
                                    onClick={() => onClickRequest()}
                                    // startIcon={<AddCircleIcon />}
                                >
                                    {isMobile ? 'Apply' : 'Apply Discount'}
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

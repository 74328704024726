import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface ExperienceSearchState {
    search: string;
}

const initialState: ExperienceSearchState = {
    search: '',
};

const experienceSearchSlice = createSlice({
    name: 'experienceSearch',
    initialState,
    reducers: {
        setExperienceSearchFilter: (state, action: PayloadAction<string>) => {
            state.search = action.payload;
        },
        resetExperienceSearchFilter: (state) => {
            state.search = initialState.search;
        },
    },
});

export const { setExperienceSearchFilter, resetExperienceSearchFilter } =
    experienceSearchSlice.actions;
export const selectExperienceSearchFilter = (state: RootState) =>
    state.experienceSearch.search;
export const experienceSearchReducer = experienceSearchSlice.reducer;

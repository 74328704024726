export const TimeArray = [
    { value: '06:00', label: '6:00AM' },
    { value: '06:30', label: '6:30AM' },
    { value: '07:00', label: '7:00AM' },
    { value: '07:30', label: '7:30AM' },
    { value: '08:00', label: '8:00AM' },
    { value: '08:30', label: '8:30AM' },
    { value: '09:00', label: '9:00AM' },
    { value: '09:30', label: '9:30AM' },
    { value: '10:00', label: '10:00AM' },
    { value: '10:30', label: '10:30AM' },
    { value: '11:00', label: '11:00AM' },
    { value: '11:30', label: '11:30AM' },
    { value: '12:00', label: '12:00PM' },
    { value: '12:30', label: '12:30PM' },
    { value: '13:00', label: '1:00PM' },
    { value: '13:30', label: '1:30PM' },
    { value: '14:00', label: '2:00PM' },
    { value: '14:30', label: '2:30PM' },
    { value: '15:00', label: '3:00PM' },
    { value: '15:30', label: '3:30PM' },
    { value: '16:00', label: '4:00PM' },
    { value: '16:30', label: '4:30PM' },
    { value: '17:00', label: '5:00PM' },
    { value: '17:30', label: '5:30PM' },
    { value: '18:00', label: '6:00PM' },
    { value: '18:30', label: '6:30PM' },
    { value: '19:00', label: '7:00PM' },
    { value: '19:30', label: '7:30PM' },
    { value: '20:00', label: '8:00PM' },
    { value: '20:30', label: '8:30PM' },
    { value: '21:00', label: '9:00PM' },
    { value: '21:30', label: '9:30PM' },
    { value: '22:00', label: '10:00PM' },
    { value: '22:30', label: '10:30PM' },
    { value: '23:00', label: '11:00PM' },
    { value: '23:30', label: '11:30PM' },
    { value: '24:00', label: '12:00AM' }
    // { value: '00:00', label: '12:00AM' },
    // { value: '02:00', label: '2:00AM' },
    // { value: '03:00', label: '3:00AM' },
    // { value: '04:00', label: '4:00AM' },
    // { value: '05:00', label: '5:00AM' },
];

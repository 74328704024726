import { Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import {
    getReadableReportDescription,
    getReadableReportName,
    getReportIcon,
} from './ReportsMapping';
import UserRole from '../../../shared/AuthRoles';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function ReportsComponent() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isLaptop = useMediaQuery('(max-width: 1550px)');
    const loggedInUser = UserRole();

    const ownerReports = [
        'topGuests',
        'topExperiences',
        'guestDetails',
        'cancellationReport',
        'reservationsReport',
        'monthlyReport',
        'quarterlyReport',
        'ytdReport',
        'fyReport',
    ];

    const guestAdminReports = [
        'topBookers',
        'bookerDetails',
        'cancellationReport',
        'reservationsReport',
        'monthlyReport',
        'quarterlyReport',
        'ytdReport',
        'fyReport',
    ];
    const guestUserReports = [
        'reservationsReport',
        'monthlyReport',
        'quarterlyReport',
        'ytdReport',
        'fyReport',
    ];

    const adminsReports = [
        'reservationsReport',
        'cancellationReport',
        'declinedReport',
        'guestDetails',
        'monthlyReport',
        'topGuests',
        'topExperiences',
        'quarterlyReport',
        'ytdReport',
        'fyReport',
        'hostPayments',
        'guestInvoices',
    ];

    const reports = loggedInUser?.role.includes('owner')
        ? ownerReports
        : loggedInUser?.role === 'guest_admin' ||
          loggedInUser?.role === 'guest_department_admin'
        ? guestAdminReports
        : loggedInUser?.role === 'admin'
        ? adminsReports
        : guestUserReports;

    const navigate = useNavigate();
    const handleOpen = (reportName: any) => {
        navigate(`/reports/${reportName}`);
        ReactGA.send({
            hitType: 'pageview',
            page: `/reports/${reportName}`,
            title: `${reportName} Report`,
        });
    };

    return (
        <Grid
            sx={{ flexGrow: 1, paddingTop: '30px', paddingBottom: '30px' }}
            container
            spacing={2}
        >
            <Grid item xs={12}>
                <Grid
                    container
                    justifyContent={isMobile ? 'center' : 'flex-start'}
                    spacing={isMobile ? 6 : 6}
                >
                    {reports
                        ?.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
                        .map((value) => (
                            <Grid key={value} item>
                                <Paper
                                    sx={{
                                        height: 220,
                                        width: isMobile
                                            ? 345
                                            : isIpad
                                            ? 430
                                            : isLaptop
                                            ? 255
                                            : 305,
                                        '&:hover': {
                                            cursor: 'pointer',
                                            backgroundColor: '#f6f8f9',
                                        },
                                        '&:focus': {
                                            cursor: 'pointer',
                                            backgroundColor: '#f6f8f9',
                                        },
                                        // display: 'flex',
                                        // flexDirection: 'column',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#1A2027'
                                                : '#fff',
                                    }}
                                    onClick={() => handleOpen(value)}
                                >
                                    <Grid
                                        sx={{
                                            // backgroundColor: 'red',
                                            height: '100%',
                                            // display: 'flex',
                                            flexDirection: 'column',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            textAlignVertical: 'center',
                                            alignContent: 'center',
                                            alignSelf: 'center',
                                            padding: '15px',
                                        }}
                                    >
                                        <Grid
                                            sx={{
                                                // backgroundColor: 'blue',
                                                height: 'auto',
                                            }}
                                        >
                                            <Typography
                                                component='h1'
                                                variant='h5'
                                                color='#5cb5b3'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: '70px',
                                                }}
                                            >
                                                {getReportIcon('icon', value)}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            sx={{
                                                // backgroundColor: 'purple',
                                                height: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            <Typography
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {' '}
                                                {getReadableReportName(
                                                    'type',
                                                    value
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            sx={{
                                                height: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            <Typography
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {' '}
                                                {getReadableReportDescription(
                                                    'type',
                                                    value
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

import React, { useEffect, useState } from 'react';

import { Refresh, ZoomIn, ZoomOut } from '@mui/icons-material';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slider,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Cropper from 'react-easy-crop';
import { useCreateProfilePictureMutation } from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../../layout/styledcomponents/CustomIconButton';
import toast from '../../../shared/Toast';
import { Photo } from '../../../types/user';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: Photo) => void;
    photo?: File;
}

interface Area {
    x: number; // x/y are the coordinates of the top/left corner of the cropped area
    y: number;
    width: number; // width of the cropped area
    height: number; // height of the cropped area
}

const ProfilePictureModal = ({
    open,
    onClose,
    onSubmit,
    photo,
}: ModalProps) => {
    const [createProfilePicture] = useCreateProfilePictureMutation();

    const [url, setUrl] = useState<string>('');

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [cropDimensions, setCropDimensions] = useState<Area>();
    const [zoom, setZoom] = useState(1);

    const isMobile = useMediaQuery('(max-width: 770px)');

    const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
        setCropDimensions(croppedAreaPixels);
    };

    const cropPhoto = async (photo: File, cropDimensions: Area) => {
        return new Promise<File>((resolve, reject) => {
            const image = new Image();
            image.src = URL.createObjectURL(photo);
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                if (!ctx) {
                    toast.error('Error updating profile picture.');
                    return;
                }

                canvas.width = cropDimensions.width;
                canvas.height = cropDimensions.height;
                ctx.drawImage(
                    image,
                    cropDimensions.x,
                    cropDimensions.y,
                    cropDimensions.width,
                    cropDimensions.height,
                    0,
                    0,
                    cropDimensions.width,
                    cropDimensions.height
                );
                canvas.toBlob((blob) => {
                    if (!blob) {
                        toast.error('Error updating profile picture.');
                        return;
                    }
                    resolve(
                        new File([blob], `cropped-${photo.name}`, {
                            type: blob.type,
                        })
                    );
                }, photo.type);
            };
        });
    };

    const handleZoom = (_: Event, newValue: number[] | number) => {
        setZoom(newValue as number);
    };

    const handleRefresh = () => {
        setCrop({ x: 0, y: 0 });
        setZoom(1);
    };

    const handleSubmit = async () => {
        if (photo && cropDimensions) {
            const croppedPhoto = await cropPhoto(photo, cropDimensions);

            const form = new FormData();
            form.append('file', croppedPhoto);

            createProfilePicture(form).then((data: any) => {
                if (data.error) {
                    toast.error('Error uploading profile picture.');
                } else {
                    onSubmit(data.data);
                }
            });

            onClose();
        }
    };

    const handleClose = () => {
        // reset variables
        setUrl('');
        handleRefresh();

        onClose();
    };

    useEffect(() => {
        if (photo) {
            setUrl(URL.createObjectURL(photo));
        }
    }, [photo]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Typography variant='h6' fontFamily='Open Sans' color='#787878'>
                    Upload Profile Picture
                </Typography>
            </DialogTitle>
            <Box height='300px' position='relative'>
                <Cropper
                    cropShape='round'
                    image={url}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </Box>
            <DialogContent dividers>
                <Typography
                    variant='subtitle1'
                    fontFamily='Open Sans'
                    color='#787878'
                >
                    Please ensure your face is clearly visible and centered
                    within the frame.
                </Typography>
            </DialogContent>
            <DialogActions sx={{ bgcolor: '#eeeeee' }}>
                {!isMobile && (
                    <>
                        <ZoomOut
                            fontSize='small'
                            sx={{ color: '#787878', mr: '8px' }}
                        />
                        <Slider
                            value={zoom}
                            min={1}
                            step={0.005}
                            max={3}
                            onChange={handleZoom}
                            sx={{
                                color: '#5cb5b3',
                                mx: '5px',
                            }}
                        />
                        <ZoomIn
                            fontSize='small'
                            sx={{ color: '#787878', ml: 0 }}
                        />
                    </>
                )}
                <CustomIconButton
                    color='green'
                    sx={{ mx: '10px' }}
                    onClick={handleRefresh}
                >
                    <Refresh />
                </CustomIconButton>
                <Box display='flex' gap='10px'>
                    <CustomButton
                        color='neutral'
                        variant='contained'
                        onClick={handleClose}
                    >
                        Abort
                    </CustomButton>
                    <CustomButton
                        color='green'
                        variant='contained'
                        onClick={handleSubmit}
                    >
                        Upload
                    </CustomButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default ProfilePictureModal;

import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
} from '@mui/material';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

import UserRole from '../../../../shared/AuthRoles';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import CancelReservationModal from '../ViewReservations/CancelReservationModal';
import { useState } from 'react';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import React from 'react';
import ShareReservation from '../ViewReservations/ShareReservation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { addDays } from 'date-fns';
import { useGetProfileCompanyInfoQuery } from '../../../../app/Slices/SevenDayApiSlice';
import ReactGA from 'react-ga4';
import { capitalizeName } from '../../../../shared/utils';
import { User } from '../../../types/user';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import DriverReservationDetails from '../ViewReservations/DriverReservationDetails';
import BookingCharges from '../../Bookings/Fees/BookingCharges';
import PaymentHistory from '../../Bookings/Fees/PaymentHistory';

export default function ReservationRequestSubmittedContent(params: any) {
    const [open, setOpen] = useState(false);
    const loggedInUser = UserRole();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 900px)');
    const isLaptop = useMediaQuery('(max-width: 1200px)');
    const [expandedPanel1, setExpandedPanel1] = useState<any>(true);
    const [expandedPanel2, setExpandedPanel2] = useState<any>(true);

    let getReservationDetails = params?.getReservation;
    let getIndividualAsset = params?.experience;

    let hasMassages = getIndividualAsset?.details?.massages;

    let startDate = moment(getReservationDetails?.startDate).format(
        'DD/MM/YYYY'
    );
    let calendarStart = moment(getReservationDetails?.startDate).format(
        'YYYY-MM-DD'
    );
    let calendarEnd = moment(getReservationDetails?.endDate).format(
        'YYYY-MM-DD'
    );

    let endDate = moment(getReservationDetails?.endDate).format('DD/MM/YYYY');

    // let singleDate = moment(getReservationDetails?.startDate).format('DD/MM/YYYY');

    let createdAtDate = moment(getReservationDetails?.createdAtDate).format(
        'DD/MM/YYYY'
    );

    const { data: getCompanyDetails } = useGetProfileCompanyInfoQuery(
        `/owners/${loggedInUser?.company}`
    );

    let todaysDate = moment(new Date()).format('DD/MM/YYYY');
    let dayBeforeStartDate = addDays(
        new Date(getReservationDetails?.startDate),
        -1
    );

    const fullAddress = `${getIndividualAsset?.streetAddress1} ${getIndividualAsset?.streetAddress2} ${getIndividualAsset?.suburb} ${getIndividualAsset?.postcode} ${getIndividualAsset?.state} ${getIndividualAsset?.countryCode}`;

    const fullAddressDropoff = `${getIndividualAsset?.details?.dropoffStreetAddress1} ${getIndividualAsset?.details?.dropoffStreetAddress2} ${getIndividualAsset?.details?.dropoffSuburb} ${getIndividualAsset?.details?.dropoffPostcode} ${getIndividualAsset?.details?.dropoffState} ${getIndividualAsset?.details?.dropoffCountryCode}`;

    const calDescriptionNonVehicle = `${
        getReservationDetails?.bookerType === 'owner' &&
        getReservationDetails?.onBehalf === false
            ? `REF: #${getReservationDetails?.id} - ${window.location.href}[br] [li] Reservation at ${getReservationDetails?.module?.name} [br] [li] Date: ${startDate}-${endDate} [br] [li] Notes: ${getReservationDetails?.notes}`
            : `REF: #${getReservationDetails?.id} - ${window.location.href} [br] [li]  Reservation at ${getReservationDetails?.module?.name} [br] [li] Date: ${startDate}-${endDate} [br] [li] Checkin From: ${getReservationDetails?.bookingInfo?.checkinTime} [br] [li] Reserved By: ${getReservationDetails?.booker?.firstName}
    ${getReservationDetails?.booker?.lastName} -
    ${getReservationDetails?.booker?.email}     [br] [li] Max Guests Allowed: ${getIndividualAsset?.details.maxGuests}[br] [li]  Sleeps: ${getReservationDetails?.details?.overnightGuests}[br] [li] No. Adults Attending: ${getReservationDetails?.bookingInfo?.adults} [br] [li] No. Children Attending: ${getReservationDetails?.bookingInfo?.children} [br] [li]  No. Overnight Guests: ${getReservationDetails?.bookingInfo?.overnightGuests}`
    }`;

    const calDescriptionCommercial = `${
        getReservationDetails?.bookerType === 'owner' &&
        getReservationDetails?.onBehalf === false
            ? `REF: #${getReservationDetails?.id} - ${window.location.href} [br] [li] Booking at ${getReservationDetails?.module?.name} [br] [li] Date: ${startDate}-${endDate} [br] [li] Notes: ${getReservationDetails?.notes}`
            : `REF: #${getReservationDetails?.id} - ${window.location.href} [br] [li] Booking at ${getReservationDetails?.module?.name} [br] [li] Date: ${startDate}-${endDate} [br] [li] Available From: ${getReservationDetails?.bookingInfo?.checkinTime} until ${getReservationDetails?.bookingInfo?.checkoutTime} [br] [li] Booked By: ${getReservationDetails?.booker?.firstName} ${getReservationDetails?.booker?.lastName} - ${getReservationDetails?.booker?.email} [br] ` +
              (getReservationDetails?.bookingInfo?.desks > 0
                  ? `[li] No. Dedicated Desks: ${getReservationDetails?.bookingInfo?.desks} [br] `
                  : '') +
              (getReservationDetails?.bookingInfo?.privateOffices > 0
                  ? `[li] No. Private Offices: ${getReservationDetails?.bookingInfo?.privateOffices} [br] `
                  : '') +
              (getReservationDetails?.bookingInfo?.meetingRooms > 0
                  ? `[li] No. Meeting Rooms: ${getReservationDetails?.bookingInfo?.meetingRooms} [br] `
                  : '') +
              (getReservationDetails?.bookingInfo?.notes
                  ? ` [li] Notes: ${getReservationDetails?.bookingInfo?.notes}`
                  : '')
    }`;

    const calDescriptionActivity = ` REF: #${getReservationDetails?.id} - ${
        window.location.href
    } [br] [li] Reservation at ${
        getReservationDetails?.module?.name
    } [br] [li] Date: ${startDate}  [br] [li] Reserved By: ${
        getReservationDetails?.booker?.firstName
    }
          ${getReservationDetails?.booker?.lastName} -
          ${getReservationDetails?.booker?.email}   ${
        getReservationDetails?.bookerType === 'owner' &&
        getReservationDetails?.onBehalf === false
            ? ''
            : `[br] [li] No Guests Attending: ${getReservationDetails?.bookingInfo?.guests}`
    }  [br] [li] Notes:  ${
        getReservationDetails?.bookerType === 'owner' &&
        getReservationDetails?.onBehalf === false
            ? getReservationDetails?.notes
            : getReservationDetails?.bookingInfo?.notes
    }  `;
    const calDescriptionVehicle = ` REF: #${getReservationDetails?.id} - ${
        window.location.href
    } 
    [br] [li] Reservation ${
        getReservationDetails?.module?.name
    } [br] [li] Date: ${startDate}-${endDate}   [br] [li] Reserved By: ${
        getReservationDetails?.booker?.firstName
    }
          ${getReservationDetails?.booker?.lastName} -
          ${getReservationDetails?.booker?.email}  
            [br] [li] Notes:  ${
                getReservationDetails?.bookerType === 'owner' &&
                getReservationDetails?.onBehalf === false
                    ? getReservationDetails?.notes
                    : getReservationDetails?.bookingInfo?.notes
            }  ${
        !getIndividualAsset?.details?.dropoffLocationSameAsPickup
            ? `[br] [li] Pickup at : ${fullAddress} [br] [li] Dropoff at : ${fullAddressDropoff} `
            : ''
    }  `;

    const editReservation = () => {
        navigate(
            `/editReservation/${getReservationDetails?.module?.name}/${getReservationDetails?.id}`,
            {
                state: {
                    name: 'editReservation',
                },
            }
        );
        ReactGA.event({
            category: 'UI',
            action: `Edit Reservation (Request - ${loggedInUser?.role})`,
            label: `Edit Reservation Clicked (Request - ${loggedInUser?.role})`,
        });
    };

    const onClickOpenModal = () => {
        ReactGA.event({
            category: 'UI',
            action: `Cancel Reservation (Request - ${loggedInUser?.role})`,
            label: `Cancel Reservation Clicked (Request - ${loggedInUser?.role})`,
        });
        setOpen(true);
    };
    const handleChangeModal = () => {
        setOpen(false);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <>
            {!params?.getReservation || !params?.experience ? (
                <Grid
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}

                    sx={{
                        height: isLaptop ? '50vh' : isMobile ? '5px' : '50vh',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '40px',
                    }}
                >
                    <Grid
                        sx={{
                            // backgroundColor: isMobile ? 'pink' : 'gray',
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            // paddingTop: '30px',
                            // width: isIpad ? '80%' : isMobile ? null : '%',
                        }}
                    >
                        <Grid
                            sx={{
                                width: isMobile ? '100%' : '100%',
                                // backgroundColor: 'brown',
                                display: 'flex',
                                flexDirection: 'column',
                                height: isMobile ? 'auto' : '90%',
                            }}
                        >
                            <Grid
                                sx={{
                                    height: 'auto',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // backgroundColor: 'yellow',
                                }}
                            >
                                {' '}
                                <Grid
                                    sx={{
                                        // backgroundColor: 'red',
                                        display: 'flex',
                                        flexDirection: isMobile
                                            ? 'column'
                                            : 'row',
                                        alignItems: isMobile ? '' : 'flex-end',
                                        paddingBottom: '30px',
                                    }}
                                >
                                    {isMobile ? null : (
                                        <Grid
                                            sx={{
                                                width: '50%',
                                                marginLeft: '-5px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            {getReservationDetails?.status ===
                                                'completed' ||
                                            getReservationDetails?.status ===
                                                'cancelled' ||
                                            getReservationDetails?.status ===
                                                'declined' ? null : (
                                                <AddToCalendarButton
                                                    name={`${
                                                        loggedInUser?.role.includes(
                                                            'owner'
                                                        )
                                                            ? `${
                                                                  getReservationDetails
                                                                      ?.guest
                                                                      ?.name
                                                                      ? getReservationDetails
                                                                            ?.guest
                                                                            ?.name
                                                                      : getReservationDetails
                                                                            ?.owner
                                                                            ?.name
                                                              } - ${
                                                                  getReservationDetails
                                                                      ?.module
                                                                      ?.name
                                                              } REF: #${
                                                                  getReservationDetails?.id
                                                              }`
                                                            : ` ${getReservationDetails?.module?.name} REF: #${getReservationDetails?.id}`
                                                    }`}
                                                    label='ADD TO CALENDAR'
                                                    iCalFileName={`${
                                                        loggedInUser?.role.includes(
                                                            'owner'
                                                        )
                                                            ? `${
                                                                  getReservationDetails
                                                                      ?.guest
                                                                      ?.name
                                                                      ? getReservationDetails
                                                                            ?.guest
                                                                            ?.name
                                                                      : getReservationDetails
                                                                            ?.owner
                                                                            ?.name
                                                              } - ${
                                                                  getReservationDetails
                                                                      ?.module
                                                                      ?.name
                                                              }-${
                                                                  getReservationDetails?.status
                                                              } Reservation REF: #${
                                                                  getReservationDetails?.id
                                                              }`
                                                            : `${getReservationDetails?.module?.name}-${getReservationDetails?.status} Reservation REF: #${getReservationDetails?.id}`
                                                    }`}
                                                    description={
                                                        getReservationDetails
                                                            ?.module?.type ===
                                                        'activity'
                                                            ? calDescriptionActivity
                                                            : getReservationDetails
                                                                  ?.module
                                                                  ?.type ===
                                                              'vehicle'
                                                            ? calDescriptionVehicle
                                                            : getReservationDetails
                                                                  ?.module
                                                                  ?.type ===
                                                              'commercial'
                                                            ? calDescriptionCommercial
                                                            : calDescriptionNonVehicle
                                                    }
                                                    // organizer={calOrganiser}
                                                    options={[
                                                        'iCal|Download',
                                                        'Google',
                                                        'Outlook.com',
                                                        'Microsoft365',
                                                        // "Yahoo",
                                                    ]}
                                                    location={fullAddress}
                                                    startDate={calendarStart}
                                                    endDate={calendarEnd}
                                                    startTime={
                                                        getReservationDetails
                                                            ?.bookingInfo
                                                            ?.checkinTime
                                                    }
                                                    endTime={
                                                        getReservationDetails
                                                            ?.bookingInfo
                                                            ?.checkoutTime
                                                    }
                                                    timeZone='Australia/Sydney'
                                                    hideCheckmark
                                                    size='2'
                                                    listStyle='modal'
                                                    lightMode='bodyScheme'
                                                    styleLight='--btn-background: #fff; --btn-text: #5cb5b3; '
                                                    styleDark='--btn-background: #D0342C;'
                                                ></AddToCalendarButton>
                                            )}
                                        </Grid>
                                    )}

                                    <Grid
                                        sx={{
                                            width: isMobile ? '100%' : '50%',
                                            marginLeft: isMobile
                                                ? '-15px'
                                                : '11px',
                                        }}
                                    >
                                        <List>
                                            <ListItem
                                                sx={{
                                                    paddingBottom: isMobile
                                                        ? '0px'
                                                        : '30px',
                                                    textAlign: isMobile
                                                        ? 'left'
                                                        : 'right',
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                paddingTop:
                                                                    '0px',
                                                                // paddingBottom: '30px',
                                                                fontWeight:
                                                                    'bold',
                                                                textTransform:
                                                                    'uppercase',
                                                            }}
                                                        >
                                                            Reservation
                                                            REFERENCE:{' '}
                                                            {
                                                                getReservationDetails?.id
                                                            }
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                        >
                                                            Created{' '}
                                                            {createdAtDate}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        </List>{' '}
                                    </Grid>
                                    {isMobile ? (
                                        <Grid
                                            sx={{
                                                width: '50%',
                                                marginLeft: '-5px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {getReservationDetails?.status ===
                                                'completed' ||
                                            getReservationDetails?.status ===
                                                'cancelled' ||
                                            getReservationDetails?.status ===
                                                'declined' ? null : (
                                                <AddToCalendarButton
                                                    name={`${
                                                        loggedInUser?.role.includes(
                                                            'owner'
                                                        )
                                                            ? `${
                                                                  getReservationDetails
                                                                      ?.guest
                                                                      ?.name
                                                                      ? getReservationDetails
                                                                            ?.guest
                                                                            ?.name
                                                                      : getReservationDetails
                                                                            ?.owner
                                                                            ?.name
                                                              } - ${
                                                                  getReservationDetails
                                                                      ?.module
                                                                      ?.name
                                                              } REF: #${
                                                                  getReservationDetails?.id
                                                              }`
                                                            : ` ${getReservationDetails?.module?.name} REF: #${getReservationDetails?.id}`
                                                    }`}
                                                    label='ADD TO CALENDAR'
                                                    iCalFileName={`${
                                                        loggedInUser?.role.includes(
                                                            'owner'
                                                        )
                                                            ? `${
                                                                  getReservationDetails
                                                                      ?.guest
                                                                      ?.name
                                                                      ? getReservationDetails
                                                                            ?.guest
                                                                            ?.name
                                                                      : getReservationDetails
                                                                            ?.owner
                                                                            ?.name
                                                              } - ${
                                                                  getReservationDetails
                                                                      ?.module
                                                                      ?.name
                                                              }-${
                                                                  getReservationDetails?.status
                                                              } Reservation REF: #${
                                                                  getReservationDetails?.id
                                                              }`
                                                            : `${getReservationDetails?.module?.name}-${getReservationDetails?.status} Reservation REF: #${getReservationDetails?.id}`
                                                    }`}
                                                    description={
                                                        getReservationDetails
                                                            ?.module?.type ===
                                                        'activity'
                                                            ? calDescriptionActivity
                                                            : getReservationDetails
                                                                  ?.module
                                                                  ?.type ===
                                                              'vehicle'
                                                            ? calDescriptionVehicle
                                                            : calDescriptionNonVehicle
                                                    }
                                                    // organizer={calOrganiser}
                                                    options={[
                                                        'iCal|Download',
                                                        'Google',
                                                        'Outlook.com',
                                                        'Microsoft365',
                                                        // "Yahoo",
                                                    ]}
                                                    location={fullAddress}
                                                    startDate={calendarStart}
                                                    endDate={calendarEnd}
                                                    startTime={
                                                        getReservationDetails
                                                            ?.bookingInfo
                                                            ?.checkinTime
                                                    }
                                                    endTime={
                                                        getReservationDetails
                                                            ?.bookingInfo
                                                            ?.checkoutTime
                                                    }
                                                    timeZone='Australia/Sydney'
                                                    hideCheckmark
                                                    size='2'
                                                    listStyle='modal'
                                                    lightMode='bodyScheme'
                                                    styleLight='--btn-background: #fff; --btn-text: #5cb5b3; '
                                                    styleDark='--btn-background: #D0342C;'
                                                ></AddToCalendarButton>
                                            )}
                                        </Grid>
                                    ) : null}
                                </Grid>
                                {isMobile ? (
                                    <>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Date:
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {startDate !== endDate
                                                        ? `${startDate} - ${endDate}`
                                                        : startDate}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {getIndividualAsset?.type ===
                                                    'commercial'
                                                        ? ' Access From:'
                                                        : 'Checkin'}
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {getReservationDetails?.bookerType ===
                                                        'owner' &&
                                                    getReservationDetails?.onBehalf ===
                                                        false
                                                        ? '-'
                                                        : getReservationDetails
                                                              ?.bookingInfo
                                                              ?.checkinTime}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {getIndividualAsset?.type ===
                                                    'commercial'
                                                        ? ' Access To:'
                                                        : 'Checkout:'}
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {getReservationDetails?.bookerType ===
                                                        'owner' &&
                                                    getReservationDetails?.onBehalf ===
                                                        false
                                                        ? '-'
                                                        : getReservationDetails
                                                              ?.bookingInfo
                                                              ?.checkoutTime}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Type:
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {getReservationDetails?.module?.type
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        getReservationDetails?.module?.type.slice(
                                                            1
                                                        )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid sx={{ display: 'flex' }}>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '30%'
                                                        : '20%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Status:
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    width: isMobile
                                                        ? '70%'
                                                        : '80%',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    {getReservationDetails?.status
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        getReservationDetails?.status.slice(
                                                            1
                                                        )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <TableContainer component={Paper}>
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            aria-label='simple table'
                                        >
                                            <TableHead
                                                sx={{
                                                    backgroundColor: '#f6f8f9',
                                                }}
                                            >
                                                <TableRow>
                                                    <TableCell>
                                                        {' '}
                                                        <Typography
                                                            color='#787878'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                fontWeight: 550,
                                                                textTransform:
                                                                    'uppercase',
                                                            }}
                                                        >
                                                            Date
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            color='#787878'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                fontWeight: 550,
                                                                textTransform:
                                                                    'uppercase',
                                                            }}
                                                        >
                                                            {getIndividualAsset?.type ===
                                                            'commercial'
                                                                ? ' Access From:'
                                                                : 'Checkin'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            color='#787878'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                fontWeight: 550,
                                                                textTransform:
                                                                    'uppercase',
                                                            }}
                                                        >
                                                            {getIndividualAsset?.type ===
                                                            'commercial'
                                                                ? ' Access To:'
                                                                : 'Checkout'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            color='#787878'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                fontWeight: 550,
                                                                textTransform:
                                                                    'uppercase',
                                                            }}
                                                        >
                                                            Type
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            color='#787878'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                fontWeight: 550,
                                                                textTransform:
                                                                    'uppercase',
                                                            }}
                                                        >
                                                            Status
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow
                                                    sx={{
                                                        '&:last-child td, &:last-child th':
                                                            { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        component='th'
                                                        scope='row'
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            {startDate !==
                                                            endDate
                                                                ? `${startDate} - ${endDate}`
                                                                : startDate}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            {getReservationDetails?.bookerType ===
                                                                'owner' &&
                                                            getReservationDetails?.onBehalf ===
                                                                false
                                                                ? '-'
                                                                : getReservationDetails
                                                                      ?.bookingInfo
                                                                      ?.checkinTime}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            {getReservationDetails?.bookerType ===
                                                                'owner' &&
                                                            getReservationDetails?.onBehalf ===
                                                                false
                                                                ? '-'
                                                                : getReservationDetails
                                                                      ?.bookingInfo
                                                                      ?.checkoutTime}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            {capitalizeName(
                                                                getReservationDetails
                                                                    ?.module
                                                                    ?.type
                                                            )}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align='left'>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                                color: '#787878',
                                                            }}
                                                        >
                                                            {getReservationDetails?.status
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                                getReservationDetails?.status.slice(
                                                                    1
                                                                )}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                                {/* Accordion GRID */}
                                <Grid sx={{ marginTop: '30px' }}>
                                    <Accordion
                                        expanded={expandedPanel1}
                                        onChange={() =>
                                            setExpandedPanel1(!expandedPanel1)
                                        }
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls='panel1a-content'
                                            id='panel1a-header'
                                            sx={{
                                                backgroundColor:
                                                    expandedPanel1 === false
                                                        ? '#f6f8f9'
                                                        : '',
                                            }}
                                        >
                                            <Typography
                                                component='h3'
                                                variant='h6'
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 550,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Reservation Details
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <>
                                                <Grid>
                                                    <Divider
                                                        sx={{
                                                            marginTop: '0px',
                                                        }}
                                                    />
                                                    <Grid
                                                        sx={{
                                                            display: 'flex',
                                                            paddingTop: '10px',
                                                        }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '30%'
                                                                    : '20%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                Experience:
                                                            </Typography>{' '}
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '70%'
                                                                    : '80%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                {
                                                                    getReservationDetails
                                                                        ?.module
                                                                        ?.name
                                                                }{' '}
                                                                - hosted by{' '}
                                                                {
                                                                    getReservationDetails
                                                                        ?.owner
                                                                        ?.name
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        sx={{ display: 'flex' }}
                                                    >
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '30%'
                                                                    : '20%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                {getReservationDetails
                                                                    ?.module
                                                                    ?.type ===
                                                                'vehicle'
                                                                    ? 'Pickup Location:'
                                                                    : 'Location:'}
                                                            </Typography>{' '}
                                                        </Grid>
                                                        <Grid
                                                            sx={{
                                                                width: isMobile
                                                                    ? '70%'
                                                                    : '80%',
                                                            }}
                                                        >
                                                            {' '}
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                <Link
                                                                    style={{
                                                                        color: '#787878',
                                                                    }}
                                                                    target='_blank'
                                                                    to={`https://www.google.com/maps/place/${fullAddress}`}
                                                                >
                                                                    {' '}
                                                                    {
                                                                        fullAddress
                                                                    }
                                                                </Link>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {getIndividualAsset?.type ===
                                                        'vehicle' && (
                                                        <Grid>
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        Dropoff
                                                                        Location:
                                                                    </Typography>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        <Link
                                                                            style={{
                                                                                color: '#787878',
                                                                            }}
                                                                            target='_blank'
                                                                            to={
                                                                                getIndividualAsset
                                                                                    ?.details
                                                                                    ?.dropoffLocationSameAsPickup
                                                                                    ? `https://www.google.com/maps/place/${fullAddress}`
                                                                                    : `https://www.google.com/maps/place/${fullAddressDropoff}`
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            {getIndividualAsset
                                                                                ?.details
                                                                                ?.dropoffLocationSameAsPickup
                                                                                ? 'Same as above'
                                                                                : fullAddressDropoff}
                                                                        </Link>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            {(getReservationDetails?.onBehalf ||
                                                                getReservationDetails?.bookerType !==
                                                                    'owner') && (
                                                                <DriverReservationDetails
                                                                    drivers={
                                                                        getReservationDetails
                                                                            ?.bookingInfo
                                                                            ?.drivers
                                                                    }
                                                                />
                                                            )}
                                                        </Grid>
                                                    )}
                                                    {getReservationDetails?.bookerType.includes(
                                                        'owner'
                                                    ) ? (
                                                        <>
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        Company:
                                                                    </Typography>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {getReservationDetails
                                                                            ?.guest
                                                                            ?.name
                                                                            ? getReservationDetails
                                                                                  ?.guest
                                                                                  ?.name
                                                                            : getReservationDetails
                                                                                  ?.bookingInfo
                                                                                  ?.visitType
                                                                            ? `Reservation on behalf of ${getReservationDetails?.owner?.name}`
                                                                            : `Blocked by ${getReservationDetails?.owner?.name}`}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        Reserved
                                                                        By:
                                                                    </Typography>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.firstName
                                                                        }{' '}
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.lastName
                                                                        }{' '}
                                                                        -{' '}
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.email
                                                                        }{' '}
                                                                        (
                                                                        {
                                                                            getReservationDetails
                                                                                ?.owner
                                                                                .name
                                                                        }
                                                                        )
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            {getReservationDetails?.onBehalf ===
                                                            false ? (
                                                                <Grid
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '30%'
                                                                                : '20%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontWeight:
                                                                                    'bold',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            Reservation
                                                                            Reason:
                                                                        </Typography>{' '}
                                                                    </Grid>
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '70%'
                                                                                : '80%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            {getReservationDetails?.reason
                                                                                .replace(
                                                                                    /([A-Z])/g,
                                                                                    ' $1'
                                                                                )
                                                                                .replace(
                                                                                    /^./,
                                                                                    function (
                                                                                        str: string
                                                                                    ) {
                                                                                        return str.toUpperCase();
                                                                                    }
                                                                                )}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            ) : (
                                                                <>
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                Type
                                                                                of
                                                                                visit:
                                                                            </Typography>{' '}
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {getReservationDetails?.bookingInfo?.visitType
                                                                                    ?.charAt(
                                                                                        0
                                                                                    )
                                                                                    ?.toUpperCase() +
                                                                                    getReservationDetails?.bookingInfo?.visitType.slice(
                                                                                        1
                                                                                    )}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {getReservationDetails
                                                                        ?.module
                                                                        ?.type ===
                                                                    'activity' ? (
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    No
                                                                                    Guests:
                                                                                </Typography>{' '}
                                                                            </Grid>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        getReservationDetails
                                                                                            ?.bookingInfo
                                                                                            ?.guests
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ) : getReservationDetails
                                                                          ?.module
                                                                          ?.type ===
                                                                      'vehicle' ? null : getReservationDetails
                                                                          ?.module
                                                                          ?.type ===
                                                                      'commercial' ? (
                                                                        <>
                                                                            <Grid
                                                                                sx={{
                                                                                    display:
                                                                                        'flex',
                                                                                }}
                                                                            >
                                                                                {getReservationDetails
                                                                                    ?.bookingInfo
                                                                                    ?.desks !==
                                                                                    0 && (
                                                                                    <>
                                                                                        <Grid
                                                                                            sx={{
                                                                                                width: isMobile
                                                                                                    ? '30%'
                                                                                                    : '20%',
                                                                                            }}
                                                                                        >
                                                                                            {' '}
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    fontFamily:
                                                                                                        'Open Sans',
                                                                                                    color: '#787878',
                                                                                                    paddingBottom:
                                                                                                        '10px',
                                                                                                    fontWeight:
                                                                                                        'bold',
                                                                                                    fontSize:
                                                                                                        '14px',
                                                                                                }}
                                                                                            >
                                                                                                No.
                                                                                                Dedicated
                                                                                                Desks:
                                                                                            </Typography>{' '}
                                                                                        </Grid>
                                                                                        <Grid
                                                                                            sx={{
                                                                                                width: isMobile
                                                                                                    ? '70%'
                                                                                                    : '80%',
                                                                                            }}
                                                                                        >
                                                                                            {' '}
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    fontFamily:
                                                                                                        'Open Sans',
                                                                                                    color: '#787878',
                                                                                                    paddingBottom:
                                                                                                        '10px',
                                                                                                    fontSize:
                                                                                                        '14px',
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    getReservationDetails
                                                                                                        ?.bookingInfo
                                                                                                        ?.desks
                                                                                                }
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </>
                                                                                )}
                                                                                {getReservationDetails
                                                                                    ?.bookingInfo
                                                                                    ?.privateOffices !==
                                                                                    0 && (
                                                                                    <>
                                                                                        <Grid
                                                                                            sx={{
                                                                                                width: isMobile
                                                                                                    ? '30%'
                                                                                                    : '20%',
                                                                                            }}
                                                                                        >
                                                                                            {' '}
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    fontFamily:
                                                                                                        'Open Sans',
                                                                                                    color: '#787878',
                                                                                                    paddingBottom:
                                                                                                        '10px',
                                                                                                    fontWeight:
                                                                                                        'bold',
                                                                                                    fontSize:
                                                                                                        '14px',
                                                                                                }}
                                                                                            >
                                                                                                {getIndividualAsset
                                                                                                    ?.details
                                                                                                    ?.subType ===
                                                                                                'house'
                                                                                                    ? `No. Rooms:`
                                                                                                    : `No. Private Offices:`}
                                                                                            </Typography>{' '}
                                                                                        </Grid>
                                                                                        <Grid
                                                                                            sx={{
                                                                                                width: isMobile
                                                                                                    ? '70%'
                                                                                                    : '80%',
                                                                                            }}
                                                                                        >
                                                                                            {' '}
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    fontFamily:
                                                                                                        'Open Sans',
                                                                                                    color: '#787878',
                                                                                                    paddingBottom:
                                                                                                        '10px',
                                                                                                    fontSize:
                                                                                                        '14px',
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    getReservationDetails
                                                                                                        ?.bookingInfo
                                                                                                        ?.privateOffices
                                                                                                }
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </>
                                                                                )}
                                                                            </Grid>

                                                                            {getReservationDetails
                                                                                ?.bookingInfo
                                                                                ?.meetingRooms !==
                                                                                0 && (
                                                                                <Grid
                                                                                    sx={{
                                                                                        display:
                                                                                            'flex',
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '30%'
                                                                                                : '20%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontWeight:
                                                                                                    'bold',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            No.
                                                                                            Meeting
                                                                                            Rooms:{' '}
                                                                                        </Typography>{' '}
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '70%'
                                                                                                : '80%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                getReservationDetails
                                                                                                    ?.bookingInfo
                                                                                                    ?.meetingRooms
                                                                                            }
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                            {getReservationDetails
                                                                                ?.bookingInfo
                                                                                ?.entireVenue && (
                                                                                <Grid
                                                                                    sx={{
                                                                                        display:
                                                                                            'flex',
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '30%'
                                                                                                : '20%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontWeight:
                                                                                                    'bold',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            Type
                                                                                            of
                                                                                            Reservation:{' '}
                                                                                        </Typography>{' '}
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '70%'
                                                                                                : '80%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            Entire
                                                                                            Venue
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Grid
                                                                                sx={{
                                                                                    display:
                                                                                        'flex',
                                                                                }}
                                                                            >
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '30%'
                                                                                            : '20%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        No.
                                                                                        Adults:
                                                                                    </Typography>{' '}
                                                                                </Grid>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '70%'
                                                                                            : '80%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            getReservationDetails
                                                                                                ?.bookingInfo
                                                                                                ?.adults
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid
                                                                                sx={{
                                                                                    display:
                                                                                        'flex',
                                                                                }}
                                                                            >
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '30%'
                                                                                            : '20%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        No.
                                                                                        Children:{' '}
                                                                                    </Typography>{' '}
                                                                                </Grid>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '70%'
                                                                                            : '80%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            getReservationDetails
                                                                                                ?.bookingInfo
                                                                                                ?.children
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            {getReservationDetails
                                                                                ?.bookingInfo
                                                                                ?.type ===
                                                                                'activity' ||
                                                                            startDate ===
                                                                                endDate ? null : (
                                                                                <Grid
                                                                                    sx={{
                                                                                        display:
                                                                                            'flex',
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '30%'
                                                                                                : '20%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontWeight:
                                                                                                    'bold',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            No.
                                                                                            Overnight
                                                                                            Guests:{' '}
                                                                                        </Typography>{' '}
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '70%'
                                                                                                : '80%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                getReservationDetails
                                                                                                    ?.bookingInfo
                                                                                                    ?.overnightGuests
                                                                                            }
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {getReservationDetails
                                                                        ?.module
                                                                        .type ===
                                                                        'activity' ||
                                                                    getReservationDetails
                                                                        ?.module
                                                                        .type ===
                                                                        'vehicle' ? null : (
                                                                        <>
                                                                            {getIndividualAsset
                                                                                ?.details
                                                                                ?.catering && (
                                                                                <Grid
                                                                                    sx={{
                                                                                        display:
                                                                                            'flex',
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '30%'
                                                                                                : '20%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontWeight:
                                                                                                    'bold',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            Catering
                                                                                            Details:{' '}
                                                                                        </Typography>{' '}
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '70%'
                                                                                                : '80%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            {getReservationDetails
                                                                                                ?.bookingInfo
                                                                                                .requiresCatering ===
                                                                                            true
                                                                                                ? getReservationDetails
                                                                                                      ?.bookingInfo
                                                                                                      ?.cateringNotes
                                                                                                : 'No catering requested'}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                            {getIndividualAsset
                                                                                ?.details
                                                                                ?.alcohol && (
                                                                                <Grid
                                                                                    sx={{
                                                                                        display:
                                                                                            'flex',
                                                                                    }}
                                                                                >
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '30%'
                                                                                                : '20%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontWeight:
                                                                                                    'bold',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            Alcohol
                                                                                            Details:{' '}
                                                                                        </Typography>{' '}
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        sx={{
                                                                                            width: isMobile
                                                                                                ? '70%'
                                                                                                : '80%',
                                                                                        }}
                                                                                    >
                                                                                        {' '}
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontFamily:
                                                                                                    'Open Sans',
                                                                                                color: '#787878',
                                                                                                paddingBottom:
                                                                                                    '10px',
                                                                                                fontSize:
                                                                                                    '14px',
                                                                                            }}
                                                                                        >
                                                                                            {getReservationDetails
                                                                                                ?.bookingInfo
                                                                                                .requiresBeverages ===
                                                                                            true
                                                                                                ? getReservationDetails
                                                                                                      ?.bookingInfo
                                                                                                      ?.beverageNotes
                                                                                                : 'No beverages requested'}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {getReservationDetails
                                                                        ?.module
                                                                        .type !==
                                                                    'property' ? null : (
                                                                        <>
                                                                            <Grid
                                                                                sx={{
                                                                                    display:
                                                                                        'flex',
                                                                                }}
                                                                            >
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '30%'
                                                                                            : '20%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        Massage
                                                                                        Details:{' '}
                                                                                    </Typography>{' '}
                                                                                </Grid>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '70%'
                                                                                            : '80%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {getReservationDetails
                                                                                            ?.bookingInfo
                                                                                            ?.requiresMassages ===
                                                                                        true
                                                                                            ? getReservationDetails
                                                                                                  ?.bookingInfo
                                                                                                  ?.massageNotes
                                                                                            : hasMassages ===
                                                                                              false
                                                                                            ? 'This property does not offer massages'
                                                                                            : 'No Massages requested'}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}

                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        Notes:
                                                                    </Typography>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {getReservationDetails?.bookerType ===
                                                                            'owner' &&
                                                                        getReservationDetails?.onBehalf ===
                                                                            false
                                                                            ? getReservationDetails?.notes
                                                                            : getReservationDetails
                                                                                  ?.bookingInfo
                                                                                  ?.notes}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'flex-end',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        justifyContent:
                                                                            'flex-end',
                                                                    }}
                                                                    display={
                                                                        isMobile
                                                                            ? 'none'
                                                                            : isLaptop
                                                                            ? 'none'
                                                                            : 'block'
                                                                    }
                                                                >
                                                                    {' '}
                                                                    <ShareReservation
                                                                        getReservationDetails={
                                                                            getReservationDetails
                                                                        }
                                                                        getExperienceDetails={
                                                                            getIndividualAsset
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        Company:
                                                                    </Typography>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {getReservationDetails
                                                                            ?.guest
                                                                            ?.name
                                                                            ? getReservationDetails
                                                                                  ?.guest
                                                                                  ?.name
                                                                            : getReservationDetails
                                                                                  ?.owner
                                                                                  ?.name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        Primary
                                                                        Guest:{' '}
                                                                    </Typography>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.firstName
                                                                        }{' '}
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.lastName
                                                                        }{' '}
                                                                        -{' '}
                                                                        {
                                                                            getReservationDetails
                                                                                ?.booker
                                                                                ?.email
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            {getReservationDetails?.staffAttending &&
                                                                getIndividualAsset?.visitType !==
                                                                    'personal' &&
                                                                getIndividualAsset?.type !==
                                                                    'commercial' &&
                                                                getIndividualAsset?.type !==
                                                                    'vehicle' && (
                                                                    <Grid display='flex'>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                Staff
                                                                                Attending:
                                                                            </Typography>{' '}
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                            pb='10px'
                                                                        >
                                                                            {getReservationDetails
                                                                                ?.staffAttending
                                                                                ?.length >
                                                                            0 ? (
                                                                                getReservationDetails?.staffAttending.map(
                                                                                    (
                                                                                        user: User
                                                                                    ) => (
                                                                                        <Typography
                                                                                            fontSize='14px'
                                                                                            color='#787878'
                                                                                        >
                                                                                            <li>
                                                                                                {`${user.firstName} ${user.lastName} - ${user.email}`}
                                                                                            </li>
                                                                                        </Typography>
                                                                                    )
                                                                                )
                                                                            ) : (
                                                                                <Typography
                                                                                    fontSize='14px'
                                                                                    color='#787878'
                                                                                >
                                                                                    No
                                                                                    staff
                                                                                    selected
                                                                                </Typography>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            {getReservationDetails?.createdBy &&
                                                            getReservationDetails
                                                                ?.createdBy
                                                                .email !==
                                                                getReservationDetails
                                                                    ?.booker
                                                                    .email ? (
                                                                <Grid
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '30%'
                                                                                : '20%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontWeight:
                                                                                    'bold',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            Reservation
                                                                            made
                                                                            by:{' '}
                                                                        </Typography>{' '}
                                                                    </Grid>
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '70%'
                                                                                : '80%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                getReservationDetails
                                                                                    ?.createdBy
                                                                                    ?.firstName
                                                                            }{' '}
                                                                            {
                                                                                getReservationDetails
                                                                                    ?.createdBy
                                                                                    ?.lastName
                                                                            }{' '}
                                                                            -{' '}
                                                                            {
                                                                                getReservationDetails
                                                                                    ?.createdBy
                                                                                    ?.email
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            ) : null}

                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        Type of
                                                                        visit:
                                                                    </Typography>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {getReservationDetails?.bookingInfo?.visitType
                                                                            .charAt(
                                                                                0
                                                                            )
                                                                            ?.toUpperCase() +
                                                                            getReservationDetails?.bookingInfo?.visitType.slice(
                                                                                1
                                                                            )}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            {getReservationDetails
                                                                ?.module
                                                                .type ===
                                                            'activity' ? (
                                                                <Grid
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '30%'
                                                                                : '20%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontWeight:
                                                                                    'bold',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            No
                                                                            Guests:
                                                                        </Typography>{' '}
                                                                    </Grid>
                                                                    <Grid
                                                                        sx={{
                                                                            width: isMobile
                                                                                ? '70%'
                                                                                : '80%',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily:
                                                                                    'Open Sans',
                                                                                color: '#787878',
                                                                                paddingBottom:
                                                                                    '10px',
                                                                                fontSize:
                                                                                    '14px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                getReservationDetails
                                                                                    ?.bookingInfo
                                                                                    ?.guests
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            ) : getReservationDetails
                                                                  ?.module
                                                                  .type ===
                                                              'vehicle' ? null : getReservationDetails
                                                                  ?.module
                                                                  .type ===
                                                              'commercial' ? (
                                                                <>
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        {getReservationDetails
                                                                            ?.bookingInfo
                                                                            ?.desks !==
                                                                            0 && (
                                                                            <>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '30%'
                                                                                            : '20%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        No.
                                                                                        Dedicated
                                                                                        Desks:
                                                                                    </Typography>{' '}
                                                                                </Grid>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '70%'
                                                                                            : '80%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            getReservationDetails
                                                                                                ?.bookingInfo
                                                                                                ?.desks
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </>
                                                                        )}
                                                                        {getReservationDetails
                                                                            ?.bookingInfo
                                                                            ?.privateOffices !==
                                                                            0 && (
                                                                            <>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '30%'
                                                                                            : '20%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {getIndividualAsset
                                                                                            ?.details
                                                                                            ?.subType ===
                                                                                        'house'
                                                                                            ? `No. Rooms:`
                                                                                            : `No. Private Offices:`}
                                                                                    </Typography>{' '}
                                                                                </Grid>
                                                                                <Grid
                                                                                    sx={{
                                                                                        width: isMobile
                                                                                            ? '70%'
                                                                                            : '80%',
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontFamily:
                                                                                                'Open Sans',
                                                                                            color: '#787878',
                                                                                            paddingBottom:
                                                                                                '10px',
                                                                                            fontSize:
                                                                                                '14px',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            getReservationDetails
                                                                                                ?.bookingInfo
                                                                                                ?.privateOffices
                                                                                        }
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </>
                                                                        )}
                                                                    </Grid>

                                                                    {getReservationDetails
                                                                        ?.bookingInfo
                                                                        ?.meetingRooms !==
                                                                        0 && (
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    No.
                                                                                    Meeting
                                                                                    Rooms:{' '}
                                                                                </Typography>{' '}
                                                                            </Grid>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        getReservationDetails
                                                                                            ?.bookingInfo
                                                                                            ?.meetingRooms
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                    {getReservationDetails
                                                                        ?.bookingInfo
                                                                        ?.entireVenue && (
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    Type
                                                                                    of
                                                                                    Reservation:{' '}
                                                                                </Typography>{' '}
                                                                            </Grid>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    Entire
                                                                                    Venue
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                No.
                                                                                Adults:
                                                                            </Typography>{' '}
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    getReservationDetails
                                                                                        ?.bookingInfo
                                                                                        ?.adults
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                No.
                                                                                Children:{' '}
                                                                            </Typography>{' '}
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    getReservationDetails
                                                                                        ?.bookingInfo
                                                                                        ?.children
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>

                                                                    {getReservationDetails
                                                                        ?.bookingInfo
                                                                        ?.type ===
                                                                        'activity' ||
                                                                    startDate ===
                                                                        endDate ? null : (
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    No.
                                                                                    Overnight
                                                                                    Guests:{' '}
                                                                                </Typography>{' '}
                                                                            </Grid>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        getReservationDetails
                                                                                            ?.bookingInfo
                                                                                            ?.overnightGuests
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                </>
                                                            )}
                                                            {getReservationDetails
                                                                ?.module
                                                                .type ===
                                                                'activity' ||
                                                            getReservationDetails
                                                                ?.module
                                                                .type ===
                                                                'vehicle' ? null : (
                                                                <>
                                                                    {getIndividualAsset
                                                                        ?.details
                                                                        ?.catering && (
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    Catering
                                                                                    Details:{' '}
                                                                                </Typography>{' '}
                                                                            </Grid>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {getReservationDetails
                                                                                        ?.bookingInfo
                                                                                        .requiresCatering ===
                                                                                    true
                                                                                        ? getReservationDetails
                                                                                              ?.bookingInfo
                                                                                              ?.cateringNotes
                                                                                        : 'No catering requested'}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                    {getIndividualAsset
                                                                        ?.details
                                                                        ?.alcohol && (
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    Alcohol
                                                                                    Details:{' '}
                                                                                </Typography>{' '}
                                                                            </Grid>
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {getReservationDetails
                                                                                        ?.bookingInfo
                                                                                        .requiresBeverages ===
                                                                                    true
                                                                                        ? getReservationDetails
                                                                                              ?.bookingInfo
                                                                                              ?.beverageNotes
                                                                                        : 'No beverages requested'}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                </>
                                                            )}
                                                            {getReservationDetails
                                                                ?.module
                                                                .type !==
                                                            'property' ? null : (
                                                                <>
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                Massage
                                                                                Details:{' '}
                                                                            </Typography>{' '}
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    paddingBottom:
                                                                                        '10px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {getReservationDetails
                                                                                    ?.bookingInfo
                                                                                    ?.requiresMassages ===
                                                                                true
                                                                                    ? getReservationDetails
                                                                                          ?.bookingInfo
                                                                                          ?.massageNotes
                                                                                    : hasMassages ===
                                                                                      false
                                                                                    ? 'This property does not offer massages'
                                                                                    : 'No massages requested'}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            )}

                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '30%'
                                                                            : '20%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        Notes:
                                                                    </Typography>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    sx={{
                                                                        width: isMobile
                                                                            ? '70%'
                                                                            : '80%',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily:
                                                                                'Open Sans',
                                                                            color: '#787878',
                                                                            paddingBottom:
                                                                                '10px',
                                                                            fontSize:
                                                                                '14px',
                                                                        }}
                                                                    >
                                                                        {getReservationDetails?.bookerType ===
                                                                            'owner' &&
                                                                        getReservationDetails?.onBehalf ===
                                                                            false
                                                                            ? getReservationDetails?.notes
                                                                            : getReservationDetails
                                                                                  ?.bookingInfo
                                                                                  ?.notes}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'flex-end',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Grid
                                                                    sx={{
                                                                        justifyContent:
                                                                            'flex-end',
                                                                    }}
                                                                    display={
                                                                        isMobile
                                                                            ? 'none'
                                                                            : isLaptop
                                                                            ? 'none'
                                                                            : 'block'
                                                                    }
                                                                >
                                                                    {' '}
                                                                    <ShareReservation
                                                                        getReservationDetails={
                                                                            getReservationDetails
                                                                        }
                                                                        getExperienceDetails={
                                                                            getIndividualAsset
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )}{' '}
                                                </Grid>
                                            </>
                                        </AccordionDetails>
                                    </Accordion>

                                    {getReservationDetails?.onBehalf ===
                                    false ? null : (
                                        <Accordion
                                            expanded={expandedPanel2}
                                            onChange={() =>
                                                setExpandedPanel2(
                                                    !expandedPanel2
                                                )
                                            }
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls='panel2a-content'
                                                id='panel2a-header'
                                                sx={{
                                                    backgroundColor:
                                                        expandedPanel2 === false
                                                            ? '#f6f8f9'
                                                            : '',
                                                }}
                                            >
                                                <Typography
                                                    component='h3'
                                                    variant='h6'
                                                    color='#787878'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        fontWeight: 550,
                                                        textTransform:
                                                            'uppercase',
                                                    }}
                                                >
                                                    {getIndividualAsset?.type ===
                                                    'property'
                                                        ? 'Property Information'
                                                        : 'Experience Information'}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <>
                                                    <Grid>
                                                        {moment(
                                                            dayBeforeStartDate
                                                        ).format(
                                                            'DD/MM/YYYY'
                                                        ) >= todaysDate &&
                                                        endDate <= todaysDate &&
                                                        getReservationDetails?.status ===
                                                            'approved' &&
                                                        getReservationDetails?.onBehalf !==
                                                            false ? (
                                                            <Grid>
                                                                {' '}
                                                                <Divider />
                                                                <Grid
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        paddingTop:
                                                                            '10px',
                                                                    }}
                                                                ></Grid>
                                                                <>
                                                                    <Grid
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                            paddingTop:
                                                                                '10px',
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '30%'
                                                                                    : '20%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {getIndividualAsset?.type ===
                                                                                'commercial'
                                                                                    ? ` Access
                                                                        Details:`
                                                                                    : `Checkin Details`}
                                                                            </Typography>{' '}
                                                                        </Grid>

                                                                        <Grid
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '70%'
                                                                                    : '80%',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily:
                                                                                        'Open Sans',
                                                                                    color: '#787878',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    getIndividualAsset
                                                                                        ?.details
                                                                                        .checkinDetails
                                                                                }
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                                {getIndividualAsset?.type ===
                                                                    'property' ||
                                                                getIndividualAsset?.type ===
                                                                    'boat' ? (
                                                                    <>
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                                paddingTop:
                                                                                    '10px',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    WIFI
                                                                                    Details:
                                                                                </Typography>{' '}
                                                                            </Grid>

                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        getIndividualAsset
                                                                                            ?.details
                                                                                            .wifiDetails
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    Max
                                                                                    Guests:
                                                                                </Typography>{' '}
                                                                            </Grid>

                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        getIndividualAsset
                                                                                            ?.details
                                                                                            .maxGuests
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                            }}
                                                                        >
                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '30%'
                                                                                        : '20%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    No
                                                                                    Beds:
                                                                                </Typography>{' '}
                                                                            </Grid>

                                                                            <Grid
                                                                                sx={{
                                                                                    width: isMobile
                                                                                        ? '70%'
                                                                                        : '80%',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontFamily:
                                                                                            'Open Sans',
                                                                                        color: '#787878',
                                                                                        paddingBottom:
                                                                                            '10px',
                                                                                        fontSize:
                                                                                            '14px',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        getIndividualAsset
                                                                                            ?.details
                                                                                            .beds
                                                                                    }
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                ) : null}
                                                            </Grid>
                                                        ) : (
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    paddingBottom:
                                                                        '10px',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                {getReservationDetails?.status ===
                                                                'completed' ? (
                                                                    <>
                                                                        *Experience
                                                                        information
                                                                        is no
                                                                        longer
                                                                        available
                                                                        after
                                                                        the
                                                                        experience
                                                                        has been
                                                                        completed.
                                                                    </>
                                                                ) : getReservationDetails?.status ===
                                                                      'cancelled' ||
                                                                  getReservationDetails?.status ===
                                                                      'declined' ? (
                                                                    <>
                                                                        *Experience
                                                                        information
                                                                        is not
                                                                        available
                                                                        for
                                                                        cancelled
                                                                        or
                                                                        declined
                                                                        reservations.
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        *
                                                                        {getIndividualAsset?.type ===
                                                                        'property'
                                                                            ? 'Property information'
                                                                            : 'Experience information'}{' '}
                                                                        {''}will
                                                                        be shown
                                                                        (1) day
                                                                        before
                                                                        the
                                                                        approved
                                                                        reservation.{' '}
                                                                    </>
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </>
                                            </AccordionDetails>
                                        </Accordion>
                                    )}
                                    {getReservationDetails?.charges?.length >
                                        0 && (
                                        <Accordion
                                            expanded={expandedPanel2}
                                            onChange={() =>
                                                setExpandedPanel2(
                                                    !expandedPanel2
                                                )
                                            }
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls='panel2a-content'
                                                id='panel2a-header'
                                                sx={{
                                                    backgroundColor:
                                                        expandedPanel2 === false
                                                            ? '#f6f8f9'
                                                            : '',
                                                }}
                                            >
                                                <Typography
                                                    component='h3'
                                                    variant='h6'
                                                    color='#787878'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        fontWeight: 550,
                                                        textTransform:
                                                            'uppercase',
                                                    }}
                                                >
                                                    Pricing & Fees
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {' '}
                                                <BookingCharges
                                                    charges={
                                                        getReservationDetails?.charges
                                                    }
                                                    invoice={
                                                        getReservationDetails?.invoice
                                                    }
                                                    bookingTotal={
                                                        getReservationDetails?.bookingTotal
                                                    }
                                                    moduleType={
                                                        getReservationDetails
                                                            ?.module.type
                                                    }
                                                    module={getIndividualAsset}
                                                    overnightGuestCount={
                                                        getReservationDetails
                                                            ?.bookingInfo
                                                            ?.overnightGuests
                                                    }
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    )}

                                    {getReservationDetails?.invoice && (
                                        <>
                                            <Accordion
                                                expanded={expandedPanel2}
                                                onChange={() =>
                                                    setExpandedPanel2(
                                                        !expandedPanel2
                                                    )
                                                }
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                    aria-controls='panel2a-content'
                                                    id='panel2a-header'
                                                    sx={{
                                                        backgroundColor:
                                                            expandedPanel2 ===
                                                            false
                                                                ? '#f6f8f9'
                                                                : '',
                                                    }}
                                                >
                                                    <Typography
                                                        component='h3'
                                                        variant='h6'
                                                        color='#787878'
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            fontWeight: 550,
                                                            textTransform:
                                                                'uppercase',
                                                        }}
                                                    >
                                                        Payment History
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {' '}
                                                    <PaymentHistory
                                                        reservationDetails={
                                                            getReservationDetails
                                                        }
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: isIpad ? 'column-reverse' : 'row',
                            justifyContent: 'flex-end',
                            gap: '10px',
                            alignItems: isIpad ? 'flex-end' : 'flex-start',
                        }}
                    >
                        {loggedInUser?.viewOnly && (
                            <Typography
                                sx={{
                                    color: 'red',
                                    fontSize: '10px',
                                    maxWidth: '250px',
                                    textAlign: 'right',
                                }}
                            >
                                You do not have permission to edit this
                                reservation. Please contact your administrator.
                            </Typography>
                        )}

                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                gap: '10px',
                            }}
                        >
                            {getReservationDetails?.status === 'completed' ||
                            getReservationDetails?.status ===
                                'cancelled' ? null : (
                                <CustomButton
                                    variant='contained'
                                    color='neutral'
                                    disabled={loggedInUser?.viewOnly}
                                    onClick={editReservation}
                                >
                                    <Typography>Edit</Typography>
                                </CustomButton>
                            )}
                            {getReservationDetails?.status === 'pending' && (
                                <CustomButton
                                    variant='contained'
                                    color='warning'
                                    disabled={loggedInUser?.viewOnly}
                                    onClick={onClickOpenModal}
                                >
                                    <Typography>Cancel Request</Typography>
                                </CustomButton>
                            )}

                            {loggedInUser?.role.includes('owner') &&
                                getReservationDetails?.status ===
                                    'completed' && (
                                    <Grid>
                                        <CustomButton
                                            variant='contained'
                                            color='warning'
                                            disabled={loggedInUser?.viewOnly}
                                            // autoFocus
                                            onClick={onClickOpenModal}
                                        >
                                            <Typography>
                                                Cancel Request
                                            </Typography>
                                        </CustomButton>
                                    </Grid>
                                )}
                            {getReservationDetails?.status === 'approved' && (
                                <Grid>
                                    <CustomButton
                                        variant='contained'
                                        color='warning'
                                        disabled={loggedInUser?.viewOnly}
                                        onClick={onClickOpenModal}
                                    >
                                        <Typography>
                                            Cancel Reservation
                                        </Typography>
                                    </CustomButton>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <CancelReservationModal
                        open={open}
                        onClose={handleCloseModal}
                        onChange={handleChangeModal}
                        message={'cancel this reservation'}
                        component={'reservation'}
                        id={getReservationDetails?.id}
                    />
                </Grid>
            )}{' '}
        </>
    );
}

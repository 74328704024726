import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function FeesVsPrice() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                >
                    Pricing
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    7DAY allows you to set a base price for the experience you
                    have listed. This price is what you charge guests for
                    attending/hiring. Your base price is the normal price you
                    charge per day/night/hour for the experience and the custom
                    pricing allows you to set a different price that can be
                    applied to certain days over the weekend. For example: You
                    charge $500 per night for accomodation during the week but
                    $600 on the weekend (Friday - Sunday)
                </Typography>
            </Grid>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        paddingTop: '15px',
                    }}
                >
                    Fees
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    7DAY allows you to set a additional fees for the experience
                    you have listed. Fees represent a service such as Cleaning.
                </Typography>
            </Grid>{' '}
            <Typography
                sx={{
                    fontFamily: 'Open Sans',
                    color: '#787878',
                    fontSize: '14px',
                }}
            >
                Once you apply a Price or a Fee to an experience it will display
                to the guest on the listing and at the time the submit the
                booking. This amount will be debited from their nominated card.
            </Typography>
        </Grid>
    );
}

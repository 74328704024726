import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface DateFilterState {
    invalidDateRange: boolean;
    startDate: Date | undefined;
    endDate: Date | undefined;
}

const initialState: DateFilterState = {
    invalidDateRange: false,
    startDate: undefined,
    endDate: undefined,
};

export const dateFilterSlice = createSlice({
    name: 'dateFilter',
    initialState,
    reducers: {
        setStartDate: (state, action: PayloadAction<Date | undefined>) => {
            state.startDate = action.payload;
            if (
                state.endDate &&
                state.startDate &&
                new Date(state.startDate) > new Date(state.endDate)
            ) {
                state.invalidDateRange = true;
            } else {
                state.invalidDateRange = false;
            }
        },
        setEndDate: (state, action: PayloadAction<Date | undefined>) => {
            state.endDate = action.payload;
            if (
                state.startDate &&
                state.endDate &&
                new Date(state.startDate) > new Date(state.endDate)
            ) {
                state.invalidDateRange = true;
            } else {
                state.invalidDateRange = false;
            }
        },
        resetDates: (state) => {
            state.invalidDateRange = false;
            state.startDate = undefined;
            state.endDate = undefined;
        },
    },
});

export const { setStartDate, setEndDate, resetDates } = dateFilterSlice.actions;

export const selectDateFilter = (state: RootState) => state.dateFilter;
export const selectInvalidDateRange = (state: RootState) =>
    state.dateFilter.invalidDateRange;
export const selectStartDate = (state: RootState) => state.dateFilter.startDate;
export const selectEndDate = (state: RootState) => state.dateFilter.endDate;

export const dateFilterReducer = dateFilterSlice.reducer;

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function ReservationMessages() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Guests can enquire about a reservation using the 'Contact
                        the Host' link at the bottom of the reservation. Sending
                        a message from here will create a message channel
                        between the guest and the host's support team.
                    </Typography>
                    {loggedInUser?.role.includes('owner') && (
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Similary hosts can reach out to the guest of a
                            reservation using the 'Contact the Guest' link at the
                            bottom of the reservation.
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

import { 
    Box, CircularProgress, Drawer, IconButton, 
    InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Stack, TextField, 
    Toolbar, Typography, useMediaQuery 
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import ChannelItem from './ChannelItem';
import { FilterList, Search } from '@mui/icons-material';
import { ChannelRepository, getChannelTopic, subscribeTopic } from '@amityco/ts-sdk';

interface ChannelListProps {
    open: boolean,
    onClick: (channelId: string) => void,
}

const disposers: Amity.Unsubscriber[] = [];
const subscribedChannels: Amity.Channel['channelId'][] = [];

const ChannelList = ({ open, onClick }: ChannelListProps ) => {
    const isMobile = useMediaQuery('(max-width: 1200px)');

    const [channels, setChannels] = useState<Amity.Channel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [filteredChannels, setFilteredChannels] = useState<Amity.Channel[]>([]);

    const [searchQuery, setSearchQuery] = useState<string>('');

    const [hasMore, setHasMore] = useState<boolean>(false);
    const [nextPageFn, setNextPageFn] = useState<() => void>();
    const [pageLoading, setPageLoading] = useState<boolean>(false);

    // const subscribeChannels = (channels: Amity.Channel[]) => {
    //     channels.forEach(c => {
    //         if (!subscribedChannels.includes(c.channelId)) {
    //             subscribedChannels.push(c.channelId);
    //             disposers.push(subscribeTopic(getChannelTopic(c)));
    //         }
    //     });
    // };

    useEffect(() => {
        setChannels([]);
        setFilteredChannels([]);

        const params: Amity.ChannelLiveCollection = {
            isDeleted: false,
            sortBy: 'lastActivity',
            limit: 10,
        };

        ChannelRepository.getChannels(params, 
            ({ data: channels, onNextPage, hasNextPage, loading, error}) => {
                if(channels && !loading && !error) {
                    setChannels(channels);
                    // subscribeChannels(channels);
                };

                setHasMore(hasNextPage || false);
                setNextPageFn(() => onNextPage);

                if(!loading) setLoading(false);
                
                if (!loading && pageLoading) setPageLoading(false);
            }
        );
        
        // disposers.push(unsub);

        // return () => {
        //     disposers.forEach(fn => fn())
        // }
    }, []);
    useEffect(() => {
        setFilteredChannels(
            channels.filter(channel =>
                channel.displayName?.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, channels]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const onScroll = (e: React.UIEvent<HTMLUListElement>) => {
        if (!nextPageFn || !hasMore) return;
    
        const el = e.currentTarget;
        const scrollTop = el.scrollTop;
        const clientHeight = el.clientHeight;
        const scrollHeight = el.scrollHeight;
    
        // Check if user has scrolled to the bottom (or near the bottom)
        if (scrollHeight - scrollTop <= clientHeight + 1) {
            nextPageFn();
            setPageLoading(true);
        }
    };

    return (
        <Drawer
            anchor='left'
            open={open}
            variant={isMobile ? 'persistent' : 'permanent'}
            sx={{ position: 'fixed'}}
            PaperProps={{
                sx: { 
                    width: isMobile ? '100%' : '400px',
                }
            }}
            
        >
            <Toolbar />
            <Box mb={1}>
                <Box
                    display='flex' 
                    alignItems='center' 
                    justifyContent='space-between'
                    px={3}
                    py={2}
                >
                    <Typography
                        variant='h6'
                        fontWeight='bold'
                        color='#787878'
                    >
                        Messages
                    </Typography>
                    <IconButton>
                        <FilterList />
                    </IconButton>
                </Box>
                <TextField 
                    fullWidth
                    size='small'
                    placeholder='Search'
                    value={searchQuery}
                    onChange={handleSearchChange} 
                    InputProps={{
                        startAdornment: 
                            <InputAdornment position='start'>
                                <Search color='disabled' fontSize='small'/>
                            </InputAdornment>
                    }}
                    sx={{px: 3}}
                />
            </Box>
            
            {filteredChannels.length === 0 ? (
                loading ? (
                    <List>
                        {[...Array(10)].map(() => (
                            <ListItem sx={{ px: 4, py: 3 }}>
                                <ListItemAvatar>
                                    <Skeleton variant='circular' width={40} height={40}/>
                                </ListItemAvatar>
                                <Stack spacing={0.5} width='100%'>
                                    <Skeleton variant='rounded' width={'100%'} height={20}/>
                                    <Skeleton variant='rounded' width={'50%'} height={10}/>
                                </Stack>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Box display='flex' height='100%' alignItems='center' justifyContent='center'>
                        <Typography color='#787878'>
                            No messages!
                        </Typography>
                        </Box>
                )
            ) : (
                <>
                    <List 
                        sx={{ 
                            overflow: 'scroll', 
                            // hide scroll
                            '-ms-overflow-style': 'none',
                            '::-webkit-scrollbar': {
                                display: 'none'
                            } 
                        }}
                        onScroll={onScroll}
                    >
                        {filteredChannels.map((channel) => (
                            <ChannelItem 
                                key={channel.channelId}
                                {...channel}
                                onClick={onClick}
                            />
                        ))}
                        {pageLoading && (
                            <Box display='flex' justifyContent='center' py={2}>
                                <CircularProgress />
                            </Box>
                        )}

                    </List>
                </>
            )}    
        </Drawer>
    )
}

export default ChannelList
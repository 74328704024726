import { Checkbox, CheckboxProps, FormControl, FormHelperText } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

interface FormCheckboxProps extends CheckboxProps {
    name: string,
    control: Control<any>
}

/**
 * For use within RHF FormProvider
 */
const FormCheckbox = ({ name, control, ...props}: FormCheckboxProps) => {
    return (
        <Controller 
            name={name}
            control={control}
            render={({
                field: { value, onChange, ref },
                fieldState: { error },
            }) => (
                <Checkbox 
                    {...props}
                    inputRef={ref}
                    checked={value} 
                    onChange={onChange}
                />
            )}
        />
    )
}

export default FormCheckbox
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    selectEndDate,
    selectStartDate,
} from '../../../app/Slices/DateFilterSlice';
import { selectExperienceFilters } from '../../../app/Slices/ExperienceFiltersSlice';
import { selectExperienceSearchFilter } from '../../../app/Slices/ExperienceSearchSlice';
import { useGetAllPublicHolidaysForYearAndCountryQuery } from '../../../app/Slices/PublicHolidaysSlice';
import { useGetAllAssetsQuery } from '../../../app/Slices/SevenDayApiSlice';
import { capitalizeName, filterItemsBySearch } from '../../../shared/utils';
import ExperienceListLoadingComponent from '../../../layout/styledcomponents/ExperienceListLoadingComponent';
import ImageSlider from '../../../layout/styledcomponents/CustomImageSlider/ImageSlider';
import moment from 'moment';

export const filterItemsByDateRange = (
    items: any[],
    startDate: any,
    endDate: any,
    publicHolidaysArray: any[]
): any[] => {
    if (!startDate || !endDate) {
        return items;
    }

    const dayNames = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
    ];
    let currentDate = new Date(startDate?.getTime());

    endDate = new Date(endDate?.getTime());
    currentDate?.setHours(0, 0, 0, 0);

    const daysAvailable = new Set<string>();
    while (currentDate <= endDate) {
        daysAvailable.add(dayNames[currentDate?.getDay()]);
        currentDate.setDate(currentDate?.getDate() + 1);
    }

    const daysArray = Array.from(daysAvailable);

    currentDate = new Date(startDate?.getTime());
    currentDate?.setHours(0, 0, 0, 0);

    const datesArray: Date[] = [];

    while (currentDate <= endDate) {
        datesArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return items.filter((item) => {
        if (!item.isAvailable) {
            return false;
        }

        for (let date of datesArray) {
            if (
                !item?.publicHolidays &&
                publicHolidaysArray
                    .filter((data) => {
                        return (
                            data?.counties?.includes(`AU-${item?.state}`) ||
                            data?.global
                        );
                    })
                    .some((holiday) => {
                        return (
                            new Date(holiday.date).setHours(0, 0, 0, 0) ===
                            date.getTime()
                        );
                    })
            ) {
                return false;
            }
        }

        for (let day of daysArray) {
            if (!item[day]) {
                return false;
            }
        }
        return true;
    });
};

export default function ExperiencesListAllComponent({ visitType = '' }) {
    const ExperienceFilters = useSelector(selectExperienceFilters);
    const searchTerm = useSelector(selectExperienceSearchFilter);

    const { data, isFetching } = useGetAllAssetsQuery({
        url: '/modules',
        ...ExperienceFilters,
        ...(visitType ? { visitType } : {}),
        sort: 'name',
    });

    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const { data: publicHolidaysAustraliaCurrentYear } =
        useGetAllPublicHolidaysForYearAndCountryQuery({
            country: 'AU',
            year: new Date().getFullYear(),
        });
    const { data: publicHolidaysAustraliaNextYear } =
        useGetAllPublicHolidaysForYearAndCountryQuery({
            country: 'AU',
            year: new Date().getFullYear() + 1,
        });
    const { data: publicHolidaysAustraliaYearAfterNextYear } =
        useGetAllPublicHolidaysForYearAndCountryQuery({
            country: 'AU',
            year: new Date().getFullYear() + 2,
        });

    const publicHolidaysAustralia =
        publicHolidaysAustraliaCurrentYear &&
        publicHolidaysAustraliaNextYear &&
        publicHolidaysAustraliaYearAfterNextYear
            ? [
                  ...publicHolidaysAustraliaCurrentYear,
                  ...publicHolidaysAustraliaNextYear,
                  ...publicHolidaysAustraliaYearAfterNextYear,
              ]
            : [];

    const allAssets =
        data && publicHolidaysAustralia
            ? filterItemsByDateRange(
                  data,
                  startDate,
                  endDate,
                  publicHolidaysAustralia
              )
            : undefined;
    const filteredAssets = allAssets
        ? filterItemsBySearch(allAssets, searchTerm)
        : undefined;

    const handleOpen = (experience: any) => {
        ReactGA.event({
            category: 'UI',
            action: `Individual Experience (${visitType})`,
            label: `Ind. Experience Clicked (${visitType})`,
        });
    };

    const NewBadge = (experience: any) => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 83,
                    left: 8,
                    backgroundColor: '#5cb5b3',
                    color: 'white',
                    padding: '4px 10px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    zIndex: 1,
                    letterSpacing: '0.5px',
                    // Responsive styling
                    '@media (max-width: 1200px)': {
                        bottom: 80,
                        left: 6,
                    },
                    '@media (max-width: 770px)': {
                        bottom: 80,
                        left: 6,
                    },
                }}
            >
                NEW
            </Box>
        );
    };

    const isNewExperience = (dateAdded: any) => {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        return new Date(dateAdded) >= thirtyDaysAgo;
    };

    return (
        <Grid
            container
            spacing={4}
            columns={60}
            sx={{
                flexGrow: 1,
                paddingTop: '30px',
                paddingBottom: '20px',
                height: '100%',
                width: '100%',
            }}
        >
            {filteredAssets?.length === 0 ? (
                <Grid
                    sx={{
                        height: '100%',
                        paddingTop: '50px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    <img
                        alt='noresults'
                        src='/noResults.png'
                        style={{
                            width: '900px',
                            height: '500px',
                        }}
                    />
                    <Typography sx={{ fontFamily: 'Open Sans' }}>
                        Your query did not return any results
                    </Typography>
                </Grid>
            ) : isFetching === true || filteredAssets === undefined ? (
                <ExperienceListLoadingComponent />
            ) : (
                filteredAssets.map((experience: any) => (
                    <Grid
                        key={experience.name}
                        item
                        xs={60}
                        sm={30}
                        md={20}
                        lg={15}
                        xl={12}
                        sx={{ textDecoration: 'none' }}
                    >
                        <Box
                            component={Link}
                            to={`/viewExperiences/${experience?.type.toLowerCase()}/${
                                experience?.id
                            }`}
                            state={{
                                experience: experience,
                                component: 'all',
                            }}
                            onClick={() => handleOpen(experience)}
                            sx={{ textDecoration: 'none' }}
                        >
                            <Card
                                key={experience.name}
                                onClick={() => handleOpen(experience)}
                                variant='outlined'
                                sx={{
                                    width: 'auto',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between',
                                    boxShadow: 0,
                                    background: 'none',
                                    backgroundColor: 'transparent',
                                    borderRadius: '8px',
                                    color: 'none',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#fff',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                    '&:focus': {
                                        cursor: 'pointer',
                                        color: '#fff',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                }}
                            >
                                <CardActionArea
                                    sx={{
                                        background: 'none',
                                        backgroundColor: 'transparent',
                                        color: '#fff',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: '#fff',
                                            backgroundColor: 'transparent',
                                            background: 'none',
                                        },
                                        '&:focus': {
                                            cursor: 'pointer',
                                            color: '#fff',
                                            backgroundColor: 'transparent',
                                            background: 'none',
                                        },
                                    }}
                                >
                                    <ImageSlider images={experience.photos} />

                                    <CardContent
                                        onClick={() => handleOpen(experience)}
                                        sx={{ padding: '15px' }}
                                    >
                                        <Grid>
                                            {' '}
                                            <Typography
                                                gutterBottom
                                                variant='h5'
                                                component='div'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 'bold',
                                                    color: '#787878',
                                                    fontSize: '16px',
                                                    '&:hover': {
                                                        color: '#5cb5b3',
                                                    },
                                                    // Responsive styling
                                                    '@media (max-width: 1200px)':
                                                        {
                                                            fontSize: '16px',
                                                        },
                                                    '@media (max-width: 770px)':
                                                        {
                                                            fontSize: '16px',
                                                        },
                                                }}
                                            >
                                                {capitalizeName(
                                                    experience.name
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                            }}
                                        >
                                            {capitalizeName(
                                                experience?.type ===
                                                    'commercial' &&
                                                    experience?.details
                                                        ?.subType === 'house'
                                                    ? 'home office'
                                                    : experience?.details
                                                          ?.subType
                                            )}{' '}
                                            - {capitalizeName(experience?.type)}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {capitalizeName(experience?.suburb)}
                                            ,{' '}
                                            {capitalizeName(experience?.state)}
                                        </Typography>
                                    </CardContent>
                                    {isNewExperience(experience.createdAt) && (
                                        <NewBadge
                                            dateAdded={experience.createdAt}
                                        />
                                    )}
                                </CardActionArea>
                            </Card>
                        </Box>
                    </Grid>
                ))
            )}
        </Grid>
    );
}

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Divider,
    Grid,
    Popover,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/system';
import React, { useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { capitalizeName, formatCurrency } from '../../../../shared/utils';
import UserRole from '../../../../shared/AuthRoles';
import { useGetPlatformSettingsQuery } from '../../../../app/Slices/SevenDayApiSlice';

const LinkItem = styled('li')<{ isFixed: boolean }>(({ isFixed }) => ({
    cursor: isFixed ? 'default' : 'default',
    //In case we decide to have a small description with a popover we can utilize textDecoration and Popover
    textDecoration: isFixed ? 'none' : 'none',
}));

const BookingCharges = ({
    charges,
    bookingTotal,
    module,
    invoice,
}: {
    charges: any;
    moduleType: any;
    module?: any;
    overnightGuestCount?: any;
    bookingTotal: any;
    invoice?: any;
}) => {
    const { data: platformSettings } =
        useGetPlatformSettingsQuery('/platform-settings');
    const loggedInUser = UserRole();
    const firstTextFieldRef = useRef(null);
    const isXxs = useMediaQuery('(max-width: 400px)');
    const isXs = useMediaQuery('(max-width: 500px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isMd = useMediaQuery('(max-width: 900px)');
    const isLg = useMediaQuery('(max-width: 1200px)');
    const isXl = useMediaQuery('(max-width: 1535px)');
    const [openFeeDetails, setOpenFeeDetails] = useState<null | string>(null);
    const [anchorElFeeDetails, setAnchorElFeeDetails] =
        React.useState<null | HTMLElement>(null);

    const chargesWithIds = charges.map((charge: any, index: number) => ({
        ...charge,
        feeId: `charge-${index}`,
    }));

    const handleFeeDetailsClose = () => {
        setOpenFeeDetails(null);
        setAnchorElFeeDetails(null);
    };

    const getFeeMultiplierText = (feeMultiplier: any) => {
        const { value, type } = feeMultiplier;

        if (value > 1) {
            switch (type) {
                case 'night':
                    return 'nights';
                case 'day':
                    return 'days';
                case 'guest':
                    return 'guests';
                case 'hour':
                    return 'hours';
                default:
                    return type;
            }
        } else return type;
    };

    return (
        <Grid>
            <Typography
                component='ul'
                sx={{
                    fontSize: '14px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    listStyle: charges?.length === 1 ? 'none' : 'default',
                    paddingLeft: 0,
                }}
            >
                <TransitionGroup>
                    {chargesWithIds
                        ?.filter((fee: any) => fee?.total !== 0)
                        ?.map((fee: any, index: number) => (
                            <Collapse key={index}>
                                <Grid
                                    container
                                    columns={100}
                                    key={fee.feeId}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    <LinkItem
                                        // onClick={(event) =>
                                        //     handleFeeDetails(event, fee)
                                        // }
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: isMobile ? '100%' : '40%',
                                        }}
                                        ref={firstTextFieldRef}
                                        isFixed={fee.type === 'fixed'}
                                    >
                                        <Grid sx={{ width: '100%' }}>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {fee?.type === 'fixed' ? (
                                                    capitalizeName(fee.name)
                                                ) : (
                                                    <>
                                                        {`$${fee?.unitPrice?.toFixed(
                                                            2
                                                        )} AUD `}{' '}
                                                        {fee.feeMultipliers.map(
                                                            (
                                                                feeMultiplier: any
                                                            ) => (
                                                                <span>
                                                                    {` x ${
                                                                        feeMultiplier.value
                                                                    } ${getFeeMultiplierText(
                                                                        feeMultiplier
                                                                    )}`}
                                                                </span>
                                                            )
                                                        )}{' '}
                                                        {`${
                                                            fee?.isWeekendPrice
                                                                ? ' (Weekend Price)'
                                                                : ''
                                                        }`}{' '}
                                                        {fee?.name
                                                            ? `(${capitalizeName(
                                                                  fee.name
                                                              )})`
                                                            : ``}
                                                    </>
                                                )}
                                            </Typography>
                                            {fee?.discount?.percentage > 0 && (
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        marginLeft: '20px',
                                                        marginTop: '5px',
                                                    }}
                                                >
                                                    - {fee.discount.percentage}%
                                                    Discount
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                width: isMobile ? '70%' : '80%',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {formatCurrency(fee?.total)}
                                        </Typography>
                                    </LinkItem>
                                    <Popover
                                        open={openFeeDetails === fee.feeId}
                                        anchorEl={anchorElFeeDetails}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        onClose={handleFeeDetailsClose}
                                        sx={{ display: 'flex' }}
                                    >
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                padding: '10px',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {fee?.type === 'fixed' ? (
                                                    capitalizeName(fee.name)
                                                ) : (
                                                    <>
                                                        {`$${fee?.unitPrice?.toFixed(
                                                            2
                                                        )} AUD `}{' '}
                                                        {fee?.feeMultipliers?.map(
                                                            (
                                                                feeMultiplier: any
                                                            ) => (
                                                                <span
                                                                    key={
                                                                        feeMultiplier.type
                                                                    }
                                                                >
                                                                    {` x ${
                                                                        feeMultiplier.value
                                                                    } ${getFeeMultiplierText(
                                                                        feeMultiplier
                                                                    )}`}
                                                                </span>
                                                            )
                                                        )}{' '}
                                                    </>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Popover>
                                </Grid>
                            </Collapse>
                        ))}
                </TransitionGroup>
            </Typography>

            {module?.id !== 9 && (
                <>
                    {' '}
                    <Divider
                        sx={{
                            color: '#e3e3e3',
                            width: isXxs
                                ? '100%'
                                : isXs
                                ? '100%'
                                : isMobile
                                ? '100%'
                                : isMd
                                ? '40%'
                                : isLg
                                ? '40%'
                                : isXl
                                ? '40%'
                                : '40%',
                        }}
                    />
                    {Object.entries(bookingTotal)
                        .filter((item: any) => item[1] > 0)
                        .map((item: any, index: number) => (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: isMobile ? '100%' : '40%',
                                }}
                            >
                                {' '}
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight:
                                            item[0] === 'total' ? 'bold' : '',
                                        width: isMobile ? '30%' : '20%',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    {capitalizeName(
                                        item[0] === 'gst'
                                            ? 'GST'
                                            : item[0] === 'subTotal'
                                            ? 'Subtotal'
                                            : item[0] +
                                              (!invoice &&
                                              loggedInUser?.role.includes(
                                                  'guest'
                                              )
                                                  ? '*'
                                                  : '')
                                    )}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight:
                                            item[0] === 'total' ? 'bold' : '',
                                        width: isMobile ? '70%' : '80%',
                                        textAlign: 'right',
                                    }}
                                >
                                    {item[0] === 'discount' ? '-' : ''}{' '}
                                    {formatCurrency(item[1])}
                                </Typography>
                            </Grid>
                        ))}
                    {!invoice && loggedInUser?.role.includes('guest') && (
                        <Grid
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                marginTop: '10px',
                            }}
                        >
                            <Typography variant='body2'>
                                * You won't be charged yet
                            </Typography>
                            {platformSettings?.generateMonthlyInvoices && (
                                <Tooltip
                                    title={
                                        <Typography>
                                            The billing will be included in your
                                            monthly invoice once the experience
                                            has concluded for this period.
                                        </Typography>
                                    }
                                    placement='right'
                                >
                                    <HelpOutlineIcon
                                        sx={{
                                            fontSize: '16px',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default BookingCharges;

import { Card, CardActionArea, CardActions, CardContent, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ExperiencePolicy } from '../../../types/policy'
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton'
import PolicyModal from '../../Policies/PolicyModal'

interface Props {
    policy: ExperiencePolicy
}

const ExperiencePolicyCard = ({ policy }: Props) => {
    const MAX_DESCRIPTION_LENGTH: number = 150;

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const truncateDescription = (description?: string) => {
        if (!description) return '';

        if (description.length > MAX_DESCRIPTION_LENGTH) {
            return description.substring(0, MAX_DESCRIPTION_LENGTH - 3) + '...';
        } else {
            return description;
        }
    };

    return (
        <>
            <Card 
                elevation={0}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%'
                }}
            >
                <CardContent sx={{ p: 0 }}>
                    <Typography
                        color='textPrimary'
                        fontSize='14px'
                        fontWeight='bold'
                        gutterBottom
                    >
                        {policy.name}
                    </Typography>
                    <Typography
                        variant='body2'
                        color='textPrimary'
                        gutterBottom
                        sx={{ whiteSpace: 'pre-line' }}
                    >
                        {truncateDescription(policy.description)}
                    </Typography>
                    
                </CardContent>
                <CardActions sx={{ pl: 0 }}>
                    <CustomButton
                        color='green'
                        onClick={() => setModalOpen(true)}
                    >
                        See details
                    </CustomButton>
                </CardActions>
            </Card>
            <PolicyModal 
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                policy={policy}
            />
        </>
    )
}

export default ExperiencePolicyCard
import {
    Card,
    CardActionArea,
    CardContent,
    Fade,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
    camelCaseToLowerCase,
    camelCaseToSentenceCase,
    camelCaseToTitleCase,
} from '../../../shared/utils';
import IndividualExperienceSpacesModal from './IndividualExperienceSpacesModal';
import { getAmenitiesArray, getBeddingArray } from './Spaces/IndividualSpace';
import { SpaceType } from './Spaces/SpaceModal';
import KingBedIcon from '@mui/icons-material/KingBed';
import WeekendIcon from '@mui/icons-material/Weekend';
import GroupsIcon from '@mui/icons-material/Groups';
import ShowerIcon from '@mui/icons-material/Shower';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import KitchenIcon from '@mui/icons-material/Kitchen';

export const SpaceIcons: Record<any, React.ReactNode> = {
    [SpaceType.Bedroom]: <KingBedIcon />,
    [SpaceType.LivingRoom]: <WeekendIcon />,
    [SpaceType.Boardroom]: <GroupsIcon />,
    [SpaceType.Bathroom]: <ShowerIcon />,
    [SpaceType.DiningArea]: <RestaurantIcon />,
    [SpaceType.GameRoom]: <SportsEsportsIcon />,
    [SpaceType.Garage]: <DirectionsCarIcon />,
    [SpaceType.Kitchen]: <KitchenIcon />,
};

const SpaceCard = ({
    space,
    selected,
    spaces,
    maxNumberOfBeds,
    cursor,
}: {
    space: any;
    spaces?: any;
    selected: boolean;
    maxNumberOfBeds: number;
    cursor: string;
}) => {
    const [showModal, setShowModal] = useState(false);
    const isMobile = useMediaQuery('(max-width: 770px)');

    const myRef = useRef<HTMLDivElement>(null);

    const executeScroll = () => {
        if (myRef.current && isMobile) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    };

    useEffect(() => {
        if (selected) {
            const timer = setTimeout(() => {
                executeScroll();
            }, 200);

            return () => clearTimeout(timer);
        }
    }, [selected]);

    const handleClick = () => {
        if (spaces?.length > 3 && !showModal) {
            setShowModal(true);
        }
    };

    return (
        <Stack>
            <Card
                onClick={handleClick}
                sx={{
                    transformOrigin: 'center',
                    height: '100%',
                    minHeight: '200px',
                    cursor: cursor,
                    transition: 'transform 0.3s ease',
                    ...(cursor === 'pointer' &&
                        spaces.length > 3 && {
                            '&:hover': {
                                transform: 'scale(1.02)',
                            },
                        }),
                }}
                style={{ flex: 1 }}
            >
                <CardActionArea>
                    <Grid
                        ref={myRef}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'custom.cardActionBackground',
                            animation: selected
                                ? 'background-change 2s ease-in-out'
                                : 'none',
                            '@keyframes background-change': {
                                '0%': {
                                    backgroundColor: '#e0e0e0',
                                },

                                '100%': {
                                    backgroundColor:
                                        'custom.cardActionBackground',
                                },
                            },
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingTop: 2,
                                height: '60px',
                                gap: 1,
                            }}
                        >
                            <Stack
                                direction='row'
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: 'primary.main',
                                    }}
                                >
                                    {' '}
                                    {SpaceIcons[space.spaceType] || null}
                                </Typography>{' '}
                                <Typography
                                    sx={{
                                        color: 'primary.main',
                                    }}
                                >
                                    {' '}
                                    {camelCaseToTitleCase(space.name)}
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Grid>
                </CardActionArea>
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                        height: '145px',
                        overflow: 'auto',
                        paddingBottom: '0 !important',
                    }}
                >
                    {getBeddingArray(space).length > 0 && (
                        <Stack sx={{ paddingBottom: 1 }}>
                            {' '}
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Sleeping arrangements:
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                }}
                            >
                                {getBeddingArray(space).map((item: any, i) => (
                                    <Fade in={true} timeout={500}>
                                        <span>
                                            {i > 0 && ','} {item[1]}&nbsp;
                                            {camelCaseToLowerCase(item[0])}
                                            {item[1] > 1 &&
                                                item[0] !== 'couch' &&
                                                's'}
                                            {item[1] > 1 &&
                                                item[0] === 'couch' &&
                                                'es'}
                                        </span>
                                    </Fade>
                                ))}
                            </Typography>{' '}
                        </Stack>
                    )}
                    {getAmenitiesArray(space).length > 0 && (
                        <Stack sx={{ paddingBottom: 1 }}>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Amenities:
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                }}
                            >
                                {getAmenitiesArray(space).map(
                                    (amenity: any, i) => (
                                        <Fade in={true} timeout={500}>
                                            <span>
                                                {i > 0 && ','}{' '}
                                                {amenity === 'evChargingStation'
                                                    ? 'EV charging station'
                                                    : camelCaseToSentenceCase(
                                                          amenity
                                                      )}
                                            </span>
                                        </Fade>
                                    )
                                )}
                            </Typography>{' '}
                        </Stack>
                    )}
                    {space.spaceType === SpaceType.Bedroom &&
                        space?.desk > 0 && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Workspace:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                    }}
                                >
                                    <Fade in={true} timeout={500}>
                                        <span>
                                            {space.desk} desk
                                            {space.desk > 1 ? 's' : ''}
                                        </span>
                                    </Fade>
                                </Typography>{' '}
                            </>
                        )}
                    {(space.spaceType === SpaceType.DiningArea ||
                        space.spaceType === SpaceType.Boardroom) &&
                        space?.seatingCapacity > 0 && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Seating Capacity:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                    }}
                                >
                                    <Fade in={true} timeout={500}>
                                        <span>
                                            {space.seatingCapacity} people
                                        </span>
                                    </Fade>
                                </Typography>{' '}
                            </>
                        )}
                    {space.spaceType === SpaceType.Garage &&
                        space?.parkingCapacity > 0 && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Parking Capacity:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                    }}
                                >
                                    <Fade in={true} timeout={500}>
                                        <span>
                                            {space.parkingCapacity} cars
                                        </span>
                                    </Fade>
                                </Typography>{' '}
                            </>
                        )}
                </CardContent>
            </Card>
            {spaces && (
                <IndividualExperienceSpacesModal
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    spaces={spaces}
                    selectedSpace={space}
                    maxNumberOfBeds={maxNumberOfBeds}
                />
            )}
        </Stack>
    );
};

export default SpaceCard;

import { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography, useMediaQuery } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { CustomButton } from '../layout/styledcomponents/CustomButton';
import React from 'react';

export default function InitBeforeUnLoad(showExitPrompt: boolean) {
    window.onbeforeunload = (event) => {
        if (showExitPrompt) {
            const e = event || window.event;
            e.preventDefault();

            if (e) {
                e.returnValue = '';
            }

            return '';
        }
    };
}

// Hook
export function useExitPrompt(bool: any) {
    const [showExitPrompt, setShowExitPrompt] = useState(bool);
    window.onload = function () {
        InitBeforeUnLoad(showExitPrompt);
    };

    useEffect(() => {
        InitBeforeUnLoad(showExitPrompt);
    }, [showExitPrompt]);

    return [showExitPrompt, setShowExitPrompt];
}

export const ExitDialog = ({ onClose, onOk, visible = false }:any) => {
    const isIpad = useMediaQuery('(max-width: 1200px)');
    return (
        <Dialog open={visible} onClose={onClose}>
            <Grid
                sx={{
                    // background: ' pink',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // background: 'yellow',
                        textAlign: 'left',
                        height: '50px',
                        width: '600px',
                        paddingLeft: '18px',
                        alignContent: 'center',
                        alignItems: 'center',
                        borderBottom: ' #f6f8f9 solid 1px',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            // borderBottom: ' #d8d8d8 solid 1px',
                        }}
                    >
                        <Typography
                            sx={{ fontFamily: 'Open Sans', color: '#787878' }}
                            variant='h6'
                        >
                            Are you sure you want to leave?
                        </Typography>
                    </Grid>
                </Grid>
                <DialogContent
                    dividers
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: 'auto',
                    }}
                >
                    <DialogContentText>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            TEXT HERE
                        </Grid>
                    </DialogContentText>
                </DialogContent>{' '}
                <DialogActions
                    sx={{
                        borderTop: ' #d8d8d8 solid 1px',
                        // minHeight: '60px',
                        background: ' #eeeeee',
                    }}
                >
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color='neutral'
                            // autoFocus
                            onClick={onClose}
                        >
                            Cancel
                        </CustomButton>
                    </Grid>
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color='green'
                            onClick={onOk}
                            // disabled={cancelReason === ''}
                            // autoFocus
                            sx={{
                                top: isMobile || isIpad ? '10%' : '50%',
                            }}
                        >
                            Move
                        </CustomButton>
                    </Grid>
                </DialogActions>
            </Grid>
        </Dialog>
    );
};

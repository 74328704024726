import {
    useMediaQuery,
    Grid,
    Typography,
    List,
    ListItem,
    Tooltip,
} from '@mui/material';
import { CustomTextField } from '../../../layout/styledcomponents/CustomTextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useGetIndividualClientQuery } from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import moment from 'moment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import ClientUserListComponent from './ClientUserList';
import { CustomCheckbox } from '../../../layout/styledcomponents/CustomCheckbox';

export default function ViewIndividualClient() {
    const urlRef = window.location.href.split('/')[5];
    const { data: getIndividualClient } = useGetIndividualClientQuery(
        `/owners/${urlRef}`
    );
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const navigate = useNavigate();

    const onClickGoBack = () => {
        navigate(`/clients`);
    };

    const simplifiedAddress = `${getIndividualClient?.streetAddress1}, ${getIndividualClient?.streetAddress2} ${getIndividualClient?.suburb} ${getIndividualClient?.postcode} ${getIndividualClient?.state} ${getIndividualClient?.countryCode}`;

    return (
        <Grid sx={{ width: '100%', height: '100%' }}>
            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: isIpad ? '0px' : '30px',
                    height: 'auto',
                }}
            >
                <Typography
                    component='h4'
                    // variant='h6'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        fontWeight: 900,
                        paddingTop: isMobile ? '30px' : isIpad ? '0px' : '0px',
                        paddingBottom: '30px',
                    }}
                >
                    Client
                </Typography>

                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Company
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            disabled
                            value={getIndividualClient?.name}
                            fullWidth={isMobile || isIpad}
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Primary Contact
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <CustomTextField
                            disabled
                            placeholder='Smith'
                            value={getIndividualClient?.primaryContact}
                            fullWidth={isMobile || isIpad}
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Phone
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            disabled
                            placeholder='0414789456'
                            type='tel'
                            value={getIndividualClient?.phone}
                            fullWidth={isMobile || isIpad}
                            // color={isValidPhone ? 'green' : 'neutral'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            No. Users
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <CustomTextField
                            disabled
                            value={getIndividualClient?.activeUsers}
                            fullWidth={isMobile || isIpad}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Address
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            disabled
                            value={simplifiedAddress}
                            fullWidth={isMobile || isIpad}
                            // color={isValidPhone ? 'green' : 'neutral'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Grid sx={{ marginRight: '5px' }}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Status
                            </Typography>
                        </Grid>

                        <Grid>
                            <Tooltip
                                title={
                                    <Typography
                                        // color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <List>
                                            <ListItem>
                                                {' '}
                                                Enabled: Clients can access the
                                                site and add/edit users
                                            </ListItem>
                                            <ListItem>
                                                {' '}
                                                Disabled: Clients will not be
                                                able to access the site
                                            </ListItem>
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <CustomTextField
                            disabled
                            fullWidth={isMobile || isIpad}
                            value={
                                getIndividualClient?.isActive === true
                                    ? 'Enabled'
                                    : 'Disabled'
                            }
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Client Since
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            disabled
                            fullWidth={isMobile || isIpad}
                            value={moment(
                                getIndividualClient?.createdAt
                            ).format('DD/MM/YYYY')}
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        {' '}
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Notification Email{' '}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <CustomTextField
                            disabled
                            value={getIndividualClient?.notificationsEmail}
                            fullWidth={isMobile || isIpad}
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Grid sx={{ marginRight: '5px' }}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Allow multiple domains
                            </Typography>
                        </Grid>

                        <Grid>
                            <Tooltip
                                title={
                                    <Typography
                                        // color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <List>
                                            <ListItem>
                                                {' '}
                                                If checked, users of this company will be
                                                allowed to add email addresses
                                                that don't belong to their 
                                                organsation to access 7DAY, such as personal emails. If
                                                unchecked, users cannot add an
                                                email address that is outside
                                                their organisation.  
                                            </ListItem>
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomCheckbox
                            checked={getIndividualClient?.allowsMultipleDomains}
                            // sx={{ marginLeft: -1 }}
                            disabled
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    ></Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    ></Grid>
                </Grid>
            </Grid>

            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '45%',
                }}
            >
                <Typography
                    component='h4'
                    // variant='h6'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        fontWeight: 900,
                        paddingTop: isMobile ? '0px' : isIpad ? '10px' : '30px',
                        height: '10%',
                    }}
                >
                    Client Login
                </Typography>

                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                        paddingTop: isMobile ? '10px' : isIpad ? '0px' : '30px',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Email
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            disabled
                            value={getIndividualClient?.account.email}
                            fullWidth={isMobile || isIpad}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        {/* <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Password
                        </Typography> */}
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        {/* <CustomTextField
                            value={'********'}
                            disabled
                            fullWidth={isMobile || isIpad}
                            color={'neutral'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        /> */}
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                        height: isMobile || isIpad ? '10px' : '25%',
                    }}
                >
                    {isMobile || isIpad ? null : (
                        <>
                            {' '}
                            <Grid
                                sx={{
                                    width: isMobile || isIpad ? '100%' : '20%',
                                    height: '50px',
                                    marginRight:
                                        !isMobile || !isIpad ? '50px' : '0px',
                                }}
                            ></Grid>
                            <Grid
                                sx={{
                                    width: isMobile || isIpad ? '100%' : '30%',
                                    height: '50px',
                                }}
                            ></Grid>
                            <Grid
                                sx={{
                                    width: isMobile || isIpad ? '100%' : '20%',
                                    height: '50px',
                                    marginRight:
                                        !isMobile || !isIpad ? '20px' : '0px',
                                }}
                            ></Grid>
                        </>
                    )}
                </Grid>
                <ClientUserListComponent />
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '80px' : '10%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        // backgroundColor: 'yellow',
                    }}
                >
                    <CustomButton
                        onClick={onClickGoBack}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon />{' '}
                        {isMobile ? null : (
                            <Typography
                                sx={{ fontSize: '12px', paddingLeft: '5px' }}
                            >
                                Back to clients
                            </Typography>
                        )}
                    </CustomButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

import {
    Grid,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React from 'react';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import GenericModal from '../../shared/GenericModal';
import { ModalProps } from '../../types/modal';
import SpaceCard from './SpaceCard';
import { ModifiedSpace } from './Spaces/IndividualSpace';
import { SpaceType } from './Spaces/SpaceModal';

interface Props extends ModalProps {
    spaces: ModifiedSpace[];
    selectedSpace?: ModifiedSpace;
    maxNumberOfBeds: number;
}

export const sortSpacesByType = (spaces: Array<any>) => {
    const spaceTypeOrder = [
        SpaceType.Bedroom,
        SpaceType.LivingRoom,
        SpaceType.DiningArea,
        SpaceType.Boardroom,
        SpaceType.GameRoom,
        SpaceType.Garage,
        SpaceType.Kitchen,
        SpaceType.Bathroom,
    ];

    return [...spaces].sort((a, b) => {
        return (
            spaceTypeOrder.indexOf(a.spaceType) -
            spaceTypeOrder.indexOf(b.spaceType)
        );
    });
};

const IndividualExperienceSpacesModal = ({
    open,
    onClose,
    spaces,
    selectedSpace,
    maxNumberOfBeds,
}: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width: 780px)');

    const sortedSpaces = sortSpacesByType(spaces);

    return (
        <GenericModal open={open} onClose={onClose}>
            <Stack>
                <Grid
                    container
                    item
                    spacing={4}
                    sx={{
                        padding: '30px',
                        maxHeight: '700px',
                        overflow: 'auto',
                        maxWidth: '700px',
                        display: 'flex',
                    }}
                >
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '3px',
                        }}
                    >
                        {' '}
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                color: 'text.primary',
                            }}
                        >
                            Space Configurations
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '12px',
                            }}
                        >
                            Explore the amenities and features offered in this
                            experience.
                        </Typography>
                    </Grid>{' '}
                    {sortedSpaces.map((space, index) => (
                        <Grid item xs={12} md={6}>
                            <SpaceCard
                                key={index}
                                space={space}
                                selected={
                                    selectedSpace
                                        ? selectedSpace.name === space.name
                                        : false
                                }
                                maxNumberOfBeds={maxNumberOfBeds}
                                cursor='default'
                            />
                        </Grid>
                    ))}
                </Grid>

                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '10px',
                        borderTop: `solid 1px ${theme.palette.custom.modalActionBorder}`,
                        background: theme.palette.custom.modalActionBackground,
                    }}
                >
                    <CustomButton
                        width={isMobile ? '100%' : 'auto'}
                        variant='contained'
                        color='neutral'
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Close
                    </CustomButton>
                </Grid>
            </Stack>
        </GenericModal>
    );
};

export default IndividualExperienceSpacesModal;

import AddCircleIcon from '@mui/icons-material/AddCircle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    openFilterUserAction,
    selectFilteredUserData,
} from '../../../app/Slices/UserFilterSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import DropdownFilter from '../../../layout/styledcomponents/filters/Dropdown';
import UserRole from '../../../shared/AuthRoles';
import ImportUsersModal from './ImportUsersModal';

export default function UsersHero() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedInUser = UserRole();
    const filterResults: any = useSelector(selectFilteredUserData);
    const [role, setRole] = useState(filterResults?.role || 'All');
    const [status, setStatus] = useState(filterResults?.status || 'All');
    let url = window.location.href.split('/')[3];
    const [showModal, setShowModal] = useState(false);

    const handleAddClick = () => {
        navigate(`/newUser`, { state: { name: 'newUser' } });
        ReactGA.send({
            hitType: 'pageview',
            page: `/newUser`,
            title: `New User`,
        });
    };

    const handleRole = (value: any) => {
        setRole(value);
        dispatch(
            openFilterUserAction({
                filteredData: {
                    status: status,
                    role: value,
                },
            })
        );
    };
    const handleStatus = (value: any) => {
        setStatus(value);
        dispatch(
            openFilterUserAction({
                filteredData: {
                    status: value,
                    role: role,
                },
            })
        );
    };
    return (
        <>
            <Grid
                sx={{
                    textAlign: ' right',
                    paddingBottom: '10px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: isMobile ? '0' : '10px',
                }}
            >
                {loggedInUser.role === 'guest_admin' && (
                    <Grid sx={{ display: 'flex', padding: '0' }}>
                        {isMobile ? (
                            <CustomIconButton
                                variant='contained'
                                color='green'
                                onClick={() => setShowModal(true)}
                                sx={{
                                    padding: '0',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#5cb5b3',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                }}
                            >
                                <GroupAddIcon />{' '}
                            </CustomIconButton>
                        ) : (
                            <CustomButton
                                variant='contained'
                                color='green'
                                startIcon={<GroupAddIcon />}
                                onClick={() => setShowModal(true)}
                            >
                                IMPORT USERS
                            </CustomButton>
                        )}
                    </Grid>
                )}

                {isMobile ? (
                    <CustomIconButton
                        variant='contained'
                        color='green'
                        onClick={handleAddClick}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <AddCircleIcon />
                    </CustomIconButton>
                ) : (
                    <CustomButton
                        variant='contained'
                        color='green'
                        startIcon={<AddCircleIcon />}
                        onClick={handleAddClick}
                    >
                        {url === 'clients'
                            ? 'New Client'
                            : loggedInUser?.role === 'guest_department_admin'
                            ? 'Add Team Member'
                            : 'New User'}
                    </CustomButton>
                )}
            </Grid>
            <Grid
                sx={{
                    width: '100%',
                    minHeight: 'auto',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: 'center',
                    // backgroundColor: 'yellow',
                }}
            >
                <Grid
                    sx={{
                        width: isMobile ? '100%' : '25%',
                        // backgroundColor: 'blue',
                        marginRight: isMobile ? '0px' : '30px',
                    }}
                >
                    <DropdownFilter
                        id={'statusFilter'}
                        options={[
                            { value: 'All', label: 'All' },
                            { value: 'enabled', label: 'Enabled' },
                            { value: 'disabled', label: 'Disabled' },
                        ]}
                        handleChange={handleStatus}
                        selectedValue={
                            <Grid
                                sx={{ display: 'flex', flexDirection: 'row' }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '16px',
                                        marginRight: '5px',
                                    }}
                                >
                                    Status:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                >
                                    {status}
                                </Typography>
                            </Grid>
                        }
                        backgroundColor={'white'}
                        border={'#C3C3C3'}
                        minHeight={'30px'}
                        maxHeight={'33px'}
                    />
                </Grid>
                <ImportUsersModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
                <Grid sx={{ width: isMobile ? '100%' : '25%' }}>
                    {' '}
                    <DropdownFilter
                        id={'roleFilter'}
                        options={[
                            { value: 'All', label: 'All' },
                            { value: 'admin', label: 'Admin' },
                            { value: 'user', label: 'User' },
                        ]}
                        handleChange={handleRole}
                        selectedValue={
                            <Grid
                                sx={{ display: 'flex', flexDirection: 'row' }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '16px',
                                        marginRight: '5px',
                                    }}
                                >
                                    Access:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                >
                                    {role}
                                </Typography>
                            </Grid>
                        }
                        backgroundColor={'white'}
                        border={'#C3C3C3'}
                        minHeight={'30px'}
                        maxHeight={'33px'}
                    />
                </Grid>
            </Grid>
        </>
    );
}

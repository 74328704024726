import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { CustomChip } from '../../../layout/styledcomponents/CustomChip';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import { useNavigate } from 'react-router-dom';
import {  useGetIndividualClientQuery } from '../../../app/Slices/SevenDayApiSlice';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 900,
        color: '#000',
        borderBottom: '1px solid #787878',
        // textTransform: 'upperCase'
    },
    [`& .${gridClasses.row}`]: {
        // backgroundColor: '#f6f8f9',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            // fontWeight: 'bold',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                    // color: 'red',
                },
            },
        },
    },
}));

export default function ClientUserListComponent() {
    const [url, setUrl] = useState('');
    const urlRef = window.location.href.split('/')[5];

    const { data: getIndividualOwnerCompany, isFetching } =
        useGetIndividualClientQuery(url);


    useEffect(() => {
        if (urlRef !== undefined) {
            setUrl(`/owners/${urlRef}/users`);
        }
    }, []);

    

    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');



    const columns: GridColDef[] = [
        {
            field: 'firstName',
            headerName: 'Guest',
            type: 'string',
            // width: isMobile ? 261 : isIpad ? 735 : 1327,
            flex: 1,
            minWidth: 150,
            sortable: true,
            renderCell: (params) => {
                return (
                    <List>
                        <ListItem sx={{ padding: '0!important' }}>
                            {isMobile ? null : (
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            bgcolor: '#E0E0E0',
                                        }}
                                    >
                                        {params?.value?.charAt(0)}
                                        {params?.row.lastName?.charAt(0)}
                                    </Avatar>
                                </ListItemAvatar>
                            )}
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {params?.value}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {params?.row?.email}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                );
            },
        },
        {
            field: 'lastLoginAt',
            headerName: 'Last Login',
            width: isMobile ? 180 : isIpad ? 180 : 180,
            renderCell: (params) => {
                return (
                    <List>
                        <ListItem sx={{ padding: '0!important' }}>
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {params?.value
                                            ? moment(params?.value).format(
                                                  'DD/MM/YYYY'
                                              )
                                            : '-'}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {params?.value
                                            ? moment(params?.value).format(
                                                  'h:mm a'
                                              )
                                            : ''}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                );
            },
            sortable: true,
        },
        {
            field: 'isActive',
            headerName: 'Status',
            width: isMobile ? 5 : isIpad ? 50 : 130,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                return isIpad || isMobile ? (
                    params.value === true ? (
                        <Tooltip title='Enabled'>
                            <DoneIcon sx={{ color: '#5cb5b3' }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title='Enabled'>
                            <BlockIcon sx={{ color: '#787878' }} />
                        </Tooltip>
                    )
                ) : (
                    <CustomChip
                        style={{
                            fontFamily: 'Open Sans',
                        }}
                        label={
                            params.value === true ? (
                                <Typography
                                    color='green'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                    }}
                                >
                                    Enabled
                                </Typography>
                            ) : (
                                <Typography
                                    color='red'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                    }}
                                >
                                    Disabled
                                </Typography>
                            )
                        }
                        color={params.value === true ? 'green' : 'red'}
                        variant='outlined'
                    />
                );
            },
        },
       
    ];

    return (
        <Box sx={{ height: 700, width: '100%' }}>
            <StripedDataGrid
                // onRowClick={handleRowClick}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                sx={{
                    boxShadow: 0,
                    border: 0,
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={getIndividualOwnerCompany !== undefined ? getIndividualOwnerCompany : []}
                columns={columns}
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Users
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please add a new user or
                                                adjust the filters
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Results
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please try again
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                }}
            />
                
        </Box>
    );
}

import { Grid, Typography } from '@mui/material';
import React from 'react';

import { Link } from 'react-router-dom';

export default function PrivacyPolicyComponent() {
    return (
        <Grid sx={{ marginTop: '30px' }}>
            <Grid sx={{ paddingLeft: '0px' }}>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Effective starting: September 20, 2023
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        What our policy covers
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        Your privacy is important to us, and so is being
                        transparent about how we collect, use, and share
                        information about you.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        This Privacy Policy covers the information we collect
                        about you when you use our products or services, or
                        otherwise interact with us (for example, by attending
                        our premises or events or by communicating with us),
                        unless a different policy is displayed.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        If you do not agree with this policy, do not access or
                        use our Services or interact with any other aspect of
                        our business.
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        What information we collect about you
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        We collect information about you when you provide it to
                        us, when you use our Services, and when other sources
                        provide it to us, as further described below.
                        <ul>
                            <li>
                                Account and Profile Information - When you
                                register or add a user, edit, set preferences we
                                keep this information
                            </li>
                            <li>
                                Reservations - When you make a reservation, edit, and
                                complete relevant fields we keep this
                                information
                            </li>
                            <li>
                                Support - When you submit a support enquiry or a
                                website enquiry we keep this information
                            </li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        How we use information we collect
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        How we use the information we collect depends on which
                        Services you use, how you use them, and any preferences
                        you have communicated to us. Below are the specific
                        purposes for which we use the information we collect
                        about you.
                        <ul>
                            <li>
                                Account and Profile Information - When you
                                register or add a user, edit, set preferences we
                                keep this information and only share this
                                information with the owner of the experience
                                when you make a reservation or an admin of your
                                company
                            </li>
                            <li>
                            Reservations - When you make a reservation, edit, and
                                complete relevant fields we keep this
                                information and only share this inforamtion with
                                the owner of the experience when you make a
                                reservation or an admin of your company
                            </li>
                            <li>
                                Experiences - When you create an experience,
                                edit, and complete relevant fields we keep this
                                information and only share this inforamtion with
                                registered users of the 7DAY app.
                            </li>
                            <li>
                                Support - When you submit a support enquiry or a
                                website enquiry we keep this information and
                                only share it internally or an admin of your
                                company
                            </li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '40px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        How we store information we collect
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        Our product is built on the AWS (Amazon Web Services)
                        platform. Any information that we collect is securely
                        stored by this leading cloud platform provider.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                            fontStyle: 'italic',
                        }}
                    >
                        AWS makes security its top priority, providing a data
                        centre and network architecture built to meet the
                        requirements of the most security-sensitive
                        organisations. AWS is constantly evolving its core
                        security services such as identity and access
                        management, logging and monitoring, encryption and key
                        management, network segmentation and Denial of Service
                        (DDoS) protection.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '0px',
                            fontSize: '14px',
                            fontStyle: 'italic',
                        }}
                    >
                        For more inforamtion:{' '}
                        <Link
                            style={{
                                color: '#787878',
                                textDecoration: 'underline',
                            }}
                            target='_blank'
                            // @ts-ignore
                            to={`https://aws.amazon.com/security/?nc1=f_cc`}
                        >
                            https://aws.amazon.com/security
                        </Link>{' '}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingTop: '10px',
                            fontSize: '14px',
                        }}
                    >
                        We keep your data safe by adhering to industry best
                        practices. Not only does AWS have an extensive and
                        constant Cyber Secuirty presense but 7DAY continuously
                        monitors our AWS environment.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

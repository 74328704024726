import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import {
    useEditPlatformSettingsMutation,
    useGetPlatformSettingsQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import UserRole from '../../../../shared/AuthRoles';
import toast from '../../../shared/Toast';

const AppFeeForm = () => {
    const loggedInUser = UserRole();
    const navigate = useNavigate();
    const { data: getPlatformSettings } =
        useGetPlatformSettingsQuery('/platform-settings');

    const [editPlatformSettings] = useEditPlatformSettingsMutation();
    const [serviceFee, setServiceFee] = useState<number | null>(
        getPlatformSettings?.serviceFeePercentage
    );
    const handleServiceFee = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setServiceFee(value);
    };

    useEffect(() => {
        setServiceFee(getPlatformSettings?.serviceFeePercentage);
        if (loggedInUser.role !== 'admin') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'adminCenterPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [getPlatformSettings, loggedInUser.role]);

    const updatePlatformSettings = () => {
        const requestBody = {
            serviceFeePercentage: serviceFee,
        };

        editPlatformSettings(requestBody)
            .unwrap()
            .then(() => {
                toast.success(`Successfully updated platform settings`);
            })
            .catch(() => {
                toast.error(
                    'Failed to update platform settings. Please try again.'
                );
            });
    };

    return (
        <Grid
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    justifyContent: 'left',
                    display: 'flex',
                }}
            >
                <Typography
                    component='h1'
                    variant='h5'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        textDecoration: 'underline',
                        textDecorationColor: '#5cb5b3',
                        textUnderlineOffset: '8px',
                    }}
                >
                    7DAY App Fees
                </Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid item container columns={25} spacing={1}>
                    <Grid
                        item
                        xs={12}
                        lg={5}
                        sx={{ display: 'flex', gap: '2px' }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Service Fee %
                        </Typography>
                        <Grid>
                            <Tooltip
                                title={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        7DAY service fee is a percentage cut of
                                        the overall price/fee per transaction
                                        made on 7DAY. This is a source of
                                        revenue for 7DAY.
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={25} lg={9}>
                        <CustomTextField
                            type='number'
                            placeholder='10'
                            color={'neutral'}
                            fullWidth={true}
                            value={serviceFee}
                            inputProps={{
                                min: 0,
                                step: 1,
                                style: {
                                    height: '10px',
                                },
                                onWheel: (e: any) => e.target.blur(),
                            }}
                            onChange={handleServiceFee}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item container columns={25} spacing={1}>
                    <Grid
                        item
                        xs={12}
                        lg={5}
                        sx={{ display: 'flex', gap: '2px' }}
                    ></Grid>
                    <Grid
                        item
                        xs={25}
                        lg={9}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <CustomButton
                            variant='contained'
                            color='green'
                            onClick={updatePlatformSettings}
                        >
                            Update
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AppFeeForm;

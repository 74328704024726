import { MessageRepository } from '@amityco/ts-sdk';
import {
    Box,
    CircularProgress,
    Divider,
    Fade,
    Stack,
    Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import MessageItem from '../MessageItem';
import moment from 'moment-timezone';
import { Users } from '../../../types/messaging';
import { Client } from '@amityco/ts-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { selectAmityNewMessage, setAmityNewMessage } from '../../../../app/Slices/AmityNewMessageSlice';

interface MessageListProps {
    channelId: string;
    users: Users;
}

const disposers: Amity.Unsubscriber[] = [];

const MessageList = ({ channelId, users }: MessageListProps) => {
    const isMessage = useSelector(selectAmityNewMessage);
    const [messages, setMessages] = useState<Amity.Message[]>([]);
    const dispatch = useDispatch();
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [nextPageFn, setNextPageFn] = useState<() => void>();

    const groupMessages = (messages: Amity.Message[]) => {
        const grouped: { [key: string]: Amity.Message[] } = {};

        messages.forEach((message) => {
            const date = new Date(message.createdAt).toDateString();
            if (!grouped[date]) {
                grouped[date] = [];
            }
            grouped[date].push(message);
        });

        return grouped;
    };

    const groupedMessages = groupMessages(messages);

    const formatDate = (dateString: string) => {
        return moment(dateString).format('ddd, DD MMM');
    };


    //Check for new messages
    //if new message we need to retreive from amity

    if (isMessage.newMessage) {
        MessageRepository.getMessages(
            {
                subChannelId: channelId,
                limit: 20,
            },
            ({ data: messages, hasNextPage, onNextPage, loading, error }) => {
                if (messages && !loading && !error) {
                    setMessages(messages);
                    dispatch(
                        setAmityNewMessage({
                            newMessage: false
                        })
                    );
                    messages.forEach((message) => message.markRead());
                }

                if (hasNextPage && onNextPage) {
                    setHasMore(hasNextPage);
                    setNextPageFn(() => onNextPage);
                    dispatch(
                        setAmityNewMessage({
                            newMessage: false
                        })
                    );
                }
            }
        );
    }



    useEffect(() => {
        setMessages([]); // Clear messages when channel changes
        setNextPageFn(undefined); // Reset nextPageFn when channel changes
        setHasMore(false); // Reset hasMore when channel changes

        const unsub = MessageRepository.getMessages(
            {
                subChannelId: channelId,
                limit: 20,
            },
            ({ data: messages, hasNextPage, onNextPage, loading, error }) => {
                if (messages && !loading && !error) {
                    setMessages(messages);
                    messages.forEach((message) => message.markRead());
                }

                if (hasNextPage && onNextPage) {
                    setHasMore(hasNextPage);
                    setNextPageFn(() => onNextPage);
                }
            }
        );

        disposers.push(unsub);

        return () => {
            console.log('unsub');
            disposers.forEach((fn) => fn());
        };
    }, [channelId]);

    const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if (!nextPageFn || !hasMore) return;

        const el = e.currentTarget;

        const top = el.scrollHeight - el.clientHeight;
        const scroll = -el.scrollTop; // reversed because of the flex direction

        if (top - scroll <= 1) {
            nextPageFn();
        }
    };

    const isWithinTenMinutes = (
        current: Amity.Message,
        next: Amity.Message
    ) => {
        const currentTime = new Date(current.createdAt).getTime();
        const nextTime = new Date(next.createdAt).getTime();

        return (nextTime - currentTime) / 1000 < 600;
    };

    const checkHasNext = (messages: Amity.Message[], index: number) => {
        const currentMessage = messages[index];
        const nextMessage = messages[index - 1]; // messages are rendered in reverse order
        return (
            nextMessage &&
            currentMessage.creatorId === nextMessage.creatorId &&
            isWithinTenMinutes(currentMessage, nextMessage)
        );
    };

    return (
        <Stack
            spacing={0.5}
            p={3}
            direction='column-reverse'
            overflow='scroll'
            flex={1}
            onScroll={onScroll}
            // hide scrollbar
            sx={{
                '-ms-overflow-style': 'none',
                '::-webkit-scrollbar': {
                    display: 'none',
                },
            }}
        >
            {messages.length === 0 ? (
                <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    width='100%'
                    height='100%'
                >
                    <CircularProgress />
                </Box>
            ) : (
                Object.keys(groupedMessages).map((date) => (
                    <>
                        {groupedMessages[date].map((message, index) => (
                            <MessageItem
                                key={message.messageId}
                                {...message}
                                user={users && users[+message.creatorId]}
                                hasNext={checkHasNext(
                                    groupedMessages[date],
                                    index
                                )}
                            />
                        ))}
                        <Divider>
                            <Typography
                                variant='subtitle2'
                                color='textSecondary'
                            >
                                {formatDate(date.toString())}
                            </Typography>
                        </Divider>
                    </>
                ))
            )}
        </Stack>
    );
};

export default MessageList;

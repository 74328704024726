import React from 'react';
import { Link } from 'react-router-dom';

interface LinkCellProps {
    params: any;
    href: string;
    state: object;
    align?: 'left' | 'center' | 'right';
    paddingLeft?: string;
}

const LinkCell: React.FC<LinkCellProps> = ({
    params,
    href,
    state,
    align = 'left',
    paddingLeft = '0',
}) => {
    return (
        <Link
            to={href}
            state={state}
            style={{
                textDecoration: 'none',
                color: 'inherit',
                width: '100%',
                height: '100%',
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'flex',
                alignItems: 'center',
                justifyContent:
                    align === 'right'
                        ? 'flex-end'
                        : align === 'center'
                        ? 'center'
                        : 'flex-start',
                outline: 'none',
                cursor: 'pointer',
                textAlign: align,
                paddingLeft,
            }}
        >
            {params.value}
        </Link>
    );
};

export default LinkCell;

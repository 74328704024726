import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useDeleteAssetMutation,
    useDeleteClientMutation,
} from '../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../layout/styledcomponents/CustomTextField';
import UserRole from '../../shared/AuthRoles';
import toast from './Toast';

export default function ConfirmationModal(props: any) {
    const { onClose, open, onChange, message, component, id } = props;
    const [deleteAsset] = useDeleteAssetMutation();
    const urlRef = window.location.href.split('/')[4];
    const role = UserRole();
    const [deleteClientReq] = useDeleteClientMutation();
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [deleteReason, setDeleteReason] = useState('');
    const navigate = useNavigate();

    const handleReason = (event: any) => {
        setDeleteReason(event.target.value);
    };

    const handleClose = () => {
        onClose();
    };

    const handleCloseChangeModal = () => {
        onChange();
        if (component === 'experience') {
            deleteExperience();
        } else if (component === 'user') {
            //TB Implemented
            // deleteUser()
        } else if (component === 'client') {
            deleteClient();
        }
    };

    const confirmDelete = () => {
        handleCloseChangeModal();
    };

    const deleteExperience = () => {
        let requestInfo = [role.company, urlRef];
        deleteAsset(requestInfo).then((data: any) => {
            if (data?.error) {
                toast.error(
                    `Failed to delete experience. Cannot delete an experience with reservations`
                );
            } else {
                toast.success(`Listing has been deleted`);
                navigate(`/viewExperiences/${role?.company}`);
            }
        });
    };

    const deleteClient = () => {
        deleteClientReq(id).then((data: any) => {
            if (data) {
                toast.success(`Client has been deleted`);
            } else {
                toast.error(`Failed to delete client`);
            }
        });
        navigate(`/clients`);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid
                sx={{
                    // background: ' pink',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // background: 'yellow',
                        textAlign: 'left',
                        height: '50px',
                        width: '600px',
                        paddingLeft: '18px',
                        alignContent: 'center',
                        alignItems: 'center',
                        borderBottom: ' #f6f8f9 solid 1px',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            // borderBottom: ' #d8d8d8 solid 1px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: isMobile ? '14px' : '',
                            }}
                            variant='h6'
                        >
                            Are you sure you want to {message}?
                        </Typography>
                    </Grid>
                </Grid>
                <DialogContent
                    dividers
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '200px',
                    }}
                >
                    <DialogContentText>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    paddingRight: '10px',
                                }}
                            >
                                Reason:
                            </Typography>
                            <CustomTextField
                                required
                                onChange={handleReason}
                                placeholder=''
                                fullWidth
                                color='neutral'
                                inputProps={{
                                    style: {
                                        width: isMobile ? '200px' : '450px',
                                        // width: isMobile
                                        //     ? '350px'
                                        //     : isIpad
                                        //     ? '200px'
                                        //     : '40px',
                                    },
                                }}
                            />
                        </Grid>
                    </DialogContentText>
                </DialogContent>{' '}
                <DialogActions
                    sx={{
                        borderTop: ' #d8d8d8 solid 1px',
                        // minHeight: '60px',
                        background: ' #eeeeee',
                    }}
                >
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color='neutral'
                            // autoFocus
                            onClick={handleClose}
                        >
                            Cancel
                        </CustomButton>
                    </Grid>
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color='warning'
                            onClick={confirmDelete}
                            disabled={deleteReason === ''}
                            // autoFocus
                            sx={{
                                top: isMobile || isIpad ? '10%' : '50%',
                            }}
                        >
                            Delete
                        </CustomButton>
                    </Grid>
                </DialogActions>
            </Grid>
        </Dialog>
    );
}

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSendInviteMutation } from '../../../app/Slices/SevenDayApiSlice';
import { openInviteSliceAction } from '../../../app/Slices/inviteSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import toast from '../../shared/Toast';

export default function InviteModal(props: any) {
    const { onClose, open } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const urlRef = window.location.href.split('/')[5];
    const [sendInvite] = useSendInviteMutation();

    const handleClose = () => {
        onClose();
        dispatch(
            openInviteSliceAction({
                view: 'inviteAborted',
            })
        );
    };

    const handleSendInvite = () => {
        onClose();
        //send invite to user to set password

        dispatch(
            openInviteSliceAction({
                view: 'inviteSent',
            })
        );
        let requestBody = {
            id: parseInt(urlRef),
        };
        sendInvite(requestBody).then((data: any) => {
            if (data) {
                if (data?.error?.data) {
                    toast.success(`Invite link has been sent to user`);
                } else {
                    toast.error(`Failed to send invite link`);
                }
            } else {
                toast.error(
                    `Failed to send invite link, please check email is correct`
                );
            }
        });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid
                sx={{
                    // background: ' pink',
                    width: '100%',
                    height: 'auto',
                }}
            >
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // background: 'yellow',
                        textAlign: 'left',
                        height: '50px',
                        width: 'auto',
                        paddingLeft: '18px',
                        alignContent: 'center',
                        alignItems: 'center',
                        borderBottom: ' #f6f8f9 solid 1px',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            // borderBottom: ' #d8d8d8 solid 1px',
                        }}
                    >
                        <Typography
                            sx={{ fontFamily: 'Open Sans', color: '#787878' }}
                            variant='h6'
                        >
                            Send Access Invite
                        </Typography>
                    </Grid>
                </Grid>
                <DialogContent
                    dividers
                    sx={
                        {
                            // display: 'flex',
                            // alignItems: 'center',
                            // justifyContent: 'center',
                            // flexDirection: 'column',
                            // height: '500px',
                        }
                    }
                >
                    <DialogContentText>
                        <Grid
                            sx={{ overflowY: isMobile ? 'scroll' : 'hidden' }}
                        >
                            This new user will need to be invited to access
                            7DAY. By clicking 'Send Invite' the user will
                            receive and invite link to the email you provided.
                        </Grid>
                    </DialogContentText>
                </DialogContent>{' '}
                <DialogActions
                    sx={{
                        borderTop: ' #d8d8d8 solid 1px',
                        // minHeight: '60px',
                        background: ' #eeeeee',
                    }}
                >
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color='neutral'
                            // autoFocus
                            onClick={handleClose}
                        >
                            Cancel
                        </CustomButton>
                    </Grid>
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color='green'
                            onClick={handleSendInvite}
                            // disabled={cancelReason === ''}
                            // autoFocus
                            sx={{
                                top: isMobile || isIpad ? '50%' : '50%',
                            }}
                        >
                            Send Invite
                        </CustomButton>
                    </Grid>
                </DialogActions>
            </Grid>
        </Dialog>
    );
}

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function EditAnExperience() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <>
        {loggedInUser?.role.includes('admin') ? (
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Users with admin permissions can edit existing experiences.
                   
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To edit an experience:
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>From the menu, click on ‘Experiences’</li>
                            <li>Click 'Manage Experiences'</li>
                            <li>Click on the 'pencil' icon to edit/delete (this is located next to the name of the experience)</li>
                            <li>Make your edits</li>
                            <li>Scroll down to the bottom of the experience</li>
                            <li>Click 'Update'</li>
                        </ol>
                    </Typography>
                </Grid>
            </Grid>
        ) : (
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Only admins can edit an experience. Please contact
                        a person that is an admin in your organisation to
                        add, edit or delete an experience.
                    </Typography>
                </Grid>
            </Grid>
        )}
    </>
    );
}

import { Box, List, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import ReservationPolicy from './ReservationPolicy'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { ReservationPolicy as ReservationPolicyType } from '../../../types/policy'

const ReservationPolicies = () => {
    const { control, reset, getValues } = useFormContext();

    const { fields } = useFieldArray({
        name: 'policies',
        control: control,
        shouldUnregister: false,
    });

    return (
        <Box pt='30px'>
            <Typography
                variant='h5'
                color='#787878'
                gutterBottom
            >
                Reservation Policies
            </Typography>
            <Typography
                color='#787878'
                gutterBottom
            >
                The host of this property requires
                guests to acknowledge and accept the
                following policy/s before confirming
                the reservation.
            </Typography>
            <List>
                {fields.map((policy: unknown, index) => (
                    <ReservationPolicy 
                        key={index}
                        policy={policy as ReservationPolicyType}
                        control={control}
                        index={index}
                    />
                ))}
            </List>
        </Box>
    )
}

export default ReservationPolicies
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface IState {
    value: IType;
}

interface IType {
    view?: any;
}

const initialState: IState = {
    value: {
        view: 'none',
    },
};
export const slice = createSlice({
    name: 'inviteSlice',
    initialState: { ...initialState },
    reducers: {
        openInviteSliceAction: (state, action: PayloadAction<IType>) => {
            state.value.view =
                action.payload && action.payload.view;
        },
    },
});
export const { openInviteSliceAction } = slice.actions;
export const selectInviteSliceData = (state: RootState) =>
    state.inviteSlice.value
export const FilterInviteSliceReducer = slice.reducer;

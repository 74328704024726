import { styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiTablePagination-toolbar': {
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows': {
        margin: '0',
    },
    '& .MuiTablePagination-selectLabel': {
        margin: '0',
    },

    '& .MuiDataGrid-cell:focus-within': {
        outline: 'none',
    },
    '& .MuiDataGrid-columnHeader': {
        fontSize: '12px',
        color: theme.palette.custom.columnHeader,
        borderBottom: `1px solid ${theme.palette.custom.columnBorder}`,
        paddingLeft: '0px',
    },
    '& .custom-padding-header': {
        paddingLeft: '30px',
    },
    [`& .${gridClasses.row}`]: {
        '&:hover, &.Mui-hovered': {
            color: theme.palette.primary.main,
        },
    },
}));

export default StripedDataGrid;

import {
    Grid,
    IconButton,
    InputAdornment,
    Popover,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
    useGetAllReservationsQuery,
    useGetReportQuery,
} from '../../../../../app/Slices/SevenDayApiSlice';
import DropdownFilter from '../../../../../layout/styledcomponents/filters/Dropdown';
import { Calendar } from 'react-date-range';
import { CustomTextField } from '../../../../../layout/styledcomponents/CustomTextField';
import moment from 'moment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { startOfYear } from 'date-fns';
import { CustomButton } from '../../../../../layout/styledcomponents/CustomButton';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSelector } from 'react-redux';
import { selectReportAPIRefSliceData } from '../../../../../app/Slices/ReportAPIRefSlice';
import CustomisedReportGraph from './CustomisedReportGraph';
import CustomisedListReport from './CustomisedReportList';
import UserRole from '../../../../../shared/AuthRoles';
import MultiSelectDropdownFilter from '../../../../../layout/styledcomponents/filters/MultiSelectDropdown';

export default function CustomisedComponentPage() {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const [openCalendar1, setOpenCalendar1] = useState(false);
    const [anchorElCalendar1, setAnchorElCalendar1] =
        React.useState<null | HTMLElement>(null);
    const [openCalendar2, setOpenCalendar2] = useState(false);
    const [anchorElCalendar2, setAnchorElCalendar2] =
        React.useState<null | HTMLElement>(null);
    const [companyGuestList, setGuestCompanyList] = useState<any>([]);
    const [companyOwnerList, setOwnerCompanyList] = useState<any>([]);
    const [status, setStatus] = useState<any>([
        'pending',
        'approved',
        'completed',
        'cancelled',
        'declined',
    ]);
    const [moduleType, setModuleType] = useState([
        'boat',
        'property',
        'vehicle',
        'activity',
        'commercial',
    ]);
    const [guestId, setGuestId] = useState({ value: null, label: 'All' });
    const [ownerId, setOwnerId] = useState({ value: null, label: 'All' });
    const [visitType, setVisitType] = useState('');
    const [bookerType, setBookerType] = useState('');
    const [fromDate, setFromDate] = useState(startOfYear(new Date()));
    const [toDate, setToDate] = useState(new Date());
    const [checked, setChecked] = React.useState(false);
    const reportExportAPI: any = useSelector(selectReportAPIRefSliceData);

    //REPORT VIEW
    const graphView = (value: boolean | ((prevState: boolean) => boolean)) => {
        setChecked(value);
    };

    //GET REPORT
    const { data: getReport } = useGetReportQuery(
        `/reports/bookings?from=${moment(fromDate).format(
            'YYYY-MM-DD'
        )}&to=${moment(toDate).format(
            'YYYY-MM-DD'
        )}&status=${status}&moduleType=${moduleType}&guest=${
            guestId?.value ? guestId?.value : ''
        }&owner=${
            ownerId?.value ? ownerId?.value : ''
        }&visitType=${visitType}&bookerType=${bookerType}`
    );
    const { data: getAllReservations, isFetching } = useGetAllReservationsQuery(
        {}
    );

    //GET LIST OF GUESTS

    useEffect(() => {
        let companies: any = getAllReservations?.map((val: any) => {
            return val?.guest?.name
                ? { id: val?.guest?.id, name: val?.guest?.name }
                : { id: val?.owner?.id, name: val?.owner?.name };
        });

        let ownerCompanies: any = getAllReservations?.map((val: any) => {
            return val?.owner?.name
                ? { value: val?.owner?.id, label: val?.owner?.name }
                : { value: val?.owner?.id, label: val?.owner?.name };
        });

        let filterOutDuplicatesGuest = companies?.filter(
            (value: any, index: any, self: any) =>
                index ===
                self.findIndex(
                    (t: any) =>
                        t?.name === value?.name && t?.name === value?.name
                )
        );

        let filterOutDuplicatesOwner = ownerCompanies?.filter(
            (value: any, index: any, self: any) =>
                index ===
                self.findIndex(
                    (t: any) =>
                        t?.label === value?.label && t?.label === value?.label
                )
        );

        let removeCompany = filterOutDuplicatesGuest?.filter(
            (value: any) => !value?.name.includes('BHAG')
        );

        let companyList = removeCompany?.map((val: any) => {
            return {
                value: val?.id,
                label: val?.name,
            };
        });
        companyList?.splice(0, 0, {
            value: '',
            label: 'All',
        });
        filterOutDuplicatesOwner?.splice(0, 0, {
            value: '',
            label: 'All',
        });

        setGuestCompanyList(companyList);
        setOwnerCompanyList(filterOutDuplicatesOwner);
    }, [getAllReservations]);

    const handleStatus = (value: any) => {
        setStatus(value);
    };

    const handleModuleType = (value: any) => {
        setModuleType(value);
    };
    const handleGuestId = (value: any) => {
        let obj = companyGuestList.find((o: any) => o.value === value);
        setGuestId({ value: value, label: obj.label });
    };

    const handleOwnerId = (value: any) => {
        let obj = companyOwnerList.find((o: any) => o.value === value);
        setOwnerId({ value: value, label: obj.label });
    };
    const handleVisitType = (value: any) => {
        setVisitType(value);
    };
    const handleBookerType = (value: any) => {
        setBookerType(value);
    };

    const handleCalendar1 = (event: React.MouseEvent<HTMLElement>) => {
        setOpenCalendar1(!openCalendar1);
        setAnchorElCalendar1(event.currentTarget);
    };

    const handleCalendar1Close = (event: React.MouseEvent<HTMLElement>) => {
        setOpenCalendar1(!openCalendar1);
        setAnchorElCalendar1(null);
    };

    const handleCalendar2 = (event: React.MouseEvent<HTMLElement>) => {
        setOpenCalendar2(!openCalendar2);
        setAnchorElCalendar2(event.currentTarget);
    };

    const handleCalendar2Close = (event: React.MouseEvent<HTMLElement>) => {
        setOpenCalendar2(!openCalendar2);
        setAnchorElCalendar2(null);
    };

    let statusOptions = [
        { value: 'pending', label: 'Pending' },
        {
            value: 'approved',
            label: 'Approved',
        },
        {
            value: 'completed',
            label: 'Completed',
        },
        {
            value: 'cancelled',
            label: 'Cancelled',
        },
        {
            value: 'declined',
            label: 'Declined',
        },
    ];

    let moduleTypes = [
        { value: 'boat', label: 'Boat' },
        {
            value: 'property',
            label: 'Property',
        },
        {
            value: 'activity',
            label: 'Activity',
        },
        { value: 'vehicle', label: 'Vehicle' },
        { value: 'commercial', label: 'Commercial' },
    ];

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            <Grid
                sx={{
                    textAlign: isMobile ? 'left' : 'right',
                    // backgroundColor: 'blue',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'left',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            // backgroundColor: 'red',
                        }}
                    >
                        <Grid
                            sx={{
                                // backgroundColor: 'yellow',
                                display: 'flex',
                                flexDirection: 'row',
                                width: isIpad ? '70%' : '100%',
                                paddingBottom: '10px',

                                justifyContent: 'left',
                            }}
                        >
                            {' '}
                            <Grid
                                sx={{
                                    width: isMobile
                                        ? '49%'
                                        : isIpad
                                        ? '25%'
                                        : '15%',
                                    marginRight: isMobile ? '0px' : '15px',
                                    // backgroundColor: 'green',
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}
                            >
                                <CustomTextField
                                    color='neutral'
                                    label='From'
                                    value={moment(fromDate)?.format(
                                        'DD/MM/YYYY'
                                    )}
                                    onClick={handleCalendar1}
                                    // onChange={(item:any) => [setFromDate(item)]}
                                    fullWidth={isMobile}
                                    InputProps={{
                                        style: {
                                            minHeight: '30px',
                                            maxHeight: '33px',
                                        },
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    disableRipple
                                                    onClick={handleCalendar1}
                                                >
                                                    <DateRangeIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Popover
                                    open={openCalendar1}
                                    anchorEl={anchorElCalendar1}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handleCalendar1Close}
                                >
                                    <Calendar
                                        onChange={(item) => [setFromDate(item)]}
                                        date={fromDate}
                                        weekStartsOn={1}
                                        rangeColors={['#5cb5b3']}
                                        color='#5cb5b3'
                                    />
                                </Popover>
                            </Grid>
                            <Grid
                                sx={{
                                    width: isMobile
                                        ? '50%'
                                        : isIpad
                                        ? '25%'
                                        : '15%',
                                    // marginRight: isMobile ? '0px' : '15px',
                                    // marginTop: isMobile ? '10px' : '0px',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    paddingLeft: isMobile ? '5px' : '0px',
                                }}
                            >
                                <CustomTextField
                                    label='To'
                                    color='neutral'
                                    fullWidth={isMobile}
                                    value={moment(toDate)?.format('DD/MM/YYYY')}
                                    onClick={handleCalendar2}
                                    InputProps={{
                                        style: {
                                            minHeight: '30px',
                                            maxHeight: '33px',
                                        },
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    disableRipple
                                                    onClick={handleCalendar2}
                                                >
                                                    <DateRangeIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <Popover
                                    open={openCalendar2}
                                    anchorEl={anchorElCalendar2}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handleCalendar2Close}
                                >
                                    <Calendar
                                        onChange={(item) => [setToDate(item)]}
                                        date={toDate}
                                        weekStartsOn={1}
                                        rangeColors={['#5cb5b3']}
                                        color='#5cb5b3'
                                    />
                                </Popover>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            columns={1}
                            sx={{
                                // backgroundColor: 'yellow',
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                width: '100%',
                            }}
                        >
                            <Grid
                                sx={{
                                    width: isMobile ? '100%' : '20%',
                                    marginRight: isMobile ? '0px' : '15px',
                                    marginTop: isMobile ? '10px' : '0px',
                                }}
                            >
                                {' '}
                                <MultiSelectDropdownFilter
                                    id={'status'}
                                    options={statusOptions}
                                    handleChange={handleStatus}
                                    label={'Status:'}
                                    selectedValue={status}
                                    backgroundColor={'white'}
                                    border={'#C3C3C3'}
                                    minHeight={'30px'}
                                    maxHeight={'33px'}
                                />
                            </Grid>
                            <Grid
                                sx={{
                                    width: isMobile ? '100%' : '20%',
                                    marginRight: isMobile ? '0px' : '15px',
                                    marginTop: isMobile ? '10px' : '0px',
                                }}
                            >
                                {' '}
                                <MultiSelectDropdownFilter
                                    id={'type'}
                                    options={moduleTypes}
                                    handleChange={handleModuleType}
                                    label='Type:'
                                    selectedValue={moduleType}
                                    backgroundColor={'white'}
                                    border={'#C3C3C3'}
                                    minHeight={'30px'}
                                    maxHeight={'33px'}
                                />
                            </Grid>
                            {loggedInUser?.role.includes('guest') ? null : (
                                <Grid
                                    sx={{
                                        width: isMobile ? '100%' : '20%',
                                        marginRight: isMobile ? '0px' : '15px',
                                        marginTop: isMobile ? '10px' : '0px',
                                    }}
                                >
                                    {' '}
                                    <DropdownFilter
                                        id={'guest'}
                                        options={
                                            companyGuestList !== undefined
                                                ? companyGuestList
                                                : []
                                        }
                                        handleChange={handleGuestId}
                                        selectedValue={
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '16px',
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    Guest:
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    {guestId?.label}
                                                </Typography>
                                            </Grid>
                                        }
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                        minHeight={'30px'}
                                        maxHeight={'33px'}
                                    />
                                </Grid>
                            )}
                            {loggedInUser?.role.includes === 'admin' ? null : (
                                <Grid
                                    sx={{
                                        width: isMobile ? '100%' : '20%',
                                        marginRight: isMobile ? '0px' : '15px',
                                        marginTop: isMobile ? '10px' : '0px',
                                    }}
                                >
                                    {' '}
                                    <DropdownFilter
                                        id={'ownerList'}
                                        options={
                                            companyOwnerList !== undefined
                                                ? companyOwnerList
                                                : []
                                        }
                                        handleChange={handleOwnerId}
                                        selectedValue={
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '16px',
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    Owner:
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    {ownerId?.label}
                                                </Typography>
                                            </Grid>
                                        }
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                        minHeight={'30px'}
                                        maxHeight={'33px'}
                                    />
                                </Grid>
                            )}

                            <Grid
                                sx={{
                                    width: isMobile ? '100%' : '20%',
                                    marginRight: isMobile ? '0px' : '15px',
                                    marginTop: isMobile ? '10px' : '0px',
                                }}
                            >
                                {' '}
                                <DropdownFilter
                                    id={'visitType'}
                                    options={[
                                        // { value: 'All', label: 'All' },
                                        { value: '', label: 'All' },
                                        {
                                            value: 'business',
                                            label: 'Business',
                                        },
                                        {
                                            value: 'personal',
                                            label: 'Personal',
                                        },
                                    ]}
                                    handleChange={handleVisitType}
                                    selectedValue={
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '16px',
                                                    marginRight: '5px',
                                                }}
                                            >
                                                Visit Type:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                {visitType === undefined
                                                    ? 'All'
                                                    : visitType}
                                            </Typography>
                                        </Grid>
                                    }
                                    backgroundColor={'white'}
                                    border={'#C3C3C3'}
                                    minHeight={'30px'}
                                    maxHeight={'33px'}
                                />
                            </Grid>

                            {loggedInUser?.role.includes('guest') ? null : (
                                <Grid
                                    sx={{
                                        width: isMobile ? '100%' : '20%',
                                        marginRight: isMobile ? '0px' : '15px',
                                        marginTop: isMobile ? '10px' : '0px',
                                    }}
                                >
                                    {' '}
                                    <DropdownFilter
                                        id={'bookerType'}
                                        options={[
                                            // { value: 'All', label: 'All' },
                                            { value: '', label: 'All' },
                                            { value: 'owner', label: 'Owner' },
                                            { value: 'guest', label: 'Guest' },
                                        ]}
                                        handleChange={handleBookerType}
                                        selectedValue={
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '16px',
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    Reserved By:
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    {bookerType === undefined
                                                        ? 'All'
                                                        : bookerType}
                                                </Typography>
                                            </Grid>
                                        }
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                        minHeight={'30px'}
                                        maxHeight={'33px'}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    {/* <Grid sx={{ height: '100%' }}></Grid> */}
                </Grid>

                <Grid
                    sx={{
                        width: isMobile ? '100%' : isIpad ? '50%' : '20%',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? 'left' : 'right',
                        justifyContent: isMobile ? 'flex-start' : 'flex-end',
                        // backgroundColor:'red'
                    }}
                >
                    <Grid
                        sx={{
                            width: 'auto',
                            marginRight: isMobile ? '0px' : '15px',
                        }}
                    >
                        {checked === true ? (
                            <CustomButton
                                disableRipple
                                variant='contained'
                                color='green'
                                onClick={() => graphView(false)}
                                sx={{
                                    marginRight: '5px',
                                    marginBottom: isIpad ? '5px' : '0px',
                                }}
                            >
                                <FormatListBulletedIcon fontSize='small' />
                            </CustomButton>
                        ) : (
                            <CustomButton
                                disableRipple
                                variant='contained'
                                color='green'
                                onClick={() => graphView(true)}
                                sx={{
                                    marginRight: '5px',
                                    marginBottom: isIpad ? '5px' : '0px',
                                }}
                            >
                                <EqualizerIcon fontSize='small' />
                            </CustomButton>
                        )}
                        {checked === false ? (
                            <>
                                {' '}
                                {/* <CustomButton
                                    disableRipple
                                    variant='contained'
                                    color='neutral'
                                    onClick={() =>
                                        reportExportAPI?.printExport()
                                    }
                                    sx={{
                                        marginRight: '5px',
                                        marginBottom: isIpad ? '5px' : '0px',
                                    }}
                                >
                                    <PrintIcon fontSize='small' />{' '}
                                </CustomButton> */}
                                <CustomButton
                                    disableRipple
                                    variant='contained'
                                    color='neutral'
                                    onClick={() =>
                                        reportExportAPI?.csvExport({
                                            fileName:
                                                'Experience Reservations Report',
                                            columnsStyles: {
                                                createdAt: {
                                                    numFmt: 'dd/mm/yyyy',
                                                },
                                            },
                                        })
                                    }
                                    sx={{
                                        marginRight: '5px',
                                        marginBottom: isIpad ? '5px' : '0px',
                                    }}
                                >
                                    <FileDownloadIcon fontSize='small' />{' '}
                                </CustomButton>
                            </>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                {checked === true ? (
                    <CustomisedReportGraph data={getReport} />
                ) : (
                    <CustomisedListReport data={getReport} />
                )}
            </Grid>
        </Grid>
    );
}

import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Clear } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardMedia,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Typography,
    useTheme,
} from '@mui/material';
import SortableList, { SortableItem } from 'react-easy-sort';
import { useDispatch } from 'react-redux';
import { useAddPhotosMutation } from '../../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../../shared/AuthRoles';
import { Photo } from '../../../types/user';

import {
    Controller,
    useFieldArray,
    useFormContext,
    useWatch,
} from 'react-hook-form';
import toast from '../../../shared/Toast';
import { FormValues } from '../../../types/experience';

const useStyles = makeStyles({
    list: {
        display: 'flex',
        flexWrap: 'wrap',
        userSelect: 'none',
        gap: 8,
    },

    item: {
        cursor: 'grab',
        userSelect: 'none',
    },

    dragged: {},
});

const ExperiencePhotos = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loggedInUser = UserRole();
    const theme = useTheme();

    const [addPhoto] = useAddPhotosMutation();

    const [limitReached, isLimitReached] = useState<boolean>(false);

    const {
        control,
        formState: { errors },
    } = useFormContext<FormValues>();
    const { fields, append, remove, move } = useFieldArray<
        FormValues,
        'photos'
    >({
        control,
        name: 'photos',
    });

    const photos = useWatch({ control, name: 'photos' });

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        move(oldIndex, newIndex);
    };

    const validateImg = async (element: Photo): Promise<boolean> => {
        return new Promise((resolve) => {
            const img = new Image();

            img.onload = function () {
                const height = img.height;
                const width = img.width;

                // Check if the image is portrait
                const portrait = height > width;

                resolve(!portrait); // Return the opposite of portrait
            };

            img.src = element.src;
        });
    };

    const handlePhotos = async (e: any) => {
        // setShowExitPrompt(true);
        if (e.target.files?.length > 10) {
            isLimitReached(true);
            toast.error(`Exceeds the limit`);
        } else if (photos?.length + e.target.files?.length > 10) {
            isLimitReached(true);
            toast.error(`Exceeds the limit`);
        } else {
            isLimitReached(false);
            const formData = new FormData();

            for (const image of e.target.files) {
                formData.append('files', image);
            }
            const requestInfo = [loggedInUser.company, formData];

            addPhoto(requestInfo).then((data: any) => {
                if (data) {
                    if (data?.error) {
                        toast.error(`Photo exceeds 5MB size limit`);
                    } else {
                        data.data?.forEach((element: Photo) => {
                            validateImg(element).then((valid) => {
                                if (valid) {
                                    append(element);
                                } else {
                                    toast.error(`Photo must be landscape`);
                                }
                            });
                        });
                    }
                } else {
                    toast.error(`Failed to upload photos`);
                }
            });
        }
    };

    const handleDeletePhoto = (index: number) => {
        remove(index);
    };

    return (
        <Box>
            <Grid>
                <Typography
                    component='h4'
                    color='textPrimary'
                    fontWeight='bold'
                    pb='20px'
                >
                    Photos
                </Typography>

                <Grid container columns={25}>
                    <Grid item lg={5}>
                        <InputLabel required>Upload Photos</InputLabel>
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '10px',
                                fontStyle: 'italic',
                                paddingTop: '5px',
                            }}
                        >
                            {photos?.length < 5
                                ? 'Min 5 Photos Required (5MB per photo)'
                                : photos?.length === 10
                                ? 'Maximum uploads reached'
                                : null}
                            {limitReached === true ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    {' '}
                                    - Upload cannot exceed 10 photos, please
                                    choose 5-10 photos
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Controller
                        name='photos'
                        control={control}
                        render={({ field: { ref } }) => (
                            <Button
                                component='label'
                                variant='contained'
                                disabled={photos?.length >= 10}
                                sx={{ height: '40px' }}
                                ref={ref}
                            >
                                Upload
                                <input
                                    disabled={photos?.length >= 10}
                                    style={{
                                        display: 'none',
                                    }}
                                    onChange={(e) => {
                                        handlePhotos(e);
                                    }}
                                    type='file'
                                    multiple
                                />
                            </Button>
                        )}
                    />
                </Grid>
                <Grid container columns={25}>
                    <Grid item xs={25} lg={5}></Grid>
                    <Grid
                        item
                        xs={25}
                        lg={20}
                        sx={{
                            paddingTop: '10px',
                        }}
                    >
                        <SortableList
                            onSortEnd={onSortEnd}
                            className={classes.list}
                            draggedItemClassName={classes.dragged}
                        >
                            {fields.map((field, index) => (
                                <SortableItem key={field.id}>
                                    <Card
                                        variant='outlined'
                                        className={classes.item}
                                        sx={{
                                            p: 1,
                                            pb: 0,
                                            borderColor: errors.photos
                                                ? theme.palette.error.main
                                                : '',
                                        }}
                                    >
                                        <CardMedia
                                            component='img'
                                            src={field.src}
                                            height='100px'
                                            sx={{ pointerEvents: 'none' }}
                                        />
                                        <CardActions>
                                            <IconButton
                                                size='small'
                                                onClick={() =>
                                                    handleDeletePhoto(index)
                                                }
                                            >
                                                <Clear
                                                    sx={{ fontSize: '12px' }}
                                                />
                                            </IconButton>
                                        </CardActions>
                                    </Card>
                                </SortableItem>
                            ))}
                        </SortableList>
                        {errors.photos && (
                            <FormHelperText error>
                                {errors.photos.message}
                            </FormHelperText>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ExperiencePhotos;

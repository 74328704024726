import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Collapse,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { CalendarMonthOutlined, ExpandMore } from '@mui/icons-material';
import FormTextField from '../../../../shared/FormTextField';

import { TimeArray } from '../Property/TimeArray';
import FormAutocomplete from '../../../../shared/FormAutocomplete';
import FormMultiAutocomplete from '../../../../shared/FormMultiAutocomplete';

const PricingForm = () => {
    const { control, setValue } = useFormContext();

    const weekendPrice = useWatch({ control, name: 'weekendPrice' });

    const handleCheck = () => {
        if (weekendPrice !== null) {
            setValue('weekendPrice', null);
        } else {
            setValue('weekendPrice.hourPrice', '');
            setValue('weekendPrice.dayPrice', '');
            setValue('weekendPrice.nightPrice', '');
            setValue('weekendPrice.days', []);
        }
    };

    return (
        <Stack spacing={2}>
            <Accordion
                component={Paper}
                variant='outlined'
                sx={{ boxShadow: 0 }}
                defaultExpanded={!weekendPrice}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Box>
                        <Typography variant='h6' fontWeight='bold'>
                            Day Pricing
                        </Typography>
                        <Typography variant='body2'>
                            Set your day visit pricing
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1} rowSpacing={2}>
                        <Grid item xs={12}>
                            <FormTextField
                                name='dayPrice'
                                control={control}
                                label='Day Rate'
                                placeholder='800'
                                // required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormAutocomplete
                                control={control}
                                name='dayCheckin'
                                label='Check-in'
                                placeholder='8:00AM'
                                options={TimeArray}
                                startAdornment={
                                    <InputAdornment position='end'>
                                        <CalendarMonthOutlined fontSize='small' />
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormAutocomplete
                                control={control}
                                name='dayCheckout'
                                label='Checkout'
                                placeholder='8:00PM'
                                options={TimeArray}
                                startAdornment={
                                    <InputAdornment position='end'>
                                        <CalendarMonthOutlined fontSize='small' />
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                    </Grid>
                    <FormHelperText>
                        Check-in/checkout outside of these hours will be charged
                        at your hourly rate.
                    </FormHelperText>
                </AccordionDetails>
            </Accordion>
            <Accordion
                component={Paper}
                variant='outlined'
                sx={{ boxShadow: 0 }}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Box>
                        <Typography variant='h6' fontWeight='bold'>
                            Overnight Pricing
                        </Typography>
                        <Typography variant='body2'>
                            Set your overnight visit pricing
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1} rowSpacing={2}>
                        <Grid item xs={12}>
                            <FormTextField
                                name='nightPrice'
                                control={control}
                                label='Nightly Rate'
                                // required
                                placeholder='800'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormAutocomplete
                                name='nightCheckin'
                                control={control}
                                options={TimeArray}
                                label='Check-in'
                                placeholder='2:00PM'
                                startAdornment={
                                    <InputAdornment position='end'>
                                        <CalendarMonthOutlined fontSize='small' />
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormAutocomplete
                                name='nightCheckout'
                                control={control}
                                options={TimeArray}
                                label='Checkout'
                                // required
                                placeholder='10:00AM'
                                startAdornment={
                                    <InputAdornment position='end'>
                                        <CalendarMonthOutlined fontSize='small' />
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                    </Grid>
                    <FormHelperText>
                        Check-in/checkout outside of these hours will be charged
                        at your hourly rate.
                    </FormHelperText>
                </AccordionDetails>
            </Accordion>
            <Paper variant='outlined' sx={{ p: 2 }}>
                <Stack spacing={1}>
                    <Typography variant='h6' fontWeight='bold'>
                        Hourly Rate
                    </Typography>
                    <FormControl fullWidth>
                        <FormTextField
                            name='hourPrice'
                            control={control}
                            placeholder='250'
                            // required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormHelperText>
                            Hourly rate outside of standard check-in/checkout
                        </FormHelperText>
                    </FormControl>
                </Stack>
            </Paper>
            <Paper variant='outlined' sx={{ p: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <Typography variant='h6' fontWeight='bold'>
                            Weekend Pricing
                        </Typography>
                        <Typography variant='body2'>
                            Set your weekend pricing (optional)
                        </Typography>
                    </Box>
                    <Checkbox
                        checked={weekendPrice !== null}
                        onChange={handleCheck}
                        sx={{ p: 0 }}
                    />
                </Box>
                <Collapse in={weekendPrice !== null} unmountOnExit>
                    <Stack spacing={2} pt={2}>
                        <FormMultiAutocomplete
                            name='weekendPrice.days'
                            control={control}
                            label='Days'
                            options={[
                                {
                                    value: 'friday',
                                    label: 'Friday',
                                },
                                {
                                    value: 'saturday',
                                    label: 'Saturday',
                                },
                                {
                                    value: 'sunday',
                                    label: 'Sunday',
                                },
                            ]}
                        />
                        <FormTextField
                            name='weekendPrice.dayPrice'
                            control={control}
                            label='Day Rate'
                            placeholder='800'
                            // required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormTextField
                            name='weekendPrice.nightPrice'
                            control={control}
                            label='Nightly Rate'
                            placeholder='800'
                            // required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormTextField
                            name='weekendPrice.hourPrice'
                            control={control}
                            label='Hourly Rate'
                            placeholder='250'
                            // required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </Collapse>
            </Paper>
        </Stack>
    );
};

export default PricingForm;

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function NewDiscount() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            {loggedInUser?.role.includes('admin') ? (
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                            paddingTop: '15px',
                        }}
                    >
                        To create a Discount:
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>Click on 'Experiences' from the menu</li>
                            <li>Select 'Manage Experiences' </li>
                            <li>Select a Experience</li>
                            <li>Click the edit icon (pencil icon)</li>
                            <li>Scroll down to 'Pricing'</li>
                            <li>
                                Please ensure you have entered in a base amount
                                or additional charge before entering a discount
                            </li>
                            <li>Click on 'Discounts'</li>
                            <li>
                                Chat to the AI Assistant about your Discount by
                                initiating the conversation. i.e Discount 50% in
                                december for Xmas Special
                            </li>
                            <li>
                                The AI Assistant will display the Discount for
                                you to 'Apply' before saving
                            </li>
                            <li>
                                Ensure you scroll to the bottom of the
                                Experience and click 'Update' to make the
                                changes
                            </li>
                        </ol>
                    </Typography>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                         Only admins can create a new discount. Please contact
                            a person that is an admin in your organisation to
                            add, edit or delete discounts.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

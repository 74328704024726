import { DeleteOutline } from '@mui/icons-material';
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
    useDeleteDiscountMutation,
    useDisableDiscountMutation,
} from '../../../../../app/Slices/SevenDayApiSlice';
import { CustomIconButton } from '../../../../../layout/styledcomponents/CustomIconButton';
import { CustomSwitch } from '../../../../../layout/styledcomponents/CustomSwitch';
import UserRole from '../../../../../shared/AuthRoles';

import { useFieldArray, useFormContext } from 'react-hook-form';
import toast from '../../../../shared/Toast';
import { ExperienceDiscount } from '../../../../types/experience';
import { handleHttpError } from '../../../../../shared/utils';

interface Props {
    discounts: ExperienceDiscount[];
}

export default function AIEditDiscounts({ discounts }: Props) {
    const urlRef = window.location.href.split('/')[3];

    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const [deleteDiscount] = useDeleteDiscountMutation();
    const [disableDiscount] = useDisableDiscountMutation();
    const dispatch = useDispatch();

    const { control } = useFormContext();

    const { remove, update } = useFieldArray({
        control,
        name: 'discounts',
    });

    const handleDelete = (index: number) => {
        const selectedDiscount = discounts[index];

        if (selectedDiscount.id) {
            deleteDiscount([loggedInUser?.company, selectedDiscount.id]).then(
                (data: any) => {
                    if (data) {
                        if (data?.error) {
                            const errorMessage = handleHttpError(data?.error);
                            toast.error(errorMessage);
                        } else {
                            toast.success(`Discount deleted`);
                            remove(index);
                        }
                    } else {
                        toast.error(`Failed to delete discount`);
                    }
                }
            );
        }
    };

    const handleDisable = (index: number) => {
        const selectedDiscount = discounts[index];

        if (selectedDiscount.id) {
            disableDiscount([loggedInUser?.company, selectedDiscount.id]).then(
                (data: any) => {
                    if (data) {
                        if (data?.error) {
                            const errorMessage = handleHttpError(data?.error);
                            toast.error(errorMessage);
                        } else {
                            toast.success(
                                `Discount ${
                                    selectedDiscount.disabled
                                        ? 'enabled'
                                        : 'disabled'
                                } `
                            );
                        }
                    } else {
                        toast.error(
                            `Failed to ${
                                selectedDiscount.disabled ? 'enable' : 'disable'
                            } discount`
                        );
                    }
                }
            );
        }

        update(index, {
            ...selectedDiscount,
            disabled: !selectedDiscount.disabled,
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                height: discounts?.length < 4 ? 'auto' : '300px',
                width: 'auto',
                // overflowY: discounts?.length < 4 ? 'hidden' : 'scroll',
            }}
        >
            <Grid
                sx={{
                    // width: '100%',
                    minHeight: isMobile ? '65%' : '78%',
                    paddingTop: '30px',
                    justifyContent: 'left',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                {discounts?.length === 0 ||
                Object.keys(discounts?.[0]).length === 0 ? (
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        {' '}
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontWeight: 'bold',
                            }}
                        >
                            There are no discounts
                        </Typography>
                    </Table>
                ) : (
                    <TableContainer>
                        <Table aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Discount Ref
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Discount %
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Period
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Status
                                        </Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontWeight: 'bold',
                                            }}
                                        ></Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Expired
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Delete
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {discounts?.map(
                                    (discount: any, index: number) => (
                                        <TableRow key={discount?.id}>
                                            <TableCell>
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                    }}
                                                >
                                                    {discount?.name
                                                        ?.charAt(0)
                                                        ?.toUpperCase() +
                                                        discount?.name?.slice(
                                                            1
                                                        )}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                    }}
                                                >
                                                    {discount?.percentage}%
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                    }}
                                                >
                                                    {discount?.dates?.length ===
                                                    1
                                                        ? `${moment(
                                                              discount
                                                                  ?.dates?.[0]
                                                                  .startDate
                                                          ).format(
                                                              'DD/MM/YYYY'
                                                          )} - ${moment(
                                                              discount
                                                                  ?.dates?.[0]
                                                                  .endDate
                                                          ).format(
                                                              'DD/MM/YYYY'
                                                          )}`
                                                        : discount?.dates?.map(
                                                              (i: any) => {
                                                                  return (
                                                                      <li>
                                                                          {moment(
                                                                              i?.startDate
                                                                          ).format(
                                                                              'DD/MM/YYYY'
                                                                          )}{' '}
                                                                          -{' '}
                                                                          {moment(
                                                                              i?.endDate
                                                                          ).format(
                                                                              'DD/MM/YYYY'
                                                                          )}
                                                                      </li>
                                                                  );
                                                              }
                                                          )}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        minWidth: '50px',
                                                    }}
                                                >
                                                    {discount?.disabled
                                                        ? 'Disabled'
                                                        : 'Enabled'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <CustomSwitch
                                                    variant='contained'
                                                    color='green'
                                                    onChange={() =>
                                                        handleDisable(index)
                                                    }
                                                    defaultChecked={
                                                        !discount?.disabled
                                                    }
                                                    sx={{ marginRight: '5px' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                    }}
                                                >
                                                    {discount?.expired === true
                                                        ? ' Expired'
                                                        : 'Active'}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                <CustomIconButton
                                                    variant='contained'
                                                    onClick={() =>
                                                        handleDelete(index)
                                                    }
                                                    sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: 'red',
                                                            backgroundColor:
                                                                'transparent',
                                                            background: 'none',
                                                        },
                                                    }}
                                                >
                                                    <DeleteOutline
                                                        sx={{
                                                            fontSize: '14px',
                                                        }}
                                                    />
                                                </CustomIconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </Box>
    );
}

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function CreateFeeType() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
           
           {loggedInUser?.role.includes('admin') ? (
           
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        paddingBottom: '20px',
                        fontSize: '14px',
                    }}
                >
                    Pricing & Fees Types allow you to customize the charges associated with
                    experiences. Users with admin permissions can add, edit, and
                    delete Pricing & Fees types as needed. Pricing & Fees types can be associated with
                    different experiences to ensure accurate billing and
                    accounting.
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To create a new fee type:
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>
                            From the top right corner of the app, click on the
                            cog wheel icon
                        </li>
                        <li>Select 'Admin Center'</li>
                        <li>Select 'Fees Types'</li>
                        <li>Click 'Add'</li>
                        <li>Complete all mandatory fields with a *</li>
                        <li>Click 'Save'</li>
                    </ol>
                    Once saved, you will be able to add this Fee type to
                    experiences.
                </Typography>
            </Grid>):
            
            (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                         Only admins can create a new fee type. Please contact
                            a person that is an admin in your organisation to
                            add, edit or delete a fee type.
                        </Typography>
                    </Grid>
                </Grid>
            )
            
            }
        </Grid>
    );
}

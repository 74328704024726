import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export default function UserReservationsReportGraph(params: any) {
    const isMobile = useMediaQuery('(max-width: 770px)');

    let boat = params?.data?.filter((i: any) => i.moduleType === 'boat');
    let vehicle = params?.data?.filter((i: any) => i.moduleType === 'vehicle');
    let property = params?.data?.filter(
        (i: any) => i.moduleType === 'property'
    );
    let activity = params?.data?.filter(
        (i: any) => i.moduleType === 'activity'
    );
    let commercial = params?.data?.filter(
        (i: any) => i.moduleType === 'commercial'
    );

    let totalCancellations = [
        { name: 'Boat', cancellations: boat?.length },
        { name: 'Vehicle', cancellations: vehicle?.length },
        { name: 'Property', cancellations: property?.length },
        { name: 'Activity', cancellations: activity?.length },
        { name: 'Commercial', cancellations: commercial?.length },
    ];

    return (
        <Box
            sx={{
                height: isMobile ? 400 : 800,
                width: '100%',
                paddingTop: isMobile ? '0px' : '50px',
            }}
        >
            <BarChart
                tooltip={{ trigger: 'item' }}
                xAxis={[
                    {
                        id: 'barCategories',
                        data: totalCancellations.map(
                            (reservation: { name: any }) => reservation?.name
                        ),
                        scaleType: 'band',
                    },
                ]}
                series={[
                    {
                        data: totalCancellations.map(
                            (reservation: { cancellations: any }) =>
                                reservation?.cancellations
                        ),
                        color: '#5cb5b3',
                    },
                ]}
                // width={2500}
                // height={300}
            />
        </Box>
    );
}

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function BillingExplanation() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        You're required to add a payment method to 7DAY in order
                        to secure a booking. We do not store this information as
                        we securely integrate with Stripe. Once your details
                        have been entered and verified, you will be bale to make
                        a reservation.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        When making a booking, payments are on hold until the
                        host of the experience has approved the reservation.
                        Once approved, the amount will be debited from your
                        account. For any refunds, they will be processed in
                        within 14 days of cancellation provided they meet the
                        hosts cancellation policy.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

import { Box,  useMediaQuery } from '@mui/material';
import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export default function TopGuestsReportGraph(params: any) {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <Box
            sx={{
                height: isMobile ? 400 : 800,
                width: '100%',
                paddingTop: isMobile ? '0px' : '50px',
            }}
        >
            <BarChart
                tooltip={{ trigger: 'item' }}
                xAxis={[
                    {
                        id: 'barCategories',
                        data: params?.data.map(
                            (guest: { name: any }) => guest?.name
                        ),
                        scaleType: 'band',
                    },
                ]}
                series={[
                    {
                        data: params?.data.map(
                            (guest: { completedBookings: any }) =>
                                guest?.completedBookings
                        ),
                        // color: params?.data.map((guest: { id: any; })=> 'red')
                        color: '#5cb5b3',
                    },
                ]}
                // width={2500}
                // height={300}
            />
        </Box>
    );
}

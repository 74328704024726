import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import UserRole from '../../../shared/AuthRoles';
import {
    useGetProfileCompanyInfoQuery,
    useGetProfileDetailsQuery,
} from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import EditCompanyDetails from '../../components/Profile/Edit/EditCompanyDetails';
import ProfileHero from '../../components/Profile/View/ProfileHero';
import EditPersonalDetails from '../../components/Profile/Edit/EditPersonalDetails';
import { useEffect, useState } from 'react';
import React from 'react';
import EditProfilePicture from '../../components/Profile/Edit/EditProfilePicture';
import { Photo } from '../../types/user';
import toast from '../../shared/Toast';

export default function EditProfilePage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    const [url, setURL] = useState('');

    const { data: profileDetails } = useGetProfileDetailsQuery('/profile');
    const { data: getCompanyDetails, isFetching } =
        useGetProfileCompanyInfoQuery(url);

    const [photo, setPhoto] = useState<Photo>();

    const onSubmitProfilePicture = (photo: Photo) => {
        setPhoto(photo);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_company' ||
            loggedInUser?.role === 'owner_user'
        ) {
            setURL(`owners/${loggedInUser?.company}`);
        } else if (
            loggedInUser?.role === 'guest_admin' ||
            loggedInUser?.role === 'guest_company' ||
            loggedInUser?.role === 'guest_user' ||
            loggedInUser?.role === 'guest_department_admin'
        ) {
            setURL(`guests/${loggedInUser?.company}`);
        } else {
            setURL(`/7-day/users`);
        }
    }, [loggedInUser?.company]);

    useEffect(() => {
        toast.remove('finishSettingUpProfile');
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {isFetching === true ? (
                <Grid
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '2%' : '5%',
                            justifyContent: 'left',
                            display: 'flex',
                        }}
                    >
                        <Typography
                            component='h1'
                            variant='h5'
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                                textDecoration: 'underline',
                                textDecorationColor: '#5cb5b3',
                                textUnderlineOffset: '8px',
                            }}
                        >
                            Edit Profile
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            textAlign: ' left',
                            paddingBottom: isMobile ? '10px' : '0px',
                        }}
                    >
                        <ProfileHero />
                    </Grid>
                    {/* ================================================= */}
                    {!loggedInUser?.role?.includes('company') &&
                        loggedInUser?.role !== 'admin' && (
                            <Grid pt='30px'>
                                <EditProfilePicture
                                    user={profileDetails}
                                    onSubmit={onSubmitProfilePicture}
                                />
                            </Grid>
                        )}
                    {/* ================================================= */}
                    {loggedInUser?.role === 'admin' ? null : (
                        <Grid
                            sx={{
                                width: '100%',
                                paddingTop: '30px',
                                minHeight: 'auto',
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: 'center',
                            }}
                        >
                            <EditCompanyDetails
                                companyDetails={getCompanyDetails}
                            />
                        </Grid>
                    )}
                    {loggedInUser?.role === 'owner_company' ||
                    loggedInUser?.role === 'guest_company' ? null : (
                        <>
                            <Grid
                                sx={{
                                    width: '100%',
                                    minHeight: 'auto',
                                    justifyContent: 'left',
                                }}
                            >
                                <EditPersonalDetails photo={photo} />
                            </Grid>{' '}
                        </>
                    )}
                </Grid>
            )}
        </Container>
    );
}

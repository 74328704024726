import { Avatar, Badge, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import React from 'react'

interface ChannelItemProps {
    channelId: string,
    displayName?: string,
    lastActivity: string,
    unreadCount: number,
    metadata?: {
        experience?: {
            photo?: string
        }
    }
    onClick: (id: string) => void
}

const ChannelItem = ({ 
    channelId, 
    displayName,
    lastActivity,
    unreadCount,
    metadata,
    onClick 
}: ChannelItemProps) => {
    const formatDate = (date: string) => {
        let lastActivityDate = new Date(date);
        return formatDistanceToNow(lastActivityDate, { addSuffix: true })
    }

    return (
        <ListItem>
            <ListItemButton onClick={() => onClick(channelId)}>
                <ListItemAvatar>
                    {unreadCount > 0 ? (
                        <Badge
                            overlap='circular'
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={unreadCount}
                            color='primary'
                        >
                            <Avatar src={metadata?.experience?.photo}/>
                        </Badge>
                    ) : (
                        <Avatar src={metadata?.experience?.photo} />
                    )}
                </ListItemAvatar>
                <ListItemText 
                    primary={displayName} 
                    secondary={formatDate(lastActivity)}
                />
            </ListItemButton>
        </ListItem>
    )
}



export default ChannelItem
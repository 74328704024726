import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import {
    Box,
    FormHelperText,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface FormCounterProps {
    name: string;
    disabled: boolean;
}

/**
 * For use within RHF FormProvider
 */
const FormCounter = ({ name, disabled }: FormCounterProps) => {
    const { control, setValue, watch } = useFormContext();

    const value: number = watch(name);

    const handleIncrement = () => {
        setValue(name, value + 1, { shouldValidate: true, shouldDirty: true });
    };

    const handleDecrement = () => {
        setValue(name, value - 1, { shouldValidate: true, shouldDirty: true });
    };

    // TODO: Add ref for scroll into view

    return (
        <Controller
            name={name}
            control={control}
            render={({ fieldState: { error } }) => (
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='flex-end'
                >
                    <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        width='100px'
                    >
                        <IconButton
                            size='small'
                            onClick={handleDecrement}
                            disabled={value === 0}
                        >
                            <RemoveCircleOutline fontSize='small' />
                        </IconButton>
                        <Typography color='textPrimary'>{value}</Typography>
                        <IconButton
                            size='small'
                            onClick={handleIncrement}
                            disabled={disabled}
                        >
                            <AddCircleOutline fontSize='small' />
                        </IconButton>
                    </Box>
                    {!!error && (
                        <FormHelperText error>{error.message}</FormHelperText>
                    )}
                </Box>
            )}
        />
    );
};

export default FormCounter;

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Container,
    Grid,
    List,
    ListItem,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectInviteSliceData } from '../../../app/Slices/inviteSlice';
import {
    useGetIndividualUserInfoQuery,
    useSendInviteMutation,
} from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import { CustomTextField } from '../../../layout/styledcomponents/CustomTextField';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../shared/AuthRoles';
import InviteModal from '../../pages/Guest Pages/InviteModal';
import toast from '../../shared/Toast';
import UserSummary from './UserSummary';

export default function ViewIndividualUser() {
    const loggedInUser = UserRole();
    const [userUrl, setUserUrl] = useState('');
    const {
        data: getIndividualUser,
        isFetching,
        isError,
    } = useGetIndividualUserInfoQuery(userUrl);
    const urlRef = window.location.href.split('/')[5];
    const isLaptop = useMediaQuery('(max-width: 1535px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const navigate = useNavigate();
    const [sendInvite] = useSendInviteMutation();
    const location = useLocation();
    const [openInviteModal, setInviteOpen] = useState(false);
    const wasInviteSent: any = useSelector(selectInviteSliceData);
    const viewOnly = getIndividualUser?.viewOnly;

    if (isError) {
        //API error i.e incorrect user accessing someone elses profile
        navigate(`/permissionDenied`, {
            state: {
                name: 'individualUser',
            },
        });
        ReactGA.send({
            hitType: 'pageview',
            page: `/permissionDenied`,
            title: `Permission Denied`,
        });
    }

    useEffect(() => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_company'
        ) {
            setUserUrl(`/owners/${loggedInUser?.company}/users/${urlRef}`);
        } else if (
            loggedInUser?.role === 'guest_admin' ||
            loggedInUser?.role === 'guest_company' ||
            loggedInUser?.role === 'guest_department_admin'
        ) {
            setUserUrl(`/guests/${loggedInUser?.company}/users/${urlRef}`);
        } else {
            setUserUrl(`/7-day/users/${urlRef}`);
        }
        if (
            location?.state?.name === 'newlyAddedUser' &&
            wasInviteSent?.view === 'none'
        ) {
            setInviteOpen(true);
        } else {
            setInviteOpen(false);
        }
    }, [loggedInUser]);

    const handleCloseInviteModal = () => {
        setInviteOpen(false);
    };

    const onClickGoBack = (experience: any) => {
        navigate(`/users`);
    };

    const onClickSendInvite = () => {
        ReactGA.event({
            category: 'UI',
            action: `Send Invite`,
            label: `Send Invite Clicked - ${loggedInUser.role}`,
        });
        setInviteOpen(false);
        //send invite to user to set password
        let requestBody = {
            id: parseInt(urlRef),
        };
        sendInvite(requestBody).then((data: any) => {
            if (data) {
                if (data?.error?.data) {
                    toast.success(`Invite link has been sent to user`);
                } else {
                    toast.error(`Failed to send invite link`);
                }
            } else {
                toast.error(
                    `Failed to send invite link, please check email is correct`
                );
            }
        });
    };

    const editUser = () => {
        navigate(
            `/editUser/${getIndividualUser?.firstName}/${getIndividualUser?.id}`,
            {
                state: {
                    name: 'editUser',
                },
            }
        );
    };

    const textFieldProps = {
        height: '10px',
        width: isMobile ? '' : isIpad ? '' : isLaptop ? '340px' : '400px',
        backgroundColor: '#f6f8f9',
    };

    const inviteDetails = (): string => {
        if (getIndividualUser?.lastLoginAt) {
            return '';
        }
        if (getIndividualUser?.invite) {
            return `Invitation sent by ${
                getIndividualUser?.invite?.sentBy
            } on ${moment(getIndividualUser?.sentAt).format(
                'DD/MM/YYYY'
            )} - ${moment(getIndividualUser?.sentAt).format('h:mm a')}.
            ${
                getIndividualUser?.invite?.accepted
                    ? 'Invitation has been accepted.'
                    : getIndividualUser?.invite?.expired
                    ? 'Invitation has expired.'
                    : 'Invitation is pending.'
            } 
           `;
        }
        return 'No invitation has been sent yet.';
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile || isIpad ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {isFetching === true ? (
                <Grid
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                        paddingTop: '30px',
                    }}
                >
                    {/* <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '2%' : '5%',
                            justifyContent: 'left',
                            display: 'flex',
                        }}
                    >
                        <Typography
                            component='h1'
                            variant='h5'
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                                textDecoration: 'underline',
                                textDecorationColor: '#5cb5b3',
                                textUnderlineOffset: '8px',
                                fontWeight: window.location.href?.includes(
                                    'user'
                                )
                                    ? '550'
                                    : 'none',
                                paddingRight: '15px',
                            }}
                        >
                            {' '}
                            {getIndividualUser?.firstName}{' '}
                            {getIndividualUser?.lastName}
                        </Typography>
                    </Grid> */}
                    <UserSummary user={getIndividualUser} />
                    <Grid sx={{ width: '100%', textAlign: 'right' }}>
                        {window.location.href?.includes('editUser') ||
                        window.location.href?.includes('newUser') ? null : (
                            <>
                                <CustomIconButton
                                    sx={{
                                        backgroundColor: '#fff',
                                        width: '25px',
                                        height: '25px',
                                        paddingLeft: '20px',
                                    }}
                                    onClick={editUser}
                                >
                                    <EditIcon
                                        fontSize='small'
                                        sx={{
                                            color: '#787878',
                                            fontSize: '16px',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                        }}
                                    />
                                </CustomIconButton>
                            </>
                        )}
                    </Grid>

                    <Grid sx={{ width: '100%', height: '100%' }}>
                        <Grid
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingTop: isIpad ? '0px' : '30px',
                                height: 'auto',
                            }}
                        >
                            <Typography
                                component='h4'
                                // variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textAlign: 'left',
                                    fontWeight: 900,
                                    paddingTop: isMobile
                                        ? '30px'
                                        : isIpad
                                        ? '0px'
                                        : '0px',
                                    paddingBottom: '30px',
                                }}
                            >
                                User
                            </Typography>

                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection:
                                        isMobile || isIpad ? 'column' : 'row',
                                    width: '100%',
                                }}
                            >
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '20%',
                                        height:
                                            isMobile || isIpad
                                                ? '25px'
                                                : '50px',
                                    }}
                                >
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        First Name
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '30%',
                                        height:
                                            isMobile || isIpad
                                                ? '60px'
                                                : '50px',
                                        marginRight:
                                            !isMobile || !isIpad
                                                ? '50px'
                                                : '0px',
                                    }}
                                >
                                    <CustomTextField
                                        disabled
                                        value={getIndividualUser?.firstName}
                                        fullWidth={isMobile || isIpad}
                                        color='neutral'
                                        inputProps={{
                                            style: { ...textFieldProps },
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '20%',
                                        height:
                                            isMobile || isIpad
                                                ? '25px'
                                                : '50px',
                                    }}
                                >
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Surname
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '30%',
                                        height:
                                            isMobile || isIpad
                                                ? '60px'
                                                : '50px',
                                    }}
                                >
                                    <CustomTextField
                                        disabled
                                        placeholder='Smith'
                                        value={getIndividualUser?.lastName}
                                        fullWidth={isMobile || isIpad}
                                        color='neutral'
                                        inputProps={{
                                            style: { ...textFieldProps },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection:
                                        isMobile || isIpad ? 'column' : 'row',
                                    width: '100%',
                                }}
                            >
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '20%',
                                        height:
                                            isMobile || isIpad
                                                ? '25px'
                                                : '50px',
                                    }}
                                >
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Phone
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '30%',
                                        height:
                                            isMobile || isIpad
                                                ? '60px'
                                                : '50px',
                                        marginRight:
                                            !isMobile || !isIpad
                                                ? '50px'
                                                : '0px',
                                    }}
                                >
                                    <CustomTextField
                                        disabled
                                        placeholder='0414789456'
                                        type='tel'
                                        value={getIndividualUser?.phone}
                                        fullWidth={isMobile || isIpad}
                                        inputProps={{
                                            style: { ...textFieldProps },
                                        }}
                                    />
                                </Grid>
                                {loggedInUser?.role.includes('guest') ? (
                                    <>
                                        <Grid
                                            sx={{
                                                width:
                                                    isMobile || isIpad
                                                        ? '100%'
                                                        : '20%',
                                                height:
                                                    isMobile || isIpad
                                                        ? '25px'
                                                        : '50px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <>
                                                <Grid
                                                    sx={{
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    <Typography
                                                        color='#787878'
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                        }}
                                                    >
                                                        Team
                                                    </Typography>
                                                </Grid>

                                                <Grid>
                                                    <Tooltip
                                                        title={
                                                            <Typography
                                                                // color='#787878'
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                }}
                                                            >
                                                                <List>
                                                                    By selecting
                                                                    the users
                                                                    Team, they
                                                                    will appear
                                                                    in the 'Team
                                                                    Managers'
                                                                    profile.
                                                                    Team
                                                                    Managers
                                                                    will have
                                                                    access to
                                                                    view and
                                                                    manage users
                                                                    that belong
                                                                    to their
                                                                    Team as well
                                                                    as view and
                                                                    edit any
                                                                    reservations
                                                                    that belong
                                                                    to them.
                                                                </List>
                                                            </Typography>
                                                        }
                                                        placement='right'
                                                    >
                                                        <HelpOutlineIcon
                                                            sx={{
                                                                color: '#787878',
                                                                fontSize:
                                                                    '16px',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                            </>
                                        </Grid>
                                        <Grid
                                            sx={{
                                                width:
                                                    isMobile || isIpad
                                                        ? '100%'
                                                        : '30%',
                                                height:
                                                    isMobile || isIpad
                                                        ? '60px'
                                                        : '50px',
                                            }}
                                        >
                                            <CustomTextField
                                                disabled
                                                value={
                                                    getIndividualUser
                                                        ?.department?.name
                                                        ? getIndividualUser?.department?.name
                                                              ?.charAt(0)
                                                              ?.toUpperCase() +
                                                          getIndividualUser?.department?.name.slice(
                                                              1
                                                          )
                                                        : '-'
                                                }
                                                fullWidth={isMobile || isIpad}
                                                // color={isValidPhone ? 'green' : 'neutral'}
                                                inputProps={{
                                                    style: {
                                                        ...textFieldProps,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid
                                            sx={{
                                                width:
                                                    isMobile || isIpad
                                                        ? '100%'
                                                        : '20%',
                                                height:
                                                    isMobile || isIpad
                                                        ? '0px'
                                                        : '50px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        ></Grid>
                                        <Grid
                                            sx={{
                                                width:
                                                    isMobile || isIpad
                                                        ? '100%'
                                                        : '30%',
                                                height:
                                                    isMobile || isIpad
                                                        ? '0px'
                                                        : '50px',
                                            }}
                                        ></Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection:
                                        isMobile || isIpad ? 'column' : 'row',
                                    width: '100%',
                                }}
                            >
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '20%',
                                        height:
                                            isMobile || isIpad
                                                ? '25px'
                                                : '50px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Grid sx={{ marginRight: '5px' }}>
                                        <Typography
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            Access
                                        </Typography>
                                    </Grid>

                                    <Grid>
                                        <Tooltip
                                            title={
                                                <Typography
                                                    // color='#787878'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                    }}
                                                >
                                                    <List>
                                                        <ListItem>
                                                            {' '}
                                                            Company Admin:
                                                            Add/Edit/View
                                                            Listings, Users and
                                                            Reservations for the
                                                            entire company.
                                                        </ListItem>
                                                        <ListItem>
                                                            {' '}
                                                            Team Admin:
                                                            Add/Edit/View
                                                            Listings, Users and
                                                            Reservations for
                                                            their Team only.
                                                        </ListItem>
                                                        <ListItem>
                                                            {' '}
                                                            User: Add/Edit/View
                                                            Reservations for
                                                            themselves & view
                                                            listings
                                                        </ListItem>
                                                        {loggedInUser?.role.includes(
                                                            'guest'
                                                        ) ? (
                                                            <ListItem>
                                                                View Only User:
                                                                View listings &
                                                                view
                                                                reservations
                                                                made for them
                                                            </ListItem>
                                                        ) : null}
                                                    </List>
                                                </Typography>
                                            }
                                            placement='right'
                                        >
                                            <HelpOutlineIcon
                                                sx={{
                                                    color: '#787878',
                                                    fontSize: '16px',
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '30%',
                                        height:
                                            isMobile || isIpad
                                                ? '60px'
                                                : '50px',
                                        marginRight:
                                            !isMobile || !isIpad
                                                ? '50px'
                                                : '0px',
                                    }}
                                >
                                    <CustomTextField
                                        disabled
                                        fullWidth={isMobile || isIpad}
                                        value={
                                            loggedInUser?.role === 'admin' ||
                                            getIndividualUser?.role ===
                                                'owner_admin'
                                                ? 'Admin'
                                                : getIndividualUser?.role ===
                                                  'guest_admin'
                                                ? 'Company Admin'
                                                : getIndividualUser?.role ===
                                                  'guest_department_admin'
                                                ? 'Team Admin'
                                                : viewOnly
                                                ? 'View Only User'
                                                : 'User'
                                        }
                                        color='neutral'
                                        inputProps={{
                                            style: { ...textFieldProps },
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '20%',
                                        height:
                                            isMobile || isIpad
                                                ? '25px'
                                                : '50px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Grid sx={{ marginRight: '5px' }}>
                                        <Typography
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            Status
                                        </Typography>
                                    </Grid>

                                    <Grid>
                                        <Tooltip
                                            title={
                                                <Typography
                                                    // color='#787878'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                    }}
                                                >
                                                    <List>
                                                        <ListItem>
                                                            {' '}
                                                            Enabled: Users can
                                                            access the site and
                                                            perform actions
                                                            based on their level
                                                            of access
                                                        </ListItem>
                                                        <ListItem>
                                                            {' '}
                                                            Disabled: Users will
                                                            not be able to
                                                            access the site
                                                        </ListItem>
                                                    </List>
                                                </Typography>
                                            }
                                            placement='right'
                                        >
                                            <HelpOutlineIcon
                                                sx={{
                                                    color: '#787878',
                                                    fontSize: '16px',
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '30%',
                                        height:
                                            isMobile || isIpad
                                                ? '60px'
                                                : '50px',
                                    }}
                                >
                                    {' '}
                                    <CustomTextField
                                        disabled
                                        fullWidth={isMobile || isIpad}
                                        value={
                                            getIndividualUser?.isActive === true
                                                ? 'Enabled'
                                                : 'Disabled'
                                        }
                                        color='neutral'
                                        inputProps={{
                                            style: { ...textFieldProps },
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection:
                                        isMobile || isIpad ? 'column' : 'row',
                                    width: '100%',
                                }}
                            >
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '20%',
                                        height:
                                            isMobile || isIpad
                                                ? '25px'
                                                : '50px',
                                    }}
                                >
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Last Login
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '30%',
                                        height:
                                            isMobile || isIpad
                                                ? '60px'
                                                : '50px',
                                        marginRight:
                                            !isMobile || !isIpad
                                                ? '50px'
                                                : '0px',
                                    }}
                                >
                                    <CustomTextField
                                        disabled
                                        fullWidth={isMobile || isIpad}
                                        value={
                                            getIndividualUser?.lastLoginAt
                                                ? `${moment(
                                                      getIndividualUser?.lastLoginAt
                                                  ).format(
                                                      'DD/MM/YYYY'
                                                  )} - ${moment(
                                                      getIndividualUser?.lastLoginAt
                                                  ).format('h:mm a')}`
                                                : '-'
                                        }
                                        color='neutral'
                                        inputProps={{
                                            style: { ...textFieldProps },
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '20%',
                                        height:
                                            isMobile || isIpad
                                                ? '25px'
                                                : '50px',
                                    }}
                                ></Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '30%',
                                        height:
                                            isMobile || isIpad
                                                ? '60px'
                                                : '50px',
                                    }}
                                >
                                    {' '}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '45%',
                            }}
                        >
                            <Typography
                                component='h4'
                                // variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textAlign: 'left',
                                    fontWeight: 900,
                                    paddingTop: isMobile
                                        ? '0px'
                                        : isIpad
                                        ? '10px'
                                        : '30px',
                                    height: '10%',
                                }}
                            >
                                Users Login
                            </Typography>

                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection:
                                        isMobile || isIpad ? 'column' : 'row',
                                    width: '100%',
                                    paddingTop: isMobile
                                        ? '10px'
                                        : isIpad
                                        ? '0px'
                                        : '30px',
                                }}
                            >
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '20%',
                                        height:
                                            isMobile || isIpad
                                                ? '25px'
                                                : '50px',
                                    }}
                                >
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Email
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '30%',
                                        height:
                                            isMobile || isIpad
                                                ? '60px'
                                                : '50px',
                                        marginRight:
                                            !isMobile || !isIpad
                                                ? '50px'
                                                : '0px',
                                    }}
                                >
                                    <CustomTextField
                                        disabled
                                        placeholder='john.smith@abc.com'
                                        value={getIndividualUser?.email}
                                        type='email'
                                        fullWidth={isMobile || isIpad}
                                        // color={isValidEmail ? 'green' : 'neutral'}
                                        inputProps={{
                                            style: { ...textFieldProps },
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '20%',
                                    }}
                                >
                                    {!getIndividualUser?.lastLoginAt && (
                                        <CustomButton
                                            variant='contained'
                                            color='green'
                                            // autoFocus
                                            disabled={
                                                getIndividualUser?.isActive ===
                                                false
                                            }
                                            onClick={onClickSendInvite}
                                            sx={{
                                                height: '40px',
                                            }}
                                        >
                                            <Typography>Send Invite</Typography>
                                        </CustomButton>
                                    )}
                                </Grid>
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '30%',
                                        height:
                                            isMobile || isIpad
                                                ? '60px'
                                                : '50px',
                                        display: 'flex',
                                    }}
                                >
                                    <Typography
                                        color='#787878'
                                        width='400px'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            height: '10px',
                                        }}
                                    >
                                        {inviteDetails()}
                                    </Typography>
                                    {/* <CustomButton
                            variant='contained'
                            color='green'
                            // autoFocus
                            onClick={onClickSendInvite}
                            sx={{
                                height: '40px'
                            }}
                        >
                            <Typography>Send Invite</Typography>
                        </CustomButton> */}
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection:
                                        isMobile || isIpad ? 'column' : 'row',
                                    width: '100%',
                                    height: isMobile || isIpad ? '10px' : '25%',
                                }}
                            >
                                {isMobile || isIpad ? null : (
                                    <>
                                        {' '}
                                        <Grid
                                            sx={{
                                                width:
                                                    isMobile || isIpad
                                                        ? '100%'
                                                        : '20%',
                                                height: '50px',
                                                marginRight:
                                                    !isMobile || !isIpad
                                                        ? '50px'
                                                        : '0px',
                                            }}
                                        ></Grid>
                                        <Grid
                                            sx={{
                                                width:
                                                    isMobile || isIpad
                                                        ? '100%'
                                                        : '30%',
                                                height: '50px',
                                            }}
                                        ></Grid>
                                        <Grid
                                            sx={{
                                                width:
                                                    isMobile || isIpad
                                                        ? '100%'
                                                        : '20%',
                                                height: '50px',
                                                marginRight:
                                                    !isMobile || !isIpad
                                                        ? '20px'
                                                        : '0px',
                                            }}
                                        ></Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '80px' : '10%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    // backgroundColor: 'yellow',
                                }}
                            >
                                <CustomButton
                                    onClick={onClickGoBack}
                                    color='green'
                                    sx={{
                                        marginLeft: !isMobile
                                            ? '-8px'
                                            : '-18px',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: '#5cb5b3',
                                            backgroundColor: 'transparent',
                                            background: 'none',
                                        },
                                    }}
                                >
                                    <ArrowBackIcon />{' '}
                                    {isMobile ? null : (
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Back to users
                                        </Typography>
                                    )}
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <InviteModal
                open={openInviteModal}
                onClose={handleCloseInviteModal}
            />
        </Container>
    );
}

import React, { useEffect, useState } from 'react';
import GenericModal from '../../../shared/GenericModal';
import { ModalProps } from '../../../types/modal';
import { ExperienceCharge } from '../../../types/experience';
import {
    Alert,
    Collapse,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { CustomIconButton } from '../../../../layout/styledcomponents/CustomIconButton';
import { TransitionGroup } from 'react-transition-group';
import { AddCircle } from '@mui/icons-material';

import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FeesSchema } from '../Owner/schema';
import { FeeType } from '../../Fees/FeeForm';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import ExperienceFeesForm from './ExperienceFeesForm';

export type Fees = {
    fees: Array<ExperienceCharge>;
};

interface Props extends ModalProps {
    experienceType: string;
    fees: Array<ExperienceCharge>;
    onSubmit: (data: Fees) => void;
}

const FeesModal = ({
    open,
    onClose,
    onSubmit,
    fees,
    experienceType,
}: Props) => {
    const isMobile = useMediaQuery('(max-width: 700px)');

    const [cancel, setCancel] = useState<boolean>(false);

    const methods = useForm<Fees>({
        resolver: zodResolver(FeesSchema),
        defaultValues: { fees: [] },
    });

    const {
        control,
        reset,
        handleSubmit,
        formState: { isDirty },
    } = methods;

    const { fields, append, remove } = useFieldArray({
        name: 'fees',
        control,
    });

    const onNestedSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        if (event && typeof event.stopPropagation === 'function') {
            event.stopPropagation();
        }

        setCancel(false);

        return handleSubmit(onSubmit)(event);
    };

    const handleAdd = () => {
        append({ feeId: '', type: 'fixed', chargeType: 'fee', unitPrice: 0 });
    };

    const handleDelete = (index: number) => {
        remove(index);
    };

    const generateSpaceNames = (
        charges: ExperienceCharge[]
    ): ExperienceCharge[] => {
        return charges;
    };

    const handleCancel = () => {
        if (isDirty) {
            // if form is dirty, display alert
            if (!cancel) {
                setCancel(true);
                return;
            }
        }

        if (cancel) reset();

        setCancel(false);
        onClose();
    };

    useEffect(() => {
        if (fees.length > 0) {
            reset({ fees: fees });
        }
    }, [fees]);

    return (
        <GenericModal
            open={open}
            onClose={onClose}
            // onChange={handleDeleteFee}
            message={'delete this Pricing & Fee type'}
        >
            <FormProvider {...methods}>
                <form onSubmit={onNestedSubmit}>
                    <Grid
                        sx={{
                            padding: '30px',
                            minWidth: isMobile ? 0 : '700px',
                        }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                flexDirection: isMobile ? 'column' : 'row',
                            }}
                        >
                            <Grid
                                item
                                xs={25}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '3px',
                                }}
                            >
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        gap: '10px',
                                    }}
                                >
                                    {' '}
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            color: 'text.primary',
                                        }}
                                    >
                                        Additional Charges
                                    </Typography>
                                    <Typography sx={{ color: 'text.primary' }}>
                                        AUD
                                    </Typography>
                                </Grid>

                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                        paddingBottom: '10px',
                                    }}
                                >
                                    Add additional charges such as cleaning or
                                    pets. <strong>Note:</strong> all prices are
                                    excluding GST.
                                </Typography>
                            </Grid>

                            <CustomIconButton
                                variant='contained'
                                color='green'
                                onClick={handleAdd}
                            >
                                <AddCircle />
                            </CustomIconButton>
                        </Grid>

                        <Collapse in={cancel}>
                            <Alert severity='warning'>
                                You have unsaved changes. Are you sure you want
                                to cancel?
                            </Alert>
                        </Collapse>
                        <Stack spacing={1} pt={2}>
                            {fields.map(
                                (fee: ExperienceCharge, index: number) => (
                                    <ExperienceFeesForm
                                        existingFee={fee}
                                        experienceType={experienceType}
                                        index={index}
                                        handleDelete={() => handleDelete(index)}
                                    />
                                )
                            )}
                        </Stack>
                    </Grid>
                    <Grid
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '10px',
                            gap: '10px',
                            borderTop: ' #d8d8d8 solid 1px',
                            // minHeight: '60px',
                            background: ' #eeeeee',
                        }}
                    >
                        {' '}
                        <CustomButton
                            width={isMobile ? '50%' : 'auto'}
                            variant='contained'
                            color='neutral'
                            // autoFocus
                            onClick={handleCancel}
                        >
                            Cancel
                        </CustomButton>
                        <Grid>
                            {' '}
                            <CustomButton
                                variant='contained'
                                color='green'
                                type='submit'
                                autoFocus
                            >
                                Save
                            </CustomButton>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </GenericModal>
    );
};

export default FeesModal;

import AcUnitIcon from '@mui/icons-material/AcUnit';
import BalconyIcon from '@mui/icons-material/Balcony';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import DeskIcon from '@mui/icons-material/Desk';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KitchenIcon from '@mui/icons-material/Kitchen';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LockIcon from '@mui/icons-material/Lock';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import PetsIcon from '@mui/icons-material/Pets';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ShowerIcon from '@mui/icons-material/Shower';
import TvIcon from '@mui/icons-material/Tv';
import WifiIcon from '@mui/icons-material/Wifi';
import {
    Box,
    Card,
    Grid,
    InputLabel,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import { capitalizeName } from '../../../../../shared/utils';
import { TimeArray } from '../Property/TimeArray';
import FormAutocomplete from '../../../../shared/FormAutocomplete';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormTextField from '../../../../shared/FormTextField';

export default function NewCommercialAmenitiesForm() {
    const { control, setValue } = useFormContext();
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');

    const theme = useTheme();
    
    const items = [
        {
            name: 'wifi',
            selected: false,
            icon: <WifiIcon fontSize='large' />,
        },
        {
            name: 'boardroom',
            selected: false,
            icon: <BusinessCenterIcon fontSize='large' />,
        },
        {
            name: 'tv',
            selected: false,
            icon: <TvIcon fontSize='large' />,
        },
        {
            name: 'kitchen',
            selected: false,
            icon: <KitchenIcon fontSize='large' />,
        },
        {
            name: 'parking',
            selected: false,
            icon: <LocalParkingIcon fontSize='large' />,
        },
        {
            name: 'aircon',
            selected: false,
            icon: <AcUnitIcon fontSize='large' />,
        },
        {
            name: 'dedicatedWorkspace',
            selected: false,
            icon: <DeskIcon fontSize='large' />,
        },

        {
            name: 'balcony',
            selected: false,
            icon: <BalconyIcon fontSize='large' />,
        },

        {
            name: 'bbq',
            selected: false,
            icon: <OutdoorGrillIcon fontSize='large' />,
        },

        {
            name: 'gym',
            selected: false,
            icon: <FitnessCenterIcon fontSize='large' />,
        },

        {
            name: 'diningOptions',
            selected: false,
            icon: <RestaurantIcon fontSize='large' />,
        },
        {
            name: 'petFriendly',
            selected: false,
            icon: <PetsIcon fontSize='large' />,
        },

        {
            name: 'showerRoom',
            selected: false,
            icon: <ShowerIcon fontSize='large' />,
        },
        {
            name: 'bikeLock',
            selected: false,
            icon: <PedalBikeIcon fontSize='large' />,
        },
        {
            name: 'personalStorage',
            selected: false,
            icon: <LockIcon fontSize='large' />,
        },

        {
            name: 'childcare',
            selected: false,
            icon: <ChildCareIcon fontSize='large' />,
        },
    ];
    
    const subType = useWatch({ control, name: 'details.subType'});

    return (
        <>
            <Typography
                color='textPrimary'
                pb='30px'
                pt={isIpad ? '10px' : '0px'}
            >
                Tell guests what your space has to offer.
            </Typography>
            <Grid
                container
                columnSpacing={5}
                rowSpacing={1}
                pb='15px'
            >
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Property Type</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.subType'
                            control={control}
                            options={[
                                { value: 'office', label: 'Office Space' },
                                { value: 'house', label: 'Home Office' },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Dedicated Desks</InputLabel>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={7}
                        sx={{
                            paddingBottom: isMobile || isIpad ? '15px' : '0px',
                        }}
                    >
                        <FormTextField
                            name='details.desks'
                            control={control}
                            type='number'
                            placeholder='100'
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                </Grid>

                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>
                            {subType === 'house'
                                ? `No. Rooms`
                                : `No. Private Offices`}
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormTextField
                            name='details.privateOffices'
                            control={control}
                            type='number'
                            placeholder='100'
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                </Grid>

                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Meeting Rooms</InputLabel>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={7}
                        sx={{
                            paddingBottom: isMobile || isIpad ? '15px' : '0px',
                        }}
                    >
                        <FormTextField
                            name='details.meetingRooms'
                            control={control}
                            type='number'
                            placeholder='100'
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel>Wifi Details</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormTextField
                            name='details.wifiDetails'
                            control={control}
                            placeholder='FREE WIFI, PW: 123456. This information will be displayed 24 hours before check-in and hidden after completed.'
                            inputProps={{ maxLength: 500 }}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Typography
                color='textPrimary'
                pb='20px'
                pt='30px'
            >
                Select available amenities:
            </Typography>
            <Grid container columns={{ lg: 7, md: 4, xs: 2 }} spacing={2}>
                {items.map((item, i) => (
                    <Grid item xs={1} key={i}>
                        <Controller 
                            name={`details.${item.name}`}
                            control={control}
                            render={({ field: { value }}) => (
                                <Card
                                    sx={{ 
                                        height: '100px',
                                        bgcolor: value ? theme.palette.primary.main : '#f6f8f9',
                                        color: value ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                    }}
                                    onClick={() => setValue(`details.${item.name}`, !value)}
                                >
                                    <Box 
                                        display='flex'
                                        flexDirection='column'
                                        alignItems='center'
                                        height='100%'
                                        gap={1}
                                        justifyContent='center'
                                        textAlign='center'
                                    >
                                        {item.icon}
                                        <Typography>
                                            {item.name === 'diningOptions'
                                                ? 'Dining Options'
                                                : item.name === 'petFriendly'
                                                ? 'Pet Friendly'
                                                : item.name === 'dedicatedWorkspace'
                                                ? 'Dedicated Workspace'
                                                : item.name === 'balcony'
                                                ? 'Balcony/Outdoor Space '
                                                : item.name === 'bbq'
                                                ? 'BBQ'
                                                : item.name === 'showerRoom'
                                                ? 'Shower/Change Room'
                                                : item.name === 'bikeLock'
                                                ? 'Bike Lock'
                                                : item.name === 'personalStorage'
                                                ? 'Personal Lockers/Storage'
                                                : item.name === 'childcare'
                                                ? 'Child Care'
                                                : capitalizeName(item.name)}
                                        </Typography>
                                    </Box>
                                </Card>
                            )}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

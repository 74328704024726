import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StyledFilterButton, StyledFilterText } from './SharedFilterComponents';
import { usePopoverOnButtonClick } from './filterHooks';
import {
    Box,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    MenuItem,
    NativeSelect,
    OutlinedInput,
    Popover,
    Select,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import UseDebounceInput from '../../../shared/DebounceInput';
// import { SearchIconWrapper, StyledListItem, } from 'src/Shared/StyledComponent/StyledComponents';

const theme = createTheme({
    palette: {
        // action: {
        //     disabledBackground: 'red',
        //     disabled: 'red',
        // },
    },
});

export default function DropdownFilter({
    id,
    options,
    handleChange,
    selectedValue,
    isTopLevel = true,
    backgroundColor,
    border,
    minHeight,
    maxHeight,
    disabled = false,
}: {
    id?: any;
    options?: any;
    handleChange?: any;
    selectedValue?: any;
    isTopLevel?: boolean;
    backgroundColor?: string;
    border?: string;
    minHeight?: string;
    maxHeight?: string;
    disabled?: boolean;
}) {
    const { handleClick, handleTab, handleClose, open, popoverId, anchorEl } =
        usePopoverOnButtonClick();

    const [searchText, setSearchText] = UseDebounceInput(10);
    const isMobile = useMediaQuery('(max-width: 770px)');

    const [filteredBySearchTextOptions, setFilteredBySearchTextOptions] =
        useState<any[]>([]);

    useEffect(() => {
        setFilteredBySearchTextOptions(
            options.filter((option: any) => {
                if (
                    option.label
                        ?.toLowerCase()
                        ?.replace(/\s/g, '')
                        ?.includes(
                            searchText?.toLowerCase()?.replace(/\s/g, '')
                        )
                ) {
                    return true;
                } else {
                    return false;
                }
            })
        );
    }, [options, searchText]);

    const handleKey = (e: any, value: any) => {
        if (e.key === 'Enter' && !value.disabled) {
            handleChange(value);
            handleClose();
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <FormControl fullWidth>
                <StyledFilterButton
                    disableElevation
                    // disableRipple
                    aria-describedby={popoverId}
                    onClick={handleClick}
                    // onSelect={handleClick}
                    // onKeyDown={handleTab}
                    onFocus={handleTab}
                    // onMouseEnter={handleClick}
                    variant={'outlined'}
                    device={isMobile.toString()}
                    onChange={handleChange}
                    disabled={disabled}
                    sx={{
                        backgroundColor: open ? '#fff' : backgroundColor,
                        borderColor: open ? '#5cb5b3' : border,
                        color: open ? '#5cb5b3' : '#787878',
                        minHeight: minHeight,
                        maxHeight: maxHeight,
                    }}
                >
                    <StyledFilterText variant={'body2'}>
                        {selectedValue === true
                            ? 'Yes'
                            : selectedValue === false
                            ? 'No'
                            : selectedValue}
                    </StyledFilterText>
                    <Box width={10} flexGrow={1} />
                    {isTopLevel ? (
                        <KeyboardArrowDown />
                    ) : (
                        <KeyboardArrowRightIcon />
                    )}
                </StyledFilterButton>
                <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    disableRestoreFocus
                    anchorOrigin={{
                        vertical: isTopLevel ? 'bottom' : 'top',
                        horizontal: isTopLevel ? 'left' : 'right',
                    }}
                >
                    <List sx={{ maxHeight: '200px', overflow: 'auto' }} id={id}>
                        {options?.length > 2 ? (
                            <ListItem>
                                <Input
                                    defaultValue={searchText}
                                    placeholder={'Search'}
                                    endAdornment={
                                        <InputAdornment position={'end'}>
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                    onChange={(e) =>
                                        setSearchText(e.target.value)
                                    }
                                />
                            </ListItem>
                        ) : null}
                        {filteredBySearchTextOptions.map(
                            (option: {
                                value: any;
                                label: any;
                                disabled: boolean;
                            }) => (
                                <ListItem
                                    onKeyDown={(e: any) => {
                                        if (!option.disabled) {
                                            handleKey(e, option.value);
                                        }
                                        // handleClose();
                                    }}
                                    onClick={() => {
                                        if (!option.disabled) {
                                            handleChange(option.value);
                                            handleClose();
                                        }
                                    }}
                                    key={option.value}
                                    value={option.value}
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: option.disabled
                                            ? '#d3d3d3'
                                            : '#787878',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            // color: '#5cb5b3',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                >
                                    <ListItemButton
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                        }}
                                    >
                                        {' '}
                                        {option.label}
                                    </ListItemButton>
                                </ListItem>
                            )
                        )}
                    </List>
                </Popover>
            </FormControl>
        </ThemeProvider>
    );
}

{
    /* <ThemeProvider theme={theme}>
<StyledFilterButton
    disableElevation
    // disableRipple
    aria-describedby={popoverId}
    onClick={handleClick}
    variant={'outlined'}
    device={isMobile.toString()}
    onChange={handleChange}
    sx={{
        backgroundColor: open ? '#fff' : backgroundColor,
        borderColor: open ? '#5cb5b3' : border,
        color: open ? '#5cb5b3' : '#787878',
    }}
>
    <StyledFilterText variant={'body2'}>
        {selectedValue === true
            ? 'Yes'
            : selectedValue === false
            ? 'No'
            : selectedValue}
    </StyledFilterText>
    <Box width={10} flexGrow={1} />
    {isTopLevel ? (
        <KeyboardArrowDown />
    ) : (
        <KeyboardArrowRightIcon />
    )}
</StyledFilterButton>
<Popover
    id={popoverId}
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
        vertical: isTopLevel ? 'bottom' : 'top',
        horizontal: isTopLevel ? 'left' : 'right',
    }}
>
    <List sx={{ maxHeight: '200px' , overflow: 'auto',}} id={id}>
        {options.map((option: { value: any; label: any }) => (
            <ListItem
                onClick={() => {
                    handleChange(option.value);
                    handleClose();
                }}
                key={option.value}
                value={option.value}
                sx={{
                   
                    fontFamily: 'Open Sans',
                    color: '#787878',
                    '&:hover': {
                        cursor: 'pointer',
                        // color: '#5cb5b3',
                        backgroundColor: '#f6f8f9',
                    },
                }}
            >
                <ListItemButton
                    sx={{
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    {' '}
                    {option.label}
                </ListItemButton>
            </ListItem>
        ))}
    </List>
</Popover>
</ThemeProvider> */
}

{
    /* <FormControl fullWidth>
<NativeSelect
    sx={{
        backgroundColor: '#fff',
        borderColor: '#5cb5b3',
        color: '#787878',
        maxHeight: '45px',
        maxWidth: '99%',
        // '&:hover': {
        //     cursor: 'pointer',
        //     // color: '#5cb5b3',
        //     backgroundColor: '#f6f8f9',
        // },
    }}
   
    onChange={handleChange}
    input={<OutlinedInput />}
>
    {options.map((option: any) => (
        <option
        onMouseEnter={()=>{
            setHover(true);
          }}
            style={{
                backgroundColor:'#fff',
                fontFamily: 'Open Sans',
                color: '#787878',
                height: '85px',
                // hover: {
                //     cursor: 'pointer',
                //     // color: '#5cb5b3',
                //     backgroundColor: '#f6f8f9',
                // },
                // ...(hover ? 'red' : null)
            
            }}
            onClick={() => {
                handleChange(option.value);
                handleClose();
            }}
            key={option.value}
            // value={option.value}
        >
            <Typography
                sx={{
                    fontFamily: 'Open Sans',
                    color: '#787878',
                }}
                // variant='h5'
            >
                {option.label}
            </Typography>
        </option>
    ))}
</NativeSelect>
</FormControl> */
}

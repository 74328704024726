import { Box, Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectSnackBars, Toast } from '../../app/Slices/SnackBarSlice';
import IndividualToast from './IndividualToast';

const ToastContainer = () => {
    const toasts = useSelector(selectSnackBars);

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                width: '100%',
                zIndex: 1300,
                pointerEvents: 'none',
                display: 'flex',
                justifyContent: { xs: 'center', md: 'flex-end' },
                alignItems: 'center',
                py: ['', 'login'].includes(window.location.href.split('/')[3])
                    ? 2
                    : 9,
                px: 1,
            }}
        >
            <Grid
                container
                sx={{
                    maxWidth: { xs: '90%', md: '460px' },
                    flexDirection: 'column',
                    alignItems: { xs: 'center', md: 'flex-start' },
                }}
            >
                {toasts.map((toast: Toast) => {
                    return <IndividualToast key={toast.id} snackbar={toast} />;
                })}
            </Grid>
        </Box>
    );
};

export default ToastContainer;

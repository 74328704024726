import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetIndividualOwnerAssetsQuery } from '../../../../app/Slices/SevenDayApiSlice';
import { FormValues, LinkedModule } from '../../../types/experience';
import ExperiencePolicies from '../Policies/ExperiencePolicies';
import NewBoatAmenitiesForm from './Boat/NewBoatAmenitiesForm';
import NewCommercialAmenitiesForm from './Commercial/NewCommercialAmenitiesForm';
import NewPropertyAmenitiesForm from './Property/NewPropertyAmenitiesForm';
import NewVehicleAmenitiesForm from './Vehicle/NewVehicleAmenitiesForm';
import LinkCalendars from './SharedComponents/LinkCalendars';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import ExperienceDetails from './ExperienceDetails';
import { zodResolver } from '@hookform/resolvers/zod';
import { ExperienceSchema } from './schema';
import LocationDetails from './LocationDetails';
import HiringDetails from './HiringDetails';
import ExperiencePhotos from './ExperiencePhotos';
import { useNavigate } from 'react-router-dom';
import UserRole from '../../../../shared/AuthRoles';
import { ArrowBack } from '@mui/icons-material';
import NewActivityAmenitiesForm from './Activity/NewActivityAmenitiesForm';
import CancelModal from './CancelModal';
import ExperienceCharges from '../Fees/ExperienceCharges';
import NetEarnings from '../Fees/NetEarnings';
import ConfirmationModal from '../../../shared/ConfirmationModal';
import SaveExperienceConfirmationModal from './SharedComponents/SaveExperienceConfirmationModal';
import {
    selectPriceRequired,
    setPriceRequired,
} from '../../../../app/Slices/PriceRequiredSlice';
import PricingModalTest from './Pricing/PricingModal';
import SpaceConfiguration from '../Spaces/SpaceConfiguration';

const VehicleDetails = {
    dropoffLocationSameAsPickup: false,
    dropoffStreetAddress1: '',
    dropoffStreetAddress2: '',
    dropoffSuburb: '',
    dropoffState: '',
    dropoffPostcode: '',
    dropoffCountryCode: '',
    dropoffPhone: '',
    dropoffUsesNirovision: false,
    minimumLicenceRequired: '',
    minimumDriversAge: undefined,
    petsAllowed: false,
    smokingAllowed: false,
    rules: '',
    subType: '', // Default to 'car'
    make: '',
    model: '',
    year: null, // Assume the user will provide a valid year
    transmission: '', // Default to 'manual'
    heatedSeats: false,
    appleCarPlay: false,
    androidAuto: false,
    fuelType: '', // Default to 'petrol'
    rearVisionCamera: false,
    cruiseControl: false,
    touchscreenDisplay: false,
    bluetooth: false,
    blindSpotAlert: false,
    navigation: false,
    tyrePressureSensor: false,
    airbags: false,
    bodyType: 'sedan', // Default to 'sedan'
    numberOfDoors: '', // Default to a common car type
    numberOfSeats: '', // Default to a common car type
    ac: false,
    keylessEntry: false,
    sunroof: false,
    laneAssist: false,
    autoPark: false,
    powerSeats: false,
    remoteStart: false,
    wirelessPhoneCharger: false,
    rearEntertainmentSystem: false,
    engineCC: '',
    adjustableSeats: false,
    helmet: false,
    gloves: false,
};

const PropertyDetails = {
    catering: false,
    alcohol: false,
    wifi: false,
    boardroom: false,
    tv: false,
    kitchen: false,
    parking: false,
    aircon: false,
    dedicatedWorkspace: false,
    pool: false,
    bbq: false,
    firepit: false,
    washingMachine: false,
    gamesRoom: false,
    spa: false,
    wineCellar: false,
    massages: false,
    wifiDetails: '',
    checkinDetails: '',
};

interface FormProps {
    owner: any;
    experience?: FormValues;
    isEditing?: boolean;
    onSubmit: (data: FormValues) => void;
}

export default function ExperienceForm({
    owner,
    experience,
    isEditing = false,
    onSubmit,
}: FormProps) {
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const navigate = useNavigate();
    const loggedInUser = UserRole();
    const dispatch = useDispatch();

    // TODO: Show exit prompt
    const methods = useForm<FormValues>({
        // mode: 'onChange',
        resolver: zodResolver(ExperienceSchema),
        defaultValues: {
            // experience details
            type: '',
            visitType: '',
            name: '',
            description: '',
            isAvailable: '',
            notificationsEmailType: 'default',
            notificationsEmail: '',
            // location details
            streetAddress1: '',
            streetAddress2: '',
            suburb: '',
            state: '',
            postcode: '',
            countryCode: '',
            phone: '',
            usesNirovision: false,
            // hiring details
            minHireHours: undefined,
            maxHireHours: undefined,
            bufferHours: undefined,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            publicHolidays: false,
            instantBook: false,
            checkinTime: '',
            checkoutTime: '',
            checkinDetails: '',
            policies: [],
            hasPolicies: false,
            charges: [],
            discounts: [],
            spaces: [],
            linkedModules: [],
            details: {
                ...VehicleDetails,
                ...PropertyDetails,
            },
        },
        shouldUnregister: false,
        shouldFocusError: true,
    });

    const {
        control,
        reset,
        watch,
        handleSubmit,
        setFocus,
        formState: { isDirty, isSubmitting },
    } = methods;

    const [loading, setLoading] = useState<boolean>(true);
    const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
    const [pendingSubmitData, setPendingSubmitData] =
        useState<FormValues | null>(null);

    const priceRequired = useSelector(selectPriceRequired);

    const handleSaveConfirmation = (data: FormValues) => {
        setPendingSubmitData(data);

        const prices = data.charges.filter(
            (charge) => charge.chargeType === 'price' || charge.chargeType === 'flexiblePrice'
        );

        if (prices.length > 0 && priceRequired.length === 0) {
            if (Object.keys(errors).length === 0) {
                onSubmit(data);
            } else {
                setFocus(Object.keys(errors)[0] as any);
            }

            setPendingSubmitData(null);
        } else {
            setShowSaveModal(true);
        }
    };

    const handleConfirmSubmit = () => {
        if (pendingSubmitData) {
            if (Object.keys(errors).length === 0) {
                onSubmit(pendingSubmitData);
            } else {
                setFocus(Object.keys(errors)[0] as any);
            }
        }
        setShowSaveModal(false);
        setPendingSubmitData(null);
    };

    useEffect(() => {
        if (experience && isEditing) {
            reset({
                ...experience,
                instantBook: !experience.requiresBookingApproval,
                linkedModules: experience.linkedModules.map(
                    (value) => (value as LinkedModule).id
                ),
                notificationsEmailType:
                    experience.notificationsEmail === '' ? 'default' : 'custom',
                hasPolicies: experience.policies.length > 0,
            });
        }

        dispatch(setPriceRequired([]));
        setLoading(false);
    }, [experience, isEditing]);

    const { data: allAssets } = useGetIndividualOwnerAssetsQuery({
        url: `owners/${loggedInUser?.company}/modules`,
    });
    const type = watch('type');
    const visitType = watch('visitType');

    const experienceName = useWatch({ name: 'name', control });
    const available = watch('isAvailable');

    //Retrieve assets and compare asset.name with the new experience name
    const mapOverAllAssets = allAssets
        ?.filter((asset: FormValues) => asset.id !== experience?.id)
        ?.map((asset: { name: string }) => asset.name);

    const [errors, setErrors] = useState<Record<string, string>>({});
    const [open, setOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [pricingModalOpen, setPricingModalOpen] = useState<boolean>(false);

    useEffect(() => {
        const findDuplicate = mapOverAllAssets?.find(
            (i: any) =>
                i.toLowerCase().trim() === experienceName?.toLowerCase().trim()
        );

        if (findDuplicate) {
            setErrors((prev) => ({ ...prev, name: 'Duplicate name' }));
        } else {
            const { name, ...rest } = errors;
            setErrors(rest);
        }
    }, [experienceName]);

    const handleBack = () => {
        if (isEditing) {
            navigate(
                `/viewExperiences/${loggedInUser?.company}/${experience?.type}/${experience?.id}`
            );
        } else {
            navigate('/experiences');
        }
    };

    const onClickGoBack = () => {
        if (isDirty) {
            setOpen(true);
        } else {
            handleBack();
        }
    };

    return (
        <Box display='flex' justifyContent='center'>
            {loading ? (
                <Box pt='30px'>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(handleSaveConfirmation)}>
                            <Stack spacing='30px' pt='30px'>
                                <ExperienceDetails
                                    isEditing={isEditing}
                                    errors={errors}
                                    owner={owner}
                                />
                                {isMobile || isIpad ? null : <Divider />}
                                <LocationDetails type={type} />
                                {isMobile || isIpad ? null : <Divider />}
                                <HiringDetails
                                    type={type}
                                    visitType={visitType}
                                />
                                {isMobile || isIpad ? null : <Divider />}
                                <ExperiencePolicies experienceType={type} />
                                {isMobile || isIpad ? null : <Divider />}
                                <ExperiencePhotos />
                                {type === 'property' && (
                                    <>
                                        {isMobile || isIpad ? null : (
                                            <Divider />
                                        )}
                                        <LinkCalendars
                                            existingModule={experience}
                                        />
                                    </>
                                )}
                                {type !== '' && (
                                    <>
                                        {isMobile || isIpad ? null : (
                                            <Divider />
                                        )}
                                        <Box>
                                            <Typography
                                                component='h4'
                                                color='textPrimary'
                                                fontWeight='bold'
                                                pb='15px'
                                            >
                                                {type === 'property'
                                                    ? 'Property Details'
                                                    : type === 'boat'
                                                    ? 'Boat Details'
                                                    : type === 'vehicle'
                                                    ? 'Vehicle Details'
                                                    : type === 'commercial'
                                                    ? 'Space Details'
                                                    : 'Activity Details'}
                                            </Typography>
                                            {type === 'boat' ? (
                                                <NewBoatAmenitiesForm />
                                            ) : type === 'property' ? (
                                                <NewPropertyAmenitiesForm />
                                            ) : type === 'vehicle' ? (
                                                <NewVehicleAmenitiesForm
                                                    isEditing={isEditing}
                                                />
                                            ) : type === 'commercial' ? (
                                                <NewCommercialAmenitiesForm />
                                            ) : (
                                                <NewActivityAmenitiesForm />
                                            )}
                                        </Box>
                                        {isMobile || isIpad ? null : (
                                            <Divider />
                                        )}
                                        {['property', 'boat'].includes(
                                            type
                                        ) && (
                                            <>
                                                <Box>
                                                    <Typography
                                                        component='h4'
                                                        color='textPrimary'
                                                        fontWeight='bold'
                                                        pb='15px'
                                                    >
                                                        Space Configurations
                                                    </Typography>

                                                    <SpaceConfiguration />
                                                </Box>

                                                {isMobile || isIpad ? null : (
                                                    <Divider />
                                                )}
                                            </>
                                        )}

                                        <ExperienceCharges
                                            experienceType={type}
                                            isEditing={isEditing}
                                        />
                                        <NetEarnings
                                            // fees={details.details?.charges}
                                            experienceType={type}
                                        />
                                    </>
                                )}
                            </Stack>
                            <Box
                                display='flex'
                                justifyContent='space-between'
                                py='50px'
                            >
                                <Button
                                    startIcon={<ArrowBack />}
                                    onClick={onClickGoBack}
                                    size='small'
                                >
                                    {!isMobile &&
                                        `Back to ${
                                            isEditing ? 'experience' : 'options'
                                        }`}
                                </Button>
                                <SaveExperienceConfirmationModal
                                    experienceType={type}
                                    open={showSaveModal}
                                    onChange={handleConfirmSubmit}
                                    onClose={() => setShowSaveModal(false)}
                                />
                                <Box display='flex' gap={1}>
                                    {isEditing && (
                                        <Button
                                            variant='contained'
                                            color='error'
                                            onClick={() =>
                                                setDeleteModalOpen(true)
                                            }
                                        >
                                            Delete
                                        </Button>
                                    )}
                                    <Button
                                        variant='contained'
                                        type='submit'
                                        disabled={isSubmitting}
                                    >
                                        {available
                                            ? `Save ${type}`
                                            : 'Save Draft'}
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </FormProvider>

                    <CancelModal
                        open={open}
                        onClose={() => setOpen(false)}
                        onSubmit={handleBack}
                    />
                    <ConfirmationModal
                        open={deleteModalOpen}
                        onClose={() => setDeleteModalOpen(false)}
                        onChange={() => setDeleteModalOpen(false)}
                        message={`delete this ${type}`}
                        component={'experience'}
                    />
                </>
            )}
        </Box>
    );
}

import { useState, useRef } from "react";
import {
  KnockFeedProvider,
  NotificationIconButton,
  NotificationFeedPopover,
  OnNotificationClick,
} from "@knocklabs/react-notification-feed";
import UserRole from "../../../shared/AuthRoles";
import React from "react";

// Required CSS import, unless you're overriding the styling
import "@knocklabs/react-notification-feed/dist/index.css";
import "./Notification.css";
import { useNavigate } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import ReactGA from 'react-ga4';

export default function NotificationsCenter() {
  const isMobile = useMediaQuery("(max-width: 770px)");
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef(null);

  const loggedInUser = UserRole();
  const navigate = useNavigate();

  const onNotificationClick: OnNotificationClick = (item: any) => {
    setIsVisible(false)
    if (item.data.bookingId) {
      navigate(`/reservations/experience/${item.data.bookingId}`, {
        state: {
          name: "viewReservation",
          component: "notifications",
          url: "/",
        },
      });
      ReactGA.event({
        category: 'UI',
        action: `Ind. Notification Clicked`,
        label: `Ind. Notification Clicked - ${loggedInUser.role}`,
    });
    }
  };

  const notificationCenterClicked = ()=>{
    setIsVisible(!isVisible)
    ReactGA.event({
      category: 'UI',
      action: `Notification Center`,
      label: `Notification Center Clicked - ${loggedInUser.role}`,
  });
  }

  return (
    <KnockFeedProvider
      apiKey={process.env.REACT_APP_KNOCK_PUBLIC_API_KEY as string}
      feedId={process.env.REACT_APP_KNOCK_FEED_CHANNEL_ID as string}
      userId={loggedInUser?.sub.toString()}
    >
      <Grid
        sx={{
          margin: isMobile ? "5px 0px 20px 0px" : "16px 0px 0px 0px",
          display: "flex",
          justifyContent: isMobile ? "flex-start" : "flex-end",
        }}
      >
        <NotificationIconButton
          ref={notifButtonRef}
          onClick={notificationCenterClicked}
        />

        <NotificationFeedPopover
          buttonRef={notifButtonRef}
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          onNotificationClick={onNotificationClick}
        />
      </Grid>
    </KnockFeedProvider>
  );
}

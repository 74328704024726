import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../layout/styledcomponents/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import { CustomIconButton } from '../../layout/styledcomponents/CustomIconButton';
import DropdownFilter from '../../layout/styledcomponents/filters/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { selectCalendarViewReservationData } from '../../app/Slices/CalendarViewSlice';
import UserRole from '../../shared/AuthRoles';
import MultiSelectDropdownFilter from '../../layout/styledcomponents/filters/MultiSelectDropdown';
import { useGetReportQuery } from '../../app/Slices/SevenDayApiSlice';
import { CustomBadge } from '../../layout/styledcomponents/CustomBadge';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
    selectReservationFilters,
    setReservationFilters,
} from '../../app/Slices/ReservationFiltersSlice';
import ReactGA from 'react-ga4';

export default function CalendarFiltersModal() {
    const filters: any = useSelector(selectReservationFilters);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const calendarViewSelected: any = useSelector(
        selectCalendarViewReservationData
    );
    const { data: guestCompanies, isFetching } = useGetReportQuery(
        `/reports/guest-details`,
        { skip: loggedInUser?.role.includes('guest') }
    );

    const [status, setStatus] = useState(filters.status);
    const [startDate, setStartDate] = useState(filters.startDate);
    const [moduleType, setModuleType] = useState(filters.moduleType);
    const [visitType, setVisitType] = useState(filters.visitType);
    const [bookerType, setBookerType] = useState(filters.bookerType);
    const [guest, setGuest] = useState(filters.guest);

    const filterCount =
        (filters.startDate ? 1 : 0) +
        (filters?.moduleType?.length ? 1 : 0) +
        (filters?.guest?.length ? 1 : 0) +
        // (filters?.status?.length ? 1 : 0) +
        (filters.visitType ? 1 : 0) +
        (filters.bookerType ? 1 : 0);

    useEffect(() => {
        // When switching to calendar view reset the status and startDate filters

        ReactGA.event({
            category: 'UI',
            action: `Calendar Filters`,
            label: 'Calendar Filters Clicked',
        });
        if (calendarViewSelected.view) {
            setStartDate('');
            setStatus([]);
            dispatch(
                setReservationFilters({
                    startDate: '',
                    moduleType,
                    status: [],
                    guest,
                    visitType,
                    bookerType,
                })
            );
        }
    }, [calendarViewSelected.view]);

    const handleSave = () => {
        setOpen(false);
        dispatch(
            setReservationFilters({
                startDate,
                moduleType,
                status,
                guest,
                visitType,
                bookerType,
            })
        );
    };

    const handleCloseModal = () => {
        setOpen(false);
      
    };


    const onClickClearFilters = () => {
        setStartDate('');
        if (calendarViewSelected?.view === true) {
            setStatus([]);
        }
        setModuleType([]);
        setGuest([]);
        setVisitType('');
        setBookerType('');
    };

    return (
        <>
            <CustomBadge
                color='green'
                badgeContent={filterCount}
                invisible={filterCount === 0}
            >
                <CustomButton
                    variant='outlined'
                    color='green'
                    onClick={() => setOpen(true)}
                >
                    <FilterAltIcon fontSize='small' />{' '}
                </CustomButton>
            </CustomBadge>
            <Dialog open={open} onClose={handleCloseModal}>
                <Grid
                    sx={{
                        // background: ' pink',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            // background: 'yellow',
                            // textAlign: 'center',
                            height: '50px',
                            width: isMobile ? '320px' : '600px',

                            // alignContent: 'center',
                            // alignItems: 'center',
                            borderBottom: ' #f6f8f9 solid 1px',
                        }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                // background: 'red',
                                height: '100%',
                                width: isMobile ? '88%' : '90%',
                                // paddingLeft: '18px',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    textAlign: 'center',
                                    paddingLeft: '18px',
                                }}
                                variant='h6'
                            >
                                Filters
                            </Typography>
                        </Grid>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // backgroundColor: 'blue',
                                height: '100%',
                                width: '10%',
                            }}
                        >
                            <CustomIconButton
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#5cb5b3',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                }}
                                onClick={handleCloseModal}
                            >
                                <CloseIcon />
                            </CustomIconButton>
                        </Grid>
                    </Grid>
                    <DialogContent
                        dividers
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'auto',
                            width: '100%',
                        }}
                    >
                        <DialogContentText>
                            {calendarViewSelected?.view === true ? null : (
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {' '}
                                    <Grid
                                        sx={{ width: isMobile ? '35%' : '35%' }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#000',
                                                paddingRight: '10px',
                                                width: '100%',
                                            }}
                                        >
                                            Date Range:
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        sx={{ width: isMobile ? '65%' : '75%' }}
                                    >
                                        <DropdownFilter
                                            id={'rangeFilter'}
                                            options={[
                                                { value: '', label: 'Any' },
                                                {
                                                    value: 'yesterday',
                                                    label: 'Yesterday',
                                                },
                                                {
                                                    value: 'today',
                                                    label: 'Today',
                                                },
                                                {
                                                    value: 'tomorrow',
                                                    label: 'Tomorrow',
                                                },
                                                {
                                                    value: 'current-week',
                                                    label: 'This Week',
                                                },
                                                {
                                                    value: 'prev-week',
                                                    label: 'Last Week',
                                                },
                                                {
                                                    value: 'next-week',
                                                    label: 'Next Week',
                                                },
                                                {
                                                    value: 'current-month',
                                                    label: 'This Month',
                                                },
                                                {
                                                    value: 'prev-month',
                                                    label: 'Last Month',
                                                },
                                                {
                                                    value: 'next-month',
                                                    label: 'Next Month',
                                                },
                                                {
                                                    value: 'current-year',
                                                    label: 'This Year',
                                                },
                                                {
                                                    value: 'past',
                                                    label: 'All Past',
                                                },
                                                {
                                                    value: 'future',
                                                    label: 'All Future',
                                                },
                                            ]}
                                            handleChange={setStartDate}
                                            selectedValue={
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    {startDate ===
                                                    'current-week'
                                                        ? 'This Week'
                                                        : startDate ===
                                                          'next-week'
                                                        ? 'Next Week'
                                                        : startDate ===
                                                          'current-month'
                                                        ? 'This Month'
                                                        : startDate ===
                                                          'next-month'
                                                        ? 'Next Month'
                                                        : startDate ===
                                                          'prev-week'
                                                        ? 'Last Week'
                                                        : startDate ===
                                                          'prev-month'
                                                        ? 'Last Month'
                                                        : startDate ===
                                                          'current-year'
                                                        ? 'This Year'
                                                        : startDate === 'past'
                                                        ? 'All Past'
                                                        : startDate === 'future'
                                                        ? 'All Future'
                                                        : startDate === ''
                                                        ? 'Any'
                                                        : startDate}
                                                </Typography>
                                            }
                                            backgroundColor={'white'}
                                            border={'#C3C3C3'}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Grid sx={{ width: isMobile ? '35%' : '35%' }}>
                                    {' '}
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#000',
                                            paddingRight: '10px',
                                        }}
                                    >
                                        Experience Type:
                                    </Typography>
                                </Grid>
                                <Grid sx={{ width: isMobile ? '65%' : '75%' }}>
                                    <MultiSelectDropdownFilter
                                        id={'typeFilter'}
                                        options={[
                                            {
                                                value: 'property',
                                                label: 'Property',
                                            },
                                            {
                                                value: 'commercial',
                                                label: 'Commercial',
                                            },
                                            { value: 'boat', label: 'Boat' },
                                            {
                                                value: 'vehicle',
                                                label: 'Vehicle',
                                            },
                                            {
                                                value: 'activity',
                                                label: 'Activity',
                                            },
                                        ]}
                                        handleChange={setModuleType}
                                        selectedValue={moduleType}
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                    />
                                </Grid>
                            </Grid>

                            {/* <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {' '}
                                <Grid sx={{ width: isMobile ? '35%' : '35%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#000',
                                            paddingRight: '10px',
                                            width: '100%',
                                        }}
                                    >
                                        Status:
                                    </Typography>
                                </Grid>
                                <Grid sx={{ width: isMobile ? '65%' : '75%' }}>
                                    <MultiSelectDropdownFilter
                                        id={'statusFilter'}
                                        options={
                                            calendarViewSelected.view
                                                ? [
                                                      {
                                                          value: 'pending',
                                                          label: 'Pending',
                                                      },
                                                      {
                                                          value: 'approved',
                                                          label: 'Approved',
                                                      },
                                                      {
                                                          value: 'completed',
                                                          label: 'Completed',
                                                      },
                                                  ]
                                                : [
                                                      {
                                                          value: 'pending',
                                                          label: 'Pending',
                                                      },
                                                      {
                                                          value: 'approved',
                                                          label: 'Approved',
                                                      },
                                                      {
                                                          value: 'cancelled',
                                                          label: 'Cancelled',
                                                      },
                                                      {
                                                          value: 'declined',
                                                          label: 'Declined',
                                                      },
                                                      {
                                                          value: 'completed',
                                                          label: 'Completed',
                                                      },
                                                  ]
                                        }
                                        selectedValue={status}
                                        handleChange={setStatus}
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                    />
                                </Grid>
                            </Grid> */}
                            {guestCompanies && guestCompanies?.length > 0 && (
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid
                                        sx={{ width: isMobile ? '35%' : '35%' }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#000',
                                                paddingRight: '10px',
                                                width: '100%',
                                            }}
                                        >
                                            Guest:
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        sx={{ width: isMobile ? '65%' : '75%' }}
                                    >
                                        <MultiSelectDropdownFilter
                                            id={'companyFilter'}
                                            options={guestCompanies.map(
                                                (company: any) => ({
                                                    value: company.id,
                                                    label: company.name,
                                                })
                                            )}
                                            handleChange={setGuest}
                                            selectedValue={guest}
                                            backgroundColor={'white'}
                                            border={'#C3C3C3'}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Grid sx={{ width: isMobile ? '35%' : '35%' }}>
                                    {' '}
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#000',
                                            paddingRight: '10px',
                                        }}
                                    >
                                        Visit Type:
                                    </Typography>
                                </Grid>
                                <Grid sx={{ width: isMobile ? '65%' : '75%' }}>
                                    <DropdownFilter
                                        id={'visitTypeFilter'}
                                        options={[
                                            { value: '', label: 'Any' },
                                            {
                                                value: 'business',
                                                label: 'Business',
                                            },
                                            {
                                                value: 'personal',
                                                label: 'Personal',
                                            },
                                        ]}
                                        handleChange={setVisitType}
                                        selectedValue={visitType || 'Any'}
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                    />
                                </Grid>
                            </Grid>
                            {!loggedInUser?.role.includes('guest') && (
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Grid
                                        sx={{ width: isMobile ? '35%' : '35%' }}
                                    >
                                        {' '}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#000',
                                                paddingRight: '10px',
                                            }}
                                        >
                                            Reserved By:
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        sx={{ width: isMobile ? '65%' : '75%' }}
                                    >
                                        <DropdownFilter
                                            id={'visitTypeFilter'}
                                            options={[
                                                { value: '', label: 'Any' },
                                                {
                                                    value: 'owner',
                                                    label: 'Owner',
                                                },
                                                {
                                                    value: 'guest',
                                                    label: 'Guest',
                                                },
                                            ]}
                                            handleChange={setBookerType}
                                            selectedValue={bookerType || 'Any'}
                                            backgroundColor={'white'}
                                            border={'#C3C3C3'}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </DialogContentText>
                    </DialogContent>{' '}
                    <DialogActions
                        sx={{
                            borderTop: ' #d8d8d8 solid 1px',
                            // minHeight: '60px',
                            background: ' #eeeeee',
                        }}
                    >
                        <Grid>
                            <CustomButton
                                variant='contained'
                                color='neutral'
                                // autoFocus
                                onClick={onClickClearFilters}
                            >
                                Clear Filters
                            </CustomButton>
                        </Grid>
                        <Grid>
                            {' '}
                            <CustomButton
                                variant='contained'
                                color='green'
                                // autoFocus
                                onClick={handleSave}
                            >
                                Save
                            </CustomButton>
                        </Grid>
                    </DialogActions>
                </Grid>
            </Dialog>
        </>
    );
}

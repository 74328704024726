import React, { useState } from 'react';

import { HelpOutline } from '@mui/icons-material';
import {
    Badge,
    Box,
    List,
    ListItem,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import toast from '../../../shared/Toast';
import { Photo } from '../../../types/user';
import ProfilePicture from '../ProfilePicture';
import ProfilePictureModal from './ProfilePictureModal';

interface UserProps {
    firstName: string;
    lastName: string;
    profilePicture?: {
        src: string;
    };
}

interface ProfileProps {
    user: UserProps;
    onSubmit: (data: Photo) => void;
    message?: boolean;
}

const EditProfilePicture = ({
    user,
    onSubmit,
    message = true,
}: ProfileProps) => {
    const isMobile = useMediaQuery('(max-width: 770px)');

    const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
    const [photo, setPhoto] = useState<File>();

    const [updatedPhoto, setUpdatedPhoto] = useState('');

    const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            if (e.target.files[0].size > 5000000) {
                toast.error('File must be smaller than 5MB.');
                return;
            }

            setPhoto(e.target.files[0]);
            setUploadModalOpen(true);
        }

        e.target.value = '';
    };

    const onClose = () => {
        setPhoto(undefined);
        setUploadModalOpen(false);
    };

    const handleSubmit = (photo: Photo) => {
        setUpdatedPhoto(photo.src);
        onSubmit(photo);
    };

    return (
        <>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems='center'
                gap='30px'
            >
                <Badge
                    overlap='circular'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                        <Tooltip
                            title={
                                <Typography
                                    // color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    <List>
                                        <ListItem>
                                            {' '}
                                            Your profile picture will be used
                                            for facial recognition.
                                        </ListItem>
                                        <ListItem>
                                            {' '}
                                            For security reasons, a profile
                                            picture is mandatory for some
                                            experiences.
                                        </ListItem>
                                    </List>
                                </Typography>
                            }
                            placement='right'
                        >
                            <HelpOutline
                                sx={{
                                    color: '#787878',
                                    bgcolor: '#fff',
                                    borderRadius: '24px',
                                    padding: '1px',
                                    fontSize: '24px',
                                }}
                            />
                        </Tooltip>
                    }
                >
                    <ProfilePicture
                        profileDetails={user}
                        src={
                            updatedPhoto
                                ? updatedPhoto
                                : user?.profilePicture?.src
                        }
                    />
                </Badge>
                <Box
                    display={isMobile ? 'flex' : 'block'}
                    flexDirection='column'
                    alignItems={isMobile ? 'center' : 'left'}
                >
                    {!isMobile && (
                        <>
                            <Typography
                                fontFamily='Open Sans'
                                color='#787878'
                                fontWeight='bold'
                                gutterBottom
                            >
                                Profile Picture
                            </Typography>

                            <Typography
                                fontFamily='Open Sans'
                                color='#787878'
                                variant='subtitle2'
                                pb='15px'
                            >
                                PNG or JPG up to 5MB.
                            </Typography>
                        </>
                    )}
                    <CustomButton
                        color='green'
                        variant='contained'
                        component='label'
                    >
                        Upload
                        <input
                            type='file'
                            hidden
                            accept='image/png, image/jpeg'
                            onChange={handleUpload}
                        />
                    </CustomButton>
                    {user &&
                        !user?.profilePicture &&
                        !updatedPhoto &&
                        message && (
                            <Typography
                                fontFamily='Open Sans'
                                variant='subtitle2'
                                color='red'
                                pt='10px'
                            >
                                To complete your profile, please upload a
                                profile picture!
                            </Typography>
                        )}
                </Box>
            </Stack>
            <ProfilePictureModal
                open={uploadModalOpen}
                onClose={onClose}
                onSubmit={handleSubmit}
                photo={photo}
            />
        </>
    );
};

export default EditProfilePicture;

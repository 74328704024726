import {
    Container,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import ReportsComponent from '../../components/Reports/ReportsComponent';
import React, { useEffect } from 'react';

export default function ReportsPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent:'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile || isIpad ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <BarChartIcon
                        sx={{
                            color: '#5cb5b3',
                            paddingRight: '5px',
                            fontSize: '32px',
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        Reports
                    </Typography>
                </Grid>
  
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: isMobile || isIpad ? '65%' : '95%',
                        // justifyContent: 'flex-start',
                        paddingBottom: isMobile||isIpad  ? '40px': '0px'
                    }}
                >
                    <ReportsComponent />
                </Grid>
            </Grid>
        </Container>
    );
}

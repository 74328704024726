import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Grid,
    List,
    ListItem,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useEditCompanyMutation,
    useGetUserListQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomCheckbox } from '../../../../layout/styledcomponents/CustomCheckbox';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import DropdownFilter from '../../../../layout/styledcomponents/filters/Dropdown';
import UserRole from '../../../../shared/AuthRoles';
import { useExitPrompt } from '../../../../shared/LeavingPageWarning';
import toast from '../../../shared/Toast';
import { handleHttpError } from '../../../../shared/utils';

export default function EditCompanyDetails(companyDetails: any) {
    const navigate = useNavigate();
    const [editCompanyProfileDetails] = useEditCompanyMutation();
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
    const loggedInUser = UserRole();
    const [getCompanyDetails, setCompanyDetails] = useState(
        companyDetails.companyDetails
    );

    const [userUrl, setUsersUrl] = useState('');
    const { data: getUsers } = useGetUserListQuery(userUrl);
    const [primaryContact, setPrimaryContact] = useState(
        getCompanyDetails?.primaryContact
    );
    const [emailAddress, setEmailAddress] = useState(
        getCompanyDetails?.account?.email
    );
    const [notificationEmail, setNotificationEmail] = useState(
        getCompanyDetails?.notificationsEmail
    );
    const [allowMultipleDomains, setAllowMultpleDomains] = useState(
        getCompanyDetails?.allowsMultipleDomains
    );
    const [phoneNumber, setPhoneNumber] = useState(getCompanyDetails?.phone);
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [isValidNotificationEmail, setIsValidNotificationEmail] =
        useState(false);
    const [streetNo, setStreetNumber] = useState(
        getCompanyDetails?.streetAddress1
    );
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [street, setStreet] = useState(getCompanyDetails?.streetAddress2);
    const [suburb, setSuburb] = useState(getCompanyDetails?.suburb);
    const [postCode, setPostCode] = useState(getCompanyDetails?.postcode);
    const [state, setState] = useState(getCompanyDetails?.state);
    const [country, setCountry] = useState(getCompanyDetails?.countryCode);
    const [isDuplicateEmail, setDuplicateEmail] = useState(false);

    useEffect(() => {
        setCompanyDetails(companyDetails.companyDetails);
    }, [companyDetails.companyDetails]);

    useEffect(() => {
        return () => {
            setShowExitPrompt(false);
        };
    }, []);

    const formDetailsProfile = {
        // name: 'Corporate Facilities',
        primaryContact: primaryContact,
        // isActive: true,
        phone: phoneNumber,
        countryCode: country,
        state: state,
        suburb: suburb,
        streetAddress1: streetNo,
        streetAddress2: street,
        postcode: postCode,
        notificationsEmail: notificationEmail,
        allowsMultipleDomains: allowMultipleDomains,
    };

    useEffect(() => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_company'
        ) {
            setUsersUrl(`/owners/${loggedInUser?.company}/users`);
        } else if (
            loggedInUser?.role === 'guest_admin' ||
            loggedInUser?.role === 'guest_company'
        ) {
            setUsersUrl(`/guests/${loggedInUser?.company}/users`);
        } else {
            setUsersUrl(`/7-day/users`);
        }
    }, [loggedInUser]);

    const handlePhone = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setPhoneNumber(event.target.value);
        setShowExitPrompt(true);
        if (validatePhone(event.target.value) === false) {
            setIsValidPhone(true);
        } else {
            setIsValidPhone(false);
        }
    };

    //Retrieve users and compare account.email with the new user email
    const mapOverUsers = getUsers?.map(
        (account: { email: any }) => account?.email
    );

    const handleEmail = (event: any) => {
        // setEmailAddress(event.target.value);
        setShowExitPrompt(true);
        if (validatEmail(event?.target?.value) === false) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
        //Find Duplicate User
        const findDuplicate = mapOverUsers?.find(
            (i: any) => i === event?.target?.value
        );
        if (
            findDuplicate !== undefined &&
            findDuplicate !== getUsers?.account?.email
        ) {
            setDuplicateEmail(true);
            setEmailAddress('');
        } else if (findDuplicate === getUsers?.account?.email) {
            setDuplicateEmail(false);
            setEmailAddress(event?.target?.value);
        } else {
            setDuplicateEmail(false);
            setEmailAddress(event?.target?.value);
        }
    };

    const handleNotificationEmail = (event: any) => {
        setShowExitPrompt(true);
        if (validatEmail(event?.target?.value) === false) {
            setIsValidNotificationEmail(true);
        } else {
            setIsValidNotificationEmail(false);
        }

        setNotificationEmail(event?.target?.value);
    };

    const handleMultipleDomains = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setAllowMultpleDomains(event?.target?.checked);
    };

    const validatEmail = (email: any) => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    };

    const validatePhone = (ph: any) => {
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i.test(
            ph
        );
    };

    const handlePrimaryContact = (event: any) => {
        setShowExitPrompt(true);
        setPrimaryContact(
            event.target.value.charAt(0).toUpperCase() +
                event.target.value.slice(1)
        );
    };

    const handleStreetNo = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setShowExitPrompt(true);
        setStreetNumber(event.target.value);
    };

    const handleStreet = (event: { target: { value: string } }) => {
        setShowExitPrompt(true);
        setStreet(
            event?.target?.value.charAt(0)?.toUpperCase() +
                event?.target?.value?.slice(1)
        );
    };

    const handleSuburb = (event: { target: { value: string } }) => {
        setShowExitPrompt(true);
        setSuburb(
            event?.target?.value.charAt(0)?.toUpperCase() +
                event?.target?.value?.slice(1)
        );
    };

    const handlePostCode = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setShowExitPrompt(true);
        setPostCode(event.target.value);
    };
    const handleState = (value: SetStateAction<string>) => {
        setShowExitPrompt(true);
        setState(value);
    };
    const handleCountry = (value: SetStateAction<string>) => {
        setShowExitPrompt(true);
        setCountry(value);
    };

    const errors: any = [];

    if (phoneNumber?.length === 0) {
        errors.push('Phone cannot be empty');
    }
    if (primaryContact?.length === 0) {
        errors.push('Primary Contact cannot be empty');
    }
    if (emailAddress?.length === 0) {
        errors.push('Email cannot be empty');
    }
    if (streetNo?.length === 0) {
        errors.push('Street Number cannot be empty');
    }
    if (street?.length === 0) {
        errors.push('Street cannot be empty');
    }
    if (suburb?.length === 0) {
        errors.push('Suburb cannot be empty');
    }
    if (state?.length === 0) {
        errors.push('State cannot be empty');
    }
    if (postCode?.length === 0) {
        errors.push('Post Code cannot be empty');
    }
    if (country?.length === 0) {
        errors.push('Country cannot be empty');
    }

    const editProfile = () => {
        const requestBody = formDetailsProfile;

        let url =
            loggedInUser?.role === 'owner_company'
                ? `/owners/${getCompanyDetails?.id}`
                : `/guests/${getCompanyDetails?.id}`;
        let requestDetails = [url, requestBody];
        editCompanyProfileDetails(requestDetails).then((data: any) => {
            if (data) {
                if (data?.error) {
                    const errorMessage = handleHttpError(data?.error);
                    toast.error(errorMessage);
                } else {
                    toast.success(`Profile has been edited`);
                    navigate('/profile', { replace: true });
                }
            } else {
                toast.error(`Failed to edit profile`);
            }
        });
    };

    const simplifiedAddress = `${companyDetails.companyDetails?.streetAddress1}, ${companyDetails.companyDetails?.streetAddress2} ${companyDetails.companyDetails?.suburb} ${companyDetails.companyDetails?.postcode} ${companyDetails.companyDetails?.state} ${companyDetails.companyDetails?.countryCode}`;

    return (
        <Grid
            sx={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
            }}
        >
            <Typography
                component='h4'
                // variant='h6'
                color='#787878'
                sx={{
                    fontFamily: 'Open Sans',
                    textAlign: 'left',
                    fontWeight: 900,
                    height: '10%',
                    paddingTop: isMobile ? '30px' : '0px',
                }}
            >
                Company Details
            </Typography>

            <Grid
                sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}
            >
                <Grid container columnSpacing='50px' rowSpacing='10px'>
                    {' '}
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid item xs={12} lg={5}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Company
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <CustomTextField
                                // placeholder='LEAP'
                                fullWidth
                                value={companyDetails.companyDetails?.name}
                                disabled
                                color='neutral'
                                inputProps={{
                                    style: {
                                        height: '10px',
                                        backgroundColor: '#f6f8f9',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        <Grid item xs={12} lg={5}>
                            {' '}
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Phone
                                {phoneNumber === '' ? (
                                    <span
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        *
                                    </span>
                                ) : null}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <CustomTextField
                                disabled={
                                    loggedInUser?.role === 'owner_admin' ||
                                    loggedInUser?.role === 'guest_admin' ||
                                    loggedInUser?.role ===
                                        'guest_department_admin' ||
                                    loggedInUser?.role === 'owner_user' ||
                                    loggedInUser?.role === 'guest_user'
                                }
                                value={phoneNumber}
                                onChange={handlePhone}
                                fullWidth
                                color={isValidPhone ? 'red' : 'green'}
                                inputProps={{
                                    style: {
                                        height: '10px',
                                        backgroundColor:
                                            loggedInUser?.role ===
                                                'owner_company' ||
                                            loggedInUser?.role ===
                                                'guest_company'
                                                ? '#fff'
                                                : '#f6f8f9',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        <Grid item xs={12} lg={5}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Primary Contact
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <CustomTextField
                                // placeholder='LEAP'
                                fullWidth
                                onChange={handlePrimaryContact}
                                value={primaryContact}
                                disabled={
                                    loggedInUser?.role === 'owner_admin' ||
                                    loggedInUser?.role === 'guest_admin' ||
                                    loggedInUser?.role ===
                                        'guest_department_admin' ||
                                    loggedInUser?.role === 'owner_user' ||
                                    loggedInUser?.role === 'guest_user'
                                }
                                color='neutral'
                                inputProps={{
                                    style: {
                                        height: '10px',
                                        backgroundColor:
                                            loggedInUser?.role ===
                                                'owner_company' ||
                                            loggedInUser?.role ===
                                                'guest_company'
                                                ? '#fff'
                                                : '#f6f8f9',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        <Grid item xs={12} lg={5}>
                            {' '}
                            {loggedInUser?.role === 'owner_company' ||
                            loggedInUser?.role === 'guest_company' ? (
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Email
                                </Typography>
                            ) : (
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Address
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            {loggedInUser?.role === 'owner_company' ||
                            loggedInUser?.role === 'guest_company' ? (
                                <CustomTextField
                                    disabled
                                    error={isDuplicateEmail}
                                    label={
                                        isDuplicateEmail === true
                                            ? 'Error: User already exists'
                                            : ''
                                    }
                                    value={
                                        !isDuplicateEmail ? emailAddress : null
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    // onChange={handleEmail}
                                    fullWidth
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            ) : (
                                <CustomTextField
                                    disabled
                                    value={simplifiedAddress}
                                    fullWidth
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {loggedInUser?.role === 'guest_company' ||
                    loggedInUser?.role === 'guest_user' ||
                    loggedInUser?.role === 'guest_admin' ||
                    loggedInUser?.role === 'guest_department_admin' ? null : (
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Notifications Email
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    // placeholder='LEAP'
                                    fullWidth
                                    onChange={handleNotificationEmail}
                                    value={notificationEmail}
                                    disabled={
                                        loggedInUser?.role === 'owner_user' ||
                                        loggedInUser?.role === 'owner_admin'
                                    }
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor:
                                                loggedInUser?.role ===
                                                'owner_company'
                                                    ? '#fff'
                                                    : '#f6f8f9',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                {loggedInUser?.role === 'owner_company' ||
                loggedInUser?.role === 'guest_company' ? (
                    <Grid container columnSpacing='50px' rowSpacing='10px'>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Street No.
                                    {streetNo === '' ? (
                                        <span
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            *
                                        </span>
                                    ) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    type='number'
                                    placeholder='230'
                                    value={streetNo}
                                    onChange={handleStreetNo}
                                    fullWidth
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            width: '400px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Street
                                    {street === '' ? (
                                        <span
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            *
                                        </span>
                                    ) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    placeholder='Oak Road'
                                    value={street}
                                    onChange={handleStreet}
                                    fullWidth
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Suburb
                                    {suburb === '' ? (
                                        <span
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            *
                                        </span>
                                    ) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    placeholder='Matcham'
                                    value={suburb}
                                    onChange={handleSuburb}
                                    fullWidth
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            width: '400px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Post Code
                                    {postCode === '' ? (
                                        <span
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            *
                                        </span>
                                    ) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    type='number'
                                    placeholder='2250'
                                    value={postCode}
                                    onChange={handlePostCode}
                                    fullWidth
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            width: '400px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    State
                                    {state === '' ? (
                                        <span
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            *
                                        </span>
                                    ) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <DropdownFilter
                                    id={'state'}
                                    options={[
                                        { value: 'NSW', label: 'NSW' },
                                        { value: 'QLD', label: 'QLD' },
                                        { value: 'VIC', label: 'VIC' },
                                        { value: 'SA', label: 'SA' },
                                        { value: 'WA', label: 'WA' },
                                        { value: 'NT', label: 'NT' },
                                        { value: 'TAS', label: 'TAS' },
                                        { value: 'ACT', label: 'ACT' },
                                    ]}
                                    handleChange={handleState}
                                    selectedValue={state}
                                    backgroundColor={'white'}
                                    border={'#C3C3C3'}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Country
                                    {country === '' ? (
                                        <span
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            *
                                        </span>
                                    ) : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <DropdownFilter
                                    id={'country'}
                                    options={[
                                        { value: 'AU', label: 'Australia' },
                                    ]}
                                    handleChange={handleCountry}
                                    selectedValue={country}
                                    backgroundColor={'white'}
                                    border={'#C3C3C3'}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Stack
                                    direction='row'
                                    gap='5px'
                                    alignItems='center'
                                >
                                    {' '}
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Allow multiple domains
                                    </Typography>
                                    <Tooltip
                                        title={
                                            <Typography
                                                // color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                <List>
                                                    <ListItem>
                                                        {' '}
                                                        If checked, users will
                                                        be allowed to add email
                                                        addresses that don't
                                                        belong to the
                                                        organsation to access
                                                        7DAY, such as personal
                                                        emails. If unchecked,
                                                        users cannot add an
                                                        email address that is
                                                        outside the{' '}
                                                        {
                                                            getCompanyDetails?.name
                                                        }{' '}
                                                        organisation.
                                                    </ListItem>
                                                </List>
                                            </Typography>
                                        }
                                        placement='right'
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomCheckbox
                                    checked={allowMultipleDomains}
                                    onChange={handleMultipleDomains}
                                    // sx={{ marginLeft: -1 }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                {loggedInUser?.role === 'owner_company' ? (
                    <Grid container columnSpacing='50px' rowSpacing='10px'>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Stack
                                    direction='row'
                                    gap='5px'
                                    alignItems='center'
                                >
                                    {' '}
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Status
                                    </Typography>
                                    <Tooltip
                                        title={
                                            <Typography
                                                // color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                You do not have permission to
                                                change this. Please contact your
                                                administrator.
                                            </Typography>
                                        }
                                        placement='right'
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    disabled
                                    value={
                                        companyDetails.companyDetails
                                            ?.isActive === true
                                            ? 'Enabled'
                                            : 'Disabled'
                                    }
                                    fullWidth
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Stack
                                    direction='row'
                                    gap='5px'
                                    alignItems='center'
                                >
                                    {' '}
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Access
                                    </Typography>{' '}
                                    <Tooltip
                                        title={
                                            <Typography
                                                // color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                You do not have permission to
                                                change this. Please contact your
                                                administrator.
                                            </Typography>
                                        }
                                        placement='right'
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    value={
                                        loggedInUser?.role === 'owner_admin'
                                            ? 'Admin'
                                            : loggedInUser?.role ===
                                              'owner_user'
                                            ? 'User'
                                            : loggedInUser?.role ===
                                              'guest_user'
                                            ? 'User'
                                            : 'Admin'
                                    }
                                    fullWidth
                                    disabled
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
            </Grid>

            {loggedInUser?.role === 'owner_company' ||
            loggedInUser?.role === 'guest_company' ? (
                <Grid
                    sx={{
                        height: isMobile || isIpad ? '90px' : '90px',
                        textAlign: ' right',
                        paddingBottom: isMobile || isIpad ? '10px' : '0px',
                    }}
                >
                    <CustomButton
                        variant='contained'
                        color='green'
                        onClick={editProfile}
                        disabled={
                            // isValidInput ||
                            isValidEmail || isValidPhone || errors.length > 0
                        }
                        sx={{
                            top: isMobile ? '10%' : '50%',
                        }}
                    >
                        Save Changes
                    </CustomButton>
                </Grid>
            ) : null}
        </Grid>
    );
}

import {
    DataGrid,
    GridColDef,
    GridEventListener,
    useGridApiRef,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { CustomChip } from '../../../../../layout/styledcomponents/CustomChip';
import { useDispatch } from 'react-redux';
import { openReportAPIRefSliceAction } from '../../../../../app/Slices/ReportAPIRefSlice';
import UserRole from '../../../../../shared/AuthRoles';
import { useGetCompanyOwnerInfoQuery } from '../../../../../app/Slices/SevenDayApiSlice';
import { Link, useNavigate } from 'react-router-dom';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 900,
        color: '#000',
        borderBottom: '1px solid #787878',
        paddingLeft: '0px',
    },
    [`& .${gridClasses.row}`]: {
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                },
            },
        },
    },
}));

export default function TopModulesListReport(params: any) {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const loggedInUser = UserRole();
    const { data: getCompanyDetails } = useGetCompanyOwnerInfoQuery(
        loggedInUser?.company
    );

    useEffect(() => {
        let csv = apiRef?.current.exportDataAsCsv;
        let print = apiRef?.current.exportDataAsPrint;
        dispatch(
            openReportAPIRefSliceAction({
                exportInfo: {
                    csvExport: csv,
                    printExport: print,
                },
            })
        );
    }, [apiRef, dispatch]);

    const renderLinkCell = (params: any) => (
        <Link
            to={{
                pathname: `/viewExperiences/${params.row.type
                    .replace(/ /g, '')
                    .toLowerCase()}/${params.row.id}`,
            }}
            state={{
                name: 'viewExperiences',
                component: 'reports',
                url: 'reports/topExperiences',
                params: params.row,
            }}
            style={{
                textDecoration: 'none',
                color: 'inherit',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent:
                    params.field === 'guestBookings' ||
                    params.field === 'ownerBookings' ||
                    params.field === 'onBehalfBookings' ||
                    params.field === 'completedBookings'
                        ? 'center'
                        : 'flex-start',
                cursor: 'pointer',
            }}
        >
            {params.value}
        </Link>
    );

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Experience',
            flex: 1,
            minWidth: isIpad ? 200 : 550,
            type: 'string',
            sortable: true,
            renderCell: renderLinkCell,
        },
        {
            field: 'type',
            headerName: 'Experience Type',
            width: isMobile ? 150 : isIpad ? 120 : 150,
            type: 'string',
            sortable: true,
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value:
                        params?.value.charAt(0).toUpperCase() +
                        params?.value.slice(1),
                }),
        },
        {
            field: 'visitType',
            headerName: 'Visit Type',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            type: 'boolean',
            sortable: true,
            renderCell: (params) => (
                <CustomChip
                    sx={{ fontFamily: 'Open Sans' }}
                    label={
                        params?.value.charAt(0).toUpperCase() +
                        params?.value.slice(1)
                    }
                    color={params.value === 'personal' ? 'yellow' : 'green'}
                    variant='outlined'
                />
            ),
        },
        {
            field: 'guestBookings',
            headerName: 'Total Guest Reservations',
            description: 'No. of successfully completed guest reservations',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            align: 'center',
            type: 'number',
            sortable: true,
            renderCell: renderLinkCell,
        },
        {
            field: 'ownerBookings',
            headerName: 'Total Blocked Reservations',
            description:
                'No. of blocked reservations (maintenance, staff away, shutdown period, etc',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            align: 'center',
            type: 'number',
            sortable: true,
            renderCell: renderLinkCell,
        },
        {
            field: 'onBehalfBookings',
            headerName: `Total ${
                getCompanyDetails?.name ? getCompanyDetails?.name : 'On Behalf'
            } Reservations`,
            description:
                'No. of successfully completed owner reservations reserved on behalf of you',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            align: 'center',
            type: 'number',
            sortable: true,
            renderCell: renderLinkCell,
        },
        {
            field: 'completedBookings',
            headerName: 'Total Completed Reservations',
            description:
                'No. of successfully completed reservations during date range',
            width: isMobile ? 180 : isIpad ? 180 : 180,
            align: 'center',
            type: 'number',
            sortable: true,
            renderCell: renderLinkCell,
        },
    ];

    const navigate = useNavigate();
    // const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    //     navigate(
    //         `/viewExperiences/${params?.row?.type
    //             .replace(/ /g, '')
    //             .toLowerCase()}/${params?.row?.id}`,
    //         {
    //             state: {
    //                 name: 'viewExperiences',
    //                 component: 'reports',
    //                 url: `reports/topExperiences`,
    //             },
    //         }
    //     );
    // };

    return (
        <Box sx={{ height: 800, width: '100%', paddingTop: '50px' }}>
            <StripedDataGrid
                apiRef={apiRef}
                // onRowClick={handleRowClick}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                sx={{
                    boxShadow: 0,
                    border: 0,
                    '& .MuiDataGrid-cell': {
                        padding: '0',
                        borderRight: 'none', // Remove right border between cells
                    },
                    '& .MuiDataGrid-row': {
                        margin: 0, // Remove margin between rows
                    },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={params?.data !== undefined ? params?.data : []}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Experiences
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                No Experiences have been
                                                completed
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Results
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please try again
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
}

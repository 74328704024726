import { ChannelRepository, MessageRepository } from '@amityco/ts-sdk';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../layout/styledcomponents/CustomTextField';
import UserRole from '../../../shared/AuthRoles';
import { camelCaseToTitleCase, capitalizeName } from '../../../shared/utils';
import toast from '../../shared/Toast';
import { ExperiencePolicy } from '../../types/policy';
import AmenitiesComponent from './Amenities/AmenitiesComponent';
import CalendarComponent from './Calendar/CalendarComponent';
import IndividualExperienceCharges from './IndividualExperienceCharges';
import IndividualExperienceSpaces from './IndividualExperienceSpaces';
import ExperiencePolicyCard from './Policies/ExperiencePolicyCard';

export default function IndividualExperienceContent(experience: any) {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const dispatch = useDispatch();

    const loggedInUser = UserRole();

    const value = experience?.experience;
    let days = [
        { day: 'monday', value: value?.monday },
        { day: 'tuesday', value: value?.tuesday },
        { day: 'wednesday', value: value?.wednesday },
        { day: 'thursday', value: value?.thursday },
        { day: 'friday', value: value?.friday },
        { day: 'saturday', value: value?.saturday },
        { day: 'sunday', value: value?.sunday },
    ];

    const capacity: any = {
        dedicatedDesks: value?.details?.desks,
        privateOffices: value?.details?.privateOffices,
        meetingRooms: value?.details?.meetingRooms,
    };

    let availableDays = days.filter?.((i: any) => i.value === true);

    const onClick = () => {
        navigate(`/reservations/ai-assistant`, {
            state: { page: 'experience', id: value?.id, name: value?.name },
        });
        ReactGA.send({
            hitType: 'pageview',
            page: `/reservations/ai-assistant`,
            title: `Ai Reservation Assistant`,
        });
    };
    const fullAddressPickup = `${value?.streetAddress1} ${value?.streetAddress2} ${value?.suburb} ${value?.postcode} ${value?.state} ${value?.countryCode}`;
    const fullAddressDropoff = `${value?.details?.dropoffStreetAddress1} ${value?.details?.dropoffStreetAddress2} ${value?.details?.dropoffSuburb} ${value?.details?.dropoffPostcode} ${value?.details?.dropoffState} ${value?.details?.dropoffCountryCode}`;

    const [message, setMessage] = useState<string>('');
    const [messageSending, setMessageSending] = useState<boolean>(false);

    const handleMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    };

    const sendMessage = async () => {
        try {
            if (!message) return;

            setMessageSending(true);

            const newChannel = {
                displayName: `Enquiry: ${value?.name}`, // experience name
                type: 'community' as Amity.ChannelType,
                userIds: [
                    loggedInUser?.sub.toString(),
                    ...value?.owner?.messagingUsers.map((user: number) =>
                        user.toString()
                    ),
                ],
                isPublic: false,
                metadata: {
                    experience: {
                        id: value?.id,
                        photo: value?.photos[0]?.src,
                    },
                    guest: loggedInUser?.sub.toString(),
                },
            };

            const { data: channel } = await ChannelRepository.createChannel(
                newChannel
            );

            // promote all owner users to 'channel-moderator'
            const success = await ChannelRepository.Moderation.addRole(
                channel.channelId,
                'channel-moderator',
                value?.owner?.messagingUsers.map((user: number) =>
                    user.toString()
                )
            );

            const newMessage = {
                subChannelId: channel.channelId,
                data: { text: message },
                dataType: 'text' as Amity.MessageContentType,
            };

            await MessageRepository.createMessage(newMessage);

            navigate('/messages', { state: { channelId: channel.channelId } });
        } catch (error) {
            toast.error('Error sending message to host.');
        } finally {
            setMessageSending(false);
        }
    };

    const rules = [];

    if (value?.details?.minimumLicenceRequired) {
        rules.push(
            `Minimum licence required: ${capitalizeName(
                value.details.minimumLicenceRequired
            )}`
        );
    }
    if (value?.details?.minimumDriversAge) {
        rules.push(`Minimum driver's age: ${value.details.minimumDriversAge}`);
    }

    if (value?.details?.petsAllowed === false) {
        rules.push('Pets not allowed');
    }

    if (value?.details?.smokingAllowed === false) {
        rules.push('Smoking, vaping, e-cigarettes not allowed');
    }

    if (value?.details?.rules) {
        rules.push(value.details.rules);
    }

    return (
        <Grid>
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    paddingTop: '30px',
                    width: '100%',

                    // width: isIpad ? '80%' : isMobile ? null : '%',
                }}
            >
                <Grid
                    sx={{
                        width: isMobile ? '100%' : isIpad ? '60%' : '70%',
                        // backgroundColor: 'pink',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Grid
                        sx={{
                            height: isMobile || isIpad ? 'auto' : '50px',
                            width: isMobile || isIpad ? '100%' : '88%',

                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Grid sx={{ width: '90%' }}>
                            <Typography
                                variant={isMobile ? 'h6' : 'h5'}
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                {' '}
                                {/* {value?.name} hosted by ASSET OWNER DETAILS */}
                                {value?.type === 'property'
                                    ? `Entire ${value.details.subType} hosted by ${value?.owner.name}`
                                    : value?.type === 'boat'
                                    ? `Entire boat hosted by  ${value?.owner.name}`
                                    : value?.type === 'commercial' &&
                                      value?.details?.subType === 'office'
                                    ? `Office space provided by ${value?.owner.name}`
                                    : value?.type === 'commercial' &&
                                      value?.details?.subType === 'house'
                                    ? `Home office provided by ${value?.owner.name}`
                                    : `${capitalizeName(
                                          value?.details?.subType
                                      )} supplied by  ${value?.owner.name}`}
                            </Typography>
                        </Grid>
                        {/* <Grid sx={{ width: '10%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Avatar alt="logo" src="/bhaglogo.jpg"    sx={{ width: 56, height: 56,boxShadow: 1   }}/>
                        </Grid> */}
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '88%',
                            // backgroundColor: 'pink',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {value?.type === 'property' ||
                        value?.type === 'boat' ? (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                }}
                            >
                                {!(
                                    value?.details?.subType === 'commercial' ||
                                    value.details.subType === 'office space'
                                ) && (
                                    <>
                                        <Grid>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    paddingRight: '5px',
                                                    //   marginLeft: "5px",
                                                }}
                                            >
                                                Sleeps:{' '}
                                                {value?.details.overnightGuests}
                                                <span
                                                    style={{
                                                        paddingLeft: '5px',
                                                    }}
                                                >
                                                    <FiberManualRecordIcon
                                                        sx={{ fontSize: '4px' }}
                                                    />
                                                </span>
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    paddingRight: '5px',
                                                }}
                                            >
                                                Bedrooms:{' '}
                                                {value?.details.bedrooms}{' '}
                                                <span
                                                    style={{
                                                        paddingLeft: '5px',
                                                    }}
                                                >
                                                    <FiberManualRecordIcon
                                                        sx={{ fontSize: '4px' }}
                                                    />
                                                </span>
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    paddingRight: '5px',
                                                }}
                                            >
                                                Beds: {value?.details.beds}{' '}
                                                <span
                                                    style={{
                                                        paddingLeft: '5px',
                                                    }}
                                                >
                                                    <FiberManualRecordIcon
                                                        sx={{ fontSize: '4px' }}
                                                    />
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                                <Grid>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            paddingRight: '5px',
                                        }}
                                    >
                                        Bathrooms: {value?.details.baths}
                                        <span style={{ paddingLeft: '5px' }}>
                                            <FiberManualRecordIcon
                                                sx={{ fontSize: '4px' }}
                                            />
                                        </span>
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            paddingRight: '5px',
                                        }}
                                    >
                                        Max {value?.details.maxGuests} guests on
                                        the {value?.type}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : value?.type === 'vehicle' ? (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                }}
                            >
                                {
                                    <>
                                        <Grid>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    paddingRight: '5px',
                                                    //   marginLeft: "5px",
                                                }}
                                            >
                                                {value?.details.make}{' '}
                                                {value?.details.model}{' '}
                                                {value.details.subType ===
                                                    'car' &&
                                                    capitalizeName(
                                                        value?.details?.bodyType
                                                    )}{' '}
                                                {value?.details?.year &&
                                                    value?.details?.year}{' '}
                                                <span
                                                    style={{
                                                        paddingLeft: '5px',
                                                    }}
                                                >
                                                    <FiberManualRecordIcon
                                                        sx={{
                                                            fontSize: '4px',
                                                        }}
                                                    />
                                                </span>
                                            </Typography>
                                        </Grid>

                                        <Grid>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    paddingRight: '5px',
                                                }}
                                            >
                                                Transmission:{' '}
                                                {capitalizeName(
                                                    value?.details
                                                        .transmission === 'cvt'
                                                        ? 'CVT'
                                                        : value?.details
                                                              .transmission
                                                )}{' '}
                                                <span
                                                    style={{
                                                        paddingLeft: '5px',
                                                    }}
                                                >
                                                    <FiberManualRecordIcon
                                                        sx={{ fontSize: '4px' }}
                                                    />
                                                </span>
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    paddingRight: '5px',
                                                }}
                                            >
                                                Fuel Type:{' '}
                                                {capitalizeName(
                                                    value?.details.fuelType
                                                )}{' '}
                                                <span
                                                    style={{
                                                        paddingLeft: '5px',
                                                    }}
                                                >
                                                    <FiberManualRecordIcon
                                                        sx={{
                                                            fontSize: '4px',
                                                        }}
                                                    />
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                                {value.details.subType === 'motorbike' && (
                                    <Grid>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                paddingRight: '5px',
                                            }}
                                        >
                                            Engine CC:{' '}
                                            {value?.details?.engineCC}
                                        </Typography>
                                    </Grid>
                                )}
                                {value.details.subType === 'car' && (
                                    <Grid>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                paddingRight: '5px',
                                            }}
                                        >
                                            Seats:{' '}
                                            {value?.details.numberOfSeats}
                                            <span
                                                style={{ paddingLeft: '5px' }}
                                            >
                                                <FiberManualRecordIcon
                                                    sx={{ fontSize: '4px' }}
                                                />
                                            </span>
                                        </Typography>
                                    </Grid>
                                )}
                                {value.details.subType === 'car' && (
                                    <Grid>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                paddingRight: '5px',
                                            }}
                                        >
                                            Number of Doors:{' '}
                                            {value?.details.numberOfDoors}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        ) : value?.type === 'commercial' &&
                          ['office', 'house'].includes(
                              value.details.subType
                          ) ? (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: isIpad ? 'column' : 'row',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                    paddingTop: isIpad ? '10px' : '0px',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        paddingRight: '5px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    Total Capacity
                                </Typography>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        flexDirection: isIpad
                                            ? 'column'
                                            : 'row',
                                        flexWrap: 'wrap',
                                        gap: isIpad ? '0px' : '30px',
                                    }}
                                >
                                    {capacity ? (
                                        Object.keys(capacity)
                                            .filter(
                                                (key: any) => capacity[key] > 0
                                            )
                                            .map((key: any, index) => (
                                                <Typography
                                                    key={key}
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        marginBottom: '8px',

                                                        fontSize: isMobile
                                                            ? '14px'
                                                            : '14px',
                                                    }}
                                                >
                                                    {`${camelCaseToTitleCase(
                                                        key ===
                                                            'privateOffices' &&
                                                            value?.details
                                                                ?.subType ===
                                                                'house'
                                                            ? 'Rooms'
                                                            : key
                                                    )}: ${capacity[key]}`}
                                                    {index !==
                                                        Object.keys(
                                                            capacity
                                                        ).filter(
                                                            (key: any) =>
                                                                capacity[key] >
                                                                0
                                                        ).length -
                                                            1 &&
                                                        !isIpad && (
                                                            <span
                                                                style={{
                                                                    paddingLeft:
                                                                        '20px',
                                                                }}
                                                            >
                                                                <FiberManualRecordIcon
                                                                    sx={{
                                                                        fontSize:
                                                                            '4px',
                                                                    }}
                                                                />
                                                            </span>
                                                        )}
                                                </Typography>
                                            ))
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            Please select the dates
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                {value?.details.quantity} x {value?.name}
                            </Typography>
                        )}
                    </Grid>
                    <Divider
                        sx={{ width: isMobile || isIpad ? '100%' : '88%' }}
                    />
                    <Grid
                        sx={{
                            height: 'auto',
                            width: isMobile || isIpad ? '100%' : '88%',
                            paddingTop: '30px',
                            // backgroundColor: 'gray',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '10px',
                                fontWeight: 'bold',
                                fontSize: '14px',
                            }}
                        >
                            {value?.type === 'property'
                                ? 'Property'
                                : value?.type === 'boat'
                                ? 'Boat'
                                : value?.type === 'vehicle'
                                ? 'Vehicle'
                                : value?.type === 'commercial'
                                ? capitalizeName(value?.details?.subType)
                                : 'Activity'}{' '}
                            Description
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            {value?.description}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                fontWeight: 'bold',
                                fontSize: '14px',
                            }}
                        >
                            Reservation Information
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <li>
                                {' '}
                                This experience accepts {value?.visitType}{' '}
                                reservations only for{' '}
                                {availableDays.map((name: any, i) => (
                                    <span>
                                        {i > 0 && ','}{' '}
                                        {name.day.charAt(0).toUpperCase() +
                                            name.day.slice(1)}
                                    </span>
                                ))}
                            </li>

                            <li>
                                This experience{' '}
                                {value?.publicHolidays === true
                                    ? 'accepts'
                                    : "doesn't accept"}{' '}
                                reservations for public holidays
                            </li>

                            <li>
                                {value?.type === 'commercial'
                                    ? `Access from ${value?.checkinTime} to ${value?.checkoutTime}`
                                    : `Checkin from ${value?.checkinTime} and checkout before ${value?.checkoutTime}`}
                            </li>
                            <li>
                                The min/max hire period is{' '}
                                {value?.type === 'commercial'
                                    ? `${value?.minHireHours / 24}`
                                    : value?.minHireHours === 24
                                    ? `${value?.minHireHours / 24} day`
                                    : value?.minHireHours === 48
                                    ? `${(value?.minHireHours - 24) / 24}`
                                    : value?.minHireHours > 24
                                    ? `${
                                          (value?.minHireHours - 24) / 24
                                      } nights`
                                    : `${value?.minHireHours} ${
                                          value?.minHireHours === 1
                                              ? 'hr'
                                              : 'hrs'
                                      }`}
                                -
                                {value?.type === 'commercial'
                                    ? `${value?.maxHireHours / 24} ${
                                          value?.maxHireHours === 24
                                              ? 'day'
                                              : 'days'
                                      }`
                                    : value?.maxHireHours === 24
                                    ? `${value?.maxHireHours / 24} day`
                                    : value?.maxHireHours === 48
                                    ? `${(value?.maxHireHours - 24) / 24} night`
                                    : value?.maxHireHours > 24
                                    ? `${
                                          (value?.maxHireHours - 24) / 24
                                      } nights`
                                    : `${value?.maxHireHours} ${
                                          value?.maxHireHours === 1
                                              ? 'hr'
                                              : 'hrs'
                                      }`}
                            </li>
                        </Typography>
                        {value?.type === 'vehicle' && rules?.length > 0 && (
                            <>
                                {' '}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        paddingBottom: '10px',
                                        paddingTop: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    Hiring Rules
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        paddingBottom: '10px',
                                        fontSize: '14px',

                                        listStyle:
                                            rules.length === 1
                                                ? 'none'
                                                : 'default',
                                    }}
                                >
                                    {rules.map((rule: any, index: any) => (
                                        <li key={index}>{rule}</li>
                                    ))}
                                </Typography>
                            </>
                        )}
                    </Grid>{' '}
                    {value?.type === 'vehicle' && (
                        <Grid
                            sx={{
                                height: 'auto',
                                paddingTop: isIpad ? '10px' : '30px',
                                width: isMobile || isIpad ? '100%' : '88%',
                                // backgroundColor: 'blue',
                            }}
                        >
                            <Divider
                                sx={{
                                    width: '100%',
                                }}
                            />
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    paddingTop: '30px',
                                    paddingBottom: '10px',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }}
                            >
                                Pick Up{' '}
                                {value.details.dropoffLocationSameAsPickup &&
                                    'and Drop Off '}
                                Location
                            </Typography>{' '}
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    fontSize: '14px',
                                }}
                            >
                                {' '}
                                {fullAddressPickup}
                            </Typography>
                            {!value.details.dropoffLocationSameAsPickup && (
                                <>
                                    {' '}
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            paddingTop: '30px',
                                            paddingBottom: '10px',
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                        }}
                                    >
                                        Drop Off Location
                                    </Typography>{' '}
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {' '}
                                        {fullAddressDropoff}
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    )}
                    {value?.charges?.length > 0 && (
                        <IndividualExperienceCharges
                            charges={value?.charges}
                            experienceType={value?.type}
                            experienceSubType={value?.details?.subType}
                            discounts={value?.discounts}
                        />
                    )}
                    {value?.spaces?.length > 0 && (
                        <IndividualExperienceSpaces spaces={value?.spaces} />
                    )}
                    {value?.type === 'activity' ? null : (
                        <Divider
                            sx={{
                                marginTop: '30px',
                                width: isMobile || isIpad ? '100%' : '88%',
                            }}
                        />
                    )}
                    <Grid
                        sx={{
                            height: 'auto',
                            paddingTop: isIpad ? '10px' : '30px',
                            width: isMobile || isIpad ? '100%' : '88%',
                            // backgroundColor: 'blue',
                        }}
                    >
                        {value?.type === 'property' ||
                        value?.type === 'boat' ||
                        value?.type === 'vehicle' ? (
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }}
                            >
                                What this{' '}
                                {value?.type === 'property'
                                    ? 'place'
                                    : value?.type === 'vehicle' &&
                                      value?.details.subType === 'car'
                                    ? 'car'
                                    : value?.type === 'vehicle' &&
                                      value?.details.subType === 'motorbike'
                                    ? 'motorbike'
                                    : 'boat'}{' '}
                                offers
                            </Typography>
                        ) : null}
                    </Grid>
                    {value?.type === 'property' ||
                    value?.type === 'boat' ||
                    value?.type === 'vehicle' ||
                    value?.type === 'commercial' ? (
                        <Grid
                            sx={{
                                height: 'auto',
                                paddingTop: '30px',
                                // backgroundColor: 'green',
                                width: isIpad || isMobile ? '100%' : '88%',
                            }}
                        >
                            <AmenitiesComponent amenities={value?.details} />
                        </Grid>
                    ) : value?.type === 'activity' ? null : null}
                    {value?.policies.length > 0 && (
                        <>
                            <Divider
                                sx={{
                                    marginTop: '30px',
                                    width: isMobile || isIpad ? '100%' : '88%',
                                }}
                            />
                            <Box
                                pt='30px'
                                width={isIpad || isMobile ? '100%' : '88%'}
                            >
                                <Typography
                                    fontFamily='Open Sans'
                                    color='#787878'
                                    fontWeight='bold'
                                    fontSize='14px'
                                    pb='30px'
                                >
                                    Reservations Policies
                                </Typography>
                                <Grid container spacing={2}>
                                    {value?.policies?.map(
                                        (policy: ExperiencePolicy) => (
                                            <Grid
                                                key={policy.policyId}
                                                item
                                                sm={12}
                                                lg
                                            >
                                                <ExperiencePolicyCard
                                                    policy={policy}
                                                />
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </Box>
                        </>
                    )}
                    <Divider
                        sx={{
                            marginTop: '30px',
                            width: isMobile || isIpad ? '100%' : '88%',
                        }}
                    />
                    {/* 
                        In-app messaging
                    */}
                    {loggedInUser?.role?.includes('guest') && (
                        <Box
                            pt='30px'
                            width={isIpad || isMobile ? '100%' : '88%'}
                        >
                            <Typography variant='h6' color='#787878'>
                                Contact the Host
                            </Typography>

                            {value?.owner?.messagingUsers?.length > 0 ? (
                                <>
                                    <Typography
                                        variant='body2'
                                        color='#787878'
                                        gutterBottom
                                    >
                                        Got a question about this experience?
                                        Send a message to the host for more
                                        details. Whether it's about
                                        availability, specific details, or
                                        special requests, the host is here to
                                        help!
                                    </Typography>
                                    <CustomTextField
                                        color='green'
                                        rows={2}
                                        value={message}
                                        onChange={handleMessage}
                                        multiline
                                        fullWidth
                                    />
                                    <Box
                                        display='flex'
                                        justifyContent='flex-end'
                                        pt={1}
                                    >
                                        <CustomButton
                                            loading={messageSending}
                                            disabled={
                                                !message || messageSending
                                            }
                                            variant='contained'
                                            color='green'
                                            onClick={sendMessage}
                                        >
                                            Send message
                                        </CustomButton>
                                    </Box>
                                </>
                            ) : (
                                <Typography
                                    variant='body2'
                                    color='#787878'
                                    gutterBottom
                                >
                                    This host does not allow for in-app
                                    messaging about this experience.
                                </Typography>
                            )}
                        </Box>
                    )}
                </Grid>

                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: isMobile ? 'auto' : isIpad ? 'auto' : '30%',
                        // backgroundColor: 'yellow',
                        paddingLeft: isMobile ? '0px' : isIpad ? '15px' : '0px',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                        }}
                    >
                        <CalendarComponent experience={value} />
                    </Grid>

                    <Grid
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            marginTop: '20px',
                        }}
                        px='25px'
                    >
                        {' '}
                        {loggedInUser?.role.includes('owner') &&
                        !loggedInUser?.viewOnly &&
                        value?.isAvailable &&
                        experience?.experience?.owner.id ===
                            loggedInUser?.company ? (
                            <CustomButton
                                fullWidth
                                id='recurringReservation'
                                variant='outlined'
                                color='green'
                                onClick={() => onClick()}
                                startIcon={<AddCircleIcon />}
                                sx={{
                                    top: isMobile ? '50%' : '50%',
                                    mb: '10px',
                                }}
                            >
                                AI Reservation Assistant
                            </CustomButton>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

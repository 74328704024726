import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function DeleteBookingCap() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            {loggedInUser?.role.includes('admin') ? (
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To delete a booking cap:
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top right corner of the app, click on
                                the cog wheel icon
                            </li>
                            <li>Select 'Settings'</li>
                            <li>Select 'Booking Caps'</li>
                            <li>
                                Click on the 'pencil' icon located next to the
                                name of the Bookig Cap
                            </li>
                            <li>Click 'Delete'</li>
                            <li>
                                You will be required to enter a reason for the
                                deletion
                            </li>
                        </ol>
                        Once deleted, this action cannot be undone.
                    </Typography>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Only admins can delete a booking cap. Please contact
                            a person that is an admin in your organisation to
                            add, edit or delete a booking cap.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

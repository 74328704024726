import { CreditCard } from '@mui/icons-material';
import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import BillingReport from '../../components/Billing/Reports/BillingReport';

const GuestInvoicesReportPage = () => {
    return (
        <Container fixed maxWidth='xl' sx={{ pt: '125px' }}>
            <Box width='100%'>
                <Stack direction='row' alignItems='center' gap={1} pb='30px'>
                    <CreditCard color='primary' />
                    <Typography variant='h5' color='#787878'>
                        BILLING REPORTS
                    </Typography>
                </Stack>
                <BillingReport />
            </Box>
        </Container>
    );
};

export default GuestInvoicesReportPage;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface IState {
    value: IType;
}

interface IType {
    view?: any;
}

const initialState: IState = {
    value: {
        view: false,
    },
};
export const slice = createSlice({
    name: 'calendarView',
    initialState: { ...initialState },
    reducers: {
        openCalendarViewReservationAction: (state, action: PayloadAction<IType>) => {
            state.value.view =
                action.payload && action.payload.view;
        },
    },
});
export const { openCalendarViewReservationAction } = slice.actions;
export const selectCalendarViewReservationData = (state: RootState) =>
    state.calendarView.value
export const FilterCalendarReducer = slice.reducer;

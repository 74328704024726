import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ChatRoom from '../../components/Messages/ChatRoom';
import ChannelList from '../../components/Messages/ChannelList';
import { useLocation } from 'react-router-dom';
import { client } from '../../../shared/Amity';
import { Client } from '@amityco/ts-sdk';

const Messages = () => {
    const location = useLocation();

    const [selectedChannel, setSelectedChannel] = useState<string | null>(location.state?.channelId || null);
    const [markersSynced, setMarkersSynced] = useState<boolean>(false);

    const [open, setOpen] = useState<boolean>(true);
    
    const handleSelectedChannel = (channelId: string) => {
        setOpen(false);
        setSelectedChannel(channelId);
    }
    
    const handleBack = () => {
        setSelectedChannel(null);
        setOpen(true);
    }

    return (
        <>
            {client.sessionState === 'established' && (
                <Box width='100%'>
                    <ChannelList 
                        open={open}
                        onClick={handleSelectedChannel}
                    />
                    {selectedChannel && (
                        <ChatRoom 
                            channelId={selectedChannel}
                            handleBack={handleBack}
                        />
                    )}
                </Box>
            )}
        </>
    )
}

export default Messages;

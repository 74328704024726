import {
    ChannelRepository,
    Client,
    FileRepository,
    MessageRepository,
    subscribeTopic,
} from '@amityco/ts-sdk';
import { Attachment, Image, Send } from '@mui/icons-material';
import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import FilePreview from './FilePreview';
import { setAmityNewMessage } from '../../../../app/Slices/AmityNewMessageSlice';
import { useDispatch } from 'react-redux';

interface MessageComposerProps {
    channelId: string;
    muted: boolean;
}

const MessageComposer = ({ channelId, muted }: MessageComposerProps) => {
    const [message, setMessage] = useState<string>('');
    const [file, setFile] = useState<Amity.File>();
    const dispatch = useDispatch();

    // const subscribeChannels = (channels: Amity.Channel[]) => {
    //     channels.forEach(c => {
    //         if (!subscribedChannels.includes(c.channelId)) {
    //             subscribedChannels.push(c.channelId);
    //             disposers.push(subscribeTopic(getChannelTopic(c)));
    //         }
    //     });
    // };

    const handleMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    };

    const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let data = new FormData();
            data.append('files', e.target.files[0]);

            const response = await FileRepository.uploadImage(data);
            setFile(response.data[0]);
        }

        e.target.value = '';
    };

    const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let data = new FormData();
            data.append('files', e.target.files[0]);

            const response = await FileRepository.uploadFile(data);
            setFile(response.data[0]);
        }

        e.target.value = '';
    };

    const handleDelete = async () => {
        if (file) {
            const success = await FileRepository.deleteFile(file?.fileId);

            if (success) setFile(undefined);
        }
    };

    const handleSend = async () => {
        if (message) {
            await MessageRepository.createMessage({
                subChannelId: channelId,
                data: { text: message },
                dataType: 'text',
            });

            setMessage('');
        }

        if (file && file.type === 'image') {
            await MessageRepository.createMessage({
                subChannelId: channelId,
                fileId: file?.fileId,
                dataType: 'image',
            });

            setFile(undefined);
        }

        if (file && file.type === 'file') {
            await MessageRepository.createMessage({
                subChannelId: channelId,
                fileId: file?.fileId,
                dataType: 'file',
            });

            setFile(undefined);
        }
        //pass newMessage True to top level to call in messageList
        dispatch(
            setAmityNewMessage({
                newMessage: true,
            })
        );
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent default form behavior
            handleSend(); // Trigger the submit action
        }
    };

    return (
        <>
            {file && <FilePreview file={file} handleDelete={handleDelete} />}
            <Box display='flex' gap={1} p={3}>
                <Button
                    disabled={muted}
                    component='label'
                    variant='outlined'
                    sx={{ minWidth: 0 }}
                >
                    <Attachment />
                    <input
                        type='file'
                        hidden
                        accept='.pdf,.doc,.docx'
                        onChange={handleFile}
                    />
                </Button>
                <Button
                    disabled={muted}
                    component='label'
                    variant='outlined'
                    sx={{ minWidth: 0 }}
                >
                    <Image />
                    <input
                        type='file'
                        hidden
                        accept='image/png, image/jpeg'
                        onChange={handleImage}
                    />
                </Button>
                <TextField
                    disabled={muted}
                    fullWidth
                    size='small'
                    placeholder={
                        muted
                            ? 'You are not permitted to send messages in this channel'
                            : 'Send message'
                    }
                    value={message}
                    onChange={handleMessage}
                    // type='text'
                    onKeyDown={handleKeyDown}
                />
                <Button
                    variant='contained'
                    onClick={handleSend}
                    disabled={!message && !file}
                    disableElevation
                    sx={{ minWidth: 0 }}
                >
                    <Send />
                </Button>
            </Box>
        </>
    );
};

export default MessageComposer;

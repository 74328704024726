import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function AddingAUser() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <>
            {loggedInUser?.role.includes('admin') ? (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            {loggedInUser?.role.includes('guest') ? (
                                <>
                                    Not just you? You can add and manage your
                                    staffs access to the site with three
                                    different levels of access.
                                    <ul>
                                        <>
                                            <li>
                                                COMPANY ADMIN – Has access to
                                                add, edit staff. View and edit
                                                staff reservations as well as making
                                                reservations.
                                            </li>

                                            <li>
                                                TEAM ADMIN – Has access to
                                                add staff. View and edit staff
                                                reservations as well as making
                                                reservations.
                                            </li>
                                            <li>
                                                USER – Has access to add, edit
                                                and view reservations for themselves
                                                & view experiences
                                            </li>
                                            <li>
                                                VIEW ONLY USER – Has access to
                                                view experiences and view
                                                reservations made for them
                                            </li>
                                        </>
                                    </ul>{' '}
                                </>
                            ) : (
                                <>
                                    Not just you? You can add and manage your
                                    staffs access to the site with two different
                                    levels of access.
                                    <ul>
                                        <>
                                            <li>
                                                ADMIN – Has access to
                                                view/add/edit experiences. Add
                                                and Manage users and approve,
                                                edit and decline reservations from
                                                guests.
                                            </li>

                                            <li>
                                                USER – Has access to view
                                                reservations for guests & view
                                                experiences
                                            </li>
                                        </>
                                    </ul>{' '}
                                </>
                            )}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To create a new user:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>
                                    From the top right corner of the app, click
                                    on the cog wheel icon
                                </li>
                                <li>Select 'Users'</li>
                                <li>Select 'New User'</li>
                                <li>Complete all mandatory fields with a *</li>
                                <li>Click 'Save New User'</li>
                                <li>
                                    Once the user has been saved, a 'Send
                                    Invitation' button will appear next to the
                                    users email. Click on this to send the
                                    invite to the user. The user will need to
                                    click on the link in order to set a
                                    password. Once the password has been set by
                                    the user, they will be able to log into the
                                    7DAY application.
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            You currently do not have admin access. In order to
                            add a new user, please request admin access from
                            your human resources team/company
                            administrator or alternatively ask someone who has
                            admin access.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

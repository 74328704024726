import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { CustomButton } from '../../layout/styledcomponents/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import { CustomIconButton } from '../../layout/styledcomponents/CustomIconButton';
import DropdownFilter from '../../layout/styledcomponents/filters/Dropdown';
import React from 'react';
import MultiSelectDropdownFilter from '../../layout/styledcomponents/filters/MultiSelectDropdown';
import { CustomBadge } from '../../layout/styledcomponents/CustomBadge';
import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectExperienceFilters,
    setExperienceFilters,
} from '../../app/Slices/ExperienceFiltersSlice';
import ReactGA from 'react-ga4';

export default function FiltersModal({ management }: any) {
    const filters = useSelector(selectExperienceFilters);
    const isMobile = useMediaQuery('(max-width: 900px)');
    const [openModal, setOpen] = useState(false);
    const dispatch = useDispatch();

    const [type, setType] = useState(filters.type);
    const [state, setState] = useState(filters.state);
    const [amenities, setAmenities] = useState(filters.amenities);
    const [visitType, setVisitType] = useState(filters.visitType);
    const [guests, setGuests] = useState(filters.guests);
    const [overnightGuests, setOvernightGuests] = useState(
        filters.overnightGuests
    );
    const [bedrooms, setBedrooms] = useState(filters.bedrooms);

    ReactGA.event({
        category: 'UI',
        action: `Experience Filters`,
        label: 'Experience Filters Clicked',
    });
    const filterCount =
        (filters.type.length ? 1 : 0) +
        (filters.state.length ? 1 : 0) +
        (filters.guests ? 1 : 0) +
        (filters.overnightGuests ? 1 : 0) +
        (filters.bedrooms ? 1 : 0) +
        (filters.visitType ? 1 : 0) +
        (filters.amenities.length ? 1 : 0);

    const handleClose = () => {
        dispatch(
            setExperienceFilters({
                ...filters,
                type,
                state,
                guests,
                overnightGuests,
                bedrooms,
                visitType,
                amenities,
            })
        );
        setOpen(false);
    };

    const onClickClearFilters = () => {
        setType([]);
        setState([]);
        setGuests('');
        setOvernightGuests('');
        setBedrooms('');
        setVisitType('');
        setAmenities([]);
    };

    return (
        <>
            <CustomBadge
                color='green'
                badgeContent={filterCount}
                invisible={filterCount === 0}
            >
                <CustomIconButton
                    variant='none'
                    color='gray'
                    onClick={() => setOpen(true)}
                    // sx={{ padding: isMobile ? '5.5px' : '7px' }}
                >
                    <TuneIcon fontSize='small' />
                </CustomIconButton>
            </CustomBadge>
            <Dialog open={openModal} onClose={handleClose}>
                <Grid
                    sx={{
                        // background: ' pink',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            // background: 'yellow',
                            // textAlign: 'center',
                            height: '50px',
                            width: isMobile ? '320px' : '600px',

                            // alignContent: 'center',
                            // alignItems: 'center',
                            borderBottom: ' #f6f8f9 solid 1px',
                        }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                // background: 'red',
                                height: '100%',
                                width: isMobile ? '88%' : '90%',
                                // paddingLeft: '18px',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    textAlign: 'center',
                                    paddingLeft: '18px',
                                }}
                                variant='h6'
                            >
                                Filters
                            </Typography>
                        </Grid>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // backgroundColor: 'blue',
                                height: '100%',
                                width: '10%',
                            }}
                        >
                            <CustomIconButton
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#5cb5b3',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                }}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </CustomIconButton>
                        </Grid>
                    </Grid>
                    <DialogContent
                        dividers
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'auto',
                        }}
                    >
                        <DialogContentText>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Grid sx={{ width: isMobile ? '55%' : '25%' }}>
                                    {' '}
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            paddingRight: '10px',
                                        }}
                                    >
                                        Experience Type:
                                    </Typography>
                                </Grid>
                                <Grid sx={{ width: isMobile ? '45%' : '75%' }}>
                                    <MultiSelectDropdownFilter
                                        id={'typeFilter'}
                                        options={[
                                            {
                                                value: 'property',
                                                label: 'Property',
                                            },
                                            {
                                                value: 'commercial',
                                                label: 'Commercial',
                                            },
                                            { value: 'boat', label: 'Boat' },
                                            {
                                                value: 'vehicle',
                                                label: 'Vehicle',
                                            },
                                            {
                                                value: 'activity',
                                                label: 'Activity',
                                            },
                                        ]}
                                        handleChange={setType}
                                        selectedValue={type}
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {' '}
                                <Grid sx={{ width: isMobile ? '55%' : '25%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            paddingRight: '10px',
                                            width: '100%',
                                        }}
                                    >
                                        Location:
                                    </Typography>
                                </Grid>
                                <Grid sx={{ width: isMobile ? '45%' : '75%' }}>
                                    <MultiSelectDropdownFilter
                                        id={'stateFilter'}
                                        options={[
                                            { value: 'NSW', label: 'NSW' },
                                            { value: 'VIC', label: 'VIC' },
                                            { value: 'QLD', label: 'QLD' },
                                            { value: 'SA', label: 'SA' },
                                            { value: 'WA', label: 'WA' },
                                            { value: 'ACT', label: 'ACT' },
                                            { value: 'NT', label: 'NT' },
                                            { value: 'TAS', label: 'TAS' },
                                        ]}
                                        handleChange={setState}
                                        selectedValue={state}
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {' '}
                                <Grid sx={{ width: isMobile ? '55%' : '25%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            paddingRight: '10px',
                                            width: '100%',
                                        }}
                                    >
                                        No Guests:
                                    </Typography>
                                </Grid>
                                <Grid sx={{ width: isMobile ? '45%' : '75%' }}>
                                    <DropdownFilter
                                        id={'guestilter'}
                                        options={[
                                            { value: '', label: 'Any' },
                                            { value: 1, label: '1+' },
                                            { value: 2, label: '2+' },
                                            { value: 3, label: '3+' },
                                            { value: 4, label: '4+' },
                                            { value: 5, label: '5+' },
                                            { value: 6, label: '6+' },
                                            { value: 7, label: '7+' },
                                            { value: 8, label: '8+' },
                                            { value: 9, label: '9+' },
                                            { value: 10, label: '10+' },
                                        ]}
                                        handleChange={setGuests}
                                        selectedValue={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {guests || 'Any'}
                                            </Typography>
                                        }
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {' '}
                                <Grid sx={{ width: isMobile ? '55%' : '25%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            paddingRight: '10px',
                                            width: '100%',
                                        }}
                                    >
                                        No Overnight Guests:
                                    </Typography>
                                </Grid>
                                <Grid sx={{ width: isMobile ? '45%' : '75%' }}>
                                    <DropdownFilter
                                        id={'overnightGuestFilter'}
                                        options={[
                                            { value: '', label: 'Any' },
                                            { value: 1, label: '1+' },
                                            { value: 2, label: '2+' },
                                            { value: 3, label: '3+' },
                                            { value: 4, label: '4+' },
                                            { value: 5, label: '5+' },
                                            { value: 6, label: '6+' },
                                            { value: 7, label: '7+' },
                                            { value: 8, label: '8+' },
                                            { value: 9, label: '9+' },
                                            { value: 10, label: '10+' },
                                        ]}
                                        handleChange={setOvernightGuests}
                                        selectedValue={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {overnightGuests || 'Any'}
                                            </Typography>
                                        }
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Grid sx={{ width: isMobile ? '55%' : '25%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            paddingRight: '10px',
                                            width: '100%',
                                        }}
                                    >
                                        No Bedrooms:
                                    </Typography>
                                </Grid>

                                <Grid sx={{ width: isMobile ? '45%' : '75%' }}>
                                    <DropdownFilter
                                        id={'bedroomsilter'}
                                        options={[
                                            { value: '', label: 'Any' },
                                            { value: 1, label: '1+' },
                                            { value: 2, label: '2+' },
                                            { value: 3, label: '3+' },
                                            { value: 4, label: '4+' },
                                            { value: 5, label: '5+' },
                                            { value: 6, label: '6+' },
                                            { value: 7, label: '7+' },
                                            { value: 8, label: '8+' },
                                            { value: 9, label: '9+' },
                                            { value: 10, label: '10+' },
                                        ]}
                                        handleChange={setBedrooms}
                                        selectedValue={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {bedrooms || 'Any'}
                                            </Typography>
                                        }
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                    />
                                </Grid>
                            </Grid>
                            {management && (
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid
                                        sx={{ width: isMobile ? '55%' : '25%' }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                paddingRight: '10px',
                                                width: '100%',
                                            }}
                                        >
                                            Visit Type:
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        sx={{ width: isMobile ? '45%' : '75%' }}
                                    >
                                        <DropdownFilter
                                            id={'visitTypeFilter'}
                                            options={[
                                                { value: '', label: 'Any' },
                                                {
                                                    value: 'personal',
                                                    label: ' Personal',
                                                },
                                                {
                                                    value: 'business',
                                                    label: ' Business',
                                                },
                                            ]}
                                            handleChange={setVisitType}
                                            selectedValue={
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {visitType || 'Any'}
                                                </Typography>
                                            }
                                            backgroundColor={'white'}
                                            border={'#C3C3C3'}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {' '}
                                <Grid sx={{ width: isMobile ? '55%' : '25%' }}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            paddingRight: '10px',
                                            width: '100%',
                                        }}
                                    >
                                        Amenities:
                                    </Typography>
                                </Grid>
                                <Grid sx={{ width: isMobile ? '45%' : '75%' }}>
                                    <MultiSelectDropdownFilter
                                        id={'amenitiesFilter'}
                                        options={[
                                            {
                                                value: 'aircon',
                                                label: 'Aircon',
                                            },
                                            { value: 'bbq', label: 'BBQ' },
                                            {
                                                value: 'boardroom',
                                                label: 'Boardroom',
                                            },
                                            {
                                                value: 'dedicatedWorkspace',
                                                label: 'Dedicated Workspace',
                                            },
                                            {
                                                value: 'gamesRoom',
                                                label: 'Games Room',
                                            },
                                            {
                                                value: 'kitchen',
                                                label: 'Kitchen',
                                            },
                                            {
                                                value: 'washingMachine',
                                                label: 'Laundry',
                                            },
                                            {
                                                value: 'massages',
                                                label: 'Massages',
                                            },
                                            {
                                                value: 'parking',
                                                label: 'Parking',
                                            },
                                            { value: 'pool', label: 'Pool' },
                                            { value: 'spa', label: 'Spa' },
                                            { value: 'tv', label: 'TV' },
                                            {
                                                value: 'waterSports',
                                                label: 'Water Sport',
                                            },
                                            { value: 'wifi', label: 'Wifi' },
                                            {
                                                value: 'wineCellar',
                                                label: 'Wine Cellar',
                                            },
                                        ]}
                                        handleChange={setAmenities}
                                        selectedValue={amenities}
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>{' '}
                    <DialogActions
                        sx={{
                            borderTop: ' #d8d8d8 solid 1px',
                            // minHeight: '60px',
                            background: ' #eeeeee',
                        }}
                    >
                        <Grid>
                            <CustomButton
                                variant='contained'
                                color='neutral'
                                // autoFocus
                                onClick={onClickClearFilters}
                            >
                                Clear Filters
                            </CustomButton>
                        </Grid>
                        <Grid>
                            {' '}
                            <CustomButton
                                variant='contained'
                                color='green'
                                // autoFocus
                                onClick={handleClose}
                            >
                                Save
                            </CustomButton>
                        </Grid>
                    </DialogActions>
                </Grid>
            </Dialog>
        </>
    );
}

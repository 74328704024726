import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import React, { useEffect } from 'react';
import UserRole from '../../../shared/AuthRoles';
import UserDashboardLargerStats from '../../components/Dashboard/User Dashboards/UserDashboardLargerStats';
import UserDashboardSmallerStats from '../../components/Dashboard/User Dashboards/UserDashboardSmallerStats';
import AdminDashboardSmallerStats from '../../components/Dashboard/Admin Dashboards/AdminDashboardSmallerStats';
import GuestAdminDashboardLargerStats from '../../components/Dashboard/Admin Dashboards/GuestAdminDashboardLargerStats';
import OwnerAdminDashboardLargerStats from '../../components/Dashboard/Admin Dashboards/OwnerAdminDashboardLargerStats';
import SevenDayAdminDashboardLargerStats from '../../components/Dashboard/Admin Dashboards/SevenDayAdminDashboardLargerStats';

export default function DashboardPage() {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // overflow: 'hidden',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <DashboardIcon
                        sx={{
                            color: '#5cb5b3',
                            paddingRight: '5px',
                            fontSize: '32px',
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        Dashboard
                    </Typography>
                </Grid>

                <Grid
                    sx={{
                        width: '100%',
                        minHeight: isMobile ? '35%' : '20%',
                    }}
                >
                    {loggedInUser?.role.includes('admin') ||
                    loggedInUser?.role === 'owner_user' ? (
                        <AdminDashboardSmallerStats />
                    ) : (
                        <UserDashboardSmallerStats />
                    )}
                </Grid>
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: isMobile ? '65%' : '78%',
                        justifyContent: 'left',
                    }}
                >
                    {loggedInUser?.role.includes('owner') ? (
                        <OwnerAdminDashboardLargerStats />
                    ) : loggedInUser?.role === 'admin' ? (
                        <SevenDayAdminDashboardLargerStats />
                    ) : loggedInUser?.role === 'guest_admin' ||
                      loggedInUser?.role === 'guest_department_admin' ? (
                        <GuestAdminDashboardLargerStats />
                    ) : (
                        <UserDashboardLargerStats />
                    )}
                </Grid>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            color: '#787878',
                            fontSize: '12px',
                        }}
                    >
                        {loggedInUser.role === 'owner_admin' ||
                        loggedInUser.role === 'admin' ||
                        loggedInUser?.role === 'owner_user'
                            ? 'NOTE: The following stats do not include blocked reservations i.e Maintenance/staff away etc.'
                            : loggedInUser.role === 'guest_admin' 
                            ? 'NOTE: The following stats are company wide reservations.'
                            :  loggedInUser?.role === 'guest_department_admin'
                            ? 'NOTE: the following stats are your Team reservations'
                            : loggedInUser.role === 'guest_admin'
                            ? 'NOTE: The following stats are your reservations only.'
                            : null}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { useGetReportQuery } from '../../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../../layout/styledcomponents/CustomButton';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSelector } from 'react-redux';
import { selectReportAPIRefSliceData } from '../../../../../app/Slices/ReportAPIRefSlice';
import GuestDetailsReportList from './IndividualGuestCompanyUsers';
import GuestCompanies from './GuestCompanies';

export default function GuestDetailsComponentPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const reportExportAPI: any = useSelector(selectReportAPIRefSliceData);
    const urlRef = window.location.href.split('/')[4];

    //GET REPORT
    const { data: getReport } = useGetReportQuery(`/reports/guest-details`);

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            <Grid
                sx={{
                    textAlign: isMobile ? 'left' : 'right',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? 'left' : 'right',
                        justifyContent: isMobile ? 'flex-start' : 'flex-end',
                    }}
                >
                    <Grid
                        sx={{
                            width: 'auto',
                            marginRight: isMobile ? '0px' : '15px',
                        }}
                    >
                        {/* <CustomButton
                            disableRipple
                            variant='contained'
                            color='neutral'
                            onClick={() => reportExportAPI?.printExport()}
                            sx={{ marginRight: '5px' }}
                        >
                            <PrintIcon fontSize='small' />{' '}
                        </CustomButton> */}
                        <CustomButton
                            disableRipple
                            variant='contained'
                            color='neutral'
                            onClick={() =>
                                reportExportAPI?.csvExport({
                                    fileName: 'Guest Details Report',
                                    columnsStyles: {
                                        createdAt: {
                                            numFmt: 'dd/mm/yyyy',
                                        },
                                    },
                                })
                            }
                            sx={{ marginRight: '5px' }}
                        >
                            <FileDownloadIcon fontSize='small' />{' '}
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                {urlRef === 'guestDetails' ? (
                    <GuestCompanies data={getReport} />
                ) : <GuestDetailsReportList/>}
            </Grid>
        </Grid>
    );
}

import React, { useEffect } from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import PolicyList from '../../components/Policies/PolicyList';
import PolicyToolbar from '../../components/Policies/PolicyToolbar';
import UserRole from '../../../shared/AuthRoles';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import PolicyIcon from '@mui/icons-material/Policy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PoliciesPage = () => {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');

    const navigate = useNavigate();

    useEffect(() => {
        if (loggedInUser.role !== 'owner_admin') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'policiesPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    const onClickGoBack = () => {
        navigate(`/adminCenter/host`);
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? '90%' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <PolicyIcon
                        sx={{
                            color: '#5cb5b3',
                            paddingRight: '5px',
                            fontSize: '32px',
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        Policies
                    </Typography>
                </Grid>
                <Grid sx={{ height: isMobile ? '5%' : '10%' }}>
                    <PolicyToolbar />
                </Grid>
                <Grid sx={{ height: isMobile ? '90%':'85%' }}>
                    {' '}
                    <PolicyList />
                </Grid>
                <Grid sx={{ height: isMobile ? '5%' : '5%' }}>
                    {' '}
                    <CustomButton
                        onClick={onClickGoBack}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon />{' '}
                        {isMobile ? null : (
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    paddingLeft: '5px',
                                }}
                            >
                                Back
                            </Typography>
                        )}
                    </CustomButton>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PoliciesPage;

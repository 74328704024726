import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { GridEventListener } from '@mui/x-data-grid/models';
import {
    CustomChip,
    CustomSmallListChip,
} from '../../../layout/styledcomponents/CustomChip';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserRole from '../../../shared/AuthRoles';
import { useGetUserListQuery } from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector } from 'react-redux';
import { selectFilteredUserData } from '../../../app/Slices/UserFilterSlice';
import moment from 'moment';
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import ProfilePicture from '../Profile/ProfilePicture';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 900,
        color: '#000',
        borderBottom: '1px solid #787878',
        // textTransform: 'upperCase'
    },
    [`& .${gridClasses.row}`]: {
        // backgroundColor: '#f6f8f9',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            // fontWeight: 'bold',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                    // color: 'red',
                },
            },
        },
    },
}));

export default function UsersComponent() {
    const navigate = useNavigate();
    const loggedInUser = UserRole();
    const filterResults: any = useSelector(selectFilteredUserData);
    const [url, setUrl] = useState('');
    const { data: getUsers } = useGetUserListQuery(url);
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    useEffect(() => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_company'
        ) {
            setUrl(`/owners/${loggedInUser?.company}/users`);
        } else if (
            loggedInUser?.role === 'guest_admin' ||
            loggedInUser?.role === 'guest_company' ||
            loggedInUser?.role === 'guest_department_admin'
        ) {
            setUrl(`/guests/${loggedInUser?.company}/users`);
        } else {
            setUrl(`/7-day/users`);
        }
    }, [loggedInUser]);

    let users = getUsers?.filter((val: any) => {
        if (
            filterResults?.role === undefined &&
            filterResults?.status === undefined
        ) {
            return val;
        } else if (
            filterResults?.role !== undefined &&
            filterResults?.status !== undefined
        ) {
            if (
                filterResults?.role === 'All' &&
                filterResults?.status === 'All'
            ) {
                return val;
            } else if (
                filterResults?.role !== 'All' &&
                filterResults?.status !== 'All'
            ) {
                return (
                    val?.role
                        ?.toLowerCase()
                        ?.includes(filterResults?.role?.toLowerCase()) &&
                    val?.isActive ===
                        (filterResults?.status === 'enabled' ? true : false)
                );
            } else if (
                filterResults?.role === 'All' &&
                filterResults?.status !== 'All'
            ) {
                return (
                    val?.isActive ===
                    (filterResults?.status === 'enabled' ? true : false)
                );
            } else if (
                filterResults?.role !== 'All' &&
                filterResults?.status === 'All'
            ) {
                return val?.role
                    ?.toLowerCase()
                    ?.includes(filterResults?.role?.toLowerCase());
            } else {
                return val;
            }
        } else {
            return val;
        }
    });

    const handleShowDetailsClick = (params: any) => {
        navigate(
            `/users/${params?.row?.firstName.replace(/ /g, '').toLowerCase()}/${
                params?.row?.id
            }`,
            {
                state: { name: 'viewUser', params: params.row },
            }
        );
    };

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        navigate(
            `/users/${params.row.firstName.replace(/ /g, '').toLowerCase()}/${
                params?.row.id
            }`,
            {
                state: { name: 'viewUser', params: params.row },
            }
        );
    };

    const columns: GridColDef[] = [
        {
            field: 'firstName',
            headerName: 'User',
            type: 'string',
            // width: isMobile ? 261 : isIpad ? 735 : 1327,
            flex: 1,
            minWidth: 261,
            sortable: true,
            renderCell: (params) => {
                return (
                    <List>
                        <ListItem sx={{ padding: '0!important' }}>
                            {isMobile ? null : (
                                <ListItemAvatar>
                                    <ProfilePicture 
                                        profileDetails={params?.row} 
                                        src={params?.row?.profilePicture?.src}
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            fontSize: '14px'
                                        }}
                                    />
                                </ListItemAvatar>
                            )}
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {params?.value} {params?.row.lastName} -{' '}
                                        <CustomSmallListChip
                                            size='small'
                                            style={{
                                                fontFamily: 'Open Sans',
                                                backgroundColor:
                                                    params?.row.role?.includes([
                                                        'admin',
                                                    ]) ||
                                                    loggedInUser?.role ===
                                                        'admin'
                                                        ? '#5cb5b3'
                                                        : '#E0E0E0',
                                                color:
                                                    params?.row.role?.includes([
                                                        'admin',
                                                    ]) ||
                                                    loggedInUser?.role ===
                                                        'admin'
                                                        ? '#fff'
                                                        : '#787878',
                                            }}
                                            label={
                                                params?.row.role ===
                                                    'owner_admin' ||
                                                loggedInUser?.role === 'admin'
                                                    ? 'ADMIN'
                                                    : params?.row.role ===
                                                      'guest_admin'
                                                    ? 'COMPANY ADMIN'
                                                    : params?.row.role ===
                                                      'guest_department_admin'
                                                    ? 'TEAM ADMIN'
                                                    : params?.row?.viewOnly ===
                                                      true
                                                    ? 'USER (VIEW ONLY)'
                                                    : 'USER'
                                            }
                                            variant='filled'
                                        />
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {params?.row.email}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                );
            },
        },
        {
            field: 'department',
            headerName: loggedInUser?.role.includes('guest')
                ? 'Team'
                : '',
            width: 200,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return loggedInUser?.role.includes('guest') ? (
                    params?.value?.name ? (
                        params?.value?.name?.charAt(0)?.toUpperCase() +
                        params?.value?.name.slice(1)
                    ) : (
                        <Tooltip title='Missing Team: Team Managers will not see, manage or view their staff /reservations unless they have been added to the same team as the manager'>
                            <ErrorIcon sx={{ color: 'red' }} />
                        </Tooltip>
                    )
                ) : null;
            },
        },
        {
            field: 'lastLoginAt',
            headerName: 'Last Login',
            width: isMobile ? 180 : isIpad ? 180 : 180,
            renderCell: (params) => {
                return (
                    <List>
                        <ListItem sx={{ padding: '0!important' }}>
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {params?.value
                                            ? moment(params?.value).format(
                                                  'DD/MM/YYYY'
                                              )
                                            : '-'}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {params?.value
                                            ? moment(params?.value).format(
                                                  'h:mm a'
                                              )
                                            : ''}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                );
            },
            sortable: true,
        },
        {
            field: 'isActive',
            headerName: 'Status',
            width: isMobile ? 5 : isIpad ? 50 : 130,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                return isIpad || isMobile ? (
                    params.value === true ? (
                        <Tooltip title='Enabled'>
                            <DoneIcon sx={{ color: '#5cb5b3' }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title='Enabled'>
                            <BlockIcon sx={{ color: '#787878' }} />
                        </Tooltip>
                    )
                ) : (
                    <CustomChip
                        style={{
                            fontFamily: 'Open Sans',
                        }}
                        label={
                            params.value === true ? (
                                <Typography
                                    color='green'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                    }}
                                >
                                    Enabled
                                </Typography>
                            ) : (
                                <Typography
                                    color='red'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                    }}
                                >
                                    Disabled
                                </Typography>
                            )
                        }
                        color={params.value === true ? 'green' : 'red'}
                        variant='outlined'
                    />
                );
            },
        },
        {
            field: 'more',
            headerName: isMobile ? '' : 'Actions',
            width: isMobile ? 5 : isIpad ? 60 : 60,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return (
                    <CustomButton
                        onClick={() => handleShowDetailsClick(params)}
                        sx={{
                            textTransform: 'lowercase',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <MoreVertIcon sx={{ color: '#787878' }} />
                    </CustomButton>
                );
            },
        },
    ];

    return (
        <Box sx={{ height: 650, width: '100%' }}>
            <StripedDataGrid
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                sx={{
                    boxShadow: 0,
                    border: 0,
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Users
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please add a new user or
                                                adjust the filters
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Results
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please try again
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                }}
                onRowClick={handleRowClick}
                rows={users !== undefined ? users : []}
                columns={columns}
            />
        </Box>
    );
}

import {
    Grid,
    List,
    ListItem,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useGetProfileDetailsQuery } from '../../../../app/Slices/SevenDayApiSlice';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UserRole from '../../../../shared/AuthRoles';
import React from 'react';

export default function PersonalDetailsComponent() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const {
        data: profileDetails,
        isFetching,
        error,
    } = useGetProfileDetailsQuery('/profile');

    return (
        <>
            {isFetching === true ? (
                <Grid
                    sx={{
                        height: isIpad ? '50vh' : isMobile ? '5px' : '50vh',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <>
                    <Typography
                        component='h4'
                        // variant='h6'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 900,
                            paddingY: '30px',
                        }}
                    >
                        Personal Details
                    </Typography>
                    <Grid container columnSpacing='50px' rowSpacing='10px'>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    First Name
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    // placeholder='LEAP'
                                    fullWidth
                                    value={profileDetails?.firstName}
                                    disabled
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Surname
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    // placeholder='LEAP'
                                    fullWidth
                                    value={profileDetails?.lastName}
                                    disabled
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Stack
                                    direction='row'
                                    gap='5px'
                                    alignItems='center'
                                >
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Status
                                    </Typography>
                                    <Tooltip
                                        title={
                                            <Typography
                                                // color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                <List>
                                                    <ListItem>
                                                        {' '}
                                                        Enabled: Users can
                                                        access the site and
                                                        perform actions based on
                                                        their level of access
                                                    </ListItem>
                                                    <ListItem>
                                                        {' '}
                                                        Disabled: Users will not
                                                        be able to access the
                                                        site
                                                    </ListItem>
                                                </List>
                                            </Typography>
                                        }
                                        placement='right'
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    // placeholder='LEAP'
                                    fullWidth
                                    value={
                                        profileDetails?.isActive === true
                                            ? 'Enabled'
                                            : 'Disabled'
                                    }
                                    disabled
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Stack
                                    direction='row'
                                    gap='5px'
                                    alignItems='center'
                                >
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Access
                                    </Typography>
                                    <Tooltip
                                        title={
                                            <Typography
                                                // color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                {loggedInUser?.role ===
                                                'admin' ? (
                                                    <List>
                                                        <ListItem>
                                                            {' '}
                                                            Admin: Add/Edit/View
                                                            Users and Clients
                                                        </ListItem>
                                                    </List>
                                                ) : (
                                                    <List>
                                                        <ListItem>
                                                            {' '}
                                                            Company Admin:
                                                            Add/Edit/View
                                                            Listings, Users and
                                                            Reservations for the
                                                            entire company.
                                                        </ListItem>
                                                        <ListItem>
                                                            {' '}
                                                            Team Admin:
                                                            Add/Edit/View
                                                            Listings, Users and
                                                            Reservations for
                                                            their Team only.
                                                        </ListItem>
                                                        <ListItem>
                                                            {' '}
                                                            User: Add/Edit/View
                                                            Reservations for
                                                            themselves & view
                                                            listings
                                                        </ListItem>
                                                        {loggedInUser?.role.includes(
                                                            'guest'
                                                        ) ? (
                                                            <ListItem>
                                                                View Only User:
                                                                View listings &
                                                                view
                                                                reservations
                                                                made for them
                                                            </ListItem>
                                                        ) : null}
                                                    </List>
                                                )}
                                            </Typography>
                                        }
                                        placement='right'
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    fullWidth
                                    value={
                                        profileDetails?.role === 'owner_admin'
                                            ? 'Admin'
                                            : profileDetails?.role ===
                                              'owner_user'
                                            ? 'User'
                                            : profileDetails?.role ===
                                              'guest_department_admin'
                                            ? 'Team Admin'
                                            : profileDetails?.role ===
                                              'guest_user'
                                            ? profileDetails?.viewOnly
                                                ? 'View Only User'
                                                : 'User'
                                            : 'Company Admin'
                                    }
                                    disabled
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Phone
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    fullWidth
                                    value={profileDetails?.phone}
                                    disabled
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                {loggedInUser?.role.includes('guest') ? (
                                    <Stack
                                        direction='row'
                                        gap='5px'
                                        alignItems='center'
                                    >
                                        {' '}
                                        <Typography
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            Team
                                        </Typography>
                                        <Tooltip
                                            title={
                                                <Typography
                                                    // color='#787878'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                    }}
                                                >
                                                    <List>
                                                        You do not have
                                                        permission to change
                                                        your Team. Please
                                                        contact your company
                                                        admin or Team admin to
                                                        make these changes.
                                                    </List>
                                                </Typography>
                                            }
                                            placement='right'
                                        >
                                            <HelpOutlineIcon
                                                sx={{
                                                    color: '#787878',
                                                    fontSize: '16px',
                                                }}
                                            />
                                        </Tooltip>
                                    </Stack>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                {loggedInUser?.role.includes('guest') ? (
                                    <CustomTextField
                                        fullWidth
                                        value={
                                            profileDetails?.department?.name
                                                ? profileDetails?.department?.name
                                                      ?.charAt(0)
                                                      ?.toUpperCase() +
                                                  profileDetails?.department?.name.slice(
                                                      1
                                                  )
                                                : '-'
                                        }
                                        disabled
                                        color='neutral'
                                        inputProps={{
                                            style: {
                                                height: '10px',
                                                backgroundColor: '#f6f8f9',
                                            },
                                        }}
                                    />
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography
                        component='h4'
                        // variant='h6'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 900,
                            paddingTop: isMobile || isIpad ? '40px' : '30px',
                            paddingBottom: '30px',
                        }}
                    >
                        Login Details
                    </Typography>{' '}
                    <Grid
                        container
                        columnSpacing='50px'
                        rowSpacing='10px'
                        sx={{ paddingBottom: '50px' }}
                    >
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                {' '}
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Email
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    // placeholder='LEAP'
                                    fullWidth
                                    value={profileDetails?.email}
                                    disabled
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Password
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    <CustomTextField
                                        // placeholder='LEAP'
                                        fullWidth
                                        value={'******'}
                                        disabled
                                        color='neutral'
                                        inputProps={{
                                            style: {
                                                height: '10px',
                                                backgroundColor: '#f6f8f9',
                                            },
                                        }}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}

import EditIcon from '@mui/icons-material/Edit';
import {
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetTeamListQuery } from '../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../shared/AuthRoles';
import { capitalizeName } from '../../../shared/utils';

export interface Team {
    id: number;
    name: string;
    activeUsersCount: number;
}

const TeamList = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const { data: getTeams } = useGetTeamListQuery(
        loggedInUser?.company
    );
    const teams: Team[] = getTeams ?? [];


    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: teams?.length === 0 ? 'column' : 'row',
            }}
            container
            spacing={2}
        >
            {teams?.length === 0 && (
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: '150px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                        }}
                    >
                        No Teams
                    </Typography>{' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '12px',
                            textAlign: 'center',
                        }}
                    >
                        No Teams have been created
                    </Typography>
                </Grid>
            )}
            {teams?.map((team) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={2}
                    key={team.id}
                    style={{ display: 'flex' }}
                >
                    {' '}
                    <Card
                        sx={{
                            boxShadow: 1,
                            background: 'none',
                            minHeight: isMobile ? '50px' : '150px',
                            backgroundColor: 'transparent',
                            color: 'none',
                            cursor: 'pointer',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                            '&:focus': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                        onClick={() => {
                            navigate(`/team/${team.id}/edit`);
                        }}
                        style={{ flex: 1 }}
                    >
                        <CardActionArea
                            sx={{
                                background: 'none',
                                backgroundColor: 'transparent',
                                color: '#fff',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                                '&:focus': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    backgroundColor: '#f6f8f9',
                                }}
                            >
                                <Grid>
                                    <Typography
                                        gutterBottom
                                        component='div'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            '&:hover': {
                                                color: '#5cb5b3',
                                            },

                                            padding: '0px 5px 0px 0px',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <EditIcon
                                            fontSize='small'
                                            sx={{ fontSize: '16px' }}
                                        />
                                    </Typography>
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '80px',
                                        }}
                                    >
                                        <Typography
                                            variant='h6'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#5cb5b3',
                                                // fontSize: isIpad ? '16px':''
                                            }}
                                        >
                                            {capitalizeName(team?.name)}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '10px',
                                                paddingLeft: '2px',
                                            }}
                                        >
                                            {/* ({cap?.yearlyCap} bookings / per{' '}
                                            {cap?.appliesTo}) */}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            </Grid>
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '15px',
                                }}
                            >
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {' '}
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '12px',
                                        }}
                                    >
                                        Applied to{' '}
                                        ({team?.activeUsersCount}){' '}
                                        {team?.activeUsersCount === 1
                                            ? 'active user'
                                            : 'active users'}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default TeamList;

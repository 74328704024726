import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useDeclineReservationMutation } from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import DropdownFilter from '../../../../layout/styledcomponents/filters/Dropdown';
import toast from '../../../shared/Toast';

export default function CancelReservationModal(props: any) {
    const [declineReservation] = useDeclineReservationMutation();
    const { onClose, open, onChange, message, id } = props;
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [declineReason, setDeclineReason] = useState('');
    const [declineComment, setDeclineComment] = useState('');

    const MAX_COMMENT_LENGTH = 160;

    const handleReason = (value: any) => {
        setDeclineReason(value);
        ReactGA.event({
            category: 'UI',
            action: `Confirm Declined Reservation`,
            label: `Confirm Declined Reservation Clicked`,
        });
    };

    const handleDeclineComment = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeclineComment(e.target.value);
    };

    const handleClose = () => {
        onClose();
    };

    const handleCloseChangeModal = () => {
        onChange();
    };

    const confirmDecline = () => {
        let requestBody = [
            id,
            {
                reason: declineReason,
                comment: declineComment,
            },
        ];

        declineReservation(requestBody).then((data: any) => {
            if (data) {
                toast.success(`Reservation has been declined`);
            } else {
                toast.error(`Failed to decline reservation`);
            }
        });

        handleCloseChangeModal();
    };

    let ownerReasons = [
        { value: 'Another Reservation', label: 'Another Reservation' },
        { value: 'Cleaning', label: 'Cleaning' },
        { value: 'Duplicate', label: 'Duplicate' },
        { value: 'Booking Cap Exceeded', label: 'Booking Cap Exceeded' },
        { value: 'Weather', label: 'Weather' },
        { value: 'Maintenance', label: 'Maintenance' },
        { value: 'Staff Unavailable', label: 'Staff Unavailable' },
        { value: 'Owners Request', label: 'Owners Request' },
        { value: 'Other', label: 'Other' },
    ];

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid
                sx={{
                    // background: ' pink',
                    width: '100%',
                    height: '100%',
                }}
            >
                <DialogTitle borderBottom='#f6f8f9 solid 1px'>
                    <Typography
                        sx={{ fontFamily: 'Open Sans', color: '#787878' }}
                        variant='h6'
                    >
                        Are you sure you want to {message}?
                    </Typography>
                </DialogTitle>
                <DialogContent
                    dividers
                    sx={{
                        display: 'flex',
                        // alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '220px',
                    }}
                >
                    <DialogContentText>
                        <Typography
                            variant='subtitle2'
                            sx={{ fontFamily: 'Open Sans', color: '#787878' }}
                        >
                            {' '}
                            Note: this reason is displayed to the user
                        </Typography>
                        <DropdownFilter
                            id={'declineReason'}
                            options={ownerReasons}
                            handleChange={handleReason}
                            selectedValue={
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '16px',
                                        marginRight: '5px',
                                    }}
                                >
                                    {declineReason}
                                </Typography>
                            }
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                        />
                        {declineReason === 'Other' && (
                            <>
                                <Box
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='flex-end'
                                >
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Comment{' '}
                                        {declineComment.length === 0 && (
                                            <span
                                                style={{
                                                    color: 'red',
                                                }}
                                            >
                                                *
                                            </span>
                                        )}
                                    </Typography>
                                    <Typography
                                        variant='subtitle2'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color:
                                                declineComment.length <
                                                MAX_COMMENT_LENGTH
                                                    ? '#5cb5b3'
                                                    : 'red',
                                        }}
                                    >
                                        Characters Left:{' '}
                                        {MAX_COMMENT_LENGTH -
                                            declineComment.length}
                                    </Typography>
                                </Box>
                                <CustomTextField
                                    fullWidth
                                    multiline
                                    maxRows={2}
                                    value={declineComment}
                                    onChange={handleDeclineComment}
                                    inputProps={{
                                        maxLength: MAX_COMMENT_LENGTH,
                                        sx: {
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        },
                                    }}
                                />
                            </>
                        )}
                    </DialogContentText>
                </DialogContent>{' '}
                <DialogActions
                    sx={{
                        borderTop: ' #d8d8d8 solid 1px',
                        // minHeight: '60px',
                        background: ' #eeeeee',
                    }}
                >
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color='neutral'
                            // autoFocus
                            onClick={handleClose}
                        >
                            Cancel
                        </CustomButton>
                    </Grid>
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color='warning'
                            onClick={confirmDecline}
                            disabled={
                                declineReason === '' ||
                                (declineReason === 'Other' &&
                                    declineComment.length === 0)
                            }
                            // autoFocus
                            sx={{
                                top: isMobile || isIpad ? '10%' : '50%',
                            }}
                        >
                            Decline Reservation
                        </CustomButton>
                    </Grid>
                </DialogActions>
            </Grid>
        </Dialog>
    );
}

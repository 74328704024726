import {
    Box,
    Grid,
    InputLabel,
    List,
    ListItem,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import FormSelect from '../../../shared/FormSelect';

import { useFormContext, useWatch } from 'react-hook-form';
import FormTextField from '../../../shared/FormTextField';
import { HelpOutline } from '@mui/icons-material';

interface ExperienceDetailsProps {
    isEditing: boolean;
    errors: Record<string, string>;
    owner: {
        notificationsEmail: string;
    };
}

const ExperienceDetails = ({
    isEditing,
    errors,
    owner,
}: ExperienceDetailsProps) => {
    const { control, watch, setValue, clearErrors } = useFormContext();

    const type = useWatch({ control, name: 'type' });
    const description = useWatch({ control, name: 'description' });
    const notificationsEmailType = watch('notificationsEmailType');
    const notificationsEmail = useWatch({
        control,
        name: 'notificationsEmail',
    });

    useEffect(() => {
        if (notificationsEmail === '') {
            setValue('notificationsEmailType', 'default');
        } else {
            setValue('notificationsEmailType', 'custom');
        }
    }, [notificationsEmail]);

    useEffect(() => {
        if (notificationsEmailType === 'default') {
            clearErrors('notificationsEmail');
            setValue('notificationsEmail', '');
        }
    }, [notificationsEmailType]);

    return (
        <Box>
            <Typography
                component='h4'
                color='textPrimary'
                fontWeight='bold'
                pb='30px'
            >
                Experience Details
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={5}>
                <Grid container item xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Type of Experience</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        {/* Wrapped in div as Tooltip doesn't listen over disabled buttons 
                            https://mui.com/material-ui/react-tooltip/#disabled-elements */}
                        <Tooltip
                            title={'Experience type cannot be edited'}
                            disableHoverListener={!isEditing}
                            disableFocusListener={!isEditing}
                        >
                            <div>
                                <FormSelect
                                    name='type'
                                    control={control}
                                    options={[
                                        {
                                            value: 'property',
                                            label: 'Property',
                                        },
                                        {
                                            value: 'commercial',
                                            label: 'Commercial',
                                        },
                                        { value: 'boat', label: 'Boat' },
                                        {
                                            value: 'vehicle',
                                            label: 'Vehicle',
                                        },
                                        {
                                            value: 'activity',
                                            label: 'Activity',
                                        },
                                    ]}
                                    disabled={isEditing}
                                />
                            </div>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Name</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormTextField
                            name='name'
                            control={control}
                            placeholder={
                                type === 'property'
                                    ? 'The Beach House'
                                    : type === 'commercial'
                                    ? 'The Innovation Hub'
                                    : type === 'boat'
                                    ? 'The Dancing Queen'
                                    : type === 'vehicle'
                                    ? 'Speedzilla'
                                    : 'Wamberal GoKarts'
                            }
                            inputProps={{ maxLength: 25 }}
                            customError={errors.name}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>
                            Visit Type{' '}
                            <Tooltip
                                title={
                                    <Typography>
                                        <List>
                                            <ListItem>
                                                Personal: This experience will
                                                only be displayed on the
                                                'Personal' experiences listing
                                                page
                                            </ListItem>
                                            <ListItem>
                                                Business: This experience will
                                                only be displayed on the
                                                'Business' experiences listing
                                                page
                                            </ListItem>
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutline sx={{ fontSize: '16px' }} />
                            </Tooltip>
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormSelect
                            name='visitType'
                            control={control}
                            options={[
                                { value: 'personal', label: 'Personal' },
                                { value: 'business', label: 'Business' },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Status</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormSelect
                            name='isAvailable'
                            control={control}
                            options={[
                                { value: true, label: 'Available' },
                                {
                                    value: false,
                                    label: 'Not Available (Draft)',
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Listing Description</InputLabel>
                        {description?.length < 500 ? (
                            <Typography color='primary' fontSize='12px'>
                                Characters left: {500 - description.length}{' '}
                            </Typography>
                        ) : description?.length > 499 ? (
                            <Typography color='error' fontSize='12px'>
                                Max characters reached:{' '}
                                {description.length - 500}
                            </Typography>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormTextField
                            name='description'
                            control={control}
                            placeholder={
                                type === 'property'
                                    ? 'This property offers amazing waterfront views to host your team bonding events or frequent strategy meetings.'
                                    : type === 'boat'
                                    ? '85ft boat ideal for the non-sailors who want to enjoy life on the water.'
                                    : type === 'activity'
                                    ? 'Electric ninebot GoKarts for the adrenline junkies.'
                                    : type === 'commercial'
                                    ? 'Modern office space with fully-equipped meeting rooms to accommodate your business needs.'
                                    : 'Luxury sedan perfect for executive meetings or stylish city drives.'
                            }
                            inputProps={{ maxLength: 500 }}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel
                            required={notificationsEmailType === 'custom'}
                        >
                            Notification Email{' '}
                            <Tooltip
                                title={
                                    <Typography>
                                        <List>
                                            <ListItem>
                                                This email will receive
                                                notifications for this
                                                experience such as new, edited
                                                and canceled bookings
                                            </ListItem>
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutline sx={{ fontSize: '16px' }} />
                            </Tooltip>
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <Stack spacing={1}>
                            <FormSelect
                                name='notificationsEmailType'
                                control={control}
                                options={[
                                    { value: 'default', label: 'Default' },
                                    { value: 'custom', label: 'Custom' },
                                ]}
                            />
                            <FormTextField
                                name='notificationsEmail'
                                control={control}
                                disabled={notificationsEmailType === 'default'}
                                placeholder={
                                    notificationsEmailType === 'default'
                                        ? owner.notificationsEmail
                                        : ''
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ExperienceDetails;

import { Avatar, SxProps } from '@mui/material'
import React from 'react'

interface ProfilePictureProps {
    profileDetails: {
        firstName: string,
        lastName: string,
    },
    src?: string,
    sx?: SxProps,
}

const ProfilePicture = ({ profileDetails, src, sx }: ProfilePictureProps) => {
    return (
        <Avatar 
            alt='profile picture'
            src={src}
            sx={{
                bgcolor: '#5cb5b3',
                width: '125px', 
                height: '125px',
                ...sx,
            }}
        >
            {`${profileDetails?.firstName.charAt(0)}${profileDetails?.lastName.charAt(0)}`}
        </Avatar>
    )
    }

export default ProfilePicture
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { capitalizeName } from '../../../../shared/utils';

const DriverReservationDetails = ({ drivers }: { drivers: any }) => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <Grid sx={{ display: 'flex' }}>
            <Grid
                sx={{
                    width: isMobile ? '30%' : '20%',
                }}
            >
                {' '}
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        paddingBottom: '10px',
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }}
                >
                    {`Driver${drivers?.length > 1 ? `s` : ``} Information:`}
                </Typography>{' '}
            </Grid>
            <Grid
                sx={{
                    width: isMobile ? '70%' : '80%',
                }}
            >
                {' '}
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        paddingBottom: '10px',
                        fontSize: '14px',
                        listStyle: drivers?.length === 1 ? 'none' : 'default',
                        '& ul': {
                            paddingLeft: drivers?.length > 1 ? '17px' : '0px',
                        },
                        '& li': {
                            marginBottom: '10px',
                        },
                    }}
                >
                    <Box
                        component='ul'
                        sx={{
                            listStyle:
                                drivers?.length === 1 ? 'none' : 'default',
                        }}
                    >
                        {drivers?.map((driver: any, index: any) => (
                            <li key={index}>
                                {`${capitalizeName(
                                    driver.firstName
                                )} ${capitalizeName(driver.lastName)} - ${
                                    driver.email
                                }`}
                                <br />
                                {`Phone: ${driver.phone} `}
                                <br />
                                {`Licence No: ${driver.licenceNo} - Licence Type: ${driver.licenceType}`}
                                {` `}
                            </li>
                        ))}
                    </Box>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default DriverReservationDetails;

import { API_REGIONS, Client } from "@amityco/ts-sdk";

const API_KEY = process.env.REACT_APP_AMITY_API_KEY || '';
    
export let client = Client.createClient(API_KEY, API_REGIONS.SG);

Client.enableUnreadCount()

const sessionHandler: Amity.SessionHandler = {
    sessionWillRenewAccessToken(renewal: Amity.AccessTokenRenewal) {
        renewal.renew();
    },
};

export const connectClient = async (userId: string) => {
    return await Client.login({ userId: userId }, sessionHandler);
}
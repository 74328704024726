import React, { useEffect, useState } from 'react';
import { ModalProps } from '../../../types/modal';

import { ChannelRepository, UserRepository } from '@amityco/ts-sdk';
import { AddCircleRounded } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { useGetUserListQuery } from '../../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../../shared/AuthRoles';
import toast from '../../../shared/Toast';
import { Member, Users } from '../../../types/messaging';
import { User } from '../../../types/user';
import MemberItem from './MemberItem';

interface MembersModalProps extends ModalProps {
    channelId: string;
    users: Users;
}

const MembersModal = ({
    channelId,
    users,
    open,
    onClose,
}: MembersModalProps) => {
    const loggedInUser = UserRole();
    const URL = `/owners/${loggedInUser?.company}`;

    const [members, setMembers] = useState<Member[]>([]);

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState<boolean>(false);

    const [pendingAddition, setPendingAddition] = useState<Member[]>([]);
    const [pendingRemoval, setPendingRemoval] = useState<Member[]>([]);
    const [pendingMute, setPendingMute] = useState<Member[]>([]);
    const [pendingUnmute, setPendingUnmute] = useState<Member[]>([]);

    const [userFlag, setUserFlag] = useState<boolean>(false);

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { data: companyUsers } = useGetUserListQuery(`${URL}/users`);

    // Filter out existing messaging users
    const availableUsers =
        companyUsers?.filter(
            (companyUser: User) =>
                !Object.entries(users).some(
                    ([id, user]) => +id === companyUser.id
                )
        ) || [];

    const handleSelect = (user: User) => {
        handleAdd(user);
        setMenuOpen(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleSave = async () => {
        try {
            setSaving(true);

            if (pendingAddition.length > 0)
                await ChannelRepository.Membership.addMembers(
                    channelId,
                    pendingAddition.map((member) => member.userId)
                );

            if (pendingRemoval.length > 0)
                await ChannelRepository.Membership.removeMembers(
                    channelId,
                    pendingRemoval.map((member) => member.userId)
                );

            if (pendingMute.length > 0) {
                await ChannelRepository.Moderation.muteMembers(
                    channelId,
                    pendingMute.map((member) => member.userId)
                );
            }
            if (pendingUnmute.length > 0) {
                await ChannelRepository.Moderation.unmuteMembers(
                    channelId,
                    pendingUnmute.map((member) => member.userId)
                );
            }

            // cleanup
            setPendingAddition([]);
            setPendingRemoval([]);
            setPendingMute([]);
            setPendingUnmute([]);

            onClose();
            setSaving(false);

            toast.success(`Changes saved successfully.`);
        } catch {
            toast.error(`Couldn't save changes. Please try again`);
        }
    };

    const handleAdd = (user: User) => {
        // check if user exists
        UserRepository.getUser(
            user.id.toString(),
            ({ data: amityUser, loading }) => {
                if (amityUser) {
                    setUserFlag(false);

                    const newMember: Member = {
                        userId: user.id.toString(),
                        name: `${user.firstName} ${user.lastName}`,
                        src: user.profilePicture?.src,
                        isMuted: true,
                        pending: true,
                    };

                    setMembers([...members, newMember]);
                    setPendingAddition([...pendingAddition, newMember]);
                    // mute by default
                    setPendingMute([...pendingMute, newMember]);
                }
                if (!amityUser && !loading) {
                    setUserFlag(true);
                }
            }
        );
    };

    const handleDelete = async (member: Member) => {
        setMembers(members.filter((m) => m !== member));
        setPendingAddition(pendingAddition.filter((m) => m !== member));
        setPendingMute(pendingMute.filter((m) => m !== member));
        setPendingUnmute(pendingUnmute.filter((m) => m !== member));

        if (!member.pending) setPendingRemoval([...pendingRemoval, member]);
    };

    const handleMute = async (member: Member) => {
        setMembers((prevMembers) =>
            prevMembers.map((m) =>
                m === member ? { ...m, isMuted: !m.isMuted } : m
            )
        );

        if (member.isMuted) {
            // If currently muted, unmute
            setPendingMute((prev) =>
                prev.filter((m) => m.userId !== member.userId)
            );
            setPendingUnmute((prev) => [...prev, member]);
        } else {
            // If currently unmuted, mute
            setPendingUnmute((prev) =>
                prev.filter((m) => m.userId !== member.userId)
            );
            setPendingMute((prev) => [...prev, member]);
        }
    };

    useEffect(() => {
        setUserFlag(false);

        ChannelRepository.Membership.getMembers(
            { channelId: channelId, memberships: ['member', 'muted'] },
            ({ data: amityMembers, loading: dataLoading }) => {
                setLoading(dataLoading);

                const newMembers: Member[] = amityMembers.map((member) => {
                    return {
                        ...member,
                        src: users[+member.userId]?.profilePicture?.src,
                        name: users[+member.userId]?.name,
                        pending: false,
                    };
                });

                setMembers(newMembers);
            }
        );
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle variant='h6' fontWeight='bold'>
                Manage Users
            </DialogTitle>
            <DialogContent>
                <Typography variant='body2'>
                    Manage users associated with this channel. Add additional
                    users to view/respond to messages in this channel. Muted
                    users cannot respond to messages.
                </Typography>
                <Box display='flex' justifyContent='flex-end' py={2}>
                    <Button
                        size='small'
                        variant='contained'
                        onClick={handleClick}
                        startIcon={<AddCircleRounded />}
                    >
                        Member
                    </Button>
                    <Popover
                        open={menuOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <List disablePadding>
                            {availableUsers.length > 0 ? (
                                availableUsers.map((user: User) => (
                                    <ListItem
                                        key={user.id}
                                        disableGutters
                                        sx={{ p: 1 }}
                                    >
                                        <ListItemButton
                                            onClick={() => handleSelect(user)}
                                        >
                                            <ListItemText
                                                primary={`${user.firstName} ${user.lastName}`}
                                                secondary={user.email}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            ) : (
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='center'
                                    p={4}
                                >
                                    <Typography color='textSecondary'>
                                        No users available.
                                    </Typography>
                                </Box>
                            )}
                        </List>
                    </Popover>
                </Box>
                <TableContainer>
                    <Table size='small'>
                        <TableBody>
                            {loading ? (
                                <>
                                    {[...Array(3)].map(() => (
                                        <TableRow>
                                            <TableCell>
                                                <Box
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <Box display='flex' gap={1}>
                                                        <Skeleton
                                                            variant='circular'
                                                            height={40}
                                                            width={40}
                                                        />
                                                        <Skeleton
                                                            height={24}
                                                            width={200}
                                                        />
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            ) : (
                                members.map((member) => (
                                    <MemberItem
                                        member={member}
                                        handleDelete={handleDelete}
                                        handleMute={handleMute}
                                    />
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Collapse in={userFlag}>
                    <Alert severity='warning' sx={{ mt: 2 }}>
                        This user needs to log in to 7DAY before they can be
                        added.
                    </Alert>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button variant='contained' onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MembersModal;

import { Box, Typography } from '@mui/material'
import React from 'react'

interface TextContentProps {
    data?: Amity.ContentDataText
}

const TextContent = ({ data }: TextContentProps) => {
    return (
        <Typography>
            {data?.text}
        </Typography>
    )
}

export default TextContent
export const MinHoursOfHire = [
    { value: 1, label: '1 hour' },
    { value: 2, label: '2 hours' },
    { value: 3, label: '3 hours' },
    { value: 4, label: '4 hours' },
    { value: 5, label: '5 hours' },
    { value: 6, label: '6 hours' },
    { value: 24, label: '1 day (no overnight)' },
    // { value: 8, label: '8 hours' },
    // { value: 9, label: '9 hours' },
    // { value: 10, label: '10 hours' },
    // { value: 11, label: '11 hours' },
    // { value: 12, label: '12 hours' },
    // { value: 13, label: '13 hours' },
    // { value: 14, label: '14 hours' },
    // { value: 15, label: '15 hours' },
    // { value: 16, label: '16 hours' },
    // { value: 17, label: '17 hours' },
    // { value: 18, label: '18 hours' },
    // { value: 19, label: '19 hours' },
    // { value: 20, label: '20 hours' },
    // { value: 21, label: '21 hours' },
    // { value: 22, label: '22 hours' },
    // { value: 23, label: '23 hours' },
    { value: 48, label: '1 night' },
    { value: 72, label: '2 nights' },
    { value: 96, label: '3 nights' },
];

export const MinHoursOfHirePropertyCorp = [
    { value: 24, label: '1 day (no overnight)' },
    { value: 48, label: '1 night' },
    { value: 72, label: '2 nights' },
    { value: 96, label: '3 nights' },
    // { value: 120, label: '4 nights' },
    // { value: 144, label: '5 nights' },
    // { value: 168, label: '6 nights' },
    // { value: 168, label: '7 nights' },
];
export const MinHoursOfHirePropertyPersonal = [
    // { value: 24, label: '1 day (no overnight)' },
    { value: 48, label: '1 night' },
    { value: 72, label: '2 nights' },
    { value: 96, label: '3 nights' },
    // { value: 120, label: '4 nights' },
    // { value: 144, label: '5 nights' },
    // { value: 168, label: '6 nights' },
    // { value: 168, label: '7 nights' },
];
export const MinHoursOfHireCommercial = [
    { value: 24, label: '1 day ' },
    { value: 48, label: '2 days' },
    { value: 72, label: '3 days' },
    { value: 96, label: '4 days' },
    { value: 120, label: '5 days' },
];
export const MaxHoursOfHireCommercial = Array.from({ length: 365 }, (_, i) => {
    const days = i + 1;
    return { value: days * 24, label: `${days} day${days > 1 ? 's' : ''}` };
});

export const MaxHoursOfHireProperty = [
    { value: 48, label: '1 night' },
    { value: 72, label: '2 nights' },
    { value: 96, label: '3 nights' },
    { value: 120, label: '4 nights' },
    { value: 144, label: '5 nights' },
    { value: 168, label: '6 nights' },
    { value: 192, label: '7 nights' },
];

export const MaxHoursOfHire = [
    { value: 1, label: '1 hour' },
    { value: 2, label: '2 hours' },
    { value: 3, label: '3 hours' },
    { value: 4, label: '4 hours' },
    { value: 5, label: '5 hours' },
    { value: 6, label: '6 hours' },
    { value: 24, label: '1 day (no overnight)' },
    // { value: 7, label: '7 hours' },
    // { value: 8, label: '8 hours' },
    // { value: 9, label: '9 hours' },
    // { value: 10, label: '10 hours' },
    // { value: 11, label: '11 hours' },
    // { value: 12, label: '12 hours' },
    // { value: 13, label: '13 hours' },
    // { value: 14, label: '14 hours' },
    // { value: 15, label: '15 hours' },
    // { value: 16, label: '16 hours' },
    // { value: 17, label: '17 hours' },
    // { value: 18, label: '18 hours' },
    // { value: 19, label: '19 hours' },
    // { value: 20, label: '20 hours' },
    // { value: 21, label: '21 hours' },
    // { value: 22, label: '22 hours' },
    // { value: 23, label: '23 hours' },
    { value: 48, label: '1 night' },
    { value: 72, label: '2 nights' },
    { value: 96, label: '3 nights' },
    { value: 120, label: '4 nights' },
    { value: 144, label: '5 nights' },
    { value: 168, label: '6 nights' },
    { value: 192, label: '7 nights' },
];

export const CleaningBuffer = [
    { value: 1, label: '1 hour' },
    { value: 2, label: '2 hours' },
    { value: 3, label: '3 hours' },
    { value: 4, label: '4 hours' },
    { value: 5, label: '5 hours' },
    { value: 6, label: '6 hours' },
    // { value: 7, label: '7 hours' },
    // { value: 8, label: '8 hours' },
    // { value: 9, label: '9 hours' },
    // { value: 10, label: '10 hours' },
    // { value: 11, label: '11 hours' },
    // { value: 12, label: '12 hours' },
    // { value: 13, label: '13 hours' },
    // { value: 14, label: '14 hours' },
    // { value: 15, label: '15 hours' },
    // { value: 16, label: '16 hours' },
    // { value: 17, label: '17 hours' },
    // { value: 18, label: '18 hours' },
    // { value: 19, label: '19 hours' },
    // { value: 20, label: '20 hours' },
    // { value: 21, label: '21 hours' },
    // { value: 22, label: '22 hours' },
    // { value: 23, label: '23 hours' },
    { value: 24, label: '1 day' },
];

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

const initialState: { value: any[]; nextId: number } = {
    value: [],
    nextId: 1, // Start ID from 1
};

export const slice = createSlice({
    name: 'experienceDiscounts',
    initialState,
    reducers: {
        setExperienceDiscounts: (state, action: PayloadAction<any[]>) => {
            state.value = action.payload;
        },
        addExperienceDiscount: (state, action: PayloadAction<any>) => {
            const newDiscount = { ...action.payload, id: state.nextId };
            state.value.push(newDiscount);
            state.nextId += 1;
        },
        removeExperienceDiscount: (state, action: PayloadAction<number>) => {
            state.value = state.value.filter(
                (discount) => discount.id !== action.payload
            );
        },
        toggleDisableExperienceDiscount: (
            state,
            action: PayloadAction<number>
        ) => {
            const discount = state.value.find(
                (discount) => discount.id === action.payload
            );
            if (discount) {
                discount.disabled = !discount.disabled;
            }
        },
    },
});

export const {
    setExperienceDiscounts,
    addExperienceDiscount,
    removeExperienceDiscount,
    toggleDisableExperienceDiscount,
} = slice.actions;

export const selectExperienceDiscounts = (state: RootState) =>
    state.experienceDiscounts.value;

export const ExperienceDiscountsReducer = slice.reducer;

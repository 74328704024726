import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface FormDateFilterProps {
    name: string;
    control: Control<any>;
    label?: string;
    views?: Array<'day' | 'month' | 'year'>;
    format?: string;
}

const FormDateFilter: React.FC<FormDateFilterProps> = ({
    name,
    control,
    label,
    views,
    format,
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale='de'
                >
                    <DatePicker
                        views={views}
                        format={format ? format : 'DD/MM/YYYY'}
                        label={label}
                        value={value ? dayjs(value) : null}
                        onChange={(date) => {
                            const formattedDate = date
                                ? dayjs(date).format('YYYY-MM-DD')
                                : '';
                            onChange(formattedDate);
                        }}
                        slots={{
                            textField: TextField,
                        }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                                sx: {
                                    '& .MuiInputBase-input': {
                                        fontSize: '14px',
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '14px',
                                    },
                                    '& .MuiInputLabel-shrink': {
                                        fontSize: '16px',
                                    },
                                },
                            },
                        }}
                    />
                </LocalizationProvider>
            )}
        />
    );
};

export default FormDateFilter;

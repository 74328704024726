import { Grid, Typography, useMediaQuery } from '@mui/material';
import { CustomButton } from '../../layout/styledcomponents/CustomButton';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

export default function PermissionDeniedPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const location = useLocation();
    const navigate = useNavigate();

    const navigateHome = () => {
        navigate(`/dashboard`);
        ReactGA.send({
            hitType: 'pageview',
            page: `/dashboard`,
            title: `Dashboard`,
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Grid sx={{ width: '100%', marginTop: isMobile ? '100px' : '0px' }}>
            <Grid
                sx={{
                    // backgroundColor: 'pink',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    textAlign: 'center',
                    alignSelf: 'center',
                    height: '100%',
                }}
            >
                <Grid>
                    <Typography
                        variant='h1'
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#5cb5b3',
                            fontSize: isMobile ? '100px' : '250px',
                            fontWeight: 900,
                        }}
                    >
                        Oops!
                    </Typography>
                </Grid>
                <Grid sx={{ paddingTop: '30px' }}>
                    <Typography
                        variant={isMobile ? 'h6' : 'h4'}
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontWeight: 900,
                        }}
                    >
                        Unauthorised Access
                    </Typography>
                </Grid>
                <Grid
                    sx={{ paddingTop: '30px', width: isMobile ? '80%' : '20%' }}
                >
                    <Typography
                        sx={{ fontFamily: 'Open Sans', color: '#787878' }}
                    >
                        Sorry, you are an unauthorised person to
                        {location.state?.name === 'individualUser' ? (
                            <span> access another person's details.</span>
                        ) : location.state?.name === 'individualReservation' ? (
                            <span> access another person's details.</span>
                        ) : location.state?.name === 'feesPage' ? (
                            <span> access or edit fees.</span>
                        ) : location.state?.name === 'newUserPage' ? (
                            <span> create new users. Please contact an admin or department admin.</span>
                        ) : (
                            <span> access this page.</span>
                        )}
                    </Typography>
                </Grid>
                <Grid sx={{ paddingTop: '30px' }}>
                    <CustomButton
                        variant='contained'
                        color='green'
                        // fullWidth={isMobile || isIpad}
                        onClick={navigateHome}
                        // disabled={!isValidInput}
                        // sx={{
                        //     top: isMobile || isIpad ? '10%' : '50%',
                        // }}
                    >
                        Go To Dashboard
                    </CustomButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

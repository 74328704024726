import {
    Grid,
    List,
    ListItem,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import UserRole from '../../../../shared/AuthRoles';
import { useGetCompanyOwnerInfoQuery } from '../../../../app/Slices/SevenDayApiSlice';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import { CustomCheckbox } from '../../../../layout/styledcomponents/CustomCheckbox';

export default function OwnerCompanyDetails() {
    const loggedInUser = UserRole();
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const { data: getCompanyDetails } = useGetCompanyOwnerInfoQuery(
        loggedInUser?.company
    );
    const simplifiedAddress = `${getCompanyDetails?.streetAddress1}, ${getCompanyDetails?.streetAddress2} ${getCompanyDetails?.suburb} ${getCompanyDetails?.postcode} ${getCompanyDetails?.state} ${getCompanyDetails?.countryCode}`;

    return (
        <>
            <Typography
                component='h4'
                // variant='h6'
                color='#787878'
                sx={{
                    fontFamily: 'Open Sans',
                    textAlign: 'left',
                    fontWeight: 900,
                    height: '10%',
                    paddingTop: isMobile ? '30px' : '0px',
                    paddingBottom: '30px',
                }}
            >
                Company Details
            </Typography>
            <Grid container columnSpacing='50px' rowSpacing='10px'>
                <Grid item container xs={12} md={6}>
                    <Grid item xs={12} lg={5}>
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Company
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <CustomTextField
                            // placeholder='LEAP'
                            fullWidth
                            value={getCompanyDetails?.name}
                            disabled
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Grid item xs={12} lg={5}>
                        {' '}
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Phone
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <CustomTextField
                            // placeholder='LEAP'
                            fullWidth
                            value={getCompanyDetails?.phone}
                            disabled
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Grid item xs={12} lg={5}>
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Primary Contact
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <CustomTextField
                            // placeholder='LEAP'
                            fullWidth
                            value={getCompanyDetails?.primaryContact}
                            disabled
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Grid item xs={12} lg={5}>
                        {' '}
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Email
                        </Typography>{' '}
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        {' '}
                        <CustomTextField
                            // placeholder='LEAP'
                            fullWidth
                            value={getCompanyDetails?.account.email}
                            disabled
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Grid item xs={12} lg={5}>
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Address
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <CustomTextField
                            // placeholder='LEAP'
                            fullWidth
                            value={simplifiedAddress}
                            disabled
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Grid item xs={12} lg={5}>
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Notification Email
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        {' '}
                        <CustomTextField
                            // placeholder='LEAP'
                            fullWidth
                            value={getCompanyDetails?.notificationsEmail}
                            disabled
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                {loggedInUser?.role === 'owner_company' ||
                loggedInUser?.role === 'guest_company' ? (
                    <>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Stack
                                    direction='row'
                                    gap='5px'
                                    alignItems='center'
                                >
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Status
                                    </Typography>
                                    <Tooltip
                                        title={
                                            <Typography
                                                // color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                <List>
                                                    <ListItem>
                                                        {' '}
                                                        Enabled: Users can
                                                        access the site and
                                                        perform actions based on
                                                        their level of access
                                                    </ListItem>
                                                    <ListItem>
                                                        {' '}
                                                        Disabled: Users will not
                                                        be able to access the
                                                        site
                                                    </ListItem>
                                                </List>
                                            </Typography>
                                        }
                                        placement='right'
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    // placeholder='LEAP'
                                    fullWidth
                                    value={
                                        getCompanyDetails?.isActive === true
                                            ? 'Enabled'
                                            : 'Disabled'
                                    }
                                    disabled
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Stack
                                    direction='row'
                                    gap='5px'
                                    alignItems='center'
                                >
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Access
                                    </Typography>
                                    <Tooltip
                                        title={
                                            <Typography
                                                // color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                <List>
                                                    <ListItem>
                                                        {' '}
                                                        Master Account:
                                                        Add/Edit/View Users
                                                    </ListItem>
                                                    <ListItem>
                                                        {' '}
                                                        Admin: Add/Edit/View
                                                        Listings, Users and
                                                        Reservations
                                                    </ListItem>
                                                    <ListItem>
                                                        {' '}
                                                        User: Add/Edit/View
                                                        Reservations for
                                                        themselves & view
                                                        listings
                                                    </ListItem>
                                                </List>
                                            </Typography>
                                        }
                                        placement='right'
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomTextField
                                    // placeholder='LEAP'
                                    fullWidth
                                    value={
                                        loggedInUser?.role === 'owner_admin'
                                            ? 'Admin'
                                            : loggedInUser?.role ===
                                              'owner_user'
                                            ? 'User'
                                            : loggedInUser?.role ===
                                              'guest_user'
                                            ? loggedInUser?.viewOnly
                                                ? 'View Only User'
                                                : 'User'
                                            : loggedInUser?.role === 'admin'
                                            ? 'Admin'
                                            : 'Master Account'
                                    }
                                    disabled
                                    color='neutral'
                                    inputProps={{
                                        style: {
                                            height: '10px',
                                            backgroundColor: '#f6f8f9',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                            <Grid item xs={12} lg={5}>
                                <Stack
                                    direction='row'
                                    gap='5px'
                                    alignItems='center'
                                >
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Allow multiple domains
                                    </Typography>
                                    <Tooltip
                                        title={
                                            <Typography
                                                // color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                <List>
                                                    <ListItem>
                                                        {' '}
                                                        If checked, users will
                                                        be allowed to add email
                                                        addresses that don't
                                                        belong to the
                                                        organsation to access
                                                        7DAY such as personal
                                                        emails. If unchecked,
                                                        users cannot add an
                                                        email address that is
                                                        outside the{' '}
                                                        {
                                                            getCompanyDetails?.name
                                                        }{' '}
                                                        organisation.
                                                    </ListItem>
                                                </List>
                                            </Typography>
                                        }
                                        placement='right'
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <CustomCheckbox
                                    checked={
                                        getCompanyDetails?.allowsMultipleDomains ===
                                        true
                                            ? true
                                            : false
                                    }
                                    // sx={{ marginLeft: -1 }}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </>
    );
}

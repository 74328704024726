import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReservationRequestContent from '../../components/Reservations/ReservationRequest/ReservationRequestContent';
import { useGetIndividualAssetQuery } from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../shared/AuthRoles';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

export default function ReservationRequestPage() {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();
    const urlRef = window.location.href.split('/')[7];

    const {
        data: getIndividualAsset,
        isFetching,
        error,
    } = useGetIndividualAssetQuery(`modules/${urlRef}`);

    if (error) {
        navigate(`/*`);
        ReactGA.send({
            hitType: 'pageview',
            page: `/*`,
            title: `Permission Denied`,
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? 'auto' : '100%',
                paddingTop: isMobile || isIpad ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {isFetching === true ? (
                <Grid
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{
                        height: '100%',
                        width: '100%',
                        // backgroundColor: 'red',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : isIpad ? '100%' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '2%' : '5%',
                            // backgroundColor: 'yellow',
                            justifyContent: 'left',
                            display: 'flex',
                        }}
                    >
                        <Typography
                            component='h1'
                            variant='h5'
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                                textDecoration: 'underline',
                                textDecorationColor: '#5cb5b3',
                                textUnderlineOffset: '8px',
                            }}
                        >
                            {loggedInUser?.role.includes('owner')
                                ? 'Reserve'
                                : 'Submit Request'}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: '100%',
                            // backgroundColor: 'red',
                            height: isMobile ? '90%' : '100vh',
                            paddingBottom: isMobile ? '10px' : '0px',
                        }}
                    >
                        <ReservationRequestContent
                            reservationDetails={getIndividualAsset}
                        />
                    </Grid>
                </Grid>
            )}
        </Container>
    );
}

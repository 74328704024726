import React, { useEffect, useState } from 'react';
import {
    Box,
    Collapse,
    FormHelperText,
    Grid,
    Paper,
    Stack,
    Typography,
} from '@mui/material';

import { useFormContext, useWatch } from 'react-hook-form';
import { FormValues } from '../../../../types/experience';

// calendar
import FormAutocomplete from '../../../../shared/FormAutocomplete';
import { TimeArray } from '../Property/TimeArray';
import { useCalculatePriceMutation } from '../../../../../app/Slices/SevenDayApiSlice';
import FormCalendar from '../../../../shared/FormCalendar';
import UserRole from '../../../../../shared/AuthRoles';
import { format } from 'date-fns';

const css = `
    .rdp-month_caption {
        display: none;
    }

    /* Scale down further for screens 768px or less */
    @media (max-width: 768px) {
        .rdp-root {
            --rdp-day_button-width: 32px !important;
            --rdp-day_button-height: 32px !important;
            --rdp-day-width: 32px !important;
            --rdp-day-height: 32px !important;
        }
    }

    /* Scale down even further for screens 480px or less */
    @media (max-width: 480px) {
        .rdp-root {
            --rdp-day_button-width: 28px !important;
            --rdp-day_button-height: 28px !important;
            --rdp-day-width: 28px !important;
            --rdp-day-height: 28px !important;
        }
    }
`;

const CalendarView = ({ experience }: { experience: FormValues }) => {
    const { control, setValue } = useFormContext();

    const [calculatePrice] = useCalculatePriceMutation();

    const values = useWatch({ control });

    const date = useWatch({ control, name: '_demo.date' });

    const handleSelect = (d: any) => {
        setValue('_demo.date', d);
    };

    const checkinDate = date?.from;
    const checkoutDate = date?.to;
    const checkinTime = values?._demo?.checkinTime;
    const checkoutTime = values?._demo?.checkoutTime;

    const loggedInUser = UserRole();

    // TODO: Define type
    const [data, setData] = useState<any>();
    const [error, setError] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!date) return;

                const request = {
                    charges: [
                        {
                            ...values,
                            dayPrice: parseInt(values.dayPrice),
                            nightPrice: parseInt(values.nightPrice),
                            hourPrice: parseInt(values.hourPrice),
                            weekendPrice: values.weekendPrice
                                ? {
                                      ...values.weekendPrice,
                                      dayPrice: parseInt(
                                          values.weekendPrice.dayPrice
                                      ),
                                      nightPrice: parseInt(
                                          values.weekendPrice.nightPrice
                                      ),
                                      hourPrice: parseInt(
                                          values.weekendPrice.hourPrice
                                      ),
                                  }
                                : null,
                        },
                    ],
                    checkinTime,
                    checkoutTime,
                    startDate: format(checkinDate, 'yyyy-MM-dd'),
                    endDate: format(checkoutDate, 'yyyy-MM-dd'),
                };
                const response = await calculatePrice([
                    loggedInUser?.company,
                    request,
                ]).unwrap();

                setData(response);
                setError(false);
            } catch (error) {
                setError(true);
            }
        };

        fetchData();
    }, [date, values]);

    return (
        <Paper variant='outlined' sx={{ p: 2 }}>
            <Stack spacing={2}>
                <style>{css}</style>
                <Box>
                    <Typography variant='h6' fontWeight='bold' gutterBottom>
                        Preview
                    </Typography>
                    <Typography variant='body2'>
                        Preview how your rates will look to the guest and adjust
                        to maximize revenue.
                    </Typography>
                </Box>
                <FormCalendar
                    name='_demo.date'
                    control={control}
                    onSelect={handleSelect}
                    hideNavigation
                />
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <FormAutocomplete
                                control={control}
                                name='_demo.checkinTime'
                                label='Check-in'
                                options={TimeArray}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormAutocomplete
                                control={control}
                                name='_demo.checkoutTime'
                                label='Checkout'
                                options={TimeArray}
                            />
                        </Grid>
                    </Grid>
                </Box>
                {error ? (
                    <FormHelperText>
                        Please complete form to see changes reflected
                    </FormHelperText>
                ) : (
                    <Collapse in={!!date}>
                        <div>
                            <Typography variant='overline' fontWeight='bold'>
                                Price Breakdown
                            </Typography>
                            {data?.charges?.map(
                                (charge: any, index: number) => (
                                    <Collapse key={index} in={charge.total > 0}>
                                        {charge?.type === 'perNight' ? (
                                            <Typography variant='body2'>
                                                {`Nightly Rate${
                                                    charge.isWeekendPrice
                                                        ? ' (weekend)'
                                                        : ''
                                                }: ${
                                                    charge.total /
                                                    charge.unitPrice
                                                } night/s x $${
                                                    charge.unitPrice
                                                } = $${charge.total}`}
                                            </Typography>
                                        ) : charge.type === 'perDay' ? (
                                            <Typography variant='body2'>
                                                {`Day Rate${
                                                    charge.isWeekendPrice
                                                        ? ' (weekend)'
                                                        : ''
                                                }: ${
                                                    charge.total /
                                                    charge.unitPrice
                                                } day/s x $${
                                                    charge.unitPrice
                                                } = $${charge.total}`}
                                            </Typography>
                                        ) : (
                                            <Typography variant='body2'>
                                                {`Hourly Rate${
                                                    charge.isWeekendPrice
                                                        ? ' (weekend)'
                                                        : ''
                                                }: ${
                                                    charge.total /
                                                    charge.unitPrice
                                                } hour/s x $${
                                                    charge.unitPrice
                                                } = $${charge.total}`}
                                            </Typography>
                                        )}
                                    </Collapse>
                                )
                            )}
                            <Typography variant='body2' sx={{ pt: 1 }}>
                                + 10% GST: ${data?.bookingTotal?.gst}
                            </Typography>
                            <Typography fontWeight='bold' sx={{ pt: 1 }}>
                                Final Price:
                            </Typography>
                            <Typography variant='h3'>
                                {`A$${new Intl.NumberFormat('en-AU').format(
                                    data?.bookingTotal?.total
                                )}`}
                            </Typography>
                        </div>
                    </Collapse>
                )}
            </Stack>
        </Paper>
    );
};

export default CalendarView;

import { ArrowBack, Info } from '@mui/icons-material'
import { Avatar, Box, Divider, IconButton, Toolbar, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

interface ChannelHeaderProps {
    displayName?: string,
    metadata?: {
        experience?: {
            id?: number,
            photo?: string
        }
        booking?: number,
    },
    handleBack: () => void,
    handleOpen: () => void,
}

const ChannelHeader = ({
    displayName, 
    metadata,
    handleBack,
    handleOpen
}: ChannelHeaderProps) => {
    const isMobile = useMediaQuery('(max-width: 1200px)');
    
    return (
        <>
            <Toolbar />
            <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                px={isMobile ? 2 : 3}
                py={2}
            >
                <Box
                    display='flex'
                    alignItems='center'
                    gap={2}
                    maxWidth='calc(100% - 60px)'
                >
                    {isMobile && (
                        <IconButton onClick={handleBack}>
                            <ArrowBack />
                        </IconButton>
                    )}
                    {!isMobile && (
                        <Avatar 
                            src={metadata?.experience?.photo} 
                            sx={{ width: '40px', height: '40px', zIndex: -1 }}
                        />
                    )}
                   
                    <Typography
                        variant='h6'
                        fontWeight='bold'
                        overflow='hidden'
                        textOverflow='ellipsis'
                        whiteSpace='nowrap'
                        color='#787878'
                    >
                        {displayName}
                    </Typography>
                </Box>
                {metadata?.experience && (
                    <IconButton onClick={handleOpen}>
                        <Info color='primary' />
                    </IconButton>
                )}
            </Box>
            <Divider />
        </>
    )
}

export default ChannelHeader
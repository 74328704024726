import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export default function UseDebounceInput(interval: number = 3000): [string, Dispatch<SetStateAction<string>>] {
    const [inputValue, setInputValue] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, interval);
        return () => clearTimeout(handler);
    }, [inputValue, interval]);
    return [debouncedValue, setInputValue]
}

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import ReactGA from 'react-ga4';

const BookingCapToolbar = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    
    const handleAddClick = () => {
        navigate(`/newBookingCap`);
        ReactGA.send({
            hitType: 'pageview',
            page: `/newBookingCap`,
            title: `New Booking Cap`,
        });
    };

    return (
        <Grid
            sx={{
                textAlign: ' right',
                paddingBottom: '20px',
            }}
        >
            {isMobile || isIpad ? (
                <CustomIconButton
                    variant='contained'
                    color='green'
                    onClick={handleAddClick}
                >
                    <AddCircleIcon />
                </CustomIconButton>
            ) : (
                <CustomButton
                    variant='contained'
                    color='green'
                    startIcon={<AddCircleIcon />}
                    onClick={handleAddClick}
                >
                    add{' '}
                </CustomButton>
            )}
        </Grid>
    );
};

export default BookingCapToolbar;

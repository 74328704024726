import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function EditFeeType() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            {loggedInUser?.role.includes('admin') ? (
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Users with admin permissions can edit existing Fee
                        types.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To edit a Fee type:
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top right corner of the app, click on
                                the cog wheel icon
                            </li>
                            <li>Select 'Admin Center'</li>
                            <li>Select 'Fee Types'</li>
                            <li>
                                Click on the 'pencil' icon located next to the
                                name of the fee type
                            </li>
                            <li>Make your edits</li>
                            <li>Click 'Save'</li>
                        </ol>
                    </Typography>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Only admins can edit a fee type. Please contact
                            a person that is an admin in your organisation to
                            add, edit or delete a fee type.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

import {
    Divider,
    Grid,
    Stack,
    ThemeProvider,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import IndividualExperienceSpacesModal, {
    sortSpacesByType,
} from './IndividualExperienceSpacesModal';
import SpaceCard from './SpaceCard';
import { BeddingCustomTheme } from './Spaces/BeddingCustomBreakpoints';
import { getBeddingArray, ModifiedSpace } from './Spaces/IndividualSpace';

const IndividualExperienceSpaces = ({
    spaces,
}: {
    spaces: ModifiedSpace[];
}) => {
    const [showModal, setShowModal] = useState(false);

    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const numberOfBedsArray = spaces.map((space) => getBeddingArray(space));

    const totalBedsPerSpace = numberOfBedsArray.map((space) =>
        space.reduce((total, [_, count]) => total + count, 0)
    );

    const maxNumberOfBeds = Math.max(...totalBedsPerSpace);

    const visibleSpaces = sortSpacesByType(spaces).slice(0, 3);

    return (
        <Grid
            sx={{
                height: 'auto',
                paddingTop: isIpad ? '10px' : '30px',
                width: isMobile || isIpad ? '100%' : '88%',
            }}
        >
            <Divider
                sx={{
                    width: '100%',
                }}
            />
            <Grid>
                <Grid
                    sx={{
                        paddingTop: '30px',
                        paddingBottom: '10px',
                    }}
                >
                    {' '}
                    <Typography
                        sx={{
                            color: 'text.primary',
                            fontWeight: 'bold',
                            fontSize: '14px',
                        }}
                    >
                        Space Configurations
                    </Typography>
                </Grid>

                <ThemeProvider theme={BeddingCustomTheme}>
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            gap: 4,
                        }}
                    >
                        <Grid container spacing={4}>
                            {visibleSpaces.map((space, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                    xxl={4}
                                >
                                    <SpaceCard
                                        space={space}
                                        selected={false}
                                        spaces={spaces}
                                        maxNumberOfBeds={maxNumberOfBeds}
                                        cursor={
                                            spaces.length > 3
                                                ? 'pointer'
                                                : 'default'
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        {spaces.length > visibleSpaces.length && (
                            <CustomButton
                                color='green'
                                onClick={() => setShowModal(true)}
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Show all {spaces.length} spaces
                            </CustomButton>
                        )}
                    </Stack>
                    <IndividualExperienceSpacesModal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        spaces={spaces}
                        maxNumberOfBeds={maxNumberOfBeds}
                    />
                </ThemeProvider>
            </Grid>
        </Grid>
    );
};

export default IndividualExperienceSpaces;

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function SupportUsers() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        In order to enable In-app messaging you need to assign
                        at least one user from your organisation as a support user. This will enable messaging
                        and add the assigned support user/s to any new experience or
                        reservation message channels.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To assign a support user:
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top right corner of the app, click on
                                the cog wheel icon
                            </li>
                            <li>Click 'Admin Center'</li>
                            <li>Click 'In-app Messaging'</li>
                            <li>
                                Click 'Add User' and select the user you want to
                                assign as a support user.
                            </li>

                            <li>Click 'Save'</li>
                        </ol>
                        After assigning a support user they will automatically
                        be added to the future message channels.
                    </Typography>
                </Grid>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        <br />
                        You can add a new member to a specific channel using the
                        following steps:
                        <ol>
                            <li>
                                Click on the envelope icon on the top right
                                corner of the app
                            </li>
                            <li>
                                From the left panel, select the channel you want to add the member to
                                
                            </li>
                            <li>
                                Click on the Members cog wheel in the Details
                                panel on the right
                            </li>
                            <li>Add the member</li>
                            <li>Click 'SAVE CHANGES'</li>
                        </ol>
                        Added members can see the history of the messages and if
                        not muted, they will be able to send messages themselves. They can also be
                        removed from the channel later.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

import { Box, List, ListItem, ListItemButton, ListItemText, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { User } from '../../../types/user'
import { AddModerator, RemoveModerator } from '@mui/icons-material'
import { useGetUserListQuery } from '../../../../app/Slices/SevenDayApiSlice'
import UserRole from '../../../../shared/AuthRoles'
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton'

interface UserTableProps {
    users: User[],
    handleAdd: (user: User) => void,
    handleRemove: (id: number) => void,
}

const UserTable = ({ users, handleAdd, handleRemove }: UserTableProps) => {
    const loggedInUser = UserRole();
    const URL = `/owners/${loggedInUser?.company}`;

    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { data: companyUsers } = useGetUserListQuery(`${URL}/users`);

    // Filter out existing messaging users
    const availableUsers = companyUsers?.filter((companyUser: User) => 
        !users.some((user) => user.id === companyUser.id)
    ) || [];

    const handleSelect = (user: User) => {
        handleAdd(user);
        setOpen(false);
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <TableContainer>
            <Box 
                display='flex' 
                justifyContent='space-between'
            >
                <Typography variant='h6' color='#787878'>
                    Support Users
                </Typography>
                <CustomButton 
                    color='green'
                    variant='contained'
                    startIcon={<AddModerator />}
                    onClick={handleClick}
                >   
                    Add User
                </CustomButton>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <List disablePadding>
                        {availableUsers.length > 0 ? (
                            availableUsers.map((user: User) => (
                                <ListItem key={user.id} disableGutters sx={{ p: 1 }}>
                                    <ListItemButton onClick={() => handleSelect(user)}>
                                        <ListItemText 
                                            primary={`${user.firstName} ${user.lastName}`}
                                            secondary={user.email}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))
                        ) : (
                            <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                p={4}
                            >
                                <Typography color='textSecondary'>
                                    No users available.
                                </Typography>
                            </Box>
                        )}
                        
                    </List>
                </Popover>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            User
                        </TableCell>
                        <TableCell>
                            Email
                        </TableCell>
                        <TableCell padding='checkbox'/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.id}>
                            <TableCell>
                                {user.firstName} {user.lastName}
                            </TableCell>
                            <TableCell>
                                {user.email}
                            </TableCell>
                            <TableCell padding='checkbox'>
                                <Tooltip title='Revoke Access'>
                                    <CustomButton
                                        color='error'
                                        variant='outlined'
                                        size='small'
                                        startIcon={<RemoveModerator />}
                                        onClick={() => handleRemove(user.id)}
                                    >
                                        Revoke
                                    </CustomButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default UserTable

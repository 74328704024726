import { Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import React, { forwardRef, useRef, useState } from 'react';
import { CustomTextField } from '../../layout/styledcomponents/CustomTextField';

const useStyles = makeStyles({
    input: {
        '&::placeholder': {
            color: '#787878',
            opacity: 0.9,
        },
    },
});

interface Props {
    handleSearchValueChange: any;
    value: string;
}

export default function SearchFilterGeneric({
    handleSearchValueChange,
    value,
}: Props) {
    const isMobile = useMediaQuery('(max-width: 900px)');
    const firstTextFieldRef = useRef(null);

    return (
        <>
            <SearchFilter
                label='Where to'
                inputVal={value}
                ref={firstTextFieldRef}
                handleInput={handleSearchValueChange}
            />
        </>
    );
}

interface SearchFilterProps {
    label?: string;
    inputVal?: string | undefined;
    handleInput?: any;
    textBox?: any;
}

const SearchFilter = forwardRef<any, SearchFilterProps>(
    ({ label, inputVal, handleInput }, ref) => {
        const isMobile = useMediaQuery('(max-width: 900px)');
        const classes = useStyles();

        return (
            <CustomTextField
                color='neutral'
                variant='standard'
                placeholder='Search experiences'
                fullWidth
                label={
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontWeight: 'bold',
                        }}
                    >
                        {label}
                    </Typography>
                }
                onChange={handleInput}
                value={inputVal}
                sx={{
                    border: 0,
                    fontFamily: 'Open Sans',
                    '& .MuiInputBase-input': {
                        color: '#787878',
                        fontSize: isMobile ? '10px' : '12px',
                        fontFamily: 'Open Sans',
                    },
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.input },
                }}
            />
        );
    }
);

import { Message } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useEditCompanyMutation,
    useGetProfileCompanyInfoQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import UserRole from '../../../../shared/AuthRoles';
import toast from '../../../shared/Toast';
import { User } from '../../../types/user';
import UserTable from './UserTable';
import { handleHttpError } from '../../../../shared/utils';

const MessagingSettings = () => {
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    const URL = `/owners/${loggedInUser?.company}`;

    // api
    const { data: company } = useGetProfileCompanyInfoQuery(URL);
    const [updateCompany] = useEditCompanyMutation();

    // state
    const [messagingUsers, setMessagingUsers] = useState<User[]>([]);

    const handleAdd = (user: User) => {
        setMessagingUsers((prev) => [...prev, user]);
    };

    const handleRemove = (id: number) => {
        setMessagingUsers((prev) => prev.filter((user) => user.id !== id));
    };

    const handleSubmit = () => {
        const request = {
            messagingUsers: messagingUsers.map((user) => ({ id: user.id })),
        };

        updateCompany([URL, request]).then((data: any) => {
            if (data) {
                if (data?.error) {
                    const errorMessage = handleHttpError(data?.error);
                    toast.error(errorMessage);
                } else {
                    toast.success(`Messaging settings have been updated`);
                    navigate('/adminCenter/host');
                }
            } else {
                toast.error(`Failed to edit messaging settings`);
            }
        });
    };

    useEffect(() => {
        if (company) {
            setMessagingUsers(company.messagingUsers);
        }
    }, [company]);

    return (
        <Box width='100%'>
            <Typography variant='h5' color='#787878' pb='30px'>
                <Message color='primary' sx={{ mr: 1 }} />
                MESSAGING
            </Typography>
            <Stack spacing={2}>
                <UserTable
                    users={messagingUsers}
                    handleAdd={handleAdd}
                    handleRemove={handleRemove}
                />
                <Box display='flex' justifyContent='flex-end'>
                    <CustomButton
                        color='green'
                        variant='contained'
                        onClick={handleSubmit}
                    >
                        Save Changes
                    </CustomButton>
                </Box>
            </Stack>
        </Box>
    );
};

export default MessagingSettings;

import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAccessToken } from "../../../app/Slices/AuthSlice";
import { useRefreshMutation } from "../../../app/Slices/SevenDayApiSlice";
import UserRole from "../../../shared/AuthRoles";
import { connectClient } from "../../../shared/Amity";

const RequireAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const loggedInUser = UserRole();
  const token = useSelector(getAccessToken);
  const [refresh] = useRefreshMutation();

  const [connected, setConnected] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
        if (loggedInUser && !connected) {
            console.log('Amity logging in...')
            let isConnected = await connectClient(loggedInUser?.sub.toString());
            setConnected(isConnected)
        }
    })();
  }, [loggedInUser])

  useEffect(() => {
    const loadAccessToken = async () => {
      await refresh({});
      setIsLoading(false);
    };
    loadAccessToken();
  }, [refresh]);

  return token ? (
    <Outlet />
  ) : isLoading ? (
    <></>
  ) : (
    <Navigate to={"/login"} replace />
  );
};
export default RequireAuth;

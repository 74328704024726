import { Box, useMediaQuery } from '@mui/material';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import moment from 'moment';
import React from 'react';
import { HostPayment } from '../../../app/Slices/SevenDayApiTypes';
import { formatCurrencyShort, getPaymentStatus } from '../../../shared/utils';
import StripedDataGrid from '../../shared/StripedDataGrid';
import { useNavigate } from 'react-router-dom';
export const PaymentsList = ({ payments }: { payments: HostPayment[] }) => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();
    const columns: GridColDef[] = [
        {
            field: 'period',
            headerName: 'Period',
            flex: 1,
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'ref',
            headerName: 'Ref.',
            width: 100,
            sortable: true,
        },
        {
            field: 'bookingsTotal',
            headerName: 'Amount',
            sortable: true,
            renderCell: ({ value }) => formatCurrencyShort(value),
            headerAlign: 'right',
            align: 'right',
        },
        {
            field: 'serviceFeeAmount',
            headerName: '7DAY Service Fee',
            width: isMobile ? 80 : isIpad ? 100 : 150,
            sortable: true,
            renderCell: ({ value }) => formatCurrencyShort(value),
            headerAlign: 'right',
            align: 'right',
        },
        {
            field: 'amount',
            headerName: 'Gross',
            sortable: true,
            renderCell: ({ value }) => formatCurrencyShort(value),
            headerAlign: 'right',
            align: 'right',
        },
        {
            field: 'paidAt',
            headerName: 'Paid Date',
            width: isMobile ? 80 : isIpad ? 100 : 150,
            sortable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: true,
        },
    ];

    const rows = payments.map((payment) => {
        const {
            id,
            periodStart,
            periodEnd,
            bookingsTotal,
            serviceFeeAmount,
            amount,
            amountDue,
            paidAt,
            year,
            month,
            ownerId,
        } = payment;
        return {
            id: id ? id : 0,
            ref: id ? `#${id}` : '',
            period: `${moment(new Date(periodStart)).format(
                'D MMM'
            )} - ${moment(new Date(periodEnd)).format('D MMM, YYYY')}`,
            bookingsTotal,
            serviceFeeAmount,
            amount,
            paidAt: paidAt ? moment(paidAt).format('D MMM YYYY') : '',
            status: getPaymentStatus(payment),
            year,
            month,
            ownerId,
        };
    });

    const handleRowClick: GridEventListener<'rowClick'> = ({
        row: { ownerId, year, month },
    }) => {
        navigate(`/host-payment/${ownerId}/${year}/${month}`);
    };
    return (
        <Box>
            <StripedDataGrid
                sx={{
                    '& .MuiDataGrid-columnHeader': {
                        paddingLeft: '10px',
                    },
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    },
                }}
                onRowClick={handleRowClick}
                columns={columns}
                rows={rows}
            />
        </Box>
    );
};

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../shared/AuthRoles';
import { Link } from 'react-router-dom';

export default function Support() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        paddingBottom: '20px',
                        fontSize: '14px',
                    }}
                >
                    {' '}
                    Need help? Email us at {''}
                    <Link
                        style={{
                            color: '#787878',
                            textDecoration: 'underline',
                        }}
                        to='#'
                        // @ts-ignore
                        onClick={() =>
                            // @ts-ignore
                            (window.location = 'mailto: support@7-day.com')
                        }
                    >
                        support@7-day.com
                    </Link>{' '}
                </Typography>
                {loggedInUser?.role.includes('guest') && (
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Have questions about your reservation or a listed
                        experience? <br />
                        Contact the host using our messaging feature. Refer to
                        the In-app Messaging section for more details.
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
}

import { Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { capitalizeName, formatCurrency, sum } from '../../../../shared/utils';
import {
    useGetFeeListQuery,
    useGetPlatformSettingsQuery,
} from '../../../../app/Slices/SevenDayApiSlice';

import { useFormContext, useWatch } from 'react-hook-form';
import UserRole from '../../../../shared/AuthRoles';
import { ExperienceCharge } from '../../../types/experience';

export default function NetEarnings(params: any) {
    const { control } = useFormContext();
    const loggedInUser = UserRole();

    const charges = useWatch({ control, name: 'charges' });

    const isXxs = useMediaQuery('(max-width: 400px)');
    const isXs = useMediaQuery('(max-width: 500px)');
    const isMd = useMediaQuery('(max-width: 900px)');
    const isLg = useMediaQuery('(max-width: 1200px)');
    const isXl = useMediaQuery('(max-width: 1535px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const { data: getPlatformSettings } =
        useGetPlatformSettingsQuery('/platform-settings');

    const { data: availableFees = [] } = useGetFeeListQuery(
        loggedInUser?.company
    );

    const findBasePrice = charges.filter((fee: any) => {
        return fee?.chargeType === 'price';
    });

    const findDayPrice = charges.filter((price: ExperienceCharge) => {
        return price.chargeType === 'flexiblePrice';
    });

    const dayPrice = findDayPrice?.[0]?.dayPrice ?? 0;
    const dayWeekendPrice = findDayPrice?.[0]?.weekendPrice?.dayPrice ?? 0;

    const findType = findBasePrice?.[0]?.type;

    const basePrice =
        findBasePrice?.length > 0
            ? params?.experienceType === 'commercial'
                ? findBasePrice.find(
                      (price: any) =>
                          price.type === 'entireVenuePerDay' ||
                          price.type === 'entireVenuePerNight'
                  )?.unitPrice
                : findBasePrice?.[0]?.unitPrice
            : findDayPrice?.length > 0
            ? dayPrice
            : 0;

    const weekendPrice = findBasePrice?.[0]?.weekendPrice
        ? findBasePrice?.[0]?.weekendPrice?.unitPrice
        : findDayPrice?.[0]?.weekendPrice
        ? dayWeekendPrice
        : 0;

    const getFees = charges.filter((fee: any) => {
        return fee?.chargeType === 'fee';
    });

    const fees = getFees?.map((fee: any) => {
        return {
            feeName:
                fee?.name ??
                availableFees.find((f: any) => f.id === fee.feeId).name,
            feePrice: fee?.unitPrice,
        };
    });

    const feeAmounts = getFees?.map((fee: any) => {
        return fee?.unitPrice;
    });

    const feeTotals = feeAmounts?.length > 0 ? sum(feeAmounts) : 0;
    // const feeTotals = 0

    const serviceFee = getPlatformSettings?.serviceFeePercentage;
    // Base price calcs
    const baseTallyTotalsExcludingGST = feeTotals + basePrice;
    const GSTBase = (baseTallyTotalsExcludingGST * 10) / 100;
    const baseTallyTotalsIncGST = baseTallyTotalsExcludingGST + GSTBase;

    const lessServiceFeeBase = (baseTallyTotalsIncGST * serviceFee) / 100;
    const hostNetpayBase = baseTallyTotalsIncGST - lessServiceFeeBase;

    // Weekend price calcs
    const weekendTallyTotalsExcludingGST = Number(feeTotals + weekendPrice);
    const GSTWeekend = (weekendTallyTotalsExcludingGST * 10) / 100;
    const weekendTallyTotalsIncGST = Number(
        weekendTallyTotalsExcludingGST + GSTWeekend
    );

    const lessServiceFeeWeekend = (weekendTallyTotalsIncGST * serviceFee) / 100;
    const hostNetpayWeekend = weekendTallyTotalsIncGST - lessServiceFeeWeekend;

    const basePriceEarnings = {
        gst: GSTBase,
        totalIncGst: baseTallyTotalsIncGST,
        serviceFee: lessServiceFeeBase,
        netPay: hostNetpayBase,
    };

    const weekendPriceEarnings = {
        gst: GSTWeekend,
        totalIncGst: weekendTallyTotalsIncGST,
        serviceFee: lessServiceFeeWeekend,
        netPay: hostNetpayWeekend,
    };

    return (
        <>
            {charges.length > 0 ? (
                <Grid
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: '30px',
                        paddingTop: '30px',
                    }}
                >
                    <Typography
                        component='h4'
                        // variant='h6'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            fontWeight: 900,
                            paddingBottom: '20px',
                        }}
                    >
                        Your Earnings
                    </Typography>
                    <Grid
                        container
                        columns={25}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}
                    >
                        <Grid item xs={25}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                An example of your net payment minus our 7DAY
                                Service Fee
                                {params?.experienceType === 'commercial'
                                    ? ' for the entire property.'
                                    : '.'}
                            </Typography>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontSize: '10px',
                                }}
                            >
                                Note: Amounts do not include any active
                                discounts.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid sx={{ paddingTop: '20px' }}>
                        {charges?.[0]?.weekendPrice === null ? (
                            <Grid>
                                <Grid
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            width: isMobile ? '60%' : '20%',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        {params?.experienceType === 'commercial'
                                            ? 'Entire Property '
                                            : 'Base Price '}

                                        {findType === 'perHour'
                                            ? '(per hour)'
                                            : findType === 'perDay'
                                            ? '(per day)'
                                            : findType === 'perNight'
                                            ? '(per night)'
                                            : findType === 'perDayPerGuest'
                                            ? '(per day per guest)'
                                            : findType === 'perNightPerGuest'
                                            ? '(per night per guest)'
                                            : findType === 'perHourPerGuest'
                                            ? '(per hour per guest)'
                                            : ''}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '14px',
                                            width: isMobile ? '40%' : '20%',
                                            textAlign: 'right',
                                        }}
                                    >
                                        {formatCurrency(basePrice)}
                                    </Typography>
                                </Grid>

                                {fees?.map((item: any, index: number) => (
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        {' '}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                                // fontWeight: item[0] === 'total' ? 'bold' : '',
                                                // fontWeight: 'bold',
                                                width: isMobile ? '60%' : '20%',
                                                paddingBottom: '5px',
                                            }}
                                        >
                                            {capitalizeName(item?.feeName)}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                                width: isMobile ? '40%' : '20%',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {formatCurrency(item?.feePrice)}
                                        </Typography>
                                    </Grid>
                                ))}

                                <Divider
                                    sx={{
                                        color: '#e3e3e3',
                                        width: isXxs
                                            ? '100%'
                                            : isXs
                                            ? '100%'
                                            : isMobile
                                            ? '100%'
                                            : isMd
                                            ? '40%'
                                            : isLg
                                            ? '40%'
                                            : isXl
                                            ? '40%'
                                            : '40%',
                                    }}
                                />

                                {Object.entries(basePriceEarnings).map(
                                    (item: any, index: number) => (
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            {' '}
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                    fontWeight:
                                                        item[0] === 'gst' ||
                                                        item[0] ===
                                                            'totalIncGst' ||
                                                        item[0] === 'serviceFee'
                                                            ? ''
                                                            : 'bold',

                                                    width: isMobile
                                                        ? '60%'
                                                        : '20%',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                {capitalizeName(
                                                    item?.[0] === 'gst'
                                                        ? 'GST 10%'
                                                        : item?.[0] ===
                                                          'totalIncGst'
                                                        ? 'Total (Inc GST)'
                                                        : item?.[0] ===
                                                          'serviceFee'
                                                        ? `less 7DAY Service Fee ${serviceFee}%`
                                                        : item?.[0] === 'netPay'
                                                        ? 'Your net pay'
                                                        : item?.[0]
                                                )}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                    fontWeight:
                                                        item[0] === 'netPay'
                                                            ? 'bold'
                                                            : '',
                                                    width: isMobile
                                                        ? '40%'
                                                        : '20%',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                {item?.[0] === 'serviceFee'
                                                    ? `(${formatCurrency(
                                                          item?.[1]
                                                      )})`
                                                    : `${formatCurrency(
                                                          item?.[1]
                                                      )}`}
                                            </Typography>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection:
                                        isMobile || isIpad ? 'column' : 'row',
                                    width: '100%',
                                }}
                            >
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '50%',
                                    }}
                                >
                                    {' '}
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        {' '}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                width:
                                                    isMobile || isIpad
                                                        ? '60%'
                                                        : '30%',
                                                paddingBottom: '5px',
                                            }}
                                        >
                                            {params?.experienceType ===
                                            'commercial'
                                                ? 'Entire Property'
                                                : 'Base Price '}
                                            {findType === 'perHour'
                                                ? '(per hour)'
                                                : findType === 'perDay'
                                                ? '(per day)'
                                                : findType === 'perNight'
                                                ? '(per night)'
                                                : findType === 'perDayPerGuest'
                                                ? '(per day per guest)'
                                                : findType ===
                                                  'perNightPerGuest'
                                                ? '(per night per guest)'
                                                : findType === 'perHourPerGuest'
                                                ? '(per hour per guest)'
                                                : ''}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                                width:
                                                    isMobile || isIpad
                                                        ? '40%'
                                                        : '20%',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {formatCurrency(basePrice)}
                                        </Typography>
                                    </Grid>
                                    {fees.map((item: any, index: number) => (
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            {' '}
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                    width:
                                                        isMobile || isIpad
                                                            ? '60%'
                                                            : '30%',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                {capitalizeName(item?.feeName)}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                    width:
                                                        isMobile || isIpad
                                                            ? '40%'
                                                            : '20%',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                {formatCurrency(item?.feePrice)}
                                            </Typography>
                                        </Grid>
                                    ))}
                                    <Divider
                                        sx={{
                                            color: '#e3e3e3',
                                            width: isXxs
                                                ? '100%'
                                                : isXs
                                                ? '100%'
                                                : isMobile
                                                ? '100%'
                                                : isMd
                                                ? '100%'
                                                : isLg
                                                ? '100%'
                                                : isXl
                                                ? '50%'
                                                : '50%',
                                        }}
                                    />
                                    {Object.entries(basePriceEarnings).map(
                                        (item: any, index: number) => (
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '14px',
                                                        fontWeight:
                                                            item[0] === 'gst' ||
                                                            item[0] ===
                                                                'totalIncGst' ||
                                                            item[0] ===
                                                                'serviceFee'
                                                                ? ''
                                                                : 'bold',

                                                        width:
                                                            isMobile || isIpad
                                                                ? '60%'
                                                                : '30%',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    {capitalizeName(
                                                        item?.[0] === 'gst'
                                                            ? 'GST 10%'
                                                            : item?.[0] ===
                                                              'totalIncGst'
                                                            ? 'Total (Inc GST)'
                                                            : item?.[0] ===
                                                              'serviceFee'
                                                            ? `less 7DAY Service Fee ${serviceFee}%`
                                                            : item?.[0] ===
                                                              'netPay'
                                                            ? 'Your net pay'
                                                            : item?.[0]
                                                    )}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '14px',
                                                        fontWeight:
                                                            item[0] === 'netPay'
                                                                ? 'bold'
                                                                : '',
                                                        width:
                                                            isMobile || isIpad
                                                                ? '40%'
                                                                : '20%',
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {item?.[0] === 'serviceFee'
                                                        ? `(${formatCurrency(
                                                              item?.[1]
                                                          )})`
                                                        : `${formatCurrency(
                                                              item?.[1]
                                                          )}`}
                                                </Typography>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                                {params?.experienceType === 'commercial' ||
                                weekendPrice === 0 ? null : (
                                    <Grid
                                        sx={{
                                            paddingTop:
                                                isMobile || isIpad
                                                    ? '25px'
                                                    : '',
                                            width:
                                                isMobile || isIpad
                                                    ? '100%'
                                                    : '50%',
                                        }}
                                    >
                                        <Grid
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            {' '}
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    width:
                                                        isMobile || isIpad
                                                            ? '60%'
                                                            : '30%',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Weekend Price
                                                {findType === 'perHour'
                                                    ? ' (per hour)'
                                                    : findType === 'perDay'
                                                    ? ' (per day)'
                                                    : findType === 'perNight'
                                                    ? ' (per night)'
                                                    : findType ===
                                                      'perDayPerGuest'
                                                    ? ' (per day per guest)'
                                                    : findType ===
                                                      'perNightPerGuest'
                                                    ? ' (per night per guest)'
                                                    : findType ===
                                                      'perHourPerGuest'
                                                    ? ' (per hour per guest)'
                                                    : ''}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '14px',
                                                    width:
                                                        isMobile || isIpad
                                                            ? '40%'
                                                            : '20%',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                {formatCurrency(weekendPrice)}
                                            </Typography>
                                        </Grid>

                                        {fees.map(
                                            (item: any, index: number) => (
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            fontSize: '14px',
                                                            width:
                                                                isMobile ||
                                                                isIpad
                                                                    ? '60%'
                                                                    : '30%',
                                                            paddingBottom:
                                                                '5px',
                                                        }}
                                                    >
                                                        {capitalizeName(
                                                            item?.feeName
                                                        )}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            fontSize: '14px',
                                                            width:
                                                                isMobile ||
                                                                isIpad
                                                                    ? '40%'
                                                                    : '20%',
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {formatCurrency(
                                                            item?.feePrice
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            )
                                        )}
                                        <Divider
                                            sx={{
                                                color: '#e3e3e3',
                                                width: isXxs
                                                    ? '100%'
                                                    : isXs
                                                    ? '100%'
                                                    : isMobile
                                                    ? '100%'
                                                    : isMd
                                                    ? '100%'
                                                    : isLg
                                                    ? '100%'
                                                    : isXl
                                                    ? '50%'
                                                    : '50%',
                                            }}
                                        />
                                        {Object.entries(
                                            weekendPriceEarnings
                                        ).map((item: any, index: number) => (
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                {' '}
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '14px',
                                                        fontWeight:
                                                            item[0] === 'gst' ||
                                                            item[0] ===
                                                                'totalIncGst' ||
                                                            item[0] ===
                                                                'serviceFee'
                                                                ? ''
                                                                : 'bold',

                                                        width:
                                                            isMobile || isIpad
                                                                ? '60%'
                                                                : '30%',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    {capitalizeName(
                                                        item?.[0] === 'gst'
                                                            ? 'GST 10%'
                                                            : item?.[0] ===
                                                              'totalIncGst'
                                                            ? 'Total (Inc GST)'
                                                            : item?.[0] ===
                                                              'serviceFee'
                                                            ? `less 7DAY Service Fee ${serviceFee}%`
                                                            : item?.[0] ===
                                                              'netPay'
                                                            ? 'Your net pay'
                                                            : item?.[0]
                                                    )}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '14px',
                                                        fontWeight:
                                                            item[0] === 'netPay'
                                                                ? 'bold'
                                                                : '',
                                                        width:
                                                            isMobile || isIpad
                                                                ? '40%'
                                                                : '20%',
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {item?.[0] === 'serviceFee'
                                                        ? `(${formatCurrency(
                                                              item?.[1]
                                                          )})`
                                                        : `${formatCurrency(
                                                              item?.[1]
                                                          )}`}
                                                </Typography>
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            ) : null}
        </>
    );
}

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
} from '@mui/material';
import React from 'react';
import { CustomButton } from '../../layout/styledcomponents/CustomButton';

export default function GenericModal(props: any) {
    const {
        onClose,
        open,
        children,
        dialogContent,
        dialogActions,
        onChange,
        confirmText,
        confirmColor,
        showConfirmButton,
        cancelText,
        showCancelButton,
        disableConfirm,
        loading,
        loadingColor,
        loadingSize,
    } = props;

    const handleClose = () => {
        onClose();
    };

    const confirmAction = () => {
        onChange();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={false}>
            <Grid
                sx={{
                    width: '100%',
                    height: '100%',
                    // background: ' pink',
                }}
            >
                <Grid>{children}</Grid>
            </Grid>
            {dialogContent && (
                <DialogContent
                    dividers
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '200px',
                    }}
                >
                    <DialogContentText>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        ></Grid>
                    </DialogContentText>
                </DialogContent>
            )}{' '}
            {dialogActions && (
                <DialogActions
                    sx={{
                        borderTop: ' #d8d8d8 solid 1px',
                        // minHeight: '60px',
                        background: ' #eeeeee',
                    }}
                >
                    {showCancelButton && (
                        <Grid>
                            {' '}
                            <CustomButton
                                variant='contained'
                                color='neutral'
                                // autoFocus
                                onClick={handleClose}
                            >
                                {cancelText}
                            </CustomButton>
                        </Grid>
                    )}
                    {showConfirmButton && (
                        <Grid>
                            {' '}
                            <CustomButton
                                variant='contained'
                                color={confirmColor}
                                onClick={confirmAction}
                                disabled={disableConfirm}
                                loading={loading}
                                loadingColor={loadingColor}
                                loadingSize={loadingSize}

                                // autoFocus
                            >
                                {confirmText}
                            </CustomButton>
                        </Grid>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
}

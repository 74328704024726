import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import DropdownFilter from '../../../layout/styledcomponents/filters/Dropdown';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import ReactGA from 'react-ga4';

import { openFilterClientAction, selectFilteredClientData } from '../../../app/Slices/ClientFilterSlice';

export default function ClientsHero() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const dispatch = useDispatch();
    const navigate = useNavigate();;
    const filterResults: any = useSelector(selectFilteredClientData);
    const [status, setStatus] = useState(filterResults?.status || 'All');

    const handleAddClick = () => {
        navigate(`/newClient`);
        ReactGA.send({
            hitType: 'pageview',
            page: '/newClient',
            title: 'New Client',
        });
    };

    // const handleRole = (value: any) => {
    //     setRole(value);
    //     dispatch(
    //         openFilterUserAction({
    //             filteredData: {
    //                 status: status,
    //                 role: value,
    //             },
    //         })
    //     );
    // };
    const handleStatus = (value: any) => {
        setStatus(value);
        dispatch(
            openFilterClientAction({
                filteredData: {
                    status: value,
                    // role: role,
                },
            })
        );
    };
    return (
        <>
            <Grid
                sx={{
                    textAlign: ' right',
                    paddingBottom: isMobile ? '10px' : '0px',
                }}
            >
                {isMobile ? (
                    <CustomIconButton
                        variant='contained'
                        color='green'
                        onClick={handleAddClick}
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <AddCircleIcon />
                    </CustomIconButton>
                ) : (
                    <CustomButton
                        variant='contained'
                        color='green'
                        startIcon={<AddCircleIcon />}
                        onClick={handleAddClick}
                    >
                        Add New Client
                    </CustomButton>
                )}
            </Grid>
            <Grid
                sx={{
                    width: '100%',
                    minHeight: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: isMobile ? '100%' : '25%',
                        // backgroundColor: 'blue',
                        marginRight: isMobile ? '0px' : '30px',
                    }}
                >
                    <DropdownFilter
                        id={'statusFilter'}
                        options={[
                            { value: 'All', label: 'All' },
                            { value: 'enabled', label: 'Enabled' },
                            { value: 'disabled', label: 'Disabled' },
                        ]}
                        handleChange={handleStatus}
                        selectedValue={
                            <Grid
                                sx={{ display: 'flex', flexDirection: 'row' }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '16px',
                                        marginRight: '5px',
                                    }}
                                >
                                    Status:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                >
                                    {status}
                                </Typography>
                            </Grid>
                        }
                        backgroundColor={'white'}
                        border={'#C3C3C3'}
                    />
                </Grid>
            </Grid>
        </>
    );
}

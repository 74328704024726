import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import {
    useAddAssetMutation,
    useGetCompanyOwnerInfoQuery,
} from '../../../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../../../shared/AuthRoles';
import ExperienceForm from '../../../../components/Experiences/Owner/ExperienceForm';
import toast from '../../../../shared/Toast';
import { FormValues } from '../../../../types/experience';

export default function ExperiencesNewFormPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();
    const role = UserRole();

    const {
        data: getCompanyDetails,
        isFetching,
        error,
    } = useGetCompanyOwnerInfoQuery(role?.company);

    const [addNewAsset] = useAddAssetMutation();

    useEffect(() => {
        if (role?.role === 'owner_admin') {
            navigate(`/newExperience`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/newExperience',
                title: 'New Experience',
            });
        } else {
            navigate(`/viewExperiences/all`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: '/viewExperiences/all',
                title: 'All Experiences',
            });
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (error) {
        navigate(`/*`);
        ReactGA.send({
            hitType: 'pageview',
            page: '/*',
            title: 'Error 404',
        });
    }

    const onSubmit = async (data: FormValues) => {
        try {
            const { instantBook, hasPolicies, policies, ...rest } = data;
            const request = {
                ...rest,
                policies: hasPolicies ? policies : [],
                requiresBookingApproval: !instantBook,
            };

            const response = await addNewAsset([
                role?.company,
                request,
            ]).unwrap();

            toast.success(`${data.name} ${data.type} has been created`);

            navigate(
                `/viewExperiences/${role?.company}/${data.type}/${response.id}`
            );
        } catch (error) {
            toast.error(`Failed to create ${data.name} experience`);
        }
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile || isIpad ? 'auto' : '100%',
                paddingTop: '100px',
                display: 'flex',
            }}
        >
            {isFetching === true ? (
                <Grid
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? 'auto' : isIpad ? 'auto' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            justifyContent: 'left',
                            display: 'flex',
                        }}
                    >
                        <AddCircleIcon
                            sx={{
                                color: '#5cb5b3',
                                paddingRight: '5px',
                                fontSize: '32px',
                            }}
                        />

                        <Typography
                            component='h1'
                            variant='h5'
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                            }}
                        >
                            New Experience
                        </Typography>
                    </Grid>

                    <ExperienceForm
                        owner={getCompanyDetails}
                        onSubmit={onSubmit}
                    />
                </Grid>
            )}
        </Container>
    );
}

import React from 'react';
import moment from 'moment';
import { HostPayment } from '../../../app/Slices/SevenDayApiTypes';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { useMediaQuery, useTheme } from '@mui/material';
import { formatCurrencyShort } from '../../../shared/utils';

export const PaymentsChart = ({
    payments,
    year,
}: {
    payments: HostPayment[];
    year: number;
}) => {
    const theme = useTheme();
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const paymentsMap = new Map<number, { amount: number }>();
    for (const { month, amount } of payments) {
        paymentsMap.set(month, { amount });
    }

    const dataset = [];
    for (let month = 0; month < 12; month++) {
        const { amount } = paymentsMap.get(month) ?? { amount: 0 };
        dataset.push({
            month: moment(new Date(year, month)).format(
                isIpad ? 'MMM' : 'MMM, YY'
            ),
            amount,
            period: new Date(year, month),
        });
    }
    return (
        <ResponsiveContainer width={'100%'} height={350}>
            <BarChart data={dataset} margin={{ left: 30 }}>
                <CartesianGrid strokeDasharray='3 3' vertical={false} />
                <XAxis dataKey='month' />
                <YAxis tickFormatter={formatCurrencyShort} />
                <Tooltip formatter={formatCurrencyShort} />
                <Legend />
                <Bar
                    dataKey='amount'
                    name='Gross Earnings'
                    fill={theme.palette.primary.main}
                    maxBarSize={50}
                />
            </BarChart>
        </ResponsiveContainer>
    );
};

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import FeesList from '../../components/Fees/FeesList';
import FeesToolbar from '../../components/Fees/FeesToolbar';
import UserRole from '../../../shared/AuthRoles';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const FeesPage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedInUser.role !== 'owner_admin') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'feesPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    const onClickGoBack = () => {
        navigate(`/adminCenter/host`);
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                // height: isMobile ? '90%' : '100%',
                // paddingTop: isMobile ? '100px' : null,
                paddingTop: '100px',
                paddingBottom: '50px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    // height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <AttachMoneyIcon
                        sx={{
                            color: '#5cb5b3',
                            paddingRight: '5px',
                            fontSize: '32px',
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        Fee Types
                    </Typography>
                </Grid>
                <Grid sx={{ height: isMobile ? '5%' : '10%' }}>
                    <FeesToolbar />
                </Grid>
                <Grid
                    // sx={{ height: isMobile ? '90%' : '85%' }}
                    sx={{ paddingBottom: '50px' }}
                >
                    <FeesList />
                </Grid>
                <Grid sx={{ height: isMobile ? '5%' : '5%' }}>
                    {' '}
                    <CustomButton
                        onClick={onClickGoBack}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon />{' '}
                        {isMobile ? null : (
                            <Typography
                                sx={{
                                    fontSize: '12px',
                                    paddingLeft: '5px',
                                }}
                            >
                                Back
                            </Typography>
                        )}
                    </CustomButton>
                </Grid>
            </Grid>
        </Container>
    );
};

export default FeesPage;

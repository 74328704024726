import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Grid,
    List,
    ListItem,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useEditUserMutation,
    useGetProfileDetailsQuery,
    useGetTeamListQuery,
    useGetUserListQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import DropdownFilter from '../../../../layout/styledcomponents/filters/Dropdown';
import UserRole from '../../../../shared/AuthRoles';
import { useExitPrompt } from '../../../../shared/LeavingPageWarning';
import Modal from '../../../shared/Modal';
import toast from '../../../shared/Toast';
import { Photo } from '../../../types/user';
import EditProfilePicture from '../../Profile/Edit/EditProfilePicture';

export interface Teams {
    id: number;
    name: string;
}

export default function EditUserForm(userInformation: any) {
    const navigate = useNavigate();
    const [editUser] = useEditUserMutation();
    const [usersUrl, setUserUrl] = useState('');
    const { data: getUsers } = useGetUserListQuery(usersUrl);
    const [isDuplicateEmail, setDuplicateEmail] = useState(false);
    const loggedInUser = UserRole();
    const [getURL, setURL] = useState('');
    const [userDetails, setUserDetails] = useState(userInformation.user);
    const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [contactFirst, setContactFirst] = useState(userDetails?.firstName);
    const [contactLast, setContactLast] = useState(userDetails?.lastName);
    const [role, setRole] = useState(userDetails?.role);
    const [viewOnly, setViewOnly] = useState(userDetails?.viewOnly);
    const [status, setStatus] = useState(
        userDetails?.isActive ? 'enabled' : 'disabled'
    );

    const [photo, setPhoto] = useState<Photo>();
    const [emailAddress, setEmailAddress] = useState(userDetails?.email);
    const [phone, setPhone] = useState(userDetails?.phone);
    const [password, setPassword] = useState('');
    const [isValidInput, setIsValidInput] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [lowerCaseValidation, setLowercaseValidation] = useState(false);
    const [upperCaseValidation, setUppercaseValidation] = useState(false);
    const [pwLengthValidation, setPWLengthValidation] = useState(false);
    const [numberValidation, setNumberValidation] = useState(false);
    const [characterValidation, setCharacterValidation] = useState(false);
    const [team, setTeam] = useState(userDetails?.department);
    //Get List of Teams for dropdown selection

    const [teamList, setTeamList] = useState<any>([]);
    const [teamId, setTeamId] = useState({
        value: null,
        label: '',
    });
    const { data: getTeams } = useGetTeamListQuery(loggedInUser?.company);
    const teams: Teams[] = getTeams ?? [];

    // Get currently logged in users profile details to display team admins team
    const { data: profileDetails, isFetching } =
        useGetProfileDetailsQuery('/profile');

    // Modal for confirming change of team if department_admin
    const [openModal, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalComponent, setModalComponent] = useState('');

    const handleCloseModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        setUserDetails(userInformation.user);
    }, [userInformation.user]);

    useEffect(() => {
        return () => {
            setShowExitPrompt(false);
        };
    }, []);

    const onClickGoBack = () => {
        navigate(`/users`);
    };

    useEffect(() => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_company'
        ) {
            setUserUrl(`/owners/${loggedInUser?.company}/users`);
        } else if (
            loggedInUser?.role === 'guest_admin' ||
            loggedInUser?.role === 'guest_company' ||
            loggedInUser?.role === 'guest_department_admin'
        ) {
            setUserUrl(`/guests/${loggedInUser?.company}/users`);
        } else {
            setUserUrl(`/7-day/users`);
        }
    }, [loggedInUser]);

    useEffect(() => {
        let allTeams: any = teams?.map((val: any) => {
            return val?.owner?.name
                ? {
                      value: val?.id,
                      label:
                          val?.name?.charAt(0)?.toUpperCase() +
                          val?.name.slice(1),
                  }
                : {
                      value: val?.id,
                      label:
                          val?.name?.charAt(0)?.toUpperCase() +
                          val?.name.slice(1),
                  };
        });

        let filterOutDuplicates = allTeams?.filter(
            (value: any, index: any, self: any) =>
                index ===
                self.findIndex(
                    (t: any) =>
                        t?.label === value?.label && t?.label === value?.label
                )
        );

        setTeamList(filterOutDuplicates);
    }, [getTeams]);

    useEffect(() => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_company'
        ) {
            setURL(`/owners/${loggedInUser?.company}/users/${userDetails?.id}`);
        } else if (
            loggedInUser?.role === 'guest_admin' ||
            loggedInUser?.role === 'guest_company' ||
            loggedInUser?.role === 'guest_department_admin'
        ) {
            setURL(`/guests/${loggedInUser?.company}/users/${userDetails?.id}`);
        } else {
            setURL(`/7-day/users/${userDetails?.id}`);
        }
    }, [loggedInUser?.company]);

    const formDetailsEditUser = {
        email: emailAddress?.toLowerCase(),
        // password: 'password',
        firstName: contactFirst,
        lastName: contactLast,
        phone: phone,
        department: team,
        isActive: status === 'enabled' ? true : false,
        role: role,
        ...(!role?.includes('guest_user') && !role?.includes('owner_user')
            ? {}
            : { viewOnly }),
        profilePicture: photo,
    };

    const errors: any = [];

    if (contactFirst?.length === 0) {
        errors.push('Name cannot be empty');
    }
    if (contactLast?.length === 0) {
        errors.push('Surname cannot be empty');
    }
    if (role?.length === 0) {
        errors.push('Role cannot be empty');
    }
    if (status?.length === 0) {
        errors.push('Status cannot be empty');
    }
    if (emailAddress?.length === 0) {
        errors.push('Email Address cannot be empty');
    }
    if (phone?.length === 0) {
        errors.push('Phone cannot be empty');
    }
    if (role === 'guest_department_admin' && !team) {
        errors.push('Team cannot be empty');
    }
    // if (password?.length === 0) {
    //     errors.push('Password cannot be empty');
    // }

    const handleFirstName = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setContactFirst(event.target.value);
        setShowExitPrompt(true);
    };

    const handleLastName = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setContactLast(event.target.value);
        setShowExitPrompt(true);
    };

    const handleRole = (value: any) => {
        if (userDetails?.role?.includes('owner')) {
            setRole(value === 'viewOnlyUser' ? `owner_user` : `owner_${value}`);
        } else if (userDetails?.role?.includes('guest')) {
            setRole(
                value === 'admin'
                    ? 'guest_admin'
                    : value === 'teamAdmin'
                    ? 'guest_department_admin'
                    : 'guest_user'
            );
        } else console.log('7dayAdmin');
        setViewOnly(value === 'viewOnlyUser' ? true : false);
        setShowExitPrompt(true);
    };

    const handleTeamId = (value: any) => {
        if (value === '') {
            navigate('/teams');
            return;
        } else {
            let obj = teamList.find((o: any) => o.value === value);
            setTeamId({ value: value, label: obj.label });
            setTeam({
                id: value,
            });

            // if guest_admin is changing a guest_department_admins team we want to throw a warning that by doing so, they will no longer see any staff under the previous Team
            if (
                loggedInUser?.role === 'guest_admin' &&
                userDetails?.role === 'guest_department_admin'
            ) {
                //execute line
                setOpen(true);

                setModalMessage('Warning');
                setModalComponent('Team change');
            }
        }
    };

    const handleStatus = (value: any) => {
        setStatus(value);
        setShowExitPrompt(true);
    };
    //Retrieve users and compare account.email with the new user email
    const mapOverUsers = getUsers?.map(
        (account: { email: any }) => account?.email
    );

    const handleEmail = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        if (validatEmail(event?.target?.value) === false) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
        //Find Duplicate User
        const findDuplicate = mapOverUsers?.find(
            (i: any) => i === event?.target?.value
        );
        if (
            findDuplicate !== undefined &&
            findDuplicate !== getUsers?.account?.email
        ) {
            setDuplicateEmail(true);
            setEmailAddress('');
        } else if (findDuplicate === getUsers?.account?.email) {
            setDuplicateEmail(false);
            setEmailAddress(event?.target?.value);
        } else {
            setDuplicateEmail(false);
            setEmailAddress(event?.target?.value);
        }
    };
    const handlePhone = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setPhone(event.target.value);
        setShowExitPrompt(true);
        if (validatePhone(event.target.value) === false) {
            setIsValidPhone(true);
        } else {
            setIsValidPhone(false);
        }
    };

    const handlePassword = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setPassword(event.target.value);
        if (event.target.value.length === 0) {
            // if the password is blank, validate as true and don't update PW
            setIsValidInput(false);
        } else {
            if (validatePassword(event.target.value) === false) {
                setIsValidInput(true);
            } else {
                setIsValidInput(false);
            }
        }
    };

    const handlePhoto = (photo: Photo) => {
        setPhoto(photo);
    };

    const validatePassword = (pw: any) => {
        if (/(?=.*[A-Z])/.test(pw) === true) {
            setUppercaseValidation(true);
        } else {
            setUppercaseValidation(false);
        }
        if (pw.length >= 8 === true) {
            setPWLengthValidation(true);
        } else {
            setPWLengthValidation(false);
        }
        if (/[a-z]/.test(pw) === true) {
            setLowercaseValidation(true);
        } else {
            setLowercaseValidation(false);
        }
        if (/[#?!@$%^&*-]/.test(pw) === true) {
            setCharacterValidation(true);
        } else {
            setCharacterValidation(false);
        }
        if (/[0-9]/.test(pw) === true) {
            setNumberValidation(true);
        } else {
            setNumberValidation(false);
        }

        return (
            /[A-Z]/.test(pw) &&
            pw.length >= 8 &&
            /[a-z]/.test(pw) &&
            /[0-9]/.test(pw) &&
            /[#?!@$%^&*-]/.test(pw)
        );
    };

    const validatEmail = (email: any) => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    };

    const validatePhone = (ph: any) => {
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i.test(
            ph
        );
    };

    const saveEditUser = () => {
        const requestBody = formDetailsEditUser;
        let url = getURL;
        let reqInfo = [url, requestBody];
        editUser(reqInfo).then((data: any) => {
            if (data) {
                if (data?.error) {
                    if (data?.error?.data?.error) {
                        toast.error(`${data?.error?.data?.message}`);
                    } else {
                        toast.error(
                            `Failed to edit user: ${formDetailsEditUser.firstName}`
                        );
                    }
                } else {
                    toast.success(
                        `${formDetailsEditUser.firstName} has been edited`
                    );

                    navigate(
                        `/users/${userDetails?.firstName
                            .replace(/ /g, '')
                            .toLowerCase()}/${userDetails?.id}`
                    );
                }
            } else {
                toast.error(
                    `Failed to edit user: ${formDetailsEditUser.firstName}`
                );
            }
        });
    };

    return (
        <Grid sx={{ width: '100%', height: '100%' }}>
            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '30px',
                    height: 'auto',
                }}
            >
                {!loggedInUser?.role?.includes('company') &&
                    loggedInUser?.role !== 'admin' && (
                        <EditProfilePicture
                            user={userDetails}
                            onSubmit={handlePhoto}
                            message={false}
                        />
                    )}

                <Typography
                    component='h4'
                    // variant='h6'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        fontWeight: 900,
                        paddingY: '30px',
                    }}
                >
                    User
                </Typography>

                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            First Name
                            {contactFirst === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            placeholder='John'
                            value={contactFirst}
                            onChange={handleFirstName}
                            fullWidth={isMobile || isIpad}
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Surname
                            {contactLast === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <CustomTextField
                            value={contactLast}
                            placeholder='Smith'
                            onChange={handleLastName}
                            fullWidth={isMobile || isIpad}
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Phone{' '}
                            {phone === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            value={phone}
                            placeholder='0414789456'
                            type='tel'
                            onChange={handlePhone}
                            fullWidth={isMobile || isIpad}
                            color={isValidPhone ? 'red' : 'green'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>
                    {loggedInUser?.role.includes('guest') ? (
                        <>
                            <Grid
                                sx={{
                                    width: isMobile || isIpad ? '100%' : '20%',
                                    height:
                                        isMobile || isIpad ? '25px' : '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <>
                                    <Grid sx={{ marginRight: '5px' }}>
                                        <Typography
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            Team
                                            {role ===
                                                'guest_department_admin' &&
                                            !team ? (
                                                <span
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                >
                                                    *
                                                </span>
                                            ) : null}{' '}
                                        </Typography>
                                    </Grid>

                                    <Grid>
                                        <Tooltip
                                            title={
                                                <Typography
                                                    // color='#787878'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                    }}
                                                >
                                                    <List>
                                                        By selecting* the users
                                                        team, they will appear
                                                        in the 'Team Managers'
                                                        profile. Team Managers
                                                        will have access to view
                                                        and manage users that
                                                        belong to their team as
                                                        well as view and edit
                                                        any reservations that
                                                        belong to them.
                                                    </List>
                                                    <List>
                                                        * Only a company admin
                                                        can change a users Team.
                                                    </List>
                                                </Typography>
                                            }
                                            placement='right'
                                        >
                                            <HelpOutlineIcon
                                                sx={{
                                                    color: '#787878',
                                                    fontSize: '16px',
                                                }}
                                            />
                                        </Tooltip>
                                    </Grid>
                                </>
                            </Grid>
                            <Grid
                                sx={{
                                    width: isMobile || isIpad ? '100%' : '30%',
                                    height:
                                        isMobile || isIpad ? '60px' : '50px',
                                }}
                            >
                                {' '}
                                {loggedInUser?.role ===
                                'guest_department_admin' ? (
                                    <CustomTextField
                                        disabled
                                        fullWidth={isMobile || isIpad}
                                        value={
                                            profileDetails?.department?.name
                                                ?.charAt(0)
                                                ?.toUpperCase() +
                                            profileDetails?.department?.name?.slice(
                                                1
                                            )
                                        }
                                        color='neutral'
                                        inputProps={{
                                            style: {
                                                height: '10px',
                                                width: isMobile
                                                    ? ''
                                                    : isIpad
                                                    ? ''
                                                    : '400px',
                                                backgroundColor: '#f6f8f9',
                                            },
                                        }}
                                    />
                                ) : (
                                    <DropdownFilter
                                        id={'teamList'}
                                        options={[
                                            {
                                                value: '',
                                                label: '+ Add Team',
                                            },
                                        ].concat(
                                            teamList !== undefined
                                                ? teamList
                                                : []
                                        )}
                                        handleChange={handleTeamId}
                                        selectedValue={
                                            <Typography
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                {team?.name
                                                    ? team?.name
                                                    : teamId?.label
                                                          ?.charAt(0)
                                                          ?.toUpperCase() +
                                                      teamId?.label.slice(1)}
                                            </Typography>
                                        }
                                        backgroundColor={'white'}
                                        border={'#C3C3C3'}
                                    />
                                )}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                sx={{
                                    width: isMobile || isIpad ? '100%' : '20%',
                                    height: isMobile || isIpad ? '0px' : '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            ></Grid>{' '}
                            <Grid
                                sx={{
                                    width: isMobile || isIpad ? '100%' : '30%',
                                    height: isMobile || isIpad ? '0px' : '50px',
                                }}
                            ></Grid>
                        </>
                    )}
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Grid sx={{ marginRight: '5px' }}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Access
                                {role === '' ? (
                                    <span
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        *
                                    </span>
                                ) : null}{' '}
                            </Typography>
                        </Grid>

                        <Grid>
                            <Tooltip
                                title={
                                    <Typography
                                        // color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <List>
                                            <ListItem>
                                                {' '}
                                                Company Admin: Add/Edit/View
                                                Listings, Users and Reservations
                                                for the entire company.
                                            </ListItem>
                                            <ListItem>
                                                {' '}
                                                Team Admin: Add/Edit/View
                                                Listings, Users and Reservations
                                                for their Team only.
                                            </ListItem>
                                            <ListItem>
                                                {' '}
                                                User: Add/Edit/View Reservations
                                                for themselves & view listings
                                            </ListItem>
                                            {loggedInUser?.role.includes(
                                                'guest'
                                            ) ? (
                                                <ListItem>
                                                    View Only User: View
                                                    listings & view reservations
                                                    made for them
                                                </ListItem>
                                            ) : null}
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <DropdownFilter
                            id={'userAccess'}
                            options={
                                loggedInUser?.role === 'admin'
                                    ? [{ value: 'admin', label: 'Admin' }]
                                    : loggedInUser.role.includes('guest')
                                    ? loggedInUser?.role ===
                                      'guest_department_admin'
                                        ? [
                                              {
                                                  value: 'user',
                                                  label: 'User',
                                                  isDefault: true,
                                              },
                                              {
                                                  value: 'viewOnlyUser',
                                                  label: 'View Only User',
                                              },
                                          ]
                                        : [
                                              {
                                                  value: 'admin',
                                                  label: 'Company Admin',
                                              },
                                              {
                                                  value: 'teamAdmin',
                                                  label: 'Team Admin',
                                              },
                                              {
                                                  value: 'user',
                                                  label: 'User',
                                                  isDefault: true,
                                              },
                                              {
                                                  value: 'viewOnlyUser',
                                                  label: 'View Only User',
                                              },
                                          ]
                                    : [
                                          { value: 'admin', label: 'Admin' },
                                          {
                                              value: 'user',
                                              label: 'User',
                                              isDefault: true,
                                          },
                                          {
                                              value: 'viewOnlyUser',
                                              label: 'View Only User',
                                          },
                                      ]
                            }
                            handleChange={handleRole}
                            selectedValue={
                                role === 'admin' || role === 'owner_admin'
                                    ? 'Admin'
                                    : role === 'guest_admin'
                                    ? 'Company Admin'
                                    : role === 'guest_department_admin'
                                    ? 'Team Admin'
                                    : viewOnly
                                    ? 'View Only User'
                                    : 'User'
                            }
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        {' '}
                        <Grid sx={{ marginRight: '5px' }}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Status
                                {status === '' ? (
                                    <span
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        *
                                    </span>
                                ) : null}{' '}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Tooltip
                                title={
                                    <Typography
                                        // color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        <List>
                                            <ListItem>
                                                {' '}
                                                Enabled: Users can access the
                                                site and perform actions based
                                                on their level of access
                                            </ListItem>
                                            <ListItem>
                                                {' '}
                                                Disabled: Users will not be able
                                                to access the site
                                            </ListItem>
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    >
                        <DropdownFilter
                            id={'userStatus'}
                            options={[
                                { value: 'enabled', label: 'Enabled' },
                                {
                                    value: 'disabled',
                                    label: 'Disabled',
                                    isDefault: true,
                                },
                            ]}
                            handleChange={handleStatus}
                            selectedValue={status}
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Last Login
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            disabled
                            fullWidth={isMobile || isIpad}
                            value={
                                userDetails?.lastLoginAt
                                    ? `${moment(
                                          userDetails?.lastLoginAt
                                      ).format('DD/MM/YYYY')} - ${moment(
                                          userDetails?.lastLoginAt
                                      ).format('h:mm a')}`
                                    : '-'
                            }
                            color='neutral'
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '20%',
                            height: isMobile || isIpad ? '25px' : '50px',
                        }}
                    ></Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: isMobile || isIpad ? '60px' : '50px',
                        }}
                    ></Grid>
                </Grid>
            </Grid>

            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '45%',
                }}
            >
                <Typography
                    component='h4'
                    // variant='h6'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        fontWeight: 900,
                        paddingTop: isMobile
                            ? '10px'
                            : isIpad
                            ? '10px'
                            : '30px',
                        height: '10%',
                    }}
                >
                    Users Login
                </Typography>

                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        width: '100%',
                        paddingTop: '30px',
                    }}
                >
                    <Grid
                        sx={{
                            width: '20%',
                            height: '50px',
                        }}
                    >
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Email
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                        }}
                    >
                        <CustomTextField
                            error={isDuplicateEmail}
                            label={
                                isDuplicateEmail === true
                                    ? 'Error: User already exists'
                                    : ''
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={!isDuplicateEmail ? emailAddress : null}
                            placeholder='john.smith@abc.com'
                            type='email'
                            onChange={handleEmail}
                            fullWidth={isMobile || isIpad}
                            color={isValidEmail ? 'green' : 'neutral'}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: '400px',
                                },
                            }}
                        />
                    </Grid>

                    <Grid sx={{ width: '20%', height: '50px' }}></Grid>
                    <Grid sx={{ width: '30%', height: '50px' }}></Grid>
                </Grid>

                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: '100%',
                        height:
                            password === '' && isMobile
                                ? '0px'
                                : isIpad
                                ? '180px'
                                : '200px',
                    }}
                >
                    <>
                        {' '}
                        <Grid
                            sx={{
                                width:
                                    password !== '' && isIpad ? '100%' : '20%',
                                height: '50px',
                            }}
                        >
                            {' '}
                            {(password !== '' && isMobile) ||
                            (password !== '' && isIpad) ? (
                                <Grid
                                    sx={{
                                        width:
                                            isMobile || isIpad ? '100%' : '30%',
                                        height: '50px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '10px',
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {' '}
                                        Password must:
                                        <List>
                                            <ListItem>
                                                {pwLengthValidation === true ? (
                                                    <DoneIcon
                                                        sx={{
                                                            fontSize: '12px',
                                                            paddingRight: '5px',
                                                            color: 'green',
                                                        }}
                                                    />
                                                ) : (
                                                    <CloseIcon
                                                        sx={{
                                                            fontSize: '12px',
                                                            paddingRight: '5px',
                                                            color: 'red',
                                                        }}
                                                    />
                                                )}
                                                Be a minimum of 8 characters
                                            </ListItem>
                                            <ListItem>
                                                {lowerCaseValidation ===
                                                true ? (
                                                    <DoneIcon
                                                        sx={{
                                                            fontSize: '12px',
                                                            paddingRight: '5px',
                                                            color: 'green',
                                                        }}
                                                    />
                                                ) : (
                                                    <CloseIcon
                                                        sx={{
                                                            fontSize: '12px',
                                                            paddingRight: '5px',
                                                            color: 'red',
                                                        }}
                                                    />
                                                )}
                                                Include at least one lowercase
                                                letter (a-z)
                                            </ListItem>
                                            <ListItem>
                                                {upperCaseValidation ===
                                                true ? (
                                                    <DoneIcon
                                                        sx={{
                                                            fontSize: '12px',
                                                            paddingRight: '5px',
                                                            color: 'green',
                                                        }}
                                                    />
                                                ) : (
                                                    <CloseIcon
                                                        sx={{
                                                            fontSize: '12px',
                                                            paddingRight: '5px',
                                                            color: 'red',
                                                        }}
                                                    />
                                                )}
                                                Include at least one uppercase
                                                letter (A-Z)
                                            </ListItem>
                                            <ListItem>
                                                {numberValidation === true ? (
                                                    <DoneIcon
                                                        sx={{
                                                            fontSize: '12px',
                                                            paddingRight: '5px',
                                                            color: 'green',
                                                        }}
                                                    />
                                                ) : (
                                                    <CloseIcon
                                                        sx={{
                                                            fontSize: '12px',
                                                            paddingRight: '5px',
                                                            color: 'red',
                                                        }}
                                                    />
                                                )}
                                                Include at least one number
                                                (0-9)
                                            </ListItem>
                                            <ListItem>
                                                {characterValidation ===
                                                true ? (
                                                    <DoneIcon
                                                        sx={{
                                                            fontSize: '12px',
                                                            paddingRight: '5px',
                                                            color: 'green',
                                                        }}
                                                    />
                                                ) : (
                                                    <CloseIcon
                                                        sx={{
                                                            fontSize: '12px',
                                                            paddingRight: '5px',
                                                            color: 'red',
                                                        }}
                                                    />
                                                )}
                                                Include at least one special
                                                character (!@#?&)
                                            </ListItem>
                                        </List>
                                    </Typography>
                                </Grid>
                            ) : null}
                        </Grid>
                        <Grid
                            sx={{
                                width: '30%',
                                height: '50px',
                                marginRight:
                                    !isMobile || !isIpad ? '50px' : '0px',
                            }}
                        >
                            {isValidEmail === true && !isIpad ? (
                                <Typography
                                    sx={{
                                        fontSize: '10px',
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    <List>
                                        <ListItem>
                                            <CloseIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'red',
                                                }}
                                            />{' '}
                                            Invalid email
                                        </ListItem>{' '}
                                    </List>{' '}
                                </Typography>
                            ) : null}
                        </Grid>
                        <Grid
                            sx={{
                                width: '20%',
                                height: '50px',
                            }}
                        ></Grid>
                    </>

                    {password !== '' && !isIpad ? (
                        <Grid
                            sx={{
                                width: isMobile || isIpad ? '100%' : '30%',
                                height: '50px',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '10px',
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                {' '}
                                Password must:
                                <List>
                                    <ListItem>
                                        {pwLengthValidation === true ? (
                                            <DoneIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'green',
                                                }}
                                            />
                                        ) : (
                                            <CloseIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'red',
                                                }}
                                            />
                                        )}
                                        Be a minimum of 8 characters
                                    </ListItem>
                                    <ListItem>
                                        {lowerCaseValidation === true ? (
                                            <DoneIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'green',
                                                }}
                                            />
                                        ) : (
                                            <CloseIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'red',
                                                }}
                                            />
                                        )}
                                        Include at least one lowercase letter
                                        (a-z)
                                    </ListItem>
                                    <ListItem>
                                        {upperCaseValidation === true ? (
                                            <DoneIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'green',
                                                }}
                                            />
                                        ) : (
                                            <CloseIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'red',
                                                }}
                                            />
                                        )}
                                        Include at least one uppercase letter
                                        (A-Z)
                                    </ListItem>
                                    <ListItem>
                                        {numberValidation === true ? (
                                            <DoneIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'green',
                                                }}
                                            />
                                        ) : (
                                            <CloseIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'red',
                                                }}
                                            />
                                        )}
                                        Include at least one number (0-9)
                                    </ListItem>
                                    <ListItem>
                                        {characterValidation === true ? (
                                            <DoneIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'green',
                                                }}
                                            />
                                        ) : (
                                            <CloseIcon
                                                sx={{
                                                    fontSize: '12px',
                                                    paddingRight: '5px',
                                                    color: 'red',
                                                }}
                                            />
                                        )}
                                        Include at least one special character
                                        (!@#?&)
                                    </ListItem>
                                </List>
                            </Typography>
                        </Grid>
                    ) : null}
                </Grid>

                <Grid
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        height: 'auto',
                        paddingTop: '50px',
                        paddingBottom: '50px',
                    }}
                >
                    <Grid
                        sx={{
                            width: '50%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {' '}
                        <CustomButton
                            onClick={onClickGoBack}
                            color='green'
                            sx={{
                                marginLeft: !isMobile ? '-8px' : '-18px',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#5cb5b3',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <ArrowBackIcon />{' '}
                            {isMobile ? null : (
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                        paddingLeft: '5px',
                                    }}
                                >
                                    Back to users
                                </Typography>
                            )}
                        </CustomButton>
                    </Grid>
                    <Grid
                        sx={{
                            width: '50%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <CustomButton
                            variant='contained'
                            color='green'
                            fullWidth={isMobile}
                            onClick={saveEditUser}
                            disabled={
                                isValidInput ||
                                isValidEmail ||
                                isValidPhone ||
                                isDuplicateEmail ||
                                errors.length > 0
                            }
                            sx={{
                                top: isMobile || isIpad ? '10%' : '50%',
                            }}
                        >
                            Save Changes
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                message={modalMessage}
                component={modalComponent}
            />
        </Grid>
    );
}

import { useSelector } from 'react-redux';
import {
    CreateToastOptions,
    openSnackAction,
    removeToast,
    selectSnackBars,
    Toast,
    ToastType,
    updateToast,
} from '../../app/Slices/SnackBarSlice';
import { store } from '../../app/Slices/store';

let toasts: ReturnType<typeof selectSnackBars> = [];

export const useToastUpdater = () => {
    toasts = useSelector(selectSnackBars);
};

const toast = {
    success: (message: string, options?: CreateToastOptions) =>
        store.dispatch(
            openSnackAction({ type: ToastType.SUCCESS, message, options })
        ),
    error: (message: string, options?: CreateToastOptions) =>
        store.dispatch(
            openSnackAction({ type: ToastType.ERROR, message, options })
        ),
    info: (message: string, options?: CreateToastOptions) =>
        store.dispatch(
            openSnackAction({ type: ToastType.INFO, message, options })
        ),
    warning: (message: string, options?: CreateToastOptions) =>
        store.dispatch(
            openSnackAction({ type: ToastType.WARNING, message, options })
        ),

    remove: (id: string) => {
        if (toasts.find((toast: Toast) => toast.id === id)) {
            store.dispatch(removeToast(id));
        }
    },
    update: (id: string, updates: Partial<Toast>) => {
        store.dispatch(updateToast({ id, updates }));
    },
};

export default toast;

import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import NewClientForm from '../../components/ClientManagement/Client Forms/NewClientForm';
import { useNavigate } from 'react-router-dom';
import EditClientForm from '../../components/ClientManagement/Client Forms/EditClientForm';
import { CustomSmallListChip } from '../../../layout/styledcomponents/CustomChip';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useGetIndividualClientQuery } from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import ViewIndividualClient from '../../components/ClientManagement/ViewIndividualClient';
import ConfirmationModal from '../../shared/ConfirmationModal';
import { useEffect, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React from 'react';

export default function ClientFormPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();
    const [url, setUrl] = useState('');
    const urlRef = window.location.href.split('/')[5];
    const { data: getIndividualClient, isFetching } =
        useGetIndividualClientQuery(url);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (urlRef !== undefined) {
            setUrl(`/owners/${urlRef}`);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const editClient = () => {
        navigate(
            `/editClient/${getIndividualClient?.name}/${getIndividualClient?.id}`,
            {
                state: {
                    name: 'editClient',
                },
            }
        );
    };

    const onClickOpenModal = () => {
        setOpen(true);
    };
    const handleChangeModal = () => {
        setOpen(false);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile || isIpad ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {isFetching === true ? (
                <Grid
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '2%' : '5%',
                            justifyContent: 'left',
                            display: 'flex',
                        }}
                    >
                        {window.location.href?.includes('newClient') ? (
                            <Typography
                                component='h1'
                                variant='h5'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textAlign: 'left',
                                    textTransform: 'uppercase',
                                }}
                            >
                                New Client
                            </Typography>
                        ) : window.location.href?.includes('client') ? (
                            <>
                                <Typography
                                    component='h1'
                                    variant='h5'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        textAlign: 'left',
                                        textTransform: 'uppercase',
                                        fontWeight:
                                            window.location.href?.includes(
                                                'client'
                                            )
                                                ? '550'
                                                : 'none',
                                        paddingRight: '15px',
                                    }}
                                >
                                    {' '}
                                    {getIndividualClient?.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    <CustomSmallListChip
                                        size='small'
                                        style={{
                                            fontFamily: 'Open Sans',
                                            border: 0,
                                            backgroundColor:
                                                getIndividualClient?.isActive ===
                                                true
                                                    ? '#5cb5b3'
                                                    : '#E0E0E0',
                                            color:
                                                getIndividualClient?.isActive ===
                                                true
                                                    ? '#fff'
                                                    : '#787878',
                                        }}
                                        label={
                                            getIndividualClient?.isActive ===
                                            true
                                                ? 'Enabled'
                                                : 'Disabled'
                                        }
                                        variant='outlined'
                                    />
                                </Typography>
                            </>
                        ) : (
                            <Typography
                                component='h1'
                                variant='h5'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textAlign: 'left',
                                    textTransform: 'uppercase',
                                }}
                            >
                                Edit Client
                            </Typography>
                        )}
                    </Grid>
                    <Grid sx={{ width: '100%', textAlign: 'right' }}>
                        {window.location.href?.includes('editClient') ||
                        window.location.href?.includes('newClient') ? null : (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Grid>
                                    {' '}
                                    <CustomIconButton
                                        disableRipple
                                        sx={{
                                            backgroundColor: '#fff',
                                            width: '25px',
                                            height: '25px',
                                            paddingLeft: '20px',
                                        }}
                                        onClick={editClient}
                                    >
                                        <EditIcon
                                            fontSize='small'
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    color: '#5cb5b3',
                                                    backgroundColor:
                                                        'transparent',
                                                    background: 'none',
                                                },
                                            }}
                                        />
                                    </CustomIconButton>
                                </Grid>

                                <Grid>
                                    <CustomIconButton
                                        disableRipple
                                        sx={{
                                            backgroundColor: '#fff',
                                            width: '25px',
                                            height: '25px',
                                        }}
                                        onClick={onClickOpenModal}
                                    >
                                        <DeleteOutlineIcon
                                            fontSize='small'
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    color: 'red',
                                                    backgroundColor:
                                                        'transparent',
                                                    background: 'none',
                                                },
                                            }}
                                        />
                                    </CustomIconButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    {window.location.href?.includes('newClient') ? (
                        <NewClientForm />
                    ) : window.location.href?.includes('editClient') ? (
                        <EditClientForm client={getIndividualClient} />
                    ) : (
                        <ViewIndividualClient />
                    )}
                </Grid>
            )}
            <ConfirmationModal
                open={open}
                onClose={handleCloseModal}
                onChange={handleChangeModal}
                message={'delete this user'}
                component={'client'}
                id={getIndividualClient?.id}
            />
        </Container>
    );
}

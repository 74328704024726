import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { ModalProps } from '../../../types/modal'

interface Props extends ModalProps {
    onSubmit: () => void
}

const CancelModal = ({ open, onClose, onSubmit }: Props) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
                <Alert severity='warning'>
                    You have unsaved changes. Are you sure you want to leave?
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={onSubmit}>
                    Leave
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CancelModal
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGetIndividualOwnerAssetsQuery } from '../../../../app/Slices/SevenDayApiSlice';

import UserRole from '../../../../shared/AuthRoles';
import { selectExperienceFilters } from '../../../../app/Slices/ExperienceFiltersSlice';
import { useSelector } from 'react-redux';
import { capitalizeName, filterItemsBySearch } from '../../../../shared/utils';
import {
    selectEndDate,
    selectStartDate,
} from '../../../../app/Slices/DateFilterSlice';
import { filterItemsByDateRange } from '../ExperiencesListAllComponent';
import { selectExperienceSearchFilter } from '../../../../app/Slices/ExperienceSearchSlice';
import { useGetAllPublicHolidaysForYearAndCountryQuery } from '../../../../app/Slices/PublicHolidaysSlice';
import ExperienceListLoadingComponent from '../../../../layout/styledcomponents/ExperienceListLoadingComponent';
import ImageSlider from '../../../../layout/styledcomponents/CustomImageSlider/ImageSlider';

export default function ExperiencesListOwnerComponent({ visitType = '' }) {
    const role = UserRole();
    const ExperienceFilters = useSelector(selectExperienceFilters);
    const { data, isFetching } = useGetIndividualOwnerAssetsQuery({
        url: `owners/${role?.company}/modules`,
        ...ExperienceFilters,
        ...(visitType ? { visitType } : {}),
        sort: 'name',
    });

    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const searchTerm = useSelector(selectExperienceSearchFilter);
    const { data: publicHolidaysAustraliaCurrentYear } =
        useGetAllPublicHolidaysForYearAndCountryQuery({
            country: 'AU',
            year: new Date().getFullYear(),
        });
    const { data: publicHolidaysAustraliaNextYear } =
        useGetAllPublicHolidaysForYearAndCountryQuery({
            country: 'AU',
            year: new Date().getFullYear() + 1,
        });
    const { data: publicHolidaysAustraliaYearAfterNextYear } =
        useGetAllPublicHolidaysForYearAndCountryQuery({
            country: 'AU',
            year: new Date().getFullYear() + 2,
        });

    const publicHolidaysAustralia =
        publicHolidaysAustraliaCurrentYear &&
        publicHolidaysAustraliaNextYear &&
        publicHolidaysAustraliaYearAfterNextYear
            ? [
                  ...publicHolidaysAustraliaCurrentYear,
                  ...publicHolidaysAustraliaNextYear,
                  ...publicHolidaysAustraliaYearAfterNextYear,
              ]
            : [];

    // const handleOpen = (experience: any) => {
    //     navigate(
    //         `/viewExperiences/${
    //             role?.company
    //         }/${experience?.type.toLowerCase()}/${experience?.id}`,
    //         { state: experience }
    //     );
    // };
    const allAssets =
        data && publicHolidaysAustralia
            ? filterItemsByDateRange(
                  data,
                  startDate,
                  endDate,
                  publicHolidaysAustralia
              )
            : undefined;
    const filteredAssets = allAssets
        ? filterItemsBySearch(allAssets, searchTerm)
        : undefined;

    const AvailableBadge = (experience: any) => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 97,
                    left: 8,
                    backgroundColor:
                        experience?.availability === true ? '#5cb5b3' : 'red',
                    color: 'white',
                    padding: '4px 10px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    zIndex: 1,
                    letterSpacing: '0.5px',
                    // Responsive styling
                    '@media (max-width: 1200px)': {
                        bottom: 95,
                        left: 6,
                    },
                    '@media (max-width: 770px)': {
                        bottom: 95,
                        left: 6,
                    },
                }}
            >
                {experience?.availability === true
                    ? 'Available'
                    : 'Unavailable'}
            </Box>
        );
    };

    return (
        <Grid
            container
            spacing={4}
            columns={60}
            sx={{
                flexGrow: 1,
                paddingTop: '30px',
                paddingBottom: '20px',
                height: '100%',
                width: '100%',
            }}
        >
            {filteredAssets?.length === 0 ? (
                <Grid
                    sx={{
                        height: '100%',
                        paddingTop: '50px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    <img
                        alt='noresults'
                        src='/noResults.png'
                        style={{
                            width: '900px',
                            height: '500px',
                        }}
                    />
                    <Typography sx={{ fontFamily: 'Open Sans' }}>
                        Your query did not return any results
                    </Typography>
                </Grid>
            ) : isFetching === true || filteredAssets === undefined ? (
                <ExperienceListLoadingComponent />
            ) : (
                filteredAssets.map((experience: any) => (
                    <Grid
                        key={experience.name}
                        item
                        xs={60}
                        sm={30}
                        md={20}
                        lg={15}
                        xl={12}
                        sx={{ textDecoration: 'none' }}
                    >
                        {' '}
                        <Box
                            component={Link}
                            to={`/viewExperiences/${
                                role?.company
                            }/${experience?.type.toLowerCase()}/${
                                experience?.id
                            }`}
                            state={experience}
                            // onClick={() => handleOpen(experience)}
                            sx={{ textDecoration: 'none' }}
                        >
                            <Card
                                key={experience.name}
                                variant='outlined'
                                sx={{
                                    width: 'auto',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between',
                                    boxShadow: 0,
                                    background: 'none',
                                    backgroundColor: 'transparent',
                                    borderRadius: '8px',
                                    color: 'none',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#fff',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                    '&:focus': {
                                        cursor: 'pointer',
                                        color: '#fff',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                }}
                            >
                                <CardActionArea
                                    sx={{
                                        background: 'none',
                                        backgroundColor: 'transparent',
                                        color: '#fff',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: '#fff',
                                            backgroundColor: 'transparent',
                                            background: 'none',
                                        },
                                        '&:focus': {
                                            cursor: 'pointer',
                                            color: '#fff',
                                            backgroundColor: 'transparent',
                                            background: 'none',
                                        },
                                    }}
                                >
                                    {experience.photos.length === 0 ? (
                                        <CardMedia
                                            component='img'
                                            image={
                                                '/experiences/placeholder.jpg'
                                            }
                                            alt={'no image'}
                                            sx={{
                                                aspectRatio: 1 / 1,
                                            }}
                                        />
                                    ) : experience.photos.length === 1 ? (
                                        <>
                                            {experience.photos.map(
                                                (image: any) => (
                                                    <CardMedia
                                                        component='img'
                                                        image={image.src}
                                                        alt={image.id}
                                                        sx={{
                                                            aspectRatio: 1 / 1,
                                                        }}
                                                    />
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <ImageSlider
                                            images={experience.photos}
                                        />
                                    )}

                                    <CardContent
                                        // onClick={() => handleOpen(experience)}
                                        sx={{ padding: '15px' }}
                                    >
                                        <Grid>
                                            {' '}
                                            <Typography
                                                gutterBottom
                                                variant='h5'
                                                component='div'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    fontWeight: 'bold',
                                                    color: '#787878',
                                                    fontSize: '16px',
                                                    '&:hover': {
                                                        color: '#5cb5b3',
                                                    },
                                                    // Responsive styling
                                                    '@media (max-width: 1200px)':
                                                        {
                                                            fontSize: '16px',
                                                        },
                                                    '@media (max-width: 770px)':
                                                        {
                                                            fontSize: '16px',
                                                        },
                                                }}
                                            >
                                                {capitalizeName(
                                                    experience.name
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '14px',
                                            }}
                                        >
                                            {capitalizeName(
                                                experience?.type ===
                                                    'commercial' &&
                                                    experience?.details
                                                        ?.subType === 'house'
                                                    ? 'home office'
                                                    : experience?.details
                                                          ?.subType
                                            )}{' '}
                                            - {capitalizeName(experience?.type)}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {capitalizeName(experience?.suburb)}
                                            ,{' '}
                                            {capitalizeName(experience?.state)}
                                        </Typography>

                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '10px',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {' '}
                                            Visit Type: (
                                            {capitalizeName(
                                                experience.visitType
                                            )}
                                            )
                                        </Typography>
                                    </CardContent>

                                    <AvailableBadge
                                        availability={experience.isAvailable}
                                    />
                                </CardActionArea>
                            </Card>
                        </Box>
                    </Grid>
                ))
            )}
        </Grid>
    );
}

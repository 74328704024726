import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface Make {
    MakeId: number;
    MakeName: string;
    VehicleTypeId: number;
    VehicleTypeName: string;
}

interface Model {
    Model_Id: number;
    Model_Name: string;
}

interface ApiResponse<T> {
    Results: T[];
}

const BASE_URL = 'https://vpic.nhtsa.dot.gov/api/vehicles';

export const vehicleApi = createApi({
    reducerPath: 'vehicleApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        getAllMakesForVehicleType: builder.query<Make[], string>({
            query: (vehicleType) =>
                `/GetMakesForVehicleType/${vehicleType}?format=json`,
            transformResponse: (response: unknown) => {
                const data = response as ApiResponse<Make>;
                return data.Results.sort((a, b) =>
                    a.MakeName.localeCompare(b.MakeName)
                );
            },
        }),
        getModelsForMake: builder.query<
            Model[],
            { make: string; vehicleTypeForAPI: string }
        >({
            query: ({ make, vehicleTypeForAPI }) =>
                `/getmodelsformakeyear/make/${make}/vehicleType/${vehicleTypeForAPI}?format=json`,
            transformResponse: (response: unknown) => {
                const data = response as ApiResponse<Model>;
                return data.Results;
            },
        }),
    }),
});

export const { useGetAllMakesForVehicleTypeQuery, useGetModelsForMakeQuery } =
    vehicleApi;

import EditIcon from '@mui/icons-material/Edit';
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetBookingCapListQuery } from '../../../app/Slices/SevenDayApiSlice';
import { capitalizeName } from '../../../shared/utils';

export interface BookingCaps {
    id: number;
    name: string;
    yearlyCap: number;
    moduleTypes: string[];
    modules: string[];
    visitType: string;
    guests: string[];
    excludedUsers: string[];
    appliesTo: string;
}

const BookingCapList = () => {
    const MAX_DESCRIPTION_LENGTH: number = 18;

    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const { data: getBookingCaps } = useGetBookingCapListQuery('/booking-caps');
    const bookingCaps: BookingCaps[] = getBookingCaps ?? [];


    const truncateHeading = (heading?: string) => {
        if (!heading) return '';

        if (heading.length > MAX_DESCRIPTION_LENGTH) {
            return heading.substring(0, MAX_DESCRIPTION_LENGTH - 3) + '...';
        } else {
            return heading;
        }
    };

    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: bookingCaps?.length === 0 ? 'column' : 'row',
            }}
            container
            spacing={2}
        >
            {bookingCaps?.length === 0 && (
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: '150px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                        }}
                    >
                        No Booking Caps
                    </Typography>{' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '12px',
                            textAlign: 'center',
                        }}
                    >
                        No booking caps have been created
                    </Typography>
                </Grid>
            )}
            {bookingCaps?.map((cap) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={2}
                    key={cap.id}
                    style={{ display: 'flex' }}
                >
                    {' '}
                    <Card
                        sx={{
                            boxShadow: 1,
                            background: 'none',
                            minHeight: isMobile ? '50px' : '150px',
                            backgroundColor: 'transparent',
                            color: 'none',
                            cursor: 'pointer',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                            '&:focus': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                        onClick={() => {
                            navigate(`/bookingCap/${cap.id}/edit`);
                        }}
                        style={{ flex: 1 }}
                    >
                        <CardActionArea
                            sx={{
                                background: 'none',
                                backgroundColor: 'transparent',
                                color: '#fff',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                                '&:focus': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    backgroundColor: '#f6f8f9',
                                }}
                            >
                                <Grid>
                                    <Typography
                                        gutterBottom
                                        component='div'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            '&:hover': {
                                                color: '#5cb5b3',
                                            },

                                            padding: '0px 5px 0px 0px',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <EditIcon
                                            fontSize='small'
                                            sx={{ fontSize: '16px' }}
                                        />
                                    </Typography>
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '80px',
                                        }}
                                    >
                                        <Typography
                                            variant='h6'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#5cb5b3',
                                                // fontSize: isIpad ? '16px':''
                                            }}
                                        >
                                        {truncateHeading(capitalizeName(cap?.name))}      
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '10px',
                                                paddingLeft: '2px',
                                            }}
                                        >
                                            ({cap?.yearlyCap} bookings / per{' '}
                                            {cap?.appliesTo})
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            </Grid>
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '15px',
                                }}
                            >
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    {' '}
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '12px',
                                        }}
                                    >
                                        Applied to {cap.modules.length}{' '}
                                        Experiences and {cap.guests.length}{' '}
                                        {cap.guests.length === 1
                                            ? 'company'
                                            : 'companies'}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default BookingCapList;

import {
    Grid,
    InputAdornment,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useGetProfileDetailsQuery } from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../layout/styledcomponents/CustomTextField';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';

//Support@7-DAY.COM
const SERVICE_ID = 'service_r1upc8p';
const TEMPLATE_ID = 'template_o3f6y9h';
const USER_ID = 'BYnD6lRrcDdjsaoXt';

export default function SupportComponentPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const [submitting, setSubmitting] = useState(false);

    const { data: profileDetails, isFetching } =
        useGetProfileDetailsQuery('/profile');

    const [values, setValues] = useState({
        firstName: profileDetails?.firstName,
        lastName: profileDetails?.lastName,
        phone: profileDetails?.phone,
        company: profileDetails?.companyName,
        email: profileDetails?.email,
        comment: '',
    });

    //Validation Check
    const validationCheck = Object.values(values).includes('');

    const handleFirstNameInputChange = (event: any) => {
        event.persist();
        setValues((values) => ({
            ...values,
            firstName: event.target.value,
        }));
    };

    const handleLastNameInputChange = (event: any) => {
        event.persist();
        setValues((values) => ({
            ...values,
            lastName: event.target.value,
        }));
    };

    const handleCompanyInputChange = (event: any) => {
        event.persist();
        setValues((values) => ({
            ...values,
            company: event.target.value,
        }));
    };

    const handlePhoneInputChange = (event: any) => {
        event.persist();
        setValues((values) => ({
            ...values,
            phone: event.target.value,
        }));
    };

    const handleEmailInputChange = (event: any) => {
        event.persist();
        setValues((values) => ({
            ...values,
            email: event.target.value,
        }));
    };

    const handleCommentInputChange = (event: any) => {
        event.persist();
        setValues((values) => ({
            ...values,
            comment: event.target.value,
        }));
    };

    const handleSubmit = (e: any) => {
        setSubmitting(true);
        e.preventDefault();

        emailjs
            .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
            .then((result: any) => {
                setSubmitting(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Your message has been sent, we will respond shortly.',
                    iconColor: theme.palette.primary.main,
                    confirmButtonColor: theme.palette.primary.main,
                });
                e.target.reset();
            })
            .catch((error) => {
                setSubmitting(false);
                console.error(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: 'We were unable to send your message. Please try again later or send us an email at support@7-day.com.',
                    iconColor: theme.palette.error.main,
                    confirmButtonColor: theme.palette.error.main,
                });
            });
    };

    return (
        <Grid sx={{ marginTop: '30px' }}>
            <Grid sx={{ paddingLeft: '0px' }}>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Need help? Email us at {''}
                        <Link
                            style={{
                                color: '#787878',
                                textDecoration: 'underline',
                            }}
                            to='#'
                            // @ts-ignore
                            onClick={() =>
                                // @ts-ignore
                                (window.location = 'mailto: support@7-day.com')
                            }
                        >
                            support@7-day.com
                        </Link>{' '}
                        or complete the below form.
                    </Typography>
                </Grid>

                {isFetching === true ? (
                    <Grid
                        // columnSpacing={{ xs: 1, sm: 2, md: 3 }}

                        sx={{
                            height: isIpad ? '50vh' : isMobile ? '5px' : '50vh',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <LoadingComponent color='green' />
                    </Grid>
                ) : (
                    <Grid sx={{ width: '100%' }}>
                        <form onSubmit={handleSubmit}>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    // backgroundColor: 'red',
                                }}
                            >
                                <Grid sx={{ width: '50%' }}>
                                    <CustomTextField
                                        label='First'
                                        name='user_first'
                                        fullWidth
                                        color='neutral'
                                        value={values.firstName}
                                        onChange={handleFirstNameInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'></InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid sx={{ width: '50%', paddingLeft: '5px' }}>
                                    <CustomTextField
                                        label='Last'
                                        name='user_last'
                                        fullWidth
                                        color='neutral'
                                        // sx={{ paddingLeft: '10px' }}
                                        value={values.lastName}
                                        onChange={handleLastNameInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'></InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    // backgroundColor: 'red',
                                    paddingTop: '20px',
                                }}
                            >
                                <Grid sx={{ width: '50%' }}>
                                    <CustomTextField
                                        label='Company'
                                        name='user_company'
                                        fullWidth
                                        color='neutral'
                                        value={values.company}
                                        onChange={handleCompanyInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'></InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid sx={{ width: '50%', paddingLeft: '5px' }}>
                                    <CustomTextField
                                        label='Phone'
                                        name='user_phone'
                                        fullWidth
                                        color='neutral'
                                        // sx={{ paddingLeft: '10px' }}
                                        value={values.phone}
                                        onChange={handlePhoneInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'></InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                sx={{
                                    paddingTop: '20px',
                                    width: '100%',
                                    // backgroundColor: 'blue',
                                }}
                            >
                                <CustomTextField
                                    label='Email'
                                    name='user_email'
                                    value={values.email}
                                    color='neutral'
                                    fullWidth
                                    onChange={handleEmailInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'></InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid sx={{ paddingTop: '20px' }}>
                                <CustomTextField
                                    label='Message'
                                    name='user_message'
                                    // value={values.comment}
                                    color='neutral'
                                    fullWidth
                                    onChange={handleCommentInputChange}
                                    multiline
                                    rows={4}
                                    // maxRows={4}
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'></InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <CustomButton
                                sx={{
                                    marginTop: '20px',
                                    marginLeft: '10px',
                                    fontFamily: 'Open Sans',
                                }}
                                disable={validationCheck === true}
                                variant='contained'
                                color='green'
                                type='submit'
                                loading={submitting}
                            >
                                Submit
                            </CustomButton>
                        </form>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

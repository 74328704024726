import React, { useEffect, useRef, useState } from 'react';
import { ModalProps } from '../../../types/modal';
import GenericModal from '../../../shared/GenericModal';
import {
    Alert,
    Box,
    Checkbox,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { camelCaseToTitleCase } from '../../../../shared/utils';
import FormTextField from '../../../shared/FormTextField';

import {
    FormProvider,
    useFieldArray,
    useForm,
    useWatch,
} from 'react-hook-form';
import FormAutocomplete from '../../../shared/FormAutocomplete';
import FormSelect from '../../../shared/FormSelect';
import FormMultiAutocomplete from '../../../shared/FormMultiAutocomplete';
import { ExperienceCharge } from '../../../types/experience';
import { zodResolver } from '@hookform/resolvers/zod';
import { BasePriceSchema } from '../Owner/schema';
import { getFeeId } from './ExperienceChargesForm';
import {
    selectPriceRequired,
    setPriceRequired,
} from '../../../../app/Slices/PriceRequiredSlice';
import { useDispatch, useSelector } from 'react-redux';

export type Pricing = {
    prices: Array<ExperienceCharge>;
};

interface Props extends ModalProps {
    experienceType: string;
    availableSpaces: any[];
    prices: ExperienceCharge[];
    onSubmit: (data: Pricing) => void;
    isEditing: boolean;
}

enum FeeType {
    Fixed = 'fixed',
    PerHour = 'perHour',
    PerDay = 'perDay',
    PerNight = 'perNight',
    PerHourPerGuest = 'perHourPerGuest',
    PerDayPerGuest = 'perDayPerGuest',
    PerNightPerGuest = 'perNightPerGuest',
    PerDeskPerDay = 'perDeskPerDay',
    PerOfficePerDay = 'perOfficePerDay',
    PerMeetingRoomPerDay = 'perMeetingRoomPerDay',
    EntireVenuePerDay = 'entireVenuePerDay',
    EntireVenuePerNight = 'entireVenuePerNight',
}

const getType = (id: string) => {
    switch (id) {
        case 'priceDesk':
            return FeeType.PerDeskPerDay;
        case 'priceMeetingRoom':
            return FeeType.PerMeetingRoomPerDay;
        case 'priceOffice':
            return FeeType.PerOfficePerDay;
        default:
            return FeeType.EntireVenuePerDay;
    }
};

const BasePriceModal = ({
    experienceType,
    availableSpaces,
    open,
    prices,
    onClose,
    onSubmit,
    isEditing,
}: Props) => {
    const isMobile = useMediaQuery('(max-width: 700px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const [cancel, setCancel] = useState<boolean>(false);

    const defaultValues: Pricing = {
        prices:
            experienceType !== 'commercial'
                ? [
                      {
                          feeId: '',
                          type: 'perDay',
                          unitPrice: 0,
                          chargeType: 'price',
                          weekendPrice: null,
                      },
                  ]
                : availableSpaces.map((space: any) => ({
                      feeId: space.feeId,
                      type: getType(space.feeId),
                      unitPrice: 0,
                      chargeType: 'price',
                  })),
    };

    useEffect(() => {
        reset(defaultValues);
    }, [experienceType, availableSpaces]);

    useEffect(() => {
        if (prices.length > 0) reset({ prices: prices });
    }, [prices, isEditing]);

    const methods = useForm<Pricing>({
        resolver: zodResolver(BasePriceSchema),
        defaultValues: defaultValues,
        // shouldUnregister: false
    });

    const {
        control,
        reset,
        handleSubmit,
        setValue,
        formState: { isDirty },
    } = methods;

    const { append } = useFieldArray({
        name: 'prices',
        control,
    });

    const weekendPrice = useWatch({ control, name: 'prices.0.weekendPrice' });

    const handleClearWeekendPrice = () => {
        setValue('prices.0.weekendPrice.unitPrice', 0);
        setValue('prices.0.weekendPrice.days', []);
    };

    useEffect(() => {
        if (experienceType !== 'commercial') return;
        // reorder prices to align with availableSpaces
        const syncedPrices: ExperienceCharge[] = availableSpaces.map((space: any) => {
            const existingPrice = prices.find((price) => price.chargeType !== 'flexiblePrice' && getFeeId(price.type) === space.feeId);

                return (
                    existingPrice || {
                        feeId: space.feeId,
                        type: getType(space.feeId),
                        unitPrice: 0,
                        chargeType: 'price',
                    }
                );
            }
        );
        // Reset the form with the updated prices
        reset({ prices: syncedPrices });
    }, [availableSpaces, prices, reset, experienceType]);

    const onNestedSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        if (event && typeof event.stopPropagation === 'function') {
            event.stopPropagation();
        }

        return handleSubmit(onSubmit)(event);
    };

    const handleCheck = () => {
        if (weekendPrice !== null) {
            setValue('prices.0.weekendPrice', null);
        } else {
            setValue('prices.0.weekendPrice.unitPrice', 0);
            setValue('prices.0.weekendPrice.days', []);
        }
    };

    // reset if experience type changes
    useEffect(() => {
        reset();
    }, [experienceType]);

    const handleCancel = () => {
        if (isDirty) {
            // if form is dirty, display alert
            if (!cancel) {
                setCancel(true);
                return;
            }
        }

        if (cancel) reset();

        setCancel(false);
        onClose();
    };

    return (
        <>
            <GenericModal
                open={open}
                onClose={onClose}
                message={'delete this Pricing & Fee type'}
            >
                <FormProvider {...methods}>
                    <form onSubmit={onNestedSubmit}>
                        {experienceType !== 'commercial' && (
                            <>
                                <Grid
                                    sx={{
                                        minWidth:
                                            isMobile || isIpad ? 0 : '700px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '20px',
                                        gap: '20px',
                                    }}
                                >
                                    <Stack spacing='3px'>
                                        <Box display='flex' gap={1}>
                                            <Typography
                                                color='textPrimary'
                                                fontWeight='bold'
                                            >
                                                Base Price
                                            </Typography>
                                            <Typography color='textPrimary'>
                                                AUD
                                            </Typography>
                                        </Box>
                                        <Typography
                                            color='textPrimary'
                                            fontSize='12px'
                                        >
                                            Applied to all dates unless you
                                            customise. <strong>Note:</strong>{' '}
                                            all prices are excluding GST.
                                        </Typography>
                                        <Collapse in={cancel}>
                                            <Alert severity='warning'>
                                                You have unsaved changes. Are
                                                you sure you want to cancel?
                                            </Alert>
                                        </Collapse>
                                    </Stack>
                                    <Grid
                                        container
                                        columnSpacing={2}
                                        rowSpacing={1}
                                    >
                                        <Grid item container xs={12} lg={6}>
                                            <Grid item xs={12} lg={4}>
                                                <InputLabel required>
                                                    Amount
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <FormTextField
                                                    name='prices.0.unitPrice'
                                                    type='number'
                                                    control={control}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>
                                                                $
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} lg={6}>
                                            <Grid item xs={12} lg={4}>
                                                <InputLabel required>
                                                    Type
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} lg={8}>
                                                <FormSelect
                                                    name='prices.0.type'
                                                    control={control}
                                                    options={[
                                                        {
                                                            value: FeeType.PerNight,
                                                            label: 'Per Night',
                                                        },
                                                        {
                                                            value: FeeType.PerDay,
                                                            label: 'Per Day',
                                                        },
                                                        {
                                                            value: FeeType.PerNightPerGuest,
                                                            label: 'Per Night Per Guest',
                                                        },
                                                        {
                                                            value: FeeType.PerDayPerGuest,
                                                            label: 'Per Day Per Guest',
                                                        },
                                                        {
                                                            value: FeeType.PerHour,
                                                            label: 'Per Hour',
                                                        },
                                                        {
                                                            value: FeeType.PerHourPerGuest,
                                                            label: 'Per Hour Per Guest',
                                                        },
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Box
                                        display='flex'
                                        justifyContent='space-between'
                                    >
                                        <Stack spacing='3px'>
                                            <Typography
                                                color='textPrimary'
                                                fontWeight='bold'
                                            >
                                                Custom Pricing
                                            </Typography>
                                            <Typography
                                                color='textPrimary'
                                                fontSize='12px'
                                            >
                                                Add a custom weekend price
                                            </Typography>
                                        </Stack>
                                        <Checkbox
                                            checked={
                                                weekendPrice !== null && true
                                            }
                                            onChange={handleCheck}
                                        />
                                    </Box>
                                    {weekendPrice !== null && (
                                        <>
                                            <FormHelperText>
                                                Base price will be overridden by
                                                the weekend price on the days
                                                you select.
                                            </FormHelperText>
                                            <Grid container columnSpacing={2}>
                                                <Grid
                                                    item
                                                    container
                                                    xs={12}
                                                    lg={6}
                                                >
                                                    <Grid item xs={12} lg={4}>
                                                        <InputLabel>
                                                            Amount
                                                        </InputLabel>
                                                    </Grid>
                                                    <Grid item xs={12} lg={8}>
                                                        <FormTextField
                                                            name='prices.0.weekendPrice.unitPrice'
                                                            control={control}
                                                            type='number'
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position='start'>
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    xs={12}
                                                    lg={6}
                                                >
                                                    <Grid item xs={12} lg={4}>
                                                        <InputLabel>
                                                            Days
                                                        </InputLabel>
                                                    </Grid>
                                                    <Grid item xs={12} lg={8}>
                                                        <FormMultiAutocomplete
                                                            name='prices.0.weekendPrice.days'
                                                            control={control}
                                                            options={[
                                                                {
                                                                    value: 'friday',
                                                                    label: 'Friday',
                                                                },
                                                                {
                                                                    value: 'saturday',
                                                                    label: 'Saturday',
                                                                },
                                                                {
                                                                    value: 'sunday',
                                                                    label: 'Sunday',
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </>
                        )}
                        {experienceType === 'commercial' && (
                            <>
                                {' '}
                                <Grid
                                    container
                                    columns={25}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '20px',
                                        gap: '10px',
                                    }}
                                >
                                    <Stack spacing='3px'>
                                        <Grid
                                            display='flex'
                                            alignItems='center'
                                            gap='10px'
                                        >
                                            <Typography
                                                color='textPrimary'
                                                fontWeight='bold'
                                            >
                                                Base Price
                                            </Typography>
                                            <Typography color='textPrimary'>
                                                AUD
                                            </Typography>
                                        </Grid>
                                        <Typography
                                            color='textPrimary'
                                            fontSize='12px'
                                        >
                                            Applied to all dates.
                                        </Typography>
                                        <Collapse in={cancel}>
                                            <Alert severity='warning'>
                                                You have unsaved changes. Are
                                                you sure you want to cancel?
                                            </Alert>
                                        </Collapse>
                                    </Stack>

                                    {availableSpaces.map(
                                        (space: any, index: number) => (
                                            <Grid
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    gap: isMobile
                                                        ? '5px'
                                                        : '15vw',
                                                    flexDirection: isMobile
                                                        ? 'column'
                                                        : 'row',
                                                }}
                                            >
                                                <InputLabel required>
                                                    {camelCaseToTitleCase(
                                                        space.spaceType
                                                    )}
                                                </InputLabel>
                                                <Grid
                                                    sx={{
                                                        display: 'flex',
                                                        width: isMobile
                                                            ? 'auto'
                                                            : '290px',
                                                        gap: '3px',
                                                    }}
                                                >
                                                    <FormControl
                                                        size='small'
                                                        fullWidth={isMobile}
                                                    >
                                                        <FormTextField
                                                            name={`prices[${index}].unitPrice`}
                                                            control={control}
                                                            type='number'
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position='start'>
                                                                            $
                                                                        </InputAdornment>
                                                                    ),
                                                                endAdornment:
                                                                    space.feeId !==
                                                                        'priceEntireVenue' && (
                                                                        <InputAdornment position='end'>
                                                                            Per
                                                                            Day
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {space.feeId ===
                                                        'priceEntireVenue' && (
                                                        <Grid
                                                            sx={{
                                                                minWidth:
                                                                    '140px',
                                                                width: isMobile
                                                                    ? 'auto'
                                                                    : '210px',
                                                            }}
                                                        >
                                                            {' '}
                                                            <FormAutocomplete
                                                                name={`prices[${index}].type`}
                                                                control={
                                                                    control
                                                                }
                                                                options={[
                                                                    {
                                                                        value: FeeType.EntireVenuePerNight,
                                                                        label: 'Per Night',
                                                                    },
                                                                    {
                                                                        value: FeeType.EntireVenuePerDay,
                                                                        label: 'Per Day',
                                                                    },
                                                                ]}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </>
                        )}

                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: '10px',
                                gap: '10px',
                                borderTop: ' #d8d8d8 solid 1px',
                                // minHeight: '60px',
                                background: ' #eeeeee',
                                flexDirection: isMobile ? 'column' : 'row',
                            }}
                        >
                            <Grid>
                                {experienceType !== 'commercial' && (
                                    <CustomButton
                                        width={isMobile ? '100%' : 'auto'}
                                        variant='contained'
                                        color='neutral'
                                        // autoFocus
                                        onClick={handleClearWeekendPrice}
                                        // disabled={disableClearCustomPrice}
                                    >
                                        Clear Custom Price
                                    </CustomButton>
                                )}
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <CustomButton
                                    width={isMobile ? '50%' : 'auto'}
                                    variant='contained'
                                    color='neutral'
                                    // autoFocus
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </CustomButton>

                                <CustomButton
                                    width={isMobile ? '50%' : 'auto'}
                                    variant='contained'
                                    color='green'
                                    // onClick={handleSave}
                                    // disabled={disableSavePrice}
                                    autoFocus
                                    type='submit'
                                >
                                    Save
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </GenericModal>
        </>
    );
};

export default BasePriceModal;

import React from 'react'
import { CustomSmallListChip } from '../../layout/styledcomponents/CustomChip'
import UserRole from '../../shared/AuthRoles'

interface ChipProps {
    user: {
        role: string,
        viewOnly: boolean,
    }
}
const RoleChip = ({ user }: ChipProps) => {
    const loggedInUser = UserRole();

    return (
        <CustomSmallListChip
            size='small'
            style={{
                fontFamily: 'Open Sans',
                backgroundColor:
                    user?.role?.includes('admin') ||
                    loggedInUser?.role ===
                        'admin'
                        ? '#5cb5b3'
                        : '#E0E0E0',
                color:
                    user?.role?.includes('admin') ||
                    loggedInUser?.role ===
                        'admin'
                        ? '#fff'
                        : '#787878',
            }}
            label={
                user?.role ===
                    'owner_admin' ||
                user?.role ===
                    'guest_admin' ||
                loggedInUser?.role === 'admin'
                    ? 'ADMIN'
                    : user?.viewOnly ===
                    true
                    ? 'USER (VIEW ONLY)'
                    : 'USER'
            }
            variant='filled'
        />
  )
}

export default RoleChip
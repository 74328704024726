
import { Container, useMediaQuery } from '@mui/material'
import React from 'react'
import MessagingSettings from '../../components/Messages/MessagingSettings';

const MessagingSettingsPage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    
    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : '130px',
                paddingBottom: isMobile ? '100px' : '100px',
                display: 'flex',
            }}
        >
            <MessagingSettings />
        </Container>
    )
}

export default MessagingSettingsPage
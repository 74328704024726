import { Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetReportQuery } from '../../../../app/Slices/SevenDayApiSlice';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function UserDashboardSmallerStats() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const [dashboardData, setDashboardData] = useState<any>([]);

    //GET REPORT
    const { data: getThisMonth } = useGetReportQuery(
        `/dashboard/scheduled-bookings?period=current-month`
    );
    const { data: getPendingReservations } = useGetReportQuery(
        `/dashboard/user-pending-bookings`
    );
    const { data: getApprovedReservations } = useGetReportQuery(
        `/dashboard/user-approved-bookings`
    );
    const { data: getCompletedReservations } = useGetReportQuery(
        `/dashboard/user-completed-bookings`
    );

    useEffect(() => {
        let data = [
            {
                reportName: 'Scheduled This Month',
                reportUrl: 'current-month',
                statNo: getThisMonth?.length,
            },
            {
                reportName: 'Your Pending Requests',
                reportUrl: 'user-pending-bookings',
                statNo: getPendingReservations?.length,
            },
            {
                reportName: 'Your Upcoming Reservations',
                reportUrl: 'user-approved-bookings',
                statNo: getApprovedReservations?.length,
            },
            {
                reportName: 'Your Completed Reservations',
                reportUrl: 'user-completed-bookings',
                statNo: getCompletedReservations?.length,
            },
        ];
        setDashboardData(data);
    }, [
        getThisMonth,
        getPendingReservations,
        getApprovedReservations,
        getCompletedReservations,
    ]);

    const navigate = useNavigate();

    const handleOpen = (report: any) => {
        if (report.reportName.includes('Scheduled')) {
            navigate(`/dashboard/scheduled/${report.reportUrl}`);
            ReactGA.send({
                hitType: 'pageview',
                page: `/dashboard/scheduled/${report.reportUrl}`,
                title: `Scheduled ${report.reportUrl} Report (User Dashboard)`,
            });
        } else  {
            navigate(`/dashboard/user/${report.reportUrl}`);
            ReactGA.send({
                hitType: 'pageview',
                page: `/dashboard/user/${report.reportUrl}`,
                title: `USer ${report.reportUrl} Report (User Dashboard)`,
            });
        } 
    };

    return (
        <Grid sx={{ flexGrow: 1, paddingTop: '30px' }} container spacing={2}>
            <Grid item xs={12}>
                <Grid
                    container
                    justifyContent='center'
                    spacing={isMobile ? 6 : 6}
                >
                    {dashboardData.map((dashboardItem: any) => (
                        <Grid key={dashboardItem?.reportName} item>
                            <Paper
                                sx={{
                                    height: 165,
                                    width: isMobile? 330 :isIpad ? 400 : 335,

                                    // display: 'flex',
                                    // flexDirection: 'column',
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#1A2027'
                                            : '#fff',
                                }}
                                onClick={() =>
                                    handleOpen(dashboardItem)
                                }
                            >
                                <Grid
                                    sx={{
                                        // backgroundColor: 'red',
                                        height: '100%',
                                        // display: 'flex',
                                        flexDirection: 'column',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        textAlignVertical: 'center',
                                        alignContent: 'center',
                                        alignSelf: 'center',
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            // backgroundColor: 'white',
                                            height: '70%',
                                        }}
                                    >
                                        <Typography
                                            component='h1'
                                            variant='h5'
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: '70px',
                                            }}
                                        >
                                            {' '}
                                            {dashboardItem?.statNo}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        sx={{
                                            // backgroundColor: 'purple',
                                            height: '30%',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            {dashboardItem?.reportName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';
import { Link } from 'react-router-dom';

export default function AiReservation() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <>
            {loggedInUser?.role.includes('owner') ? (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            7DAY offers a seamless experience with our AI
                            Assistant who can help you make a booking for an
                            individual reservation or a recurring reservation.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            There are two ways to use 7DAY AI Assistant for your
                            reservations: 
                       
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                                fontWeight: 'bold',
                            }}
                        >
                          Option 1:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                              <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on 'Experiences'</li>
                                <li>Click 'View All Experiences' </li>
                                <li>
                                    Click on the Experience you would like to
                                    book
                                </li>
                                <li>
                                    Scroll down to the calendar on the left hand
                                    side and select 'AI Reservation Assistant'
                                </li>
                                <li>
                                    Type in what dates you would like to block
                                    and why
                                </li>
                                <li>
                                    A list of dates will appear. Occasionally
                                    some dates may be in red, and this is
                                    because these dates are already booked
                                </li>
                                <li>Click 'Reserve' to block those dates</li>
                            </ol>
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                                fontWeight: 'bold',
                            }}
                        >
                          Option 2:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                              <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on 'Reservations'</li>
                                <li>Click '+New' button </li>
                           
                                <li>
                                    Type in what dates you would like to block
                                    for which experience and why
                                </li>
                                <li>
                                    A list of dates will appear. Occasionally
                                    some dates may be in red, and this is
                                    because these dates are already booked
                                </li>
                                <li>Click 'Reserve' to block those dates</li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            7DAY allows you to export the reservation
                            information to an external calendar such as Google,
                            Outlook etc. We do not allow for importation of
                            other reservations into 7DAY, only individual
                            export.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To export a reservation to a calendar:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on 'Reservations'</li>
                                <li>
                                    Select the reservations you want to export
                                    from 7DAY
                                </li>
                                <li>Click on 'Add To Calendar' button</li>
                                <li>Select desired calendar to export to</li>
                            </ol>
                            Please note that any updates to the reservations
                            within the 7DAY app do not autmatically sync to your
                            external calendar.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CustomIconButton } from '../../../../layout/styledcomponents/CustomIconButton';
import { CustomChip } from '../../../../layout/styledcomponents/CustomChip';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { useGetAllReservationsQuery } from '../../../../app/Slices/SevenDayApiSlice';
import moment from 'moment';
import React, { useEffect } from 'react';
import EngineeringIcon from '@mui/icons-material/Engineering';
import UserRole from '../../../../shared/AuthRoles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDispatch, useSelector } from 'react-redux';
import { openReportAPIRefSliceAction } from '../../../../app/Slices/ReportAPIRefSlice';
import { selectReservationFilters } from '../../../../app/Slices/ReservationFiltersSlice';
import ReportLoadingComponent from '../../../../layout/styledcomponents/ReportLoadingComponent';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#000',
        borderBottom: '0px solid #787878',
        paddingLeft: '0px',
    },
    [`& .${gridClasses.row}`]: {
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                },
            },
        },
    },
}));

export default function ViewReservationListComponent(params:any) {
    const loggedInUser = UserRole();


    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let csv = apiRef?.current.exportDataAsCsv;
        let print = apiRef?.current.exportDataAsPrint;
        dispatch(
            openReportAPIRefSliceAction({
                exportInfo: {
                    csvExport: csv,
                    printExport: print,
                    search: apiRef?.current.setQuickFilterValues,
                },
            })
        );
    }, [apiRef, dispatch]);

    // const handleShowDetailsClick = (params: any) => {
    //     navigate(
    //         `/reservations/${params?.row?.module?.name
    //             .replace(/ /g, '')
    //             .toLowerCase()}/${params?.row?.id}`,
    //         {
    //             state: { name: 'viewReservation', params: params.row },
    //         }
    //     );
    // };

    // const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    //     navigate(
    //         `/reservations/${params?.row?.module?.name
    //             .replace(/ /g, '')
    //             .toLowerCase()}/${params?.row?.id}`,
    //         {
    //             state: {
    //                 name: 'viewReservation',
    //                 component: 'reservations',
    //                 url: 'reservations',
    //                 params: params.row,
    //             },
    //         }
    //     );
    // };

    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const renderLinkCell = (params: any) => (
        <Link
            to={{
                pathname: `/reservations/${params.row.module.name
                    .replace(/ /g, '')
                    .toLowerCase()}/${params.row.id}`,
            }}
            state={{
                name: 'viewReservation',
                component: 'reservations',
                url: 'reservations',
                params: params.row,
            }}
            style={{
                textDecoration: 'none',
                color: 'inherit',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {params.value}
        </Link>
    );

    const renderBookingRef = (params: any) => (
        <Link
            to={{
                pathname: `/reservations/${params.row.module.name
                    .replace(/ /g, '')
                    .toLowerCase()}/${params.row.id}`,
            }}
            state={{
                name: 'viewIndividualReservations',
                component: 'reservations',
                url: 'reservations',
                params: params.row,
            }}
            style={{
                textDecoration: 'none',
                color: 'inherit',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            #{params.value}
        </Link>
    );

    const columns: GridColDef[] = [
        {
            field: 'module',
            headerName: 'Experience',
            flex: 1,
            minWidth: 100,
            type: 'string',
            sortable: true,
            valueGetter: (params) => {
                return params?.value?.name;
            },
            renderCell: renderLinkCell,
        },
        {
            field: 'type',
            headerName: 'Type',
            width: isMobile ? 100 : isIpad ? 100 : 100,
            sortable: true,
            valueGetter: (params) => {
                return (
                    params?.row.module?.type.charAt(0).toUpperCase() +
                    params?.row.module?.type.slice(1)
                );
            },
            renderCell: renderLinkCell,
        },
        {
            field: 'startDate',
            headerName: 'Check-in',
            type: 'string',
            width: isMobile ? 150 : isIpad ? 150 : 120,
            sortable: true,
            renderCell: (params) => {
                return (
                    <Link
                        to={{
                            pathname: `/reservations/${params.row.module.name
                                .replace(/ /g, '')
                                .toLowerCase()}/${params.row.id}`,
                        }}
                        state={{
                            name: 'viewReservation',
                            component: 'reservations',
                            url: 'reservations',
                            params: params.row,
                        }}
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {moment(params?.value).format('ll')}
                    </Link>
                );
            },
        },
        {
            field: 'endDate',
            headerName: 'Checkout',
            type: 'string',
            width: isMobile ? 150 : isIpad ? 150 : 120,
            sortable: true,
            renderCell: (params) => {
                return (
                    <Link
                        to={{
                            pathname: `/reservations/${params.row.module.name
                                .replace(/ /g, '')
                                .toLowerCase()}/${params.row.id}`,
                        }}
                        state={{
                            name: 'viewReservation',
                            component: 'reservations',
                            url: 'reservations',
                            params: params.row,
                        }}
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {moment(params?.value).format('ll')}
                    </Link>
                );
            },
        },
        {
            field: 'createdAt',
            headerName: 'Booked',
            type: 'string',
            width: isMobile ? 150 : isIpad ? 150 : 100,
            sortable: true,
            renderCell: (params) => {
                return (
                    <Link
                        to={{
                            pathname: `/reservations/${params.row.module.name
                                .replace(/ /g, '')
                                .toLowerCase()}/${params.row.id}`,
                        }}
                        state={{
                            name: 'viewReservation',
                            component: 'reservations',
                            url: 'reservations',
                            params: params.row,
                        }}
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {moment(params?.value).format('ll')}
                    </Link>
                );
            },
        },

        {
            field: 'id',
            headerName: 'Ref.',
            width: isMobile ? 100 : isIpad ? 100 : 80,
            sortable: true,
            renderCell: renderBookingRef,
        },
        {
            field: 'recurringId',
            headerName: 'Recurring Reservation',
            width: isMobile ? 100 : isIpad ? 100 : 80,
            sortable: true,
            valueGetter: (params) => {
                return params?.value ? `#${params?.value}` : '-';
            },
        },
        {
            field: 'company',
            headerName: 'Guest',
            type: 'string',
            width: isMobile ? 120 : isIpad ? 120 : 120,
            sortable: true,
            valueGetter: (params) => {
                return params?.row.guest?.name
                    ? params?.row.guest?.name
                    : params?.row.owner?.name;
            },
            renderCell: renderLinkCell,
        },
        {
            field: 'visitType',
            headerName: 'Visit Type',
            width: isMobile ? 100 : isIpad ? 100 : 100,
            sortable: true,
            valueGetter: (params) => {
                return params?.row.bookingInfo?.visitType
                    ? params?.row.bookingInfo?.visitType
                          .charAt(0)
                          .toUpperCase() +
                          params?.row.bookingInfo?.visitType.slice(1)
                    : 'Blocked';
            },
            renderCell: renderLinkCell,
        },
        {
            field: 'onBehalf',
            headerName: `${
                loggedInUser?.role.includes('owner') ? 'Priority' : ''
            }`,
            type: 'string',
            width: 30,
            sortable: true,
            renderCell: (params) => {
                return (
                    <Link
                        to={{
                            pathname: `/reservations/${params.row.module.name
                                .replace(/ /g, '')
                                .toLowerCase()}/${params.row.id}`,
                        }}
                        state={{
                            name: 'viewReservation',
                            component: 'reservations',
                            url: 'reservations',
                            params: params.row,
                        }}
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {loggedInUser?.role.includes('owner') &&
                        params?.value === false ? (
                            <EngineeringIcon
                                style={{ color: '#787878', opacity: 0.3 }}
                            />
                        ) : loggedInUser?.role.includes('owner') &&
                          params?.value === true ? (
                            <StarOutlineIcon
                                style={{ color: '#787878', opacity: 0.3 }}
                            />
                        ) : (
                            ''
                        )}
                    </Link>
                );
            },
        },
        {
            field: 'createdBy',
            headerName: 'Reserved By',
            type: 'string',
            width: isMobile ? 150 : isIpad ? 150 : 130,
            sortable: true,
            valueGetter: (params) => {
                return params?.row?.createdBy?.firstName
                    ? `${params?.row?.createdBy?.firstName} ${params?.row?.createdBy?.lastName}`
                    : '-';
            },
            renderCell: renderLinkCell,
        },
        {
            field: 'booker',
            headerName: 'Primary Guest',
            type: 'string',
            width: isMobile ? 150 : isIpad ? 150 : 130,
            sortable: true,
            valueGetter: (params) => {
                return params?.row.bookingInfo?.visitType === undefined
                    ? '-'
                    : `${params?.row.booker?.firstName} ${params?.row.booker?.lastName}`;
            },
            renderCell: renderLinkCell,
        },

        {
            field: 'status',
            headerName: 'Status',
            width: isMobile ? 150 : isIpad ? 150 : 140,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                return (
                    <Link
                        to={{
                            pathname: `/reservations/${params.row.module.name
                                .replace(/ /g, '')
                                .toLowerCase()}/${params.row.id}`,
                        }}
                        state={{
                            name: 'viewReservation',
                            component: 'reservations',
                            url: 'reservations',
                            params: params.row,
                        }}
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <CustomChip
                            sx={{ fontFamily: 'Open Sans', cursor: 'pointer' }}
                            label={
                                params?.value.charAt(0).toUpperCase() +
                                params?.value.slice(1)
                            }
                            color={
                                params.value === 'completed'
                                    ? 'green'
                                    : params.value === 'cancelled'
                                    ? 'red'
                                    : params.value === 'approved'
                                    ? 'yellow'
                                    : params.value === 'declined'
                                    ? 'gray'
                                    : 'blue'
                            }
                            variant='outlined'
                        />
                    </Link>
                );
            },
        },
        {
            field: 'more',
            headerName: '',
            width: isMobile ? 90 : isIpad ? 90 : 40,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return (
                    <Link
                        to={{
                            pathname: `/reservations/${params.row.module.name
                                .replace(/ /g, '')
                                .toLowerCase()}/${params.row.id}`,
                        }}
                        state={{
                            name: 'viewReservation',
                            component: 'reservations',
                            url: 'reservations',
                            params: params.row,
                        }}
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <CustomIconButton
                            // onClick={() => handleShowDetailsClick(params)}
                            sx={{
                                width: '25px',
                                height: '25px',
                                paddingLeft: '30px',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#5cb5b3',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <OpenInNewIcon
                                sx={{ color: '#787878', fontSize: '14px' }}
                            />
                        </CustomIconButton>{' '}
                    </Link>
                );
            },
        },
    ];

    return (
                 <StripedDataGrid
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                            ? 'even'
                            : 'odd'
                    }
                    sx={{
                        paddingTop: '30px',
                        boxShadow: 0,
                        border: 0,
                        '& .MuiDataGrid-cell': {
                            padding: '0',
                        },
                    }}
                    apiRef={apiRef}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                owner:
                                    loggedInUser?.role === 'guest_admin' ||
                                    loggedInUser?.role ===
                                        'guest_department_admin' ||
                                    loggedInUser?.role === 'guest_user',
                                recurringId: loggedInUser?.role.includes(
                                    'owner'
                                )
                                    ? true
                                    : false,
                            },
                        },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    rows={params?.reservations !== undefined ? params?.reservations : []}
                    columns={columns}
                    components={{
                        NoRowsOverlay: () => (
                            <Stack
                                height='100%'
                                alignItems='center'
                                justifyContent='center'
                            >
                                <List>
                                    <ListItem
                                        sx={{
                                            padding: '0!important',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                    }}
                                                >
                                                    No Reservations
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '12px',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Please add a new reservation
                                                    or adjust the filters
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack
                                height='100%'
                                alignItems='center'
                                justifyContent='center'
                            >
                                <List>
                                    <ListItem
                                        sx={{
                                            padding: '0!important',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                    }}
                                                >
                                                    No Results
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '12px',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Please try again
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Stack>
                        ),
                    }}
                />
            
                )
 
}

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
    Avatar,
    Box,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { CustomChip, CustomSmallListChip } from '../../../layout/styledcomponents/CustomChip';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import { useNavigate } from 'react-router-dom';
import {  useGetIndividualClientQuery } from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProfilePicture from '../Profile/ProfilePicture';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 900,
        color: '#000',
        borderBottom: '1px solid #787878',
        // textTransform: 'upperCase'
    },
    [`& .${gridClasses.row}`]: {
        // backgroundColor: '#f6f8f9',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            // fontWeight: 'bold',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                    // color: 'red',
                },
            },
        },
    },
}));

export default function IndividualGuestsComponent() {
    const navigate = useNavigate();


    const [url, setUrl] = useState('');
    const urlRef = window.location.href.split('/')[4];
    const { data: getIndividualGuestCompany, isFetching } =
        useGetIndividualClientQuery(url);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (urlRef !== undefined) {
            setUrl(`/guests/${urlRef}/users`);
        }
    }, []);

    

    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');


    const onClickGoBack = () => {
        navigate(`/guests`);
    };

    // const filterResults: any = useSelector(selectFilteredClientData);

    // const handleShowDetailsClick = (params: any) => {
    //     navigate(
    //         `/guests/${
    //             params?.row?.id
    //         }/users`,
    //         {
    //             state: { name: 'viewGuests', params: params.row },
    //         }
    //     );
    // };

    // const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    //     navigate(
    //         `/guests/${
    //             params?.row?.id
    //         }/users`,
    //         {
    //             state: { name: 'viewGuests', params: params.row },
    //         }
    //     );
    // };

  

    const columns: GridColDef[] = [
        // {
        //     field: 'firstName',
        //     headerName: 'Guest',
        //     type: 'string',
        //     // width: isMobile ? 261 : isIpad ? 735 : 1327,
        //     flex: 1,
        //     minWidth: 150,
        //     sortable: true,
        //     renderCell: (params) => {
        //         return (
        //             <List>
        //                 <ListItem sx={{ padding: '0!important' }}>
        //                     {isMobile ? null : (
        //                         <ListItemAvatar>
        //                             <Avatar
        //                                 sx={{
        //                                     bgcolor: '#E0E0E0',
        //                                 }}
        //                             >
        //                                 {params?.value?.charAt(0)}
        //                                 {params?.row.lastName?.charAt(0)}
        //                             </Avatar>
        //                         </ListItemAvatar>
        //                     )}
        //                     <ListItemText
        //                         primary={
        //                             <Typography
        //                                 sx={{
        //                                     fontFamily: 'Open Sans',
        //                                     color: '#787878',
        //                                 }}
        //                             >
        //                                 {params?.value}
        //                             </Typography>
        //                         }
        //                         secondary={
        //                             <Typography
        //                                 sx={{
        //                                     fontFamily: 'Open Sans',
        //                                     color: '#787878',
        //                                     fontSize: '12px',
        //                                 }}
        //                             >
        //                                 {params?.row?.email}
        //                             </Typography>
        //                         }
        //                     />
        //                 </ListItem>
        //             </List>
        //         );
        //     },
        // },
        {
            field: 'firstName',
            headerName: 'User',
            type: 'string',
            // width: isMobile ? 261 : isIpad ? 735 : 1327,
            flex: 1,
            minWidth: 261,
            sortable: true,
            renderCell: (params) => {
                return (
                    <List>
                        <ListItem sx={{ padding: '0!important' }}>
                            {isMobile ? null : (
                                <ListItemAvatar>
                                    <ProfilePicture 
                                        profileDetails={params?.row} 
                                        src={params?.row?.profilePicture?.src}
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            fontSize: '14px'
                                        }}
                                    />
                                </ListItemAvatar>
                            )}
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {params?.value} {params?.row.lastName} -{' '}
                                        <CustomSmallListChip
                                            size='small'
                                            style={{
                                                fontFamily: 'Open Sans',
                                                backgroundColor: params?.row.role.includes('admin') ? '#5cb5b3':'#E0E0E0',
                                                color:  params?.row.role.includes('admin') ? '#fff':'#787878',
                                            }}
                                            label={
                                                params?.row.role ===
                                                      'guest_admin'
                                                    ? 'COMPANY ADMIN'
                                                    : params?.row.role ===
                                                      'guest_department_admin'
                                                    ? 'TEAM ADMIN'
                                                    : params?.row?.viewOnly ===
                                                      true
                                                    ? 'USER (VIEW ONLY)'
                                                    : 'USER'
                                            }
                                            variant='filled'
                                        />
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {params?.row.email}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                );
            },
        },
        {
            field: 'department',
            headerName: 'Team',
            width: 200,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return params?.value?.name 
            },
        },
        {
            field: 'lastLoginAt',
            headerName: 'Last Login',
            width: isMobile ? 180 : isIpad ? 180 : 180,
            renderCell: (params) => {
                return (
                    <List>
                        <ListItem sx={{ padding: '0!important' }}>
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {params?.value
                                            ? moment(params?.value).format(
                                                  'DD/MM/YYYY'
                                              )
                                            : '-'}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {params?.value
                                            ? moment(params?.value).format(
                                                  'h:mm a'
                                              )
                                            : ''}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                );
            },
            sortable: true,
        },
        {
            field: 'isActive',
            headerName: 'Status',
            width: isMobile ? 5 : isIpad ? 50 : 130,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                return isIpad || isMobile ? (
                    params.value === true ? (
                        <Tooltip title='Enabled'>
                            <DoneIcon sx={{ color: '#5cb5b3' }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title='Enabled'>
                            <BlockIcon sx={{ color: '#787878' }} />
                        </Tooltip>
                    )
                ) : (
                    <CustomChip
                        style={{
                            fontFamily: 'Open Sans',
                        }}
                        label={
                            params.value === true ? (
                                <Typography
                                    color='green'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                    }}
                                >
                                    Enabled
                                </Typography>
                            ) : (
                                <Typography
                                    color='red'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                    }}
                                >
                                    Disabled
                                </Typography>
                            )
                        }
                        color={params.value === true ? 'green' : 'red'}
                        variant='outlined'
                    />
                );
            },
        },
       
    ];

    return (
        <Box sx={{ height: 700, width: '100%' }}>
            <StripedDataGrid
                // onRowClick={handleRowClick}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                sx={{
                    boxShadow: 0,
                    border: 0,
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={getIndividualGuestCompany !== undefined ? getIndividualGuestCompany : []}
                columns={columns}
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Guest Users
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please add a new guest user or
                                                adjust the filters
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Results
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please try again
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                }}
            />
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '80px' : '10%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        // backgroundColor: 'yellow',
                    }}
                >
                    <CustomButton
                        onClick={onClickGoBack}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon />{' '}
                        {isMobile ? null : (
                            <Typography
                                sx={{ fontSize: '12px', paddingLeft: '5px' }}
                            >
                                Back to Guest Companies
                            </Typography>
                        )}
                    </CustomButton>
                </Grid>
        </Box>
    );
}

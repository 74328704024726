import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IndividualExperiencePhotos from '../../../components/Experiences/IndividualExperiencePhotos';
import IndividualExperienceContent from '../../../components/Experiences/IndividualExperienceContent';
import IndividualExperienceHero from '../../../components/Experiences/IndividualExperienceHero';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    useGetIndividualAssetQuery,
    useGetIndividualOwnerQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../../shared/AuthRoles';
import { useEffect } from 'react';
import React from 'react';
import { CustomSmallListChip } from '../../../../layout/styledcomponents/CustomChip';
import ReactGA from 'react-ga4';

export default function OwnersIndividualExperiencePage() {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const { data: getIndividualCompany } = useGetIndividualOwnerQuery(
        `owners/${loggedInUser?.company}`
    );
    const navigate = useNavigate();
    const onClickGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const urlRefType = window.location.href.split('/')[5];
    const urlRef = window.location.href.split('/')[6];

    const {
        data: getIndividualAsset,
        isFetching,
        error,
    } = useGetIndividualAssetQuery(
        `owners/${loggedInUser?.company}/modules/${urlRef}`
    );

    useEffect(() => {
        if (loggedInUser?.role !== 'owner_admin') {
            navigate(`/viewExperiences/${urlRefType}/${urlRef}`);
        }
    }, [loggedInUser?.role]);

    if (error) {
        navigate(`/*`);
        ReactGA.send({
            hitType: 'pageview',
            page: '/*',
            title: 'Error 404',
        });
    }

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {isFetching === true ? (
                <Grid
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{
                        height: '100%',
                        width: '100%',
                        // backgroundColor: 'red',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : '80%',
                        // backgroundColor: 'pink',
                        justifyContent: 'left',
                        alignItems: 'center',
                        // display: 'flex',
                        // flexDirection: 'row',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '2%' : '3%',
                            // backgroundColor: 'blue',
                            justifyContent: 'left',
                            display: 'flex',
                        }}
                    >
                        {/* {getIndividualAsset?.type === 'property' ? (
                        <MapsHomeWorkIcon
                            fontSize='large'
                            sx={{
                                // backgroundColor: 'pink',
                                color: '#5cb5b3',
                                // height: '100%'
                                paddingRight: '5px',
                                fontSize: '32px',
                            }}
                        />
                    ) : getIndividualAsset?.type === 'boat' ? (
                        <SailingIcon
                            fontSize='large'
                            sx={{
                                // backgroundColor: 'pink',
                                color: '#5cb5b3',
                                // height: '100%'
                                paddingRight: '5px',
                                fontSize: '32px',
                            }}
                        />
                    ) : getIndividualAsset?.type === 'vehicle' ? (
                        <DirectionsCarIcon
                            fontSize='large'
                            sx={{
                                // backgroundColor: 'pink',
                                color: '#5cb5b3',
                                // height: '100%'
                                paddingRight: '5px',
                                fontSize: '32px',
                            }}
                        />
                    ) : null} */}
                        <Typography
                            component='h1'
                            variant='h5'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                letterSpacing: '1px',
                                paddingRight: '15px',
                            }}
                        >
                            {getIndividualAsset?.name}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                            }}
                        >
                            <CustomSmallListChip
                                size='small'
                                style={{
                                    fontFamily: 'Open Sans',
                                    border: 0,
                                    backgroundColor:
                                        getIndividualAsset?.isAvailable
                                            ? '#5cb5b3'
                                            : 'red',
                                    color: getIndividualAsset?.isAvailable
                                        ? '#fff'
                                        : '#fff',
                                }}
                                label={
                                    getIndividualAsset?.isAvailable
                                        ? 'AVAILABLE'
                                        : 'UNAVAILABLE'
                                }
                                variant='filled'
                            />{' '}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            textAlign: ' left',
                            paddingBottom: isMobile ? '10px' : '0px',
                        }}
                    >
                        <IndividualExperienceHero
                            experience={getIndividualAsset}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: '100%',
                        }}
                    >
                        <IndividualExperiencePhotos
                            experience={getIndividualAsset}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '65%' : 'auto',
                            // backgroundColor: 'green',
                            justifyContent: 'left',
                            // alignItems: 'center',
                            // display: 'flex',
                            // flexDirection: 'row',
                        }}
                    >
                        <IndividualExperienceContent
                            experience={getIndividualAsset}
                        />
                    </Grid>

                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '80px' : '10%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            // backgroundColor: 'yellow',
                        }}
                    >
                        <CustomButton
                            onClick={onClickGoBack}
                            color='green'
                            sx={{
                                marginLeft: !isMobile ? '-8px' : '-18px',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#5cb5b3',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <ArrowBackIcon />{' '}
                            {isMobile ? null : (
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        paddingLeft: '5px',
                                    }}
                                >
                                    Back to experiences
                                </Typography>
                            )}
                        </CustomButton>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
}

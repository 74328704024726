import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import CalculateChargesContent from './CalculateChargesContent';

const CalculateCharges = ({
    fees,
    moduleType,
    moduleId,
    overnightGuestCount,
}: {
    fees: any;
    moduleType: any;
    moduleId: any;
    overnightGuestCount?: any;
}) => {
    return (
        <Grid
            sx={{
                minWidth: '350px',
            }}
        >
            <Card
                sx={{
                    boxShadow: 1,
                    background: 'none',
                    // minHeight: isMobile ? '50px' : '150px',
                    height: '100%',
                    backgroundColor: 'transparent',
                    color: 'none',
                    '&:focus': {
                        color: '#fff',
                        backgroundColor: 'transparent',
                        background: 'none',
                    },
                }}
                style={{ flex: 1 }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '15px',
                        height: '100%',
                    }}
                >
                    <CalculateChargesContent
                        fees={fees}
                        moduleId={moduleId}
                        moduleType={moduleType}
                    />
                </CardContent>
            </Card>
        </Grid>
    );
};

export default CalculateCharges;

import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import UsersComponent from '../../components/User Management/UsersComponent';
import UsersHero from '../../components/User Management/UsersHero';
import React, { useEffect } from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import UserRole from '../../../shared/AuthRoles';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

export default function UsersPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    let url = window.location.href.split('/')[3];
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBackButton = () => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_company'
        ) {
            navigate(`/adminCenter/host`);
        } else {
            navigate(`/adminCenter/guest`);
        }
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? '90%' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <PeopleAltIcon
                        sx={{
                            color: '#5cb5b3',
                            paddingRight: '5px',
                            fontSize: '32px',
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        {url === 'users' ? 'Users' : 'Clients'}
                    </Typography>
                </Grid>

                <Grid
                    sx={{
                        width: '100%',
                        minHeight: isMobile ? '65%' : '100%',
                        justifyContent: 'left',
                    }}
                >
                    {loggedInUser?.role === 'guest_admin' ||
                    loggedInUser?.role === 'guest_company' ? (
                        <>
                            <Grid sx={{ height: isMobile ? '5%' : '10%' }}>
                                {' '}
                                <UsersHero />
                            </Grid>
                            <Grid
                                sx={{
                                    height: isMobile ? '90%' : '55%',
                                }}
                            >
                                {' '}
                                <UsersComponent />
                            </Grid>
                            <Grid sx={{ height: isMobile ? '5%' : '5%' }}>
                                {' '}
                                <CustomButton
                                    onClick={handleBackButton}
                                    color='green'
                                    sx={{
                                        marginLeft: !isMobile
                                            ? '-8px'
                                            : '-18px',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: '#5cb5b3',
                                            backgroundColor: 'transparent',
                                            background: 'none',
                                        },
                                    }}
                                >
                                    <ArrowBackIcon />{' '}
                                    {isMobile ? null : (
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: '12px',
                                                paddingLeft: '5px',
                                            }}
                                        >
                                            Back
                                        </Typography>
                                    )}
                                </CustomButton>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <UsersHero />
                            <UsersComponent />
                        </>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

const initialBookerState: any = {};

export const bookerSlice = createSlice({
    name: 'booker',
    initialState: initialBookerState,
    reducers: {
        setBookerObject: (state, action: PayloadAction<any>) => {
            return { ...state, ...action.payload };
        },
        updateBookerField: (
            state,
            action: PayloadAction<{ field: string; value: any }>
        ) => {
            state[action.payload.field] = action.payload.value;
        },
        resetBookerObject: () => initialBookerState,
    },
});

export const { setBookerObject, updateBookerField, resetBookerObject } =
    bookerSlice.actions;

export const selectBookerObject = (state: RootState) => state.booker;

export const bookerReducer = bookerSlice.reducer;

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Grid,
    List,
    ListItem,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    openLoadingSliceAction,
    selectLoadingSliceData,
} from '../../../../app/Slices/LoadingSlice';
import {
    useEditProfileDetailsMutation,
    useGetProfileDetailsQuery,
    useGetTeamListQuery,
    useGetUserListQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../../layout/styledcomponents/CustomIconButton';
import { CustomTextField } from '../../../../layout/styledcomponents/CustomTextField';
import DropdownFilter from '../../../../layout/styledcomponents/filters/Dropdown';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../../shared/AuthRoles';
import { useExitPrompt } from '../../../../shared/LeavingPageWarning';
import toast from '../../../shared/Toast';
import { Photo } from '../../../types/user';
import EditPasswordModal from './EditPasswordModal';

export interface TeamProps {
    id: number;
    name: string;
}

interface PersonalDetailsProps {
    photo?: Photo;
}

export default function EditPersonalDetails({ photo }: PersonalDetailsProps) {
    const navigate = useNavigate();
    const [openModal, setOpen] = useState(false);
    const [editProfileDetails] = useEditProfileDetailsMutation();
    const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const { data: profileDetails, isFetching } =
        useGetProfileDetailsQuery('/profile');
    const loggedInUser = UserRole();
    const [url, setURL] = useState('');
    const [userUrl, setUsersUrl] = useState('');
    const { data: getUsers } = useGetUserListQuery(userUrl);
    const [firstName, setFirstName] = useState(profileDetails?.firstName);
    const [surname, setSurname] = useState(profileDetails?.lastName);
    const [phoneNumber, setPhoneNumber] = useState(profileDetails?.phone);
    const [team, setTeam] = useState(profileDetails.department);
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [emailAddress, setEmailAddress] = useState(profileDetails?.email);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isDuplicateEmail, setDuplicateEmail] = useState(false);
    const [emailHasChanged, setEmailHasChanged] = useState(false);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = React.useState(false);
    const resetEmailIsLoading: any = useSelector(selectLoadingSliceData);

    //Get List of Teams for dropdown selection

    const [teamList, setTeamList] = useState<any>([]);
    const [teamId, setTeamId] = useState({
        value: null,
        label: '',
    });
    const { data: getTeams } = useGetTeamListQuery(loggedInUser?.company);
    const teams: TeamProps[] = getTeams ?? [];

    useEffect(() => {
        setLoading(resetEmailIsLoading.isLoading);
    }, [resetEmailIsLoading.isLoading]);

    const onClickGoBack = () => {
        navigate('/profile');
    };
    const onClickOpenModal = () => {
        setOpen(true);
    };
    // const handleChangeModal = () => {
    //     setOpen(false);
    // };

    const handleCloseModal = () => {
        setOpen(false);
    };
    useEffect(() => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_company'
        ) {
            setUsersUrl(`/owners/${loggedInUser?.company}/users`);
        } else if (
            loggedInUser?.role === 'guest_admin' ||
            loggedInUser?.role === 'guest_company' ||
            loggedInUser?.role === 'guest_department_admin'
        ) {
            setUsersUrl(`/guests/${loggedInUser?.company}/users`);
        } else {
            setUsersUrl(`/7-day/users`);
        }
    }, [loggedInUser]);

    useEffect(() => {
        let allTeams: any = teams?.map((val: any) => {
            return val?.owner?.name
                ? {
                      value: val?.id,
                      label:
                          val?.name?.charAt(0)?.toUpperCase() +
                          val?.name.slice(1),
                  }
                : {
                      value: val?.id,
                      label:
                          val?.name?.charAt(0)?.toUpperCase() +
                          val?.name.slice(1),
                  };
        });

        let filterOutDuplicates = allTeams?.filter(
            (value: any, index: any, self: any) =>
                index ===
                self.findIndex(
                    (t: any) =>
                        t?.label === value?.label && t?.label === value?.label
                )
        );

        setTeamList(filterOutDuplicates);
    }, [getTeams]);

    const formDetailsProfile = {
        // email: emailAddress,
        // password: 'password',
        firstName: firstName,
        lastName: surname,
        phone: phoneNumber,
        profilePicture: photo,
        // isActive: true,
    };

    const formDetailsProfileGuestAdmin = {
        email: emailAddress,
        // password: 'password',
        firstName: firstName,
        lastName: surname,
        phone: phoneNumber,
        department: team,
        profilePicture: photo,
        // isActive: true,
    };

    useEffect(() => {
        return () => {
            setShowExitPrompt(false);
        };
    }, []);

    useEffect(() => {
        if (
            loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_user' ||
            loggedInUser?.role === 'owner_company'
        ) {
            setURL(`owners/${loggedInUser?.company}`);
        } else if (
            loggedInUser?.role === 'guest_admin' ||
            loggedInUser?.role === 'guest_company' ||
            loggedInUser?.role === 'guest_user' ||
            loggedInUser?.role === 'guest_department_admin'
        ) {
            setURL(`guests/${loggedInUser?.company}`);
        } else {
            setURL(`/7-day/users`);
        }
        setFirstName(profileDetails?.firstName);
        setSurname(profileDetails?.lastName);
        setPhoneNumber(profileDetails?.phone);
        setEmailAddress(profileDetails?.email);
    }, [profileDetails]);

    const handlePhone = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setShowExitPrompt(true);
        setPhoneNumber(event.target.value);
        if (validatePhone(event.target.value) === false) {
            setIsValidPhone(true);
        } else {
            setIsValidPhone(false);
        }
    };

    const validatePhone = (ph: any) => {
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i.test(
            ph
        );
    };

    const errors: any = [];

    if (phoneNumber?.length === 0) {
        errors.push('Phone cannot be empty');
    }
    if (firstName?.length === 0) {
        errors.push('First Name cannot be empty');
    }
    if (surname?.length === 0) {
        errors.push('Surname cannot be empty');
    }
    if (emailAddress?.length === 0) {
        errors.push('Surname cannot be empty');
    }
    if (loggedInUser?.role === 'guest_department_admin' && !team) {
        errors.push('Team cannot be empty');
    }

    const handleFirstName = (event: any) => {
        setShowExitPrompt(true);
        setFirstName(
            event?.target?.value.charAt(0)?.toUpperCase() +
                event?.target?.value?.slice(1)
        );
    };
    const handleLastName = (event: any) => {
        setShowExitPrompt(true);
        setSurname(
            event?.target?.value.charAt(0)?.toUpperCase() +
                event?.target?.value?.slice(1)
        );
    };

    const handleTeamId = (value: any) => {
        if (value === '') {
            navigate('/teams');
            return;
        } else {
            let obj = teamList.find((o: any) => o.value === value);
            setTeamId({ value: value, label: obj.label });
            setTeam({
                id: value,
                name: obj.label,
            });
        }
    };

    //Retrieve users and compare account.email with the new user email
    const mapOverUsers = getUsers?.map(
        (account: { email: any }) => account?.email
    );

    const handleEmail = (event: any) => {
        if (profileDetails?.email !== event?.target?.value) {
            setEmailHasChanged(true);
        }
        // setEmailAddress(event.target.value);
        setShowExitPrompt(true);
        if (validatEmail(event?.target?.value) === false) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
        //Find Duplicate User
        const findDuplicate = mapOverUsers?.find(
            (i: any) => i === event?.target?.value
        );
        if (
            findDuplicate !== undefined &&
            findDuplicate !== getUsers?.account?.email
        ) {
            setDuplicateEmail(true);
            setEmailAddress('');
        } else if (findDuplicate === getUsers?.account?.email) {
            setDuplicateEmail(false);
            setEmailAddress(event?.target?.value);
        } else {
            setDuplicateEmail(false);
            setEmailAddress(event?.target?.value);
        }
    };

    const validatEmail = (email: any) => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    };

    const editProfile = () => {
        const requestBody = loggedInUser?.role.includes('admin')
            ? formDetailsProfileGuestAdmin
            : formDetailsProfile;

        editProfileDetails(requestBody).then((data: any) => {
            if (data) {
                if (data?.error) {
                    if (data?.error?.data?.error) {
                        toast.error(`${data?.error?.data?.message}`);
                    } else {
                        toast.error(`Failed to edit profile.`);
                    }
                } else {
                    toast.success(
                        `Your profile has been successfully updated.`
                    );
                    if (emailHasChanged === true) {
                        dispatch(
                            openLoadingSliceAction({
                                isLoading: true,
                            })
                        );
                        setTimeout(function () {
                            navigate(`/login`);
                            //3600000 1 hr
                        }, 4000);
                    } else {
                        navigate(`/profile`);
                    }
                }
            } else {
                toast.error(`Failed to profile`, { duration: 3000 });
            }
        });
    };
    return (
        <>
            {isFetching === true || isLoading === true ? (
                <Grid
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}

                    sx={{
                        height: isIpad ? '50vh' : isMobile ? '5px' : '50vh',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <>
                    <Grid
                        sx={{
                            width: '100%',
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            component='h4'
                            // variant='h6'
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                textAlign: 'left',
                                fontWeight: 900,
                                paddingTop:
                                    isMobile || isIpad ? '30px' : '40px',
                                paddingBottom: '30px',
                                height: '10%',
                            }}
                        >
                            Personal Details
                        </Typography>

                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '30px',
                            }}
                        >
                            <Grid
                                container
                                columnSpacing='50px'
                                rowSpacing='10px'
                            >
                                {' '}
                                <Grid item container xs={12} md={6}>
                                    {' '}
                                    <Grid item xs={12} lg={5}>
                                        <Typography
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            First Name
                                            {firstName === '' ? (
                                                <span
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                >
                                                    *
                                                </span>
                                            ) : null}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <CustomTextField
                                            // disabled
                                            fullWidth
                                            placeholder=''
                                            value={firstName}
                                            onChange={handleFirstName}
                                            color='neutral'
                                            inputProps={{
                                                style: {
                                                    height: '10px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={6}>
                                    {' '}
                                    <Grid item xs={12} lg={5}>
                                        <Typography
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            Surname
                                            {surname === '' ? (
                                                <span
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                >
                                                    *
                                                </span>
                                            ) : null}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <CustomTextField
                                            // disabled
                                            value={surname}
                                            onChange={handleLastName}
                                            placeholder=''
                                            fullWidth
                                            color='neutral'
                                            inputProps={{
                                                style: {
                                                    height: '10px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={6}>
                                    {' '}
                                    <Grid item xs={12} lg={5}>
                                        <Stack
                                            direction='row'
                                            gap='5px'
                                            alignItems='center'
                                        >
                                            <Typography
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                Status
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    <Typography
                                                        // color='#787878'
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                        }}
                                                    >
                                                        {loggedInUser?.role ===
                                                        'admin' ? (
                                                            <List>
                                                                <ListItem>
                                                                    {' '}
                                                                    Enabled:
                                                                    Users can
                                                                    access the
                                                                    site and
                                                                    perform
                                                                    actions
                                                                    based on
                                                                    their level
                                                                    of access
                                                                </ListItem>
                                                                <ListItem>
                                                                    {' '}
                                                                    Disabled:
                                                                    Users will
                                                                    not be able
                                                                    to access
                                                                    the site
                                                                </ListItem>
                                                            </List>
                                                        ) : (
                                                            'You do not have permission to change this. Please contact your administrator.'
                                                        )}
                                                    </Typography>
                                                }
                                                placement='right'
                                            >
                                                <HelpOutlineIcon
                                                    sx={{
                                                        color: '#787878',
                                                        fontSize: '16px',
                                                    }}
                                                />
                                            </Tooltip>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        {' '}
                                        <CustomTextField
                                            disabled
                                            value={
                                                profileDetails?.isActive ===
                                                true
                                                    ? 'Enabled'
                                                    : 'Disabled'
                                            }
                                            fullWidth
                                            color='neutral'
                                            inputProps={{
                                                style: {
                                                    height: '10px',
                                                    backgroundColor: '#f6f8f9',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={6}>
                                    {' '}
                                    <Grid item xs={12} lg={5}>
                                        <Stack
                                            direction='row'
                                            gap='5px'
                                            alignItems='center'
                                        >
                                            {' '}
                                            <Typography
                                                color='#787878'
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                Access
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    <Typography
                                                        // color='#787878'
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                        }}
                                                    >
                                                        {loggedInUser?.role ===
                                                        'admin' ? (
                                                            <List>
                                                                <ListItem>
                                                                    {' '}
                                                                    Admin:
                                                                    Add/Edit/View
                                                                    Users and
                                                                    Clients
                                                                </ListItem>
                                                            </List>
                                                        ) : (
                                                            'You do not have permission to change this. Please contact your administrator.'
                                                        )}
                                                    </Typography>
                                                }
                                                placement='right'
                                            >
                                                <HelpOutlineIcon
                                                    sx={{
                                                        color: '#787878',
                                                        fontSize: '16px',
                                                    }}
                                                />
                                            </Tooltip>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        {' '}
                                        <CustomTextField
                                            value={
                                                loggedInUser?.role ===
                                                'owner_admin'
                                                    ? 'Admin'
                                                    : loggedInUser?.role ===
                                                      'owner_user'
                                                    ? 'User'
                                                    : loggedInUser?.role ===
                                                      'guest_department_admin'
                                                    ? 'Team Admin'
                                                    : loggedInUser?.role ===
                                                      'guest_user'
                                                    ? 'User'
                                                    : 'Company Admin'
                                            }
                                            fullWidth
                                            disabled
                                            color='neutral'
                                            inputProps={{
                                                style: {
                                                    height: '10px',
                                                    backgroundColor: '#f6f8f9',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={6}>
                                    {' '}
                                    <Grid item xs={12} lg={5}>
                                        <Typography
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            Phone
                                            {phoneNumber === '' ? (
                                                <span
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                >
                                                    *
                                                </span>
                                            ) : null}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <CustomTextField
                                            placeholder=''
                                            type='tel'
                                            fullWidth
                                            value={phoneNumber}
                                            onChange={handlePhone}
                                            color={
                                                isValidPhone ? 'red' : 'green'
                                            }
                                            inputProps={{
                                                style: {
                                                    height: '10px',
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {loggedInUser?.role.includes('guest') && (
                                    <Grid item container xs={12} md={6}>
                                        {' '}
                                        <Grid item xs={12} lg={5}>
                                            <Stack
                                                direction='row'
                                                gap='5px'
                                                alignItems='center'
                                            >
                                                {' '}
                                                <Typography
                                                    color='#787878'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                    }}
                                                >
                                                    Team
                                                    {loggedInUser?.role ===
                                                        'guest_department_admin' &&
                                                    !team ? (
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    ) : null}
                                                </Typography>
                                                {loggedInUser?.role !==
                                                    'guest_admin' ||
                                                    (loggedInUser?.role !==
                                                        'guest_department_admin' && (
                                                        <Tooltip
                                                            title={
                                                                <Typography
                                                                    // color='#787878'
                                                                    sx={{
                                                                        fontFamily:
                                                                            'Open Sans',
                                                                    }}
                                                                >
                                                                    <List>
                                                                        You do
                                                                        not have
                                                                        permission
                                                                        to
                                                                        change
                                                                        this.
                                                                        Please
                                                                        contact
                                                                        your
                                                                        company
                                                                        admin or
                                                                        team
                                                                        admin to
                                                                        update
                                                                        this.
                                                                    </List>
                                                                </Typography>
                                                            }
                                                            placement='right'
                                                        >
                                                            <HelpOutlineIcon
                                                                sx={{
                                                                    color: '#787878',
                                                                    fontSize:
                                                                        '16px',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    ))}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            {loggedInUser?.role ===
                                            'guest_admin' ? (
                                                <DropdownFilter
                                                    id={'teamList'}
                                                    options={[
                                                        {
                                                            value: '',
                                                            label: '+ Add Team',
                                                        },
                                                    ].concat(
                                                        teamList !== undefined
                                                            ? teamList
                                                            : []
                                                    )}
                                                    handleChange={handleTeamId}
                                                    selectedValue={
                                                        <Typography
                                                            color='#787878'
                                                            sx={{
                                                                fontFamily:
                                                                    'Open Sans',
                                                            }}
                                                        >
                                                            {team?.name
                                                                ? team?.name
                                                                : teamId?.label
                                                                      ?.charAt(
                                                                          0
                                                                      )
                                                                      ?.toUpperCase() +
                                                                  teamId?.label.slice(
                                                                      1
                                                                  )}
                                                        </Typography>
                                                    }
                                                    backgroundColor={'white'}
                                                    border={
                                                        loggedInUser?.role ===
                                                            'guest_department_admin' &&
                                                        !team
                                                            ? 'red'
                                                            : '#C3C3C3'
                                                    }
                                                />
                                            ) : (
                                                <CustomTextField
                                                    disabled
                                                    value={
                                                        profileDetails
                                                            ?.department?.name
                                                            ? profileDetails?.department?.name
                                                                  ?.charAt(0)
                                                                  ?.toUpperCase() +
                                                              profileDetails?.department?.name.slice(
                                                                  1
                                                              )
                                                            : '-'
                                                    }
                                                    fullWidth
                                                    color='neutral'
                                                    inputProps={{
                                                        style: {
                                                            height: '10px',
                                                            backgroundColor:
                                                                '#f6f8f9',
                                                        },
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid item container xs={12} md={6}>
                                    {' '}
                                    <Grid item xs={12} lg={5}></Grid>
                                    <Grid item xs={12} lg={7}></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: '100%',
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            component='h4'
                            // variant='h6'
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                textAlign: 'left',
                                fontWeight: 900,
                                paddingTop: isMobile ? '20px' : '30px',
                                paddingBottom: '30px',
                                height: '10%',
                            }}
                        >
                            Login Details
                        </Typography>{' '}
                        <Grid container columnSpacing='50px' rowSpacing='10px'>
                            <Grid item container xs={12} md={6}>
                                {' '}
                                <Grid item xs={12} lg={5}>
                                    <Stack
                                        direction='row'
                                        gap='5px'
                                        alignItems='center'
                                    >
                                        {' '}
                                        <Typography
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            Email
                                            {emailAddress === '' ? (
                                                <span
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                >
                                                    *
                                                </span>
                                            ) : null}
                                        </Typography>
                                        <Tooltip
                                            title={
                                                <Typography
                                                    // color='#787878'
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                    }}
                                                >
                                                    {loggedInUser?.role ===
                                                    'admin'
                                                        ? null
                                                        : 'You do not have permission to change this. Please contact your administrator.'}
                                                </Typography>
                                            }
                                            placement='right'
                                        >
                                            <HelpOutlineIcon
                                                sx={{
                                                    color: '#787878',
                                                    fontSize: '16px',
                                                }}
                                            />
                                        </Tooltip>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    {' '}
                                    <CustomTextField
                                        // placeholder='Email example here'
                                        error={isDuplicateEmail}
                                        label={
                                            isDuplicateEmail === true
                                                ? 'Error: User already exists'
                                                : ''
                                        }
                                        disabled={
                                            !loggedInUser?.role.includes(
                                                'admin'
                                            )
                                        }
                                        value={
                                            !isDuplicateEmail
                                                ? emailAddress
                                                : null
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        onChange={handleEmail}
                                        color={
                                            isValidEmail ? 'green' : 'neutral'
                                        }
                                        inputProps={{
                                            style: {
                                                height: '10px',
                                                backgroundColor:
                                                    !loggedInUser?.role.includes(
                                                        'admin'
                                                    )
                                                        ? '#f6f8f9'
                                                        : '#fff',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} md={6}>
                                {' '}
                                <Grid item xs={12} lg={5}>
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Password
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={7}
                                    sx={{ position: 'relative' }}
                                >
                                    <CustomTextField
                                        placeholder='********'
                                        fullWidth
                                        disabled
                                        color={'neutral'}
                                        inputProps={{
                                            style: {
                                                height: '10px',
                                                backgroundColor: '#f6f8f9',
                                            },
                                        }}
                                    />
                                    <CustomIconButton
                                        variant='contained'
                                        onClick={onClickOpenModal}
                                        sx={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                        }}
                                    >
                                        <EditIcon sx={{ fontSize: '18px' }} />
                                    </CustomIconButton>
                                </Grid>
                            </Grid>

                            <Grid
                                sx={{
                                    width: isMobile || isIpad ? '100%' : '30%',
                                    height: isMobile || isIpad ? '' : '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Grid
                                    sx={{
                                        width: '10%',
                                        paddingLeft: isMobile
                                            ? '0px'
                                            : isIpad
                                            ? '30px'
                                            : '0px',
                                    }}
                                >
                                    {' '}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                height: 'auto',
                                paddingTop: '50px',
                                paddingBottom: '50px',
                            }}
                        >
                            <Grid
                                sx={{
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {' '}
                                <CustomButton
                                    onClick={onClickGoBack}
                                    color='green'
                                    sx={{
                                        // top: isMobile || isIpad ? '10%' : '20%',
                                        marginLeft: !isMobile
                                            ? '-8px'
                                            : '-18px',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: '#5cb5b3',
                                            backgroundColor: 'transparent',
                                            background: 'none',
                                        },
                                        // alignItems: 'flex-end'
                                    }}
                                >
                                    <ArrowBackIcon />{' '}
                                    {isMobile ? null : (
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                paddingLeft: '5px',
                                                fontSize: '12px',
                                            }}
                                        >
                                            Back To Profile
                                        </Typography>
                                    )}
                                </CustomButton>
                            </Grid>
                            <Grid
                                sx={{
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <CustomButton
                                    variant='contained'
                                    color='green'
                                    onClick={editProfile}
                                    disabled={
                                        isValidEmail ||
                                        isValidPhone ||
                                        errors.length > 0
                                    }
                                    // sx={{
                                    //     top: isMobile ? '10%' : '50%',
                                    // }}
                                >
                                    Save Changes
                                </CustomButton>
                            </Grid>
                        </Grid>
                        <EditPasswordModal
                            open={openModal}
                            onClose={handleCloseModal}
                        />
                    </Grid>
                </>
            )}
        </>
    );
}

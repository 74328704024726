import { Box, Card, Grid, InputLabel, Typography, useMediaQuery, useTheme } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import KitchenIcon from '@mui/icons-material/Kitchen';
import WifiIcon from '@mui/icons-material/Wifi';
import TvIcon from '@mui/icons-material/Tv';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DeskIcon from '@mui/icons-material/Desk';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import PoolIcon from '@mui/icons-material/Pool';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import { TimeArray } from './TimeArray';
import { LrgNumberArray, SmlNumberArray } from './NumberArray';
import React from 'react';
import HotTubIcon from '@mui/icons-material/HotTub';
import WineBarIcon from '@mui/icons-material/WineBar';
import SpaIcon from '@mui/icons-material/Spa';
import { Liquor, Restaurant } from '@mui/icons-material';
import FormAutocomplete from '../../../../shared/FormAutocomplete';
import { Controller, useFormContext, useWatch } from 'react-hook-form' 
import FormTextField from '../../../../shared/FormTextField';
import { capitalizeName } from '../../../../../shared/utils';

export default function NewPropertyAmenitiesForm() {
    const theme = useTheme();

    const isIpad = useMediaQuery('(max-width: 1200px)');

    const { control, setValue } = useFormContext();

    const items = [
        {
            name: 'catering',
            selected: false,
            icon: <Restaurant fontSize='large' />,
        },
        {
            name: 'alcohol',
            selected: false,
            icon: <Liquor fontSize='large' />,
        },
        {
            name: 'wifi',
            selected: false,
            icon: <WifiIcon fontSize='large' />,
        },
        {
            name: 'boardroom',
            selected: false,
            icon: <BusinessCenterIcon fontSize='large' />,
        },
        {
            name: 'tv',
            selected: false,
            icon: <TvIcon fontSize='large' />,
        },
        {
            name: 'kitchen',
            selected: false,
            icon: <KitchenIcon fontSize='large' />,
        },
        {
            name: 'parking',
            selected: false,
            icon: <LocalParkingIcon fontSize='large' />,
        },

        {
            name: 'aircon',
            selected: false,
            icon: <AcUnitIcon fontSize='large' />,
        },
        {
            name: 'dedicatedWorkspace',
            selected: false,
            icon: <DeskIcon fontSize='large' />,
        },
        {
            name: 'pool',
            selected: false,
            icon: <PoolIcon fontSize='large' />,
        },
        {
            name: 'spa',
            selected: false,
            icon: <HotTubIcon fontSize='large' />,
        },
        {
            name: 'bbq',
            selected: false,
            icon: <OutdoorGrillIcon fontSize='large' />,
        },
        {
            name: 'firepit',
            selected: false,
            icon: <LocalFireDepartmentIcon fontSize='large' />,
        },
        {
            name: 'washingMachine',
            selected: false,
            icon: <LocalLaundryServiceIcon fontSize='large' />,
        },
        {
            name: 'gamesRoom',
            selected: false,
            icon: <SportsEsportsIcon fontSize='large' />,
        },

        {
            name: 'wineCellar',
            selected: false,
            icon: <WineBarIcon fontSize='large' />,
        },
        {
            name: 'massages',
            selected: false,
            icon: <SpaIcon fontSize='large' />,
        },
    ];

    return (
        <>
            <Typography
                color='textPrimary'
                pb='30px'
                pt={isIpad ? '10px' : '0px'}
            >
                Tell guests what your property has to offer.
            </Typography>
            
            <Grid
                container
                columnSpacing={5}
                rowSpacing={1}
                pb='15px'
            >
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Property Type</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete 
                            name='details.subType'
                            control={control}
                            options={[
                                { value: 'apartment', label: 'Apartment' },
                                { value: 'cabin', label: 'Cabin' },
                                { value: 'duplex', label: 'Duplex' },
                                { value: 'house', label: 'House' },
                                { value: 'unit', label: 'Unit' },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Bedrooms</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.bedrooms'
                            control={control}
                            options={SmlNumberArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Beds</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.beds'
                            control={control}
                            options={SmlNumberArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Bathrooms</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.baths'
                            control={control}
                            options={SmlNumberArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Day Guests</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.maxGuests'
                            control={control}
                            options={LrgNumberArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Overnight Guests</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.overnightGuests'
                            control={control}
                            options={LrgNumberArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel>Wifi Details</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormTextField
                            name='details.wifiDetails'
                            control={control}
                            placeholder='FREE WIFI, PW: 123456. This information will be displayed 24 hours before check-in and hidden after completed.'
                            inputProps={{ maxLength: 500 }}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Typography
                color='textPrimary'
                pb='20px'
                pt='30px'
            >
                Select available amenities:
            </Typography>
            <Grid container columns={{ lg: 7, md: 4, xs: 2 }} spacing={2}>
                {items.map((item, i) => (
                    <Grid item xs={1} key={i}>
                        <Controller 
                            name={`details.${item.name}`}
                            control={control}
                            render={({ field: { value }}) => (
                                <Card
                                    sx={{ 
                                        height: '100px',
                                        bgcolor: value ? theme.palette.primary.main : '#f6f8f9',
                                        color: value ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                    }}
                                    onClick={() => setValue(`details.${item.name}`, !value)}
                                >
                                    <Box 
                                        display='flex'
                                        flexDirection='column'
                                        alignItems='center'
                                        height='100%'
                                        gap={1}
                                        justifyContent='center'
                                        textAlign='center'
                                    >
                                        {item.icon}
                                        <Typography>
                                            {item.name === 'bbq' ? 'BBQ' : 
                                             item.name === 'tv' ? 'TV' : 
                                             item.name === 'dedicatedWorkspace' ? 'Dedicated Workspace' : 
                                             item.name === 'washingMachine' ? 'Laundry' : 
                                             item.name === 'gamesRoom' ? 'Games Room' : 
                                             item.name === 'wineCellar' ? 'Wine Cellar' : 
                                             capitalizeName(item.name)}
                                        </Typography>
                                    </Box>
                                </Card>
                            )}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

import { IconButton, InputAdornment, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../shared/AuthRoles';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { CustomTextField } from '../../layout/styledcomponents/CustomTextField';
import { selectReportAPIRefSliceData } from '../../app/Slices/ReportAPIRefSlice';
import ReactGA from 'react-ga4';

const defaultSearchValueParser = (searchText: any) =>
    searchText.split(' ').filter((word: any) => word !== '');

export default function SearchFilter(props: any) {
    const {
        quickFilterParser = defaultSearchValueParser,
        debounceMs = 500,
        ...other
    } = props;
    const reportExportAPI: any = useSelector(selectReportAPIRefSliceData);
    const [searchValue, setSearchValue] = React.useState('');

    const handleSearchValueChange = (event: { target: { value: any } }) => {
        const newSearchValue = event.target.value;
        setSearchValue(newSearchValue);
        updateSearchValue(event.target.value);
        ReactGA.event({
            category: 'UI',
            action: `Search Filter`,
            label: `Search Filter Reservation List`,
        });
    };

    const updateSearchValue = React.useCallback(
        (newSearchValue: any) => {
            reportExportAPI.search(quickFilterParser(newSearchValue));
        },
        [reportExportAPI, quickFilterParser]
    );

    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <>
            <CustomTextField
                fullWidth={isMobile}
                color='neutral'
                placeholder='Search...'
                onChange={handleSearchValueChange}
                InputProps={{
                    style: {
                        minHeight: '30px',
                        maxHeight: '38px',
                        
                    },
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton disableRipple>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = 'https://date.nager.at/api/v3/PublicHolidays';

export const publicHolidaysApi = createApi({
    reducerPath: 'publicHolidaysApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        getAllPublicHolidaysForYearAndCountry: builder.query<
            any[],
            { country: string; year: number }
        >({
            query: ({ country, year }) => `/${year}/${country}`,
        }),
    }),
});

export const { useGetAllPublicHolidaysForYearAndCountryQuery } =
    publicHolidaysApi;

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Divider,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
    useImportGuestUsersMutation,
    useValidateImportGuestUsersMutation,
} from '../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../shared/AuthRoles';
import GenericModal from '../../shared/GenericModal';

const ImportUsersModal = (props: any) => {
    const { onClose, showModal } = props;

    const loggedInUser = UserRole();
    const [validateImportUsers] = useValidateImportGuestUsersMutation();
    const [importUsers] = useImportGuestUsersMutation();
    const [usersForImport, setUsersForImport] = useState<any>();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [validatedFile, setValidatedFile] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [loading, setLoading] = useState(false);

    const getFieldColor = (user: any, property: string) => ({
        fontFamily: 'Open Sans',
        color:
            user?.errors &&
            user?.errors?.find((error: any) => error.property === property)
                ? '#D0342C'
                : '#787878',
    });

    useEffect(() => {
        if (!showModal) {
            setUsersForImport(null);
        }
    }, [showModal]);

    const downloadSpreadsheet = () => {
        const a = document.createElement('a');
        a.href = '/ImportCSVfile.xlsx';
        a.download = 'ImportCSVfile.xlsx';
        a.click();
    };
    const handleValidateUsers = (e: any) => {
        const file = e.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            const requestInfo = [loggedInUser.company, formData];

            validateImportUsers(requestInfo)
                .then((data: any) => {
                    if (data?.error) {
                        setUsersForImport(data);
                        setFileUploaded(true);
                        return;
                    }
                    setValidatedFile(file);
                    setUsersForImport(data);
                })
                .catch((error: any) => {
                    console.error(
                        'An error occurred during user validation:',
                        error
                    );
                    setUsersForImport({
                        error: 'An error occurred during user validation.',
                    });
                });

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleImportUsers = () => {
        if (validatedFile) {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', validatedFile);

            const requestInfo = [loggedInUser.company, formData];

            importUsers(requestInfo)
                .then((data) => {
                    //Artificial delay, so the very short loading period doesn't look like a lag in the app
                    setTimeout(() => {
                        setUsersForImport(data);
                        setLoading(false);
                    }, 500);
                })
                .catch((error: any) => {
                    console.error(
                        'An error occurred during user import:',
                        error
                    );
                    setTimeout(() => {
                        setUsersForImport({
                            error: 'An error occurred during user import.',
                        });
                        setLoading(false);
                    }, 500);
                });
        }
    };

    const dataToBeMapped = usersForImport?.error
        ? usersForImport?.error?.data
        : usersForImport?.data;

    const importedWithoutError =
        usersForImport?.data?.imported &&
        usersForImport?.data?.failed.length === 0 &&
        usersForImport?.data?.inviteFailed?.length === 0;

    const numberOfInvitations =
        usersForImport?.data?.imported?.length -
        usersForImport?.data?.inviteFailed?.length;

    const isBulletPoint =
        (usersForImport?.data?.failed &&
            usersForImport?.data?.failed.length > 0) ||
        (usersForImport?.data?.inviteFailed &&
            usersForImport?.data?.inviteFailed?.length > 0);

    const totalNumberOfUploadedUsers = usersForImport?.data?.imported
        ? usersForImport?.data?.imported?.length +
          usersForImport?.data?.failed?.length
        : 0;

    const increaseHeight =
        (usersForImport?.data?.inviteFailed &&
            usersForImport?.data?.inviteFailed?.length > 0) ||
        (usersForImport?.data?.failed &&
            usersForImport?.data?.failed.length > 0);

    return (
        <>
            {showModal && (
                <GenericModal
                    open={showModal}
                    onClose={onClose}
                    onChange={handleImportUsers}
                    dialogActions={true}
                    showCancelButton={true}
                    showConfirmButton={
                        usersForImport?.data &&
                        !importedWithoutError &&
                        dataToBeMapped?.length > 0
                    }
                    confirmText={loading ? <Grid>Import </Grid> : 'Import '}
                    loading={loading}
                    loadingColor={'neutral'}
                    loadingSize={16}
                    disableConfirm={usersForImport?.data?.imported || loading}
                    confirmColor={'green'}
                    cancelText={
                        !!usersForImport?.error ||
                        usersForImport?.data?.imported ||
                        !fileUploaded
                            ? 'Close'
                            : 'Cancel'
                    }
                >
                    <Grid
                        sx={{
                            padding: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            maxHeight: increaseHeight ? '800px' : '600px',
                            maxWidth: importedWithoutError
                                ? '800px'
                                : usersForImport
                                ? '1200px'
                                : '1000px',
                        }}
                    >
                        {usersForImport?.data?.imported &&
                            usersForImport?.data?.imported?.length === 0 && (
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        paddingBottom: '10px',
                                        alignSelf: 'flex-start',
                                    }}
                                >
                                    {isBulletPoint && (
                                        <span
                                            style={{
                                                fontSize: '1.5em',
                                                marginRight: '5px',
                                                verticalAlign: 'middle',
                                                lineHeight: '1',
                                                color: '#787878',
                                            }}
                                        >
                                            {'\u2022'}
                                        </span>
                                    )}
                                    {`${usersForImport?.data?.imported?.length}/${totalNumberOfUploadedUsers}`}{' '}
                                    users were imported
                                </Typography>
                            )}
                        {usersForImport?.data?.imported &&
                            usersForImport?.data?.imported?.length >
                                usersForImport?.data?.inviteFailed &&
                            usersForImport?.data?.inviteFailed?.length ===
                                0 && (
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        paddingBottom: '10px',
                                        alignSelf: 'flex-start',
                                    }}
                                >
                                    <span>
                                        <span
                                            style={{
                                                color:
                                                    numberOfInvitations > 0
                                                        ? '#5cb5b3'
                                                        : '#787878',
                                            }}
                                        >
                                            {isBulletPoint && (
                                                <span
                                                    style={{
                                                        fontSize: '1.5em',
                                                        marginRight: '5px',
                                                        verticalAlign: 'middle',
                                                        lineHeight: '1',
                                                        color: '#787878',
                                                    }}
                                                >
                                                    {'\u2022'}
                                                </span>
                                            )}
                                            {`${
                                                usersForImport?.data?.imported
                                                    ?.length
                                            }/${totalNumberOfUploadedUsers} ${
                                                usersForImport?.data?.imported
                                                    ?.length > 1
                                                    ? `users were imported successfully. `
                                                    : usersForImport?.data
                                                          ?.imported?.length ===
                                                      1
                                                    ? `user was imported successfully.`
                                                    : `users were imported.`
                                            } `}{' '}
                                        </span>
                                        {numberOfInvitations > 0 && (
                                            <span>
                                                {`An invitation has been sent to ${
                                                    numberOfInvitations === 1
                                                        ? `this user.`
                                                        : `these users.`
                                                } They will need to complete
                                            their profile after their first
                                            login.`}
                                            </span>
                                        )}
                                    </span>
                                </Typography>
                            )}
                        {usersForImport?.data?.imported &&
                            usersForImport?.data?.imported?.length >
                                usersForImport?.data?.inviteFailed?.length &&
                            usersForImport?.data?.inviteFailed?.length > 0 && (
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        paddingBottom: '10px',
                                        alignSelf: 'flex-start',
                                    }}
                                >
                                    {isBulletPoint && (
                                        <span
                                            style={{
                                                fontSize: '1.5em',
                                                marginRight: '5px',
                                                verticalAlign: 'middle',
                                                lineHeight: '1',
                                                color: '#787878',
                                            }}
                                        >
                                            {'\u2022'}
                                        </span>
                                    )}
                                    <span>
                                        <span
                                            style={{
                                                color:
                                                    numberOfInvitations > 0
                                                        ? '#5cb5b3'
                                                        : '#787878',
                                            }}
                                        >
                                            {`${numberOfInvitations}/${totalNumberOfUploadedUsers} ${`users imported.`} `}{' '}
                                        </span>
                                        {numberOfInvitations > 0 && (
                                            <span>
                                                {`Invitations have been sent to all successfully imported user/s.`}
                                            </span>
                                        )}
                                    </span>
                                </Typography>
                            )}

                        {usersForImport?.data?.inviteFailed?.length > 0 && (
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    paddingBottom: '10px',

                                    alignSelf: 'flex-start',
                                }}
                            >
                                {isBulletPoint && (
                                    <span
                                        style={{
                                            fontSize: '1.5em',
                                            marginRight: '5px',
                                            verticalAlign: 'middle',
                                            lineHeight: '1',
                                            color: '#787878',
                                        }}
                                    >
                                        {'\u2022'}
                                    </span>
                                )}
                                <span
                                    style={{ color: '#D0342C' }}
                                >{`Following ${
                                    usersForImport?.data?.inviteFailed?.length
                                } ${
                                    usersForImport?.data?.inviteFailed?.length >
                                    1
                                        ? `users were`
                                        : `user was`
                                } imported but failed to receive an invitation. If the problem persists please contact support.`}</span>
                            </Typography>
                        )}

                        {usersForImport?.data?.inviteFailed?.length > 0 && (
                            <>
                                <Divider
                                    sx={{ width: '120%', marginBottom: '20px' }}
                                />
                                <Stack
                                    paddingBottom={'20px'}
                                    sx={{ width: '100%', maxHeight: '300px' }}
                                >
                                    <UserTable
                                        users={
                                            usersForImport?.data
                                                ?.inviteFailed || []
                                        }
                                        getFieldColor={getFieldColor}
                                    />
                                </Stack>
                            </>
                        )}

                        {usersForImport?.data?.failed?.length > 0 && (
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    paddingBottom: '10px',
                                    alignSelf: 'flex-start',
                                }}
                            >
                                {isBulletPoint && (
                                    <span
                                        style={{
                                            fontSize: '1.5em',
                                            marginRight: '5px',
                                            verticalAlign: 'middle',
                                            lineHeight: '1',
                                            color: '#787878',
                                        }}
                                    >
                                        {'\u2022'}
                                    </span>
                                )}
                                <span style={{ color: '#D0342C' }}>
                                    {`Following ${
                                        usersForImport?.data?.failed?.length
                                    } ${
                                        usersForImport?.data?.failed?.length > 1
                                            ? 'users have '
                                            : 'user has '
                                    }not been imported as they already exist.`}
                                </span>
                            </Typography>
                        )}

                        {usersForImport?.data?.failed?.length > 0 && (
                            <>
                                <Divider
                                    sx={{ width: '120%', marginBottom: '20px' }}
                                />
                                <Stack
                                    paddingBottom={'20px'}
                                    sx={{ width: '100%', maxHeight: '300px' }}
                                >
                                    <UserTable
                                        users={
                                            usersForImport?.data?.failed || []
                                        }
                                        getFieldColor={getFieldColor}
                                    />
                                </Stack>
                            </>
                        )}

                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                paddingBottom: '10px',
                                color: usersForImport?.error
                                    ? '#D0342C'
                                    : '#787878',
                                alignSelf: 'flex-start',
                            }}
                        >
                            {!usersForImport ? (
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px',
                                    }}
                                >
                                    Please follow the steps below to import
                                    guest users
                                    <ol
                                        style={{
                                            paddingLeft: '20px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                        }}
                                    >
                                        <li>
                                            Download the Excel template file by
                                            clicking{' '}
                                            <span
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#5cb5b3',
                                                }}
                                                onClick={downloadSpreadsheet}
                                            >
                                                <FileDownloadIcon
                                                    fontSize='small'
                                                    sx={{ fontSize: '16px' }}
                                                />{' '}
                                                Users Spreadsheet
                                            </span>
                                            . This file contains the necessary
                                            fields and format required for the
                                            data you need to fill out.
                                        </li>
                                        <li>
                                            Open the downloaded Excel file and
                                            carefully fill out all the required
                                            fields. Ensure that you do not
                                            change the format or structure of
                                            the file.
                                        </li>
                                        <li>
                                            Once you've completed filling out
                                            the Excel file, save it as a CSV
                                            (Comma-Separated Values) file.
                                        </li>
                                        <li>
                                            After saving your file in CSV
                                            format, return to this page and use
                                            the 'UPLOAD CSV' button to submit
                                            your completed file.
                                        </li>
                                    </ol>
                                </Typography>
                            ) : usersForImport?.error &&
                              !usersForImport?.error?.data?.message ? (
                                <span>
                                    Before we can proceed with import
                                    {`${
                                        usersForImport?.error?.data?.length > 1
                                            ? `, there are issues with ${usersForImport?.error?.data?.length} of users. 
                                                Please address the issues listed below and try uploading again.`
                                            : usersForImport?.error?.data
                                                  ?.length === 1 &&
                                              usersForImport?.error?.data[0]
                                                  ?.errors?.length > 1
                                            ? `, there are issues with ${usersForImport?.error?.data?.length} user that need to be fixed. Please address the issues highlighted in red and try uploading again. `
                                            : usersForImport?.error?.data
                                                  ?.length === 1 &&
                                              usersForImport?.error?.data[0]
                                                  ?.errors?.length === 1
                                            ? ', there is an issue that needs to be fixed. Please address the issue highlighted in red and try uploading again.'
                                            : ''
                                    }`}
                                </span>
                            ) : usersForImport?.data &&
                              usersForImport?.data.length === 0 ? (
                                `Your CSV file seems to be empty. Please enter the list of users and try uploading again.`
                            ) : usersForImport?.data &&
                              usersForImport?.data.length > 0 ? (
                                <>
                                    {`The following `}
                                    <strong>
                                        {usersForImport?.data?.length}
                                    </strong>
                                    {` user${
                                        usersForImport?.data?.length > 1
                                            ? 's are'
                                            : ' is'
                                    } ready to be imported. After you click 'Import', an invitation will be sent to ${
                                        usersForImport?.data?.length > 1
                                            ? 'each of these users'
                                            : 'this user'
                                    }.`}
                                </>
                            ) : usersForImport?.error?.data?.message ? (
                                `We couldn't upload the file. Please make sure your CSV file is in the right format and try uploading again `
                            ) : (
                                ``
                            )}
                        </Typography>
                        {usersForImport && dataToBeMapped?.length > 0 && (
                            <>
                                <Divider
                                    sx={{ width: '120%', marginBottom: '20px' }}
                                />
                                <UserTable
                                    users={dataToBeMapped || []}
                                    getFieldColor={getFieldColor}
                                />
                            </>
                        )}

                        {!usersForImport && (
                            <Grid>
                                <UploadButton
                                    fileInputRef={fileInputRef}
                                    label={'upload csv'}
                                    onChange={handleValidateUsers}
                                />
                            </Grid>
                        )}

                        {(usersForImport?.error ||
                            dataToBeMapped?.length === 0) && (
                            <Grid sx={{ paddingTop: '30px' }}>
                                <UploadButton
                                    label={'upload again'}
                                    onChange={handleValidateUsers}
                                    fileInputRef={fileInputRef}
                                />
                            </Grid>
                        )}
                    </Grid>
                </GenericModal>
            )}
        </>
    );
};

export default ImportUsersModal;

interface ErrorTooltipProps {
    user: any;
    property: string;
}

const ErrorTooltip: React.FC<ErrorTooltipProps> = ({ user, property }) => {
    const errorObject = user.errors
        ? user?.errors.find((error: any) => error.property === property)
        : null;

    if (!errorObject) {
        return null;
    }

    const errorMessages = errorObject?.errors
        ? errorObject.errors.map((errorMessage: string) => {
              if (errorMessage === 'email address for login must be an email') {
                  return "An email address should be in the format 'username@example.com'";
              }
              return errorMessage;
          })
        : '';

    return (
        <Grid>
            <Tooltip
                title={
                    <Typography sx={{ fontFamily: 'Open Sans' }}>
                        {errorMessages.join(' & ')}
                    </Typography>
                }
                placement='right'
            >
                <HelpOutlineIcon
                    sx={{
                        color: '#D0342C',
                        fontSize: '16px',
                    }}
                />
            </Tooltip>
        </Grid>
    );
};

interface UserInfoCellProps {
    user: any;
    property: string;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
    getFieldColor: (user: any, property: string) => object;
}

const UserInfoCell: React.FC<UserInfoCellProps> = ({
    user,
    property,
    align = 'left',
    getFieldColor,
}) => (
    <TableCell align={align}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
            {' '}
            <Typography sx={getFieldColor(user, property)}>
                {user?.user ? user.user[property] : user[property]}
            </Typography>
            {user?.user && <ErrorTooltip user={user} property={property} />}
        </div>
    </TableCell>
);

const UploadButton = ({
    onChange,
    label,
    fileInputRef,
}: {
    onChange: any;
    label: any;
    fileInputRef: any;
}) => {
    return (
        <label
            style={{
                width: '170px',
                height: 'auto',
                backgroundColor: '#5cb5b3',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '6px 12px',
                textAlign: 'center',
                borderRadius: '5px',
                boxShadow: '1px 2px 3px -2px',
                transition: 'box-shadow 0.2s ease-in-out',
                cursor: 'pointer',
                userSelect: 'none',
            }}
            onMouseEnter={(e) => {
                e.currentTarget.style.boxShadow = '1px 2px 8px -2px';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.boxShadow = '1px 2px 3px -2px';
            }}
            onMouseDown={(e) => {
                e.currentTarget.style.boxShadow = '1px 2px 11px -2px';
            }}
            onMouseUp={(e) => {
                e.currentTarget.style.boxShadow = '1px 2px 8px -2px';
            }}
        >
            <Grid
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '5px',
                    flexDirection: 'row',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: 'white',
                        textTransform: 'uppercase',
                        display: 'flex',
                        fontSize: '14px',
                        gap: '3px',
                    }}
                >
                    <CloudUploadIcon
                        sx={{
                            fontSize: '24px',
                        }}
                    />{' '}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: 9 >= 10 ? '#787878' : 'white',
                        textTransform: 'uppercase',
                        fontSize: '14px',
                    }}
                >
                    <span>{label}</span>
                </Typography>
            </Grid>

            <input
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={onChange}
                type='file'
                accept='.csv'
            />
        </label>
    );
};

interface UserTableProps {
    users: any[];
    getFieldColor: (user: any, property: string) => object;
}

const UserTable: React.FC<UserTableProps> = ({ users, getFieldColor }) => (
    <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table aria-label='simple table' stickyHeader>
            <TableHead sx={{ backgroundColor: '#f6f8f9' }}>
                <TableRow>
                    {[
                        'First Name',
                        'Last Name',
                        'Phone',
                        'Team/Department',
                        'Level of Access',
                        'Email',
                    ].map((header) => (
                        <TableCell
                            key={header}
                            align='left'
                            sx={{ bgcolor: '#f6f8f9' }}
                        >
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 550,
                                    textTransform: 'uppercase',
                                }}
                            >
                                {header}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {users.map((user, index) => (
                    <TableRow
                        key={index}
                        sx={{
                            '&:last-child td, &:last-child th': {
                                border: 0,
                            },
                        }}
                    >
                        <UserInfoCell
                            user={user}
                            property='first name'
                            getFieldColor={getFieldColor}
                        />
                        <UserInfoCell
                            user={user}
                            property='last name'
                            getFieldColor={getFieldColor}
                        />
                        <UserInfoCell
                            user={user}
                            property='phone'
                            getFieldColor={getFieldColor}
                        />
                        <UserInfoCell
                            user={user}
                            property='team/department'
                            getFieldColor={getFieldColor}
                        />
                        <UserInfoCell
                            user={user}
                            property='level of access'
                            getFieldColor={getFieldColor}
                        />
                        <UserInfoCell
                            user={user}
                            property='email address for login'
                            getFieldColor={getFieldColor}
                        />
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TextField } from '@mui/material';
import React, { forwardRef } from 'react';
const theme = createTheme({
    palette: {
        // @ts-ignore
        neutral: {
            main: '#787878',
            contrastText: '#fff',
        },
        green: {
            main: '#5cb5b3',
            contrastText: '#fff',
        },
        other: {
            main: '#5cb5b3',
            contrastText: '#5cb5b3',
        },
        roundButton: {
            main: '#addad9',
            contrastText: '#5cb5b3',
        },
        red: {
            main: 'red',
            contrastText: '#5cb5b3',
        },
    },
});

export const CustomTextField = forwardRef((props: any, ref) => {
    const { children, ...rest } = props;
    return (
        <ThemeProvider theme={theme}>
            <TextField
                inputProps={{
                    autoComplete: 'none',
                }}
                sx={{
                    fontFamily: 'Open Sans',
                    color: '#787878',
                    input: {
                        fontFamily: 'Open Sans',
                        height: '5px',
                        color: '#787878',
                    },
                }}
                {...rest}
                ref={ref}
                autoComplete='off'
            >
                {children}
            </TextField>
        </ThemeProvider>
    );
});

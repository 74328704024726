import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface IFilterState {
    value: IFilterType;
}

interface IFilterType {
    isLoading?: any;
}

const initialState: IFilterState = {
    value: {
        isLoading: false,
    },
};
export const slice = createSlice({
    name: 'loadingSlice',
    initialState: { ...initialState },
    reducers: {
        openLoadingSliceAction: (state, action: PayloadAction<IFilterType>) => {
            state.value.isLoading =
                action.payload && action.payload.isLoading;
        },
    },
});
export const { openLoadingSliceAction } = slice.actions;
export const selectLoadingSliceData = (state: RootState) =>
    state.loadingSlice.value;
export const LoadingSliceReducer = slice.reducer;

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { BasePolicy } from '../../types/policy'
import { capitalizeName } from '../../../shared/utils'
import { CustomButton } from '../../../layout/styledcomponents/CustomButton'

interface ModalProps {
    policy: BasePolicy
    open: boolean
    onClose: () => void
}

const PolicyModal = ({ policy, open, onClose }: ModalProps) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle
                fontFamily='Open Sans'
                color='#787878'
            >
                {capitalizeName(policy.name)}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText
                    fontFamily='Open Sans'
                    color='#787878'
                    sx={{ whiteSpace: 'pre-line' }}
                >
                    {policy.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions 
                sx={{
                    borderTop: ' #d8d8d8 solid 1px',
                    bgcolor: '#eeeeee'
                }}
            >
                <CustomButton
                    color='green'
                    variant='contained'
                    onClick={onClose}
                >
                    Close
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default PolicyModal
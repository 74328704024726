import { BaseTextFieldProps, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface FormTextFieldProps extends Omit<TextFieldProps, 'variant'> {
    /**
     * Specifies the key in the form's data object
     */
    name: string;
    /**
     * Connects field to the form context
     */
    control: Control<any>;
    /**
     * Custom validation errors
     */
    customError?: string;
}

/**
 * For use within RHF FormProvider
 */
const FormTextField = ({
    name,
    control,
    customError,
    ...props
}: FormTextFieldProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { value, onChange, ref },
                fieldState: { error },
            }) => (
                <TextField
                    {...props}
                    inputRef={ref}
                    size='small'
                    fullWidth
                    value={value}
                    onChange={onChange}
                    error={!!error || !!customError}
                    helperText={(error && error.message) || customError}
                    onWheel={(e) => {
                        const target = e.target as HTMLInputElement;
                        target.blur();
                    }}
                />
            )}
        />
    );
};

export default FormTextField;

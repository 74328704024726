import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useVerifyEmailMutation } from '../../../app/Slices/SevenDayApiSlice';
import toast from '../../shared/Toast';

const VerifyEmailPage = () => {
    const { userId, token } = useParams();
    const [verifyEmail] = useVerifyEmailMutation();
    const navigate = useNavigate();

    useEffect(() => {
        verifyEmail({ userId, token })
            .then((data: any) => {
                if (data?.error) {
                    toast.error(data.error.data.message);
                } else {
                    toast.success('Your email is verified successfully.');
                }
            })
            .catch(() => {
                toast.error('Failed to verify email. Please try again.');
            })
            .finally(() => {
                navigate(`/login`, { replace: true });
            });
    }, []);

    return <></>;
};
export default VerifyEmailPage;

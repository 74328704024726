import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function ApplyTeam() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        As a Team Admin, User or User (view Only), you do
                        not have access to select your team or change
                        team. To assign yourself a 'Team' you will
                        need to ask your Company Admin to make this selection
                        for you.
                        <ul>
                            <br></br>
                            <li>
                                COMPANY ADMIN – Has access to create and edit
                                user profiles including updating their
                                Team and they cannot change their own
                                team.
                            </li>
                            <li>
                                TEAM ADMIN – Has access to create a user
                                profile including adding their Team. They
                                cannot edit an existing users team.
                            </li>

                            <li>USER – Cannot change their team.</li>
                            {loggedInUser?.role.includes('guest') ? (
                                <li>
                                    VIEW ONLY USER – Cannot change their
                                    team.
                                </li>
                            ) : null}
                        </ul>
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To apply a 'Team' to a user (If you're the company
                        Admin.):
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        <ol>
                            <br></br>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top right corner of the app, click on
                                the cog wheel icon
                            </li>
                            <li>Select 'Users'</li>
                            <li>
                                From the list, select the user you would like to
                                apply a Team too.
                            </li>
                            <li>Click the edit icon</li>
                            <li>Select the 'Team' from the dropdown list</li>
                            <br></br>
                            Note: If the 'Team' does not appear in the
                            list, you will need to follow 'How to Create A
                            Team'
                        </ol>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Card,
    CardActionArea,
    CardContent,
    Fade,
    Grid,
    useTheme,
    Stack,
    Typography,
} from '@mui/material';
import { keyframes } from '@mui/system';
import React, { useState } from 'react';
import {
    camelCaseToLowerCase,
    camelCaseToSentenceCase,
    camelCaseToTitleCase,
} from '../../../../shared/utils';
import { Space } from '../../../types/experience';
import SpaceModal from './SpaceModal';
import { SpaceIcons } from '../SpaceCard';

export const getBeddingArray = (space: Space): [string, number][] => {
    return Object.entries(space).filter(
        ([key, value]) =>
            [
                'singleBed',
                'doubleBed',
                'queenBed',
                'kingBed',
                'bunkBed',
                'sofaBed',
                'couch',
                'babyCot',
            ].includes(key) && Number(value) > 0
    );
};

export const getAmenitiesArray = (space: Space): string[] => {
    return Object.entries(space)
        .filter(([key, value]) => value === true && key !== 'newSpace')
        .map(([key]) => key);
};

const fadeInZoomAnimation = keyframes`
    from {
        opacity: 0; 
        transform: scale(0.95); 
    }
    to {
        opacity: 1; 
        transform: scale(1); 
    }
`;

export type ModifiedSpace = Space & {
    newSpace?: boolean;
    name: string;
    id: string;
};

const IndividualSpace = ({
    space,
    handleDelete,
    handleUpdate,
    index,
}: {
    space: ModifiedSpace;
    handleDelete: (i: number) => void;
    handleUpdate: (updatedSpace: Space) => void;
    index: number;
}) => {
    const [showModal, setShowModal] = useState(false);
    const theme = useTheme();

    const handleEditSpace = (updatedSpace: Space) => {
        handleUpdate(updatedSpace);
        setShowModal(false);
    };

    return (
        <Grid item xs={1}>
            <Card
                onClick={() => {
                    setShowModal(true);
                }}
                sx={{
                    // Animation helps visually indicate where the new space is added, as spaces are sorted alphabetically.
                    animation: space.newSpace
                        ? `${fadeInZoomAnimation} 0.3s ease-out`
                        : 'none',
                    transformOrigin: 'center',
                    boxShadow: 1,
                    background: 'none',
                    backgroundColor: 'transparent',
                    color: 'none',
                    cursor: 'pointer',
                    height: '100%',
                    minHeight: '250px',
                }}
                style={{ flex: 1 }}
            >
                <CardActionArea>
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor:
                                theme.palette.custom.cardActionBackground,
                        }}
                    >
                        <Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    onClick={() => handleDelete(index)}
                                    gutterBottom
                                    component='div'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        '&:hover': {
                                            color: 'primary.main',
                                        },
                                        padding: '0px 5px 0px 0px',
                                        textAlign: 'right',
                                    }}
                                >
                                    <DeleteIcon
                                        fontSize='small'
                                        sx={{ fontSize: '16px' }}
                                    />
                                </Typography>
                                <Typography
                                    gutterBottom
                                    component='div'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        '&:hover': {
                                            color: 'primary.main',
                                        },

                                        padding: '0px 5px 0px 0px',
                                        textAlign: 'right',
                                    }}
                                >
                                    <EditIcon
                                        fontSize='small'
                                        sx={{ fontSize: '16px' }}
                                    />
                                </Typography>
                            </Grid>

                            <CardContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '80px',
                                    paddingTop: 0,
                                }}
                            >
                                <Stack
                                    direction='row'
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'primary.main',
                                        }}
                                    >
                                        {SpaceIcons[space.spaceType] || null}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'primary.main',
                                        }}
                                    >
                                        {camelCaseToTitleCase(space.name)}
                                    </Typography>
                                </Stack>
                            </CardContent>
                        </Grid>
                    </Grid>
                </CardActionArea>
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                        height: '145px',
                        overflow: 'auto',
                    }}
                >
                    {getBeddingArray(space).length > 0 && (
                        <Stack sx={{ paddingBottom: 1 }}>
                            {' '}
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Sleeping arrangements:
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                }}
                            >
                                {getBeddingArray(space).map((item: any, i) => (
                                    <Fade in={true} timeout={500}>
                                        <span>
                                            {i > 0 && ','} {item[1]}&nbsp;
                                            {camelCaseToLowerCase(item[0])}
                                            {item[1] > 1 &&
                                                item[0] !== 'couch' &&
                                                's'}
                                            {item[1] > 1 &&
                                                item[0] === 'couch' &&
                                                'es'}
                                        </span>
                                    </Fade>
                                ))}
                            </Typography>{' '}
                        </Stack>
                    )}
                    {getAmenitiesArray(space).length > 0 && (
                        <Stack sx={{ paddingBottom: 1 }}>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Amenities:
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                }}
                            >
                                {getAmenitiesArray(space).map(
                                    (amenity: any, i) => (
                                        <Fade in={true} timeout={500}>
                                            <span>
                                                {i > 0 && ','}{' '}
                                                {camelCaseToSentenceCase(
                                                    amenity
                                                )}
                                            </span>
                                        </Fade>
                                    )
                                )}
                            </Typography>{' '}
                        </Stack>
                    )}
                    {'desk' in space && space.desk > 0 && (
                        <>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Workspace:
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                }}
                            >
                                <Fade in={true} timeout={500}>
                                    <span>
                                        {space.desk} desk
                                        {space.desk > 1 ? 's' : ''}
                                    </span>
                                </Fade>
                            </Typography>{' '}
                        </>
                    )}
                    {'seatingCapacity' in space &&
                        space.seatingCapacity > 0 && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Seating Capacity:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                    }}
                                >
                                    <Fade in={true} timeout={500}>
                                        <span>
                                            {space.seatingCapacity} people
                                        </span>
                                    </Fade>
                                </Typography>{' '}
                            </>
                        )}
                    {'parkingCapacity' in space &&
                        space.parkingCapacity > 0 && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Parking Capacity:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                    }}
                                >
                                    <Fade in={true} timeout={500}>
                                        <span>
                                            {space.parkingCapacity} cars
                                        </span>
                                    </Fade>
                                </Typography>{' '}
                            </>
                        )}
                </CardContent>
            </Card>

            <SpaceModal
                open={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={handleEditSpace}
                existingSpace={space}
            />
        </Grid>
    );
};

export default IndividualSpace;

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function ChangePassword() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Passwords cannot be changed by another user. In order for
                        a password to be changed, the user will need to follow the
                        below steps.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        To change your password:
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top right corner of the app, click on the profile icon and select 'Profile'
                            </li>
                            <li>Click the 'pencil' icon to edit the content</li>
                            <li>Next to password, click edit to change the password</li>
                        </ol>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

import { Grid, InputLabel, Typography } from '@mui/material';
import React from 'react';
import FormCheckbox from '../../../shared/FormCheckbox';

const GameRoomForm = ({ control }: { control: any }) => {
    return (
        <>
            {' '}
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid item container xs={12} md={6}>
                    <Grid item xs={12}>
                        <Typography color='textPrimary' fontWeight='bold'>
                            Amenities
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid item container xs={12} md={6}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <FormCheckbox
                            name='gameConsole'
                            control={control}
                            sx={{ p: 0 }}
                        />
                        <InputLabel>Game Console</InputLabel>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <FormCheckbox
                            name='poolTable'
                            control={control}
                            sx={{ p: 0 }}
                        />
                        <InputLabel>Pool Table</InputLabel>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid item container xs={12} md={6}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <FormCheckbox
                            name='soundSystem'
                            control={control}
                            sx={{ p: 0 }}
                        />
                        <InputLabel>Sound System</InputLabel>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <FormCheckbox
                            name='tv'
                            control={control}
                            sx={{ p: 0 }}
                        />
                        <InputLabel>TV</InputLabel>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GameRoomForm;

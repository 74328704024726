import { Box, Grid, Stack, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import React from 'react';
import {
    openCalendarViewReservationAction,
    selectCalendarViewReservationData,
} from '../../../../app/Slices/CalendarViewSlice';
import CalendarFiltersModal from '../../../shared/CalendarFiltersModal';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchFilter from '../../../shared/SearchFilter';
import ReactGA from 'react-ga4';
import UserRole from '../../../../shared/AuthRoles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import {
    selectReservationFilters,
    setReservationFilters,
} from '../../../../app/Slices/ReservationFiltersSlice';

export default function ReservationHero({ tabValue = 0 }) {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();
    const [checked, setChecked] = React.useState(false);
    const dispatch = useDispatch();
    const calendarViewSelected: any = useSelector(
        selectCalendarViewReservationData
    );
    const filters: any = useSelector(selectReservationFilters);
    const [startDate, setStartDate] = useState(filters.startDate);
    const [moduleType, setModuleType] = useState(filters.moduleType);
    const [visitType, setVisitType] = useState(filters.visitType);
    const [bookerType, setBookerType] = useState(filters.bookerType);
    const [guest, setGuest] = useState(filters.guest);

    useEffect(() => {
        setChecked(calendarViewSelected.view);
    }, [calendarViewSelected.view]);

    const calendarView = (
        value: boolean | ((prevState: boolean) => boolean)
    ) => {
        setChecked(value);
        dispatch(
            openCalendarViewReservationAction({
                view: value,
            })
        );
        let newStatus:string[] = [];
        if (tabValue === 0) {
            newStatus = ['pending', 'approved'];
        } else if (tabValue === 1) {
            newStatus = ['completed'];
        } else if (tabValue === 2) {
            newStatus = ['cancelled', 'declined'];
        }

        dispatch(
            setReservationFilters({
                startDate,
                moduleType,
                status: newStatus,
                guest,
                visitType,
                bookerType,
            })
        );
        ReactGA.event({
            category: 'UI',
            action: `Calendar View`,
            label: `${
                value === true ? 'Calendar View Selected' : 'List View Selected'
            }`,
        });
    };

    const onClick = (value: string) => {
        if (value === 'aiReservation') {
            navigate(`/reservations/ai-assistant`, {
                state: { page: 'reservationsPage', id: undefined },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/reservations/ai-assistant`,
                title: `AI Reservation Assistant`,
            });
        } else {
            navigate(`/viewExperiences/all`);
            ReactGA.send({
                hitType: 'pageview',
                page: `/viewExperiences/all`,
                title: `View Experiences`,
            });
        }
    };

    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid
                sx={{
                    paddingBottom: isMobile ? '10px' : '0px',
                    paddingTop: '30px',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                <Grid sx={{ width: '100%' }}>
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        width='100%'
                        spacing='5px'
                    >
                        {!checked && <SearchFilter />}{' '}
                        {checked ? (
                            <CustomButton
                                variant='outlined'
                                color='green'
                                onClick={() => calendarView(false)}
                            >
                                <FormatListBulletedIcon fontSize='small' />{' '}
                            </CustomButton>
                        ) : (
                            <CustomButton
                                variant='outlined'
                                color='green'
                                onClick={() => calendarView(true)}
                            >
                                <CalendarMonthIcon fontSize='small' />{' '}
                            </CustomButton>
                        )}
                        <CalendarFiltersModal />
                        {!loggedInUser?.viewOnly && (
                            <CustomButton
                                id='aiReservation'
                                variant='contained'
                                color='green'
                                onClick={() =>
                                    onClick(
                                        loggedInUser?.role.includes('owner')
                                            ? 'aiReservation'
                                            : 'newReservation'
                                    )
                                }
                            >
                                <AddCircleIcon /> New
                            </CustomButton>
                        )}
                    </Stack>
                </Grid>
            </Grid>{' '}
        </Grid>
    );
}

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function ViewingMessages() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        To view your messages, click on the envelope icon at the
                        top right corner of the app and select the relevant
                        channel from the left panel. 
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

import { Grid, List, ListItem, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function CreateBookingCap() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            {loggedInUser?.role.includes('admin') ? (
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Booking caps allow you more control over who can book
                        your experiences and how many times a year. By creating
                        a booking cap, you can apply how many times users or the
                        entire organisation can book an experience per year.
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            paddingBottom: '5px',
                        }}
                    >
                        For Example:
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        Maximum bookings per user or per company can be applied
                        to experiences. These caps are for the entire year.
                        <ul>
                            <li>
                                PER USER: Max 2 bookings per user per year for
                                each experience you select. The user will be
                                able to book max two bookings per experience
                                over the 12 months. This number will be reset
                                each calendar year.
                            </li>
                            <li>
                                PER COMPANY: Max 100 bookings per company per
                                year for each experience you select. The compan
                                will be able to book max 100 bookings per
                                experience over the 12 months. This number will
                                be reset each calendar year. This means users
                                can make as many bookings as they want until
                                they reach the combined company total of 100.
                            </li>
                        </ul>
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To create a new booking cap:
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top right corner of the app, click on
                                the cog wheel icon
                            </li>
                            <li>Select 'Settings'</li>
                            <li>Select 'Booking Caps'</li>
                            <li>Click 'Add'</li>
                            <li>Complete all mandatory fields with a *</li>
                            <li>Click 'Save'</li>
                        </ol>
                        Once saved, this booking cap will be applied
                        immediately. If the user/company has exceeded the number
                        of bookings, they will be advised at the time they go to
                        make the booking.
                    </Typography>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Only admins can add a new booking cap. Please contact
                            a person that is an admin in your organisation to
                            add, edit or delete a booking cap.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

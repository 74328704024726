import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface IFilterState {
    value: IFilterType;
}

interface IFilterType {
    filteredData?: any;
}

const initialState: IFilterState = {
    value: {
        filteredData: [],
    },
};
export const slice = createSlice({
    name: 'filterClients',
    initialState: { ...initialState },
    reducers: {
        openFilterClientAction: (state, action: PayloadAction<IFilterType>) => {
            state.value.filteredData =
                action.payload && action.payload.filteredData;
        },
    },
});
export const { openFilterClientAction } = slice.actions;
export const selectFilteredClientData = (state: RootState) =>
    state.filterClients.value?.filteredData;
export const FilterClientReducer = slice.reducer;

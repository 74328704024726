import { ArrowBack } from '@mui/icons-material';
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useDeletePolicyMutation,
    useGetPolicyListQuery,
} from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../layout/styledcomponents/CustomTextField';
import MultiSelectDropdownFilter from '../../../layout/styledcomponents/filters/MultiSelectDropdown';
import UserRole from '../../../shared/AuthRoles';
import ConfirmationModal from '../../shared/ConfirmationModal';
import toast from '../../shared/Toast';
import { Policy } from '../../types/policy';

interface FormProps {
    policy?: Policy;
    onSubmit: (data: Policy) => void;
}

const PolicyForm = ({ policy, onSubmit }: FormProps) => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();

    const { data: policies } = useGetPolicyListQuery(loggedInUser?.company);

    const navigate = useNavigate();

    const [deletePolicy] = useDeletePolicyMutation();

    const [name, setName] = useState<string>('');
    const [isDuplicateName, setIsDuplicateName] = useState<boolean>(false);

    const [experienceTypes, setExperienceTypes] = useState<string[]>([
        'property',
        'boat',
        'vehicle',
        'activity',
        'commercial',
    ]);

    const [description, setDescription] = useState<string>('');
    // delete modal
    const [showModal, setShowModal] = useState<boolean>(false);

    const experienceOptions = [
        { value: 'property', label: 'Property' },
        { value: 'boat', label: 'Boat' },
        { value: 'vehicle', label: 'Vehicle' },
        { value: 'activity', label: 'Activity' },
        { value: 'commercial', label: 'Commercial' },
    ];

    const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);

        // check if name already exists
        const duplicateName =
            policies.some(
                (policy: Policy) =>
                    policy.name.toLowerCase() === e.target.value.toLowerCase()
            ) && e.target.value.toLowerCase() !== policy?.name.toLowerCase();

        setIsDuplicateName(duplicateName);
    };

    const handleDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleDelete = () => {
        deletePolicy([loggedInUser?.company, policy?.id]).then((data: any) => {
            if (data.error) {
                toast.error(`Policy cannot be deleted`);
            } else {
                toast.success(`Policy has been deleted`);
                navigate('/policies');
            }
        });
    };

    const handleSubmit = () => {
        onSubmit({
            name: name,
            moduleTypes: experienceTypes,
            description: description,
        });
    };

    useEffect(() => {
        if (policy) {
            setName(policy.name);
            setExperienceTypes(policy.moduleTypes);
            setDescription(policy.description);
        }
    }, [policy]);

    return (
        <>
            <Stack spacing={2} width='100%'>
                <Grid container spacing={2}>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={12} lg={2}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Name
                                {!name && (
                                    <span style={{ color: 'red' }}>*</span>
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <CustomTextField
                                color='green'
                                fullWidth
                                value={name}
                                onChange={handleName}
                                error={!!isDuplicateName}
                                helperText={
                                    isDuplicateName && 'Policy already exists'
                                }
                                inputProps={{
                                    maxLength: 19,
                                    style: {
                                        height: '10px',
                                        width: '350px',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={12} lg={2}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Experience Type
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <MultiSelectDropdownFilter
                                options={experienceOptions}
                                handleChange={setExperienceTypes}
                                selectedValue={experienceTypes}
                                backgroundColor={'white'}
                                border={'#C3C3C3'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={12} lg={2}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Description
                                {!description && (
                                    <span style={{ color: 'red' }}>*</span>
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <CustomTextField
                                color='green'
                                fullWidth
                                multiline
                                rows={8}
                                inputProps={{
                                    style: {
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    },
                                }}
                                value={description}
                                onChange={handleDescription}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        display='flex'
                        justifyContent='space-between'
                    >
                        <CustomButton
                            color='green'
                            startIcon={<ArrowBack />}
                            sx={{
                                '&:hover': { background: 'none' },
                                fontSize: '12px',
                                ml: isMobile ? '-18px' : '-8px',
                            }}
                            onClick={() => navigate('/policies')}
                        >
                            {!isMobile && 'Back to policies'}
                        </CustomButton>
                        <Box display='flex' gap='10px'>
                            {policy && (
                                <CustomButton
                                    color='error'
                                    variant='contained'
                                    onClick={() => setShowModal(true)}
                                >
                                    Delete
                                </CustomButton>
                            )}
                            <CustomButton
                                color='green'
                                variant='contained'
                                onClick={handleSubmit}
                                disabled={
                                    !name ||
                                    !experienceTypes ||
                                    !description ||
                                    isDuplicateName
                                }
                            >
                                {policy ? 'Update' : 'Save'}
                            </CustomButton>
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
            <ConfirmationModal
                open={showModal}
                onClose={() => setShowModal(false)}
                onChange={handleDelete}
                message={'delete this policy'}
            />
        </>
    );
};

export default PolicyForm;

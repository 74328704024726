import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function MakeAReservation() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();

    return (
        <>
            {loggedInUser?.role.includes('owner') ? (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Owners/Managers of an experience can make reservations
                            too. There are two types of reservations:
                            <ul>
                                <li>
                                    On Behalf of – This is a reservation that can be
                                    on behalf of the owner/organisation for
                                    personal/company use.
                                </li>

                                <li>
                                    Blocked reservations – This is a reservation that
                                    blocks out the experience on the users
                                    calendar. Blocked reservations are typically for
                                    Maintenance, staff away, shut down period
                                    etc
                                </li>
                            </ul>
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To make a reservation:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on ‘Experiences’</li>
                                <li>Click 'View All Experiences'</li>
                                <li>Select the experience you want to reserve</li>
                                <li>
                                    On the bottom right corner of the listed
                                    experience, select a date (or date range -
                                    depending on the experience type)
                                </li>
                                <li>Click 'Reserve'</li>
                                <li>
                                    Select whether this is ‘On behalf' of
                                    someone. If ‘Yes’, you’re making a reservation
                                    on behalf of a person i.e owner. You will be
                                    required to add in additional information
                                    about their visit. Alternatively, if you
                                    select ‘No’, it will ask you why you’re
                                    making a blocked reservation (i.e Staff Away,
                                    Maintenance etc)
                                </li>
                                <li>
                                    Select 'Reserve' to reserve this experience on
                                    the date/s you selected
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Users of the 7DAY App can make a reservation request for
                            any available listed experience on the app. Requests
                            are sent to the owner of the experience to approve.
                            Once approved, you will be notified via email or the
                            notification section (the "bell" icon located in the
                            top right corner of the app).
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                               To make a reservation:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on ‘Experiences’</li>
                                <li>Select the experience you want to reserve4</li>
                                <li>
                                    On the bottom right corner of the listed
                                    experience, select a date (or date range -
                                    depending on the experience type)
                                </li>
                                <li>Click 'Request'</li>
                                <li>Complete all the mandatory fields</li>
                                <li>
                                    Select 'Request Reservation' to submit a request{' '}
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

import { Grid, InputLabel, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBookerObject } from '../../../../../app/Slices/BookerSlice';
import {
    deleteDriver,
    selectDrivers,
    setDrivers,
} from '../../../../../app/Slices/DriversSlice';
import { CustomButton } from '../../../../../layout/styledcomponents/CustomButton';
import UserRole from '../../../../../shared/AuthRoles';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import FormCheckbox from '../../../../shared/FormCheckbox';
import FormTextField from '../../../../shared/FormTextField';
import FormSelect from '../../../../shared/FormSelect';

export interface Driver {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    licenceNo: string;
    licenceType: string;
}

interface Props {
    existingDriver: Driver;
    index: any;
    remove: UseFieldArrayRemove,
}

const DriverForm = ({ 
    existingDriver, 
    index, 
    remove 
}: Props) => {
    const { control, watch, setValue, getValues, setError } = useFormContext();

    const dispatch = useDispatch();
    const drivers = useSelector(selectDrivers);
    
    const driverSameAsBooker = watch('bookingInfo.driverSameAsBooker');
    const bookerIsSelected = watch('booker');
    
    const bookerObject = useSelector(selectBookerObject);
    const loggedInUser = UserRole();

    useEffect(() => {
        if (
            Object.keys(bookerObject).length > 0 &&
            driverSameAsBooker &&
            drivers[0].email !== bookerObject.email
        ) {
            
            const driverData = {
                id: 'driver1',
                firstName: bookerObject.firstName,
                lastName: bookerObject.lastName,
                phone: bookerObject.phone,
                email: bookerObject.email,
                licenceNo: '',
                licenceType: '',
            };

            setValue('bookingInfo.drivers[0]', driverData);

            dispatch(setDrivers([driverData, ...drivers.slice(1)]));
        }
    }, [bookerObject]);

    const handleDeleteDriver = () => {
        remove();
        dispatch(deleteDriver(existingDriver.id));
    };

    const handleCheck = () => {
        if (driverSameAsBooker) {
            setValue('bookingInfo.drivers[0]', {
                id: 'driver1',
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                licenceNo: '',
                licenceType: '',
            })
        } else {
            setValue('bookingInfo.drivers[0]', {
                id: 'driver1',
                firstName: bookerObject.firstName,
                lastName: bookerObject.lastName,
                phone: bookerObject.phone,
                email: bookerObject.email,
                licenceNo: '',
                licenceType: '',
            })
        }
    }

    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid>
                {index === 0 && !loggedInUser.role.includes('owner') && (
                    <Grid
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '10px',
                        }}
                    >
                        <Grid
                            sx={{
                                width: '100%',
                                display: 'flex',
                                gap: '10px',
                                paddingBottom: '5px',
                                alignItems: 'center',
                            }}
                        >
                            <Grid>
                                <FormCheckbox 
                                    name='bookingInfo.driverSameAsBooker'
                                    defaultChecked={false}
                                    control={control}
                                    disabled={!bookerIsSelected}
                                    onClick={handleCheck}
                                />
                            </Grid>
                            <Grid>
                                <Typography color='#787878'>
                                    Driver is the same person as who the
                                    reservation is for
                                </Typography>
                                {!bookerIsSelected && (
                                    <Typography 
                                        color='red'
                                        sx={{
                                            fontSize: '12px',
                                        }}
                                    >
                                        ( Please select who the booking is for)
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {drivers?.length > 1 && (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            paddingBottom: '10px',
                        }}
                    >
                        {index === 0
                            ? `   Main Driver:`
                            : `Additional Driver ${index}:`}
                    </Typography>
                )}

                <Grid sx={{ paddingBottom: '15px' }}>
                    <InputLabel required>First Name</InputLabel>
                    <FormTextField 
                        name={`bookingInfo.drivers[${index}].firstName`}
                        control={control}
                        disabled={
                            driverSameAsBooker &&
                            index === 0 &&
                            !loggedInUser.role.includes('owner')
                        }
                    />
                </Grid>
                <Grid pb='15px'>
                    <InputLabel required>Last Name</InputLabel>
                    <FormTextField 
                        name={`bookingInfo.drivers[${index}].lastName`}
                        control={control}
                        disabled={
                            driverSameAsBooker &&
                            index === 0 &&
                            !loggedInUser.role.includes('owner')
                        }
                    />
                </Grid>
                <Grid pb='15px'>
                    <InputLabel required>Phone</InputLabel>
                    <FormTextField 
                        name={`bookingInfo.drivers[${index}].phone`}
                        control={control}
                        disabled={
                            driverSameAsBooker &&
                            index === 0 &&
                            !loggedInUser.role.includes('owner')
                        }
                    />
                </Grid>
                <Grid pb='15px'>
                    <InputLabel required>Email</InputLabel>
                    <FormTextField 
                        name={`bookingInfo.drivers[${index}].email`}
                        control={control}
                        disabled={
                            driverSameAsBooker &&
                            index === 0 &&
                            !loggedInUser.role.includes('owner')
                        }
                    />
                </Grid>
                <Grid pb='15px'>
                    <InputLabel required>Licence No.</InputLabel>
                    <FormTextField 
                        name={`bookingInfo.drivers[${index}].licenceNo`}
                        control={control}
                    />
                </Grid>
                <Grid pb='15px'>
                    <InputLabel required>Licence Type</InputLabel>
                    <FormSelect 
                        name={`bookingInfo.drivers[${index}].licenceType`}
                        control={control}
                        options={[
                            { value: 'full', label: 'Full' },
                            { value: 'p2', label: 'P2' },
                            { value: 'p1', label: 'P1' },
                        ]}
                    />
                </Grid>
            </Grid>
            {index > 0 && (
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <CustomButton
                        variant='contained'
                        color='warning'
                        onClick={handleDeleteDriver}
                    >
                        Delete
                    </CustomButton>
                </Grid>
            )}
        </Grid>
    );
};

export default DriverForm;

import { 
    Avatar, Box, Button, Card, CardContent, 
    Divider, Drawer, IconButton, Skeleton, Stack, 
    Toolbar, Typography, useMediaQuery 
} from '@mui/material'
import React from 'react'
import { useGetIndividualAssetQuery, useGetIndividualReservationQuery } from '../../../../app/Slices/SevenDayApiSlice'
import { Clear, NavigateNext } from '@mui/icons-material'
import Location from './Location'
import ExperiencePhotos from './ExperiencePhotos'
import { useNavigate } from 'react-router-dom';
import ChannelMembers from './ChannelMembers'
import { Users } from '../../../types/messaging'

interface ExperienceDrawerProps {
    open: boolean,
    handleClose: () => void,
    channelId: string,
    users: Users,
    metadata?: {
        experience?: {
            id: string,
        },
        booking?: {
            id: string,
        }
    },
}

const ChannelDetails = ({ open, handleClose, channelId, users, metadata }: ExperienceDrawerProps) => {
    const isMobile = useMediaQuery('(max-width: 1200px)');

    const navigate = useNavigate();

    const { data: experience, isLoading } = useGetIndividualAssetQuery(
        `/modules/${metadata?.experience?.id}`, 
        { skip: !metadata?.experience}
    );

    const { data: booking } = useGetIndividualReservationQuery(
        `/reservations/${metadata?.booking?.id}`, 
        { skip: !metadata?.booking }
    );

    return (
        <Drawer 
            open={open}
            variant={'persistent'}
            anchor='right'
            sx={{ position: 'fixed' }}     
            PaperProps={{
                sx: { width: isMobile ? '100%' : '400px' }
            }}    
        >
            <Toolbar />
            <Box
                display='flex' 
                alignItems='center' 
                justifyContent='space-between'
                px={3}
                py={2}
            >
                <Typography 
                    variant='h6' 
                    fontWeight='bold' 
                    color='#787878'
                >
                    Details
                </Typography>
                <IconButton onClick={handleClose}>
                    <Clear />
                </IconButton>
            </Box>
            <Divider />
            <Stack 
                p={3}
                spacing={2} 
                overflow='scroll'
                flex={1}
                divider={<Divider />}
                // hide scrollbar
                sx={{
                    '-ms-overflow-style': 'none',
                    '::-webkit-scrollbar': {
                        display: 'none'
                    }
                }}
            >
                {users && (
                    <ChannelMembers users={users} channelId={channelId}/>
                )}
                <Stack spacing={2}>
                    {experience && metadata?.experience && (
                        <>
                            <div>
                                <Typography variant='h6'>
                                    {experience?.name}
                                </Typography>
                                <Typography 
                                    variant='subtitle2' 
                                    color='textSecondary' 
                                >
                                    Hosted by {experience?.owner?.name}
                                </Typography>
                            </div>
                            <ExperiencePhotos photos={experience?.photos}/>
                            <Button 
                                endIcon={<NavigateNext />}
                                onClick={() => navigate(`/viewExperiences/property/${metadata?.experience?.id}`)}
                            >
                                View listing
                            </Button>
                        </> 
                    )}
                    {isLoading && (
                        <>
                            <Skeleton variant='rounded' width={100} height={20} />
                            <Skeleton variant='rounded' width={100} height={14} />
                            <Skeleton variant='rounded' height={200} />
                            <Skeleton variant='rounded' height={40} />
                        </>
                    )}
                </Stack>
                <Location experience={experience}/>
                {booking && metadata?.booking && (
                    <>
                        <Stack spacing={2}>
                            <Typography variant='h6'>
                                Booking #{booking.id}
                            </Typography>
                            <div>
                                <Typography gutterBottom>
                                    Primary Guest:
                                </Typography>
                                <Box display='flex' alignItems='center' gap={1}>
                                    <Avatar src={booking.booker.profilePicture?.src}/>
                                    <div>
                                        <Typography>
                                            {booking.booker.firstName} {booking.booker.lastName}
                                        </Typography>
                                        <Typography
                                            variant='subtitle2'
                                            color='textSecondary'
                                        >
                                            {booking.booker.email}
                                        </Typography>
                                    </div>
                                </Box>
                                
                            </div>
                            
                            <Card variant='outlined'>
                                <CardContent>
                                    <Stack direction='row'>
                                        <Box 
                                            display='flex' 
                                            width='100%' 
                                            alignItems='center' 
                                            justifyContent='center'
                                        >
                                            <div>
                                                <Typography>
                                                    Check-in
                                                </Typography>
                                                <Typography 
                                                    variant='subtitle2'
                                                    color='textSecondary'
                                                >
                                                    {booking.bookingInfo?.checkinTime}
                                                </Typography>
                                            </div>
                                        </Box>
                                        <Box display='flex' width='100%' alignItems='center' justifyContent='center'>
                                            <div>
                                                <Typography>
                                                    Check-out
                                                </Typography>
                                                <Typography 
                                                    variant='subtitle2'
                                                    color='textSecondary'
                                                >
                                                    {booking.bookingInfo?.checkoutTime}
                                                </Typography>
                                            </div>
                                        </Box>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Stack>
                    </>
                )}
            </Stack>
        </Drawer>
    )
}

export default ChannelDetails
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Chip } from '@mui/material';
import React from 'react';
const theme = createTheme({
    palette: {
        // @ts-ignore
        neutral: {
            main: '#fff',
            contrastText: '#5cb5b3',
        },
        green: {
            main: '#5cb5b3',
            contrastText: '#fff',
        },
        gray: {
            main: '#787878',
            contrastText: '#5cb5b3',
        },
        lightGrey: {
            main: '#E0E0E0',
            contrastText: '#E0E0E0',
        },
        red: {
            main: '#FF0000',
            contrastText: '#FF0000',
        },
        blue: {
            main: '#0096FF',
            contrastText: '#0096FF',
        },
        yellow: {
            main: '#FFBF00',
            contrastText: '#FFBF00',
        },
        orange: {
            main: '#ff4d00',
            contrastText: '#ff4d00',
        },
        white: {
            main: '#fff',
            contrastText: '#fff',
        },
        black: {
            main: '#000',
            contrastText: '#000',
        },
    },
});

export function CustomChip({ children, ...props }: any) {
    return (
        <ThemeProvider theme={theme}>
            <Chip
                {...props}
                sx={{
                    fontFamily: 'Open Sans',
                    // width: 'auto',
                    minWidth:'120px',
                    height: 'auto',
                    
                }}
                label={props.label}
            />
        </ThemeProvider>
    );
}

export function CustomSmallListChip({ children, ...props }: any) {
    return (
        <ThemeProvider theme={theme}>
            <Chip
                {...props}
                sx={{
                    fontFamily: 'Open Sans',
                    width: 'auto',
                    height: 'auto',
                    justifyContent: 'center',
                    fontSize: '10px',
                    borderRadius: 1
                    
                }}
                label={props.label}
            />
        </ThemeProvider>
    );
}


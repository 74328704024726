import React, { useEffect, useState } from 'react';
import { ModalProps } from '../../../../types/modal';
import {
    Alert,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
} from '@mui/material';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import {
    FlexibleExperienceCharge,
    FormValues,
} from '../../../../types/experience';
import { zodResolver } from '@hookform/resolvers/zod';
import { PricingSchema } from './schema';
import PricingForm from './PricingForm';
import CalendarView from './CalendarView';

interface Props extends ModalProps {
    price: FlexibleExperienceCharge;
    onSubmit: (data: Pricing) => void;
}

interface Pricing {
    chargeType: string;
    dayPrice: string | number;
    dayCheckin: string;
    dayCheckout: string;
    nightPrice: string | number;
    nightCheckin: string;
    nightCheckout: string;
    hourPrice: string | number;
    weekendPrice: {
        days: string[];
        dayPrice: number;
        nightPrice: string | number;
        hourPrice: string | number;
    } | null;
    _demo: {
        date: {
            from: Date;
            to?: Date;
        };
        checkinTime: string;
        checkoutTime: string;
    };
}

const PricingModal = ({ price, open, onClose, onSubmit }: Props) => {
    const { getValues } = useFormContext<FormValues>();
    const experience = getValues();

    const methods = useForm<Pricing>({
        defaultValues: {
            chargeType: 'flexiblePrice',
            dayPrice: '',
            hourPrice: '',
            nightPrice: '',
            weekendPrice: null,
            _demo: {
                date: {
                    from: undefined,
                    to: undefined,
                },
                checkinTime: '07:00',
                checkoutTime: '18:00',
            },
        },
        resolver: zodResolver(PricingSchema),
    });

    const {
        handleSubmit,
        reset,
        formState: { isDirty },
    } = methods;

    const [cancel, setCancel] = useState<boolean>(false);

    const handleCancel = () => {
        if (isDirty) {
            // if form is dirty, display alert
            if (!cancel) {
                setCancel(true);
                return;
            }
        }

        if (cancel) reset();

        setCancel(false);
        onClose();
    };

    const onNestedSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        if (event && typeof event.stopPropagation === 'function') {
            event.stopPropagation();
        }

        return handleSubmit(onSubmit)(event);
    };

    useEffect(() => {
        if (price) reset(price, { keepDefaultValues: true });
    }, [price, open]);

    return (
        <>
            <Dialog open={open} onClose={handleCancel} maxWidth='md'>
                <FormProvider {...methods}>
                    <form onSubmit={onNestedSubmit}>
                        <DialogTitle fontWeight='bold'>Pricing</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <PricingForm />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <CalendarView experience={experience} />
                                </Grid>
                            </Grid>
                            <Collapse in={cancel}>
                                <Alert severity='warning' sx={{ mt: 2 }}>
                                    You have unsaved changes. Are you sure you
                                    want to cancel?
                                </Alert>
                            </Collapse>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel} color='error'>
                                Cancel
                            </Button>
                            <Button variant='contained' type='submit'>
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </>
    );
};

export default PricingModal;


import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Checkbox } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';

declare module '@mui/system' {
    interface DefaultTheme extends Theme {}
}

const outerTheme = createTheme({
    palette: {
        secondary: {
            main: '#5cb5b3',
        },
    },
});
export function CustomCheckbox({ children, ...props }: any) {
    return (
        <ThemeProvider theme={outerTheme}>
            <Checkbox
                sx={{
                    color: '#787878',
                    '&.Mui-checked': {
                        color: '#5cb5b3',
                    },
                    marginLeft: -1,
                }}
                disableRipple
                // color='secondary'
                {...props}
                {...children}
            />
        </ThemeProvider>
    );
}

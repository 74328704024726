import { Box, useMediaQuery } from '@mui/material';
import { useGetAllReservationsQuery } from '../../../../app/Slices/SevenDayApiSlice';
import moment from 'moment';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDays } from 'date-fns';
import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { EventClickArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import './FullCalendar.css';
import { useSelector } from 'react-redux';
import { selectReservationFilters } from '../../../../app/Slices/ReservationFiltersSlice';

export default function ViewReservationsCalendarComponent() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const reservationFilters = useSelector(selectReservationFilters);
    const { data: reservations }: any = useGetAllReservationsQuery({
        ...reservationFilters,
        sort: 'startDate',
    });
    const navigate = useNavigate();

    const calendarRef: any = useRef(null);

    const handleEventClick = (clickInfo: EventClickArg) => {
        navigate(
            `/reservations/${clickInfo?.event?._def.groupId
                .replace(/ /g, '')
                .toLowerCase()}/${clickInfo?.event?._def.publicId}`,
            {
                state: { name: 'viewReservation', type: 'reservations' },
            }
        );
    };

    let getDates = reservations
        ?.filter(
            (value: any) =>
                value?.status !== 'cancelled' && value?.status !== 'declined'
        )
        ?.map((value: any) => {
            let endDate = value?.endDate;
            if (value?.startDate === value?.endDate) {
                endDate = value?.endDate;
            } else {
                endDate = moment(addDays(new Date(value?.endDate), 1)).format(
                    'YYYY-MM-DD'
                );
            }

            return {
                groupId: value?.module.name,
                id: value?.id,
                title:
                    value?.bookerType === 'owner' && value?.onBehalf === false
                        ? `${value?.module.name} ${
                              value?.module?.type.charAt(0).toUpperCase() +
                              value?.module?.type.slice(1)
                          } - BLOCKED ${value?.reason
                              .replace(/([A-Z])/g, ' $1')
                              .replace(/^./, function (str: string) {
                                  return str.toUpperCase();
                              })}`
                        : `${value?.module.name} ${
                              value?.module.type.charAt(0).toUpperCase() +
                              value?.module.type.slice(1)
                          } - RESERVATION REF: #${value?.id} ${
                              value?.bookerType === 'guest'
                                  ? value.guest?.name
                                  : value.owner?.name
                          } (${value?.status})`,
                start: value?.startDate,
                extendedProps: {
                    status: value?.status,
                    type: value?.module?.type,
                },
                end: endDate,
                backgroundColor:
                    value?.bookerType === 'owner' && value?.onBehalf === false
                        ? '#FA8072'
                        : value?.bookerType === 'owner' &&
                          value?.onBehalf === true
                        ? '#ffff99'
                        : value?.status === 'completed'
                        ? '#CCCCCC'
                        : value?.status === 'pending'
                        ? '#FFF'
                        : value?.module?.type === 'property'
                        ? '#3b6978'
                        : value?.module?.type === 'boat'
                        ? '#A7C7E7'
                        : value?.module?.type === 'activity'
                        ? '#93C572'
                        : value?.module?.type === 'vehicle'
                        ? '#006400'
                        : value?.module?.type === 'commercial'
                        ? '#4B0082'
                        : value?.onBehalf === false
                        ? '#FA8072'
                        : '#93C572',
                textColor:
                    value?.bookerType === 'owner' && value?.onBehalf === true
                        ? '#000'
                        : value?.status === 'pending'
                        ? '#CCCCCC'
                        : '#FFF',
                borderColor: value?.status === 'pending' ? '#CCCCCC' : '#FFF',
                // borderColor: '#000'CCCCCC
            };
        });

    return (
        <Box
            sx={{
                height: isMobile ? 550 : 800,
                width: isMobile ? '92%' : '100%',
                paddingTop: '50px',
            }}
        >
            <FullCalendar
                themeSystem='bootstrap5'
                ref={calendarRef}
                firstDay={1}
                plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    listPlugin,
                    bootstrap5Plugin,
                    interactionPlugin,
                ]}
                initialView={isMobile ? 'timeGridDay' : 'dayGridMonth'}
                events={getDates}
                editable={true}
                selectable={true}
                selectMirror={true}
                eventClick={handleEventClick}
                height={isMobile ? '100%' : '100%'}
                headerToolbar={{
                    start: isMobile
                        ? 'title'
                        : 'timeGridDay,timeGridWeek,dayGridMonth,listWeek',
                    center: isMobile ? '' : 'title',
                    end: isMobile ? '' : 'prevYear,prev,today,next,nextYear',
                }}
                footerToolbar={{
                    start: isMobile ? '' : '',
                    center: isMobile ? 'prevYear,prev,today,next,nextYear' : '',
                    end: isMobile
                        ? 'timeGridDay,timeGridWeek,dayGridMonth,listWeek'
                        : '',
                }}
                buttonText={{
                    today: 'Today',
                    month: 'Month',
                    week: 'Week',
                    day: 'Day',
                    list: 'List',
                }}
                eventDidMount={function (info: any) {
                    if (info.event.extendedProps.status === 'pending') {
                        info.el.style.color = '#787878';

                        // Change color of dot marker
                        var titleEl = info.el.getElementsByClassName(
                            'fc-list-event-title'
                        )[0];
                        var dotEl =
                            info.el.getElementsByClassName(
                                'fc-list-event-dot'
                            )[0];
                        if (titleEl) {
                            titleEl.style.color = '#787878';
                            dotEl.style.borderColor = '#FA8072';
                        }
                    } else if (
                        info.event.extendedProps.status === 'completed'
                    ) {
                        info.el.style.color = '#CCCCCC';

                        // Change color of dot marker
                        var titleEl = info.el.getElementsByClassName(
                            'fc-list-event-title'
                        )[0];
                        var dotEl =
                            info.el.getElementsByClassName(
                                'fc-list-event-dot'
                            )[0];
                        if (titleEl) {
                            titleEl.style.color = '#787878';
                            dotEl.style.borderColor = '#CCCCCC';
                        }
                    } else {
                        if (info.event.extendedProps.type === 'property') {
                            info.el.style.color = '#3b6978';

                            // Change color of dot marker
                            var titleEl = info.el.getElementsByClassName(
                                'fc-list-event-title'
                            )[0];
                            var dotEl =
                                info.el.getElementsByClassName(
                                    'fc-list-event-dot'
                                )[0];
                            if (titleEl) {
                                titleEl.style.color = '#787878';
                                dotEl.style.borderColor = '#3b6978';
                            }
                        } else if (info.event.extendedProps.type === 'boat') {
                            info.el.style.color = '#787878';

                            // Change color of dot marker
                            var titleEl = info.el.getElementsByClassName(
                                'fc-list-event-title'
                            )[0];
                            var dotEl =
                                info.el.getElementsByClassName(
                                    'fc-list-event-dot'
                                )[0];
                            if (titleEl) {
                                titleEl.style.color = '#787878';
                                dotEl.style.borderColor = '#A7C7E7';
                            }
                        } else {
                            info.el.style.color = '#748e81';

                            // Change color of dot marker
                            var titleEl = info.el.getElementsByClassName(
                                'fc-list-event-title'
                            )[0];
                            var dotEl =
                                info.el.getElementsByClassName(
                                    'fc-list-event-dot'
                                )[0];
                            if (titleEl) {
                                titleEl.style.color = '#787878';
                                dotEl.style.borderColor = '#93C572';
                            }
                        }
                    }
                }}

                // customButtons={{
                //   custom1: {
                //     text: "custom 1",
                //     click: function () {
                //       alert("clicked the custom button!");
                //     },
                //   },
                // }}
            />
        </Box>
    );
}

import { Checkbox, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectBookerObject } from '../../../../../app/Slices/BookerSlice';
import {
    selectBookerIsSelected,
    selectDriverSameAsBooker,
    toggleDriverSameAsBooker,
} from '../../../../../app/Slices/DriverSameAsBookerSlice';
import {
    deleteDriver,
    selectDrivers,
    selectDriversFormErrors,
    selectMinimumLicenceRequired,
    setDrivers,
    updateDriver,
} from '../../../../../app/Slices/DriversSlice';
import { CustomButton } from '../../../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../../../layout/styledcomponents/CustomTextField';
import DropdownFilter from '../../../../../layout/styledcomponents/filters/Dropdown';
import UserRole from '../../../../../shared/AuthRoles';
import {
    validateLicenceNo,
    validatEmail,
    validateName,
    validatePhone,
} from '../../../../../shared/utils';
import { CustomCheckbox } from '../../../../../layout/styledcomponents/CustomCheckbox';

export interface Driver {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    licenceNo: string;
    licenceType: string;
}

interface Props {
    existingDriver: Driver;
    index: any;
}

const DriverForm = ({ existingDriver, index }: Props) => {
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const dispatch = useDispatch();
    const drivers = useSelector(selectDrivers);
    const driverSameAsBooker = useSelector(selectDriverSameAsBooker);
    const bookerIsSelected = useSelector(selectBookerIsSelected);
    const driversFormErrors = useSelector(selectDriversFormErrors);
    const minimumLicenceRequired = useSelector(selectMinimumLicenceRequired);
    const bookerObject = useSelector(selectBookerObject);
    const loggedInUser = UserRole();

    const handleFieldChange = (field: keyof Driver, value: string) => {
        dispatch(
            updateDriver({
                id: existingDriver.id,
                updatedDriver: { [field]: value },
            })
        );
    };

    useEffect(() => {
        if (
            Object.keys(bookerObject).length > 0 &&
            driverSameAsBooker &&
            drivers[0].email !== bookerObject.email
        ) {
            const driverData = {
                id: 'driver1',
                firstName: bookerObject.firstName,
                lastName: bookerObject.lastName,
                phone: bookerObject.phone,
                email: bookerObject.email,
                licenceNo: '',
                licenceType: '',
            };
            dispatch(setDrivers([driverData, ...drivers.slice(1)]));
        }
    }, [bookerObject]);

    const handleDriverSameAsBooker = () => {
        dispatch(toggleDriverSameAsBooker());
        if (driverSameAsBooker) {
            const driverData = {
                id: 'driver1',
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                licenceNo: '',
                licenceType: '',
            };
            dispatch(setDrivers([driverData, ...drivers.slice(1)]));
        } else {
            const driverData = {
                id: 'driver1',
                firstName: bookerObject.firstName,
                lastName: bookerObject.lastName,
                phone: bookerObject.phone,
                email: bookerObject.email,
                licenceNo: '',
                licenceType: '',
            };
            dispatch(setDrivers([driverData, ...drivers.slice(1)]));
        }
    };

    const handleDeleteDriver = () => {
        dispatch(deleteDriver(existingDriver.id));
    };

    const invalidDriversLicence = driversFormErrors.find(
        (error: any) =>
            error.id === existingDriver.id &&
            error.field === 'licenceType' &&
            error.message === 'Invalid Licence Type.'
    );
    const formErrors = driversFormErrors.filter(
        (error: any) => error.id === existingDriver.id
    );

    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid>
                {index === 0 && !loggedInUser.role.includes('owner') && (
                    <Grid
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingBottom: '10px',
                        }}
                    >
                        <Grid
                            sx={{
                                width: '100%',
                                display: 'flex',
                                gap: '10px',
                                paddingBottom: '5px',
                                alignItems: 'center',
                            }}
                        >
                            <Grid>
                                <CustomCheckbox
                                    checked={driverSameAsBooker}
                                    onChange={handleDriverSameAsBooker}
                                    disabled={!bookerIsSelected}
                                />
                            </Grid>
                            <Grid>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Driver is the same person as who the
                                    reservation is for
                                </Typography>
                                {!bookerIsSelected && (
                                    <Typography
                                        color='red'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontSize: '12px',
                                        }}
                                    >
                                        ( Please select who the booking is for)
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {drivers?.length > 1 && (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            paddingBottom: '10px',
                        }}
                    >
                        {index === 0
                            ? `   Main Driver:`
                            : `Additional Driver ${index}:`}
                    </Typography>
                )}
                <Grid
                    sx={{
                        paddingBottom: isMobile || isIpad ? '5px' : '0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '5px',
                        }}
                    >
                        {formErrors.filter(
                            (error: any) => error.field === 'firstName'
                        ).length === 1 && (
                            <span
                                style={{
                                    color: 'red',
                                    paddingRight: '3px',
                                }}
                            >
                                *
                            </span>
                        )}
                        First Name
                    </Typography>
                </Grid>
                <Grid sx={{ paddingBottom: '15px' }}>
                    <CustomTextField
                        value={existingDriver.firstName}
                        onChange={(e: any) =>
                            handleFieldChange('firstName', e.target.value)
                        }
                        placeholder=''
                        fullWidth
                        color={
                            !validateName(existingDriver.firstName)
                                ? 'red'
                                : 'green'
                        }
                        inputProps={{
                            color: '#787878',
                            style: {
                                color: '#787878',
                            },
                        }}
                        disabled={
                            driverSameAsBooker &&
                            index === 0 &&
                            !loggedInUser.role.includes('owner')
                        }
                    />
                </Grid>
                <Grid></Grid>
                <Grid
                    sx={{
                        paddingBottom: isMobile || isIpad ? '5px' : '0px',
                    }}
                >
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '5px',
                        }}
                    >
                        {formErrors.filter(
                            (error: any) => error.field === 'lastName'
                        ).length === 1 && (
                            <span
                                style={{
                                    color: 'red',
                                    paddingRight: '3px',
                                }}
                            >
                                *
                            </span>
                        )}
                        Last Name
                    </Typography>
                </Grid>
                <Grid sx={{ paddingBottom: '15px' }}>
                    <CustomTextField
                        value={existingDriver.lastName}
                        onChange={(e: any) =>
                            handleFieldChange('lastName', e.target.value)
                        }
                        fullWidth
                        color={
                            !validateName(existingDriver.lastName)
                                ? 'red'
                                : 'green'
                        }
                        inputProps={{
                            color: '#787878',
                            style: {
                                color: '#787878',
                            },
                        }}
                        disabled={
                            driverSameAsBooker &&
                            index === 0 &&
                            !loggedInUser.role.includes('owner')
                        }
                    />
                </Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid
                    sx={{
                        paddingBottom: isMobile || isIpad ? '5px' : '0px',
                    }}
                >
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '5px',
                        }}
                    >
                        {formErrors.filter(
                            (error: any) => error.field === 'phone'
                        ).length === 1 && (
                            <span
                                style={{
                                    color: 'red',
                                    paddingRight: '3px',
                                }}
                            >
                                *
                            </span>
                        )}
                        Phone
                    </Typography>
                </Grid>
                <Grid sx={{ paddingBottom: '15px' }}>
                    <CustomTextField
                        value={existingDriver.phone}
                        onChange={(e: any) =>
                            handleFieldChange('phone', e.target.value)
                        }
                        fullWidth
                        color={
                            !validatePhone(existingDriver.phone)
                                ? 'red'
                                : 'green'
                        }
                        inputProps={{
                            color: '#787878',
                            style: {
                                color: '#787878',
                            },
                        }}
                        disabled={
                            driverSameAsBooker &&
                            index === 0 &&
                            !loggedInUser.role.includes('owner')
                        }
                    />
                </Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid
                    sx={{
                        paddingBottom: isMobile || isIpad ? '5px' : '0px',
                    }}
                >
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '5px',
                        }}
                    >
                        {formErrors.filter(
                            (error: any) => error.field === 'email'
                        ).length === 1 && (
                            <span
                                style={{
                                    color: 'red',
                                    paddingRight: '3px',
                                }}
                            >
                                *
                            </span>
                        )}
                        Email
                    </Typography>
                </Grid>
                <Grid sx={{ paddingBottom: '15px' }}>
                    <CustomTextField
                        value={existingDriver.email}
                        onChange={(e: any) =>
                            handleFieldChange('email', e.target.value)
                        }
                        fullWidth
                        color={
                            !validatEmail(existingDriver.email)
                                ? 'red'
                                : 'green'
                        }
                        inputProps={{
                            color: '#787878',
                            style: {
                                color: '#787878',
                            },
                        }}
                        disabled={
                            driverSameAsBooker &&
                            index === 0 &&
                            !loggedInUser.role.includes('owner')
                        }
                    />
                </Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid
                    sx={{
                        paddingBottom: isMobile || isIpad ? '5px' : '0px',
                    }}
                >
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '5px',
                        }}
                    >
                        {formErrors.filter(
                            (error: any) => error.field === 'licenceNo'
                        ).length === 1 && (
                            <span
                                style={{
                                    color: 'red',
                                    paddingRight: '3px',
                                }}
                            >
                                *
                            </span>
                        )}
                        Licence No
                    </Typography>
                </Grid>
                <Grid sx={{ paddingBottom: '15px' }}>
                    <CustomTextField
                        value={existingDriver.licenceNo}
                        onChange={(e: any) =>
                            handleFieldChange('licenceNo', e.target.value)
                        }
                        fullWidth
                        color={
                            !validateLicenceNo(existingDriver.licenceNo)
                                ? 'red'
                                : 'green'
                        }
                        inputProps={{
                            color: '#787878',
                            style: {
                                color: '#787878',
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid
                    sx={{
                        paddingBottom: isMobile || isIpad ? '5px' : '0px',
                    }}
                >
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '5px',
                        }}
                    >
                        {formErrors.filter(
                            (error: any) => error.field === 'licenceType'
                        ).length === 1 && (
                            <span
                                style={{
                                    color: 'red',
                                    paddingRight: '3px',
                                }}
                            >
                                *
                            </span>
                        )}
                        Licence Type
                    </Typography>
                </Grid>
                <Grid>
                    <DropdownFilter
                        id='licenceType'
                        options={[
                            { value: 'full', label: 'Full' },
                            { value: 'p2', label: 'P2' },
                            { value: 'p1', label: 'P1' },
                        ]}
                        handleChange={(value: any) =>
                            handleFieldChange('licenceType', value)
                        }
                        selectedValue={existingDriver.licenceType}
                        backgroundColor='white'
                        border='#C3C3C3'
                    />
                    {invalidDriversLicence && (
                        <Typography
                            color='red'
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                            }}
                        >
                            {`The vehicle requires a ${minimumLicenceRequired} license as the minimum qualification for drivers. `}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            {index > 0 && (
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <CustomButton
                        variant='contained'
                        color='warning'
                        onClick={handleDeleteDriver}
                    >
                        Delete
                    </CustomButton>
                </Grid>
            )}
        </Grid>
    );
};

export default DriverForm;

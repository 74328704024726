import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';
import { Link } from 'react-router-dom';

export default function AddReservationToExternalCalendar() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <>
            {loggedInUser?.role.includes('owner') ? (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            7DAY allows you to export the reservation information to
                            an external calendar such as Google, Outlook etc. We
                            do not allow for importation of other reservations into
                            7DAY, only export.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            If you would like your reservations exported directly
                            and automatically to these external calendars rather
                            than individually for each reservation, follow these
                            instructions:
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on 'Experiences'</li>
                                <li>Select ‘Manage your listing’</li>
                                <li>
                                    Click on the Experience you would like to
                                    appear in your external calendar
                                </li>
                                <li>Click on the button ‘ Calendar Link’</li>
                                <li>Click on ‘Copy Link’</li>
                            </ol>
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                                fontWeight: 'bold',
                            }}
                        >
                            Google Calendar
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>
                                    Under ‘Other Calendars’ click on the + (add
                                    symbol)
                                </li>
                                <li>Select ‘From URL’</li>
                                <li>
                                    Paste the copied link into the empty field
                                </li>
                                <li>
                                    This will sync and import your reservations from
                                    7DAY
                                </li>
                            </ol>
                            IMPORTANT: to ensure all future reservations and updates
                            are added to this google calendar, click here - {''}
                            <Link
                                style={{ color: '#787878' }}
                                target='_blank'
                                to={`https://calendar.google.com/calendar/u/0/syncselect`}
                            >
                                Google Calender Sync Select {''}
                            </Link>
                            {''}
                            and tick the calendar you just added and save. If
                            you don’t do this, Google Calendar will not fetch
                            the reservation information regularly.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                paddingTop: '10px',
                            }}
                        >
                            Outlook Calendar
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>
                                    Under ‘Home’ tab, select ‘Add Calendar’ then
                                    select ‘From Internet’
                                </li>
                                <li>
                                    Paste the copied link into the empty field{' '}
                                </li>
                                <li>
                                    Outlook will ask you if you would like to
                                    add this calendar and subscribe to updates.
                                    Select ‘Yes’
                                </li>
                                <li>
                                    This will sync and import your reservations from
                                    7DAY
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            7DAY allows you to export the reservation information to
                            an external calendar such as Google, Outlook etc. We
                            do not allow for importation of other reservations into
                            7DAY, only individual export.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To export a reservation to a calendar:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on 'Reservations'</li>
                                <li>
                                    Select the reservations you want to export from
                                    7DAY
                                </li>
                                <li>Click on 'Add To Calendar' button</li>
                                <li>Select desired calendar to export to</li>
                            </ol>
                            Please note that any updates to the reservations within
                            the 7DAY app do not autmatically sync to your
                            external calendar.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface IFilterState {
    value: IFilterType;
}

interface IFilterType {
    exportInfo?: any;
}

const initialState: IFilterState = {
    value: {
        exportInfo: [],
    },
};
export const slice = createSlice({
    name: 'reportAPIRefSlice',
    initialState: { ...initialState },
    reducers: {
        openReportAPIRefSliceAction: (state, action: PayloadAction<IFilterType>) => {
            state.value.exportInfo =
                action.payload && action.payload.exportInfo;
        },
    },
});
export const { openReportAPIRefSliceAction } = slice.actions;
export const selectReportAPIRefSliceData = (state: RootState) =>
    state.reportAPIRefSlice.value.exportInfo;
export const ReportAPIRefSliceReducer = slice.reducer;

import { ArrowForward } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Grid, Grow, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { ActionType, Toast, ToastType } from '../../app/Slices/SnackBarSlice';
import { CustomIconButton } from '../../layout/styledcomponents/CustomIconButton';
import toast from './Toast';

const IndividualToast = ({ snackbar }: { snackbar: Toast }) => {
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (
            snackbar.options?.duration !== null &&
            snackbar.options?.duration !== Infinity
        ) {
            timer = setTimeout(() => {
                toast.remove(snackbar.id);
            }, snackbar.options.duration);
        }

        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        if (snackbar.bump) {
            const timer = setTimeout(() => {
                toast.update(snackbar.id, { bump: false });
            }, 300);

            return () => clearTimeout(timer);
        }
    }, [snackbar.bump]);

    const getToastStyles = (type: ToastType) => {
        switch (type) {
            case ToastType.SUCCESS:
                return {
                    backgroundColor: '#edf7ed',
                    icon: (
                        <CheckCircleOutlineIcon
                            sx={{ color: '#2e7d32', mr: 1 }}
                        />
                    ),
                };
            case ToastType.ERROR:
                return {
                    backgroundColor: '#fdeded',
                    icon: <ErrorOutlineIcon sx={{ color: '#d32f2f', mr: 1 }} />,
                };
            case ToastType.INFO:
                return {
                    backgroundColor: ' #e5f6fd',
                    icon: <ErrorOutlineIcon sx={{ color: '#0288d1', mr: 1 }} />,
                };
            case ToastType.WARNING:
                return {
                    backgroundColor: '#fff4e5',
                    icon: <WarningAmberIcon sx={{ color: '#ed6c02', mr: 1 }} />,
                };
        }
    };

    const { backgroundColor, icon } = getToastStyles(snackbar.type);

    return (
        <Grid sx={{ paddingBottom: 1, minHeight: '60px', width: '100%' }}>
            <Grow
                in={snackbar.show}
                style={{ transformOrigin: 'center' }}
                {...{ timeout: 700 }}
            >
                <Paper
                    elevation={4}
                    sx={{
                        backgroundColor,
                        color:
                            snackbar.type === ToastType.ERROR
                                ? '#5f2120'
                                : snackbar.type === ToastType.SUCCESS
                                ? ' #1e4620'
                                : snackbar.type === ToastType.WARNING
                                ? '#663c00'
                                : snackbar.type === ToastType.INFO
                                ? '#014361'
                                : 'white',
                        padding: '8px 16px',
                        borderRadius: 1,
                        maxWidth: '100%',
                        width: '100%',
                        textAlign: 'left',
                        pointerEvents: 'auto',
                        display: 'flex',
                        boxShadow: 'none',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignContent: 'center',
                        minHeight: '60px',
                        animation: snackbar.bump
                            ? 'bump 0.3s ease-in-out'
                            : undefined,
                        '@keyframes bump': {
                            '0%': {
                                transform: 'scale(1)',
                            },

                            '50%': {
                                transform: 'scale(1.015)',
                            },

                            '100%': {
                                transform: 'scale(1)',
                            },
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {icon}
                        <Box
                            sx={{
                                flexGrow: 1,
                                textAlign: 'left',
                                fontSize: '14px',
                                pl: 1,
                            }}
                        >
                            {snackbar.message}
                        </Box>
                    </Box>
                    {snackbar.options.actionText && (
                        <Box
                            sx={{
                                textAlign: 'right',
                            }}
                        >
                            <CustomIconButton
                                size='small'
                                color='inherit'
                                onClick={snackbar.options.actionOnclick}
                                sx={{
                                    fontSize: '14px',
                                    textDecoration: 'underline',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#5cb5b3',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                }}
                            >
                                {snackbar.options.actionText}
                                {snackbar.options.actionType ===
                                    ActionType.NAVIGATE && (
                                    <ArrowForward
                                        sx={{
                                            fontSize: '16px',
                                            paddingLeft: '5px',
                                        }}
                                        fontSize='small'
                                    />
                                )}
                            </CustomIconButton>
                        </Box>
                    )}
                    {snackbar.options.closeButton && (
                        <CustomIconButton
                            size='small'
                            color='inherit'
                            onClick={() => toast.remove(snackbar.id)}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: 'primary.main',
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            <CloseIcon
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                            />
                        </CustomIconButton>
                    )}
                </Paper>
            </Grow>
        </Grid>
    );
};

export default IndividualToast;

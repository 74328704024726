import { FileRepository } from '@amityco/ts-sdk';
import { Dialog, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ImageModal from './ImageModal';

interface ImageContentProps {
    data: Amity.ContentDataImage
}

const ImageContent = ({ data }: ImageContentProps) => {
    const [imageURL, setImageURL] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);

    const fetchImage = async () => {
        if (data?.fileId) {
            const response = await FileRepository.getFile(data?.fileId);
            setImageURL(response.data.fileUrl);
        }
    }

    const renderImage = (size: 'small' | 'medium' | 'large' | 'full') => {
        return FileRepository.fileUrlWithSize(imageURL, size)
    }

    useEffect(() => {
        fetchImage();
    }, [data]);

    return (
        <>
            <img
                src={renderImage('medium')}
                onClick={() => setOpen(true)}
                style={{ maxWidth: '100%' }}
            />
            <ImageModal 
                open={open}
                onClose={() => setOpen(false)}
                src={imageURL}
            />
        </>
    )
}

export default ImageContent
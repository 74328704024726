import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import MakeAReservation from './HelpDocs/Reservations/MakeAReservation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManageAReservation from './HelpDocs/Reservations/ManageAReservation';
import UserRole from '../../../../shared/AuthRoles';
import GettingStarted from './HelpDocs/GettingStarted';
import CancelAReservation from './HelpDocs/Reservations/CancelAReservation';
import ViewReservation from './HelpDocs/Reservations/ViewReservation';
import AddingAUser from './HelpDocs/Users-Profile/AddingAUser';
import ManagingAUser from './HelpDocs/Users-Profile/ManagingAUser';
import ResetPassword from './HelpDocs/Users-Profile/ResetPassword';
import ChangePassword from './HelpDocs/Users-Profile/ChangePassword';
import ManagingProfile from './HelpDocs/Users-Profile/ManagingProfile';
import CreateAnExperience from './HelpDocs/Experiences/CreateAnExperience';
import EditAnExperience from './HelpDocs/Experiences/EditAnExperience';
import DeleteAnExperience from './HelpDocs/Experiences/DeleteAnExperience';
import Support from './HelpDocs/Support';
import AddReservationToExternalCalendar from './HelpDocs/Reservations/AddReservationToExternalCalendar';
import CreateFeeType from './HelpDocs/Fee Types/CreateFeeType';
import EditFeeType from './HelpDocs/Fee Types/EditFeeType';
import DeleteFeeType from './HelpDocs/Fee Types/DeleteFeeType';
import ApplyingAFeeToAnExperience from './HelpDocs/Fee Types/ApplyingAFeeToAnExperience';
import CreateBookingCap from './HelpDocs/Booking Cap/CreateBookingCap';
import EditBookingCap from './HelpDocs/Booking Cap/EditBookingCap';
import DeleteBookingCap from './HelpDocs/Booking Cap/DeleteBookingCap';
import CreatePolicy from './HelpDocs/Policies/CreatePolicy';
import EditPolicy from './HelpDocs/Policies/EditPolicy';
import DeletePolicy from './HelpDocs/Policies/DeletePolicy';
import TeamsExplanation from './HelpDocs/Teams/TeamExplanation';
import ApplyTeam from './HelpDocs/Teams/ApplyTeam';
import CreateTeam from './HelpDocs/Teams/CreateTeam';
import ApplyingAPriceToAnExperience from './HelpDocs/Fee Types/ApplyingAPriceToAnExperience';
import FeesVsPrice from './HelpDocs/Fee Types/FeesVsPrice';
import ServiceFee from './HelpDocs/Fee Types/ServiceFee';
import Discounts from './HelpDocs/Discounts/Discounts';
import NewDiscount from './HelpDocs/Discounts/NewDiscount';
import EditDiscount from './HelpDocs/Discounts/EditDiscount';
import AiReservation from './HelpDocs/Reservations/AIReservation';
import BillingExplanation from './HelpDocs/Billing/BillingExplanation';
import AddBillingDetails from './HelpDocs/Billing/AddBillingDetails';
import EditBillingDetails from './HelpDocs/Billing/EditBillingDetails';
import ImportUsers from './HelpDocs/ImportUsers/ImportUsers';
import LinkCalendars from './HelpDocs/Experiences/LinkCalendars';
import MessagingOverview from './HelpDocs/Messaging/MessagingOverview';
import SupportUsers from './HelpDocs/Messaging/SupportUsers';
import ExperienceMessages from './HelpDocs/Messaging/ExperienceMessages';
import ReservationMessages from './HelpDocs/Messaging/ReservationMessages';
import ViewingMessages from './HelpDocs/Messaging/ViewingMessages';

const helpDocsGettingStarted = [
    {
        helpDocItem: <GettingStarted />,
        helpDocName: 'Getting Started',
        panel: 'gettingStarted1',
    },
    {
        helpDocItem: <Support />,
        helpDocName: 'Getting Help',
        panel: 'gettingStarted2',
    },
];

const helpDocsProfile = [
    {
        helpDocItem: <ManagingProfile />,
        helpDocName: 'How to edit your profile',
        panel: 'profile1',
    },
    {
        helpDocItem: <ChangePassword />,
        helpDocName: 'How to change your password',
        panel: 'profile2',
    },
    {
        helpDocItem: <ResetPassword />,
        helpDocName: 'How to reset your password',
        panel: 'profile4',
    },
];

const helpDocsExperiences = [
    {
        helpDocItem: <CreateAnExperience />,
        helpDocName: 'How to create a new experience',
        panel: 'experience1',
    },
    {
        helpDocItem: <EditAnExperience />,
        helpDocName: 'How to edit an experience',
        panel: 'experience2',
    },
    {
        helpDocItem: <DeleteAnExperience />,
        helpDocName: 'How to delete an experience',
        panel: 'experience3',
    },
    {
        helpDocItem: <LinkCalendars />,
        helpDocName: 'How to link calendars for multiple experiences',
        panel: 'experience4',
    },
];

const helpDocsFeeTypes = [
    {
        helpDocItem: <FeesVsPrice />,
        helpDocName: 'What is the difference between Pricing & Fees?',
        panel: 'feeType1',
    },
    {
        helpDocItem: <CreateFeeType />,
        helpDocName: 'How to create a new fee type',
        panel: 'feeType2',
    },
    {
        helpDocItem: <EditFeeType />,
        helpDocName: 'How to edit a fee type',
        panel: 'feeType3',
    },
    {
        helpDocItem: <DeleteFeeType />,
        helpDocName: 'How to delete a fee type',
        panel: 'feeType4',
    },
    {
        helpDocItem: <ApplyingAFeeToAnExperience />,
        helpDocName: 'How to apply a fee to an experience',
        panel: 'feeType5',
    },
    {
        helpDocItem: <ApplyingAPriceToAnExperience />,
        helpDocName: 'How to apply a base or custom price to an experience',
        panel: 'feeType6',
    },
    {
        helpDocItem: <ServiceFee />,
        helpDocName: 'What is 7DAYs Service Fee?',
        panel: 'feeType7',
    },
];

const discounts = [
    {
        helpDocItem: <Discounts />,
        helpDocName: 'Discount Overview',
        panel: 'discounts1',
    },
    {
        helpDocItem: <NewDiscount />,
        helpDocName: 'How to create a discount',
        panel: 'discounts2',
    },
    {
        helpDocItem: <EditDiscount />,
        helpDocName: 'How to edit a discount',
        panel: 'discounts3',
    },
];

const helpDocsBookingCap = [
    {
        helpDocItem: <CreateBookingCap />,
        helpDocName: 'How to create a new booking cap and apply to guests',
        panel: 'bookCap1',
    },
    {
        helpDocItem: <EditBookingCap />,
        helpDocName: 'How to edit a booking cap',
        panel: 'bookCap2',
    },
    {
        helpDocItem: <DeleteBookingCap />,
        helpDocName: 'How to delete a booking cap',
        panel: 'bookCap3',
    },
];

const helpDocsPolicies = [
    {
        helpDocItem: <CreatePolicy />,
        helpDocName: 'How to create a new policy and apply to an experience',
        panel: 'polCap1',
    },
    {
        helpDocItem: <EditPolicy />,
        helpDocName: 'How to edit a Policy',
        panel: 'polCap2',
    },
    {
        helpDocItem: <DeletePolicy />,
        helpDocName: 'How to delete a Policy',
        panel: 'polCap3',
    },
];

const helpDocsTeams = [
    {
        helpDocItem: <TeamsExplanation />,
        helpDocName: 'What are Teams',
        panel: 'teams1',
    },
    {
        helpDocItem: <CreateTeam />,
        helpDocName: 'How to create a Team',
        panel: 'teams2',
    },
    {
        helpDocItem: <ApplyTeam />,
        helpDocName: 'How to apply a Team to your profile',
        panel: 'teams3',
    },
];

const billing = [
    {
        helpDocItem: <BillingExplanation />,
        helpDocName: 'How Billing works',
        panel: 'billing1',
    },
    {
        helpDocItem: <AddBillingDetails />,
        helpDocName: 'How to add billing details',
        panel: 'billing2',
    },
    {
        helpDocItem: <EditBillingDetails />,
        helpDocName: 'How to edit billing details',
        panel: 'billing3',
    },
];

export default function DocumentationComponentPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [expanded, setExpanded] = React.useState<string | true>(true);
    const loggedInUser = UserRole();

    const helpDocsReservations = [
        {
            helpDocItem: <MakeAReservation />,
            helpDocName: 'How to make a reservation',
            panel: 'reservation1',
        },
        {
            helpDocItem: <ViewReservation />,
            helpDocName: 'How to view a reservation',
            panel: 'reservation2',
        },
        {
            helpDocItem: <ManageAReservation />,
            helpDocName: 'How to manage a reservation',
            panel: 'reservation3',
        },
        {
            helpDocItem: <CancelAReservation />,
            helpDocName: 'How to cancel a reservation',
            panel: 'reservation4',
        },
        {
            helpDocItem: <AddReservationToExternalCalendar />,
            helpDocName: 'How to add a reservation to your external calendar',
            panel: 'reservation5',
        },
        ...(loggedInUser?.role.includes('owner')
            ? [
                  {
                      helpDocItem: <AiReservation />,
                      helpDocName:
                          'Use 7DAY AI to help you book or block an individual reservation or a recurring reservation',
                      panel: 'reservation6',
                  },
              ]
            : []),
    ];

    const helpDocsUsers = [
        {
            helpDocItem: <AddingAUser />,
            helpDocName: 'How to add a new user',
            panel: 'users1',
        },
        {
            helpDocItem: <ManagingAUser />,
            helpDocName: 'How to manage a user (view, edit, disable/enable)',
            panel: 'users2',
        },
        {
            helpDocItem: <ChangePassword />,
            helpDocName: 'How to change a users password',
            panel: 'users3',
        },
        {
            helpDocItem: <ResetPassword />,
            helpDocName: 'How to reset a users password',
            panel: 'users4',
        },
        loggedInUser?.role?.includes('guest')
            ? {
                  helpDocItem: <ImportUsers />,
                  helpDocName: 'How to bulk import a list of users in CSV format',
                  panel: 'users5',
              }
            : null,
    ].filter(Boolean);

    const helpDocsMessaging = [
        {
            helpDocItem: <MessagingOverview />,
            helpDocName: 'Messaging Overview',
            panel: 'messaging1',
        },
        ...(loggedInUser?.role.includes('owner')
            ? [
                  {
                      helpDocItem: <SupportUsers />,
                      helpDocName: 'Support Users',
                      panel: 'messaging2',
                  },
              ]
            : []),
        {
            helpDocItem: <ExperienceMessages />,
            helpDocName: 'Experience Messages',
            panel: 'messaging3',
        },
        {
            helpDocItem: <ReservationMessages />,
            helpDocName: 'Reservation Messages',
            panel: 'messaging4',
        },
        {
            helpDocItem: <ViewingMessages />,
            helpDocName: 'Viewing Messages',
            panel: 'messaging5',
        },
    ];

    useEffect(() => {
        setExpanded('gettingStarted1');
    }, []);

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : true);
        };

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            <Grid
                sx={{
                    textAlign: isMobile ? 'left' : 'right',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? 'left' : 'right',
                        justifyContent: isMobile ? 'flex-start' : 'flex-end',
                    }}
                ></Grid>
            </Grid>
            <Grid>
                {helpDocsGettingStarted.map((item: any) => (
                    <Accordion
                        expanded={expanded === item.panel}
                        onChange={handleChange(item.panel)}
                        key={item.helpDocName}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            sx={{
                                backgroundColor:
                                    expanded === item.panel ? '#f6f8f9' : '',
                            }}
                        >
                            <Typography
                                component='h3'
                                variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 550,
                                    // textTransform: 'uppercase',
                                }}
                            >
                                {item.helpDocName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>{item.helpDocItem}</AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
            {loggedInUser?.role.includes('owner') ? (
                <Grid>
                    <Grid
                        sx={{
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {' '}
                        <Typography
                            component='h3'
                            variant='h6'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                fontWeight: 550,
                                // textTransform: 'uppercase',
                            }}
                        >
                            Experiences
                        </Typography>
                    </Grid>

                    {helpDocsExperiences.map((item: any) => (
                        <Accordion
                            expanded={expanded === item.panel}
                            onChange={handleChange(item.panel)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                                sx={{
                                    backgroundColor:
                                        expanded === item.panel
                                            ? '#f6f8f9'
                                            : '',
                                }}
                            >
                                <Typography
                                    component='h3'
                                    variant='h6'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 550,
                                        // textTransform: 'uppercase',
                                    }}
                                >
                                    {item.helpDocName}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item.helpDocItem}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            ) : null}
            <Grid>
                <Grid
                    sx={{
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Typography
                        component='h3'
                        variant='h6'
                        color='#5cb5b3'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 550,
                            // textTransform: 'uppercase',
                        }}
                    >
                        Reservations
                    </Typography>
                </Grid>

                {helpDocsReservations?.map((item: any) => (
                    <Accordion
                        expanded={expanded === item?.panel}
                        onChange={handleChange(item?.panel)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            sx={{
                                backgroundColor:
                                    expanded === item?.panel ? '#f6f8f9' : '',
                            }}
                        >
                            <Typography
                                component='h3'
                                variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 550,
                                    // textTransform: 'uppercase',
                                }}
                            >
                                {item?.helpDocName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>{item?.helpDocItem}</AccordionDetails>
                    </Accordion>
                ))}
            </Grid>

            <Grid>
                <Grid
                    sx={{
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Typography
                        component='h3'
                        variant='h6'
                        color='#5cb5b3'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 550,
                            // textTransform: 'uppercase',
                        }}
                    >
                        Users
                    </Typography>
                </Grid>

                {helpDocsUsers.map((item: any) => (
                    <Accordion
                        expanded={expanded === item.panel}
                        onChange={handleChange(item.panel)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            sx={{
                                backgroundColor:
                                    expanded === item.panel ? '#f6f8f9' : '',
                            }}
                        >
                            <Typography
                                component='h3'
                                variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 550,
                                    // textTransform: 'uppercase',
                                }}
                            >
                                {item.helpDocName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>{item.helpDocItem}</AccordionDetails>
                    </Accordion>
                ))}
            </Grid>

            {loggedInUser?.role.includes('owner') ? (
                <Grid>
                    <Grid
                        sx={{
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {' '}
                        <Typography
                            component='h3'
                            variant='h6'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                fontWeight: 550,
                                // textTransform: 'uppercase',
                            }}
                        >
                            Pricing & Fees
                        </Typography>
                    </Grid>

                    {helpDocsFeeTypes.map((item: any) => (
                        <Accordion
                            expanded={expanded === item.panel}
                            onChange={handleChange(item.panel)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                                sx={{
                                    backgroundColor:
                                        expanded === item.panel
                                            ? '#f6f8f9'
                                            : '',
                                }}
                            >
                                <Typography
                                    component='h3'
                                    variant='h6'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 550,
                                        // textTransform: 'uppercase',
                                    }}
                                >
                                    {item.helpDocName}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item.helpDocItem}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            ) : null}
            {loggedInUser?.role.includes('owner') ? (
                <Grid>
                    <Grid
                        sx={{
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {' '}
                        <Typography
                            component='h3'
                            variant='h6'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                fontWeight: 550,
                                // textTransform: 'uppercase',
                            }}
                        >
                            Discounts
                        </Typography>
                    </Grid>

                    {discounts.map((item: any) => (
                        <Accordion
                            expanded={expanded === item.panel}
                            onChange={handleChange(item.panel)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                                sx={{
                                    backgroundColor:
                                        expanded === item.panel
                                            ? '#f6f8f9'
                                            : '',
                                }}
                            >
                                <Typography
                                    component='h3'
                                    variant='h6'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 550,
                                        // textTransform: 'uppercase',
                                    }}
                                >
                                    {item.helpDocName}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item.helpDocItem}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            ) : null}
            {loggedInUser?.role.includes('guest') ? (
                <Grid>
                    <Grid
                        sx={{
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {' '}
                        <Typography
                            component='h3'
                            variant='h6'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                fontWeight: 550,
                                // textTransform: 'uppercase',
                            }}
                        >
                            Billing
                        </Typography>
                    </Grid>

                    {billing.map((item: any) => (
                        <Accordion
                            expanded={expanded === item.panel}
                            onChange={handleChange(item.panel)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                                sx={{
                                    backgroundColor:
                                        expanded === item.panel
                                            ? '#f6f8f9'
                                            : '',
                                }}
                            >
                                <Typography
                                    component='h3'
                                    variant='h6'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 550,
                                        // textTransform: 'uppercase',
                                    }}
                                >
                                    {item.helpDocName}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item.helpDocItem}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            ) : null}

            {loggedInUser?.role.includes('owner') ? (
                <Grid>
                    <Grid
                        sx={{
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {' '}
                        <Typography
                            component='h3'
                            variant='h6'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                fontWeight: 550,
                                // textTransform: 'uppercase',
                            }}
                        >
                            Booking Caps
                        </Typography>
                    </Grid>

                    {helpDocsBookingCap?.map((item: any) => (
                        <Accordion
                            expanded={expanded === item?.panel}
                            onChange={handleChange(item?.panel)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                                sx={{
                                    backgroundColor:
                                        expanded === item?.panel
                                            ? '#f6f8f9'
                                            : '',
                                }}
                            >
                                <Typography
                                    component='h3'
                                    variant='h6'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 550,
                                        // textTransform: 'uppercase',
                                    }}
                                >
                                    {item.helpDocName}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item.helpDocItem}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            ) : null}
            {loggedInUser?.role.includes('owner') ? (
                <Grid>
                    <Grid
                        sx={{
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {' '}
                        <Typography
                            component='h3'
                            variant='h6'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                fontWeight: 550,
                                // textTransform: 'uppercase',
                            }}
                        >
                            Reservation Policies
                        </Typography>
                    </Grid>

                    {helpDocsPolicies?.map((item: any) => (
                        <Accordion
                            expanded={expanded === item?.panel}
                            onChange={handleChange(item?.panel)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                                sx={{
                                    backgroundColor:
                                        expanded === item.panel
                                            ? '#f6f8f9'
                                            : '',
                                }}
                            >
                                <Typography
                                    component='h3'
                                    variant='h6'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 550,
                                        // textTransform: 'uppercase',
                                    }}
                                >
                                    {item.helpDocName}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item.helpDocItem}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            ) : null}
            <Grid>
                <Grid
                    sx={{
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Typography
                        component='h3'
                        variant='h6'
                        color='#5cb5b3'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 550,
                            // textTransform: 'uppercase',
                        }}
                    >
                        In-app Messaging
                    </Typography>
                </Grid>

                {helpDocsMessaging.map((item: any) => (
                    <Accordion
                        expanded={expanded === item.panel}
                        onChange={handleChange(item.panel)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            sx={{
                                backgroundColor:
                                    expanded === item.panel ? '#f6f8f9' : '',
                            }}
                        >
                            <Typography
                                component='h3'
                                variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 550,
                                    // textTransform: 'uppercase',
                                }}
                            >
                                {item.helpDocName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>{item.helpDocItem}</AccordionDetails>
                    </Accordion>
                ))}
            </Grid>

            <Grid>
                <Grid
                    sx={{
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Typography
                        component='h3'
                        variant='h6'
                        color='#5cb5b3'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 550,
                            // textTransform: 'uppercase',
                        }}
                    >
                        Profile
                    </Typography>
                </Grid>

                {helpDocsProfile.map((item: any) => (
                    <Accordion
                        expanded={expanded === item.panel}
                        onChange={handleChange(item.panel)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            sx={{
                                backgroundColor:
                                    expanded === item.panel ? '#f6f8f9' : '',
                            }}
                        >
                            <Typography
                                component='h3'
                                variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 550,
                                    // textTransform: 'uppercase',
                                }}
                            >
                                {item.helpDocName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>{item.helpDocItem}</AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
            {loggedInUser?.role.includes('guest') ? (
                <Grid>
                    <Grid
                        sx={{
                            height: '100px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        {' '}
                        <Typography
                            component='h3'
                            variant='h6'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                fontWeight: 550,
                                // textTransform: 'uppercase',
                            }}
                        >
                            Teams
                        </Typography>
                    </Grid>

                    {helpDocsTeams.map((item: any) => (
                        <Accordion
                            expanded={expanded === item.panel}
                            onChange={handleChange(item.panel)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                                sx={{
                                    backgroundColor:
                                        expanded === item.panel
                                            ? '#f6f8f9'
                                            : '',
                                }}
                            >
                                <Typography
                                    component='h3'
                                    variant='h6'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontWeight: 550,
                                        // textTransform: 'uppercase',
                                    }}
                                >
                                    {item.helpDocName}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {item.helpDocItem}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            ) : null}
        </Grid>
    );
}

import { Box, useMediaQuery } from '@mui/material';
import { GridColDef, GridEventListener, useGridApiRef } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openReportAPIRefSliceAction } from '../../../../../app/Slices/ReportAPIRefSlice';
import { Earnings } from '../../../../../app/Slices/SevenDayApiTypes';
import StripedDataGrid from '../../../../shared/StripedDataGrid';
import {
    formatCurrencyShort,
    getPaymentStatus,
} from '../../../../../shared/utils';

export default function HostPaymentsReportList({
    earnings,
    serviceFeePercentage,
}: {
    earnings: Earnings;
    serviceFeePercentage: number;
}) {
    const {
        bills: payments,
        bookingsTotal,
        amount,
        serviceFeeAmount,
    } = earnings;
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let csv = apiRef?.current.exportDataAsCsv;
        let print = apiRef?.current.exportDataAsPrint;
        dispatch(
            openReportAPIRefSliceAction({
                exportInfo: {
                    csvExport: csv,
                    printExport: print,
                    search: apiRef?.current.setQuickFilterValues,
                },
            })
        );
    }, [apiRef, dispatch]);

    const handleRowClick: GridEventListener<'rowClick'> = ({
        row: { id, ownerId, year, month },
    }) => {
        if (id === 'total') return;
        navigate(`/host-payment/${ownerId}/${year}/${month}`, {
            state: { name: 'hostPayment' },
        });
    };

    const columns: GridColDef[] = [
        {
            field: 'period',
            headerName: 'Period',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'ownerName',
            headerName: 'Owner',
            width: isMobile ? 80 : isIpad ? 100 : 150,
            type: 'string',
            sortable: true,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            valueFormatter: ({ value }) => formatCurrencyShort(value),
            sortable: true,
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'serviceFeeAmount',
            headerName: `7DAY service Fee (${serviceFeePercentage}%)`,
            width: isMobile ? 80 : isIpad ? 100 : 150,
            valueFormatter: ({ value }) => formatCurrencyShort(value),
            sortable: true,
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'gross',
            headerName: 'Gross',
            width: isMobile ? 80 : isIpad ? 100 : 150,
            valueFormatter: ({ value }) => formatCurrencyShort(value),
            sortable: true,
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'paidAt',
            headerName: 'Paid Date',
            width: isMobile ? 80 : isIpad ? 100 : 150,
            sortable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            type: 'string',
            sortable: true,
        },
    ];
    const rows = [
        ...payments
            .filter((payment) => payment.amount)
            .map((payment) => {
                const {
                    periodStart,
                    periodEnd,
                    ownerName,
                    bookingsTotal: amount,
                    serviceFeeAmount,
                    amount: gross,
                    paidAt,
                    ownerId,
                    year,
                    month,
                } = payment;
                return {
                    id: `${ownerId}-${year}-${month}`,
                    period: `${moment(new Date(periodStart)).format(
                        'D MMM'
                    )} - ${moment(new Date(periodEnd)).format('D MMM, YYYY')}`,
                    ownerName,
                    amount,
                    serviceFeeAmount,
                    gross,
                    paidAt: paidAt ? moment(paidAt).format('D MMM YYYY') : '',
                    status: getPaymentStatus(payment),
                    ownerId,
                    year,
                    month,
                };
            }),
        {
            id: 'total',
            ownerName: 'Total',
            amount: bookingsTotal,
            serviceFeeAmount,
            gross: amount,
        },
    ];

    return (
        <Box sx={{ height: 800, width: 'auto', paddingTop: '50px' }}>
            <StripedDataGrid
                sx={{
                    boxShadow: 0,
                    border: 0,
                    '& .MuiDataGrid-cell': {
                        paddingLeft: '0',
                    },
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                    },
                }}
                onRowClick={handleRowClick}
                columns={columns}
                rows={rows}
                apiRef={apiRef}
            />
        </Box>
    );
}

import {  Clear, FilePresent } from '@mui/icons-material'
import { Badge, Box, IconButton, Typography } from '@mui/material'
import { FileRepository } from '@amityco/ts-sdk'
import React from 'react'

interface FilePreviewProps {
    file: Amity.File,
    handleDelete: () => void,
}

const FilePreview = ({ file, handleDelete }: FilePreviewProps) => {
    return (
        <Box px={2} pt={2}>
             <Badge
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                badgeContent={
                    <IconButton 
                        onClick={handleDelete}
                        sx={{
                            bgcolor: 'white',
                            p: '2px' 
                        }}
                    >
                        <Clear fontSize='small'/>
                    </IconButton>
                }
            >
                {file.type === 'image' && (
                    <img src={FileRepository.fileUrlWithSize(file.fileUrl, 'small')} />
                )}
                {file.type === 'file' && (
                    <>
                        <FilePresent />
                        <Typography variant='subtitle2'>
                            {file.attributes.name}
                        </Typography>
                    </>
                )}
            </Badge>
        </Box>
       
    )
}

export default FilePreview
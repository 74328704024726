import React from "react";

export function usePopoverOnButtonClick() {
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTab = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;
  return { anchorEl, open, handleClick, handleTab, handleClose, popoverId };
}

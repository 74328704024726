import {
    DataGrid,
    GridColDef,
    GridEventListener,
    useGridApiRef,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import moment from 'moment';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openReportAPIRefSliceAction } from '../../../../../app/Slices/ReportAPIRefSlice';
import UserRole from '../../../../../shared/AuthRoles';
import { Link, useNavigate } from 'react-router-dom';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 900,
        color: '#000',
        borderBottom: '1px solid #787878',
        paddingLeft: '0px',
    },
    [`& .${gridClasses.row}`]: {
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                },
            },
        },
    },
    '& .center-align-cell': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default function FYListReport(params: any) {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();

    useEffect(() => {
        let csv = apiRef?.current.exportDataAsCsv;
        let print = apiRef?.current.exportDataAsPrint;
        dispatch(
            openReportAPIRefSliceAction({
                exportInfo: {
                    csvExport: csv,
                    printExport: print,
                    search: apiRef?.current.setQuickFilterValues,
                },
            })
        );
    }, [apiRef, dispatch]);

    // const navigate = useNavigate();

    // const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    //     navigate(
    //         `/reservations/${params?.row?.moduleName
    //             .replace(/ /g, '')
    //             .toLowerCase()}/${params?.row?.id}`,
    //         {
    //             state: {
    //                 name: 'viewReservation',
    //                 component: 'reports',
    //                 url: 'reports/fyReport',
    //                 params: params.row,
    //             },
    //         }
    //     );
    // };

    const renderLinkCell = (params: any) => (
        <Link
            to={{
                pathname: `/reservations/${params.row.moduleName
                    .replace(/ /g, '')
                    .toLowerCase()}/${params.row.id}`,
            }}
            state={{
                name: 'viewReservation',
                component: 'reports',
                url: 'reports/fyReport',
                params: params.row,
            }}
            style={{
                textDecoration: 'none',
                color: 'inherit',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent:
                    params.field === 'guests' ||
                    params.field === 'overnightGuests' ||
                    params.field === 'requiresCatering' ||
                    params.field === 'requiresBeverages'
                        ? 'center'
                        : 'flex-start',
                cursor: 'pointer',
            }}
        >
            {params.value}
        </Link>
    );

    const columns: GridColDef[] = [
        {
            field: 'moduleName',
            headerName: 'Experience',
            flex: 1,
            minWidth: isIpad ? 200 : 100,
            type: 'string',
            sortable: true,
            renderCell: renderLinkCell,
        },
        {
            field: 'ownerName',
            headerName: 'Host',
            width: isMobile ? 80 : isIpad ? 100 : 140,
            type: 'string',
            sortable: true,
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value:
                        params?.value?.charAt(0).toUpperCase() +
                        params?.value?.slice(1),
                }),
        },
        {
            field: 'moduleType',
            headerName: 'Type',
            width: isMobile ? 80 : isIpad ? 80 : 80,
            type: 'string',
            sortable: true,
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value:
                        params?.value.charAt(0).toUpperCase() +
                        params?.value.slice(1),
                }),
        },
        {
            field: 'id',
            headerName: 'Reservation Ref',
            width: isMobile ? 80 : isIpad ? 80 : 80,
            type: 'string',
            sortable: true,
            renderCell: (params) =>
                renderLinkCell({ ...params, value: `#${params?.value}` }),
        },
        {
            field: 'guestName',
            headerName: loggedInUser?.role.includes('owner')
                ? 'Guest Company'
                : 'Company',
            description: 'Company who made the reservation',
            width: isMobile ? 80 : isIpad ? 80 : 150,
            type: 'string',
            sortable: true,
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value: loggedInUser?.role.includes('owner')
                        ? params?.value
                            ? params?.value.charAt(0).toUpperCase() +
                              params?.value.slice(1)
                            : `${params?.row.ownerName} (on behalf)`
                        : params?.value,
                }),
        },
        {
            field: 'bookedFor',
            headerName: 'Guest',
            description: 'Guest attending',
            width: isMobile ? 80 : isIpad ? 80 : 150,
            type: 'string',
            sortable: true,
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value:
                        params?.value?.charAt(0).toUpperCase() +
                        params?.value?.slice(1),
                }),
        },
        {
            field: 'bookerType',
            headerName: 'Guest Type',
            width: isMobile ? 80 : isIpad ? 80 : 80,
            type: 'string',
            sortable: true,
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value:
                        params?.value.charAt(0).toUpperCase() +
                        params?.value.slice(1),
                }),
        },
        {
            field: 'visitType',
            headerName: 'Type of Visit',
            width: isMobile ? 200 : isIpad ? 80 : 100,
            type: 'string',
            sortable: true,
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value:
                        params?.value.charAt(0).toUpperCase() +
                        params?.value.slice(1),
                }),
        },
        {
            field: 'startDate',
            headerName: 'Reservation Start',
            type: 'date',
            width: isMobile ? 200 : isIpad ? 80 : 100,
            sortable: true,
            valueGetter: (params) => new Date(params.value),
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value: moment(params?.value).format('DD/MM/YYYY'),
                }),
        },
        {
            field: 'guests',
            headerName: 'Guests',
            width: isMobile ? 200 : isIpad ? 80 : 80,
            align: 'center',
            type: 'number',
            sortable: true,
            renderCell: renderLinkCell,
        },
        {
            field: 'overnightGuests',
            headerName: 'Overnight Guests',
            width: isMobile ? 200 : isIpad ? 120 : 120,
            align: 'center',
            type: 'number',
            sortable: true,
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value: params?.value ? params?.value : '-',
                }),
        },
        {
            field: 'requiresCatering',
            headerName: 'Catering',
            width: isMobile ? 200 : isIpad ? 150 : 80,
            type: 'boolean',
            sortable: true,
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value: params?.value === true ? 'Yes' : 'No',
                }),
        },
        {
            field: 'requiresBeverages',
            headerName: 'Beverages',
            width: isMobile ? 200 : isIpad ? 150 : 80,
            type: 'boolean',
            sortable: true,
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value: params?.value === true ? 'Yes' : 'No',
                }),
        },
        {
            field: 'endDate',
            headerName: 'Completed',
            type: 'date',
            width: isMobile ? 200 : isIpad ? 80 : 100,
            sortable: true,
            valueGetter: (params) => new Date(params.value),
            renderCell: (params) =>
                renderLinkCell({
                    ...params,
                    value: moment(params?.value).format('DD/MM/YYYY'),
                }),
        },
    ];

    return (
        <Box sx={{ height: 800, width: 'auto', paddingTop: '50px' }}>
            <StripedDataGrid
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                // onRowClick={handleRowClick}
                sx={{
                    boxShadow: 0,
                    border: 0,
                    '& .MuiDataGrid-cell': {
                        padding: '0',
                    },
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            bookerType: loggedInUser?.role.includes('owner'),
                        },
                    },
                }}
                apiRef={apiRef}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={params?.data !== undefined ? params?.data : []}
                columns={columns}
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Results
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                No reservation have been completed
                                                during this period
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Results
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please try again
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
}

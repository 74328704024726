import {
    Box,
    Container,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { XeroSettingsForm } from '../../../components/7DAY Admin Components/Xero/XeroSettingsForm';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { XeroConnectionComponent } from '../../../components/7DAY Admin Components/Xero/XeroConnectionComponent';
import { useGetXeroConnectionQuery } from '../../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import UserRole from '../../../../shared/AuthRoles';
import ReactGA from 'react-ga4';

export const BillsAndInvoicesPage = () => {
    const { data: connection, isLoading } = useGetXeroConnectionQuery();

    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();
    const loggedInUser = UserRole();

    useEffect(() => {
        if (loggedInUser.role !== 'admin') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'billsAndInvoicesPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    const handleBackButton = () => {
        navigate('/adminCenter/host');
    };
    return isLoading ? (
        <LoadingComponent color='green' />
    ) : (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile || isIpad ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                }}
            >
                <Stack height='100%' gap='40px'>
                    <Typography
                        variant='h5'
                        color='textPrimary'
                        sx={{
                            textTransform: 'uppercase',
                            textDecoration: 'underline',
                            textDecorationColor: '#5cb5b3',
                            textUnderlineOffset: '8px',
                        }}
                    >
                        Bills and Invoices
                    </Typography>
                    <XeroConnectionComponent connection={connection} />
                    <XeroSettingsForm connected={!!connection} />
                </Stack>

                <CustomButton
                    onClick={handleBackButton}
                    color='green'
                    sx={{
                        marginLeft: !isMobile ? '-8px' : '-18px',
                        '&:hover': {
                            cursor: 'pointer',
                            color: '#5cb5b3',
                            background: 'none',
                        },
                    }}
                >
                    <ArrowBackIcon />
                    {isMobile ? null : (
                        <Typography
                            sx={{
                                fontSize: '12px',
                                paddingLeft: '5px',
                            }}
                        >
                            Back to Admin Center
                        </Typography>
                    )}
                </CustomButton>
            </Box>
        </Container>
    );
};

import React, { useEffect } from 'react';
import {
    Box,
    Container,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import UserRole from '../../../shared/AuthRoles';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Earnings } from '../../components/HostPayments/ReservationEarnings';

export const EarningsPage = () => {
    const loggedInUser = UserRole();
    useEffect(() => {
        if (loggedInUser.role !== 'owner_admin') {
            navigate(`/permissionDenied`);
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, []);

    const isMobile = useMediaQuery('(max-width: 770px)');
    const navigate = useNavigate();

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? '90%' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Stack
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Typography
                        variant='h5'
                        color='textPrimary'
                        sx={{
                            textDecoration: 'underline',
                            textDecorationColor: (theme) =>
                                theme.palette.primary.main,
                            textUnderlineOffset: '8px',
                        }}
                    >
                        Reservation Earnings
                    </Typography>
                </Box>
                <Earnings />
            </Stack>
        </Container>
    );
};

import { Grid, InputLabel, Typography, useMediaQuery } from '@mui/material';
import { SmlNumberArray } from '../Boat/NumberArray';
import React from 'react';
import { TimeArray } from '../Property/TimeArray';
import FormAutocomplete from '../../../../shared/FormAutocomplete';

import { useFormContext, useWatch } from 'react-hook-form'
import FormTextField from '../../../../shared/FormTextField';

export default function NewActivityAmenitiesForm() {
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const { control } = useFormContext();

    return (
        <Grid sx={{ paddingTop: isIpad ? '10px' : '0px' }}>
            <Typography
                color='textPrimary'
                pb='30px'
            >
                Tell guests about the activity.
            </Typography>

            <Grid container columns={25} pb={1}>
                <Grid
                    item
                    xs={25}
                    lg={5}
                >
                    <InputLabel required>Activity Type</InputLabel>
                </Grid>
                <Grid
                    item
                    xs={25}
                    lg={7}
                    sx={{
                        paddingBottom: isMobile || isIpad ? '5px' : '0px',
                    }}
                >
                    <FormAutocomplete
                        name='details.subType'
                        control={control}
                        options={[
                            { value: 'buggies', label: 'Buggies' },
                            { value: 'eBikes', label: 'E-Bikes' },
                            { value: 'goKarts', label: 'GoKarts' },
                        ]}
                    />
                </Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid
                    item
                    xs={25}
                    lg={5}
                >
                    <InputLabel required>No. Available</InputLabel>
                </Grid>
                <Grid
                    item
                    xs={25}
                    lg={7}
                >
                    <FormAutocomplete
                        name='details.quantity'
                        control={control}
                        options={SmlNumberArray}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

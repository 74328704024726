import React, { useState } from 'react'

import { 
    Avatar, Badge, Box, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, 
    ListItemIcon, 
    ListItemText, 
    Stack, Tooltip, Typography, useMediaQuery 
} from '@mui/material'
import { Cancel, HelpOutline } from '@mui/icons-material'

import { 
    useGetProfileCompanyInfoQuery, 
    useGetProfileDetailsQuery
} from '../../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../../shared/AuthRoles';
import ProfilePicture from '../ProfilePicture';
import RoleChip from '../../../shared/RoleChip';


const ProfileSummary = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');

    const loggedInUser = UserRole();

    const { data: profileDetails } = useGetProfileDetailsQuery('/profile');
    const { data: companyDetails } = 
        useGetProfileCompanyInfoQuery(`${loggedInUser?.role.slice(0, 5)}s/${loggedInUser?.company}`)

    return (
        <>
            <Stack 
                direction={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'center' : 'left'}
                gap='30px' 
                py='30px'
            >
                <Badge
                    overlap='circular'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                        <Tooltip
                            title={
                                <Typography
                                    // color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    <List>
                                        <ListItem>
                                            {' '}
                                            Your profile picture will be used 
                                            for facial recognition.
                                        </ListItem>
                                        <ListItem>
                                            {' '}
                                            For security reasons, a profile picture is mandatory for some experiences.
                                        </ListItem>
                                    </List>
                                </Typography>
                            }
                            placement='right'
                        >
                            <HelpOutline
                                sx={{
                                    color: '#787878',
                                    bgcolor: '#fff',
                                    borderRadius: '24px',
                                    padding: '1px',
                                    fontSize: '24px',
                                }}
                            />
                        </Tooltip>
                    }
                >
                    <ProfilePicture profileDetails={profileDetails} src={profileDetails?.profilePicture?.src}/>
                </Badge>
                <Stack alignItems={isMobile ? 'center' : 'left'}>
                    <Typography
                        variant='h6'
                        color='#787878'
                        fontFamily='Open Sans'
                        fontWeight='bold'
                    >
                        {profileDetails?.firstName} {profileDetails?.lastName}
                    </Typography>
                    <Typography
                        variant='body1'
                        color='#787878'
                        fontFamily='Open Sans'
                        gutterBottom
                    >
                        {companyDetails?.name} - {' '}
                        <RoleChip user={profileDetails}/>
                    </Typography>
                    <Typography
                        variant='body2'
                        color='#5cb5b3'
                        fontFamily='Open Sans'
                        gutterBottom
                    >
                        {profileDetails?.email}
                    </Typography>
                </Stack>
            </Stack>
        </>
    )
}

export default ProfileSummary
import BedIcon from '@mui/icons-material/Bed';
import ShowerIcon from '@mui/icons-material/Shower';
import KitchenIcon from '@mui/icons-material/Kitchen';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import WifiIcon from '@mui/icons-material/Wifi';
import TvIcon from '@mui/icons-material/Tv';
import PoolIcon from '@mui/icons-material/Pool';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import WineBarIcon from '@mui/icons-material/WineBar';
import { fontSize } from '@mui/system';
import HotTubIcon from '@mui/icons-material/HotTub';
import KitesurfingIcon from '@mui/icons-material/Kitesurfing';
import DeskIcon from '@mui/icons-material/Desk';
import React from 'react';
import SpaIcon from '@mui/icons-material/Spa';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import AirlineSeatLegroomExtraIcon from '@mui/icons-material/AirlineSeatLegroomExtra';
import AirlineSeatLegroomReducedIcon from '@mui/icons-material/AirlineSeatLegroomReduced';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import NavigationIcon from '@mui/icons-material/Navigation';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import SpeedIcon from '@mui/icons-material/Speed';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { Liquor, Restaurant } from '@mui/icons-material';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import LockIcon from '@mui/icons-material/Lock';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import PetsIcon from '@mui/icons-material/Pets';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import BalconyIcon from '@mui/icons-material/Balcony';

const amenitiesNameMapping: any = [
    {
        optionKey: 'type',
        value: [
            {
                mappingKey: 'quantity',
                name: 'No. Available',
            },

            {
                mappingKey: 'bedrooms',
                name: 'Bedrooms',
            },
            {
                mappingKey: 'beds',
                name: 'Beds',
            },
            {
                mappingKey: 'baths',
                name: 'Bathroom',
            },
            {
                mappingKey: 'maxGuests',
                name: 'No. Guests',
            },
            {
                mappingKey: 'checkinTime',
                name: 'Checkin Time',
            },
            {
                mappingKey: 'checkoutTime',
                name: 'Checkout Time',
            },
            {
                mappingKey: 'checkinMethod',
                name: 'Checkin Method',
            },
            {
                mappingKey: 'dedicatedWorkspace',
                name: 'Dedicated Workspace',
            },
            {
                mappingKey: 'wifiDetails',
                name: 'Wifi Details',
            },
            {
                mappingKey: 'kitchen',
                name: 'Kitchen',
            },
            {
                mappingKey: 'gamesRoom',
                name: 'Games Room',
            },

            {
                mappingKey: 'boardroom',
                name: 'Boardroom',
            },
            {
                mappingKey: 'firepit',
                name: 'Indoor/Outdoor Fire',
            },
            {
                mappingKey: 'wifi',
                name: 'Wifi',
            },
            {
                mappingKey: 'tv',
                name: 'TV',
            },
            {
                mappingKey: 'pool',
                name: 'Pool',
            },
            {
                mappingKey: 'spa',
                name: 'Spa',
            },
            {
                mappingKey: 'parking',
                name: 'On-site Parking',
            },
            {
                mappingKey: 'aircon',
                name: 'Aircon',
            },
            {
                mappingKey: 'bbq',
                name: 'BBQ',
            },
            {
                mappingKey: 'washingMachine',
                name: 'Laundry',
            },
            {
                mappingKey: 'waterSports',
                name: 'Water Sport',
            },
            {
                mappingKey: 'wineCellar',
                name: 'Wine Cellar',
            },
            {
                mappingKey: 'massages',
                name: 'Massages',
            },
            {
                mappingKey: 'rearVisionCamera',
                name: 'Rear Camera',
            },
            {
                mappingKey: 'cruiseControl',
                name: 'Cruise Control',
            },
            {
                mappingKey: 'touchscreenDisplay',
                name: 'Touch Display',
            },
            {
                mappingKey: 'bluetooth',
                name: 'Bluetooth',
            },
            {
                mappingKey: 'heatedSeats',
                name: 'Heated Seats',
            },
            {
                mappingKey: 'androidAuto',
                name: 'Android Auto',
            },
            {
                mappingKey: 'appleCarPlay',
                name: 'Apple CarPlay',
            },
            {
                mappingKey: 'blindSpotAlert',
                name: 'BlindSpot Alert',
            },
            {
                mappingKey: 'navigation',
                name: 'Navigation',
            },
            {
                mappingKey: 'tyrePressureSensor',
                name: 'Tyre Pressure Sensor',
            },
            {
                mappingKey: 'airbags',
                name: 'Airbags',
            },
            {
                mappingKey: 'ac',
                name: 'AC',
            },
            {
                mappingKey: 'keylessEntry',
                name: 'Keyless Entry',
            },
            {
                mappingKey: 'sunroof',
                name: 'Sunroof',
            },
            {
                mappingKey: 'laneAssist',
                name: 'Lane Assist',
            },
            {
                mappingKey: 'autoPark',
                name: 'Auto Park',
            },
            {
                mappingKey: 'powerSeats',
                name: 'Power Seats',
            },
            {
                mappingKey: 'remoteStart',
                name: 'Remote Start',
            },
            {
                mappingKey: 'wirelessPhoneCharger',
                name: 'Wireless Phone Charger',
            },
            {
                mappingKey: 'rearEntertainmentSystem',
                name: 'Rear Entertainment System',
            },
            {
                mappingKey: 'laneAssist',
                name: 'LaneAssist',
            },
            {
                mappingKey: 'adjustableSeats',
                name: 'Adjustable Seats',
            },
            {
                mappingKey: 'helmet',
                name: 'Helmet',
            },
            {
                mappingKey: 'gloves',
                name: 'Gloves',
            },
            {
                mappingKey: 'catering',
                name: 'Catering',
            },
            {
                mappingKey: 'alcohol',
                name: 'Alcohol',
            },
            {
                mappingKey: 'balcony',
                name: 'Balcony',
            },
            {
                mappingKey: 'gym',
                name: 'Gym',
            },
            {
                mappingKey: 'diningOptions',
                name: 'Dining Options',
            },
            {
                mappingKey: 'petFriendly',
                name: 'Pet-Friendly',
            },
            {
                mappingKey: 'showerRoom',
                name: 'Shower Room',
            },
            {
                mappingKey: 'bikeLock',
                name: 'Bike Lock',
            },
            {
                mappingKey: 'personalStorage',
                name: 'Personal Storage',
            },
            {
                mappingKey: 'childCare',
                name: 'Child Care',
            },
        ],
    },
];

const amenitiesIconMapping: any = [
    {
        optionKey: 'icon',
        value: [
            // {
            //     mappingKey: 'bedrooms',
            //     icon: <BedIcon sx={{ color: '#787878' ,fontSize:'30px' }}/>
            // },
            // {
            //     mappingKey: 'beds',
            //     icon:  <BedIcon sx={{ color: '#787878' ,fontSize:'30px' }}/>
            // },
            // {
            //     mappingKey: 'baths',
            //     icon: <ShowerIcon sx={{ color: '#787878' ,fontSize:'30px' }}/>
            // },
            {
                mappingKey: 'kitchen',
                icon: (
                    <KitchenIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'gamesRoom',
                icon: (
                    <SportsEsportsIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },

            {
                mappingKey: 'boardroom',
                icon: (
                    <BusinessCenterIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'firepit',
                icon: (
                    <LocalFireDepartmentIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'wifi',
                icon: <WifiIcon sx={{ color: '#787878', fontSize: '30px' }} />,
            },
            {
                mappingKey: 'tv',
                icon: <TvIcon sx={{ color: '#787878', fontSize: '30px' }} />,
            },
            {
                mappingKey: 'spa',
                icon: (
                    <HotTubIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'pool',
                icon: <PoolIcon sx={{ color: '#787878', fontSize: '30px' }} />,
            },
            {
                mappingKey: 'parking',
                icon: (
                    <LocalParkingIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'aircon',
                icon: (
                    <AcUnitIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'bbq',
                icon: (
                    <OutdoorGrillIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'washingMachine',
                icon: (
                    <LocalLaundryServiceIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'waterSports',
                icon: (
                    <KitesurfingIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'dedicatedWorkspace',
                icon: <DeskIcon sx={{ color: '#787878', fontSize: '30px' }} />,
            },
            {
                mappingKey: 'wineCellar',
                icon: (
                    <WineBarIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'massages',
                icon: <SpaIcon sx={{ color: '#787878', fontSize: '30px' }} />,
            },
            {
                mappingKey: 'rearVisionCamera',
                icon: (
                    <ControlCameraIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'cruiseControl',
                icon: <SpeedIcon sx={{ color: '#787878', fontSize: '30px' }} />,
            },
            {
                mappingKey: 'touchscreenDisplay',
                icon: (
                    <TouchAppIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'bluetooth',
                icon: (
                    <BluetoothIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'heatedSeats',
                icon: (
                    <DeviceThermostatIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'androidAuto',
                icon: (
                    <AndroidIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'appleCarPlay',
                icon: <AppleIcon sx={{ color: '#787878', fontSize: '30px' }} />,
            },
            {
                mappingKey: 'blindSpotAlert',
                icon: (
                    <CrisisAlertIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'navigation',
                icon: (
                    <NavigationIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'tyrePressureSensor',
                icon: (
                    <TireRepairIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'airbags',
                icon: (
                    <AirlineSeatReclineNormalIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'ac',
                icon: (
                    <AcUnitIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'keylessEntry',
                icon: (
                    <KeyOffIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'sunroof',
                icon: (
                    <WbSunnyIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'rearEntertainmentSystem',
                icon: (
                    <LiveTvIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'laneAssist',
                icon: (
                    <AddRoadIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'autoPark',
                icon: (
                    <LocalParkingIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'powerSeats',
                icon: (
                    <AirlineSeatLegroomReducedIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'remoteStart',
                icon: (
                    <SettingsRemoteIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'wirelessPhoneCharger',
                icon: (
                    <EdgesensorHighIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'adjustableSeats',
                icon: (
                    <AirlineSeatLegroomExtraIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'helmet',
                icon: (
                    <SportsMotorsportsIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'gloves',
                icon: (
                    <SportsMmaIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'alcohol',
                icon: <Liquor sx={{ color: '#787878', fontSize: '30px' }} />,
            },
            {
                mappingKey: 'catering',
                icon: (
                    <Restaurant sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'balcony',
                icon: (
                    <BalconyIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'gym',
                icon: (
                    <FitnessCenterIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'diningOptions',
                icon: (
                    <RestaurantIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'petFriendly',
                icon: <PetsIcon sx={{ color: '#787878', fontSize: '30px' }} />,
            },
            {
                mappingKey: 'showerRoom',
                icon: (
                    <ShowerIcon sx={{ color: '#787878', fontSize: '30px' }} />
                ),
            },
            {
                mappingKey: 'bikeLock',
                icon: (
                    <PedalBikeIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
            {
                mappingKey: 'personalStorage',
                icon: <LockIcon sx={{ color: '#787878', fontSize: '30px' }} />,
            },
            {
                mappingKey: 'childCare',
                icon: (
                    <ChildCareIcon
                        sx={{ color: '#787878', fontSize: '30px' }}
                    />
                ),
            },
        ],
    },
];

export function getReadableAmenitiesName(optionKey: any, mappingKey: any) {
    const option = amenitiesNameMapping.find(
        (i: { optionKey: any }) => i.optionKey === optionKey
    );
    return option?.value.find(
        (i: { mappingKey: any }) => i.mappingKey === mappingKey
    )?.name;
}

export function getAmenitiesIcon(optionKey: any, mappingKey: any) {
    const option = amenitiesIconMapping.find(
        (i: { optionKey: any }) => i.optionKey === optionKey
    );
    return option?.value.find(
        (i: { mappingKey: any }) => i.mappingKey === mappingKey
    )?.icon;
}

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, InputLabel, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
    useEditAssetMutation,
    useGetIndividualOwnerAssetsQuery,
} from '../../../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../../../shared/AuthRoles';
import FormMultiAutocomplete from '../../../../shared/FormMultiAutocomplete';
import toast from '../../../../shared/Toast';

const LinkCalendars = ({ existingModule }: { existingModule?: any }) => {
    const { control } = useFormContext();

    const [editAsset] = useEditAssetMutation();
    const loggedInUser = UserRole();
    const dispatch = useDispatch();

    const { data: allAssets } = useGetIndividualOwnerAssetsQuery({
        url: `owners/${loggedInUser?.company}/modules`,
        sort: 'name',
    });

    const filteredAssets = allAssets?.filter(
        (asset: any) => asset.type === 'property'
    );

    const disableOption = (asset: any) => {
        return existingModule
            ? asset.linkedModules.length > 0 ||
                  (asset.mainModule &&
                      asset.mainModule.id !== existingModule.id)
            : asset.linkedModules.length > 0 || asset.mainModule;
    };

    const unlinkFromMain = () => {
        const requestBody = {
            type: existingModule?.mainModule?.type,
            linkedModules: existingModule?.mainModule?.linkedModules.filter(
                (linkedModule: any) => linkedModule.id !== existingModule.id
            ),
        };

        let data = [
            loggedInUser?.company,
            existingModule?.mainModule.id,
            requestBody,
        ];

        try {
            editAsset(data);
            toast.success(`Experience successfully unlinked`);
        } catch (error) {
            toast.error(`Failed to unlink the experience`);
        }
    };

    return (
        <Grid>
            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        paddingBottom: '30px',
                    }}
                >
                    {' '}
                    <Typography
                        component='h4'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            fontWeight: 900,
                        }}
                    >
                        Connect Calendars
                    </Typography>
                    <Tooltip
                        title={
                            <Typography>
                                If you have multiple experiences that share the
                                same space, like one for a single activity and
                                another for the entire venue, you can link their
                                calendars. This ensures that when a reservation
                                is made for one experience, the dates are
                                automatically blocked on the linked experiences
                                to avoid double bookings.
                            </Typography>
                        }
                        placement='right'
                    >
                        <HelpOutlineIcon
                            sx={{
                                color: '#787878',
                                fontSize: '16px',
                            }}
                        />
                    </Tooltip>
                </Grid>

                <Grid container columns={25}>
                    <Grid item xs={25} lg={5}>
                        <InputLabel>Experience</InputLabel>
                    </Grid>
                    <Grid
                        item
                        xs={25}
                        lg={7}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >
                        <FormMultiAutocomplete
                            name='linkedModules'
                            control={control}
                            options={
                                (existingModule
                                    ? filteredAssets?.filter(
                                          (asset: any) =>
                                              asset.id !== existingModule.id
                                      )
                                    : filteredAssets
                                )?.map((asset: any) => ({
                                    value: asset.id,
                                    label: disableOption(asset)
                                        ? `${asset.name} (unavailable)`
                                        : asset.name,
                                })) ?? []
                            }
                            getOptionDisabled={(option) => {
                                const asset = allAssets.find(
                                    (asset: any) => asset.id === option.value
                                );

                                if (!asset) return false;

                                return disableOption(asset);
                            }}
                            disabled={existingModule?.mainModule}
                        />
                        {existingModule?.mainModule && (
                            <Typography
                                color='#D0342C'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                <span>
                                    If you want to link this experience, you
                                    first need to unlink it from '
                                    {existingModule?.mainModule?.name}' (ID:
                                    {existingModule?.mainModule?.id}). Click{' '}
                                    <span
                                        style={{
                                            cursor: 'pointer',
                                            color: '#5cb5b3',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={unlinkFromMain}
                                    >
                                        here
                                    </span>{' '}
                                    to unlink.
                                </span>
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LinkCalendars;

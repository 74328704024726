import {
    Card,
    CardActionArea,
    CardContent,
    Container,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import UserRole from '../../../../shared/AuthRoles';
import { useGetIndividualOwnerQuery } from '../../../../app/Slices/SevenDayApiSlice';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React from 'react';
import ReactGA from 'react-ga4';

export default function OwnerExperiencesPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();
    const role = UserRole();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {
        data: getIndividualCompany,
        isFetching,
        error,
    } = useGetIndividualOwnerQuery(`owners/${role?.company}`);

    const newExperience = () => {
        navigate(`/newExperience`, { state: { name: 'newExperience' } });
        ReactGA.send({
            hitType: 'pageview',
            page: '/newExperience',
            title: 'New Experiences',
        });
    };
    const viewAllExperiences = () => {
        navigate(`/viewExperiences/all`);
        ReactGA.send({
            hitType: 'pageview',
            page: '/viewExperiences/all',
            title: 'All Experiences',
        });
    };

    const viewMyExperiences = () => {
        navigate(`/viewExperiences/all/${getIndividualCompany?.name.replace(/ /g, '').toLowerCase()}`);
        ReactGA.send({
            hitType: 'pageview',
            page: `/viewExperiences/all/${getIndividualCompany?.name.replace(/ /g, '').toLowerCase()}`,
            title: 'View Owner Experiences',
        });
    };
    useEffect(() => {
        if (role?.role === 'owner_admin') {
            navigate(`/experiences`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: `/experiences`,
                title: 'Experiences',
            });
        } else {
            navigate(`/viewExperiences/all`, { replace: true });
            ReactGA.send({
                hitType: 'pageview',
                page: `/viewExperiences/all`,
                title: 'All Experiences',
            });
        }
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    {/* <LocationOnIcon
                        // fontSize='large'
                        sx={{
                            color: '#5cb5b3',
                            // height: '100%'
                            paddingRight: '5px',
                            fontSize: '32px',
                        }}
                    /> */}
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            textDecoration: 'underline',
                            textDecorationColor: '#5cb5b3',
                            textUnderlineOffset: '8px',
                        }}
                    >
                        Experiences
                    </Typography>
                </Grid>
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: isMobile ? '65%' : '78%',
                        paddingTop: '30px',
                        justifyContent: 'left',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                    }}
                >
                    <Card
                        onClick={() => newExperience()}
                        sx={{
                            width: isMobile ? 'auto' : 400,
                            minWidth: 280,
                            maxWidth: 500,
                            minHeight: isMobile ? 150 : isIpad ? 150 : 150,
                            maxHeight: isMobile ? 180 : isIpad ? 180 : 180,
                            marginRight: isMobile ? '0px' : '10px',
                            marginBottom: isMobile ? '10px' : '0px',
                            boxShadow: 1,
                            background: 'none',
                            backgroundColor: 'transparent',
                            color: 'none',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                            '&:focus': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <CardActionArea
                            // disableRipple
                            // disableTouchRipple
                            sx={{
                                background: 'none',
                                backgroundColor: 'transparent',
                                color: '#fff',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                                '&:focus': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        '&:hover': {
                                            color: '#5cb5b3',
                                        },
                                    }}
                                >
                                    <AddCircleIcon />
                                </Typography>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    Create New Experience
                                </Typography>
                                <Typography
                                    variant='body2'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    List your property, boat, vehicle, or
                                    activity to start receiving reservations!
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <Card
                        onClick={() => viewMyExperiences()}
                        sx={{
                            width: isMobile ? 'auto' : 400,
                            minWidth: 280,
                            maxWidth: 500,
                            minHeight: isMobile ? 150 : isIpad ? 150 : 150,
                            maxHeight: isMobile ? 180 : isIpad ? 180 : 180,
                            marginRight: isMobile ? '0px' : '10px',
                            boxShadow: 1,
                            background: 'none',
                            backgroundColor: 'transparent',
                            color: 'none',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                            '&:focus': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <CardActionArea
                            // disableRipple
                            // disableTouchRipple
                            sx={{
                                background: 'none',
                                backgroundColor: 'transparent',
                                color: '#fff',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                                '&:focus': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        '&:hover': {
                                            color: '#5cb5b3',
                                        },
                                    }}
                                >
                                    <EditIcon />
                                </Typography>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    Manage {getIndividualCompany?.name}{' '}
                                    Experiences
                                </Typography>
                                <Typography
                                    variant='body2'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    View and Edit only your listed experiences.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <Card
                        onClick={() => viewAllExperiences()}
                        sx={{
                            width: isMobile ? 'auto' : 400,
                            marginTop: isMobile ? '10px' : '',
                            minWidth: 280,
                            maxWidth: 500,
                            minHeight: isMobile ? 150 : isIpad ? 150 : 150,
                            maxHeight: isMobile ? 180 : isIpad ? 180 : 180,
                            boxShadow: 1,
                            background: 'none',
                            backgroundColor: 'transparent',
                            color: 'none',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                            '&:focus': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <CardActionArea
                            // disableRipple
                            // disableTouchRipple
                            sx={{
                                background: 'none',
                                backgroundColor: 'transparent',
                                color: '#fff',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                                '&:focus': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant='h5'
                                    component='div'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        '&:hover': {
                                            color: '#5cb5b3',
                                        },
                                    }}
                                >
                                    <VisibilityIcon />
                                </Typography>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    View All Experiences
                                </Typography>
                                <Typography
                                    variant='body2'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    View all available experiences listed on our
                                    7DAY App.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

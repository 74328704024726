import { KeyboardArrowDown } from '@mui/icons-material';
import {
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    SelectProps,
} from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface FormSelectProps extends SelectProps {
    name: string;
    options: Array<any>;
    control: Control<any>;
}

/**
 * For use within RHF FormProvider
 */
const FormSelect = ({ name, control, options, ...props }: FormSelectProps) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({
                field: { onChange, value, ref },
                fieldState: { error },
            }) => (
                <FormControl fullWidth>
                    <Select
                        {...props}
                        inputRef={ref}
                        size='small'
                        onChange={onChange}
                        value={value}
                        fullWidth
                        error={!!error}
                        IconComponent={KeyboardArrowDown}
                    >
                        {options.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {!!error && (
                        <FormHelperText error>{error.message}</FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};

export default FormSelect;

import { CreditCard } from '@mui/icons-material';
import {
    Box,
    Container,
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserRole from '../../../shared/AuthRoles';
import { BillingAccountForm } from '../../components/Billing/AccountDetails/BillingAccountForm';
import BillingReport from '../../components/Billing/Reports/BillingReport';
import ReactGA from 'react-ga4';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
    return (
        <div hidden={value !== index}>
            {value === index && <Box pt={3}>{children}</Box>}
        </div>
    );
};

const Billing: React.FC = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');

    const loggedInUser = UserRole();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (
            loggedInUser.role !== 'admin' &&
            loggedInUser.role !== 'guest_admin'
        ) {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'billingAccountPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    const searchParams = new URLSearchParams(location.search);
    const initialTab = parseInt(searchParams.get('page') || '0', 10);

    const [value, setValue] = useState<number>(initialTab);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        navigate(`/billing?page=${newValue}`, { replace: true });
    };

    if (loggedInUser?.role === 'admin') {
        return (
            <Container fixed maxWidth='xl' sx={{ pt: '125px' }}>
                <Box width='100%'>
                    <Stack
                        direction='row'
                        alignItems='center'
                        gap={1}
                        pb='30px'
                    >
                        <CreditCard color='primary' />
                        <Typography variant='h5' color='#787878' mt={2}>
                            BILLING REPORTS
                        </Typography>
                    </Stack>
                    <BillingReport />
                </Box>
            </Container>
        );
    }

    return (
        <Container fixed maxWidth='xl' sx={{ pt: '125px' }}>
            <Box width='100%'>
                <Stack direction='row' alignItems='center' gap={1} pb='30px'>
                    <CreditCard color='primary' />
                    <Typography variant='h5' color='#787878'>
                        BILLING
                    </Typography>
                </Stack>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant={isMobile ? 'fullWidth' : 'standard'}
                >
                    <Tab label='Account Details' />
                    <Tab label='Bills' />
                </Tabs>
                {loggedInUser?.role !== 'admin' && (
                    <TabPanel value={value} index={0}>
                        <BillingAccountForm />
                    </TabPanel>
                )}
                <TabPanel value={value} index={1}>
                    <BillingReport />
                </TabPanel>
            </Box>
        </Container>
    );
};

export default Billing;

import React from 'react';

import {
    Box,
    Button,
    ButtonGroup,
    FormHelperText,
    Grid,
    InputLabel,
    List,
    ListItem,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormAutocomplete from '../../../shared/FormAutocomplete';
import {
    MaxHoursOfHire,
    MaxHoursOfHireCommercial,
    MaxHoursOfHireProperty,
    MinHoursOfHire,
    MinHoursOfHireCommercial,
    MinHoursOfHirePropertyCorp,
    MinHoursOfHirePropertyPersonal,
} from './HoursOfHire';
import FormTextField from '../../../shared/FormTextField';
import FormCheckbox from '../../../shared/FormCheckbox';
import { HelpOutline } from '@mui/icons-material';
import FormSelect from '../../../shared/FormSelect';
import { driversAgeArray } from './Boat/NumberArray';
import { TimeArray } from './Property/TimeArray';

interface HiringDetailsProps {
    type: string;
    visitType: string;
}

const HiringDetails = ({ type, visitType }: HiringDetailsProps) => {
    const {
        control,
        setValue,
        formState: { errors },
    } = useFormContext();

    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');

    const rules = useWatch({ control, name: 'details.rules' });
    const checkinDetails = useWatch({
        control,
        name: 'checkinDetails',
        defaultValue: '',
    });

    const days = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
    ];

    let minHireArray =
        type === 'property' && visitType === 'business'
            ? MinHoursOfHirePropertyCorp
            : type === 'property' && visitType === 'personal'
            ? MinHoursOfHirePropertyPersonal
            : type === 'commercial'
            ? MinHoursOfHireCommercial
            : MinHoursOfHire;
    let maxHireArray =
        type === 'property'
            ? MaxHoursOfHireProperty
            : type === 'commercial'
            ? MaxHoursOfHireCommercial
            : MaxHoursOfHire;

    return (
        <Box>
            <Typography
                component='h4'
                color='textPrimary'
                fontWeight='bold'
                pb='30px'
            >
                Hiring Details
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={5}>
                <Grid container item xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Min Hire Period</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='minHireHours'
                            control={control}
                            disabled={visitType === ''}
                            placeholder={
                                visitType === '' ? 'Select Visit Type' : ''
                            }
                            options={minHireArray}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Max Hire Hours</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='maxHireHours'
                            control={control}
                            disabled={visitType === ''}
                            placeholder={
                                visitType === '' ? 'Select Visit Type' : ''
                            }
                            options={maxHireArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>
                            {type === 'vehicle'
                                ? 'Pickup From'
                                : type === 'commercial'
                                ? 'Access From'
                                : 'Checkin From'}
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='checkinTime'
                            control={control}
                            options={TimeArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>
                            {type === 'vehicle'
                                ? 'Dropoff Before'
                                : type === 'commercial'
                                ? 'Access To'
                                : 'Checkout Before'}
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='checkoutTime'
                            control={control}
                            options={TimeArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel>
                            {type === 'vehicle'
                                ? 'Pickup Details'
                                : type === 'commercial'
                                ? 'Access Details'
                                : 'Checkin Method'}
                        </InputLabel>
                        {checkinDetails?.length < 500 ? (
                            <Typography color='primary' fontSize='12px'>
                                Characters left: {500 - checkinDetails?.length}
                            </Typography>
                        ) : checkinDetails?.length > 499 ? (
                            <Typography color='error' fontSize='12px'>
                                Max characters reached:{' '}
                                {checkinDetails?.length - 500}
                            </Typography>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormTextField
                            name='checkinDetails'
                            control={control}
                            placeholder='Add any important details for getting access i.e Door code: 0000. This information will be displayed 24 hours before check-in and hidden after completed.'
                            inputProps={{ maxLength: 500 }}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>
                            Cleaning Buffer (hrs){' '}
                            <Tooltip
                                title={
                                    <Typography>
                                        <List>
                                            <ListItem>
                                                Amount of hours to clean the
                                                property/boat before another
                                                booking can be made
                                            </ListItem>
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutline sx={{ fontSize: '16px' }} />
                            </Tooltip>
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormTextField
                            name='bufferHours'
                            control={control}
                            type='number'
                            placeholder='3'
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs />
                <Grid container item xs={12} pb={2}>
                    <InputLabel required>
                        Availability{' '}
                        <Tooltip
                            title={
                                <Typography>
                                    <List>
                                        <ListItem>
                                            Select the days in which the
                                            property is available to book.
                                        </ListItem>
                                        <ListItem>
                                            Unselected days will appear grey in
                                            the calendar and the guest will not
                                            be able to select these days
                                        </ListItem>
                                    </List>
                                </Typography>
                            }
                            placement='right'
                        >
                            <HelpOutline sx={{ fontSize: '16px' }} />
                        </Tooltip>
                    </InputLabel>
                    <ButtonGroup fullWidth>
                        {days.map((day: any) => (
                            <Controller
                                key={day}
                                name={day}
                                control={control}
                                render={({ field: { value, ref } }) => (
                                    <Button
                                        variant={
                                            value ? 'contained' : 'outlined'
                                        }
                                        onClick={() => setValue(day, !value)}
                                        disableElevation
                                        disableRipple
                                        ref={ref}
                                    >
                                        {isMobile || isIpad
                                            ? day.charAt(0)
                                            : day}
                                    </Button>
                                )}
                            />
                        ))}
                    </ButtonGroup>
                    {errors.monday && (
                        <FormHelperText error>
                            {errors.monday.message as string}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid container item xs={12} lg={6} pb={2}>
                    <Grid item xs={12} display='flex' gap={1}>
                        <FormCheckbox
                            name='publicHolidays'
                            control={control}
                            sx={{ p: 0 }}
                        />
                        <InputLabel>Available on Public Holidays</InputLabel>
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={6} pb={2}>
                    <Grid item xs={12} display='flex' gap={1}>
                        <FormCheckbox
                            name='instantBook'
                            control={control}
                            sx={{ p: 0 }}
                        />
                        <InputLabel>Use Instant Book</InputLabel>
                        <Box>
                            <Tooltip
                                title={
                                    <Typography>
                                        <List>
                                            <ListItem>
                                                Turn on to automatically accept
                                                reservations. Turn off to
                                                manually accept or decline
                                                reservation requests.
                                            </ListItem>
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutline sx={{ fontSize: '16px' }} />
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>

                {type === 'vehicle' && (
                    <>
                        <Grid container item xs={12} lg={6}>
                            <Grid item xs={12} lg={5}>
                                <InputLabel required>
                                    Min Licence Required
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <FormSelect
                                    name='details.minimumLicenceRequired'
                                    control={control}
                                    options={[
                                        { value: 'full', label: 'Full' },
                                        { value: 'p2', label: 'P2' },
                                        { value: 'p1', label: 'P1' },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={6}>
                            <Grid item xs={12} lg={5}>
                                <InputLabel>
                                    Min Driver's Age (optional)
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <FormAutocomplete
                                    name='details.minimumDriversAge'
                                    control={control}
                                    options={[...driversAgeArray]}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={6}>
                            <Grid item xs={12} lg={5}>
                                <InputLabel>Pets Allowed</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <FormAutocomplete
                                    name='details.petsAllowed'
                                    control={control}
                                    options={[
                                        { value: false, label: 'No' },
                                        { value: true, label: 'Yes' },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={6}>
                            <Grid item xs={12} lg={5}>
                                <InputLabel>Smoking Allowed</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <FormSelect
                                    name='details.smokingAllowed'
                                    control={control}
                                    options={[
                                        { value: false, label: 'No' },
                                        { value: true, label: 'Yes' },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12} lg={2.43}>
                                <InputLabel>Additional Hiring Rules</InputLabel>
                                {rules?.length < 500 ? (
                                    <Typography color='primary' fontSize='12px'>
                                        Characters left: {500 - rules?.length}{' '}
                                    </Typography>
                                ) : rules?.length > 499 ? (
                                    <Typography color='error' fontSize='12px'>
                                        Max characters reached:{' '}
                                        {rules?.length - 500}
                                    </Typography>
                                ) : null}
                            </Grid>
                            <Grid item xs>
                                <FormTextField
                                    name='details.rules'
                                    control={control}
                                    placeholder={
                                        'You can add any additional hiring rules.'
                                    }
                                    inputProps={{ maxLength: 500 }}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default HiringDetails;

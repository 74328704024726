import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    useGetIndividualAssetQuery,
    useGetIndividualReservationQuery,
} from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import ReservationRequestSubmittedContent from '../../components/Reservations/ReservationRequestSubmitted/ReservationRequestSubmittedContent';
import React, { useEffect, useState } from 'react';
import UserRole from '../../../shared/AuthRoles';
import ReactGA from 'react-ga4';
import RecurringReservationRequestSubmittedContent from '../../components/Reservations/ReservationRequestSubmitted/RecurringReservationRequestSubmittedContent';

export default function ReservationConfirmationPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const location = useLocation();
    const loggedInUser = UserRole();
    const urlRef = window.location.href.split('/')[5];
    const navigate = useNavigate();
    const [isRecurringReservation, setIsRecurringReservation] = useState(false);

    // calls for individual
    const { data: getReservation, isFetching } =
        useGetIndividualReservationQuery(`bookings/${urlRef}`);

    const {
        data: getIndividualAsset,
        isFetching: fetchingIndividualReservation,
    } = useGetIndividualAssetQuery(`modules/${getReservation?.module.id}`);

    //calls for recurring
    const {
        data: getRecurringReservation,
        isFetching: fetchingRecurringReservation,
    } = useGetIndividualReservationQuery(
        `recurring-bookings/${location?.state?.id}`
    );

    const getModuleId = getRecurringReservation?.map((i: any) => i?.module?.id);

    const { data: getAssetDetails, isFetching: fetchingModuleInfo } =
        useGetIndividualAssetQuery(`modules/${getModuleId?.[0]}`);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (location?.state?.page !== undefined) {
            if (location?.state?.page === 'recurringReservation') {
                setIsRecurringReservation(true);
            } else {
                setIsRecurringReservation(false);
            }
        }
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // overflow: 'hidden',
                // overflowY: 'hidden',
            }}
        >
            {fetchingIndividualReservation ||
            fetchingRecurringReservation ||
            fetchingModuleInfo ? (
                <Grid
                    sx={{
                        height: '100%',
                        width: '100%',
                        // backgroundColor: 'red',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '2%' : '5%',
                            // backgroundColor: 'yellow',
                            justifyContent: 'left',
                            display: 'flex',
                        }}
                    >
                        <Typography
                            component='h1'
                            variant='h5'
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                                fontWeight: '550',
                                textDecoration: 'underline',
                                textDecorationColor: '#5cb5b3',
                                textUnderlineOffset: '8px',
                            }}
                        >
                            {loggedInUser?.role.includes('owner') ||
                            !getIndividualAsset?.requiresBookingApproval
                                ? `Reservation Submitted`
                                : `Request Submitted`}
                        </Typography>
                    </Grid>

                    <Grid
                        sx={{
                            width: '100%',
                            // backgroundColor: 'red',
                            paddingBottom: isMobile ? '20px' : '50px',
                        }}
                    >
                        {isRecurringReservation ? (
                            <RecurringReservationRequestSubmittedContent
                                reservationDetails={getRecurringReservation}
                                experience={getAssetDetails}
                            />
                        ) : (
                            <ReservationRequestSubmittedContent
                                getReservation={getReservation}
                                experience={getIndividualAsset}
                            />
                        )}
                    </Grid>
                </Grid>
            )}
        </Container>
    );
}

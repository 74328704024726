import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function CreatePolicy() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        paddingBottom: '20px',
                        fontSize: '14px',
                    }}
                >
                    Reservation Policies allow you to advise additional
                    information about the terms of reserving an experience.
                    Creating and applying this to an experience will help notify
                    guests what your terms are and in return protect you.
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    Examples of policies you would apply:
                    <ul>
                        <li>Cancellation Policy</li>
                        <li>Terms of Service </li>
                        <li>Terms and Conditions </li>
                    </ul>
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To create a new Policy:
                </Typography>
                {loggedInUser?.role.includes('admin') ? (
                    <>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                                paddingBottom: '5px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>
                                    From the top right corner of the app, click
                                    on the cog wheel icon
                                </li>
                                <li>Select 'Settings'</li>
                                <li>Select 'Your Policies'</li>
                                <li>Click 'Add'</li>
                                <li>Complete all mandatory fields with a *</li>
                                <li>
                                    Select if the Policy is available for all
                                    experience types or specfic types only.
                                    Note: this is not where you apply the policy
                                    to the experience
                                </li>
                                <li>Click 'Save'</li>
                            </ol>
                            Once saved, this policy will now be available for
                            you to apply to the experience.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To apply the policy to the experience:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>
                                    From the top left corner of the app, click
                                    on 'Experiences'
                                </li>
                                <li>
                                    Select the experience you wish to apply the
                                    policy too
                                </li>
                                <li>Select the 'edit' icon </li>
                                <li>Scroll down to the 'Policies' section</li>
                                <li>Tick 'This experience has policies'</li>
                                <li>Select a policy from the list</li>
                                <li>
                                    Tick whether you want to collect the users
                                    consent at the time they make the
                                    reservation
                                </li>
                                <li>
                                    Click 'Update' on the experience to save
                                    changes
                                </li>
                            </ol>
                            Once saved, this policy will now be applied to the
                            experience.
                        </Typography>
                    </>
                ) : (
                    <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                        <Grid>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    paddingBottom: '20px',
                                    fontSize: '14px',
                                }}
                            >
                                Only admins can add a edit a policy. Please
                                contact a person that is an admin in your
                                organisation to add, edit or delete a policy.
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

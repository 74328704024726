import { Stack, Typography } from '@mui/material';
import React from 'react';
import ExperienceChargesForm from './ExperienceChargesForm';

interface Props {
    experienceType: string
    isEditing: boolean
}

const ExperienceCharges = ({ experienceType, isEditing }: Props) => {
    return (
        <Stack spacing='20px'>
            <Typography component='h4' color='textPrimary' fontWeight='bold'>
                Pricing
            </Typography>
            <Typography color='textPrimary'>
                You can set your experience pricing here. The price you
                set applies to all future reservations.
            </Typography>
            <ExperienceChargesForm 
                experienceType={experienceType} 
                isEditing={isEditing} 
            />
        </Stack>
    );
};

export default ExperienceCharges;

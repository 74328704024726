import { Grid, Popover, Typography, useMediaQuery } from '@mui/material';
import moment from 'moment';
import React, { forwardRef, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetDates,
    selectEndDate,
    selectStartDate,
    setEndDate,
    setStartDate,
} from '../../app/Slices/DateFilterSlice';
import { CustomButton } from '../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../layout/styledcomponents/CustomTextField';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateFilter = () => {
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const isMobile = useMediaQuery('(max-width: 900px)');
    const firstTextFieldRef = useRef(null);
    const secondTextFieldRef = useRef(null);

    const [openCalendar, setOpenCalendar] = useState(false);
    const [anchorElCalendar, setAnchorElCalendar] =
        React.useState<null | HTMLElement>(null);
    const dispatch = useDispatch();

    const handleCalendar = (event: React.MouseEvent<HTMLElement>) => {
        setOpenCalendar(!openCalendar);
        setAnchorElCalendar(firstTextFieldRef.current);
    };

    const handleCalendarClose = () => {
        setOpenCalendar(!openCalendar);
        setAnchorElCalendar(null);
    };

    const handleDateChange = (date: any) => {
        dispatch(setStartDate(date.range1.startDate));

        dispatch(setEndDate(date.range1.endDate));
    };

    return (
        <Grid sx={{ display: 'flex', width: 'auto' }}>
            {isMobile ? null : (
                <Grid
                    sx={{
                        borderLeft: ' 1px solid #E0E0E0',
                        height: 'auto',
                        paddingRight: isMobile ? '0px' : '20px',
                    }}
                />
            )}
            <IndividualDate
                label='Check in'
                date={startDate}
                handleClick={handleCalendar}
                ref={firstTextFieldRef}
            />
            {isMobile ? null : (
                <Grid
                    sx={{
                        borderLeft: ' 1px solid #E0E0E0',
                        height: 'auto',
                        paddingRight: '20px',
                    }}
                />
            )}

            <IndividualDate
                label='Check out'
                date={endDate}
                handleClick={handleCalendar}
                ref={secondTextFieldRef}
            />
            {isMobile ? null : (
                <Grid
                    sx={{
                        borderLeft: ' 1px solid #E0E0E0',
                        height: 'auto',
                        // paddingRight: '10px',
                    }}
                />
            )}

            <Popover
                open={openCalendar}
                anchorEl={anchorElCalendar}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleCalendarClose}
                sx={{ display: 'flex' }}
            >
                <DateRange
                    editableDateInputs={true}
                    onChange={handleDateChange}
                    moveRangeOnFirstSelection={false}
                    ranges={[
                        {
                            startDate: startDate ? startDate : new Date(),
                            endDate: endDate ? endDate : new Date(),
                        },
                    ]}
                    rangeColors={['#5cb5b3']}
                    months={isMobile ? 1 : 2}
                    direction='horizontal'
                    showMonthAndYearPickers={false}
                    minDate={new Date()}
                    color='red'
                    showPreview={true}
                    dragSelectionEnabled={true}
                    showDateDisplay={false}
                    weekStartsOn={1}
                />
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                    }}
                >
                    <CustomButton
                        onClick={() => {
                            dispatch(resetDates());
                        }}
                        color='gray'
                        sx={{
                            fontSize: '12px',
                            '&:hover': {
                                cursor: 'pointer',
                                backgroundColor: '#f6f8f9',
                            },
                        }}
                    >
                        Clear Dates
                    </CustomButton>
                </Grid>
            </Popover>
        </Grid>
    );
};

interface IndividualDateProps {
    label: string;
    date?: Date | undefined;
    handleClick: any;
}

const IndividualDate = forwardRef<any, IndividualDateProps>(
    ({ label, date, handleClick }, ref) => {
        const isMobile = useMediaQuery('(max-width: 900px)');

        return (
            <CustomTextField
                color='neutral'
                variant='standard'
                label={
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontWeight: 'bold',
                        }}
                    >
                        {label}
                    </Typography>
                }
                value={date ? moment(date).format('DD/MM/YYYY') : 'Add dates'}
                onClick={(event: any) => handleClick(event)}
                ref={ref}
                sx={{
                    border: 0,
                    fontFamily: 'Open Sans',
                    width: isMobile ? '50%' : '160px',

                    '& .MuiInputBase-input': {
                        color: '#787878',
                        fontSize: isMobile ? '10px' : '12px',
                        fontFamily: 'Open Sans',
                    },
                }}
                InputProps={{
                    disableUnderline: true,
                    // endAdornment: (
                    //     <InputAdornment position='end'>
                    //         <IconButton
                    //             disableRipple
                    //             onClick={(event) => handleClick(event)}
                    //         >
                    //             <DateRangeIcon
                    //             // style={{ color: 'rgba(92, 181, 179, 0.8)' }}
                    //             />
                    //         </IconButton>
                    //     </InputAdornment>
                    // ),
                }}
            />
        );
    }
);

export default DateFilter;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface ReservationFilters {
    startDate?: string;
    moduleType?: string[];
    status?: string[];
    guest?: string[];
    visitType?: string;
    bookerType?: string;
}

const initialState: { value: ReservationFilters } = {
    value: {
        startDate: '',
        moduleType: [],
        status: ['pending', 'approved'],
        guest: [],
        visitType: '',
        bookerType: '',
    },
};

export const slice = createSlice({
    name: 'reservationFilters',
    initialState,
    reducers: {
        setReservationFilters: (state, action: PayloadAction<ReservationFilters>) => {
            state.value = action.payload;
        },
        resetReservationFilters: (state) => {
            state.value = initialState.value;
        },
    },
});

export const { setReservationFilters, resetReservationFilters } = slice.actions;
export const selectReservationFilters = (state: RootState) =>
    state.reservationFilters.value;
export const ReservationFiltersReducer = slice.reducer;

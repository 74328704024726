import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import React from 'react';
import UserRole from '../../../shared/AuthRoles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { CreditCard } from '@mui/icons-material';

const loggedInUser = UserRole();

const reportsNameMapping: any = [
    {
        optionKey: 'type',
        value: [
            {
                mappingKey: 'new-bookings-undefined',
                name: 'New Reservations',
                description: 'Displays new reservations submitted this week',
            },
            {
                mappingKey: 'guests-undefined',
                name: 'No. Guest Companies',
                description:
                    'Displays total number of Guest Companies with user count',
            },
            {
                mappingKey: 'topGuests',
                name: 'Top Guests',
                description:
                    'Displays top guests by the number of completed reservations',
            },
            {
                mappingKey: 'topBookers',
                name: 'Top Bookers',
                description:
                    'Displays top staff by the number of completed reservations based on the reservation from and to date',
            },

            {
                mappingKey: 'topExperiences',
                name: 'Top Experiences',
                description:
                    'Displays top experiences by the number of completed reservations based on the reservation from and to date',
            },
            {
                mappingKey: 'guestDetails',
                name: 'Guest Details',
                description:
                    'Displays companies who have reserved an experience with you.',
            },
            {
                mappingKey: 'guests',
                name: 'Users',
                description:
                    'Displays user of selected company who have reserved an experience with you.',
            },
            {
                mappingKey: 'bookerDetails',
                name: 'Booker Details',
                description:
                    'Displays staff who have reserved an experience on 7DAY',
            },
            {
                mappingKey: 'cancellationReport',
                name: 'Cancellation Report',
                description: `Displays all cancelled reservations ${
                    loggedInUser?.role.includes('owner')
                        ? 'for guest and on behalf of owner (does not include blocked days)'
                        : ''
                }`,
            },
            {
                mappingKey: 'declinedReport',
                name: 'Declined Report',
                description: `Displays all declined reservations with reasons`,
            },
            {
                mappingKey: 'reservationsReport',
                name: 'Reservations Report',
                description:
                    'A customisable report based on all reservations. Date is based on the reservation from and to',
            },
            {
                mappingKey: 'monthlyReport',
                name: 'Monthly Report',
                description:
                    'Displays completed reservations for the previous or current month based on the reservation end date',
            },
            {
                mappingKey: 'quarterlyReport',
                name: 'Quarterly Report',
                description:
                    'Displays completed reservations for the previous or current quarter based on the reservation end date',
            },
            {
                mappingKey: 'ytdReport',
                name: 'YTD Report',
                description:
                    'Displays completed reservations between January to today',
            },
            {
                mappingKey: 'fyReport',
                name: 'FY Report',
                description:
                    'Displays completed reservations in the current financial year',
            },
            {
                mappingKey: 'guest-pending-bookings-undefined',
                name: 'All Pending Reservations',
                description: 'Displays all pending reservations',
            },
            {
                mappingKey: 'scheduled-current-week',
                name: 'Scheduled This Week',
                description:
                    'Displays reservations that are scheduled this week (approved by the owner).',
            },
            {
                mappingKey: 'scheduled-next-week',
                name: 'Scheduled Next Week',
                description:
                    'Displays reservations that are scheduled next week (approved by the owner).',
            },
            {
                mappingKey: 'scheduled-today',
                name: 'Scheduled Today',
                description:
                    'Displays reservations that are scheduled today (approved by the owner).',
            },
            {
                mappingKey: 'scheduled-tomorrow',
                name: 'Scheduled Tomorrow',
                description:
                    'Displays reservations that are scheduled tomorrow (approved by the owner).',
            },
            {
                mappingKey: 'scheduled-current-month',
                name: 'Scheduled This Month',
                description:
                    'Displays reservations that are scheduled this month (approved/completed).',
            },
            {
                mappingKey: 'scheduled-all',
                name: 'All Scheduled Reservations',
                description:
                    'Displays all reservations that have been scheduled (approved/completed).',
            },
            {
                mappingKey: 'completed-current-month',
                name: 'Completed This Month',
                description:
                    'Displays reservations that have been completed this month.',
            },
            {
                mappingKey: 'completed-current-year',
                name: 'Completed This Year',
                description:
                    'Displays reservations that have been completed this year.',
            },
            {
                mappingKey: 'user-user-completed-bookings',
                name: 'Your Completed Reservations',
                description: 'Displays your past completed reservations.',
            },
            {
                mappingKey: 'user-user-approved-bookings',
                name: 'Your Upcoming Reservations',
                description:
                    'Displays your upcoming reservations that have been approved.',
            },
            {
                mappingKey: 'user-user-pending-bookings',
                name: 'Your Pending Reservations',
                description:
                    'Displays your pending reservations that have not been approved.',
            },
            {
                mappingKey: 'guestInvoices',
                name: 'Guest Invoices',
                description:
                    'Displays invoices associated with guest reservations. ',
            },
            {
                mappingKey: 'hostPayments',
                name: 'Host Payments',
                description: 'Displays all host payments',
            },
        ],
    },
];

const reportIconMapping: any = [
    {
        optionKey: 'icon',
        value: [
            {
                mappingKey: 'new-bookings-undefined',
                icon: (
                    <CalendarMonthIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'guests-undefined',
                icon: (
                    <PeopleAltIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'topGuests',
                icon: (
                    <PeopleAltIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'topBookers',
                icon: (
                    <PeopleAltIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'topExperiences',
                icon: (
                    <LocationOnIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },

            {
                mappingKey: 'guestDetails',
                icon: (
                    <PeopleAltIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'guests',
                icon: (
                    <PeopleAltIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'bookerDetails',
                icon: (
                    <PeopleAltIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'cancellationReport',
                icon: (
                    <CalendarMonthIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'declinedReport',
                icon: (
                    <CalendarMonthIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'reservationsReport',
                icon: (
                    <CalendarMonthIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'monthlyReport',
                icon: (
                    <StackedLineChartIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'quarterlyReport',
                icon: (
                    <StackedLineChartIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'ytdReport',
                icon: (
                    <StackedLineChartIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'fyReport',
                icon: (
                    <StackedLineChartIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
            {
                mappingKey: 'guestInvoices',
                icon: (
                    <CreditCard sx={{ color: '#5cb5b3', fontSize: '60px' }} />
                ),
            },
            {
                mappingKey: 'hostPayments',
                icon: (
                    <StackedLineChartIcon
                        sx={{ color: '#5cb5b3', fontSize: '60px' }}
                    />
                ),
            },
        ],
    },
];

const reportRolesMapping: { [key: string]: string[] } = {
    hostPayments: ['admin'],
};

export function getReadableReportName(optionKey: any, mappingKey: any) {
    const option = reportsNameMapping.find(
        (i: { optionKey: any }) => i.optionKey === optionKey
    );
    return option?.value.find(
        (i: { mappingKey: any }) => i.mappingKey === mappingKey
    )?.name;
}

export function getReadableReportDescription(optionKey: any, mappingKey: any) {
    const option = reportsNameMapping.find(
        (i: { optionKey: any }) => i.optionKey === optionKey
    );
    return option?.value.find(
        (i: { mappingKey: any }) => i.mappingKey === mappingKey
    )?.description;
}

export function getReportIcon(optionKey: any, mappingKey: any) {
    const option = reportIconMapping.find(
        (i: { optionKey: any }) => i.optionKey === optionKey
    );
    return option?.value.find(
        (i: { mappingKey: any }) => i.mappingKey === mappingKey
    )?.icon;
}

export function canAccessReport(reportKey: string, role: string): boolean {
    return reportKey in reportRolesMapping
        ? reportRolesMapping[reportKey].includes(role)
        : true;
}

import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    useEditXeroSettingsMutation,
    useGetXeroAccountsQuery,
    useGetXeroSettingsQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import React, { useEffect, useState } from 'react';
import { Grid, InputLabel, Tooltip, Typography } from '@mui/material';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FormAutocomplete from '../../../shared/FormAutocomplete';
import { useDispatch } from 'react-redux';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import toast from '../../../shared/Toast';
import { handleHttpError } from '../../../../shared/utils';

export const XeroSettingsForm = ({ connected }: { connected: boolean }) => {
    const [editXeroSettings] = useEditXeroSettingsMutation();
    const { data: settings, isLoading } = useGetXeroSettingsQuery();
    const { data: accounts } = useGetXeroAccountsQuery(undefined, {
        skip: !connected,
    });
    const [accountOptions, setAccountOptions] = useState<
        { value: string; label: string }[]
    >([]);
    const dispatch = useDispatch();

    const schema = z.object({
        billsAccountCode: z.string().min(1, {
            message: 'Required',
        }),
        invoicesAccountCode: z.string().min(1, {
            message: 'Required',
        }),
    });
    type schemaType = z.infer<typeof schema>;
    const {
        reset,
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm<schemaType>({
        resolver: zodResolver(schema),
    });

    useEffect(() => {
        if (settings && accounts) {
            reset(settings);
        }
    }, [settings, accounts]);

    useEffect(() => {
        if (accounts) {
            setAccountOptions(
                accounts.map((account) => ({
                    value: account.code,
                    label: `${account.code} - ${account.name}`,
                }))
            );
        }
    }, [accounts]);

    const onSubmit = async (xeroSettings: schemaType) => {
        editXeroSettings(xeroSettings).then((data: any) => {
            if (data) {
                if (data?.error) {
                    const errorMessage = handleHttpError(data?.error);
                    toast.error(errorMessage);
                } else {
                    toast.success('Xero accounts are saved.');
                }
            } else {
                toast.error(`Failed to save the accounts.`);
            }
        });
    };

    return isLoading ? (
        <LoadingComponent color='green' />
    ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
                <Grid item>
                    <Typography
                        color='textPrimary'
                        sx={{
                            fontWeight: 900,
                        }}
                    >
                        Xero Accounts
                    </Typography>
                </Grid>
                <Grid container item spacing={1}>
                    <Grid container item xs={12} lg={2.5} columnSpacing={1}>
                        <Grid item>
                            <InputLabel required>Host Bills Account</InputLabel>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                title={
                                    <Typography>
                                        Xero Account associated with host bill
                                        line items.
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.text.primary,
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4.5}>
                        <FormAutocomplete
                            name='billsAccountCode'
                            control={control}
                            options={accountOptions}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={1}>
                    <Grid container item xs={12} lg={2.5} columnSpacing={1}>
                        <Grid item>
                            <InputLabel required>
                                Guest Invoices Account
                            </InputLabel>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                title={
                                    <Typography>
                                        Xero Account associated with guest
                                        invoice line items.
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.text.primary,
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4.5}>
                        <FormAutocomplete
                            name='invoicesAccountCode'
                            control={control}
                            options={accountOptions}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={1}>
                    <Grid
                        item
                        xs={12}
                        lg={7}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <CustomButton
                            type='submit'
                            variant='contained'
                            color='green'
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            Save Accounts
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function ApplyingAFeeToAnExperience() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            {loggedInUser?.role.includes('admin') ? (
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To apply a fee to an experience:
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        Note: Before applying a Fee, you will need to follow
                        'How to create a new Fee Type'
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                            paddingTop: '15px',
                        }}
                    >
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>From the menu, click on ‘Experiences’</li>
                            <li>
                                Click 'Manage Experiences' or 'Create New
                                Experience'
                            </li>
                            <li>
                                Select the desired experience to apply fees
                                (skip this step if you are creating a new
                                experience)
                            </li>
                            <li>
                                Click the pencil icon next to the experience's
                                name (skip this step if you are creating a new
                                experience)
                            </li>
                            <li>Scroll down to 'Pricing' section</li>
                            <li>Click on 'Additional Charges'</li>

                            <li>Complete all mandatory fields with a *</li>
                            <li>Add as many fees as necessary</li>
                            <li>Click 'Save' once done</li>
                            <li>Scroll to the bottom of the experience</li>
                            <li>
                                Click 'Update' for existing experiences or
                                'Save' for new experiences
                            </li>
                        </ol>
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        Once you apply a Price or a Fee to an experience it will
                        display to the guest on the listing and at the time the
                        submit the booking. This amount will be debited from
                        their nominated card.
                    </Typography>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Only admins can apply a fee to an experience. Please
                            contact a person that is an admin in your
                            organisation to add, edit or delete a fee from an
                            experience.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

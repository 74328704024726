import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CardMedia, Grid } from '@mui/material';
import { NextArrow, PrevArrow } from './CustomArrow';

export default function ImageSlider({ images }: any) {
    const [mouseEnter, setMouseEnter] = useState<any>(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    const mouseHover = () => {
        setMouseEnter(true);
    };

    const mouseExit = () => {
        setMouseEnter(false);
    };
    const settings = {
        dots: mouseEnter,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex: any, newIndex: React.SetStateAction<number>) =>
            setCurrentSlide(newIndex),
        nextArrow: mouseEnter ? <NextArrow /> : undefined,
        prevArrow: mouseEnter ? <PrevArrow /> : undefined,
        appendDots: (dots: any) => (
            <div
                style={{
                    position: 'absolute',
                    bottom: '20px',
                    width: '100%',
                }}
            >
                <ul style={{ margin: '0px' }}>{dots}</ul>
            </div>
        ),
        customPaging: (i: any) => (
            <div
                className={`custom-dot ${currentSlide === i ? 'active' : ''}`}
                style={{
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    background: currentSlide === i ? '#5cb5b3' : '#fff', // Change active dot color here
                }}
            ></div>
        ),
    };
    return (
        <div onMouseEnter={mouseHover} onMouseLeave={mouseExit}>
            <Slider {...settings}>
                {images.map((image: any) => (
                    <CardMedia
                        component='img'
                        image={image.src}
                        alt={image.id}
                        sx={{
                           
                            aspectRatio: 1 / 1,
                        }}
                    />
                ))}
            </Slider>
        </div>
    );
}

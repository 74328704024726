import { Grid, Typography, useMediaQuery } from '@mui/material';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useRef } from 'react';
import jsPDF from 'jspdf';
import moment from 'moment';
import React from 'react';
import ReactGA from 'react-ga4';

export default function ShareReservation(params: any) {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const reservationInfo = params?.getBookingDetails;
    let getIndividualAsset = params?.getExperienceDetails;

    const fullAddress = `${getIndividualAsset?.streetAddress1} ${getIndividualAsset?.streetAddress2} ${getIndividualAsset?.suburb} ${getIndividualAsset?.postcode} ${getIndividualAsset?.state} ${getIndividualAsset?.countryCode}`;

    const reportTemplateRef: any = useRef(null);

    const handleGeneratePdf = () => {
        ReactGA.event({
            category: 'UI',
            action: `Generate PDF`,
            label: `Generate PDF Clicked`,
        });
        const doc: any = new jsPDF();

        doc.setFont('helvetica', 'bold');
        reservationInfo?.status === 'pending'
            ? doc.text(
                  50,
                  20,
                  `Reservation Request Submitted - ${moment(
                      reservationInfo?.createdAt
                  )
                      .utc()
                      .format('DD/MM/YYYY')}`
              )
            : doc.text(85, 20, `Reservation Details`);
        doc.setFont('helvetica', 'normal');
        doc.text(20, 30, `Reservation Reference: ${reservationInfo?.id}`);
        doc.setFont('helvetica', 'normal');
        doc.text(
            20,
            40,
            `Status: ${
                reservationInfo?.status.charAt(0).toUpperCase() +
                reservationInfo?.status.slice(1)
            }`
        );
        doc.setFont('helvetica', 'normal');
        doc.text(
            20,
            50,
            `Company: ${
                reservationInfo?.guest?.name
                    ? reservationInfo?.guest?.name
                    : reservationInfo?.reservationInfo?.visitType
                    ? `Booked on behalf of ${reservationInfo?.owner?.name}`
                    : `Blocked by ${reservationInfo?.owner?.name}`
            }`
        );
        doc.text(
            20,
            60,
            `Reserved By: ${reservationInfo?.booker?.firstName} ${reservationInfo?.booker?.lastName} - ${reservationInfo?.booker?.email}`
        );
        doc.setFont('helvetica', 'normal');
        doc.text(
            20,
            70,
            `Experience: ${reservationInfo?.module?.name} (${reservationInfo?.module?.type})`
        );
        doc.text(20, 80, `Location: ${fullAddress}`);
        doc.setFont('helvetica', 'normal');
        doc.text(
            20,
            90,
            `Date: ${moment(reservationInfo?.startDate).format(
                'DD/MM/YYYY'
            )} - ${moment(reservationInfo?.endDate).format('DD/MM/YYYY')}`
        );
        {
        }
        doc.setFont('helvetica', 'normal');
        doc.text(
            20,
            100,
            `${
                reservationInfo?.bookerType === 'owner' &&
                reservationInfo?.onBehalf === false
                    ? ''
                    : `${`Time:  ${reservationInfo?.reservationInfo?.checkinTime} - ${reservationInfo?.reservationInfo?.checkoutTime}`}`
            }`
        );
        doc.setFont('helvetica', 'normal');
        doc.text(
            20,
            110,
            `${
                reservationInfo?.bookerType === 'owner' &&
                reservationInfo?.onBehalf === false
                    ? ''
                    : `${`Type Of Visit:  ${reservationInfo?.reservationInfo?.visitType}`}`
            }`
        );
        doc.setFont('helvetica', 'normal');
        doc.text(
            20,
            120,
            `${
                reservationInfo?.bookerType === 'owner' &&
                reservationInfo?.onBehalf === false
                    ? ''
                    : `${
                          reservationInfo?.reservationInfo?.moduleType !==
                          'activity'
                              ? `No. Guests: ${
                                    reservationInfo?.reservationInfo?.children +
                                    reservationInfo?.reservationInfo?.adults
                                }`
                              : `No. Guests: ${reservationInfo?.reservationInfo.guests}`
                      }`
            }`
        );

        doc.setFont('helvetica', 'normal');
        doc.text(
            20,
            130,
            reservationInfo?.bookerType === 'owner' &&
                !reservationInfo?.onBehalf
                ? ''
                : reservationInfo?.reservationInfo?.moduleType !== 'activity' &&
                  !(
                      getIndividualAsset?.details?.subType === 'commercial' ||
                      getIndividualAsset?.details?.subType === 'office space'
                  )
                ? `No. Overnight Guests: ${reservationInfo?.reservationInfo?.overnightGuests}`
                : ''
        );
        doc.setFont('helvetica', 'normal');
        doc.text(
            20,
            140,
            `${
                reservationInfo?.bookerType === 'owner' &&
                reservationInfo?.onBehalf === false
                    ? ''
                    : `${
                          reservationInfo?.reservationInfo?.moduleType !==
                          'activity'
                              ? `Catering: ${reservationInfo?.reservationInfo?.cateringNotes}`
                              : ''
                      }`
            }`
        );

        doc.setFont('helvetica', 'normal');
        //         doc.setDrawColor(255, 0, 0);
        // doc.setLineWidth(0.5);
        // doc.line(10, 10, 10, 33);
        // doc.line(60, 10, 60, 33);
        doc.text(
            20,
            150,
            `${
                reservationInfo?.bookerType === 'owner' &&
                reservationInfo?.onBehalf === false
                    ? ''
                    : `${
                          reservationInfo?.reservationInfo?.moduleType !==
                          'activity'
                              ? `Beverages: ${reservationInfo?.reservationInfo?.beverageNotes}`
                              : ''
                      }`
            }`
            // {maxWidth: 150,},
        );

        doc.setFont('helvetica', 'normal');
        doc.text(
            20,
            160,
            `Notes: ${
                reservationInfo?.bookerType === 'owner' &&
                reservationInfo?.onBehalf === false
                    ? reservationInfo?.notes
                    : reservationInfo?.reservationInfo?.notes
            }`,
            { maxWidth: 180 }
        );
        doc.save(`${reservationInfo?.module.name}.pdf`);
    };

    return (
        <Grid
            ref={reportTemplateRef}
            display={isMobile ? 'none' : isIpad ? 'none' : 'block'}
            sx={{
                width: '100%',
                // backgroundColor: 'red'
            }}
        >
            <CustomButton
                onClick={handleGeneratePdf}
                color='green'
                sx={{
                    marginLeft: '-10px',
                    '&:hover': {
                        cursor: 'pointer',
                        color: '#5cb5b3',
                        backgroundColor: '#f6f8f9',
                    },
                }}
            >
                <IosShareIcon fontSize='small' sx={{ fontSize: '16px' }} />{' '}
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '16px',
                    }}
                >
                    PDF
                </Typography>
            </CustomButton>
        </Grid>
    );
}

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function ExperienceMessages() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Guest can enquire about an experience by using the 'Contact
                        the Host' link at the bottom of the listed experience. Sending a
                        message from here will create a message thread between
                        the guest and the host's support team.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

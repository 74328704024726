import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import EditIcon from '@mui/icons-material/Edit';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useState } from 'react';
import Modal from '../../shared/Modal';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import ReactGA from 'react-ga4';

export default function IndividualExperienceHero(experience: any) {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isLaptop = useMediaQuery('(max-width: 1550px)');
    const [openModal, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalComponent, setModalComponent] = useState('');
    let urlOwnerId = window.location.href.split('/')[4];
    let ownerId = experience?.experience?.owner.id.toString();

    const navigate = useNavigate();
    // const onClickGoBack = () => {
    //     navigate(`/viewExperiences`);
    // };
    let experienceDetails = experience?.experience;

    const editExperience = () => {
        navigate(`/editExperience/${experienceDetails.id}`, {
            state: {
                name: 'editExperience',
                params: experienceDetails,
            },
        });
    };

    const onClickOpenShareModal = () => {
        setOpen(true);

        setModalMessage('Share this experience');
        setModalComponent('Share');
        ReactGA.event({
            category: 'UI',
            action: `Share Experience`,
            label: 'Share Experience Clicked',
        });
    };
    const onClickOpenCalendarModal = () => {
        setOpen(true);

        setModalMessage('Link this calendar to an external calendar');
        setModalComponent('Calendar');
        ReactGA.event({
            category: 'UI',
            action: `Link Calendar`,
            label: 'Link Calendar Clicked',
        });
    };
    const handleChangeModal = () => {
        setOpen(false);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const fullAddress = `${experienceDetails?.streetAddress1} ${experienceDetails?.streetAddress2} ${experienceDetails?.suburb} ${experienceDetails?.postcode} ${experienceDetails?.state} ${experienceDetails?.countryCode}`;
    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',

                width: '100%',
            }}
        >
            <Grid sx={{ width: isMobile ? '80%' : '90%', display: 'flex' }}>
                <Typography sx={{ fontFamily: 'Open Sans', color: '#787878' }}>
                    {/* <LocationOnIcon
                        sx={{ fontSize: '15px', paddingRight: '5px' }}
                    /> */}
                    <Link
                        style={{
                            color: '#787878',
                            fontFamily: 'Open Sans',
                            fontSize: '12px',
                            paddingLeft: '0px',
                        }}
                        target='_blank'
                        to={`https://www.google.com/maps/place/${fullAddress}`}
                    >
                        {fullAddress}
                    </Link>
                </Typography>
                {urlOwnerId === ownerId ? (
                    <CustomIconButton
                        sx={{
                            backgroundColor: '#fff',
                            width: '25px',
                            height: '25px',
                            paddingLeft: '20px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                        onClick={editExperience}
                    >
                        <EditIcon
                            fontSize='small'
                            sx={{ color: '#787878', fontSize: '16px' }}
                        />
                    </CustomIconButton>
                ) : null}
            </Grid>
            <Grid
                sx={{
                    width:
                        urlOwnerId === ownerId
                            ? isMobile || isIpad || isLaptop
                                ? '100%'
                                : '20%'
                            : '100%',
                    textAlign: 'right',
                    // backgroundColor: 'yellow',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignContent: 'flex-end',
                    alignItems: 'flex-end',
                    paddingTop: isMobile ? '30px' : '0px',
                }}
            >
                {urlOwnerId === ownerId ? (
                    <CustomButton
                        onClick={onClickOpenCalendarModal}
                        color='green'
                        sx={{
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: '#f6f8f9',
                            },
                        }}
                    >
                        <LinkIcon fontSize='small' sx={{ fontSize: '20px' }} />{' '}
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '16px',
                                paddingLeft: '5px',
                                paddingTop: '4px',
                            }}
                        >
                            Calendar Link
                        </Typography>
                    </CustomButton>
                ) : null}

                {!experienceDetails?.isAvailable ? null:
                
                
                 <CustomButton
                    onClick={onClickOpenShareModal}
                    color='green'
                    sx={{
                        '&:hover': {
                            cursor: 'pointer',
                            color: '#5cb5b3',
                            backgroundColor: '#f6f8f9',
                        },
                    }}
                >
                    <IosShareIcon fontSize='small' sx={{ fontSize: '16px' }} />{' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            fontSize: '16px',
                            paddingLeft: '5px',
                            paddingTop: '4px',
                        }}
                    >
                        Share
                    </Typography>
                </CustomButton>
                }
               
            </Grid>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                onChange={handleChangeModal}
                message={modalMessage}
                component={modalComponent}
                details={experienceDetails}
            />
        </Grid>
    );
}

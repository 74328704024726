import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { camelCaseToTitleCase } from '../../../../../shared/utils';
import GenericModal from '../../../../shared/GenericModal';
import { ModalProps } from '../../../../types/modal';
import { selectPriceRequired } from '../../../../../app/Slices/PriceRequiredSlice';

interface Props extends ModalProps {
    onChange: () => any,
    experienceType: string,
}

const SaveExperienceConfirmationModal = ({ onChange, open, onClose, experienceType }: Props) => {
    const priceRequired = useSelector(selectPriceRequired);

    const experienceTypeText =
        experienceType === 'commercial'
            ? priceRequired?.length > 1
                ? priceRequired
                      .slice(0, -1)
                      .map((space) =>
                          camelCaseToTitleCase(space.spaceType)
                      )
                      .join(', ') +
                  ' and ' +
                  camelCaseToTitleCase(
                      priceRequired[priceRequired.length - 1]?.spaceType || ''
                  )
                : priceRequired
                      .map((space) =>
                          camelCaseToTitleCase(space?.spaceType || '')
                      )
                      .join('')
            : `the ${experienceType}`;

    return (
        <GenericModal
            open={open}
            onClose={onClose}
            onChange={onChange}
            dialogActions={true}
            showCancelButton={true}
            showConfirmButton={true}
            confirmText={'Yes'}
            confirmColor={'green'}
            cancelText={'Cancel'}
            message={'delete this Pricing & Fee type'}
        >
            {' '}
            <Grid
                item
                xs={25}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        padding: '30px',
                        maxWidth: '420px',
                    }}
                >
                    {`You haven't entered a price for ${experienceTypeText}. Are you sure you want to save the experience?`}
                </Typography>
            </Grid>
        </GenericModal>
    );
};

export default SaveExperienceConfirmationModal;

import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    selectEndDate,
    selectStartDate,
} from '../../../../app/Slices/DateFilterSlice';
import {
    selectExperienceFilters,
    setExperienceFilters,
} from '../../../../app/Slices/ExperienceFiltersSlice';
import ReactGA from 'react-ga4';
import {
    selectExperienceSearchFilter,
    setExperienceSearchFilter,
} from '../../../../app/Slices/ExperienceSearchSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import ExperiencesListAllComponent from '../../../components/Experiences/ExperiencesListAllComponent';
import DateFilter from '../../../shared/DateFilter';
import FiltersModal from '../../../shared/FiltersModal';
import SearchFilterGeneric from '../../../shared/SearchFilterGeneric';
import VisitTypeModal from './VisitTypeModal';

export default function ViewAllExperiencesPage() {
    const experienceFilters = useSelector(selectExperienceFilters);
    // const [openVisitModal, setVisitTypeOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 900px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const urlRef = window.location.href.split('/')[4];
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const searchValue = useSelector(selectExperienceSearchFilter);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // const handleCloseVisitModal = () => {
    //     setVisitTypeOpen(false);
    // };
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (urlRef === 'all') {
    //         setVisitTypeOpen(true);
    //     }
    // }, [urlRef]);

    const handleSearchValueChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newSearchValue = event.target.value;
        dispatch(setExperienceSearchFilter(newSearchValue));
    };

    useEffect(() => {
        if (startDate && endDate && startDate <= endDate) {
            dispatch(
                setExperienceFilters({
                    ...experienceFilters,
                    checkinDate: moment(startDate).format('YYYY-MM-DD'),
                    checkoutDate: moment(endDate).format('YYYY-MM-DD'),
                })
            );
        }
        if (startDate === undefined && endDate === undefined) {
            dispatch(
                setExperienceFilters({
                    ...experienceFilters,
                    checkinDate: '',
                    checkoutDate: '',
                })
            );
        }
    }, [startDate, endDate]);

    const handleVisitType = (event: any) => {
        navigate(`/viewExperiences/all`);
        ReactGA.send({
            hitType: 'pageview',
            page: '/viewExperiences/all',
            title: 'All Experiences',
        });
        // if (event === 'business') {
        //     navigate(`/viewExperiences/business`);
        //     // dispatch(
        //     //     openVisitTypeSliceAction({
        //     //         view: 'personal',
        //     //     })
        //     // );
        //     ReactGA.send({
        //         hitType: 'pageview',
        //         page: '/viewExperiences/business',
        //         title: 'Business Experiences',
        //     });
        // } else if (event === 'personal') {
        //     navigate(`/viewExperiences/personal`);
        //     // dispatch(
        //     //     openVisitTypeSliceAction({
        //     //         view: 'business',
        //     //     })
        //     // );
        //     ReactGA.send({
        //         hitType: 'pageview',
        //         page: '/viewExperiences/personal',
        //         title: 'Personal Experiences',
        //     });
        // } else {
        //     navigate(`/viewExperiences/all`);
        //     ReactGA.send({
        //         hitType: 'pageview',
        //         page: '/viewExperiences/all',
        //         title: 'All Experiences',
        //     });
        // }
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                {/* <Grid
                    sx={{
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex',
                        paddingBottom: '20px',
                    }}
                >
                    <Grid
                        sx={{
                            padding: '0px 20px 0px 10px',
                        }}
                    >
                        <CustomButton
                            color='gray'
                            onClick={() => {
                                handleVisitType('personal');
                            }}
                            sx={{
                                fontWeight:
                                    urlRef === 'personal' ? 'bold' : 'none',
                                '&:hover': {
                                    color:
                                        urlRef === 'personal'
                                            ? '#787878'
                                            : '#fff',
                                    backgroundColor:
                                        urlRef === 'personal'
                                            ? '#fff'
                                            : '#5cb5b3',
                                    opacity: urlRef === 'personal' ? 1 : 0.8,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textTransform: 'capitalize',
                                    fontWeight:
                                        urlRef === 'personal' ? 'bold' : 'none',
                                    '&:hover': {
                                        color:
                                            urlRef === 'personal'
                                                ? '#787878'
                                                : '#fff',
                                    },
                                }}
                            >
                                Personal
                            </Typography>
                        </CustomButton>
                    </Grid>
                    <Grid
                        sx={{
                            padding: '0px 10px 0px 20px',
                            '&:hover': {
                                color: '#fff',
                            },
                        }}
                    >
                        <CustomButton
                            color='gray'
                            onClick={() => {
                                handleVisitType('business');
                            }}
                            sx={{
                                fontWeight:
                                    urlRef === 'business' ? 'bold' : 'none',
                                // color: '#fff',
                                '&:hover': {
                                    color:
                                        urlRef === 'business'
                                            ? '#787878'
                                            : '#fff',
                                    backgroundColor:
                                        urlRef === 'business'
                                            ? '#fff'
                                            : '#5cb5b3',
                                    opacity: urlRef === 'business' ? 1 : 0.8,
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textTransform: 'capitalize',
                                    fontWeight:
                                        urlRef === 'business' ? 'bold' : 'none',
                                    '&:hover': {
                                        color:
                                            urlRef === 'business'
                                                ? '#787878'
                                                : '#fff',
                                    },
                                }}
                            >
                                corporate
                            </Typography>
                        </CustomButton>
                    </Grid>
                </Grid>{' '} */}
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: '5px',
                        justifyContent: 'center',
                        paddingBottom: '10px',
                        width: '100%',
                    }}
                >
                    <Grid
                        sx={{
                            borderStyle: 'solid',
                            borderWidth: 'thin',
                            borderColor: '#E0E0E0',
                            borderRadius: 4,
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            gap: isMobile ? '5px' : '15px',
                            padding: '15px 10px 15px 10px',
                            width: isMobile ? 'auto' : 'auto',
                            boxShadow: 1,
                        }}
                    >
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: isMobile ? 'auto' : '300px',
                                paddingLeft: isMobile ? '0px' : '20px',
                            }}
                        >
                            <SearchFilterGeneric
                                handleSearchValueChange={
                                    handleSearchValueChange
                                }
                                value={searchValue}
                            />
                            {isMobile ? (
                                <FiltersModal management={false} />
                            ) : null}
                        </Grid>
                        <DateFilter />
                        {isMobile ? null : (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: isMobile ? 'column' : 'row',
                                    width: 'auto',
                                    paddingRight: '10px',
                                }}
                            >
                                <FiltersModal management={false} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        container
                        sx={{
                            width: '100%',
                            paddingBottom: isMobile || isIpad ? '40px' : '0px',
                            marginTop: '0px',
                        }}
                    >
                        <ExperiencesListAllComponent />
                        {/* {urlRef === 'business' ? (
                            <ExperiencesListAllComponent visitType='business' />
                        ) : urlRef === 'personal' ? (
                            <ExperiencesListAllComponent visitType='personal' />
                        ) : (
                            <ExperiencesListAllComponent />
                        )} */}
                    </Grid>
                </Grid>
            </Grid>
            {/* When the user first hits the site they are asked if they want to see personal or corporate */}
            {/* <VisitTypeModal
                open={openVisitModal}
                onClose={handleCloseVisitModal}
            /> */}
        </Container>
    );
}

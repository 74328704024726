import {
    Grid,
    IconButton,
    InputAdornment,
    Popover,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useGetReportQuery } from '../../../../../app/Slices/SevenDayApiSlice';
import { Calendar } from 'react-date-range';
import { CustomTextField } from '../../../../../layout/styledcomponents/CustomTextField';
import moment from 'moment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { startOfYear } from 'date-fns';
import { CustomButton } from '../../../../../layout/styledcomponents/CustomButton';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSelector } from 'react-redux';
import { selectReportAPIRefSliceData } from '../../../../../app/Slices/ReportAPIRefSlice';
import CancellationReportGraph from './CancellationReportGraph';
import CancellationListReport from './CancellationReportList';
import SearchIcon from '@mui/icons-material/Search';

const defaultSearchValueParser = (searchText: any) =>
    searchText.split(' ').filter((word: any) => word !== '');

export default function CancellationComponentPage(props: any) {
    const {
        quickFilterParser = defaultSearchValueParser,
        debounceMs = 500,
        ...other
    } = props;
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [openCalendar1, setOpenCalendar1] = useState(false);
    const [anchorElCalendar1, setAnchorElCalendar1] =
        React.useState<null | HTMLElement>(null);
    const [openCalendar2, setOpenCalendar2] = useState(false);
    const [anchorElCalendar2, setAnchorElCalendar2] =
        React.useState<null | HTMLElement>(null);
    const [fromDate, setFromDate] = useState(startOfYear(new Date()));
    const [toDate, setToDate] = useState(new Date());
    const [checked, setChecked] = React.useState(false);
    const reportExportAPI: any = useSelector(selectReportAPIRefSliceData);
    // const [groupBy, setGroupBy] = useState('None');
    const [searchValue, setSearchValue] = React.useState('');
    //REPORT VIEW
    const graphView = (value: boolean | ((prevState: boolean) => boolean)) => {
        setChecked(value);
    };

    //GET REPORT
    const { data: getReport } = useGetReportQuery(
        `/reports/cancelled-bookings?from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
    );

    // const handleGroupBy = (value: any) => {
    //     setGroupBy(value);
    // };

    const handleSearchValueChange = (event: { target: { value: any } }) => {
        const newSearchValue = event.target.value;
        setSearchValue(newSearchValue);
        updateSearchValue(event.target.value);
    };

    const updateSearchValue = React.useCallback(
        (newSearchValue: any) => {
            reportExportAPI?.search(quickFilterParser(newSearchValue));
        },
        [reportExportAPI, quickFilterParser]
    );

    const handleCalendar1 = (event: React.MouseEvent<HTMLElement>) => {
        setOpenCalendar1(!openCalendar1);
        setAnchorElCalendar1(event.currentTarget);
    };

    const handleCalendar1Close = (event: React.MouseEvent<HTMLElement>) => {
        setOpenCalendar1(!openCalendar1);
        setAnchorElCalendar1(null);
    };

    const handleCalendar2 = (event: React.MouseEvent<HTMLElement>) => {
        setOpenCalendar2(!openCalendar2);
        setAnchorElCalendar2(event.currentTarget);
    };

    const handleCalendar2Close = (event: React.MouseEvent<HTMLElement>) => {
        setOpenCalendar2(!openCalendar2);
        setAnchorElCalendar2(null);
    };

    return (
        <Grid sx={{ Top: isMobile ? '30px' : '0px' }}>
            <Grid
                sx={{
                    textAlign: isMobile ? 'left' : 'right',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'left',
                        // backgroundColor: 'yellow',
                    }}
                >
                    <Grid
                        sx={{
                            width: 'auto',
                            // backgroundColor: 'blue',
                            marginRight: isMobile ? '0px' : '15px',
                        }}
                    >
                        <CustomTextField
                            color='neutral'
                            label='From'
                            fullWidth={isMobile}
                            value={moment(fromDate)?.format('DD/MM/YYYY')}
                            onClick={handleCalendar1}
                            // onChange={(item:any) => [setFromDate(item)]}
                            InputProps={{
                                // color: '#787878',
                                style: {
                                    minHeight: '30px',
                                    maxHeight: '33px',
                                },
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            disableRipple
                                            onClick={handleCalendar1}
                                        >
                                            <DateRangeIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Popover
                            open={openCalendar1}
                            anchorEl={anchorElCalendar1}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handleCalendar1Close}
                        >
                            <Calendar
                                onChange={(item) => [setFromDate(item)]}
                                date={fromDate}
                                weekStartsOn={1}
                                rangeColors={['#5cb5b3']}
                                color='#5cb5b3'
                            />
                        </Popover>
                    </Grid>
                    <Grid
                        sx={{
                            width: 'auto',
                            marginRight: isMobile ? '0px' : '15px',
                            marginTop: isMobile ? '10px' : '0px',
                        }}
                    >
                        <CustomTextField
                            label='To'
                            color='neutral'
                            fullWidth={isMobile}
                            value={moment(toDate)?.format('DD/MM/YYYY')}
                            onClick={handleCalendar2}
                            InputProps={{
                                style: {
                                    minHeight: '30px',
                                    maxHeight: '33px',
                                },
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            disableRipple
                                            onClick={handleCalendar2}
                                        >
                                            <DateRangeIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Popover
                            open={openCalendar2}
                            anchorEl={anchorElCalendar2}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handleCalendar2Close}
                        >
                            <Calendar
                                onChange={(item) => [setToDate(item)]}
                                date={toDate}
                                weekStartsOn={1}
                                rangeColors={['#5cb5b3']}
                                color='#5cb5b3'
                            />
                        </Popover>
                    </Grid>
                    {/* <Grid
                        sx={{
                            width: 'auto',
                            marginRight: isMobile ? '0px' : '15px',
                            marginTop: isMobile ? '10px': '0px'
                        }}
                    >
                        {' '}
                        <DropdownFilter
                            id={'groupBy'}
                            options={[
                                // { value: 'All', label: 'All' },
                                { value: 'None', label: 'None' },
                                { value: 'Experience', label: 'Experience' },
                                { value: 'Type', label: 'Type' },
                                { value: 'BookerType', label: 'Guest/Owner' },
                                { value: 'Visit', label: 'Type of Visit' },
                                { value: 'CancelledBy', label: 'Cancelled By' },
                                { value: 'Reason', label: 'Reason' },
                            ]}
                            handleChange={handleGroupBy}
                            selectedValue={
                                <Grid
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '16px',
                                            marginRight: '5px',
                                        }}
                                    >
                                        Group By:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {groupBy}
                                    </Typography>
                                </Grid>
                            }
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                        />
                    </Grid> */}
                    {checked === false ? (
                        <Grid
                            sx={{
                                width: 'auto',
                                marginTop: isMobile ? '10px' : '0px',
                            }}
                        >
                            <CustomTextField
                                fullWidth={isMobile}
                                color='neutral'
                                placeholder='Search...'
                                onChange={handleSearchValueChange}
                                InputProps={{
                                    style: {
                                        minHeight: '30px',
                                        maxHeight: '33px',
                                    },
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton disableRipple>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    ) : null}

                    {/* <Grid sx={{ height: '100%' }}></Grid> */}
                </Grid>

                <Grid
                    sx={{
                        width: isMobile ? '100%' : '50%',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? 'left' : 'right',
                        justifyContent: isMobile ? 'flex-start' : 'flex-end',
                        paddingTop: isMobile ? '10px' : '0px',
                    }}
                >
                    <Grid
                        sx={{
                            width: 'auto',
                            marginRight: isMobile ? '0px' : '15px',
                        }}
                    >
                        {checked === true ? (
                            <CustomButton
                                disableRipple
                                variant='contained'
                                color='green'
                                onClick={() => graphView(false)}
                                sx={{ marginRight: '5px' }}
                            >
                                <FormatListBulletedIcon fontSize='small' />
                            </CustomButton>
                        ) : (
                            <CustomButton
                                disableRipple
                                variant='contained'
                                color='green'
                                onClick={() => graphView(true)}
                                sx={{ marginRight: '5px' }}
                            >
                                <EqualizerIcon fontSize='small' />
                            </CustomButton>
                        )}
                        {checked === false ? (
                            <>
                                {' '}
                                {/* <CustomButton
                                    disableRipple
                                    variant='contained'
                                    color='neutral'
                                    onClick={() =>
                                        reportExportAPI?.printExport()
                                    }
                                    sx={{ marginRight: '5px' }}
                                >
                                    <PrintIcon fontSize='small' />{' '}
                                </CustomButton> */}
                                <CustomButton
                                    disableRipple
                                    variant='contained'
                                    color='neutral'
                                    onClick={() =>
                                        reportExportAPI?.csvExport({
                                            fileName:
                                                'Cancelled Reservations Report',
                                            columnsStyles: {
                                                createdAt: {
                                                    numFmt: 'dd/mm/yyyy',
                                                },
                                            },
                                            hideFooter: true,
    hideToolbar: true,
    // pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { fontSize: 50px}',
                                        })
                                    }
                                    sx={{ marginRight: '5px' }}
                                >
                                    <FileDownloadIcon fontSize='small' />{' '}
                                </CustomButton>
                            </>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                {checked === true ? (
                    <CancellationReportGraph data={getReport} />
                ) : (
                    <CancellationListReport data={getReport} />
                )}
            </Grid>
        </Grid>
    );
}

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useAddTeamMutation,
    useDeleteTeamMutation,
    useEditTeamMutation,
    useGetTeamListQuery,
} from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../layout/styledcomponents/CustomTextField';
import UserRole from '../../../shared/AuthRoles';
import ConfirmationModal from '../../shared/ConfirmationModal';
import toast from '../../shared/Toast';
import { Team } from './TeamList';
import { handleHttpError } from '../../../shared/utils';

interface Props {
    team?: Team;
}

const TeamsForm = ({ team }: Props) => {
    const [isDuplicateName, setDuplicateName] = useState(false);
    const [addNewTeam] = useAddTeamMutation();
    const [teamName, setTeamName] = useState('');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();
    const [editTeam] = useEditTeamMutation();
    const [deleteTeam] = useDeleteTeamMutation();
    const { data: getTeams } = useGetTeamListQuery(loggedInUser?.company);
    const [showModal, setShowModal] = useState(false);

    const teamNames = getTeams?.map((team: Team) => team?.name);

    const handleTeamName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const findDuplicate = teamNames?.find(
            (teamName: string) => teamName === event?.target?.value
        );
        if (
            findDuplicate !== undefined &&
            findDuplicate !== getTeams?.department?.name
        ) {
            setDuplicateName(true);
            setTeamName('');
        } else {
            setDuplicateName(false);
            setTeamName(event.target?.value?.toLowerCase());
        }
    };

    const errors: any = [];

    if (teamName.length === 0) {
        errors.push('Name cannot be empty');
    }

    useEffect(() => {
        if (team && team.name) {
            setTeamName(team.name);
        }
    }, [team]);

    const saveTeam = () => {
        if (team) {
            editTeam([team.id, { name: teamName }]).then((data: any) => {
                if (data) {
                    if (data?.error) {
                        const errorMessage = handleHttpError(data?.error);
                        toast.error(errorMessage);
                    } else {
                        toast.success(
                            `Team: ${teamName} has been successfully updated`
                        );
                        navigate('/teams');
                    }
                } else {
                    toast.error(`Failed to edit team: ${teamName}`);
                }
            });
        } else {
            if (
                loggedInUser?.role === 'guest_company' ||
                loggedInUser?.role === 'guest_admin'
            ) {
                addNewTeam([loggedInUser?.company, { name: teamName }]).then(
                    (data: any) => {
                        if (data?.error) {
                            const errorMessage = handleHttpError(data?.error);
                            toast.error(errorMessage);
                        } else {
                            toast.success(
                                `New Team: ${teamName} has been created`
                            );
                            navigate('/teams');
                        }
                    }
                );
            } else {
                toast.error(`You don't have the necessary rights to add teams`);
            }
        }
    };

    const handleDeleteTeam = () => {
        deleteTeam([team?.id]).then((data: any) => {
            if (data.error) {
                toast.error(
                    `Error: ${
                        teamName?.charAt(0)?.toUpperCase() + teamName.slice(1)
                    } ${data.error.data.message}`
                );
            } else {
                toast.success(
                    `Team: ${
                        teamName?.charAt(0)?.toUpperCase() + teamName.slice(1)
                    } has been deleted`
                );

                navigate('/teams');
            }
        });
    };

    return (
        <Grid
            sx={{
                width: '100%',
                height: '20%',
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '2%' : '5%',
                    justifyContent: 'left',
                    display: 'flex',
                }}
            >
                <Typography
                    component='h1'
                    variant='h5'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        textDecoration: 'underline',
                        textDecorationColor: '#5cb5b3',
                        textUnderlineOffset: '8px',
                    }}
                >
                    {team ? `Edit Team ` : ` New Team`}
                </Typography>
            </Grid>
            <Grid
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                    paddingTop: '30px',
                }}
            >
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile || isIpad ? 'column' : 'row',
                        width: isMobile || isIpad ? '100%' : '50%',
                    }}
                >
                    <Grid
                        sx={{
                            width: isMobile ? '100%' : '20%',
                            height: isMobile || isIpad ? '' : '50px',
                        }}
                    >
                        {' '}
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Team Name
                            {teamName === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}{' '}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            width: isMobile || isIpad ? '100%' : '30%',
                            height: '50px',
                            marginRight: !isMobile || !isIpad ? '50px' : '0px',
                            paddingTop: isMobile || isIpad ? '5px' : '0px',
                        }}
                    >
                        {' '}
                        <CustomTextField
                            fullWidth={isMobile || isIpad}
                            color='neutral'
                            error={isDuplicateName}
                            label={
                                isDuplicateName === true
                                    ? 'Error: Team already exists'
                                    : ''
                            }
                            value={!isDuplicateName ? teamName : null}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleTeamName}
                            inputProps={{
                                style: {
                                    height: '10px',
                                    width: isMobile
                                        ? ''
                                        : isIpad
                                        ? ''
                                        : '400px',
                                    backgroundColor: '#f6f8f9',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        display: 'flex',
                        width: isMobile || isIpad ? '100%' : '50%',
                        justifyContent: 'right',
                        height: '50%',
                        textAlign: 'right',
                        alignItems: 'center',
                        paddingTop: isMobile || isIpad ? '5px' : '0px',
                    }}
                >
                    {team && (
                        <Grid sx={{ paddingRight: '5px' }}>
                            <CustomButton
                                id='deleteTeam'
                                variant='contained'
                                color='warning'
                                onClick={() => {
                                    setShowModal(!showModal);
                                }}
                                sx={{
                                    // color: '#fff',
                                    // marginLeft: !isMobile ? '-8px' : '-18px',
                                    fontSize: isMobile ? '12px' : '14px',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#5cb5b3',
                                        backgroundColor: '#f6f8f9',
                                    },
                                }}
                            >
                                Delete
                            </CustomButton>
                        </Grid>
                    )}
                    <Grid
                        sx={{ paddingLeft: isMobile || isIpad ? '0px' : '5px' }}
                    >
                        <CustomButton
                            id='saveTeam'
                            fullWidth={isMobile || isIpad}
                            variant='contained'
                            color='green'
                            onClick={saveTeam}
                            disabled={errors.length > 0}
                            sx={{
                                fontSize: isMobile ? '12px' : '14px',
                            }}
                        >
                            {team ? `Update` : ` Save`}
                        </CustomButton>
                    </Grid>

                    <ConfirmationModal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        onChange={handleDeleteTeam}
                        message={'delete this team'}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TeamsForm;

import { Delete, VolumeOff, VolumeUp } from '@mui/icons-material';
import {
    Avatar,
    Box,
    ButtonGroup,
    IconButton,
    Stack,
    TableCell,
    TableRow,
} from '@mui/material';
import React from 'react';
import { CustomSmallListChip } from '../../../../layout/styledcomponents/CustomChip';
import { Member } from '../../../types/messaging';

interface MemberItemProps {
    member: Member;
    handleDelete: (member: Member) => void;
    handleMute: (member: Member) => void;
}

const MemberItem = ({ member, handleDelete, handleMute }: MemberItemProps) => {
    return (
        <TableRow key={member.userId}>
            <TableCell>
                <Box display='flex' gap={1}>
                    <Avatar src={member.src} />
                    <Stack>
                        {member.name}
                        {member.isMuted && (
                            <CustomSmallListChip label='MUTED' />
                        )}
                    </Stack>
                </Box>
            </TableCell>
            <TableCell padding='checkbox'>
                <ButtonGroup>
                    <IconButton
                        size='small'
                        disabled={member.readToSegment === 0}
                        onClick={() => handleMute(member)}
                    >
                        {member.isMuted ? <VolumeOff /> : <VolumeUp />}
                    </IconButton>
                    <IconButton
                        size='small'
                        disabled={member.readToSegment === 0}
                        color='error'
                        onClick={() => handleDelete(member)}
                    >
                        <Delete />
                    </IconButton>
                </ButtonGroup>
            </TableCell>
        </TableRow>
    );
};

export default MemberItem;

import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography,
    useMediaQuery,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Stack,
} from '@mui/material';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import UserRole from '../../../../shared/AuthRoles';
import { useState } from 'react';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import { CustomIconButton } from '../../../../layout/styledcomponents/CustomIconButton';
import EditIcon from '@mui/icons-material/Edit';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import CancelReservationModal from '../ViewReservations/CancelReservationModal';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 900,
        color: '#000',
        borderBottom: '1px solid #787878',
        paddingLeft: '0px',
    },
    [`& .${gridClasses.row}`]: {
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                },
            },
        },
    },
}));
export default function RecurringReservationRequestSubmittedContent(params: any) {
    const [open, setOpen] = useState(false);
    const loggedInUser = UserRole();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const [expandedPanel1, setExpandedPanel1] = useState<any>(true);

    let getReservationDetails = params?.reservationDetails;
    let getIndividualAsset = params?.experience;
    const recurringId = params?.reservationDetails?.map((i: any) => i?.recurringId);
    const recurringCreatedDate = params?.reservationDetails?.map(
        (i: any) => i?.createdAt
    );
    const recurringReason = params?.reservationDetails?.map((i: any) => i?.reason);
    const recurringNotes = params?.reservationDetails?.map((i: any) => i?.notes);
    const bookedBy = params?.reservationDetails?.map(
        (i: any) => `${i.booker?.firstName} ${i.booker?.lastName}`
    );

    let createdAtDate = moment(recurringCreatedDate[0]).format('DD/MM/YYYY');

    const fullAddress = `${getIndividualAsset?.streetAddress1} ${getIndividualAsset?.streetAddress2} ${getIndividualAsset?.suburb} ${getIndividualAsset?.postcode} ${getIndividualAsset?.state} ${getIndividualAsset?.countryCode}`;

    //Cancellation Modal

    const onClickOpenModal = () => {
        setOpen(true);
    };
    const handleChangeModal = () => {
        setOpen(false);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'moduleId',
            headerName: 'Experience',
            width: isMobile ? 150 : isIpad ? 150 : 250,
            type: 'string',
            sortable: false,
            renderCell: () => {
                return (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {getIndividualAsset?.name}
                    </Typography>
                );
            },
        },
        {
            field: 'moduleType',
            headerName: 'Type',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            type: 'string',
            sortable: false,
            renderCell: () => {
                return (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {getIndividualAsset?.type.charAt(0)?.toUpperCase() +
                            getIndividualAsset?.type?.slice(1)}
                    </Typography>
                );
            },
        },

        {
            field: 'startDate',
            headerName: 'Start Date',
            width: isMobile ? 150 : isIpad ? 150 : 100,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {moment(params?.value).format('DD/MM/YYYY')}
                    </Typography>
                );
            },
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            width: isMobile ? 150 : isIpad ? 150 : 100,
            type: 'Date',
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {moment(params?.value).format('DD/MM/YYYY')}
                    </Typography>
                );
            },
        },

        {
            field: 'id',
            headerName: 'Reservation Ref:',
            width: isMobile ? 150 : isIpad ? 150 : 100,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        # {params?.value}
                    </Typography>
                );
            },
        },
        {
            field: 'bookerType',
            headerName: 'Visit Type',
            width: isMobile ? 150 : isIpad ? 150 : 200,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {params?.value === 'owner' ? 'Blocked' : ''}
                    </Typography>
                );
            },
        },
        {
            field: 'booker',
            headerName: 'Reserved By',
            width: isMobile ? 150 : isIpad ? 150 : 230,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {params?.value.firstName} {params?.value.lastName}
                    </Typography>
                );
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created ',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            type: 'Date',
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {moment(params?.value).format('DD/MM/YYYY')}
                    </Typography>
                );
            },
        },

        {
            field: 'status',
            headerName: 'Status',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return (
                    <Typography
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                        }}
                    >
                        {params?.value.charAt(0).toUpperCase() +
                            params?.value.slice(1)}
                    </Typography>
                );
            },
        },

        {
            field: 'action',
            headerName: '',
            width: isMobile ? 50 : isIpad ? 50 : 50,
            sortable: false,
            renderCell: (params) => {
                const onClick = (e: any) => {
                    // redirect to individual reservation
                    const currentRowId = params?.id;

                    navigate(
                        `/reservations/${getIndividualAsset?.name
                            .replace(/ /g, '')
                            .toLowerCase()}/${currentRowId}`,
                        {
                            state: { page: 'individualExperience' },
                        }
                    );
                };

                return (
                    <CustomIconButton
                        sx={{
                            backgroundColor: 'transparent',
                            width: '25px',
                            height: '25px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                        onClick={onClick}
                    >
                        <EditIcon fontSize='small' sx={{ fontSize: '16px' }} />
                    </CustomIconButton>
                );
            },
        },
    ];

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        // redirect to individual reservation
        const currentRowId = params?.id;
        navigate(
            `/reservations/${getIndividualAsset?.name
                .replace(/ /g, '')
                .toLowerCase()}/${currentRowId}`,
            {
                state: { page: 'individualExperience' },
            }
        );
    };

    return (
        <Grid
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
            }}
        >
            <Grid
                sx={{
                    // backgroundColor: isMobile ? 'pink' : 'gray',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    // paddingTop: '30px',
                    // width: isIpad ? '80%' : isMobile ? null : '%',
                }}
            >
                <Grid
                    sx={{
                        width: isMobile ? '100%' : '100%',
                        // backgroundColor: 'brown',
                        display: 'flex',
                        flexDirection: 'column',
                        height: isMobile ? 'auto' : '90%',
                    }}
                >
                    <Grid
                        sx={{
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // backgroundColor: 'yellow',
                        }}
                    >
                        {' '}
                        <Grid
                            sx={{
                                // backgroundColor: 'red',
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: isMobile ? '' : 'flex-end',
                                paddingBottom: '30px',
                            }}
                        >
                            <Grid
                                sx={{
                                    width: '100%',
                                    marginLeft: isMobile ? '-15px' : '11px',
                                }}
                            >
                                <List>
                                    <ListItem
                                        sx={{
                                            paddingBottom: isMobile
                                                ? '0px'
                                                : '30px',
                                            textAlign: isMobile
                                                ? 'left'
                                                : 'right',
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        paddingTop: '0px',
                                                        // paddingBottom: '30px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    RECURRING RESERVATION REFERENCE:{' '}
                                                    {recurringId[0]}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    Created {''} {createdAtDate}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>{' '}
                            </Grid>
                        </Grid>
                        {/* Accordion GRID */}
                        <Grid>
                            <Accordion
                                expanded={expandedPanel1}
                                onChange={() =>
                                    setExpandedPanel1(!expandedPanel1)
                                }
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'
                                    sx={{
                                        backgroundColor:
                                            expandedPanel1 === false
                                                ? '#f6f8f9'
                                                : '',
                                    }}
                                >
                                    <Typography
                                        component='h3'
                                        variant='h6'
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            fontWeight: 550,
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Details
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <>
                                        <Grid>
                                            <Divider
                                                sx={{
                                                    marginTop: '0px',
                                                }}
                                            />
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    paddingTop: '10px',
                                                }}
                                            >
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Experience:
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {
                                                            getIndividualAsset?.name
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Booked By:
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {' '}
                                                        {bookedBy[0]} via 7DAY
                                                        AI Chat Assistant
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Location:
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {' '}
                                                        <Link
                                                            style={{
                                                                color: '#787878',
                                                            }}
                                                            target='_blank'
                                                            to={`https://www.google.com/maps/place/${fullAddress}`}
                                                        >
                                                            {' '}
                                                            {fullAddress}
                                                        </Link>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Reason:
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {recurringReason[0]
                                                            ?.charAt(0)
                                                            ?.toUpperCase() +
                                                            recurringReason[0].slice(
                                                                1
                                                            )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{ display: 'flex' }}>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '30%'
                                                            : '20%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontWeight: 'bold',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        Notes:
                                                    </Typography>{' '}
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        width: isMobile
                                                            ? '70%'
                                                            : '80%',
                                                    }}
                                                >
                                                    {' '}
                                                    <Typography
                                                        sx={{
                                                            fontFamily:
                                                                'Open Sans',
                                                            color: '#787878',
                                                            paddingBottom:
                                                                '10px',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {recurringNotes[0]}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {/* Returned Reservations Grid */}
                        <Grid
                            sx={{
                                height: 'auto',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center',
                                justifyContent: 'center',
                                marginTop: '30px',
                            }}
                        >
                            <StripedDataGrid
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0
                                        ? 'even'
                                        : 'odd'
                                }
                                onRowClick={handleRowClick}
                                sx={{
                                    boxShadow: 0,
                                    border: 0,
                                    '& .MuiDataGrid-cell': {
                                        padding: '0',
                                    },
                                }}
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            bookerType:
                                                loggedInUser?.role.includes(
                                                    'owner'
                                                ),
                                        },
                                    },
                                }}
                                disableColumnFilter
                                disableColumnSelector
                                hideFooterPagination
                                disableColumnMenu
                                disableDensitySelector
                                disableRowSelectionOnClick
                                rows={
                                    getReservationDetails ? getReservationDetails : []
                                }
                                columns={columns}
                                getRowId={(row: any) => row?.id}
                                components={{
                                    NoRowsOverlay: () => (
                                        <Stack
                                            height='100%'
                                            alignItems='center'
                                            justifyContent='center'
                                        >
                                            <List>
                                                <ListItem
                                                    sx={{
                                                        padding: '0!important',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                }}
                                                            >
                                                                No Results
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    fontSize:
                                                                        '12px',
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                            >
                                                                Please try the
                                                                request again
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        </Stack>
                                    ),
                                    NoResultsOverlay: () => (
                                        <Stack
                                            height='100%'
                                            alignItems='center'
                                            justifyContent='center'
                                        >
                                            <List>
                                                <ListItem
                                                    sx={{
                                                        padding: '0!important',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                }}
                                                            >
                                                                No Results
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        'Open Sans',
                                                                    color: '#787878',
                                                                    fontSize:
                                                                        '12px',
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                            >
                                                                Please try again
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        </Stack>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            height: 'auto',
                            paddingBottom: '50px',
                        }}
                    >
                        <Grid
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Grid
                                sx={{
                                    paddingLeft: isMobile ? '5px' : '20px',
                                }}
                            >
                                <CustomButton
                                    variant='contained'
                                    color='warning'
                                    // autoFocus
                                    onClick={onClickOpenModal}
                                >
                                    <Typography>
                                        Cancel{' '}
                                        {isMobile ? '' : 'Recurring Reservation'}{' '}
                                    </Typography>
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <CancelReservationModal
                open={open}
                onClose={handleCloseModal}
                onChange={handleChangeModal}
                message={'cancel this recurring reservation'}
                component={'recurringReservation'}
                id={recurringId?.[0]}
            />
        </Grid>
    );
}

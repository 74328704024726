import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import {  CustomSmallListChip } from '../../../layout/styledcomponents/CustomChip';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import React from 'react';
import UserRole from '../../../shared/AuthRoles';
import ProfilePicture from '../Profile/ProfilePicture';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 900,
        color: '#000',
        borderBottom: '1px solid #787878',
        // textTransform: 'upperCase'
    },
    [`& .${gridClasses.row}`]: {
        // backgroundColor: '#f6f8f9',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            // fontWeight: 'bold',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                    // color: 'red',
                },
            },
        },
    },
}));


export default function ActiveUserList({ team }: any) {
    const activeUsers = team?.activeUsers;
    const loggedInUser = UserRole();
    const allActiveUsers: any[] = activeUsers?.map((user: any) => {
        return user;
    });

    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const columns: GridColDef[] = [
        {
            field: 'firstName',
            headerName: 'Active User',
            type: 'string',
            // width: isMobile ? 261 : isIpad ? 735 : 1327,
            flex: 1,
            minWidth: 261,
            sortable: true,
            renderCell: (params) => {
                return (
                    <List>
                        <ListItem sx={{ padding: '0!important' }}>
                            {isMobile ? null : (
                                <ListItemAvatar>
                                    <ProfilePicture 
                                        profileDetails={params?.row} 
                                        src={params?.row?.profilePicture?.src}
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            fontSize: '14px'
                                        }}
                                    />
                                </ListItemAvatar>
                            )}
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {params?.value} {params?.row.lastName} -{' '}
                                        <CustomSmallListChip
                                            size='small'
                                            style={{
                                                fontFamily: 'Open Sans',
                                                backgroundColor:
                                                    params?.row.role?.includes([
                                                        'admin',
                                                    ]) ||
                                                    loggedInUser?.role ===
                                                        'admin'
                                                        ? '#5cb5b3'
                                                        : '#E0E0E0',
                                                color:
                                                    params?.row.role?.includes([
                                                        'admin',
                                                    ]) ||
                                                    loggedInUser?.role ===
                                                        'admin'
                                                        ? '#fff'
                                                        : '#787878',
                                            }}
                                            label={
                                                params?.row.role ===
                                                    'owner_admin' ||
                                                loggedInUser?.role === 'admin'
                                                    ? 'ADMIN'
                                                    : params?.row.role ===
                                                      'guest_admin'
                                                    ? 'COMPANY ADMIN'
                                                    : params?.row.role ===
                                                      'guest_department_admin'
                                                    ? 'TEAM ADMIN'
                                                    : params?.row?.viewOnly ===
                                                      true
                                                    ? 'USER (VIEW ONLY)'
                                                    : 'USER'
                                            }
                                            variant='filled'
                                        />
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {params?.row.email}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                );
            },
        },

        {
            field: 'phone',
            headerName: 'Phone',
            type: 'string',
            width: isMobile ? 180 : isIpad ? 180 : 180,
            renderCell: (params) => {
                return params?.value
            },
            sortable: true,
        },
    ];

    return (
        <Box sx={{ height: 500, width: '100%' }}>
            <StripedDataGrid
                // onRowClick={handleRowClick}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                sx={{
                    boxShadow: 0,
                    border: 0,
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={allActiveUsers !== undefined ? allActiveUsers : []}
                columns={columns}
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Users
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                No users belong to this team
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Results
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please try again
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
}

import { Avatar, Box, Fade, IconButton, Popover, Tooltip, Typography, styled } from '@mui/material'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import UserRole from '../../../../shared/AuthRoles'
import { MessageContentType, MessageRepository } from '@amityco/ts-sdk'
import TextContent from './TextContent'
import ImageContent from './ImageContent'
import FileContent from './FileContent'
import { theme } from '../../../../shared/theme'
import { Photo } from '../../../types/user'
import { DeleteOutline } from '@mui/icons-material'
import DeleteModal from './DeleteModal'

type MessageData = string | Amity.ContentDataText | Amity.ContentDataFile | Amity.ContentDataImage | Amity.ContentDataVideo | Amity.ContentDataPoll | Record<string, any>

interface MessageItemProps {
    messageId: string,
    data?: MessageData,
    dataType?: Amity.MessageContentType,
    creatorId: string,
    createdAt: string
    myReactions?: string[],
    user: {
        name: string,
        profilePicture: Photo
    },
    hasNext: boolean,
}

interface MessageBoxProps {
    self: boolean,
}

const MessageBox = styled(Box)<MessageBoxProps>(({ self }) => ({
    backgroundColor: self ? '#5cb5b3' : '#f6f6f6',
    padding: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    borderBottomLeftRadius: self ? theme.spacing(2) : 0,
    borderBottomRightRadius: self ? 0 : theme.spacing(2),
    color: self ? 'white': 'black'
}));

const MessageItem = ({ 
    messageId,
    data, 
    dataType,
    creatorId, 
    createdAt,
    user,
    hasNext,
}: MessageItemProps) => {
    const loggedInUser = UserRole();

    // delete confirmation modal
    const [open, setOpen] = useState<boolean>(false);
    
    const formatDate = (date: string) => {
        return moment(date).format('h:mm A')
    };

    const self: boolean = loggedInUser?.sub.toString() === creatorId;

    const [hover, setHover] = useState<boolean>(false);

    return (
        <>
            <Box 
                display='flex'
                maxWidth='90%'
                alignSelf={self ? 'flex-end' : 'flex-start'}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <Box 
                    display='flex' 
                    flexDirection='column' 
                    alignItems={self ? 'flex-end' : 'flex-start'}
                >
                    <Box display='flex' gap={1} alignItems='flex-end'>
                        {!self && (
                            !hasNext ? (
                                <Tooltip title={user?.name}>
                                    <Avatar src={user?.profilePicture?.src}/>
                                </Tooltip>
                            ) : (
                                <Box width='40px' />
                            )
                        )}
                        {self && (
                            <Fade in={hover} unmountOnExit>
                                <IconButton
                                    onClick={() => setOpen(true)}
                                >
                                    <DeleteOutline />
                                </IconButton>
                            </Fade>
                            
                        )}
                        <MessageBox self={self}>
                            {dataType === MessageContentType.TEXT && (
                                <TextContent data={data as Amity.ContentDataText} />
                            )}
                            {dataType === MessageContentType.IMAGE && (
                                <ImageContent data={data as Amity.ContentDataImage}/>
                            )}
                            {dataType === MessageContentType.FILE && (
                                <FileContent data={data as Amity.ContentDataFile}/>
                            )}
                        </MessageBox>
                    </Box>
                    
                    {!hasNext && (
                        <Typography 
                            color='textSecondary'
                            fontSize='12px'
                            pl={self ? 0 : '48px'}
                        >
                            {`${user?.name.split(' ')[0] || '7DAY User'} - ${formatDate(createdAt)}`}
                        </Typography>
                    )}
                </Box>
            </Box>
            <DeleteModal 
                messageId={messageId}
                open={open}
                onClose={() => setOpen(false)}
            />
        </>
        
    )
}

export default MessageItem
import React from 'react';

import { Controller, Control } from 'react-hook-form';
import {
    Autocomplete,
    AutocompleteProps,
    Checkbox,
    TextField,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

interface FormAutocompleteProps<T>
    extends Omit<
        AutocompleteProps<T, true, true, boolean | undefined>,
        'renderInput'
    > {
    name: string;
    options: Array<any>;
    label?: string;
    customError?: string;
    control: Control<any>;
    placeholder?: string;
    getOptionDisabled?: (option: any) => boolean;
    filterOptions?: (options: Array<any>, state: object) => Array<any>;
}

const FormMultiAutocomplete = <T,>({
    name,
    control,
    label,
    options,
    customError,
    placeholder,
    ...props
}: FormAutocompleteProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, ref },
                fieldState: { error },
            }) => (
                <Autocomplete
                    {...props}
                    size='small'
                    fullWidth
                    multiple
                    disableCloseOnSelect
                    options={options}
                    value={(Array.isArray(value) ? value : [])
                        .map((value: any) =>
                            options.find((option) => option.value === value)
                        )
                        .filter(Boolean)}
                    onChange={(_, selectedOptions) => {
                        onChange(selectedOptions.map((option) => option.value));
                    }}
                    renderOption={(props, option, { selected }) => {
                        return (
                            <li key={option.value} {...props}>
                                <Checkbox
                                    size='small'
                                    sx={{ mr: '8px' }}
                                    checked={selected}
                                />
                                {option.label}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            inputRef={ref}
                            error={!!error || !!customError}
                            helperText={(error && error.message) || customError}
                            placeholder={placeholder}
                        />
                    )}
                    popupIcon={<KeyboardArrowDown />}
                />
            )}
        />
    );
};

export default FormMultiAutocomplete;

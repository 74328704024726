import { Badge, FormHelperText, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { Control, Controller, useWatch } from 'react-hook-form';
import { DayPicker, PropsRange, PropsBase } from 'react-day-picker';
import 'react-day-picker/style.css';
import { useGetAllPublicHolidaysForYearAndCountryQuery } from '../../app/Slices/PublicHolidaysSlice';
import { FormValues } from '../types/experience';
import UserRole from '../../shared/AuthRoles';
import { useGetProfileDetailsQuery } from '../../app/Slices/SevenDayApiSlice';

const css = `
    .rdp-root {
        font-family: 'Open Sans';
        --rdp-accent-color: #5cb5b3;
        --rdp-accent-background-color: #f1f9f8;
        --rdp-day_button-width: 40px;
        --rdp-day_button-height: 40px;
        --rdp-day-width: 40px;
        --rdp-day-height: 40px;
        font-size: 14px;
    }

    .rdp-range_middle {
        color: #5cb5b3;
    }

    .rdp-selected {
        font-size: 14px;
    }

    .rdp-footer {
        font-size: 14px;
    }

    .rdp-months {
        flex-direction: column;
    }
`;

export type CheckinDetails = {
    checkinFrom?: string;
    checkoutBefore?: string;
};

type CalendarDay = {
    date: string;
    checkinFrom?: string;
    checkoutBefore?: string;
};

interface CalendarProps extends Omit<PropsBase & PropsRange, 'mode'> {
    name: string;
    control: Control<any>;
    experience?: FormValues;
    availability?: {
        blocked: Array<any>;
        limited: Array<CalendarDay>;
    };
    limitedDates?: Record<string, CheckinDetails>;
}

const FormCalendar = ({
    name,
    control,
    experience,
    availability,
    limitedDates,
    ...props
}: CalendarProps) => {
    const loggedInUser = UserRole();

    const [modifiers, setModifiers] = useState<any>({});

    const date = useWatch({ control, name });

    // Convert blocked dates to a Set for fast lookups
    const blockedDates: Set<string> = new Set(
        availability?.blocked.flatMap((date: any) => [date.from, date.to])
    );

    const getDayButtonProps = (
        date: string,
        modifiers: any
    ): { tooltip: string; dotBadge: boolean } => {
        if (
            minLength &&
            modifiers.selected &&
            !modifiers.range_start &&
            !modifiers.range_end
        ) {
            return { tooltip: minLength, dotBadge: false };
        }

        if (!limitedDates || !limitedDates[date])
            return { tooltip: '', dotBadge: false };

        const details = limitedDates[date];

        if (
            details.checkinFrom &&
            !details.checkoutBefore &&
            highlightCheckinOnly(
                date,
                details.checkinFrom,
                blockedDates,
                experience?.checkinTime
            )
        ) {
            return {
                tooltip: `Check-in from ${details.checkinFrom}`,
                dotBadge: !modifiers.selected && !modifiers.disabled,
            };
        }

        if (
            details.checkoutBefore &&
            !details.checkinFrom &&
            highlightCheckoutOnly(
                date,
                details.checkoutBefore,
                blockedDates,
                experience?.checkoutTime
            )
        ) {
            return {
                tooltip: `Checkout before ${details.checkoutBefore}`,
                dotBadge: !modifiers.selected && !modifiers.disabled,
            };
        }

        if (details.checkoutBefore && details.checkinFrom) {
            return {
                tooltip: `Checkout before ${details.checkoutBefore} Check-in from ${details.checkinFrom} `,
                dotBadge: !modifiers.selected && !modifiers.disabled,
            };
        }
        return { tooltip: '', dotBadge: false };
    };

    const highlightCheckinOnly = (
        date: string,
        checkinFrom: string,
        blockedDates: Set<string>,
        experienceCheckin?: string
    ): boolean => {
        // Do not highlight if checkinFrom is the same as experience checkin time and previous day is blocked
        if (!experienceCheckin) return true;
        const previousDay = new Date(date);
        previousDay.setDate(previousDay.getDate() - 1);
        return (
            !blockedDates.has(format(previousDay, 'yyyy-MM-dd')) ||
            new Date(`${date}T${checkinFrom}`).getTime() >
                new Date(`${date}T${experienceCheckin}`).getTime()
        );
    };

    const highlightCheckoutOnly = (
        date: string,
        checkoutBefore: string,
        blockedDates: Set<string>,
        experienceCheckout?: string
    ): boolean => {
        // Do not highlight if checkoutBefore is the same as experience checkout Time and next day is blocked
        if (!experienceCheckout) return true;
        const nextDay = new Date(date);
        nextDay.setDate(nextDay.getDate() + 1);
        return (
            !blockedDates.has(format(nextDay, 'yyyy-MM-dd')) ||
            new Date(`${date}T${checkoutBefore}`).getTime() <
                new Date(`${date}T${experienceCheckout}`).getTime()
        );
    };

    const { data: publicHolidaysAustraliaCurrentYear } =
        useGetAllPublicHolidaysForYearAndCountryQuery({
            country: 'AU',
            year: new Date().getFullYear(),
        });
    const { data: publicHolidaysAustraliaNextYear } =
        useGetAllPublicHolidaysForYearAndCountryQuery({
            country: 'AU',
            year: new Date().getFullYear() + 1,
        });
    const { data: publicHolidaysAustraliaYearAfterNextYear } =
        useGetAllPublicHolidaysForYearAndCountryQuery({
            country: 'AU',
            year: new Date().getFullYear() + 2,
        });

    const publicHolidaysAustralia =
        publicHolidaysAustraliaCurrentYear &&
        publicHolidaysAustraliaNextYear &&
        publicHolidaysAustraliaYearAfterNextYear
            ? [
                  ...publicHolidaysAustraliaCurrentYear,
                  ...publicHolidaysAustraliaNextYear,
                  ...publicHolidaysAustraliaYearAfterNextYear,
              ]
            : [];

    const filteredPublicHolidaysAustralia = publicHolidaysAustralia
        ?.filter((data) => {
            return (
                data.global || data.counties.includes(`AU-${experience?.state}`)
            );
        })
        .map((data) => new Date(data.date));

    const { data: profileDetails } = useGetProfileDetailsQuery('/profile');
    let beckFamily = profileDetails?.companyName
        ?.toLowerCase()
        .includes('beck');

    const availableDays = {
        sunday: experience?.sunday,
        monday: experience?.monday,
        tuesday: experience?.tuesday,
        wednesday: experience?.wednesday,
        thursday: experience?.thursday,
        friday: experience?.friday,
        saturday: experience?.saturday,
    };

    // Map day names to their corresponding numeric day of the week
    const dayToNumber: Record<string, number> = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
    };

    const dayOfWeek = Object.entries(availableDays)
        .filter(([, available]) => !available)
        .map(([day]) => dayToNumber[day]);

    const getCheckinDates = () => {
        if (!availability || !experience) return;

        const checkoutOnly = availability.limited
            .filter((d: CalendarDay) => d.checkoutBefore && !d.checkinFrom)
            .map((d: CalendarDay) => new Date(d.date));

        const checkinOnly = availability.limited
            .filter((d: CalendarDay) => d.checkinFrom)
            .map((d: CalendarDay) => new Date(d.date));

        setModifiers({
            limited: [...checkinOnly, ...checkoutOnly],
            disabled: [
                {
                    before: new Date(
                        new Date().getTime() + 24 * 60 * 60 * 1000
                    ),
                },
                ...availability.blocked,
                ...(loggedInUser?.role.includes('owner') || beckFamily
                    ? []
                    : experience.publicHolidays
                    ? []
                    : filteredPublicHolidaysAustralia),
                !(loggedInUser?.role.includes('owner') || beckFamily) && {
                    dayOfWeek,
                },
            ],
        });
    };

    const getCheckoutDates = () => {
        if (!availability || !experience) return;

        const checkoutOnly = availability.limited
            .filter((d: CalendarDay) => d.checkoutBefore)
            .map((d: CalendarDay) => new Date(d.date));

        const checkinOnly = availability.limited
            .filter(
                (d: CalendarDay) =>
                    d.checkinFrom &&
                    !d.checkoutBefore &&
                    // exclude current day to allow for single day bookings
                    new Date(new Date(d.date).setHours(0)).getTime() !==
                        date?.from.getTime()
            )
            .map((d: CalendarDay) => new Date(d.date));

        const nextCheckout = checkoutOnly.find(
            (checkout) => checkout > date?.from
        );

        const maxHire = new Date(date?.from);
        if (experience.maxHireHours > 24)
            maxHire.setHours(maxHire.getHours() + experience.maxHireHours - 24);

        const blockAfter =
            loggedInUser?.role.includes('owner') || beckFamily
                ? nextCheckout
                : nextCheckout && maxHire > nextCheckout
                ? nextCheckout
                : maxHire;

        setModifiers({
            limited: checkoutOnly,
            disabled: [
                {
                    before: date?.from,
                    after: blockAfter,
                },
                ...availability.blocked,
                ...checkinOnly,
                ...(loggedInUser?.role.includes('owner') || beckFamily
                    ? []
                    : experience.publicHolidays
                    ? []
                    : filteredPublicHolidaysAustralia),
                !(loggedInUser?.role.includes('owner') || beckFamily) && {
                    dayOfWeek,
                },
            ],
        });
    };

    useEffect(() => {
        getCheckinDates();

        if (date?.from) getCheckoutDates();
    }, [availability, date]);

    const min = !(loggedInUser?.role.includes('owner') || beckFamily)
        ? experience && (experience.minHireHours - 24) / 24
        : undefined;

    const minLength =
        experience &&
        `${
            experience.type === 'commercial'
                ? `${experience?.minHireHours / 24} day`
                : experience?.minHireHours === 24
                ? `${experience?.minHireHours / 24} day`
                : experience?.minHireHours === 48
                ? `${(experience?.minHireHours - 24) / 24} night`
                : experience?.minHireHours > 24
                ? `${(experience?.minHireHours - 24) / 24} night`
                : `${experience?.minHireHours} hour`
        } minimum`;

    const max = !(loggedInUser?.role.includes('owner') || beckFamily)
        ? experience && experience.maxHireHours > 24
            ? (experience.maxHireHours - 24) / 24
            : 1
        : undefined;

    return (
        <>
            <style>{css}</style>
            <Controller
                name={name}
                control={control}
                render={({ field: { value }, fieldState: { error } }) => (
                    <>
                        <DayPicker
                            {...props}
                            mode='range'
                            selected={value}
                            modifiers={modifiers}
                            modifiersStyles={{
                                disabled: { color: '#aeaeae' },
                                today: { color: '' },
                            }}
                            min={min}
                            max={max}
                            disabled={modifiers.disabled}
                            excludeDisabled
                            components={{
                                DayButton: (props) => {
                                    const { day, modifiers, ...buttonProps } =
                                        props;

                                    const date = format(day.date, 'yyyy-MM-dd');

                                    const { tooltip, dotBadge } =
                                        getDayButtonProps(date, modifiers);

                                    return (
                                        <>
                                            <Badge
                                                variant={
                                                    dotBadge
                                                        ? 'dot'
                                                        : 'standard'
                                                }
                                                overlap='circular'
                                                sx={{
                                                    '.MuiBadge-badge': {
                                                        bgcolor: 'text.primary',
                                                        minWidth: 4,
                                                        height: 4,
                                                    },
                                                }}
                                            >
                                                <Tooltip title={tooltip}>
                                                    <button
                                                        {...buttonProps}
                                                        style={{
                                                            ...(modifiers.disabled && {
                                                                textDecoration:
                                                                    'line-through',
                                                            }),
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Badge>
                                        </>
                                    );
                                },
                            }}
                        />

                        {!!error && (
                            <FormHelperText error>
                                {error.message}
                            </FormHelperText>
                        )}
                    </>
                )}
            />
        </>
    );
};

export default FormCalendar;

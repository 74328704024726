import { Dialog, DialogContent, useMediaQuery } from '@mui/material'
import { FileRepository } from '@amityco/ts-sdk'
import React from 'react'

interface ImageModalProps {
    open: boolean,
    onClose: () => void,
    src: string,
};

const ImageModal = ({ open, onClose, src }: ImageModalProps) => {
    const isMobile = useMediaQuery('(max-width: 1200px)');

    return (
        <Dialog
            open={open} 
            onClose={onClose}
            fullScreen={isMobile}
        >
            <DialogContent sx={{width: 'fit-content', height: 'fit-content'}}>
                <img src={FileRepository.fileUrlWithSize(src, 'full')}/>
            </DialogContent>
        </Dialog>
    )
}

export default ImageModal
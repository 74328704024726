import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialRequiredPrices: { value: any[] } = {
    value: [],
};

export const priceRequiredSlice = createSlice({
    name: 'priceRequired',
    initialState: initialRequiredPrices,
    reducers: {
        setPriceRequired: (state, action: PayloadAction<any[]>) => {
            state.value = action.payload;
        },
    },
});

export const { setPriceRequired } = priceRequiredSlice.actions;

export const selectPriceRequired = (state: RootState) =>
    state.priceRequired.value;

export const priceRequiredReducer = priceRequiredSlice.reducer;
import { LocationOnOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

interface LocationProps {
    experience?: {
        streetAddress1: string,
        streetAddress2: string,
        suburb: string,
        state: string,
        postcode: string,
    }
}

const Location = ({ experience }: LocationProps) => {
  return (
        <Box 
            display='flex' 
            gap={2}
            alignItems='center'
        >
            <LocationOnOutlined color='disabled' fontSize='large'/>
            <Box>
                <Typography>
                    {`${experience?.streetAddress1} ${experience?.streetAddress2}`}
                </Typography>
                <Typography color='textSecondary' variant='subtitle2'>
                    {`${experience?.suburb} ${experience?.state}, ${experience?.postcode}`}
                </Typography>
            </Box>
        </Box>
  )
}

export default Location
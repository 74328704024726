import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function CreateTeam() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                       First you will need to create a 'Team' at a global level. Only 'Company Admins' can create teams.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        To create the Team:
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top right corner of the app, click on the cog wheel icon and select 'Team'
                            </li>
                            <li>Click 'Add'</li>
                            <li>Enter the Team name and save</li>
                        </ol>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

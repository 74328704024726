import {
    Box,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid';
import React from 'react';
import UserRole from '../../../../shared/AuthRoles';
import LinkCell from '../../../shared/LinkCell';
import StripedDataGrid from '../../../shared/StripedDataGrid';
import moment from 'moment-timezone';

const BillingReservationsList = (params: any) => {
    const loggedInUser = UserRole();
    const apiRef = useGridApiRef();

    const renderLinkCell = (params: any) => (
        <LinkCell
            params={params}
            href={`/reservations/${params.row.moduleName
                .replace(/ /g, '')
                .toLowerCase()}/${params.row.id}`}
            state={{
                name: 'viewReservation',
                component: 'reports',
                url: 'reports/reservationsReport',
                params: params.row,
            }}
        />
    );

    const columns: GridColDef[] = [
        {
            field: 'moduleName',
            headerName: 'Experience',
            flex: 1,
            minWidth: 220,
            type: 'string',
            sortable: true,
            renderCell: renderLinkCell,
        },

        {
            field: 'startDate',
            headerName: 'Start Date',
            flex: 1,
            minWidth: 100,
            type: 'string',
            sortable: true,
            valueGetter: (params) => {
                return params?.value
                    ? moment(params?.value).format('ll')
                    : 'N/A';
            },
            renderCell: renderLinkCell,
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            flex: 1,
            minWidth: 100,
            type: 'string',
            sortable: true,
            valueGetter: (params) => {
                return params?.value
                    ? moment(params?.value).format('ll')
                    : 'N/A';
            },
            renderCell: renderLinkCell,
        },
        {
            field: 'bookedFor',
            headerName: 'Booked For',
            flex: 1,
            minWidth: 150,
            type: 'string',
            sortable: true,
            valueGetter: (params) => {
                return params.row.booker
                    ? `${params.row.booker.firstName} ${params.row.booker.lastName}`
                    : 'N/A';
            },
            renderCell: renderLinkCell,
        },
        {
            field: 'bookedBy',
            headerName: 'Booked By',
            flex: 1,
            minWidth: 150,
            type: 'string',
            sortable: true,
            valueGetter: (params) => {
                return params.row.createdBy
                    ? `${params.row.createdBy.firstName} ${params.row.createdBy.lastName}`
                    : 'N/A';
            },
            renderCell: renderLinkCell,
        },
        {
            field: 'owner',
            headerName: 'Host',
            flex: 1,
            minWidth: 150,
            type: 'string',
            sortable: true,
            renderCell: renderLinkCell,
        },
    ];

    return (
        <Box sx={{ width: '100%', paddingTop: '20px' }}>
            <StripedDataGrid
                apiRef={apiRef}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            bookerType: loggedInUser?.role.includes('owner'),
                            guestName: loggedInUser?.role.includes('owner'),
                        },
                    },
                }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                sx={{
                    boxShadow: 0,
                    border: 0,
                    '& .MuiDataGrid-cell': {
                        padding: '0',
                    },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={params?.data !== undefined ? params?.data : []}
                columns={columns}
                slots={{
                    noRowsOverlay: () => (
                        <Stack
                            sx={{
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    color: 'text.primary',
                                                }}
                                            >
                                                No Records
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    color: 'text.primary',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                There are no reservations based
                                                on your filter selection
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Stack>
                    ),
                    noResultsOverlay: () => (
                        <Stack
                            sx={{
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    color: 'text.primary',
                                                }}
                                            >
                                                No Results
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    color: 'text.primary',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please try again
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
};

export default BillingReservationsList;

import {
    Box,
    Grid,
    ListItemText,
    Paper,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetReportQuery } from '../../../../app/Slices/SevenDayApiSlice';
import {
    PieChart,
    pieArcLabelClasses,
    pieArcClasses,
} from '@mui/x-charts/PieChart';
import { useNavigate } from 'react-router-dom';
import { CustomSwitch } from '../../../../layout/styledcomponents/CustomSwitch';
import UserRole from '../../../../shared/AuthRoles';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import ReactGA from 'react-ga4';

const size = {
    height: 200,
    margin: { right: 0 },
};

export default function OwnerAdminDashboardLargerStats() {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const [dashboardData, setDashboardData] = useState<any>([]);
    const [checked, setChecked] = React.useState<boolean>(false);

    //GET REPORT

    const { data: getCurrentWeekStats, isFetching }: any = useGetReportQuery(
        `/dashboard/scheduled-bookings?period=current-week`
    );
    const { data: getNextWeekStats }: any = useGetReportQuery(
        `/dashboard/scheduled-bookings?period=next-week`
    );
    const { data: getCompletedThisMonth }: any = useGetReportQuery(
        `/dashboard/completed-bookings?period=current-month`
    );
    const { data: getCompletedThisYear }: any = useGetReportQuery(
        `/dashboard/completed-bookings?period=current-year`
    );

    //GET LIST OF GUESTS

    //Booker Company /Booker User- This Week
    let bookerThisWeek: any = getCurrentWeekStats?.map((val: any) => {
        return loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_user'
            ? val?.bookerCompany
            : val?.bookedBy;
    });

    const elementCountsThisWeek: any = {};

    bookerThisWeek?.forEach((element: any) => {
        elementCountsThisWeek[element] =
            (elementCountsThisWeek[element] || 0) + 1;
    });

    let bookerListThisWeek: any = [];

    Object.entries(elementCountsThisWeek)?.forEach(([key, value]) => {
        elementCountsThisWeek.value = true;

        bookerListThisWeek.push({ label: key, value: value });
    });

    //Booker Company /Booker User - next week
    let bookerNextWeek: any = getNextWeekStats?.map((val: any) => {
        return loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_user'
            ? val?.bookerCompany
            : val?.bookedBy;
    });

    const elementCountsNextWeek: any = {};

    bookerNextWeek?.forEach((element: any) => {
        elementCountsNextWeek[element] =
            (elementCountsNextWeek[element] || 0) + 1;
    });

    let bookerListNextWeek: any = [];

    Object.entries(elementCountsNextWeek)?.forEach(([key, value]) => {
        elementCountsNextWeek.value = true;
        bookerListNextWeek.push({ label: key, value: value });
    });

    //Booker Company /Booker User - This Month
    let bookerThisMonth: any = getCompletedThisMonth?.map((val: any) => {
        return loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_user'
            ? val?.bookerCompany
            : val?.bookedBy;
    });

    const elementCountsThisMonth: any = {};

    bookerThisMonth?.forEach((element: any) => {
        elementCountsThisMonth[element] =
            (elementCountsThisMonth[element] || 0) + 1;
    });

    let bookerListThisMonth: any = [];

    Object.entries(elementCountsThisMonth)?.forEach(([key, value]) => {
        elementCountsThisMonth.value = true;

        bookerListThisMonth.push({ label: key, value: value });
    });

    //Booker Company /Booker User- This Year
    let bookerThisYear: any = getCompletedThisYear?.map((val: any) => {
        return loggedInUser?.role === 'owner_admin' ||
            loggedInUser?.role === 'owner_user'
            ? val?.bookerCompany
            : val?.bookedBy;
    });

    const elementCountsThisYear: any = {};

    bookerThisYear?.forEach((element: any) => {
        elementCountsThisYear[element] =
            (elementCountsThisYear[element] || 0) + 1;
    });

    let bookerListThisYear: any = [];

    Object.entries(elementCountsThisYear)?.forEach(([key, value]) => {
        elementCountsThisYear.value = true;

        bookerListThisYear.push({ label: key, value: value });
    });

    const navigate = useNavigate();
    const handleOpen = (report: any) => {
        if (report.reportName.includes('Scheduled')) {
            navigate(`/dashboard/scheduled/${report.reportUrl}`);
            ReactGA.send({
                hitType: 'pageview',
                page: `/dashboard/scheduled/${report.reportUrl}`,
                title: `Scheduled ${report.reportUrl} Report (Owner)`,
            });
        } else if (report.reportName.includes('Completed')) {
            navigate(`/dashboard/completed/${report.reportUrl}`);
            ReactGA.send({
                hitType: 'pageview',
                page: `/dashboard/completed/${report.reportUrl}`,
                title: `Completed ${report.reportUrl} Report (Owner)`,
            });
        } else {
            //nothing
        }
    };

    useEffect(() => {
        let data: any = [
            {
                reportName: 'Scheduled This Week',
                statNo: getCurrentWeekStats?.length,
                reportUrl: 'current-week',
                experience: [
                    {
                        label: 'Boat',
                        value: getCurrentWeekStats?.filter(
                            (i: any) => i.moduleType === 'boat'
                        )?.length,
                    },
                    {
                        label: 'Vehicle',
                        value: getCurrentWeekStats?.filter(
                            (i: any) => i.moduleType === 'vehicle'
                        )?.length,
                    },
                    {
                        label: 'Property',
                        value: getCurrentWeekStats?.filter(
                            (i: any) => i.moduleType === 'property'
                        )?.length,
                    },
                    {
                        label: 'Activity',
                        value: getCurrentWeekStats?.filter(
                            (i: any) => i.moduleType === 'activity'
                        )?.length,
                    },
                    {
                        label: 'Commercial',
                        value: getCurrentWeekStats?.filter(
                            (i: any) => i.moduleType === 'commercial'
                        )?.length,
                    },
                ],
                booker: bookerListThisWeek,
            },
            {
                reportName: 'Scheduled Next Week',
                statNo: getNextWeekStats?.length,
                reportUrl: 'next-week',
                experience: [
                    {
                        label: 'Boat',
                        value: getNextWeekStats?.filter(
                            (i: any) => i.moduleType === 'boat'
                        )?.length,
                    },
                    {
                        label: 'Vehicle',
                        value: getNextWeekStats?.filter(
                            (i: any) => i.moduleType === 'vehicle'
                        )?.length,
                    },
                    {
                        label: 'Property',
                        value: getNextWeekStats?.filter(
                            (i: any) => i.moduleType === 'property'
                        )?.length,
                    },
                    {
                        label: 'Activity',
                        value: getNextWeekStats?.filter(
                            (i: any) => i.moduleType === 'activity'
                        )?.length,
                    },
                    {
                        label: 'Commercial',
                        value: getNextWeekStats?.filter(
                            (i: any) => i.moduleType === 'commercial'
                        )?.length,
                    },
                ],
                booker: bookerListNextWeek,
            },

            {
                reportName: 'Completed This Month',
                statNo: getCompletedThisMonth?.length,
                reportUrl: 'current-month',
                experience: [
                    {
                        label: 'Boat',
                        value: getCompletedThisMonth?.filter(
                            (i: any) => i.moduleType === 'boat'
                        )?.length,
                    },
                    {
                        label: 'Vehicle',
                        value: getCompletedThisMonth?.filter(
                            (i: any) => i.moduleType === 'vehicle'
                        )?.length,
                    },
                    {
                        label: 'Property',
                        value: getCompletedThisMonth?.filter(
                            (i: any) => i.moduleType === 'property'
                        )?.length,
                    },
                    {
                        label: 'Activity',
                        value: getCompletedThisMonth?.filter(
                            (i: any) => i.moduleType === 'activity'
                        )?.length,
                    },
                    {
                        label: 'Commercial',
                        value: getCompletedThisMonth?.filter(
                            (i: any) => i.moduleType === 'commercial'
                        )?.length,
                    },
                ],
                booker: bookerListThisMonth,
            },
            {
                reportName: 'Completed This Year',
                statNo: getCompletedThisYear?.length,
                reportUrl: 'current-year',
                experience: [
                    {
                        label: 'Boat',
                        value: getCompletedThisYear?.filter(
                            (i: any) => i.moduleType === 'boat'
                        )?.length,
                    },
                    {
                        label: 'Vehicle',
                        value: getCompletedThisYear?.filter(
                            (i: any) => i.moduleType === 'vehicle'
                        )?.length,
                    },
                    {
                        label: 'Property',
                        value: getCompletedThisYear?.filter(
                            (i: any) => i.moduleType === 'property'
                        )?.length,
                    },
                    {
                        label: 'Activity',
                        value: getCompletedThisYear?.filter(
                            (i: any) => i.moduleType === 'activity'
                        )?.length,
                    },
                    {
                        label: 'Commercial',
                        value: getCompletedThisYear?.filter(
                            (i: any) => i.moduleType === 'commercial'
                        )?.length,
                    },
                ],
                booker: bookerListThisYear,
            },
        ];
        setDashboardData(data);
    }, [
        getCompletedThisMonth,
        getCompletedThisYear,
        getCurrentWeekStats,
        getNextWeekStats,
    ]);

    const orderByBooker = (
        value: boolean | ((prevState: boolean) => boolean)
    ) => {
        setChecked(value);
    };

    return (
        <Box sx={{ width: '100%', paddingTop: '50px' }}>
            {loggedInUser?.role === 'guest_user' ? null : (
                <Grid
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingBottom: '20px',
                    }}
                >
                    {' '}
                    {checked === true ? (
                        <CustomSwitch
                            variant='contained'
                            color='green'
                            onChange={() => orderByBooker(false)}
                            sx={{ marginRight: '5px' }}
                            label={`${
                                loggedInUser?.role.includes('owner')
                                    ? 'Grouped By: Company'
                                    : 'Grouped By: User'
                            }`}
                        />
                    ) : (
                        <CustomSwitch
                            variant='contained'
                            color='green'
                            onChange={() => orderByBooker(true)}
                            sx={{ marginRight: '5px' }}
                            label={`${
                                loggedInUser?.role.includes('owner')
                                    ? 'Group By: Company'
                                    : 'Group By: User'
                            }`}
                        />
                    )}
                </Grid>
            )}

            {isFetching === true ? (
                <Grid
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}

                    sx={{
                        height: isIpad ? '50vh' : isMobile ? '5px' : '50vh',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    container
                    rowSpacing={6}
                    // columnSpacing={{ xs: 1, sm: 1, md: 3 }}
                    spacing={isMobile ? 6 : 6}
                    direction={isMobile || isIpad ? 'column' : 'row'}
                >
                    {dashboardData?.map((dashboardItem: any) => (
                        <Grid item xs={6} key={dashboardItem?.reportName}>
                            <Paper
                                sx={{
                                    height: '300px',
                                    maxHeight: '350px',
                                    flexGrow: 1,
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            gutterBottom
                                            variant='h5'
                                            component='div'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                textAlign: 'center',
                                                color: '#787878',
                                                '&:hover': {
                                                    color: '#5cb5b3',
                                                },
                                            }}
                                        >
                                            {' '}
                                            {dashboardItem?.reportName}
                                        </Typography>
                                    }
                                    secondary={
                                        <Grid sx={{ marginTop: '-10px' }}>
                                            <Grid>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '12px',
                                                        textAlign: 'center',
                                                        paddingTop: '-10px',
                                                    }}
                                                >
                                                    {checked === true
                                                        ? `${
                                                              loggedInUser?.role.includes(
                                                                  'owner'
                                                              )
                                                                  ? '(by guest)'
                                                                  : '(by user)'
                                                          }`
                                                        : '(by experience)'}
                                                </Typography>
                                            </Grid>
                                            <Grid>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Open Sans',
                                                        color: '#787878',
                                                        fontSize: '16px',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Total:{' '}
                                                    {dashboardItem?.statNo}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                                <Grid onClick={() => handleOpen(dashboardItem)}>
                                    {' '}
                                    <PieChart
                                        // onClick={() => handleOpen(dashboardItem)}
                                        tooltip={{ trigger: 'item' }}
                                        colors={[
                                            '#A7C7E7',
                                            '#93C572',
                                            '#3b6978',
                                        ]} // Use palette
                                        series={[
                                            {
                                                arcLabel: (item) =>
                                                    `${item?.label} (${item?.value})`,
                                                arcLabelMinAngle: 45,
                                                // @ts-ignore
                                                data: checked
                                                    ? dashboardItem?.booker
                                                    : dashboardItem?.experience,
                                                highlightScope: {
                                                    faded: 'global',
                                                    highlighted: 'item',
                                                },
                                                // faded: { innerRadius: 5, additionalRadius: -0 },
                                                color: '#5cb5b3',
                                                innerRadius: 10,
                                                outerRadius: 100,
                                                paddingAngle: 1,
                                                cornerRadius: 5,
                                                startAngle: -90,
                                                endAngle: 360,
                                                // cx: 150,
                                                // cy: 150,
                                            },
                                        ]}
                                        sx={{
                                            [`& .${pieArcLabelClasses.root}`]: {
                                                fill: 'white',
                                                fontWeight: 'bold',
                                            },
                                            [`& .${pieArcClasses.faded}`]: {
                                                fill: 'gray',
                                            },
                                        }}
                                        {...size}
                                    />
                                </Grid>
                            </Paper>{' '}
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
}

import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import jwt_decode from 'jwt-decode';
import median from 'median-js-bridge';
import React, { SetStateAction, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadingAccessToken } from '../../../app/Slices/AuthSlice';
import {
    useSendVerificationEmailMutation,
    useSignInMutation,
    useSignOutMutation,
} from '../../../app/Slices/SevenDayApiSlice';
import { ActionType } from '../../../app/Slices/SnackBarSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomCheckbox } from '../../../layout/styledcomponents/CustomCheckbox';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import { CustomTextField } from '../../../layout/styledcomponents/CustomTextField';
import ForgotPasswordModal from '../../components/Login/ForgotPasswordModal';
import toast from '../../shared/Toast';

export default function LoginPage(params: any) {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [signIn] = useSignInMutation();
    const [signOut] = useSignOutMutation();
    const [openModal, setOpen] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidInput, setIsValidInput] = useState(false);
    const [lowerCaseValidation, setLowercaseValidation] = useState(false);
    const [upperCaseValidation, setUppercaseValidation] = useState(false);
    const [pwLengthValidation, setPWLengthValidation] = useState(false);
    const [numberValidation, setNumberValidation] = useState(false);
    const [characterValidation, setCharacterValidation] = useState(false);
    const [sendVerificationEmail] = useSendVerificationEmailMutation();
    const [rememberMe, setRememberMe] = useState(false);
    const [eLogin, setEmailLogin] = useState<any>();
    const [passLogin, setPassLogin] = useState<any>();
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        signOut({});
    }, [signOut]);

    const paperStyle = {
        padding: '0px 0px 0px 0px',
        minWidth: 380,
        maxWidth: 480,
    };
    const btnstyle = {
        margin: '8px 0px  30px  0px',
        height: '50px',
        fontSize: '16px',
        borderRadius: '5px',
    };

    const errors: any = [];

    if (emailAddress.length === 0) {
        errors.push('Email cannot be empty');
    }

    if (password.length === 0) {
        errors.push('Password cannot be empty');
    }

    const handleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(event?.target?.checked);
        if (event?.target?.checked === false) {
            setPassword('');
            setPassLogin('');
            setIsValidInput(false);
            localStorage.removeItem('username');
            localStorage.removeItem('password');
            localStorage.removeItem('rememberMe');
        }
    };

    const handleSendVerificationEmail = (email: string) => {
        sendVerificationEmail({ email })
            .then((data: any) => {
                if (data?.error) {
                    toast.error(data.error.data.message);
                } else {
                    toast.remove('emailNotVerified');
                    toast.success('Verification email is sent successfully.');
                }
            })
            .catch(() => {
                toast.error(
                    'Failed to send verification email. Please try again.'
                );
            });
    };

    const handleSignInEmail = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setEmailAddress(event.target.value);
        setEmailLogin(event.target.value);
        if (validatEmail(event.target.value) === false) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
    };

    const onClickOpenModal = () => {
        setOpen(true);
    };
    const handleChangeModal = () => {
        setOpen(false);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const validatEmail = (email: any) => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    };

    const handlePassword = (event: {
        target: { value: SetStateAction<string> };
    }) => {
        setPassword(event.target.value);
        setPassLogin(event.target.value);
        if (validatePassword(event.target.value) === false) {
            setIsValidInput(true);
        } else {
            setIsValidInput(false);
        }
    };

    const validatePassword = (pw: any) => {
        if (/(?=.*[A-Z])/.test(pw) === true) {
            setUppercaseValidation(true);
        } else {
            setUppercaseValidation(false);
        }
        if (pw.length >= 8 === true) {
            setPWLengthValidation(true);
        } else {
            setPWLengthValidation(false);
        }
        if (/[a-z]/.test(pw) === true) {
            setLowercaseValidation(true);
        } else {
            setLowercaseValidation(false);
        }
        if (/[#?!@$%^&*-]/.test(pw) === true) {
            setCharacterValidation(true);
        } else {
            setCharacterValidation(false);
        }
        if (/[0-9]/.test(pw) === true) {
            setNumberValidation(true);
        } else {
            setNumberValidation(false);
        }

        return (
            /[A-Z]/.test(pw) &&
            pw.length >= 8 &&
            /[a-z]/.test(pw) &&
            /[0-9]/.test(pw) &&
            /[#?!@$%^&*-]/.test(pw)
        );
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const userStore = localStorage.getItem('username');
        const passStore = localStorage.getItem('password');
        const rememberMeStore = localStorage.getItem('rememberMe');

        if (userStore) {
            setEmailLogin(userStore);
            setEmailAddress(userStore);
        }
        if (passStore) {
            setPassLogin(passStore);
            setPassword(passStore);
        }
        if (rememberMeStore) {
            setRememberMe(rememberMeStore === 'true' ? true : false);
        }
    }, []);

    const onClickSignInUser = (e: any) => {
        let requestBody = { email: emailAddress, password: password };
        if (e.key === 'Enter' || e.key === undefined) {
            if (isValidEmail === true) {
                toast.error(`Invalid Email`);
            } else if (isValidInput === true) {
                toast.error(`Invalid Password`);
            } else {
                if (rememberMe) {
                    localStorage.setItem('username', eLogin);
                    localStorage.setItem('password', passLogin);
                    localStorage.setItem('rememberMe', 'true');
                } else {
                    localStorage.removeItem('username');
                    localStorage.removeItem('password');
                    localStorage.removeItem('rememberMe');
                }
                signIn(requestBody).then((data: any) => {
                    if (data) {
                        if (data?.error) {
                            if (
                                data.error.data.error ===
                                'auth.unverified_email'
                            ) {
                                toast.error(`Email address is not verified.`, {
                                    actionText: 'Resend verification email',
                                    actionOnclick: () =>
                                        handleSendVerificationEmail(
                                            emailAddress
                                        ),
                                    actionType: ActionType.DEFAULT,
                                    customId: 'emailNotVerified',
                                });
                            } else {
                                toast.error(`${data?.error?.data.message}`);
                            }
                        } else {
                            dispatch(loadingAccessToken(data?.data));
                            const userToken: any = data?.data.accessToken;
                            let decode: any = jwt_decode(userToken);

                            median.onesignal.externalUserId.set({
                                externalId: decode?.sub.toString(),
                            });
                            if (
                                params?.previousLocation === undefined ||
                                params?.previousLocation === '/' ||
                                params?.previousLocation === '/login' ||
                                params?.previousLocation === '' ||
                                params?.previousLocation.includes('reset') ||
                                params?.previousLocation.includes('verifyEmail')
                            ) {
                                if (
                                    decode?.role === 'owner_admin' ||
                                    decode?.role === 'owner_user'
                                ) {
                                    navigate(`/dashboard`, { replace: true });
                                    ReactGA.send({
                                        hitType: 'pageview',
                                        page: '/dashboard',
                                        title: 'Dashboard',
                                    });
                                } else if (
                                    decode?.role === 'guest_user' ||
                                    decode?.role === 'guest_admin' ||
                                    decode?.role === 'guest_department_admin'
                                ) {
                                    navigate(`/viewExperiences/all`, {
                                        replace: true,
                                    });
                                    ReactGA.send({
                                        hitType: 'pageview',
                                        page: '/viewExperiences/all',
                                        title: 'All Experiences',
                                    });
                                } else if (decode?.role === 'admin') {
                                    navigate(`/dashboard`, { replace: true });
                                    ReactGA.send({
                                        hitType: 'pageview',
                                        page: '/dashboard',
                                        title: 'Dashboard',
                                    });
                                } else {
                                    //  decode?.role === 'owner_company' ||
                                    navigate(`/users`, { replace: true });
                                    ReactGA.send({
                                        hitType: 'pageview',
                                        page: '/users',
                                        title: 'Users',
                                    });
                                }
                            } else {
                                navigate(`${params?.previousLocation}`);
                            }
                        }
                    } else {
                        toast.error(`User does not exist`);
                    }
                });
            }
        }
    };

    return (
        <Box
            sx={{
                width: '100vw',
                height: isMobile ? '85vh' : '100vh',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid>
                <Paper elevation={isMobile ? 0 : 10} style={paperStyle}>
                    <Grid
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            // backgroundColor: '#5cb5b3',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            padding: '35px',
                        }}
                    >
                        <img
                            alt='primarylogo'
                            src='/logos/GreenLogoNoBack.png'
                            style={{
                                // height: '50px',
                                width: '170px',
                                height: '60px',
                            }}
                        />{' '}
                    </Grid>

                    <Grid sx={{ padding: '0px 40px 40px 40px' }}>
                        <Typography
                            component='h1'
                            variant='h5'
                            color='#787878'
                            sx={{
                                fontSize: '28px',
                                letterSpacing: '-0.5px',
                                padding: '20px 0px 20px 5px',
                            }}
                        >
                            Sign In
                        </Typography>{' '}
                        <CustomTextField
                            error={isValidEmail}
                            label={
                                isValidEmail === true
                                    ? 'Invalid Email Address'
                                    : ''
                            }
                            type='email'
                            placeholder='Email'
                            onChange={handleSignInEmail}
                            value={eLogin}
                            onKeyDown={onClickSignInUser}
                            fullWidth
                            color={isValidEmail ? 'green' : 'neutral'}
                            sx={{ marginBottom: '10px', color: '#787878' }}
                            InputProps={{
                                style: {
                                    height: '50px',
                                },
                                startAdornment: (
                                    <InputAdornment
                                        position='start'
                                        sx={{ tabIndex: '-1' }}
                                    >
                                        <EmailIcon
                                            fontSize='small'
                                            sx={{
                                                color: '#E0E0E0',
                                                tabIndex: '-1',
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <CustomTextField
                            error={isValidInput}
                            label={
                                isValidInput === true ? 'Invalid Password' : ''
                            }
                            placeholder='Password'
                            type={showPassword ? 'text' : 'password'}
                            onChange={handlePassword}
                            onKeyDown={onClickSignInUser}
                            value={passLogin}
                            color={isValidInput ? 'green' : 'neutral'}
                            fullWidth
                            InputProps={{
                                style: {
                                    height: '50px',
                                },
                                startAdornment: (
                                    <InputAdornment
                                        position='start'
                                        sx={{ tabIndex: '-1' }}
                                    >
                                        <LockIcon
                                            fontSize='small'
                                            sx={{
                                                color: '#E0E0E0',
                                                tabIndex: '-1',
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment
                                        position='end'
                                        sx={{ tabIndex: '-1' }}
                                    >
                                        {rememberMe === false ? (
                                            showPassword === false ? (
                                                <CustomIconButton
                                                    tabIndex='-1'
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                    sx={{
                                                        width: '100%',
                                                        height: '25px',
                                                        display: 'flex',
                                                        flexDirection: 'row',

                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: '#5cb5b3',
                                                            backgroundColor:
                                                                'transparent',
                                                            background: 'none',
                                                        },
                                                    }}
                                                >
                                                    <VisibilityIcon fontSize='small' />
                                                </CustomIconButton>
                                            ) : (
                                                <CustomIconButton
                                                    onClick={() =>
                                                        setShowPassword(
                                                            !showPassword
                                                        )
                                                    }
                                                    sx={{
                                                        width: '100%',
                                                        height: '25px',
                                                        display: 'flex',
                                                        flexDirection: 'row',

                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            color: '#5cb5b3',
                                                            backgroundColor:
                                                                'transparent',
                                                            background: 'none',
                                                        },
                                                    }}
                                                >
                                                    <VisibilityOffIcon fontSize='small' />
                                                </CustomIconButton>
                                            )
                                        ) : null}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <Grid sx={{ width: '10%' }}>
                                <CustomCheckbox
                                    checked={rememberMe}
                                    onChange={handleRememberMe}
                                />
                            </Grid>
                            <Grid sx={{ width: '90%' }}>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '14px',
                                    }}
                                >
                                    Remember me
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid sx={{ marginTop: '30px' }}>
                            <CustomButton
                                type='submit'
                                color='green'
                                variant='contained'
                                style={btnstyle}
                                fullWidth
                                disabled={
                                    isValidEmail ||
                                    isValidInput ||
                                    errors.length > 0
                                }
                                onClick={onClickSignInUser}
                            >
                                Sign in
                            </CustomButton>
                        </Grid>
                        <Grid sx={{ display: 'flex', width: '100%' }}>
                            <Grid
                                sx={{
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                {' '}
                                <Link
                                    style={{ color: '#787878' }}
                                    target='_blank'
                                    to={`https://apps.apple.com/au/app/7day-app/id6470282265?itsct=apps_box_link&itscg=30200`}
                                >
                                    {' '}
                                    <img
                                        alt='primarylogo'
                                        src='/AppleWhite.png'
                                        style={{
                                            // padding: '0px 50px 0px 10px',
                                            width: 'auto',
                                            height: '40px',
                                        }}
                                    />
                                </Link>
                            </Grid>
                            <Grid
                                sx={{
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {' '}
                                <Link
                                    style={{ color: '#787878' }}
                                    target='_blank'
                                    to={`https://play.google.com/store/apps/details?id=com.sevenday.mobile&pcampaignid=web_share7DAY`}
                                >
                                    <img
                                        alt='primarylogo'
                                        src='/googleWhite.png'
                                        style={{
                                            padding: '0px 50px 0px 10px',
                                            width: 'auto',
                                            height: '40px',
                                            // backgroundColor: 'red',
                                        }}
                                    />
                                </Link>
                            </Grid>
                        </Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                fontSize: '12px',
                                textAlign: 'center',
                            }}
                        >
                            <CustomButton
                                onClick={onClickOpenModal}
                                disableRipple
                                sx={{
                                    color: '#787878',
                                    textDecoration: 'none',
                                    textTransform: 'initial',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                    '&:hover': {
                                        color: '#5cb5b3',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                }}
                            >
                                Forgot password ?
                            </CustomButton>
                        </Typography>
                    </Grid>
                </Paper>
            </Grid>
            <ForgotPasswordModal
                open={openModal}
                onClose={handleCloseModal}
                onChange={handleChangeModal}
            />
        </Box>
    );
}

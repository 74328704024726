import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useForgotPasswordMutation } from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import { CustomTextField } from '../../../layout/styledcomponents/CustomTextField';
import toast from '../../shared/Toast';

export default function ForgotPasswordModal(props: any) {
    const { onClose, open, onChange } = props;
    const [resetPassword] = useForgotPasswordMutation();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [email, setEmailAddress] = useState('');

    const handleEmailAddress = (event: any) => {
        setEmailAddress(event?.target?.value?.toLowerCase());
    };

    const handleResetLink = () => {
        let requestBody = {
            email: email,
        };
        resetPassword(requestBody).then((data: any) => {
            if (data) {
                if (
                    data?.error?.data !== 'Reset Password link sent to email.'
                ) {
                    toast.error(
                        `Failed to send reset password link, please check email is correct`
                    );
                } else {
                    toast.success(
                        `Reset password link has been sent to ${email}`
                    );
                    handleCloseChangeModal();
                }
            } else {
                toast.error(
                    `Failed to send reset password link, please check email is correct`
                );
            }
        });
    };

    const handleClose = () => {
        onClose();
        setEmailAddress('');
    };

    const handleCloseChangeModal = () => {
        onChange();
        setEmailAddress('');
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid
                sx={{
                    // background: ' pink',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // background: 'yellow',
                        textAlign: 'left',
                        height: '50px',
                        width: isMobile ? '97%' : '600px',
                        alignContent: 'center',
                        alignItems: 'center',
                        borderBottom: ' #f6f8f9 solid 1px',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            // background: 'red',
                            height: '100%',
                            width: '90%',
                            paddingLeft: '18px',
                        }}
                    >
                        <Typography
                            sx={{ fontFamily: 'Open Sans', color: '#787878' }}
                            variant='h6'
                        >
                            Reset Password
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // backgroundColor: 'blue',
                            height: '100%',
                            width: '10%',
                        }}
                    >
                        <CustomIconButton
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#5cb5b3',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </CustomIconButton>
                    </Grid>
                </Grid>
                <DialogContent
                    dividers
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '200px',
                        // backgroundColor: 'yellow'
                    }}
                >
                    <DialogContentText
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: isMobile ? 'column' : 'row',
                        }}
                    >
                        <>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    width: isMobile ? '100%' : '30%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        // paddingRight: '10px',
                                    }}
                                >
                                    Email Address:
                                </Typography>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    paddingTop: isMobile ? ' 10px' : '0px',
                                    paddingLeft: isMobile ? '0px' : '10px',
                                    width: isMobile ? '100%' : '70%',
                                    // backgroundColor: 'yellow',
                                }}
                            >
                                <CustomTextField
                                    type='text'
                                    fullWidth
                                    value={email}
                                    onChange={handleEmailAddress}
                                />
                            </Grid>
                        </>
                    </DialogContentText>
                </DialogContent>{' '}
                <DialogActions
                    sx={{
                        borderTop: ' #d8d8d8 solid 1px',
                        // minHeight: '60px',
                        background: ' #eeeeee',
                    }}
                >
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color='green'
                            // autoFocus
                            onClick={handleResetLink}
                        >
                            send
                        </CustomButton>
                    </Grid>
                </DialogActions>
            </Grid>
        </Dialog>
    );
}

import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import 'react-dates/initialize';
import { Provider } from 'react-redux';
import { store } from './app/Slices/store';
import moment from 'moment-timezone';
import ReactGA from 'react-ga4';

moment.tz.setDefault("Australia/Sydney");
ReactGA.initialize('G-V241GMFMXB')

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>
);

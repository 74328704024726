import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function AddBillingDetails() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To add your payment method you will need to:
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        <ol>
                            <br></br>
                            <li>Login to 7DAY</li>
                            <li>
                                {loggedInUser?.role.includes('admin')
                                    ? 'From the top right corner of the app, select "Admin Center" from the cog wheel icon and then select "Billing". '
                                    : 'From the top right corner of the app select "Billing" from the cog wheel icon'}
                            </li>
                            <li>
                                Add the billing address and then click on
                                'Payment Method'{' '}
                            </li>
                            <li>
                                Click on 'Add' and complete the necessary fields
                            </li>
                            <br></br>
                            Note: You can add in more than one payment method
                            i.e Personal Card/Corporate Card etc
                            <br></br>
                            Upon making a reservation you will be prompted to
                            select a payment method from the list.
                        </ol>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
    validateLicenceNo,
    validatEmail,
    validateName,
    validatePhone,
} from '../../shared/utils';

interface Driver {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    licenceNo: string;
    licenceType: string;
}

interface Error {
    id: string;
    field: string;
    message: string;
}

const initialState: { value: Driver[] } = {
    value: [
        {
            id: 'default1',
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            licenceNo: '',
            licenceType: '',
        },
    ],
};

export const driversSlice = createSlice({
    name: 'drivers',
    initialState,
    reducers: {
        setDrivers: (state, action: PayloadAction<Driver[]>) => {
            state.value =
                action.payload.length > 0 ? action.payload : initialState.value;
        },
        addDriver: (state, action: PayloadAction<Driver>) => {
            state.value = [...state.value, action.payload];
        },
        updateDriver: (
            state,
            action: PayloadAction<{
                id: string;
                updatedDriver: Partial<Driver>;
            }>
        ) => {
            state.value = state.value.map((driver) =>
                driver.id === action.payload.id
                    ? { ...driver, ...action.payload.updatedDriver }
                    : driver
            );
        },
        deleteDriver: (state, action: PayloadAction<string>) => {
            if (state.value.length > 1) {
                state.value = state.value.filter(
                    (driver) => driver.id !== action.payload
                );
            }
        },
        resetDrivers: (state) => {
            state.value = initialState.value;
        },
    },
});

export const {
    setDrivers,
    addDriver,
    updateDriver,
    deleteDriver,
    resetDrivers,
} = driversSlice.actions;

export const selectDrivers = (state: RootState) => state.drivers.value;

export const selectDriversFormErrors = (state: RootState): Error[] => {
    const errors: Error[] = [];
    const minimumLicenceRequired = state.licence.minimumLicenceRequired;
    const driverSameAsBooker = state.driverSameAsBooker.driverSameAsBooker;

    state.drivers.value.forEach((driver, index) => {
        if (!driver.firstName || !validateName(driver.firstName)) {
            errors.push({
                id: driver.id,
                field: 'firstName',
                message: 'Invalid or missing first name.',
            });
        }
        if (!driver.lastName || !validateName(driver.lastName)) {
            errors.push({
                id: driver.id,
                field: 'lastName',
                message: 'Invalid or missing last name.',
            });
        }
        if (
            !(driverSameAsBooker && index === 0) &&
            (!driver.phone || !validatePhone(driver.phone))
        ) {
            errors.push({
                id: driver.id,
                field: 'phone',
                message: 'Invalid or missing phone number.',
            });
        }
        if (!driver.email || !validatEmail(driver.email)) {
            errors.push({
                id: driver.id,
                field: 'email',
                message: 'Invalid or missing email.',
            });
        }
        if (!driver.licenceNo || !validateLicenceNo(driver.licenceNo)) {
            errors.push({
                id: driver.id,
                field: 'licenceNo',
                message: 'Invalid or missing licence number.',
            });
        }
        if (!driver.licenceType) {
            errors.push({
                id: driver.id,
                field: 'licenceType',
                message: 'Licence type is required.',
            });
        } else if (
            (minimumLicenceRequired === 'full' &&
                (driver.licenceType === 'p1' || driver.licenceType === 'p2')) ||
            (minimumLicenceRequired === 'p2' && driver.licenceType === 'p1')
        ) {
            errors.push({
                id: driver.id,
                field: 'licenceType',
                message: `Invalid Licence Type.`,
            });
        }
    });

    return errors;
};

export const driversReducer = driversSlice.reducer;

interface LicenceState {
    minimumLicenceRequired: string;
}

const initialLicenceState: LicenceState = {
    minimumLicenceRequired: '',
};

export const licenceSlice = createSlice({
    name: 'licence',
    initialState: initialLicenceState,
    reducers: {
        setMinimumLicenceRequired: (state, action: PayloadAction<string>) => {
            state.minimumLicenceRequired = action.payload;
        },
    },
});

export const { setMinimumLicenceRequired } = licenceSlice.actions;

export const selectMinimumLicenceRequired = (state: RootState) =>
    state.licence.minimumLicenceRequired;

export const licenceReducer = licenceSlice.reducer;

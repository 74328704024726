import React, { useEffect, useRef, useState } from 'react';
import { APIProvider, useMapsLibrary } from '@vis.gl/react-google-maps';
import { CustomTextField } from '../../layout/styledcomponents/CustomTextField';
import { Address } from '../types/experience';
import { Clear, Search } from '@mui/icons-material';
import { CustomIconButton } from '../../layout/styledcomponents/CustomIconButton';
import { Box, InputAdornment } from '@mui/material';

interface PlaceAutocompleteProps {
    onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
    height?: string;
}

const PlaceAutocomplete = ({
    onPlaceSelect,
    height,
}: PlaceAutocompleteProps) => {
    const [placeAutocomplete, setPlaceAutocomplete] =
        useState<google.maps.places.Autocomplete | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const places = useMapsLibrary('places');

    const options: google.maps.places.AutocompleteOptions = {
        fields: ['address_components'],
        componentRestrictions: { country: 'au' },
    };

    useEffect(() => {
        if (!places || !inputRef.current) return;

        setPlaceAutocomplete(
            new places.Autocomplete(inputRef.current, options)
        );
    }, [places]);

    useEffect(() => {
        if (!placeAutocomplete) return;

        placeAutocomplete.addListener('place_changed', () => {
            onPlaceSelect(placeAutocomplete.getPlace());
        });
    }, [onPlaceSelect, placeAutocomplete]);

    const handleClear = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    return (
        <div className='autocomplete-container'>
            <CustomTextField
                placeholder='Search or enter manually.'
                variant='outlined'
                color='green'
                fullWidth
                inputRef={inputRef}
                inputProps={{
                    style: {
                        height: height || '10px',
                    },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <Search color='disabled' fontSize='small' />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position='end'>
                            <CustomIconButton
                                onClick={handleClear}
                                disableRipple
                            >
                                <Clear fontSize='small' />
                            </CustomIconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

interface AddressAutocompleteProps {
    onChange: (data: Address) => void;
    height?: string;
}

const AddressAutocomplete = ({
    onChange,
    height,
}: AddressAutocompleteProps) => {
    const handleSelect = (
        selectedPlace: google.maps.places.PlaceResult | null
    ) => {
        if (!selectedPlace) return;

        const getAddressComponent = (
            types: string[],
            short_name: boolean = false
        ) => {
            const component = selectedPlace.address_components?.find((comp) =>
                types.some((type) => comp.types.includes(type))
            );
            return component
                ? short_name
                    ? component.short_name
                    : component.long_name
                : '';
        };

        const address: Address = {
            streetAddress1: getAddressComponent(['street_number']),
            streetAddress2: getAddressComponent(['route']),
            suburb: getAddressComponent(['locality', 'sublocality']),
            state: getAddressComponent(['administrative_area_level_1'], true),
            postcode: getAddressComponent(['postal_code']),
            country: getAddressComponent(['country'], true),
        };

        onChange(address);
    };

    return (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}>
            <PlaceAutocomplete onPlaceSelect={handleSelect} height={height} />
        </APIProvider>
    );
};

export default AddressAutocomplete;

import { Divider, Grid, Stack, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import {
    camelCaseToLowerCase,
    capitalizeName,
    formatCurrency,
} from '../../../../shared/utils';

const CalculateChargesContent = ({
    fees,
    moduleType,
    moduleId,
}: {
    fees: any;
    moduleType: any;
    moduleId: any;
}) => {
    const getFeeMultiplierText = (feeMultiplier: any, moduleType: any) => {
        return feeMultiplier.value > 1
            ? ['night', 'day'].includes(feeMultiplier.type) &&
              (moduleType === 'commercial' ||
                  moduleType === 'activity' ||
                  moduleType === 'vehicle')
                ? 'days'
                : feeMultiplier.type === 'night'
                ? 'nights '
                : feeMultiplier.type === 'person'
                ? 'guests'
                : feeMultiplier.type === 'hour'
                ? 'hours'
                : feeMultiplier.type
            : feeMultiplier.value === 1 && feeMultiplier.type === 'person'
            ? 'guest'
            : feeMultiplier.value === 1 &&
              feeMultiplier.type === 'night' &&
              (moduleType === 'commercial' ||
                  moduleType === 'activity' ||
                  moduleType === 'vehicle')
            ? 'day'
            : camelCaseToLowerCase(feeMultiplier.type);
    };

    return (
        <Grid sx={{ minWidth: '330px' }}>
            <TransitionGroup>
                {fees?.charges
                    ?.filter((fee: any) => fee.total !== 0)
                    ?.map((fee: any, index: number, array: any[]) => (
                        <Collapse
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                marginBottom:
                                    index !== array.length - 1 ? '10px' : '0',
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        maxWidth: '220px',
                                    }}
                                >
                                    {fee?.type === 'fixed' ? (
                                        capitalizeName(fee.name)
                                    ) : (
                                        <>
                                            {`$${fee?.unitPrice?.toFixed(
                                                2
                                            )} AUD `}
                                            {fee.feeMultipliers.map(
                                                (feeMultiplier: any) => (
                                                    <span
                                                        key={feeMultiplier.type}
                                                    >
                                                        {` x ${
                                                            feeMultiplier.value
                                                        } ${getFeeMultiplierText(
                                                            feeMultiplier,
                                                            moduleType
                                                        )}`}
                                                    </span>
                                                )
                                            )}

                                            {fee?.isWeekendPrice && (
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        marginTop: '5px',
                                                    }}
                                                >
                                                    (Weekend Price)
                                                </Typography>
                                            )}
                                            {fee?.discount?.percentage > 0 && (
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        marginLeft: '20px',
                                                        marginTop: '5px',
                                                    }}
                                                >
                                                    - {fee.discount.percentage}%
                                                    Discount
                                                </Typography>
                                            )}
                                            {fee?.name
                                                ? `(${capitalizeName(
                                                      fee.name
                                                  )})`
                                                : ``}
                                        </>
                                    )}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                    }}
                                >
                                    {formatCurrency(fee?.total)}
                                </Typography>
                            </Grid>
                        </Collapse>
                    ))}
            </TransitionGroup>
            {moduleId !== 9 && (
                <Collapse in={!!fees?.charges}>
                    {' '}
                    <Divider
                        sx={{
                            width: '100%',
                        }}
                    />
                    <Stack sx={{ gap: 1 }}>
                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {' '}
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                }}
                            >
                                Subtotal
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                }}
                            >
                                {formatCurrency(fees?.bookingTotal?.subTotal)}
                            </Typography>
                        </Grid>
                        {fees?.bookingTotal?.discount > 0 && (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {' '}
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                    }}
                                >
                                    Discount
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                    }}
                                >
                                    -{' '}
                                    {formatCurrency(
                                        fees?.bookingTotal?.discount
                                    )}
                                </Typography>
                            </Grid>
                        )}
                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {' '}
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                }}
                            >
                                GST 10%
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                }}
                            >
                                {formatCurrency(fees?.bookingTotal?.gst)}
                            </Typography>
                        </Grid>
                        <Grid
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {' '}
                            <Typography
                                sx={{
                                    color: '#000',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Total
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#000',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {formatCurrency(fees?.bookingTotal?.total)}
                            </Typography>
                        </Grid>
                    </Stack>
                </Collapse>
            )}
        </Grid>
    );
};

export default CalculateChargesContent;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface AmityNewMessageProps {
    newMessage: boolean;
}

const initialState: { value: AmityNewMessageProps } = {
    value: {
        newMessage: false
    },
};

export const slice = createSlice({
    name: 'amityNewMessage',
    initialState,
    reducers: {
        setAmityNewMessage: (state, action: PayloadAction<AmityNewMessageProps>) => {
            state.value = action.payload;
        },
        resetAmityNewMessage: (state) => {
            state.value = initialState.value;
        },
    },
});

export const { setAmityNewMessage, resetAmityNewMessage } = slice.actions;
export const selectAmityNewMessage = (state: RootState) =>
    state.amityNewMessage.value;
export const AmityNewMessageReducer = slice.reducer;

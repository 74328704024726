import { Box, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { useNavigate } from 'react-router-dom';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import ReactGA from 'react-ga4';

const PolicyToolbar = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();

    const handleAdd = () => {
        navigate('/newPolicy');
        ReactGA.send({
            hitType: 'pageview',
            page: `/newPolicy`,
            title: `New Policy`,
        });
    };

    return (
        <Grid
            sx={{
                textAlign: ' right',
                paddingBottom: '20px',
            }}
        >
            {isMobile || isIpad ? (
                <CustomIconButton
                    variant='contained'
                    color='green'
                    onClick={handleAdd}
                >
                    <AddCircleIcon />
                </CustomIconButton>
            ) : (
                <CustomButton
                    variant='contained'
                    color='green'
                    startIcon={<AddCircleIcon />}
                    onClick={handleAdd}
                >
                    add{' '}
                </CustomButton>
            )}
        </Grid>
    );
};

export default PolicyToolbar;

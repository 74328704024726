import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useAddIndividualFeeMutation,
    useDeleteFeeMutation,
    useEditFeeMutation,
    useGetFeeListQuery,
} from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomTextField } from '../../../layout/styledcomponents/CustomTextField';
import DropdownFilter from '../../../layout/styledcomponents/filters/Dropdown';
import MultiSelectDropdownFilter from '../../../layout/styledcomponents/filters/MultiSelectDropdown';
import UserRole from '../../../shared/AuthRoles';
import { camelCaseToTitleCase } from '../../../shared/utils';
import ConfirmationModal from '../../shared/ConfirmationModal';
import toast from '../../shared/Toast';
import { Fee } from './FeesList';

interface Props {
    fee?: Fee;
}

export enum FeeType {
    Fixed = 'fixed',
    PerHour = 'perHour',
    PerDay = 'perDay',
    PerNight = 'perNight',
    PerHourPerGuest = 'perHourPerGuest',
    PerDayPerGuest = 'perDayPerGuest',
    PerNightPerGuest = 'perNightPerGuest',
}

const FeeForm = ({ fee }: Props) => {
    const [isDuplicateName, setDuplicateName] = useState(false);
    const [addNewFee] = useAddIndividualFeeMutation();
    const [feeName, setFeeName] = useState('');
    const [feeType, setFeeType] = useState(fee?.type);
    const loggedInUser = UserRole();
    const navigate = useNavigate();
    const [editFee] = useEditFeeMutation();
    const [deleteFee] = useDeleteFeeMutation();
    const { data: getFees } = useGetFeeListQuery(loggedInUser?.company);
    const [showModal, setShowModal] = useState(false);
    const [experienceTypes, setExperienceTypes] = useState<string[]>([]);

    const feeNames = getFees?.map((fee: Fee) => fee?.name);
    const handleFeeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const findDuplicate = feeNames?.find(
            (feeName: string) => feeName === event?.target?.value
        );
        if (
            findDuplicate !== undefined &&
            findDuplicate !== getFees?.fee?.name
        ) {
            setDuplicateName(true);
            setFeeName('');
        } else {
            setDuplicateName(false);
            setFeeName(event.target?.value?.toLowerCase());
        }
    };

    const handleFeeType = (value: any) => {
        setFeeType(value);
    };

    const errors: any = [];

    if (feeName.length === 0) {
        errors.push('Name cannot be empty');
    }
    if (feeType?.length === 0) {
        errors.push('Fee type cannot be empty');
    }

    useEffect(() => {
        if (fee && fee.name) {
            setFeeName(fee.name);
            setExperienceTypes(fee.moduleTypes);
        }
    }, [fee]);

    const saveFee = () => {
        if (fee) {
            editFee([
                loggedInUser?.company,
                fee.id,
                { name: feeName, moduleTypes: experienceTypes, type: feeType },
            ]).then((data: any) => {
                if (data?.error) {
                    toast.error(data.error.data.message[0]);
                } else {
                    toast.success(`Fee: ${feeName} has been updated`);
                    navigate('/fees');
                }
            });
        } else {
            if (
                loggedInUser?.role === 'owner_company' ||
                loggedInUser?.role === 'owner_admin'
            ) {
                addNewFee([
                    loggedInUser?.company,
                    {
                        name: feeName,
                        moduleTypes: experienceTypes,
                        type: feeType,
                    },
                ]).then((data: any) => {
                    if (data?.error) {
                        toast.error(data.error.data.message[0]);
                    } else {
                        toast.success(
                            `New fee type: ${feeName} has been created`
                        );

                        navigate('/fees');
                    }
                });
            } else {
                toast.error(`You don't have the necessary rights to add fees`);
            }
        }
    };

    const handleDeleteFee = () => {
        deleteFee([loggedInUser?.company, fee?.id]).then((data: any) => {
            if (data.error) {
                toast.error(`${data.error?.data?.message}`);
            } else {
                toast.success(`Fee: ${feeName} has been deleted`);
                navigate('/fees');
            }
        });
    };

    return (
        <Grid
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    justifyContent: 'left',
                    display: 'flex',
                }}
            >
                <Typography
                    component='h1'
                    variant='h5'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        textDecoration: 'underline',
                        textDecorationColor: '#5cb5b3',
                        textUnderlineOffset: '8px',
                    }}
                >
                    {fee ? `Edit Pricing / Fee ` : ` New Pricing / Fee Type`}
                </Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid item container columns={25} spacing={1}>
                    <Grid item xs={25} lg={4}>
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Fee Name
                            {feeName === '' ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}{' '}
                        </Typography>
                    </Grid>
                    <Grid item xs={25} lg={9}>
                        <CustomTextField
                            color={'green'}
                            error={isDuplicateName}
                            fullWidth={true}
                            label={
                                isDuplicateName === true
                                    ? 'Error: Pricing & Fee already exists'
                                    : ''
                            }
                            value={!isDuplicateName ? feeName : null}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleFeeName}
                            inputProps={{
                                maxLength: 19,
                                style: {
                                    height: '10px',
                                    width: '350px',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container item columns={25} spacing={1}>
                    <Grid
                        item
                        xs={25}
                        lg={4}
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            gap: '3px',
                        }}
                    >
                        {' '}
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Experience Type
                        </Typography>
                        <Grid>
                            <Tooltip
                                title={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Selecting this does not apply this to
                                        all experiences with this experience
                                        type. To do so, you will need to go to
                                        the experience and apply the Pricing &
                                        Fee amount. By selecting the Experience
                                        Types here, it will appear in that
                                        experience types 'Pricing & Fees List’
                                        as an option to add.
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutlineIcon
                                    sx={{
                                        color: '#787878',
                                        fontSize: '16px',
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item xs={25} lg={9}>
                        <MultiSelectDropdownFilter
                            id={'typeFilter'}
                            options={[
                                {
                                    value: 'property',
                                    label: 'Property',
                                },
                                { value: 'boat', label: 'Boat' },
                                {
                                    value: 'vehicle',
                                    label: 'Vehicle',
                                },
                                {
                                    value: 'activity',
                                    label: 'Activity',
                                },
                                {
                                    value: 'commercial',
                                    label: 'Commercial',
                                },
                            ]}
                            handleChange={setExperienceTypes}
                            selectedValue={experienceTypes}
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                        />
                    </Grid>
                </Grid>
                <Grid container item columns={25} spacing={1}>
                    <Grid
                        item
                        xs={25}
                        lg={4}
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            gap: '3px',
                        }}
                    >
                        {' '}
                        <Typography
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                            }}
                        >
                            Fee Type
                        </Typography>
                    </Grid>
                    <Grid item xs={25} lg={9}>
                        <DropdownFilter
                            id={'feeName'}
                            options={Object.values(FeeType).map((value) => {
                                return {
                                    value: value,
                                    label: camelCaseToTitleCase(value),
                                };
                            })}
                            handleChange={handleFeeType}
                            selectedValue={camelCaseToTitleCase(feeType || '')}
                            backgroundColor={'white'}
                            border={'#C3C3C3'}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    item
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            gap: '10px',
                        }}
                    >
                        {fee && (
                            <CustomButton
                                id='deleteFeeType'
                                variant='contained'
                                color='warning'
                                onClick={() => {
                                    setShowModal(!showModal);
                                }}
                            >
                                Delete
                            </CustomButton>
                        )}

                        <CustomButton
                            id='saveFeeType'
                            variant='contained'
                            color='green'
                            onClick={saveFee}
                            disabled={errors.length > 0}
                        >
                            {fee ? `Update` : ` Save`}
                        </CustomButton>
                    </Grid>

                    <ConfirmationModal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        onChange={handleDeleteFee}
                        message={'delete this Pricing & Fee type'}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FeeForm;

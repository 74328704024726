import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function Discounts() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                        paddingTop: '15px',
                    }}
                >
                    You can create a discount per experience for any period of
                    time. Discounts are a strategy use to attract guests by
                    lowering the price for a period.
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                        paddingTop: '15px',
                    }}
                >
                    Discounts can be set for a period of time in which they will
                    expire. Alternatively you can disable or delete a discount
                    at any time.
                </Typography>
            </Grid>
        </Grid>
    );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface ExperienceFilters {
    type: string[];
    state: string[];
    guests: string;
    overnightGuests: string;
    bedrooms: string;
    visitType: string;
    amenities: string[];
    checkinDate: string;
    checkoutDate: string;
}

const initialState: { value: ExperienceFilters } = {
    value: {
        type: [],
        state: [],
        guests: '',
        overnightGuests: '',
        bedrooms: '',
        visitType: '',
        amenities: [],
        checkinDate: '',
        checkoutDate: '',
    },
};

const slice = createSlice({
    name: 'experienceFilters',
    initialState,
    reducers: {
        setExperienceFilters: (
            state,
            action: PayloadAction<ExperienceFilters>
        ) => {
            state.value = action.payload;
        },
        resetExperienceFilters: (state) => {
            state.value = initialState.value;
        },
    },
});

export const { setExperienceFilters, resetExperienceFilters } = slice.actions;
export const selectExperienceFilters = (state: RootState) =>
    state.experienceFilters.value;
export const ExperienceFiltersReducer = slice.reducer;

import { Box, Grid, InputLabel, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import {
    useGetAllClientsQuery,
    useGetEarningsQuery,
    useGetPlatformSettingsQuery,
} from '../../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../../layout/styledcomponents/CustomButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSelector } from 'react-redux';
import { selectReportAPIRefSliceData } from '../../../../../app/Slices/ReportAPIRefSlice';
import HostPaymentsReportList from './HostPaymentsReportList';
import UserRole from '../../../../../shared/AuthRoles';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import SearchFilter from '../../../../shared/SearchFilter';
import { useForm } from 'react-hook-form';
import FormSelect from '../../../../shared/FormSelect';
import LoadingComponent from '../../../../../layout/styledcomponents/LoadingComponent';

export const HostPaymentsReportPage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedInUser.role !== 'admin') {
            navigate(`/permissionDenied`);
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, []);

    const now = new Date();
    const currentYear = now.getFullYear();

    const { control, watch } = useForm<{ year: number; owner: number }>({
        defaultValues: { year: currentYear, owner: 0 },
    });

    const { data: earnings } = useGetEarningsQuery({
        year: watch('year'),
        ...(watch('owner') ? { owner: watch('owner') } : {}),
    });
    const { data: owners } = useGetAllClientsQuery('/owners');
    const { data: platformSettings } =
        useGetPlatformSettingsQuery('/platform-settings');

    const years: { value: number; label: string }[] = [];
    for (let year = currentYear; year >= 2023; year--) {
        years.push({
            value: year,
            label: `${year}`,
        });
    }

    const reportExportAPI = useSelector(selectReportAPIRefSliceData);
    return !earnings || !owners || !platformSettings ? (
        <LoadingComponent color='green'></LoadingComponent>
    ) : (
        <Box sx={{ Top: isMobile ? '30px' : '0px' }}>
            <Grid
                container
                justifyContent={'space-between'}
                spacing={1}
                alignItems={'center'}
            >
                <Grid
                    container
                    item
                    xs={12}
                    lg={8}
                    spacing={1}
                    alignItems={'flex-end'}
                >
                    <Grid item xs={12} lg={2.5}>
                        <InputLabel>Payment Year</InputLabel>
                        <FormSelect
                            name='year'
                            control={control}
                            options={years}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3.5}>
                        <InputLabel>Owner</InputLabel>
                        <FormSelect
                            name='owner'
                            control={control}
                            options={[
                                { value: 0, label: 'All' },
                                ...owners.map(
                                    (owner: { id: number; name: string }) => ({
                                        value: owner.id,
                                        label: owner.name,
                                    })
                                ),
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <SearchFilter />
                    </Grid>
                </Grid>

                <Grid item>
                    <CustomButton
                        disableRipple
                        variant='contained'
                        color='neutral'
                        onClick={() =>
                            reportExportAPI.csvExport({
                                fileName: `Host Payments Report`,
                            })
                        }
                        sx={{ marginRight: '5px' }}
                    >
                        <FileDownloadIcon fontSize='small' />
                    </CustomButton>
                </Grid>
            </Grid>
            <HostPaymentsReportList
                earnings={earnings}
                serviceFeePercentage={platformSettings.serviceFeePercentage}
            />
        </Box>
    );
};

import { zodResolver } from '@hookform/resolvers/zod';
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { endOfMonth } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import {
    useGetAllGuestsQuery,
    useGetBillingAccountQuery,
    useGetBillingReportQuery,
    useGetCompanyGuestsInfoQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import UserRole from '../../../../shared/AuthRoles';
import FormDateFilter from '../../../shared/FormDateFilter';
import FormMultiSelect from '../../../shared/FormMultiSelect';
import LinkCell from '../../../shared/LinkCell';
import StripedDataGrid from '../../../shared/StripedDataGrid';
import { formatCurrencyShort } from '../../../../shared/utils';

const BillingReport = () => {
    const loggedInUser = UserRole();
    const { data: guestCompanyInfo } = useGetCompanyGuestsInfoQuery(
        `/guests/${loggedInUser?.company}`
    );
    const [filters, setFilters] = useState<{
        guestIds?: number[];
        from?: string | null;
        to?: string | null;
    }>({
        guestIds: [],
        from: null,
        to: null,
    });

    const queryUrl = React.useMemo(() => {
        const baseUrl = '/invoices';
        const queryParts: string[] = [];

        if (filters.guestIds?.length) {
            queryParts.push(`guestIds=${filters.guestIds.join(',')}`);
        }
        if (filters.from) {
            queryParts.push(`from=${filters.from}`);
        }
        if (filters.to) {
            queryParts.push(`to=${filters.to}`);
        }

        return queryParts?.length
            ? `${baseUrl}?${queryParts.join('&')}`
            : baseUrl;
    }, [filters]);

    const { data: billingReportData } = useGetBillingReportQuery({
        url: queryUrl,
    });
    const modifiedBillingReportData = billingReportData?.map((item) => ({
        ...item,
        daysFromDueDate: Math.ceil(
            (new Date().getTime() - new Date(item.dueDate).getTime()) /
                (1000 * 60 * 60 * 24)
        ),
    }));

    const { data: billingAccount, isFetching } = useGetBillingAccountQuery(
        `guests/${guestCompanyInfo?.id}/billing-account`
    );
    const { data: allGuests } = useGetAllGuestsQuery(`/guests`);

    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        guestIds: z.array(z.number()).optional(),
        monthRange: z
            .object({
                start: z.date().nullable(),
                end: z.date().nullable(),
            })
            .optional(),
    });

    type BillingReportFilters = z.infer<typeof schema>;
    const {
        reset,
        control,
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isDirty },
    } = useForm<BillingReportFilters>({
        resolver: zodResolver(schema),
        defaultValues: {
            from: '',
            to: '',
            guestIds: [],
            monthRange: { start: null, end: null },
        },
    });

    const fromValue = watch('from');
    const toValue = watch('to');
    const guestIdsValue = watch('guestIds');

    useEffect(() => {
        if (toValue) {
            const lastDayOfMonth = moment(endOfMonth(new Date(toValue)))
                .toISOString()
                .split('T')[0];

            setValue('to', lastDayOfMonth, { shouldDirty: true });
        }
    }, [toValue, setValue]);

    useEffect(() => {
        if (billingAccount) {
            reset(billingAccount);
        }
    }, [billingAccount, reset]);

    useEffect(() => {
        const fromDate = fromValue
            ? moment(fromValue).format('YYYY-MM-DD')
            : null;
        const toDate = toValue ? moment(toValue).format('YYYY-MM-DD') : null;

        setFilters((prev) => ({
            ...prev,
            from: fromDate,
            to: toDate,
        }));
    }, [fromValue, toValue]);

    useEffect(() => {
        setFilters((prev) => ({
            ...prev,
            guestIds: guestIdsValue || [],
        }));
    }, [guestIdsValue]);

    const onSubmit = (data: BillingReportFilters) => {
        const fromDate = data.from
            ? moment(data.from).format('YYYY-MM-DD')
            : null;
        const toDate = data.to ? moment(data.to).format('YYYY-MM-DD') : null;

        setFilters({
            guestIds: data.guestIds || [],
            from: fromDate,
            to: toDate,
        });
    };

    const apiRef = useGridApiRef();

    const renderLinkCell = (
        params: any,
        align: 'left' | 'center' | 'right' = 'left',
        paddingLeft: string = '0px'
    ) => (
        <LinkCell
            params={params}
            href={`/bills/${params.row.id}`}
            state={{
                name: 'viewReservation',
                component: 'reports',
                url: 'reports/reservationsReport',
                params: params.row,
            }}
            align={align}
            paddingLeft={paddingLeft}
        />
    );

    const columns: GridColDef[] = [
        {
            field: 'guestName',
            headerName: 'Guest Name',
            flex: 1,
            minWidth: 130,
            type: 'string',
            sortable: true,
            renderCell: renderLinkCell,
        },
        {
            flex: 1,
            field: 'period',
            headerName: 'Period',
            minWidth: 170,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                const from = params.row.from
                    ? moment(params.row.from).format('D MMM')
                    : 'N/A';
                const to = params.row.to
                    ? moment(params.row.to).format('D MMM, YYYY')
                    : 'N/A';
                return renderLinkCell({
                    ...params,
                    value: `${from} - ${to}`,
                });
            },
        },
        {
            flex: 1,
            field: 'billedTo',
            headerName: 'Billed To',
            minWidth: 130,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                const { firstName, lastName, streetAddress } = params.row;
                const value =
                    `${firstName || ''} ${lastName || ''}${
                        streetAddress ? `, ${streetAddress}` : ''
                    }`.trim() || 'N/A';
                return renderLinkCell({ ...params, value });
            },
        },
        {
            flex: 1,
            field: 'dueDate',
            headerName: 'Due Date',
            minWidth: 130,
            type: 'date',
            headerClassName: 'custom-padding-header',
            sortable: true,
            valueGetter: (params) => {
                return params.row.dueDate ? new Date(params.row.dueDate) : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? moment(params.value).format('D MMM YYYY')
                    : 'N/A';
                return renderLinkCell({ ...params, value }, 'left', '30px');
            },
        },

        {
            flex: 1,
            field: 'total',
            headerName: 'Amount (inc GST)',
            minWidth: 120,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            sortable: true,
            renderCell: (params) => {
                const value = params?.value
                    ? formatCurrencyShort(params?.value)
                    : '$0.00';
                return renderLinkCell({ ...params, value }, 'right');
            },
        },

        {
            flex: 1,
            field: 'lessThan7Days',
            headerName: '<7 days',
            minWidth: 100,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            sortable: true,
            valueGetter: (params) => {
                if (params.row.amountDue === 0) {
                    return null;
                }
                return params.row.daysFromDueDate < 7 &&
                    params.row.daysFromDueDate > 0
                    ? params.row.total
                    : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? formatCurrencyShort(params?.value)
                    : '';
                return renderLinkCell({ ...params, value }, 'right');
            },
        },
        {
            flex: 1,
            field: 'lessThan14Days',
            headerName: '<14 days',
            minWidth: 100,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            sortable: true,
            valueGetter: (params) => {
                if (params.row.amountDue === 0) {
                    return null;
                }
                return params.row.daysFromDueDate < 14 &&
                    params.row.daysFromDueDate >= 7
                    ? params.row.total
                    : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? formatCurrencyShort(params?.value)
                    : '';
                return renderLinkCell({ ...params, value }, 'right');
            },
        },
        {
            flex: 1,
            field: 'lessThan30Days',
            headerName: '<30 days',
            minWidth: 100,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            sortable: true,
            valueGetter: (params) => {
                if (params.row.amountDue === 0) {
                    return null;
                }
                return params.row.daysFromDueDate < 30 &&
                    params.row.daysFromDueDate >= 14
                    ? params.row.total
                    : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? formatCurrencyShort(params?.value)
                    : '';
                return renderLinkCell({ ...params, value }, 'right');
            },
        },
        {
            flex: 1,
            field: 'moreThan30Days',
            headerName: '30+ days',
            minWidth: 100,
            type: 'number',
            align: 'right',
            headerAlign: 'right',
            sortable: true,
            valueGetter: (params) => {
                if (params.row.amountDue === 0) {
                    return null;
                }
                return params.row.daysFromDueDate >= 30
                    ? params.row.total
                    : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? formatCurrencyShort(params?.value)
                    : '';
                return renderLinkCell({ ...params, value }, 'right');
            },
        },

        {
            flex: 1,
            field: 'fullyPaidAt',
            headerName: 'Paid Date',
            minWidth: 130,
            type: 'date',
            headerClassName: 'custom-padding-header',
            sortable: true,
            valueGetter: (params) => {
                return params.row.fullyPaidAt
                    ? new Date(params.row.fullyPaidAt)
                    : null;
            },
            renderCell: (params) => {
                const value = params.value
                    ? moment(params.value).format('D MMM YYYY')
                    : 'N/A';
                return renderLinkCell({ ...params, value }, 'left', '30px');
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                const value = params.row.amountDue === 0 ? 'Paid' : 'Unpaid';
                return renderLinkCell({ ...params, value });
            },
        },
    ];

    return (
        <Stack spacing={3} paddingTop={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={{ xs: 2, md: 4 }}>
                    <Grid container item columnSpacing={5} rowSpacing={2}>
                        <Grid container item xs={12} md={12} spacing={0.5}>
                            <Grid item xs={12} md={3}>
                                <FormDateFilter
                                    name='from'
                                    control={control}
                                    label='From'
                                    views={['month', 'year']}
                                    format='MMM YYYY'
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormDateFilter
                                    name='to'
                                    control={control}
                                    label='To'
                                    views={['month', 'year']}
                                    format='MMM YYYY'
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={12} spacing={0.5}>
                            {loggedInUser?.role === 'admin' && (
                                <Grid item xs={7} md={4}>
                                    <FormMultiSelect
                                        name='guestIds'
                                        control={control}
                                        options={allGuests}
                                        label='Select Guests'
                                        getOptionLabel={(option) =>
                                            option.name || ''
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                    />
                                </Grid>
                            )}

                            <Grid item xs={5} md={2}>
                                {' '}
                                <CustomButton
                                    type='button'
                                    variant='contained'
                                    color='neutral'
                                    fullWidth
                                    onClick={() => {
                                        setFilters({
                                            guestIds: [],
                                        });
                                        reset({
                                            from: '',
                                            to: '',
                                            guestIds: [],
                                        });
                                    }}
                                    disabled={!isDirty}
                                >
                                    Clear Filters
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>

            <Box
                sx={{
                    height:
                        billingReportData && billingReportData?.length > 0
                            ? 'auto'
                            : 600,
                    width: 'auto',
                }}
            >
                <StripedDataGrid
                    apiRef={apiRef}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                guestName: loggedInUser?.role === 'admin',
                            },
                        },
                    }}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                            ? 'even'
                            : 'odd'
                    }
                    sx={{
                        boxShadow: 0,
                        border: 0,

                        '& .MuiDataGrid-cell': {
                            padding: '0',
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                    rows={
                        modifiedBillingReportData !== undefined
                            ? modifiedBillingReportData
                            : []
                    }
                    columns={columns}
                    slots={{
                        noRowsOverlay: () => (
                            <Stack
                                height='70%'
                                alignItems='center'
                                justifyContent='center'
                            >
                                <List>
                                    <ListItem
                                        sx={{
                                            padding: '0!important',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography>
                                                    No Records
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography>
                                                    There are no bills based on
                                                    your filter selection
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Stack>
                        ),
                        noResultsOverlay: () => (
                            <Stack
                                height='70%'
                                alignItems='center'
                                justifyContent='center'
                            >
                                <List>
                                    <ListItem
                                        sx={{
                                            padding: '0!important',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography>
                                                    No Results
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Please try again
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Stack>
                        ),
                    }}
                />
            </Box>
        </Stack>
    );
};

export default BillingReport;

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function EditDiscount() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            {loggedInUser?.role.includes('admin') ? (
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                            paddingTop: '15px',
                        }}
                    >
                        To edit a Discount:
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>Click on 'Experiences' from the menu</li>
                            <li>Select 'Manage Experiences' </li>
                            <li>Select a Experience</li>
                            <li>Click the edit icon (pencil icon)</li>
                            <li>Scroll down to 'Pricing'</li>

                            <li>Click on 'Discounts'</li>
                            <li>Click on 'View Your Discounts'</li>
                        </ol>{' '}
                        Here you will be able to either 'Disable' your discount
                        or 'Delete'
                    </Typography>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Only admins can edit a discount. Please contact
                            a person that is an admin in your organisation to
                            add, edit or delete discounts.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

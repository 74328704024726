import { CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';

const theme = createTheme({
    palette: {
        // @ts-ignore
        neutral: {
            main: '#fff',
            contrastText: '#5cb5b3',
        },
        green: {
            main: '#5cb5b3',
            contrastText: '#fff',
        },
        other: {
            main: '#5cb5b3',
            contrastText: '#5cb5b3',
        },
        roundButton: {
            main: '#addad9',
            contrastText: '#5cb5b3',
        },
    },
});

export default function LoadingComponent({ ...props }: any) {
    return (
        <ThemeProvider theme={theme}>
            <CircularProgress sx={{ fontFamily: 'Open Sans' }} {...props} />
        </ThemeProvider>
    );
}

import { Grid, InputLabel, Typography } from '@mui/material';
import React from 'react';
import FormCheckbox from '../../../shared/FormCheckbox';
import FormCounter from '../../../shared/FormCounter';

const BoardRoomForm = ({ control }: { control: any }) => {
    return (
        <>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid item container xs={12} md={6}>
                    <Grid item xs={12} sx={{ pt: 2 }}>
                        <Typography color='textPrimary' fontWeight='bold'>
                            Amenities
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='soundSystem'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Sound System</InputLabel>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox name='tv' control={control} sx={{ p: 0 }} />
                    <InputLabel>Tv</InputLabel>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ pt: 2 }}>
                <Typography color='textPrimary' fontWeight='bold'>
                    Arrangement
                </Typography>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <InputLabel>Seating Capacity</InputLabel>
                    <FormCounter name='seatingCapacity' disabled={false} />
                </Grid>
            </Grid>{' '}
        </>
    );
};

export default BoardRoomForm;

import {
    DataGrid,
    GridColDef,
    GridEventListener,
    useGridApiRef,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import moment from 'moment';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openReportAPIRefSliceAction } from '../../../../../app/Slices/ReportAPIRefSlice';
import UserRole from '../../../../../shared/AuthRoles';
import { CustomChip } from '../../../../../layout/styledcomponents/CustomChip';
import { useNavigate } from 'react-router-dom';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 900,
        color: '#000',
        borderBottom: '1px solid #787878',
        // textTransform: 'upperCase'
    },
    [`& .${gridClasses.row}`]: {
        // backgroundColor: '#f6f8f9',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            // fontWeight: 'bold',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                    // color: 'red',
                },
            },
        },
    },
}));

export default function UserReservationsListReport(params: any) {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const urlRef = window.location.href;

    useEffect(() => {
        let csv = apiRef?.current.exportDataAsCsv;
        let print = apiRef?.current.exportDataAsPrint;
        dispatch(
            openReportAPIRefSliceAction({
                exportInfo: {
                    csvExport: csv,
                    printExport: print,
                    search: apiRef?.current.setQuickFilterValues,
                },
            })
        );
    }, [apiRef]);

    const columns: GridColDef[] = [
        {
            field: 'moduleName',
            headerName: 'Experience',
            flex: 1,
            minWidth: isIpad ? 200 : 200,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                return params?.value;
            },
        },
        {
            field: 'ownerName',
            headerName: 'Host',
            description: 'Owner of experience',
            width: isMobile ? 80 : isIpad ? 80 : 150,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                return (
                    params?.value?.charAt(0).toUpperCase() +
                    params?.value?.slice(1)
                );
            },
        },
        {
            field: 'moduleType',
            headerName: 'Type',
            width: isMobile ? 80 : isIpad ? 80 : 80,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                return (
                    params?.value?.charAt(0).toUpperCase() +
                    params?.value?.slice(1)
                );
            },
        },

        {
            field: 'id',
            headerName: 'Reservation Ref',
            width: isMobile ? 80 : isIpad ? 80 : 80,
            type: '',
            sortable: true,
            renderCell: (params) => {
                return `#${params?.value}`;
            },
        },

        // {
        //   field: "bookerCompany",
        //   headerName: "Reserved By",
        //   description: "Companies who have made the Reservation",
        //   width: isMobile ? 80 : isIpad ? 80 : 150,
        //   type: "string",
        //   sortable: true,
        //   renderCell: (params) => {
        //     return params?.value?.charAt(0).toUpperCase() + params?.value?.slice(1);
        //   },
        // },

        // {
        //   field: "bookedBy",
        //   headerName: "Guest",
        //   description: "People who have made the Reservation",
        //   width: isMobile ? 80 : isIpad ? 80 : 150,
        //   type: "string",
        //   sortable: true,
        //   renderCell: (params) => {
        //     return params?.value?.charAt(0).toUpperCase() + params?.value?.slice(1);
        //   },
        // },
        {
            field: 'bookedFor',
            headerName: 'Guest',
            description: 'Guest attending',
            width: isMobile ? 80 : isIpad ? 80 : 150,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                return (
                    params?.value?.charAt(0).toUpperCase() +
                    params?.value?.slice(1)
                );
            },
        },

        {
            field: 'startDate',
            headerName: 'Reservation From',
            type: 'Date',
            width: isMobile ? 200 : isIpad ? 80 : 100,
            sortable: true,
            renderCell: (params) => {
                return moment(params?.value).format('DD/MM/YYYY');
            },
        },
        {
            field: 'endDate',
            headerName: 'Reservation To',
            type: 'Date',
            width: isMobile ? 200 : isIpad ? 80 : 100,
            sortable: true,
            renderCell: (params) => {
                return moment(params?.value).format('DD/MM/YYYY');
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                return (
                    <CustomChip
                        sx={{ fontFamily: 'Open Sans' }}
                        label={
                            params?.value.charAt(0).toUpperCase() +
                            params?.value.slice(1)
                        }
                        color={
                            params.value === 'completed'
                                ? 'green'
                                : params.value === 'cancelled'
                                ? 'red'
                                : params.value === 'approved'
                                ? 'yellow'
                                : params.value === 'declined'
                                ? 'gray'
                                : 'blue'
                        }
                        variant='outlined'
                    />
                );
            },
        },
    ];

    const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
        navigate(
            `/reservations/${params?.row?.moduleName
                .replace(/ /g, '')
                .toLowerCase()}/${params?.row?.id}`,
            {
                state: {
                    name: 'viewReservation',
                    component: 'dashboard',
                    url: `dashboard/user/${urlRef.split('/')[5]}`,
                    params: params.row,
                },
            }
        );
    };

    return (
        <Box sx={{ height: 800, width: 'auto', paddingTop: '50px' }}>
            <StripedDataGrid
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                onRowClick={handleRowClick}
                sx={{
                    boxShadow: 0,
                    border: 0,
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            bookerCompany:
                                loggedInUser?.role === 'owner_admin' ||
                                loggedInUser?.role === 'owner_user' ||
                                loggedInUser?.role === 'admin',
                            bookedBy: loggedInUser?.role === 'guest_admin' || loggedInUser?.role === 'guest_department_admin',
                            owner: loggedInUser?.role === 'admin',
                        },
                    },
                }}
                apiRef={apiRef}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={params?.data !== undefined ? params?.data : []}
                columns={columns}
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Records
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            ></Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Results
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please try again
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
}

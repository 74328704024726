import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface ISnackState {
    value: Toast[];
}

export interface CreateToastDto {
    type: ToastType;
    message: string;
    options?: CreateToastOptions;
}

export interface Toast extends CreateToastDto {
    id: string;
    show: boolean;
    bump: boolean;
    options: CreateToastOptions & { duration: number };
}

export type CreateToastOptions = {
    actionText?: string;
    actionOnclick?: () => void;
    actionType?: ActionType;
    closeButton?: boolean;
    duration?: number;
    customId?: string;
};

export enum ToastType {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}

export enum ActionType {
    NAVIGATE = 'navigate',
    DEFAULT = 'default',
}

const initialState: ISnackState = {
    value: [],
};

export const slice = createSlice({
    name: 'snackBar',
    initialState: { ...initialState },
    reducers: {
        openSnackAction: (state, action: PayloadAction<CreateToastDto>) => {
            const customId = action.payload.options?.customId;
            const id = customId || `id_${Date.now()}`;
            const defaults = {
                duration: action.payload.type === ToastType.ERROR ? 5000 : 3000,
                closeButton: true,
            };
            const newToast: Toast = {
                ...action.payload,
                id,
                show: true,
                bump: false,
                options: { ...defaults, ...action.payload.options },
            };
            if (
                state.value.find(
                    (toast: Toast) => toast.message === action.payload.message
                )
            ) {
                const newState = state.value.map((toast) => {
                    return {
                        ...toast,
                        bump:
                            toast.message === action.payload.message
                                ? true
                                : false,
                    };
                });

                state.value = newState;
            } else {
                state.value.push(newToast);
            }
        },
        updateToast: (
            state,
            action: PayloadAction<{ id: string; updates: Partial<Toast> }>
        ) => {
            const { id, updates } = action.payload;
            const toast = state.value.find((t) => t.id === id);
            if (toast) {
                Object.assign(toast, updates);
            }
        },

        hideToast: (state, action: PayloadAction<string>) => {
            const newState = state.value.map((toast) => {
                return {
                    ...toast,
                    show: toast.id === action.payload ? false : true,
                };
            });

            state.value = newState;
        },
        removeToast: (state, action: PayloadAction<string>) => {
            state.value = state.value.filter(
                (toast) => toast.id !== action.payload
            );
        },

        removeAllSnacks: (state) => {
            state.value = initialState.value;
        },
    },
});

export const {
    openSnackAction,
    removeToast,
    removeAllSnacks,
    hideToast,
    updateToast,
} = slice.actions;

export const selectSnackBars = (state: RootState) => state.snackBar.value;
export const SnackBarReducer = slice.reducer;

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
    useGetEarningsQuery,
    useGetPlatformSettingsQuery,
} from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import { Container, useMediaQuery } from '@mui/material';
import { Payment } from '../../components/HostPayments/Payment';
import ErrorPage from '../ErrorPage';
import UserRole from '../../../shared/AuthRoles';
import ReactGA from 'react-ga4';

export const HostPaymentPage = () => {
    const loggedInUser = UserRole();
    const navigate = useNavigate();

    useEffect(() => {
        if (!['owner_admin', 'admin'].includes(loggedInUser.role)) {
            navigate(`/permissionDenied`);
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, []);

    const isMobile = useMediaQuery('(max-width: 770px)');
    const { ownerId, year, month } = useParams();
    const { data, isLoading } = useGetEarningsQuery({
        year: Number(year),
        month: Number(month),
        owner: Number(ownerId),
    });
    const { data: platformSettings } =
        useGetPlatformSettingsQuery('/platform-settings');

    return isLoading || !platformSettings ? (
        <LoadingComponent color='green'></LoadingComponent>
    ) : data && data.bills.length ? (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Payment
                payment={data.bills[0]}
                serviceFeePercentage={platformSettings.serviceFeePercentage}
            ></Payment>
        </Container>
    ) : (
        <ErrorPage></ErrorPage>
    );
};

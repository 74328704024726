import React from 'react';
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useGetPolicyListQuery } from '../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../shared/AuthRoles';
import { capitalizeName } from '../../../shared/utils';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Policy } from '../../types/policy';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const PolicyList = () => {
    const loggedInUser = UserRole();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 600px)');
    const { data: policies } = useGetPolicyListQuery(loggedInUser?.company);

    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: policies?.length === 0 ? 'column' : 'row',
            }}
            container
            spacing={2}
        >
            {policies?.length === 0 && (
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: '150px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                        }}
                    >
                        No Policies
                    </Typography>{' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '12px',
                            textAlign: 'center',
                        }}
                    >
                        No policies have been created
                    </Typography>
                </Grid>
            )}

            {policies?.map((policy: Policy) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={2}
                    key={policy.id}
                    style={{ display: 'flex' }}
                >
                    <Card
                        sx={{
                            boxShadow: 1,
                            background: 'none',
                            minHeight: isMobile ? '50px' : '150px',
                            backgroundColor: 'transparent',
                            color: 'none',
                            cursor: 'pointer',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                            '&:focus': {
                                cursor: 'pointer',
                                color: '#fff',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                        onClick={() => {
                            navigate(`/policies/${policy.id}`);
                        }}
                        style={{ flex: 1 }}
                    >
                        <CardActionArea
                            sx={{
                                background: 'none',
                                backgroundColor: 'transparent',
                                color: '#fff',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                                '&:focus': {
                                    cursor: 'pointer',
                                    color: '#fff',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    backgroundColor: '#f6f8f9',
                                }}
                            >
                                <Grid>
                                    <Typography
                                        gutterBottom
                                        component='div'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            '&:hover': {
                                                color: '#5cb5b3',
                                            },

                                            padding: '0px 5px 0px 0px',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <EditIcon
                                            fontSize='small'
                                            sx={{ fontSize: '16px' }}
                                        />
                                    </Typography>
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '80px',
                                        }}
                                    >
                                        <Typography
                                            variant='h6'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#5cb5b3',
                                                // fontSize: isIpad ? '16px':''
                                            }}
                                        >
                                            {capitalizeName(policy.name)}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '10px',
                                                paddingLeft: '2px',
                                            }}
                                        >
                                            Created:
                                            {moment(policy?.createdAt).format(
                                                'DD/MM/YYYY'
                                            )}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px',
                            }}
                        >
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {' '}
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '12px',
                                    }}
                                >
                                    Applied to{' '}
                                    {policy.moduleTypes.length === 0
                                        ? 'all'
                                        : policy.moduleTypes.length}
                                    {policy.moduleTypes.length === 1
                                        ? ' experience type:'
                                        : ' experience types:'}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                        fontSize: '12px',
                                    }}
                                >
                                    {' '}
                                    (
                                    {policy.moduleTypes.length === 0
                                        ? [
                                              'property',
                                              'boat',
                                              'vehicle',
                                              'activity',
                                              'commercial',
                                          ].map(
                                              (moduleType) =>
                                                  `${capitalizeName(
                                                      moduleType
                                                  )}, `
                                          )
                                        : policy.moduleTypes.map(
                                              (moduleType) =>
                                                  `${capitalizeName(
                                                      moduleType
                                                  )}, `
                                          )}
                                    )
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default PolicyList;

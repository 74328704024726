import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import React from 'react';

const theme = createTheme({
    palette: {
        // @ts-ignore
        neutral: {
            main: '#fff',
            contrastText: '#5cb5b3',
        },
        green: {
            main: '#5cb5b3',
            contrastText: '#fff',
        },
        gray: {
            main: '#787878',
            contrastText: '#5cb5b3',
        },
        red: {
            main: '#FF0000',
            contrastText: '#5cb5b3',
        },
        blue: {
            main: '#0096FF',
            contrastText: '#5cb5b3',
        },
    },
});

export function CustomSwitch({ children, ...props }: any) {
    return (
        <ThemeProvider theme={theme}>
            <FormControl>
                <FormControlLabel
                    sx={{
                        fontFamily: 'Open Sans',
                        paddingLeft: '5px',
                        paddingTop: '5px',
                    }}
                    control={
                        <Switch
                            size='small'
                            sx={{ paddingTop: '5px' }}
                            {...props}
                        />
                    }
                    label={props.label}
                />
            </FormControl>
        </ThemeProvider>
    );
}

import { DataGrid, GridColDef, GridEventListener, useGridApiRef } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import moment from 'moment';
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { CustomChip } from '../../../../../layout/styledcomponents/CustomChip';
import { useDispatch } from 'react-redux';
import { openReportAPIRefSliceAction } from '../../../../../app/Slices/ReportAPIRefSlice';
import { useNavigate } from 'react-router-dom';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 900,
        color: '#000',
        borderBottom: '1px solid #787878',
        // textTransform: 'upperCase'
    },
    [`& .${gridClasses.row}`]: {
        // backgroundColor: '#f6f8f9',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            // fontWeight: 'bold',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                    // color: 'red',
                },
            },
        },
    },
}));

export default function TopGuestsListReport(params: any) {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();

    useEffect(() => {
        let csv = apiRef?.current.exportDataAsCsv;
        let print = apiRef?.current.exportDataAsPrint;
        dispatch(
            openReportAPIRefSliceAction({
                exportInfo: {
                    csvExport: csv,
                    printExport: print,
                },
            })
        );
    }, [apiRef]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Guest Company',
            description: 'Companies who have completed the most reservations',
            flex: 1,
            minWidth: isIpad ? 200 : 550,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                return params?.value;
            },
        },
        {
            field: 'createdAt',
            headerName: 'Member Since',
            description: 'Member of 7DAY since date',
            type: 'date',
            width: isMobile ? 200 : isIpad ? 80 : 200,
            sortable: true,
            renderCell: (params) => {
                return moment(params?.value).format('DD/MM/YYYY');
            },
        },

        {
            field: 'isActive',
            headerName: 'Status',
            description: 'Company membership status',
            width: isMobile ? 200 : isIpad ? 150 : 200,
            type: 'boolean',
            sortable: true,
            renderCell: (params) => {
                return (
                    <CustomChip
                        sx={{ fontFamily: 'Open Sans' }}
                        label={params?.value === true ? 'Enabled' : 'Disabled'}
                        color={params.value === true ? 'green' : 'red'}
                        variant='outlined'
                    />
                );
            },
        },
        {
            field: 'activeUsers',
            headerName: 'Current Users',
            description: 'No. of current users on 7DAY belonging to company',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            align: 'center',
            type: 'number',
            sortable: true,
            renderCell: (params) => {
                return params?.value;
            },
        },
        {
            field: 'completedBookings',
            headerName: 'Completed Reservations',
            description:
                'No. of successfully completed reservations during date range',
            width: isMobile ? 150 : isIpad ? 150 : 150,
            align: 'center',
            type: 'number',
            sortable: true,
            renderCell: (params) => {
                return params?.value;
            },
        },
    ];


    return (
        <Box sx={{ height: 800, width: '100%', paddingTop: '50px' }}>
            {/* <CustomButton onClick={() => apiRef?.current?.exportDataAsPrint()}>Go to page 1</CustomButton> */}
            <StripedDataGrid
                apiRef={apiRef}
                // pageSize={1}
                // slots={{
                //     toolbar: CustomToolbar,
                // }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                sx={{
                    boxShadow: 0,
                    border: 0,
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={params?.data !== undefined ? params?.data : []}
                columns={columns}
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Guests
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                No Guests have reserved an
                                                experience with you
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Results
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please try again
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
}

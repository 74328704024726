import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function CreateAnExperience() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();

    return (
        <>
            {loggedInUser?.role.includes('admin') ? (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Have an experience to showcase? Users with admin
                            permissions can add and manage experiences.
                            Experiences can be instantly listed to the public or
                            hidden whilst you continue to make changes.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To create a new experience:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on ‘Experiences’</li>
                                <li>Click 'Create New Experiences'</li>
                                <li>Complete all mandatory fields with a *</li>

                                <li>Click 'Save'</li>
                            </ol>
                            Once saved, you will be displayed with what your
                            listing will look like to a user.
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Only admins can add a new experience. Please contact
                            a person that is an admin in your organisation to
                            add, edit or delete an experience.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

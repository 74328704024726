import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Badge } from '@mui/material';
import React from 'react';

const theme = createTheme({
    palette: {
        // @ts-ignore
        neutral: {
            main: '#fff',
            contrastText: '#5cb5b3',
        },
        green: {
            main: '#5cb5b3',
            contrastText: '#fff',
        },
        other: {
            main: '#5cb5b3',
            contrastText: '#5cb5b3',
        },
        roundButton: {
            main: '#addad9',
            contrastText: '#5cb5b3',
        },
        warning: {
            main: '#D0342C',
            contrastText: '#fff',
        },
    },
});

export function CustomBadge({ children, ...props }: any) {
    return (
        <ThemeProvider theme={theme}>
            <Badge  sx={{ fontFamily: 'Open Sans' }} {...props}>
                {children}
            </Badge>
        </ThemeProvider>
    );
}

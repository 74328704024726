import React from 'react';

import {
    Box,
    Grid,
    InputLabel,
    List,
    ListItem,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import AddressAutocomplete from '../../../shared/AddressAutocomplete';
import { Address } from '../../../types/experience';
import FormTextField from '../../../shared/FormTextField';
import FormAutocomplete from '../../../shared/FormAutocomplete';
import FormCheckbox from '../../../shared/FormCheckbox';
import { HelpOutline } from '@mui/icons-material';

interface LocationDetailsProps {
    type: string;
}

const LocationDetails = ({ type }: LocationDetailsProps) => {
    const { control, setValue, getValues, watch, clearErrors } =
        useFormContext();

    const handleAddress = (address: Address) => {
        clearErrors([
            'streetAddress1',
            'streetAddress2',
            'suburb',
            'state',
            'postcode',
            'countryCode',
        ]);
        setValue('streetAddress1', address.streetAddress1);
        setValue('streetAddress2', address.streetAddress2);
        setValue('suburb', address.suburb);
        setValue('state', address.state);
        setValue('postcode', address.postcode);
        setValue('countryCode', address.country);
    };

    const handleDropOffAddress = (address: Address) => {
        clearErrors([
            'details.dropoffStreetAddress1',
            'details.dropoffStreetAddress2',
            'details.dropoffSuburb',
            'details.dropoffState',
            'details.dropoffPostcode',
            'details.dropoffCountryCode',
        ]);
        setValue('details.dropoffStreetAddress1', address.streetAddress1);
        setValue('details.dropoffStreetAddress2', address.streetAddress2);
        setValue('details.dropoffSuburb', address.suburb);
        setValue('details.dropoffState', address.state);
        setValue('details.dropoffPostcode', address.postcode);
        setValue('details.dropoffCountryCode', address.country);
    };

    const handleDropoffSameAsPickup = () => {
        if (!getValues('details.dropoffLocationSameAsPickup')) {
            setValue('details.dropoffStreetAddress1', getValues('streetAddress1'));
            setValue('details.dropoffStreetAddress2', getValues('streetAddress2'));
            setValue('details.dropoffSuburb', getValues('suburb'));
            setValue('details.dropoffState', getValues('state'));
            setValue('details.dropoffPostcode', getValues('postcode'));
            setValue('details.dropoffCountryCode', getValues('countryCode'));
            setValue('details.dropoffPhone', getValues('phone'));
            setValue('details.dropoffUsesNirovision', getValues('usesNirovision'));
        } else {
            setValue('details.dropoffStreetAddress1', '');
            setValue('details.dropoffStreetAddress2', '');
            setValue('details.dropoffSuburb', '');
            setValue('details.dropoffState', '');
            setValue('details.dropoffPostcode', '');
            setValue('details.dropoffCountryCode', '');
            setValue('details.dropoffPhone', '');
            setValue('details.dropoffUsesNirovision', false);
        }
    };

    return (
        <>
            <Box>
                <Typography
                    component='h4'
                    color='textPrimary'
                    fontWeight='bold'
                >
                    {type === 'vehicle'
                        ? 'Pick up Location'
                        : 'Property Address'}
                </Typography>
                <Grid container columns={12} py='30px' columnSpacing={5}>
                    <Grid item xs={12} lg={2.37}>
                        <InputLabel>Address</InputLabel>
                    </Grid>
                    <Grid item xs>
                        <AddressAutocomplete onChange={handleAddress} />
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={5}>
                    <Grid container item xs={12} lg={6}>
                        <Grid item xs={12} lg={5}>
                            <InputLabel required>Street No.</InputLabel>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormTextField
                                name='streetAddress1'
                                control={control}
                                placeholder='230'
                                type='number'
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={6}>
                        <Grid item xs={12} lg={5}>
                            <InputLabel required>Street</InputLabel>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormTextField
                                name='streetAddress2'
                                control={control}
                                placeholder='Oak Road'
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={6}>
                        <Grid item xs={12} lg={5}>
                            <InputLabel required>Suburb</InputLabel>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormTextField
                                name='suburb'
                                control={control}
                                placeholder='Matcham'
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={6}>
                        <Grid item xs={12} lg={5}>
                            <InputLabel required>Post Code</InputLabel>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormTextField
                                name='postcode'
                                control={control}
                                placeholder='2250'
                                type='number'
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={6}>
                        <Grid item xs={12} lg={5}>
                            <InputLabel required>State</InputLabel>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormAutocomplete
                                name='state'
                                control={control}
                                options={[
                                    { value: 'NSW', label: 'NSW' },
                                    { value: 'QLD', label: 'QLD' },
                                    { value: 'VIC', label: 'VIC' },
                                    { value: 'SA', label: 'SA' },
                                    { value: 'WA', label: 'WA' },
                                    { value: 'NT', label: 'NT' },
                                    { value: 'TAS', label: 'TAS' },
                                    { value: 'ACT', label: 'ACT' },
                                ]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={6}>
                        <Grid item xs={12} lg={5}>
                            <InputLabel required>Country</InputLabel>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormAutocomplete
                                name='countryCode'
                                control={control}
                                options={[{ value: 'AU', label: 'Australia' }]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={6}>
                        <Grid item xs={12} lg={5}>
                            <InputLabel required>
                                Property Contact No.
                            </InputLabel>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormTextField
                                name='phone'
                                control={control}
                                type='tel'
                                placeholder='04176541654'
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={6}>
                        <Grid item xs={12} lg={5}>
                            <InputLabel>
                                Req. Nirovision Access{' '}
                                <Tooltip
                                    title={
                                        <Typography>
                                            <List>
                                                <ListItem>
                                                    Nirovision is a face
                                                    recognition system that
                                                    allows people access to
                                                    premises.
                                                </ListItem>
                                                <ListItem>
                                                    If you are not yet using
                                                    Nirovision and wish to know
                                                    more, visit
                                                    https://www.nirovision.com/
                                                    and select ‘No’ so guests do
                                                    not receive correspondence
                                                </ListItem>
                                            </List>
                                        </Typography>
                                    }
                                    placement='right'
                                >
                                    <HelpOutline sx={{ fontSize: '16px' }} />
                                </Tooltip>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormAutocomplete
                                name='usesNirovision'
                                control={control}
                                options={[
                                    { value: true, label: 'Yes' },
                                    { value: false, label: 'No' },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {type === 'vehicle' && (
                <Box>
                    <Typography
                        component='h4'
                        color='textPrimary'
                        fontWeight='bold'
                    >
                        {'Drop off Location'}
                    </Typography>
                    <Stack gap='30px' pt='20px'>
                        <Grid container columns={12} columnSpacing={5}>
                            <Grid item xs={7} sm={5} md={3} lg={2.35}>
                                <InputLabel>Address Same as Above</InputLabel>
                            </Grid>
                            <Grid item xs={2} lg={7}>
                                <FormCheckbox
                                    name='details.dropoffLocationSameAsPickup'
                                    control={control}
                                    onClick={handleDropoffSameAsPickup}
                                    sx={{ p: 0 }}
                                />
                            </Grid>
                        </Grid>
                        {!watch('details.dropoffLocationSameAsPickup') && (
                            <>
                                <Grid
                                    container
                                    rowSpacing={1}
                                    columnSpacing={5}
                                >
                                    <Grid item container pb={2}>
                                        <Grid item xs={12} lg={2.43}>
                                            <InputLabel>Address</InputLabel>
                                        </Grid>
                                        <Grid item xs>
                                            <AddressAutocomplete
                                                onChange={handleDropOffAddress}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} lg={6}>
                                        <Grid item xs={12} lg={5}>
                                            <InputLabel required>
                                                Street No.
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <FormTextField
                                                name='details.dropoffStreetAddress1'
                                                control={control}
                                                placeholder='230'
                                                type='number'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} lg={6}>
                                        <Grid item xs={12} lg={5}>
                                            <InputLabel required>
                                                Street
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <FormTextField
                                                name='details.dropoffStreetAddress2'
                                                control={control}
                                                placeholder='Oak Road'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} lg={6}>
                                        <Grid item xs={12} lg={5}>
                                            <InputLabel required>
                                                Suburb
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <FormTextField
                                                name='details.dropoffSuburb'
                                                control={control}
                                                placeholder='Matcham'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} lg={6}>
                                        <Grid item xs={12} lg={5}>
                                            <InputLabel required>
                                                Post Code
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <FormTextField
                                                name='details.dropoffPostcode'
                                                control={control}
                                                placeholder='2250'
                                                type='number'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} lg={6}>
                                        <Grid item xs={12} lg={5}>
                                            <InputLabel required>
                                                State
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <FormAutocomplete
                                                name='details.dropoffState'
                                                control={control}
                                                options={[
                                                    {
                                                        value: 'NSW',
                                                        label: 'NSW',
                                                    },
                                                    {
                                                        value: 'QLD',
                                                        label: 'QLD',
                                                    },
                                                    {
                                                        value: 'VIC',
                                                        label: 'VIC',
                                                    },
                                                    {
                                                        value: 'SA',
                                                        label: 'SA',
                                                    },
                                                    {
                                                        value: 'WA',
                                                        label: 'WA',
                                                    },
                                                    {
                                                        value: 'NT',
                                                        label: 'NT',
                                                    },
                                                    {
                                                        value: 'TAS',
                                                        label: 'TAS',
                                                    },
                                                    {
                                                        value: 'ACT',
                                                        label: 'ACT',
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} lg={6}>
                                        <Grid item xs={12} lg={5}>
                                            <InputLabel required>
                                                Country
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <FormAutocomplete
                                                name='details.dropoffCountryCode'
                                                control={control}
                                                options={[
                                                    {
                                                        value: 'AU',
                                                        label: 'Australia',
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} lg={6}>
                                        <Grid item xs={12} lg={5}>
                                            <InputLabel required>
                                                Property Contact No.
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <FormTextField
                                                name='details.dropoffPhone'
                                                control={control}
                                                type='tel'
                                                placeholder='04176541654'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} lg={6}>
                                        <Grid item xs={12} lg={5}>
                                            <InputLabel>
                                                Req. Nirovision Access
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} lg={7}>
                                            <FormAutocomplete
                                                name='details.dropoffUsesNirovision'
                                                control={control}
                                                options={[
                                                    {
                                                        value: true,
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: false,
                                                        label: 'No',
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Stack>
                </Box>
            )}
        </>
    );
};

export default LocationDetails;

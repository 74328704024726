import Box from '@mui/material/Box';
import React from 'react';
import CustomAppBar from './CustomAppBar';
import { useMediaQuery } from '@mui/material';

const DefaultLayout = (props: any) => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <Box sx={{ width: '100%', height: '100vh', flex: 1 }}>
            <Box>
                <CustomAppBar />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '95.1%',
                    // flex: 1,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: isMobile ? null : 'flex',
                    }}
                >
                    {props.children}
                </Box>
            </Box>
        </Box>
    );
};

export default DefaultLayout;

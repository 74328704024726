import { ZodType, z } from "zod";
import { validatePhone } from "../../../../shared/utils";
import { ExperiencePolicy, ReservationPolicy } from "../../../types/policy";

const vehicleInfo = (experienceDetails: any) => z.object({
    checkinTime: z.string(),
    checkoutTime: z.string(),
    driverSameAsBooker: z.boolean().default(false),
    drivers: z.array(z.object({
        // id: z.string(),
        firstName: z.string().min(1, { 
            message: 'Required' 
        }),
        lastName: z.string().min(1, { 
            message: 'Required' 
        }),
        phone: z.string().refine(validatePhone, { 
            message: 'Invalid phone number'
        }),
        email: z.string().email(),
        licenceNo: z.string().min(1, {
            message: 'Required'
        }),
        licenceType: z.enum(['full', 'p1', 'p2'], { message: 'Required' })
                      .superRefine((val, ctx) => {
                            if((experienceDetails?.details?.minimumLicenceRequired === 'full' &&
                                    (val === 'p1' || val === 'p2')) ||
                                (experienceDetails?.details?.minimumLicenceRequired === 'p2' && val === 'p1')
                            ) {
                                ctx.addIssue({
                                    code: z.ZodIssueCode.custom,
                                    message: `The vehicle requires a ${experienceDetails?.details?.minimumLicenceRequired} license as the minimum qualification for drivers. `
                                });
                            }
                      }),
    }))
})

const commercialInfo = (experienceDetails: any) => z.object({
    spaceType: z.string(),
    reqMeetingRoom: z.boolean().default(false),
});

const boatInfo = (experienceDetails: any) => z.object({
    // moduleType: experienceDetails?.type,
    checkinTime: z.string(),
    checkoutTime:  z.string(),
    adults: z.number().min(1, {
        message: 'At least 1 guest required'
    }),
    children: z.number(),
    overnightGuests: z.number(),
    requiresCatering: z.boolean().default(false),
    cateringNotes: z.string().default(''),
    requiresBeverages: z.boolean().default(false),
    beverageNotes: z.string().default(''),
});

const activityInfo = (experienceDetails: any) => z.object({
    checkinTime: z.string(),
    checkoutTime:  z.string(),
    adults: z.number().min(1, {
        message: 'At least 1 guest required'
    }),
})

const propertyInfo = (experienceDetails: any) => z.object({
    // moduleType: experienceDetails?.type,
    checkinTime: z.string(),
    checkoutTime:  z.string(),
    adults: z.number().min(1, {
        message: 'At least 1 guest required'
    }),
    children: z.number(),
    overnightGuests: z.number()
                      .min(1, { message: 'At least 1 overnight guest required.' })
                      .default(0)
                      .optional(),
    requiresCatering: z.boolean().default(false),
    cateringNotes: z.string().default(''),
    requiresBeverages: z.boolean().default(false),
    beverageNotes: z.string().default(''),
    requiresMassages: z.boolean().default(false),
    massageNotes: z.string().default(''),
});

const bookingInfo = (experienceDetails: any) => z.object({
    visitType: z.enum(['personal', 'business']),
    notes: z.string().default(''),
}).and(
    experienceDetails?.type === 'commercial' ? commercialInfo(experienceDetails) : 
    experienceDetails?.type === 'vehicle' ? vehicleInfo(experienceDetails) :
    experienceDetails?.type === 'boat' ? boatInfo(experienceDetails) :
    experienceDetails?.type === 'activity' ? activityInfo(experienceDetails) :
    propertyInfo(experienceDetails),
);

const policy = z.object({
    policyId: z.number(),
    checked: z.boolean().default(false),
    checkedAt: z.string().optional(),
    requiresConsent: z.boolean(),
}).superRefine((val, ctx) => {
    if (val.requiresConsent && !val.checked) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please accept this policy",
            path: ["checked"],
        });
    }
})

// Type guard to check if bookingInfo has adults
function hasAdults(bookingInfo: any): bookingInfo is { adults: number } {
    return 'adults' in bookingInfo;
}

export const GuestRequestSchema = (experienceDetails: any) =>
  z.object({
    booker: z.object({
      id: z.number(),
    }),
    bookingInfo: bookingInfo(experienceDetails),
    policies: z.array(policy).default([]),
    staffAttending: z.array(z.object({
        id: z.number(),   
    })).default([]),
}).superRefine((val, ctx) => {
    if (experienceDetails?.type === 'property' && hasAdults(val.bookingInfo)) {
        if (val.staffAttending.length > val.bookingInfo.adults) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Staff attending cannot exceed the number of adults',
                path: ['staffAttending'],
            });
        }
    }
});

export const OwnerRequestSchema = (experienceDetails: any) => z.discriminatedUnion('onBehalf', [
    z.object({ 
        onBehalf: z.literal(true), 
        bookingInfo: bookingInfo(experienceDetails),
        policies: z.array(policy).default([]),
    }),
    z.object({ 
        onBehalf: z.literal(false), 
        reason: z.enum(['cleaning', 'maintenance', 'publicHoliday', 'staffAway', 'shutdownPeriod', 'other', ]),
        notes: z.string().default(''),
    })
]).superRefine((val, ctx) => {
    if (!val.onBehalf && !val.reason) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required',
            path: ['reason'],
        });
    }

    if (!val.onBehalf && val.reason === 'other' && !val.notes) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required',
            path: ['notes'],
        });
    }
});

export const getRequestSchema = (experienceDetails: any, userRole: string): ZodType => {
    if (userRole.includes('guest')) {
        return GuestRequestSchema(experienceDetails);
    }
    return OwnerRequestSchema(experienceDetails);
};

export const getDefaultValues = (experienceDetails: any, overnight: boolean, user: any) => {
    let values = {};
    
    let bookingInfo: any = { visitType: experienceDetails.visitType, notes: '' };

    const reservationPolicies: ReservationPolicy[] =
        experienceDetails?.policies?.map((policy: ExperiencePolicy) => ({
            policyId: policy.policyId,
            checked: false,
            checkedAt: undefined,
            name: policy.name,
            description: policy.description,
            requiresConsent: policy.requiresConsent,
        }));

    switch (experienceDetails?.type) {
        case 'commercial':
            bookingInfo = {
                ...bookingInfo,
                reqMeetingRoom: false,
            }
            break;
        case 'vehicle':
            bookingInfo = {
                ...bookingInfo,
                driverSameAsBooker: false,
                drivers: [],
            }
            break;
        case 'boat':
            bookingInfo = {
                ...bookingInfo,
                adults: 0,
                children: 0,
                overnightGuests: 0,
                requiresCatering: false,
                cateringNotes: '',
                requiresBeverages: false,
                beverageNotes: '',
            }
            break;
        case 'activity':
            bookingInfo = {
                ...bookingInfo,
                adults: 0,
            }
            break;
        default:
            bookingInfo = {
                ...bookingInfo,
                adults: 0,
                children: 0,
                ...(overnight ? { overnightGuests: 0 } : {}),
                requiresCatering: false,
                cateringNotes: '',
                requiresBeverages: false,
                beverageNotes: '',
                requiresMassages: false,
                massageNotes: '',
            }
            break;
    }
    
    if (user.role.includes('owner')) {
        values = {
            booker: { id: user.sub },
            onBehalf: false,
            reason: 'other',
            notes: '',
            policies: reservationPolicies,
            bookingInfo: bookingInfo
        };
    }

    if (user.role.includes('guest')) {
        values = {
            booker: { id: user.role === 'guest_user' ? user.sub : undefined },
            policies: reservationPolicies,
            bookingInfo: bookingInfo,
        }
    }

    return values;
}

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function LinkCalendars() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <>
            {loggedInUser?.role.includes('admin') ? (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Admins can link calendars of multiple experiences to
                            prevent double bookings. For example, if you have a
                            listing for an entire property and another for the
                            main house, linking their calendars ensures that
                            when you book the entire property, the main house’s
                            calendar is automatically blocked. 
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To link experience calendars:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on ‘Experiences’</li>
                                <li>Click 'Manage Experiences'</li>
                                <li>
                                    Click on the 'pencil' icon to edit the
                                    experience (this is located next to the name
                                    of the experience)
                                </li>
                                <li>
                                    Scroll down to the ‘Connect Calendars’
                                    section
                                </li>
                                <li>
                                    Select the experiences that you want to be
                                    connected to the current experience
                                </li>
                                <li>
                                    Once linked, the current experience becomes
                                    the ‘main experience’, and the others become
                                    ‘linked experiences’. These experiences will
                                    now share the same blocked dates.
                                </li>
                            </ol>

                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    fontSize: '14px',
                                    paddingTop: '10px',
                                }}
                            >
                                Important Notes:
                            </Typography>
                            <ul>
                                <li>
                                    A ‘main experience’ cannot be linked to
                                    another experience, nor can a ‘linked
                                    experience’ be linked to another.
                                </li>
                                <li>
                                    If you want to link an additional experience
                                    to the ‘main experience’, you will need to
                                    update the ‘main experience’.
                                </li>
                                <li>
                                    If an experience is already linked, the link
                                    section will be disabled when trying to
                                    update the experience. To link it to another
                                    experience, it first needs to be unlinked
                                    from its current ‘main experience’.
                                </li>
                            </ul>
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Only admins can link experience calendars. Please
                            contact an admin if you require this feature.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

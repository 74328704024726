import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NewUserForm from '../../components/User Management/User Forms/NewUserForm';
import EditUserForm from '../../components/User Management/User Forms/EditUserForm';
import UserRole from '../../../shared/AuthRoles';
import ViewIndividualUser from '../../components/User Management/ViewIndividualUser';
import { CustomSmallListChip } from '../../../layout/styledcomponents/CustomChip';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { useGetIndividualUserInfoQuery } from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import ConfirmationModal from '../../shared/ConfirmationModal';
import React from 'react';
import ReactGA from 'react-ga4';

export default function UserFormPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const loggedInUser = UserRole();
    const navigate = useNavigate();
    const [url, setUrl] = useState('');
    const { data: getIndividualUser, isFetching } =
        useGetIndividualUserInfoQuery(url);
    const [open, setOpen] = useState(false);
    const urlRef = window.location.href.split('/')[5];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if(loggedInUser?.role === 'guest_user') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'newUserPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
        
        if (urlRef !== undefined) {
            if (
                loggedInUser?.role === 'owner_admin' ||
                loggedInUser?.role === 'owner_company' ||
                loggedInUser?.role === 'owner_user'
            ) {
                setUrl(`/owners/${loggedInUser?.company}/users/${urlRef}`);
            } else if (
                loggedInUser?.role === 'guest_admin' ||
                loggedInUser?.role === 'guest_company' ||
                loggedInUser?.role === 'guest_department_admin'
            ) {
                setUrl(`/guests/${loggedInUser?.company}/users/${urlRef}`);
            } else {
                setUrl(`/7-day/users/${urlRef}`);
            }
        }
    }, [loggedInUser?.company]);

    const editUser = () => {
        navigate(
            `/editUser/${getIndividualUser?.firstName}/${getIndividualUser?.id}`,
            {
                state: {
                    name: 'editUser',
                },
            }
        );
    };

    const onClickOpenModal = () => {
        setOpen(true);
    };
    const handleChangeModal = () => {
        setOpen(false);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile || isIpad ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {isFetching === true ? (
                <Grid
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '2%' : '5%',
                            justifyContent: 'left',
                            display: 'flex',
                        }}
                    >
                        {window.location.href?.includes('newUser') ? (
                            <Typography
                                component='h1'
                                variant='h5'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textAlign: 'left',
                                    textTransform: 'uppercase',
                                    textDecoration: 'underline',
                                    textDecorationColor: '#5cb5b3',
                                    textUnderlineOffset: '8px',
                                }}
                            >
                                New User
                            </Typography>
                        ) : window.location.href?.includes('user') ? (
                            <>
                                <Typography
                                    component='h1'
                                    variant='h5'
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        textAlign: 'left',
                                        textTransform: 'uppercase',
                                        fontWeight:
                                            window.location.href?.includes(
                                                'user'
                                            )
                                                ? '550'
                                                : 'none',
                                        paddingRight: '15px',
                                    }}
                                >
                                    {' '}
                                    {getIndividualUser?.firstName}{' '}
                                    {getIndividualUser?.lastName}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    <CustomSmallListChip
                                        size='small'
                                        style={{
                                            fontFamily: 'Open Sans',
                                            border: 0,
                                            backgroundColor:
                                                getIndividualUser?.role?.includes(
                                                    ['admin']
                                                ) ||
                                                loggedInUser?.role === 'admin'
                                                    ? '#5cb5b3'
                                                    : '#E0E0E0',
                                            color:
                                                getIndividualUser?.role?.includes(
                                                    ['admin']
                                                ) ||
                                                loggedInUser?.role === 'admin'
                                                    ? '#fff'
                                                    : '#787878',
                                        }}
                                        label={
                                            getIndividualUser?.role ===
                                                'owner_admin' ||
                                            loggedInUser?.role === 'admin'
                                                ? 'ADMIN'
                                                : getIndividualUser?.role ===
                                                  'guest_admin'
                                                ? 'COMPANY ADMIN'
                                                : getIndividualUser?.role ===
                                                  'guest_department_admin'
                                                ? 'TEAM ADMIN'
                                                : 'USER'
                                        }
                                        variant='outlined'
                                    />
                                </Typography>
                            </>
                        ) : (
                            <Typography
                                component='h1'
                                variant='h5'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textAlign: 'left',
                                    textTransform: 'uppercase',
                                    textDecoration: 'underline',
                                    textDecorationColor: '#5cb5b3',
                                    textUnderlineOffset: '8px',
                                }}
                            >
                                Edit User
                            </Typography>
                        )}
                    </Grid>
                    <Grid sx={{ width: '100%', textAlign: 'right' }}>
                        {window.location.href?.includes('editUser') ||
                        window.location.href?.includes('newUser') ? null : (
                            <>
                                <CustomIconButton
                                    sx={{
                                        backgroundColor: '#fff',
                                        width: '25px',
                                        height: '25px',
                                        paddingLeft: '20px',
                                    }}
                                    onClick={editUser}
                                >
                                    <EditIcon
                                        fontSize='small'
                                        sx={{
                                            color: '#787878',
                                            fontSize: '16px',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                color: '#5cb5b3',
                                                backgroundColor: 'transparent',
                                                background: 'none',
                                            },
                                        }}
                                    />
                                </CustomIconButton>
                            </>
                        )}
                    </Grid>
                    {window.location.href?.includes('newUser') ? (
                        <NewUserForm />
                    ) : window.location.href?.includes('editUser') ? (
                        <EditUserForm user={getIndividualUser} />
                    ) : (
                        <ViewIndividualUser />
                    )}
                </Grid>
            )}
            <ConfirmationModal
                open={open}
                onClose={handleCloseModal}
                onChange={handleChangeModal}
                message={'delete this user'}
                component={'user'}
            />
        </Container>
    );
}

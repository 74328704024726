import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import React from 'react';
const theme = createTheme({
    palette: {
        // @ts-ignore
        neutral: {
            main: '#fff',
            contrastText: '#5cb5b3',
        },
        green: {
            main: '#5cb5b3',
            contrastText: '#fff',
        },
        dark: {
            main: '#787878',
            contrastText: '#fff',
        },
    },
});

export function CustomIconButton({ children, ...props }: any) {
    return (
        <ThemeProvider theme={theme}>
            <IconButton  sx={{ fontFamily: 'Open Sans' }} {...props}>
                {children}
            </IconButton>
        </ThemeProvider>
    );
}

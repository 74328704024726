import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface IAuthType {
    accessToken?: any;
}
export const slice = createSlice({
    name: 'authDetails',
    initialState: { accessToken: null },
    reducers: {
        loadingAccessToken: (state, action: PayloadAction<IAuthType>) => {
            state.accessToken = action.payload.accessToken;
        },
        logOut: (state) => {
          state.accessToken = null;
      },
    },
});
export const { loadingAccessToken, logOut } = slice.actions;
export const getAccessToken:any = (state: RootState) => state.authDetails.accessToken;
export const AuthReducer = slice.reducer;

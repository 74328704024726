import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import Person2Icon from '@mui/icons-material/Person2';
import UserRole from '../../../shared/AuthRoles';
import ProfileHero from '../../components/Profile/View/ProfileHero';
import PersonalDetailsComponent from '../../components/Profile/View/PersonalDetails';
import OwnerCompanyDetails from '../../components/Profile/View/OwnerCompanyDetails';
import GuestCompanyDetails from '../../components/Profile/View/GuestCompanyDetails';
import React, { useEffect } from 'react';
import ProfileSummary from '../../components/Profile/View/ProfileSummary';

export default function ProfilePage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    const loggedInUser = UserRole();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <Person2Icon
                        sx={{
                            color: '#5cb5b3',
                            paddingRight: '5px',
                            fontSize: '32px',
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        Profile
                    </Typography>
                </Grid>
                <Grid
                    sx={{
                        textAlign: ' left',
                        paddingBottom: isMobile ? '10px' : '0px',
                    }}
                >
                    <ProfileHero />
                </Grid>
                {/* 
                    Render profile summary for owner/guest users and admins
                */}
                {!loggedInUser?.role?.includes('company') &&
                    loggedInUser?.role !== 'admin' && <ProfileSummary />}
                {loggedInUser?.role === 'admin' ? null : loggedInUser?.role ===
                      'owner_company' ||
                  loggedInUser?.role === 'owner_admin' ||
                  loggedInUser?.role === 'owner_user' ? (
                    <Grid
                        sx={{
                            width: '100%',
                            paddingTop: '30px',
                        }}
                    >
                        <OwnerCompanyDetails />
                    </Grid>
                ) : (
                    <Grid
                        sx={{
                            width: '100%',
                            paddingTop: '30px',
                        }}
                    >
                        <GuestCompanyDetails />
                    </Grid>
                )}
                {loggedInUser?.role === 'owner_company' ||
                loggedInUser?.role === 'guest_company' ? null : (
                    <>
                        <Grid
                            sx={{
                                width: '100%',
                                minHeight: 'auto',
                                justifyContent: 'left',
                            }}
                        >
                            <PersonalDetailsComponent />
                        </Grid>{' '}
                    </>
                )}
            </Grid>
        </Container>
    );
}

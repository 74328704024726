import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import IndividualExperiencePhotos from '../../../components/Experiences/IndividualExperiencePhotos';
import IndividualExperienceContent from '../../../components/Experiences/IndividualExperienceContent';
import IndividualExperienceHero from '../../../components/Experiences/IndividualExperienceHero';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGetIndividualAssetQuery } from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import LoadingComponent from '../../../../layout/styledcomponents/LoadingComponent';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectVisitTypeSliceData } from '../../../../app/Slices/VisitTypeSlice';

export default function IndividualExperiencePage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const navigate = useNavigate();
    const urlRef = window.location.href.split('/')[5];

    const location = useLocation();
    const visitTypeSelected: any = useSelector(selectVisitTypeSliceData);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onClickGoBack = () => {
        if (location?.state?.component === 'reports') {
            navigate(`/${location?.state?.url}`);
        } else {
            navigate(`/viewExperiences/${visitTypeSelected?.view}`);
        }
    };

    const {
        data: getIndividualAsset,
        isFetching,
        error,
    } = useGetIndividualAssetQuery(`modules/${urlRef}`);

    if (error) {
        navigate(`/*`);
    }
    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? 'auto' : '100%',
                paddingTop: isMobile || isIpad ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {isFetching === true ? (
                <Grid
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{
                        height: '100%',
                        width: '100%',
                        // backgroundColor: 'red',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : isIpad ? '100%' : '80%',
                        // backgroundColor: 'pink',
                        justifyContent: 'left',
                        alignItems: 'center',
                        // display: 'flex',
                        // flexDirection: 'row',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '2%' : '3%',
                            // backgroundColor: 'blue',
                            justifyContent: 'left',
                            display: 'flex',
                        }}
                    >
                        {/* {getIndividualAsset?.type === 'property' ? (
                        <MapsHomeWorkIcon
                            fontSize='large'
                            sx={{
                                // backgroundColor: 'pink',
                                color: '#5cb5b3',
                                // height: '100%'
                                paddingRight: '5px',
                                fontSize: '32px',
                            }}
                        />
                    ) : getIndividualAsset?.type === 'boat' ? (
                        <SailingIcon
                            fontSize='large'
                            sx={{
                                // backgroundColor: 'pink',
                                color: '#5cb5b3',
                                // height: '100%'
                                paddingRight: '5px',
                                fontSize: '32px',
                            }}
                        />
                    ) : getIndividualAsset?.type === 'vehicle' ? (
                        <DirectionsCarIcon
                            fontSize='large'
                            sx={{
                                // backgroundColor: 'pink',
                                color: '#5cb5b3',
                                // height: '100%'
                                paddingRight: '5px',
                                fontSize: '32px',
                            }}
                        />
                    ) : null} */}
                        <Typography
                            component='h1'
                            variant='h5'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                letterSpacing: '1px',
                            }}
                        >
                            {getIndividualAsset?.name}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            textAlign: ' left',
                            paddingBottom: isMobile ? '10px' : '0px',
                        }}
                    >
                        <IndividualExperienceHero
                            experience={getIndividualAsset}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: '100%',
                        }}
                    >
                        <IndividualExperiencePhotos
                            experience={getIndividualAsset}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '65%' : 'auto',
                            // backgroundColor: 'green',
                            justifyContent: 'left',
                            // alignItems: 'center',
                            // display: 'flex',
                            // flexDirection: 'row',
                            paddingBottom: isIpad ? '30px' : '',
                        }}
                    >
                        <IndividualExperienceContent
                            experience={getIndividualAsset}
                        />
                    </Grid>

                    <Grid
                        sx={{
                            width: '100%',
                            height: isMobile ? '80px' : '10%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            // backgroundColor: 'yellow',
                        }}
                    >
                        {location.state && (
                            <CustomButton
                                onClick={onClickGoBack}
                                color='green'
                                sx={{
                                    marginLeft: !isMobile ? '-8px' : '-18px',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#5cb5b3',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                }}
                            >
                                <ArrowBackIcon />{' '}
                                {isMobile ? null : (
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            paddingLeft: '5px',
                                        }}
                                    >
                                        Back to{' '}
                                        {location?.state?.component ===
                                        'reports'
                                            ? 'report'
                                            : 'experiences'}
                                    </Typography>
                                )}
                            </CustomButton>
                        )}
                    </Grid>
                </Grid>
            )}
        </Container>
    );
}

import {
    Box,
    Grid,
    ListItemText,
    Paper,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetReportQuery } from '../../../../app/Slices/SevenDayApiSlice';
import {
    PieChart,
    pieArcLabelClasses,
    pieArcClasses,
} from '@mui/x-charts/PieChart';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const size = {
    height: 200,
    margin: { right: 0 },
};

export default function UserDashboardLargerStats() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const [dashboardData, setDashboardData] = useState<any>([]);

    //GET REPORT
    const { data: getThisMonth } = useGetReportQuery(
        `/dashboard/scheduled-bookings?period=current-month`
    );
    const { data: getPendingReservations } = useGetReportQuery(
        `/dashboard/user-pending-bookings`
    );
    const { data: getApprovedReservations } = useGetReportQuery(
        `/dashboard/user-approved-bookings`
    );
    const { data: getCompletedReservations } = useGetReportQuery(
        `/dashboard/user-completed-bookings`
    );
    const navigate = useNavigate();
    const handleOpen = (report: any) => {
        if (report.reportName.includes('Scheduled')) {
            navigate(`/dashboard/scheduled/${report.reportUrl}`);
            ReactGA.send({
                hitType: 'pageview',
                page: `/dashboard/scheduled/${report.reportUrl}`,
                title: `Scheduled ${report.reportUrl} Report (User Dashboard)`,
            });
        } else {
            navigate(`/dashboard/user/${report.reportUrl}`);
            ReactGA.send({
                hitType: 'pageview',
                page: `/dashboard/user/${report.reportUrl}`,
                title: `User ${report.reportUrl} Report (User Dashboard)`,
            });
        }
    };

    useEffect(() => {
        let data = [
            {
                reportName: 'Scheduled This Month',
                statNo: getThisMonth?.length,
                reportUrl: 'current-month',
                experience: [
                    {
                        label: 'Boat',
                        value: getThisMonth?.filter(
                            (i: any) => i.moduleType === 'boat'
                        )?.length,
                    },
                    {
                        label: 'Vehicle',
                        value: getThisMonth?.filter(
                            (i: any) => i.moduleType === 'vehicle'
                        )?.length,
                    },
                    {
                        label: 'Property',
                        value: getThisMonth?.filter(
                            (i: any) => i.moduleType === 'property'
                        )?.length,
                    },
                    {
                        label: 'Activity',
                        value: getThisMonth?.filter(
                            (i: any) => i.moduleType === 'activity'
                        )?.length,
                    },
                    {
                        label: 'Commercial',
                        value: getThisMonth?.filter(
                            (i: any) => i.moduleType === 'commercial'
                        )?.length,
                    },
                ],
            },
            {
                reportName: 'Your Pending Reservations',
                statNo: getPendingReservations?.length,
                reportUrl: 'user-pending-bookings',
                experience: [
                    {
                        label: 'Boat',
                        value: getPendingReservations?.filter(
                            (i: any) => i.moduleType === 'boat'
                        )?.length,
                    },
                    {
                        label: 'Vehicle',
                        value: getPendingReservations?.filter(
                            (i: any) => i.moduleType === 'vehicle'
                        )?.length,
                    },
                    {
                        label: 'Property',
                        value: getPendingReservations?.filter(
                            (i: any) => i.moduleType === 'property'
                        )?.length,
                    },
                    {
                        label: 'Activity',
                        value: getPendingReservations?.filter(
                            (i: any) => i.moduleType === 'activity'
                        )?.length,
                    },
                    {
                        label: 'Commercial',
                        value: getPendingReservations?.filter(
                            (i: any) => i.moduleType === 'commercial'
                        )?.length,
                    },
                ],
            },

            {
                reportName: 'Your Upcoming Reservations',
                statNo: getApprovedReservations?.length,
                reportUrl: 'user-approved-bookings',
                experience: [
                    {
                        label: 'Boat',
                        value: getApprovedReservations?.filter(
                            (i: any) => i.moduleType === 'boat'
                        )?.length,
                    },
                    {
                        label: 'Vehicle',
                        value: getApprovedReservations?.filter(
                            (i: any) => i.moduleType === 'vehicle'
                        )?.length,
                    },
                    {
                        label: 'Property',
                        value: getApprovedReservations?.filter(
                            (i: any) => i.moduleType === 'property'
                        )?.length,
                    },
                    {
                        label: 'Activity',
                        value: getApprovedReservations?.filter(
                            (i: any) => i.moduleType === 'activity'
                        )?.length,
                    },
                    {
                        label: 'Commercial',
                        value: getApprovedReservations?.filter(
                            (i: any) => i.moduleType === 'commercial'
                        )?.length,
                    },
                ],
            },
            {
                reportName: 'Your Completed Reservations',
                statNo: getCompletedReservations?.length,
                reportUrl: 'user-completed-bookings',
                experience: [
                    {
                        label: 'Boat',
                        value: getCompletedReservations?.filter(
                            (i: any) => i.moduleType === 'boat'
                        )?.length,
                    },
                    {
                        label: 'Vehicle',
                        value: getCompletedReservations?.filter(
                            (i: any) => i.moduleType === 'vehicle'
                        )?.length,
                    },
                    {
                        label: 'Property',
                        value: getCompletedReservations?.filter(
                            (i: any) => i.moduleType === 'property'
                        )?.length,
                    },
                    {
                        label: 'Activity',
                        value: getCompletedReservations?.filter(
                            (i: any) => i.moduleType === 'activity'
                        )?.length,
                    },
                    {
                        label: 'Commercial',
                        value: getCompletedReservations?.filter(
                            (i: any) => i.moduleType === 'commercial'
                        )?.length,
                    },
                ],
            },
        ];
        setDashboardData(data);
    }, [
        getThisMonth,
        getPendingReservations,
        getApprovedReservations,
        getCompletedReservations,
    ]);

    return (
        <Box sx={{ width: '100%', paddingTop: '50px' }}>
            <Grid
                container
                rowSpacing={6}
                // columnSpacing={{ xs: 1, sm: 1, md: 3 }}
                spacing={isMobile ? 6 : 6}
                direction={isMobile || isIpad ? 'column' : 'row'}
            >
                {dashboardData.map((dashboardItem: any) => (
                    <Grid item xs={6}>
                        <Paper
                            key={dashboardItem?.reportName}
                            sx={{
                                height: '300px',
                                maxHeight: '350px',
                                flexGrow: 1,
                            }}
                            onClick={() => handleOpen(dashboardItem)}
                        >
                            <ListItemText
                                primary={
                                    <Typography
                                        gutterBottom
                                        variant='h5'
                                        component='div'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            textAlign: 'center',
                                            color: '#787878',
                                            '&:hover': {
                                                color: '#5cb5b3',
                                            },
                                        }}
                                    >
                                        {' '}
                                        {dashboardItem?.reportName}
                                    </Typography>
                                }
                                secondary={
                                    <Grid sx={{ marginTop: '-10px' }}>
                                        <Grid>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                    paddingTop: '-10px',
                                                }}
                                            >
                                                (by Experience)
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '16px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Total: {dashboardItem?.statNo}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            />

                            <PieChart
                                tooltip={{ trigger: 'item' }}
                                colors={['#A7C7E7', '#93C572', '#3b6978']} // Use palette
                                series={[
                                    {
                                        arcLabel: (item) =>
                                            `${item.label} (${item.value})`,
                                        arcLabelMinAngle: 45,
                                        data: dashboardItem?.experience,
                                        highlightScope: {
                                            faded: 'global',
                                            highlighted: 'item',
                                        },
                                        // faded: { innerRadius: 5, additionalRadius: -0 },
                                        color: '#5cb5b3',
                                        innerRadius: 10,
                                        outerRadius: 100,
                                        paddingAngle: 1,
                                        cornerRadius: 5,
                                        startAngle: -90,
                                        endAngle: 360,
                                        // cx: 150,
                                        // cy: 150,
                                    },
                                ]}
                                sx={{
                                    [`& .${pieArcLabelClasses.root}`]: {
                                        fill: 'white',
                                        fontWeight: 'bold',
                                    },
                                    [`& .${pieArcClasses.faded}`]: {
                                        fill: 'gray',
                                    },
                                }}
                                {...size}
                            />
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function ImportUsers() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <>
            {loggedInUser?.role === 'guest_admin' ? (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            If you're looking to onboard multiple users at once,
                            the system allows you to import users using a CSV
                            file. This saves time and ensures that all user
                            information is structured correctly for easy
                            processing.
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>
                                    From the top right corner of the app, click
                                    on the cog wheel icon
                                </li>
                                <li>
                                    Click on 'Users' from the Admin Center menu
                                </li>
                                <li>Select 'Import Users'</li>
                                <li>
                                    In the pop-up menu, click on 'Users
                                    Spreadsheet' to download the template
                                </li>
                                <li>
                                    Fill out the spreadsheet, ensuring all
                                    required fields are completed
                                </li>
                                <li>Save the file in CSV format</li>
                                <li>
                                    Go back to the 'Import Users' page and click
                                    'Upload CSV'
                                </li>
                                <li>
                                    After uploading the CSV file, the system
                                    will validate the data. If there are no
                                    errors, you can see the 'Import' button.
                                </li>

                                <li>
                                    Click on 'Import' to complete the process
                                </li>
                                <li>
                                    An invitation email will be sent to all new
                                    users
                                </li>
                                <li>
                                    Users who already exist in the system will
                                    not receive a new invitation and will be
                                    skipped during the import process.
                                </li>
                                <li>
                                    After receiving the invitation, each new
                                    user will need to complete their profile
                                    after their first login.
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            You currently do not have admin access. In order to
                            import a list of users, please request admin access
                            from your human resources team/company administrator
                            or alternatively ask someone who has admin access.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function ManageAReservation() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <>
            {loggedInUser?.role.includes('owner') ? (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Owners/Managers of an experience can manage their
                            own reservation and guest reservations. Depending on the
                            status of the reservation, determines what action you
                            can make:
                            <ul>
                                <li>
                                    PENDING - You can approve, decline or edit
                                </li>

                                <li>APPROVED - You can edit or cancel</li>
                                <li>COMPLETED - You can cancel</li>
                                <li>DECLINED - You can approve</li>
                                <li>CANCELLED - No action can be undone</li>
                            </ul>
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To make a change:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on 'Reservations'</li>
                                <li>
                                    Select the reservation you want to manage from
                                    the list or apply filters to narrow the
                                    search
                                </li>
                                <li>Scroll down to the bottom of the page</li>
                                <li>
                                    Select the action you would like to perform
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Users of the 7DAY App can manage their own reservation
                            only. Company Admins /Team Admins can manage
                            their own reservations and the reservations of other users.
                            Depending on the status of the reservation determines
                            what action you can take:
                            <ul>
                                <li>PENDING - You can edit or cancel</li>

                                <li>APPROVED - You can cancel</li>
                                <li>COMPLETED - No actions can be made</li>
                                <li>DECLINED - No actions can be made</li>
                                <li>CANCELLED - No actions can be made</li>
                            </ul>
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To make a change:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on 'Reservations'</li>
                                <li>
                                    Select the reservation you want to manage from
                                    the list or apply filters to narrow the
                                    search
                                </li>
                                <li>Scroll down to the bottom of the page</li>
                                <li>
                                    Select the action you would like to perform
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Card, Grid, InputLabel, Typography } from '@mui/material';
import { useState } from 'react';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import KitchenIcon from '@mui/icons-material/Kitchen';
import WifiIcon from '@mui/icons-material/Wifi';
import TvIcon from '@mui/icons-material/Tv';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HotTubIcon from '@mui/icons-material/HotTub';
import KitesurfingIcon from '@mui/icons-material/Kitesurfing';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import { LrgNumberArray, SmlNumberArray } from './NumberArray';
import React from 'react';
import { TimeArray } from '../Property/TimeArray';
import { Liquor, Restaurant } from '@mui/icons-material';
import FormTextField from '../../../../shared/FormTextField';
import FormAutocomplete from '../../../../shared/FormAutocomplete';

import { Controller, useFormContext, useWatch } from 'react-hook-form';

export default function NewBoatAmenitiesForm() {
    const theme = useTheme();

    const items = [
        {
            name: 'catering',
            selected: false,
            icon: <Restaurant fontSize='large' />,
        },
        {
            name: 'alcohol',
            selected: false,
            icon: <Liquor fontSize='large' />,
        },
        {
            name: 'boardroom',
            selected: false,
            icon: <BusinessCenterIcon fontSize='large' />,
        },
        {
            name: 'kitchen',
            selected: false,
            icon: <KitchenIcon fontSize='large' />,
        },
        {
            name: 'wifi',
            selected: false,
            icon: <WifiIcon fontSize='large' />,
        },
        {
            name: 'tv',
            selected: false,
            icon: <TvIcon fontSize='large' />,
        },
        {
            name: 'aircon',
            selected: false,
            icon: <AcUnitIcon fontSize='large' />,
        },
        {
            name: 'spa',
            selected: false,
            icon: <HotTubIcon fontSize='large' />,
        },
        {
            name: 'waterSports',
            selected: false,
            icon: <KitesurfingIcon fontSize='large' />,
        },
        {
            name: 'bbq',
            selected: false,
            icon: <OutdoorGrillIcon fontSize='large' />,
        },
    ];
    
    const { control, setValue } = useFormContext();

    return (
        <>
            <Typography sx={{ color: 'text.primary', pb: '30px' }}>
                Tell guests what your boat has to offer.
            </Typography>
            <Grid
                container
                columnSpacing={5}
                rowSpacing={1}
                pb='15px'
            >
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Boat Type</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete 
                            name='details.subType'
                            control={control}
                            options={[
                                { value: 'bass', label: 'Bass Boat' },
                                { value: 'cabin cruiser', label: 'Cabin Cruiser' },
                                { value: 'canoe', label: 'Canoe' },
                                { value: 'catamaran', label: 'Catamaran Boat' },
                                { value: 'dingy', label: 'Dingy' },
                                { value: 'jetboat', label: 'Jetboat' },
                                { value: 'runabout', label: 'Runabout Boat' },
                                { value: 'inflatable', label: 'Inflatable Boat' },
                                { value: 'sailboat', label: 'Sailing Boat' },
                                { value: 'skiff', label: 'Skiff Boat' },
                                { value: 'superyacht', label: 'Superyacht' },
                                { value: 'yacht', label: 'Yacht' },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Bedrooms</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.bedrooms'
                            control={control}
                            options={SmlNumberArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Beds</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.beds'
                            control={control}
                            options={SmlNumberArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Bathrooms</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.baths'
                            control={control}
                            options={SmlNumberArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Day Guests</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.maxGuests'
                            control={control}
                            options={LrgNumberArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>No. Overnight Guests</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.overnightGuests'
                            control={control}
                            options={LrgNumberArray}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel>Wifi Details</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormTextField
                            name='details.wifiDetails'
                            control={control}
                            placeholder='FREE WIFI, PW: 123456. This information will be displayed 24 hours before check-in and hidden after completed.'
                            inputProps={{ maxLength: 500 }}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Typography
                color='textPrimary'
                pb='20px'
                pt='30px'
            >
                Select available amenities:
            </Typography>
            <Grid container columns={{ lg: 7, md: 4, xs: 2 }} spacing={2}>
                {items.map((item, i) => (
                    <Grid item xs={1} key={i}>
                        <Controller 
                            name={`details.${item.name}`}
                            control={control}
                            render={({ field: { value }}) => (
                                <Card
                                    sx={{ 
                                        height: '100px',
                                        bgcolor: value ? theme.palette.primary.main : '#f6f8f9',
                                        color: value ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                    }}
                                    onClick={() => setValue(`details.${item.name}`, !value)}
                                >
                                    <Box 
                                        display='flex'
                                        flexDirection='column'
                                        alignItems='center'
                                        height='100%'
                                        gap={1}
                                        justifyContent='center'
                                        textAlign='center'
                                    >
                                        {item.icon}
                                        <Typography>
                                            {item.name === 'bbq'
                                                ? 'BBQ'
                                                : item.name === 'tv'
                                                ? 'TV'
                                                : item.name === 'waterSports'
                                                ? 'Water Sports'
                                                : item.name
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                item.name.slice(1)}
                                        </Typography>
                                    </Box>
                                </Card>
                            )}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function DeletePolicy() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            {loggedInUser?.role.includes('admin') ? (
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        There are two ways to delete a policy:
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                            paddingBottom: '5px',
                        }}
                    >
                        <ol>
                            <li>Remove the policy from the experience; OR</li>
                            <li>Delete the policy entirely from the system</li>
                        </ol>
                        Once deleted, this action cannot be undone.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To delete the policy from an individual experience:
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                            paddingBottom: '5px',
                        }}
                    >
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top left corner of the app, click on
                                'Experiences'
                            </li>
                            <li>
                                Select the experience you wish to remove the
                                policy from
                            </li>
                            <li>Select the 'edit' icon </li>
                            <li>Scroll down to the 'Policies' section</li>

                            <li>
                                Select 'Delete' next to the policy you wish to
                                remove
                            </li>

                            <li>
                                Click 'Update' on the experience to save changes
                            </li>
                        </ol>
                        Once saved, this policy will now be removed from the
                        experience.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To delete the policy from the system:
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top right corner of the app, click on
                                the cog wheel icon
                            </li>
                            <li>Select 'Settings'</li>
                            <li>Select 'Your Policies'</li>

                            <li>
                                Select the 'Edit' icon on the policy you would
                                like to delele
                            </li>
                            <li>
                                Click 'Delete' and enter a reason for the
                                deletion
                            </li>
                            <li>Click 'Update'</li>
                        </ol>
                        Once saved, this policy will deleted and cannot be
                        undone. Please note that you cannot delete a policy if
                        it is currently applied to experiences. You would need
                        to first remove the policy from the experiences before
                        you can master delete it.
                    </Typography>
                </Grid>
            ) : (
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Only admins can add a edit a policy. Please contact
                            a person that is an admin in your organisation to
                            add, edit or delete a policy.
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

import { Grid, InputLabel, Typography } from '@mui/material';
import React from 'react';
import FormCheckbox from '../../../shared/FormCheckbox';
import FormCounter from '../../../shared/FormCounter';

const GarageForm = ({ control }: { control: any }) => {
    return (
        <>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid item container xs={12} md={6}>
                    <Grid item xs={12} sx={{ pt: 2 }}>
                        <Typography color='textPrimary' fontWeight='bold'>
                            Amenities
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='evChargingStation'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>EV Charging Station</InputLabel>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='securitySystem'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Security System</InputLabel>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='automaticDoor'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Automatic Door</InputLabel>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='dehumidifier'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Dehumidifier</InputLabel>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='carwashStation'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Carwash Station</InputLabel>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='bicycleRack'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Bicycle Rack</InputLabel>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ pt: 2 }}>
                <Typography color='textPrimary' fontWeight='bold'>
                    Capacity
                </Typography>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <InputLabel>Parking Capacity</InputLabel>
                    <FormCounter name='parkingCapacity' disabled={false} />
                </Grid>
            </Grid>
        </>
    );
};

export default GarageForm;

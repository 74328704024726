import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function ViewReservation() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const loggedInUser = UserRole();
    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
        <Grid>
            <Typography
                sx={{
                    fontFamily: 'Open Sans',
                    color: '#787878',
                    paddingBottom: '20px',
                    fontSize: '14px',
                }}
            >
                There are multiple ways to view an individual or
                multiple reservation:
                <ul>
                    <li>Via the reservations list</li>
                    <li>Via the reservations calendar</li>
                    <li>Via the reports/dashboard</li>
                    <li>Via the notification section</li>
                </ul>
            </Typography>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To view a reservation (list view):
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>From the menu, click on 'Reservations'</li>
                        <li>
                            The initial view is a list view. This
                            will display all reservations. You can narrow
                            the list by clicking on the filter icon
                            on the right.
                        </li>
                        <li>Select the reservation you wish to view</li>
                    </ol>
                </Typography>
            </Grid>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To view a reservation (calendar view):
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>From the menu, click on 'Reservations'</li>
                        <li>
                            The initial view is a list view. To
                            change the view to calendar, click the
                            calendar icon on the right.
                        </li>
                        <li>
                            This will display all reservations for the
                            month. You can{' '}
                            <ul>
                                <li>
                                    Narrow the list by clicking on
                                    the filter icon on the right.
                                </li>
                                <li>
                                    Change the calendar view to day,
                                    week, month or list view
                                </li>
                            </ul>
                        </li>
                        <li>Select the reservation you wish to view</li>
                    </ol>
                </Typography>
            </Grid>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To view a reservation (report view):
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>From the menu, click on 'Reports'</li>
                        <li>
                            Select the report relevant to what you
                            need. Alternatively, use the customisable
                            report 'Reservations Report'.
                        </li>
                        <li>
                            Make your relevant filter selections if
                            needed
                        </li>
                        <li>Click on the reservation to view</li>
                    </ol>
                </Typography>
            </Grid>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To view a reservation (dashboard view):
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>
                            Click on either:{' '}
                            <ul>
                                <li>
                                    the 7DAY icon in the top left
                                    corner of the app
                                </li>
                                <li>
                                    the profile icon in the top
                                    right corner of the app and
                                    select 'Dashboard'
                                </li>
                            </ul>
                        </li>
                        <li>
                            Select the dashboard stat relevant to
                            what you need.
                        </li>

                        <li>Click on the reservation to view</li>
                    </ol>
                </Typography>
            </Grid>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To view a reservation (notification section):
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>
                            Click on the notification 'bell' located
                            in the top right corner of the app
                        </li>

                        <li>Click on the reservation to view</li>
                    </ol>
                </Typography>
            </Grid>
        </Grid>
    </Grid>
    );
}

import { Container, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useEditPolicyMutation,
    useGetIndividualPolicyQuery,
} from '../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../shared/AuthRoles';
import PolicyForm from '../../components/Policies/PolicyForm';
import toast from '../../shared/Toast';
import { Policy } from '../../types/policy';

const EditPolicyPage = () => {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');

    const navigate = useNavigate();

    const { id } = useParams();
    const { data: policy } = useGetIndividualPolicyQuery([
        loggedInUser?.company,
        id,
    ]);

    const [editPolicy] = useEditPolicyMutation();

    const onSubmit = (request: Policy) => {
        editPolicy([loggedInUser?.company, id, request]).then((data: any) => {
            if (data?.error) {
                toast.error(data.error.data.message);
            } else {
                toast.success(`Policy has been updated`);
                navigate('/policies');
            }
        });
    };

    useEffect(() => {
        if (loggedInUser.role !== 'owner_admin') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'policiesPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Stack width='100%' height='80%' gap='40px'>
                <Typography
                    variant='h5'
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        textTransform: 'uppercase',
                        textDecoration: 'underline',
                        textDecorationColor: '#5cb5b3',
                        textUnderlineOffset: '8px',
                    }}
                    gutterBottom
                >
                    EDIT POLICY
                </Typography>
                <PolicyForm onSubmit={onSubmit} policy={policy} />
            </Stack>
        </Container>
    );
};

export default EditPolicyPage;

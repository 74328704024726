import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

const initialState: { calendarError: boolean } = {
    calendarError: false,
};

export const calendarErrorSlice = createSlice({
    name: 'calendarError',
    initialState,
    reducers: {
        setCalendarError: (state, action: PayloadAction<boolean>) => {
            state.calendarError = action.payload;
        },
        toggleCalendarError: (state) => {
            state.calendarError = !state.calendarError;
        },
    },
});

export const { setCalendarError, toggleCalendarError} =
calendarErrorSlice.actions;

export const selectCalendarError = (state: RootState) =>
    state.calendarError.calendarError;

export const calendarErrorReducer = calendarErrorSlice.reducer;

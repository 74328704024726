import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function ResetPassword() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Passwords cannot be reset by another user. In order for
                        a password to be reset, the user will need to follow the
                        below steps.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        To reset your password:
                        <ol>
                            <li>Navigate to the 7DAY App</li>
                            <li>Click 'Forgot Password'</li>
                            <li>Enter your email address</li>
                            <li>
                                You will receive an email with a link. Click on
                                the link and reset your password
                            </li>
                        </ol>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetIndividualBookingCapQuery } from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import BookingCapForm from '../../components/BookingCaps/BookingCapForm';
import ReactGA from 'react-ga4';
import UserRole from '../../../shared/AuthRoles';

const EditBookingCapPage = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const [url, setUrl] = useState('');
    const { data: getIndividualBookingCap, isFetching } =
        useGetIndividualBookingCapQuery(url);
    const urlRef = window.location.href.split('/')[4];
    const navigate = useNavigate();
    const loggedInUser = UserRole();

    useEffect(() => {
        if (urlRef !== undefined) {
            setUrl(`/booking-caps/${urlRef}`);
        }
    }, []);

    useEffect(() => {
        if (loggedInUser.role !== 'owner_admin') {
            navigate(`/permissionDenied`, {
                state: {
                    name: 'feesPage',
                },
            });
            ReactGA.send({
                hitType: 'pageview',
                page: `/permissionDenied`,
                title: `Permission Denied`,
            });
        }
    }, [loggedInUser.role]);

    const handleBackButton = () => {
        navigate(`/bookingCaps`);
    };

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                height: isMobile || isIpad ? 'auto' : '100%',
                paddingTop: isMobile ? '100px' : null,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {isFetching === true ? (
                <Grid
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <LoadingComponent color='green' />
                </Grid>
            ) : (
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '100%' : '80%',
                        justifyContent: 'left',
                        alignItems: 'center',
                    }}
                >
                    <BookingCapForm capProp={getIndividualBookingCap} />
                    <CustomButton
                        onClick={handleBackButton}
                        color='green'
                        sx={{
                            marginLeft: !isMobile ? '-8px' : '-18px',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <ArrowBackIcon />{' '}
                        {isMobile ? null : (
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontSize: '12px',
                                    paddingLeft: '5px',
                                }}
                            >
                                Back to list
                            </Typography>
                        )}
                    </CustomButton>
                </Grid>
            )}
        </Container>
    );
};

export default EditBookingCapPage;

import React, { useEffect, useState } from 'react';
import {
    Grid,
    InputLabel,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import {
    useGetEarningsQuery,
    useGetIndividualOwnerQuery,
    useGetPlatformSettingsQuery,
} from '../../../app/Slices/SevenDayApiSlice';
import LoadingComponent from '../../../layout/styledcomponents/LoadingComponent';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PaymentsChart } from './PaymentsChart';
import { PaymentsList } from './PaymentsList';
import { formatCurrencyShort } from '../../../shared/utils';
import { useForm } from 'react-hook-form';
import FormSelect from '../../shared/FormSelect';
import { useNavigate } from 'react-router-dom';
import UserRole from '../../../shared/AuthRoles';

export const Earnings = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const navigate = useNavigate();
    const [years, setYears] = useState<{ value: number; label: string }[]>([]);

    const now = new Date();
    const currentYear = now.getFullYear();
    const { control, watch } = useForm<{ year: number }>({
        defaultValues: { year: currentYear },
    });
    const { data: earnings } = useGetEarningsQuery({ year: watch('year') });

    const role = UserRole();
    const { data: company } = useGetIndividualOwnerQuery(
        `owners/${role.company}`
    );
    const { data: platformSettings } =
        useGetPlatformSettingsQuery('/platform-settings');
    useEffect(() => {
        if (!company) return;
        const options: { value: number; label: string }[] = [];
        const companyFirstYear = new Date(company.createdAt).getFullYear();
        for (let year = currentYear; year >= companyFirstYear; year--) {
            options.push({
                value: year,
                label: `${moment(new Date(year, 0, 1)).format(
                    'D MMM'
                )} - ${moment(
                    year === currentYear ? now : new Date(year, 12, 0)
                ).format('D MMM, YYYY')}`,
            });
        }
        setYears(options);
    }, [company]);

    return earnings && company && platformSettings ? (
        <Stack width='100%' spacing={5}>
            <Grid
                container
                sx={{
                    paddingTop: '30px',
                }}
            >
                <Grid item xs={8.5} lg={4}>
                    <InputLabel>Earnings Period</InputLabel>
                    <FormSelect name='year' control={control} options={years} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid container item>
                    <Grid item xs={6.5} md={3} lg={2}>
                        <Typography color='textPrimary'>Total</Typography>
                    </Grid>
                    <Grid item xs={3} md={2} textAlign={'right'}>
                        <Typography color='textPrimary'>
                            {formatCurrencyShort(earnings.bookingsTotal)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={6.5} md={3} lg={2}>
                        <Typography color='textPrimary'>
                            7DAY Service Fee (
                            {platformSettings.serviceFeePercentage}%)
                        </Typography>
                    </Grid>
                    <Grid item xs={3} md={2} textAlign={'right'}>
                        <Typography color='textPrimary'>
                            {formatCurrencyShort(earnings.serviceFeeAmount)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={6.5} md={3} lg={2}>
                        <Typography color='textPrimary'>
                            Gross Earnings Paid
                        </Typography>
                    </Grid>
                    <Grid item xs={3} md={2} textAlign={'right'}>
                        <Typography color='textPrimary'>
                            {formatCurrencyShort(earnings.amountPaid)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={6.5} md={3} lg={2}>
                        <Typography color='textPrimary'>
                            Gross Earnings Unpaid
                        </Typography>
                    </Grid>
                    <Grid item xs={3} md={2} textAlign={'right'}>
                        <Typography color='textPrimary'>
                            {formatCurrencyShort(earnings.amountDue)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <PaymentsChart payments={earnings.bills} year={watch('year')} />
            <PaymentsList payments={earnings.bills} />
            <Grid>
                <CustomButton
                    onClick={() => navigate(-1)}
                    color='green'
                    sx={{
                        marginLeft: !isMobile ? '-8px' : '-18px',
                        '&:hover': {
                            background: 'none',
                        },
                    }}
                >
                    <ArrowBackIcon />
                    {isMobile ? null : (
                        <Typography
                            sx={{
                                fontSize: '12px',
                                paddingLeft: '5px',
                            }}
                        >
                            Back
                        </Typography>
                    )}
                </CustomButton>
            </Grid>
        </Stack>
    ) : (
        <LoadingComponent color='green'></LoadingComponent>
    );
};

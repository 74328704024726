import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface DriverState {
    driverSameAsBooker: boolean;
    bookerIsSelected: boolean;
}

const initialState: DriverState = {
    driverSameAsBooker: false,
    bookerIsSelected: true,
};

export const driverSameAsBookerSlice = createSlice({
    name: 'driverSameAsBooker',
    initialState,
    reducers: {
        setDriverSameAsBooker: (state, action: PayloadAction<boolean>) => {
            state.driverSameAsBooker = action.payload;
        },
        toggleDriverSameAsBooker: (state) => {
            state.driverSameAsBooker = !state.driverSameAsBooker;
        },
        setBookerIsSelected: (state, action: PayloadAction<boolean>) => {
            state.bookerIsSelected = action.payload;
        },
        toggleBookerIsSelected: (state) => {
            state.bookerIsSelected = !state.bookerIsSelected;
        },
    },
});

export const {
    setDriverSameAsBooker,
    toggleDriverSameAsBooker,
    setBookerIsSelected,
    toggleBookerIsSelected,
} = driverSameAsBookerSlice.actions;

export const selectDriverSameAsBooker = (state: RootState) =>
    state.driverSameAsBooker.driverSameAsBooker;

export const selectBookerIsSelected = (state: RootState) =>
    state.driverSameAsBooker.bookerIsSelected;

export const driverSameAsBookerReducer = driverSameAsBookerSlice.reducer;

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import {
    useCancelRecurringReservationMutation,
    useCancelReservationMutation,
} from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import DropdownFilter from '../../../../layout/styledcomponents/filters/Dropdown';
import UserRole from '../../../../shared/AuthRoles';
import toast from '../../../shared/Toast';

export default function CancelReservationModal(props: any) {
    const [cancelReservation] = useCancelReservationMutation();
    const [cancelRecurringReservation] =
        useCancelRecurringReservationMutation();
    const { onClose, open, onChange, message, component, id } = props;
    const loggedInUser = UserRole();
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [cancelReason, setCancelReason] = useState('');
    const handleReason = (value: any) => {
        setCancelReason(value);
    };

    const handleClose = () => {
        onClose();
        ReactGA.event({
            category: 'UI',
            action: `Confirfmed Cancel Reservation (${loggedInUser?.role})`,
            label: `Confirmed Cancel Reservation Clicked (${loggedInUser?.role})`,
        });
    };

    const handleCloseChangeModal = () => {
        onChange();
    };
    const confirmCancel = () => {
        let requestBody = [id, { reason: cancelReason }];

        if (component === 'recurringReservation') {
            cancelRecurringReservation(requestBody).then((data: any) => {
                if (data) {
                    toast.success(`Reservation has been cancelled`);
                } else {
                    toast.error(`Failed to cancel reservation`);
                }
            });
        } else {
            cancelReservation(requestBody).then((data: any) => {
                if (data) {
                    toast.success(`Reservation has been cancelled`);
                } else {
                    toast.error(`Failed to cancel reservation`);
                }
            });
        }

        handleCloseChangeModal();
    };

    let ownerReasons = [
        { value: 'Customer Cancellation', label: 'Customer Cancellation' },
        { value: "Guest didn't show up", label: "Guest didn't show up" },
        { value: 'Maintenance', label: 'Maintenance' },
        { value: 'Staff Unavailable', label: 'Staff Unavailable' },
        { value: 'Owners Request', label: 'Owners Request' },
        { value: 'Other', label: 'Other' },
        { value: 'Weather', label: 'Weather' },
    ];
    let guestReasons = [
        { value: 'No Longer Needed', label: 'No Longer Needed' },
        { value: 'Weather', label: 'Weather' },
        { value: 'Other', label: 'Other' },
    ];

    return (
        <Dialog open={open} onClose={handleClose}>
            <Grid
                sx={{
                    // background: ' pink',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Grid
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // background: 'yellow',
                        textAlign: 'left',
                        height: '50px',
                        width: '600px',
                        paddingLeft: '18px',
                        alignContent: 'center',
                        alignItems: 'center',
                        borderBottom: ' #f6f8f9 solid 1px',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            // borderBottom: ' #d8d8d8 solid 1px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: isMobile ? '12px' : '',
                            }}
                            variant='h6'
                        >
                            Are you sure you want to {message}?
                        </Typography>
                    </Grid>
                </Grid>
                <DialogContent
                    dividers
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '200px',
                    }}
                >
                    <DialogContentText>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Grid sx={{ width: isMobile ? 'auto' : '500px' }}>
                                {component === 'recurringReservation' ? (
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: isMobile ? '14px' : '',
                                        }}
                                        variant='h6'
                                    >
                                        Note: All individual reservations
                                        belonging to this recurring reservation
                                        will all be cancelled.
                                    </Typography>
                                ) : null}
                                <DropdownFilter
                                    id={'cancelReason'}
                                    options={
                                        loggedInUser?.role.includes('owner')
                                            ? ownerReasons
                                            : guestReasons
                                    }
                                    handleChange={handleReason}
                                    selectedValue={
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                color: '#787878',
                                                fontSize: '16px',
                                                marginRight: '5px',
                                            }}
                                        >
                                            {cancelReason}
                                        </Typography>
                                    }
                                    backgroundColor={'white'}
                                    border={'#C3C3C3'}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '10px',
                                        color: 'red',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    * Once cancelled this action cannot be
                                    undone.
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>{' '}
                <DialogActions
                    sx={{
                        borderTop: ' #d8d8d8 solid 1px',
                        // minHeight: '60px',
                        background: ' #eeeeee',
                    }}
                >
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color='neutral'
                            // autoFocus
                            onClick={handleClose}
                        >
                            Abort
                        </CustomButton>
                    </Grid>
                    <Grid>
                        {' '}
                        <CustomButton
                            variant='contained'
                            color='warning'
                            onClick={confirmCancel}
                            disabled={cancelReason === ''}
                            // autoFocus
                            sx={{
                                top: isMobile || isIpad ? '10%' : '50%',
                            }}
                        >
                            Cancel Reservation
                        </CustomButton>
                    </Grid>
                </DialogActions>
            </Grid>
        </Dialog>
    );
}

import React, { useEffect, useState } from 'react'
import { FileRepository } from '@amityco/ts-sdk';
import { Attachment } from '@mui/icons-material';
import { Link, Typography } from '@mui/material';

interface FileContentProps {
    data: Amity.ContentDataFile
}

const FileContent = ({ data }: FileContentProps) => {
    const [file, setFile] = useState<Amity.File>();

    const fetchFile = async () => {
        const response = await FileRepository.getFile(data?.fileId)
        setFile(response.data);
    }

    useEffect(() => {
        fetchFile();
    }, [data]);

    return (
        <>     
            <Link
                href={file?.fileUrl}
                color='inherit'
                sx={{
                    '&:hover': { color: 'inherit'}
                }}
            >
                <Attachment/>
                {file?.attributes.name}
            </Link>
        </>
    )
}

export default FileContent
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StyledFilterButton, StyledFilterText } from './SharedFilterComponents';
import { usePopoverOnButtonClick } from './filterHooks';
import {
    Box,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemButton,
    Popover,
    useMediaQuery,
    ListItemIcon,
    ListItemText,
    Typography,
    Grid,
    Input,
    InputAdornment,
} from '@mui/material';
import { Add, KeyboardArrowDown } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React, { ChangeEvent, useEffect, useState } from 'react';
import UseDebounceInput from '../../../shared/DebounceInput';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import UserRole from '../../../shared/AuthRoles';

const theme = createTheme({
    palette: {
        action: {
            disabledBackground: '#E0E0E0',
            disabled: '#E0E0E0',
        },
    },
});

export default function MultiSelectDropdownFilter({
    id,
    options,
    handleChange,
    selectedValue,
    label = '',
    isTopLevel = true,
    backgroundColor,
    border,
    minHeight,
    maxHeight,
    disabled,
    handleObject,
    selectedObject,
}: {
    id?: any;
    options?: any;
    handleChange?: any;
    label?: string;
    selectedValue: any[];
    isTopLevel?: boolean;
    backgroundColor?: string;
    border?: string;
    minHeight?: string;
    maxHeight?: string;
    disabled?: boolean;
    handleObject?: any;
    selectedObject?: any;
}) {
    const navigate = useNavigate();

    const { handleClick, handleClose, open, popoverId, anchorEl } =
        usePopoverOnButtonClick();
    const [searchText, setSearchText] = UseDebounceInput(10);
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [filteredByActiveOptions, setFilteredByActiveOptions] =
        React.useState<any[]>([]);

    const [filteredBySearchTextOptions, setFilteredBySearchTextOptions] =
        useState<any[]>([]);
    // if component requires not just the value but the object for more information we store the entire object so we can use information in other component
    const [checkedValues, setCheckedValues] = React.useState<string[]>([]);
    const [checkedObjects, setCheckedObjects] = React.useState<string[]>([]);

    useEffect(() => {
        if (id === 'excludedUserList') {
            let getValueId = options?.map((guest: any) => ({
                value: guest?.id,
                label: `${guest.firstName} ${guest.lastName} - ${guest.email} (${guest.companyName})`,
            }));
            setCheckedObjects(selectedObject);
            setCheckedValues(selectedValue);
            setFilteredByActiveOptions(getValueId);
        } else {
            setCheckedValues(selectedValue);
            setFilteredByActiveOptions(options);
        }
    }, [selectedValue, options, selectedObject]);

    useEffect(() => {
        if (id === 'excludedUserList') {
            let getValueId = options?.map((guest: any) => ({
                value: guest?.id,
                label: `${guest.firstName} ${guest.lastName} - ${guest.email} (${guest.companyName})`,
            }));
            setFilteredBySearchTextOptions(
                getValueId?.filter((option: any) => {
                    if (
                        option.label
                            ?.toLowerCase()
                            ?.replace(/\s/g, '')
                            ?.includes(
                                searchText?.toLowerCase()?.replace(/\s/g, '')
                            )
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                })
            );
        } else {
            setFilteredBySearchTextOptions(
                options?.filter((option: any) => {
                    if (
                        option.label
                            ?.toLowerCase()
                            ?.replace(/\s/g, '')
                            ?.includes(
                                searchText?.toLowerCase()?.replace(/\s/g, '')
                            )
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                })
            );
        }
    }, [options, searchText]);

    const handleToggle = (item: any) => () => {
        const currentIndex = checkedValues?.indexOf(item?.value);
        const newChecked: string[] = [...checkedValues];
        const checkedObj: string[] = [...checkedObjects];

        if (currentIndex === -1) {
            newChecked?.push(item?.value);
            checkedObj?.push(item);
        } else {
            newChecked?.splice(currentIndex, 1);
            checkedObj?.splice(currentIndex, 1);
        }

        if (id === 'excludedUserList') {
            // if component requires not just the value but the object for more information we store the entire object so we can use information in other component
            setCheckedObjects(checkedObj);
            handleObject([...checkedObj]);
            setCheckedValues(newChecked);
            handleChange([...newChecked]);
        } else {
            setCheckedValues(newChecked);
            handleChange([...newChecked]);
        }
    };

    const onChangeSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setCheckedValues(
                filteredBySearchTextOptions?.map((i: any) => i.value)
            );

            handleChange(filteredBySearchTextOptions?.map((i: any) => i.value));
        } else {
            setCheckedValues([]);
            handleChange([]);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <StyledFilterButton
                disableElevation
                // disableRipple
                disabled={disabled}
                aria-describedby={popoverId}
                onClick={handleClick}
                variant={disabled ? 'contained' : 'outlined'}
                device={isMobile.toString()}
                onChange={handleChange}
                sx={{
                    backgroundColor: disabled
                        ? '#E0E0E0'
                        : open
                        ? '#fff'
                        : backgroundColor,
                    borderColor: disabled
                        ? '#E0E0E0'
                        : open
                        ? '#5cb5b3'
                        : border,
                    color: open ? '#5cb5b3' : '#787878',
                    minHeight: minHeight,
                    maxHeight: maxHeight,
                }}
            >
                <StyledFilterText variant={'body2'}>
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '16px',
                                marginRight: '5px',
                            }}
                        >
                            {label}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: isMobile ? '16px' : '14px',
                            }}
                        >
                            {checkedValues?.length === options?.length
                                ? options?.length === 0
                                    ? ''
                                    : `All ${checkedValues?.length} selected`
                                : checkedValues?.length === 0
                                ? 'Please select'
                                : id === 'excludedUserList'
                                ? ` Select users`
                                : `${checkedValues?.length} ${
                                      options?.length === 0
                                          ? 'Selected'
                                          : `of ${options?.length} Selected`
                                  }`}
                        </Typography>
                    </Grid>
                </StyledFilterText>
                <Box width={10} flexGrow={1} />
                {isTopLevel ? (
                    <KeyboardArrowDown />
                ) : (
                    <KeyboardArrowRightIcon />
                )}
            </StyledFilterButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                anchorOrigin={{
                    vertical: isTopLevel ? 'bottom' : 'top',
                    horizontal: isTopLevel ? 'left' : 'right',
                }}
            >
                <List sx={{ maxHeight: '200px', overflow: 'auto' }}>
                    {options?.length > 10 ? (
                        <ListItem>
                            <Input
                                defaultValue={searchText}
                                placeholder={'Search'}
                                endAdornment={
                                    <InputAdornment position={'end'}>
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </ListItem>
                    ) : null}

                    <ListItem
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            '&:hover': {
                                cursor: 'pointer',
                                // color: '#5cb5b3',
                                backgroundColor: '#f6f8f9',
                            },
                            display:
                                id === 'excludedUserList' ? 'none' : 'flex',
                        }}
                    >
                        <ListItemButton
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            <ListItemIcon>
                                {' '}
                                <Checkbox
                                    sx={{ p: 0 }}
                                    size={'small'}
                                    edge='start'
                                    tabIndex={-1}
                                    disableRipple
                                    checked={
                                        checkedValues?.length ===
                                        filteredBySearchTextOptions?.length
                                    }
                                    onChange={onChangeSelectAll}
                                    indeterminate={
                                        checkedValues?.length !== 0 &&
                                        checkedValues?.length !==
                                            filteredBySearchTextOptions?.length
                                    }
                                />
                            </ListItemIcon>

                            <ListItemText>
                                <Typography
                                    variant={'body2'}
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        color: '#787878',
                                    }}
                                >
                                    {checkedValues?.length === 0
                                        ? 'Select All'
                                        : checkedValues?.length ===
                                          filteredBySearchTextOptions?.length
                                        ? `All ${checkedValues?.length} selected`
                                        : `${checkedValues?.length} of ${filteredBySearchTextOptions?.length} Selected`}
                                </Typography>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <Divider
                        sx={{
                            display:
                                id === 'excludedUserList' ? 'none' : 'flex',
                        }}
                    />
                    {id === 'staffAttending' && (
                        <ListItem
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                '&:hover': {
                                    cursor: 'pointer',
                                    // color: '#5cb5b3',
                                    backgroundColor: '#f6f8f9',
                                },
                                // display:
                                //     id === 'excludedUserList' ? 'none' : 'flex',
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    pl: 0,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                                onClick={() => navigate('/newUser')}
                            >
                                <ListItemIcon>
                                    <Add color='disabled' />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography
                                        variant={'body2'}
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        Create New User
                                    </Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    )}
                    {filteredBySearchTextOptions?.map(
                        (option: { value: any; label: any }) => (
                            <ListItem
                                // onClick={() => {
                                //     handleChange(option.value);
                                //     // handleClose();
                                // }}
                                key={option.value}
                                value={option.value}
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                    '&:hover': {
                                        cursor: 'pointer',
                                        // color: '#5cb5b3',
                                        backgroundColor: '#f6f8f9',
                                    },
                                }}
                            >
                                <ListItemButton
                                    onClick={handleToggle(option)}
                                    dense
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            sx={{ p: 0 }}
                                            size={'small'}
                                            edge='start'
                                            tabIndex={-1}
                                            disableRipple
                                            checked={
                                                checkedValues?.indexOf(
                                                    option?.value
                                                ) !== -1
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText>{option.label}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )
                    )}
                </List>
            </Popover>
        </ThemeProvider>
    );
}

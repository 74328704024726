import { ChannelRepository, Client, SubChannelRepository, getChannelTopic, subscribeTopic } from '@amityco/ts-sdk'
import { Avatar, AvatarGroup, Box, Drawer, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MessageList from '../MessageList'
import ChannelHeader from '../ChannelHeader'
import MessageComposer from '../MessageComposer'
import ChannelDetails from '../ChannelDetails'
import { useGetChatUsersQuery } from '../../../../app/Slices/SevenDayApiSlice'
import UserRole from '../../../../shared/AuthRoles'

interface ChatRoomProps {
    channelId: string,
    handleBack: () => void,
}

const disposers: Amity.Unsubscriber[] = [];

const ChatRoom = ({ channelId, handleBack }: ChatRoomProps) => {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 1200px)');

    const [channel, setChannel] = useState<Amity.Channel>();
    const [members, setMembers] = useState<Amity.Membership<'channel'>[]>([]);
    const [open, setOpen] = useState<boolean>(!isMobile);
    
    const { data } = useGetChatUsersQuery(members.map((member) => member.userId), { skip: members.length === 0})

    useEffect(() => {
        setChannel(undefined);
        setMembers([]);

        const unsub = ChannelRepository.getChannel(channelId, ({ data: channel, error, loading }) => {
            if (channel && !loading && !error) {
                setChannel(channel);
            }
        });

        disposers.push(unsub);

        ChannelRepository.Membership.getMembers({ channelId: channelId, memberships: ['member', 'muted'] }, 
            ({ data: members, loading}) => {
                if (members && !loading) {
                    setMembers(members)
                }
            }
        );

        // Cleanup function to unsubscribe from topics
        return () => {
            disposers.forEach((unsub) => {
                unsub();
                console.log('unsub')
            });

            disposers.length = 0;
        };
    }, [channelId]);

    const muted = members.some((member) => member.userId === loggedInUser?.sub.toString() && member.isMuted);

    return (
        <Box
            display='flex'
            flexDirection='column'
            height='100vh'
            ml={isMobile ? 0 : '400px'}
            mr={open ? '400px' : 0}
            sx={{ transition: 'margin-right 200ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}
        >
            {channel && (
                <>
                    <ChannelHeader 
                        {...channel} 
                        handleBack={handleBack} 
                        handleOpen={() => setOpen(!open)}
                    />
                    <MessageList 
                        channelId={channelId} 
                        users={data?.users}
                    />
                    <MessageComposer 
                        channelId={channelId}
                        muted={muted}
                    />
                    <ChannelDetails
                        users={data?.users}
                        {...channel}
                        open={open}
                        handleClose={() => setOpen(false)}
                    />
                </>
            )}
            
        </Box>
    )
}

export default ChatRoom
import { styled } from '@mui/material/styles';
import {
    Button,
    Typography,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import React from 'react';
export const FilterLabel = styled(Button)`
    display: block;
`;

interface StyledFilterButtonProps  {
    hasError?: boolean;
    device?: any;
    disabled?: boolean;
}

export const StyledFilterButton = styled(Button, {
    shouldForwardProp: (propName) => propName !== 'hasError',
})<StyledFilterButtonProps>(({ device, hasError,disabled, onClick }) => ({
    height: '42px',
    width: '100%',
    display: 'flex',
    alignItems: device ? 'left' : 'center',
    marginBottom: device ? '10px' : '0px',
    marginRight: device ? '0px' : '10px',
    flexGrow: 1,
    minWidth: 0,
    fontFamily: 'Open Sans',
    fontSize:'14px',
    justifyContent: 'flex-start',
    backgroundColor: disabled ? '#E0E0E0':'#f6f8f9',
    color: '#787878',
    borderColor: disabled ? '#E0E0E0':'#fff',
    '&:hover': {
        backgroundColor: '#f6f8f9',
        borderColor: '#787878',
    },
}));
const StyledFilterTypography = styled(Typography)(({ theme }) => ({
    textTransform: 'capitalize',
    // fontWeight: 600,
    fontFamily: 'Open Sans',
    fontSize:'16px',
    color: '#787878',

}));

export function StyledFilterText({
    children,
    variant = 'body2',
}: {
    children: any;
    variant: Variant;
}) {
    return (
        <StyledFilterTypography variant={variant}>
            {children}
        </StyledFilterTypography>
    );
}

// export const LightErrorTooltip = styled(
//     ({ className, ...props }: TooltipProps) => (
//         <Tooltip {...props} classes={{ popper: className }} />
//     )
// )(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: theme.palette.common.white,
//         color: theme.palette.error.main,
//         boxShadow: theme.shadows[1],
//         fontSize: 11,
//     },
// }));

// export const StyledNonFilterButton = styled(Button)<StyledFilterButtonProps>(
//     ({ theme }) => ({
//         height: theme.spacing(9),
//         display: 'flex',
//         alignItems: 'center',
//         // padding: '10px',
//         flexGrow: 1,
//         minWidth: 0,
//         justifyContent: 'flex-start',
//         backgroundColor: 'white',
//         // boxShadow: 'none',

//         '&.MuiButton-containedPrimary': {
//             backgroundColor: theme.palette.grey[400],
//             color: theme.palette.primary.main,
//             textTransform: 'capitalize',
//         },
//         '&.MuiButton-containedPrimary:focus': {
//             // boxShadow: 'none',
//             // backgroundColor: theme.palette.grey[500],
//             backgroundColor: theme.palette.grey[400],
//             textTransform: 'capitalize',
//             // color: theme.palette.primary.contrastText,
//             color: theme.palette.primary.main,
//         },
//         '&.MuiButton-containedPrimary:hover': {
//             // boxShadow: 'none',
//             // backgroundColor: theme.palette.primary,
//             backgroundColor: 'red',
//             color: theme.palette.primary.contrastText,
//             textTransform: 'capitalize',
//         },
//     })
// );

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function TeamsExplanation() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        The 'Team' feature allows organisations to better
                        control who has access to view/edit users and users
                        reservations. By creating a 'Team' and applying it to
                        users and selecting a 'Tean Admin', it will group
                        those users within the same 'Team' and appear under
                        the 'Team Admins' profile. The Team Admin
                        will have access to then view/edit the user as well as
                        view and manage their reservations. Team Admins cannot
                        view a user that is not in the same team as them.
                        Only Company Admins can view all users across any
                        team.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

import { useLazyGetXeroConsentUrlQuery } from '../../../../app/Slices/SevenDayApiSlice';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { XeroConnection } from '../../../../app/Slices/SevenDayApiTypes';
import toast from '../../../shared/Toast';

export const XeroConnectionComponent = ({
    connection,
}: {
    connection?: XeroConnection;
}) => {
    const [getXeroConsentUrl] = useLazyGetXeroConsentUrlQuery();

    const handleConnect = async () => {
        const { data } = await getXeroConsentUrl();
        if (data) {
            window.location.href = data.url;
        } else {
            toast.error(`Failed to connect to Xero.`);
        }
    };

    return (
        <Grid container spacing={4}>
            <Grid container item>
                <Typography
                    color='textPrimary'
                    sx={{
                        fontWeight: 900,
                    }}
                >
                    Xero Connection
                </Typography>
            </Grid>
            <Grid container item spacing={1}>
                <Grid item xs={12} lg={2}>
                    <Typography color='textPrimary'>
                        Connected Organisation
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Typography color='textPrimary'>
                        {connection ? connection.orgName : '[Not Connected]'}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item>
                <CustomButton
                    //variant='contained'
                    color='green'
                    onClick={handleConnect}
                    sx={{
                        pl: '0px',
                        fontWeight: 550,
                    }}
                >
                    {connection ? 'Reconnect' : 'Connect'} to Xero
                </CustomButton>
            </Grid>
        </Grid>
    );
};

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import UserRole from '../../../../../../shared/AuthRoles';

export default function ServiceFee() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    What is 7DAY's Service Fee?
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                        paddingTop: '15px',
                    }}
                >
                    7DAY takes a cut of your overall charge per experience, per
                    booking. This is called the 7DAY Service Fee. This Service
                    Fee is subject to change, however you will be notified in
                    advance of any changes.
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                        paddingTop: '15px',
                    }}
                >
                    To view the Service Fee and what your netpay would be,
                    follow these steps:
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>Click on 'Experiences' from the menu</li>
                        <li>Select 'Manage Experiences' </li>
                        <li>Select a Experience</li>
                        <li>Click the edit icon (pencil icon)</li>
                        <li>Scroll down to 'Pricing'</li>
                        <li>
                            If you have entered your Pricing and Fees for this experience, you will
                            see the 'Your Earnings' section where it will advise
                            what the 7DAY Service Fee % is and what your Netpay
                            will be. If you do not have any Price/Fees entered,
                            please enter them.
                        </li>
                        
                    </ol>
                </Typography>
            </Grid>
        </Grid>
    );
}

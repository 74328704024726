import { Grid, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import { CustomIconButton } from '../../../../layout/styledcomponents/CustomIconButton';
import React from 'react';
import ReactGA from 'react-ga4';

export default function ProfileHero() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const urlRef = window.location.href.split('/')[3];
    const navigate = useNavigate();


    const editProfile = () => {
        navigate(`/editProfile`, {
            state: {
                name: 'editProfile',
            },
        });
        ReactGA.send({
            hitType: 'pageview',
            page: `/editProfile`,
            title: `Edit Profile`,
        });
    };

    return (
        <Grid
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                width: '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                {urlRef === 'editProfile' ? null : (
                    <Grid>
                        <CustomIconButton
                            sx={{
                                backgroundColor: '#fff',
                                width: '25px',
                                height: '25px',
                                paddingLeft: '20px',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#5cb5b3',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                },
                            }}
                            onClick={editProfile}
                        >
                            <EditIcon
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                        color: '#5cb5b3',
                                        backgroundColor: 'transparent',
                                        background: 'none',
                                    },
                                }}
                            />
                        </CustomIconButton>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

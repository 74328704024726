import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    addDriver,
    selectDrivers,
} from '../../../../../app/Slices/DriversSlice';
import { CustomButton } from '../../../../../layout/styledcomponents/CustomButton';
import DriverForm from './RHFDriverForm';
import { useFieldArray, useFormContext } from 'react-hook-form';

const Drivers = () => {
    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        name: 'bookingInfo.drivers',
        control,
        shouldUnregister: false,
    })

    const drivers = useSelector(selectDrivers);
    const [driverCounter, setDriverCounter] = useState(1);
    const dispatch = useDispatch();

    const handleAdd = () => {
        append({
            id: `new${driverCounter}`,
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            licenceNo: '',
            licenceType: '',
        });

        const newDriver = {
            id: `new${driverCounter}`,
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            licenceNo: '',
            licenceType: '',
        };
        dispatch(addDriver(newDriver));
        setDriverCounter(driverCounter + 1);
    };

    return (
        <Grid
            sx={{
                width: '100%',
                display: 'flex',
                gap: '20px',
                flexDirection: 'column',
                paddingBottom: '30px',
            }}
        >
            <Grid
                sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
                {' '}
                {drivers?.map((driver: any, index: any) => (
                    <DriverForm
                        key={driver.id}
                        existingDriver={driver}
                        index={index}
                        remove={remove}
                    />
                ))}
            </Grid>
            <Grid
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <CustomButton
                    variant='contained'
                    color='green'
                    startIcon={<AddCircleIcon />}
                    onClick={handleAdd}
                >
                    Additional Driver
                </CustomButton>
            </Grid>
        </Grid>
    );
};

export default Drivers;

import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export default function TopBookersReportGraph(params: any) {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <Box
            sx={{
                height: isMobile ? 400: 800,
                width: '100%',
                paddingTop: isMobile ? '0px' : '50px',
            }}
        >
            <BarChart
                tooltip={{ trigger: 'item' }}
                xAxis={[
                    {
                        id: 'barCategories',
                        data: params?.data.map(
                            (staff: { firstName: string; lastName: string }) =>
                                staff?.firstName + ' ' + staff?.lastName
                        ),
                        scaleType: 'band',
                    },
                ]}
                series={[
                    {
                        data: params?.data.map(
                            (staff: { completedBookings: any }) =>
                                staff?.completedBookings
                        ),
                        color: '#5cb5b3',
                    },
                ]}
                // width={2500}
                // height={300}
            />
        </Box>
    );
}


import { Box, Card, Grid, InputLabel, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import {
    NumberOfDoorsArray,
    NumberOfSeatsArray,
    yearArray,
} from '../Boat/NumberArray';
import { TimeArray } from '../Property/TimeArray';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import AirlineSeatLegroomExtraIcon from '@mui/icons-material/AirlineSeatLegroomExtra';
import AirlineSeatLegroomReducedIcon from '@mui/icons-material/AirlineSeatLegroomReduced';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import NavigationIcon from '@mui/icons-material/Navigation';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import SpeedIcon from '@mui/icons-material/Speed';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import carData from './car_makes_and_models.json';
import bikeData from './bike_makes_and_models.json';
import FormSelect from '../../../../shared/FormSelect';
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import FormAutocomplete from '../../../../shared/FormAutocomplete';
import FormTextField from '../../../../shared/FormTextField';

type VehicleData = {
    [key: string]: string[];
};

const carDataTyped: VehicleData = carData;
const bikeDataTyped: VehicleData = bikeData;

export const bodyTypes = [
    { value: 'bus', label: 'Bus' },
    { value: 'cabChassis', label: 'Cab Chassis' },
    { value: 'convertible', label: 'Convertible' },
    { value: 'coupe', label: 'Coupe' },
    { value: 'fastback', label: 'Fastback' },
    { value: 'hatchback', label: 'Hatchback' },
    { value: 'lightTruck', label: 'Light Truck' },
    { value: 'limousine', label: 'Limousine' },
    { value: 'minivan', label: 'Minivan' },
    { value: 'sedan', label: 'Sedan' },
    { value: 'sportsCar', label: 'Sports Car' },
    { value: 'stationWagon', label: 'Station Wagon' },
    { value: 'suv', label: 'SUV' },
    { value: 'ute', label: 'Ute' },
    { value: 'van', label: 'Van' },
];

export const fuelTypes = [
    { value: 'petrol', label: 'Petrol' },
    { value: 'diesel', label: 'Diesel' },
    { value: 'electric', label: 'Electric' },
    { value: 'hybrid', label: 'Hybrid' },
    { value: 'cng', label: 'CNG' },
    { value: 'lpg', label: 'LPG' },
];
export const carTransmissionTypes = [
    { value: 'manual', label: 'Manual' },
    { value: 'automatic', label: 'Automatic' },
    { value: 'cvt', label: 'CVT' },
];
export const motorBikeTransmissionTypes = [
    { value: 'manual', label: 'Manual' },
    { value: 'automatic', label: 'Automatic' },
    { value: 'electric', label: 'Electric' },
];

interface Props {
    isEditing: boolean
}

export default function NewVehicleAmenitiesForm({ isEditing }: Props) {
    const isMobile = useMediaQuery('(max-width: 770px)');

    const { control, setValue, formState: { isDirty } } = useFormContext();

    const type = useWatch({ control, name: 'details.subType'});
    const make = useWatch({ control, name: 'details.make' });

    const carItems = [
        {
            name: 'rearVisionCamera',
            selected: false,
            icon: <ControlCameraIcon fontSize='large' />,
        },
        {
            name: 'cruiseControl',
            selected: false,
            icon: <SpeedIcon fontSize='large' />,
        },
        {
            name: 'touchscreenDisplay',
            selected: false,
            icon: <TouchAppIcon fontSize='large' />,
        },
        {
            name: 'bluetooth',
            selected: false,
            icon: <BluetoothIcon fontSize='large' />,
        },
        {
            name: 'heatedSeats',
            selected: false,
            icon: <DeviceThermostatIcon fontSize='large' />,
        },
        {
            name: 'androidAuto',
            selected: false,
            icon: <AndroidIcon fontSize='large' />,
        },
        {
            name: 'appleCarPlay',
            selected: false,
            icon: <AppleIcon fontSize='large' />,
        },
        {
            name: 'blindSpotAlert',
            selected: false,
            icon: <CrisisAlertIcon fontSize='large' />,
        },
        {
            name: 'navigation',
            selected: false,
            icon: <NavigationIcon fontSize='large' />,
        },
        {
            name: 'tyrePressureSensor',
            selected: false,
            icon: <TireRepairIcon fontSize='large' />,
        },
        {
            name: 'airbags',
            selected: false,
            icon: <AirlineSeatReclineNormalIcon fontSize='large' />,
        },
        {
            name: 'ac',
            selected: false,
            icon: <AcUnitIcon fontSize='large' />,
        },
        {
            name: 'keylessEntry',
            selected: false,
            icon: <KeyOffIcon fontSize='large' />,
        },
        {
            name: 'sunroof',
            selected: false,
            icon: <WbSunnyIcon fontSize='large' />,
        },
        {
            name: 'laneAssist',
            selected: false,
            icon: <AddRoadIcon fontSize='large' />,
        },
        {
            name: 'autoPark',
            selected: false,
            icon: <LocalParkingIcon fontSize='large' />,
        },
        {
            name: 'powerSeats',
            selected: false,
            icon: <AirlineSeatLegroomReducedIcon fontSize='large' />,
        },
        {
            name: 'remoteStart',
            selected: false,
            icon: <SettingsRemoteIcon fontSize='large' />,
        },
        {
            name: 'wirelessPhoneCharger',
            selected: false,
            icon: <EdgesensorHighIcon fontSize='large' />,
        },
        {
            name: 'rearEntertainmentSystem',
            selected: false,
            icon: <LiveTvIcon fontSize='large' />,
        },
    ];

    const motorBikeItems = [
        {
            name: 'rearVisionCamera',
            selected: false,
            icon: <ControlCameraIcon fontSize='large' />,
        },
        {
            name: 'heatedSeats',
            selected: false,
            icon: <DeviceThermostatIcon fontSize='large' />,
        },
        {
            name: 'androidAuto',
            selected: false,
            icon: <AndroidIcon fontSize='large' />,
        },
        {
            name: 'appleCarPlay',
            selected: false,
            icon: <AppleIcon fontSize='large' />,
        },
        {
            name: 'cruiseControl',
            selected: false,
            icon: <SpeedIcon fontSize='large' />,
        },
        {
            name: 'touchscreenDisplay',
            selected: false,
            icon: <TouchAppIcon fontSize='large' />,
        },
        {
            name: 'bluetooth',
            selected: false,
            icon: <BluetoothIcon fontSize='large' />,
        },
        {
            name: 'blindSpotAlert',
            selected: false,
            icon: <CrisisAlertIcon fontSize='large' />,
        },
        {
            name: 'navigation',
            selected: false,
            icon: <NavigationIcon fontSize='large' />,
        },
        {
            name: 'tyrePressureSensor',
            selected: false,
            icon: <TireRepairIcon fontSize='large' />,
        },
        {
            name: 'airbags',
            selected: false,
            icon: <AirlineSeatReclineNormalIcon fontSize='large' />,
        },
        {
            name: 'adjustableSeats',
            selected: false,
            icon: <AirlineSeatLegroomExtraIcon fontSize='large' />,
        },
        {
            name: 'helmet',
            selected: false,
            icon: <SportsMotorsportsIcon fontSize='large' />,
        },
        {
            name: 'gloves',
            selected: false,
            icon: <SportsMmaIcon fontSize='large' />,
        },
    ];

    const makeNames =
        type === 'car'
            ? Object.keys(carData).map((make) => ({
                  value: make,
                  label: make,
              }))
            : Object.keys(bikeData).map((make) => ({
                  value: make,
                  label: make,
              }));

    const modelNames =
        type === 'car'
            ? (carDataTyped[make] || []).map((model) => ({
                  value: model,
                  label: model,
              }))
            : (bikeDataTyped[make] || []).map((model) => ({
                  value: model,
                  label: model,
              }));

    useEffect(() => {
        if (isDirty) {
            setValue('details.make', '');
            setValue('details.model', '');
            setValue('details.transmission', '');
            setValue('details.bodyType', '');
            setValue('details.engineCC', '');
        }
    }, [type]);

    useEffect(() => {
        if (isDirty)
            setValue('details.model', '')
    }, [make]);

    return (
        <>
            <Typography 
                sx={{
                    color: 'text.primary',
                    pb: '30px'
                }}
            >
                Tell guests about the vehicle.
            </Typography>
            <Grid 
                container
                columnSpacing={5}
                rowSpacing={1}
                pb='15px'
            >
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Vehicle Type</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormSelect
                            name='details.subType'
                            control={control}
                            options={[
                                { value: 'car', label: 'Car' },
                                { value: 'motorbike', label: 'Motorbike' },
                                // { value: 'truck', label: 'Truck' },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Make</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete 
                            name='details.make'
                            control={control}
                            options={makeNames}
                            disabled={!type}
                            placeholder={type ? '' : 'Select Vehicle Type'}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Model</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete 
                            name='details.model'
                            control={control}
                            options={modelNames}
                            disabled={!make}
                            placeholder={make ? '' : 'Select Vehicle Make'}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel>Year (optional)</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete 
                            name='details.year'
                            control={control}
                            options={yearArray}
                            disableClearable={undefined}
                        />
                    </Grid>
                </Grid>
                {type === 'car' ? (
                    <Grid item container xs={12} lg={6}>
                        <Grid item xs={12} lg={5}>
                            <InputLabel required>Body Type</InputLabel>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormAutocomplete 
                                name='details.bodyType'
                                control={control}
                                options={bodyTypes}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item container xs={12} lg={6}>
                        <Grid item xs={12} lg={5}>
                            <InputLabel required>Engine CC</InputLabel>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <FormTextField 
                                name='details.engineCC'
                                control={control}
                                type='number'
                                placeholder='450'
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Transmission</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.transmission'
                            control={control}
                            options={type === 'car' ? carTransmissionTypes : motorBikeTransmissionTypes}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={5}>
                        <InputLabel required>Fuel Type</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete
                            name='details.fuelType'
                            control={control}
                            options={fuelTypes}
                        />
                    </Grid>
                </Grid>
                {!isMobile && <Grid item xs />}
                {type === 'car' && (
                    <>
                        <Grid item container xs={12} lg={6}>
                            <Grid item xs={12} lg={5}>
                                <InputLabel required>No. Doors</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <FormAutocomplete
                                    name='details.numberOfDoors'
                                    options={NumberOfDoorsArray}
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} lg={6}>
                            <Grid item xs={12} lg={5}>
                                <InputLabel required>No. Seats</InputLabel>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <FormAutocomplete
                                    name='details.numberOfSeats'
                                    control={control}
                                    options={NumberOfSeatsArray}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            <Typography
                sx={{
                    color: 'text.primary',
                    pb: '20px',
                }}
            >
                Select available features:
            </Typography>
            <Grid container columns={{ lg: 7, md: 4, xs: 2 }} spacing={2}>
                {(type === 'car' ? carItems : motorBikeItems).map((item, i) => (
                    <Grid item xs={1} key={i}>
                        <Controller 
                            name={`details.${item.name}`}
                            control={control}
                            render={({ field: { value }}) => (
                                <Card
                                    sx={{ 
                                        height: '100px',
                                        bgcolor: value ? 'primary.main' : '#f6f8f9',
                                        color: value ? 'primary.contrastText' : 'text.primary'
                                    }}
                                    onClick={() => setValue(`details.${item.name}`, !value)}
                                >
                                    <Box 
                                        display='flex'
                                        flexDirection='column'
                                        alignItems='center'
                                        height='100%'
                                        gap={1}
                                        justifyContent='center'
                                        textAlign='center'
                                    >
                                        {item.icon}
                                        <Typography>
                                            {item.name === 'rearVisionCamera'
                                                ? 'Rear Camera'
                                                : item.name === 'cruiseControl'
                                                ? 'Cruise Control'
                                                : item.name === 'touchscreenDisplay'
                                                ? 'Touch Screen'
                                                : item.name === 'heatedSeats'
                                                ? 'Heated Seats'
                                                : item.name === 'androidAuto'
                                                ? 'Android Auto'
                                                : item.name === 'appleCarPlay'
                                                ? 'Apple CarPlay'
                                                : item.name === 'blindSpotAlert'
                                                ? 'Blindspot Alert'
                                                : item.name === 'tyrePressureSensor'
                                                ? 'Tyre Pressure Sensor'
                                                : item.name === 'ac'
                                                ? 'Air Conditioning'
                                                : item.name === 'keylessEntry'
                                                ? 'Keyless Entry'
                                                : item.name === 'laneAssist'
                                                ? 'Lane Assist'
                                                : item.name === 'powerSeats'
                                                ? 'Power Seats'
                                                : item.name === 'remoteStart'
                                                ? 'Remote Start'
                                                : item.name ===
                                                'wirelessPhoneCharger'
                                                ? 'Wireless Phone Charger'
                                                : item.name ===
                                                'rearEntertainmentSystem'
                                                ? 'Rear Entertainment System'
                                                : item.name === 'adjustableSeats'
                                                ? 'Adjustable Seats'
                                                : item.name
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                item.name.slice(1)}
                                        </Typography>
                                    </Box>
                                </Card>
                            )}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

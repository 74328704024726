import {
    Box,
    Button,
    Grid,
    InputLabel,
    Paper,
    Stack,
    useMediaQuery,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import React from 'react';
import { useGetFeeListQuery } from '../../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import UserRole from '../../../../shared/AuthRoles';
import { camelCaseToTitleCase, capitalizeName } from '../../../../shared/utils';
import FormAutocomplete from '../../../shared/FormAutocomplete';

import { useFormContext, useWatch } from 'react-hook-form';
import { ExperienceCharge } from '../../../types/experience';
import FormTextField from '../../../shared/FormTextField';
import { Add } from '@mui/icons-material';

export interface ExperienceFee {
    feeId: number;
    unitPrice: number;
}

const ExperienceFeesForm = ({
    existingFee,
    experienceType,
    index,
    handleDelete,
}: {
    existingFee: ExperienceCharge;
    experienceType: string;
    index: number;
    handleDelete: (i: number) => void;
}) => {
    const { control } = useFormContext();

    const fees = useWatch({ control, name: 'fees' });

    const loggedInUser = UserRole();
    const { data: availableFees = [] } = useGetFeeListQuery(
        loggedInUser?.company
    );

    return (
        <Stack spacing={1}>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={4}>
                        <InputLabel required>Name</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <FormAutocomplete
                            name={`fees[${index}].feeId`}
                            control={control}
                            disabled={!experienceType}
                            placeholder={
                                experienceType
                                    ? ''
                                    : 'Please select experience type'
                            }
                            options={availableFees.map((fee: any) => ({
                                value: fee.id,
                                label:
                                    capitalizeName(fee?.name) +
                                    ` (${camelCaseToTitleCase(
                                        fee?.type ? fee?.type : 'fixed'
                                    )})`,
                            }))}
                            filterOptions={(options) => {
                                // Filter the options to exclude already selected fees and fees not matching experienceType
                                const selectedFeeIds = fees.map(
                                    (fee: any) => fee.feeId
                                );
                                return options.filter((option) => {
                                    const fee = availableFees.find(
                                        (f: any) => f.id === option.value
                                    );
                                    return (
                                        !selectedFeeIds.includes(
                                            option.value
                                        ) && // Exclude selected fees
                                        (fee?.moduleTypes.includes(
                                            experienceType
                                        ) || // Match experienceType
                                            fee?.moduleTypes.length === 0)
                                    );
                                });
                            }}
                            PaperComponent={({ children }) => {
                                return (
                                    <Paper>
                                        <Button
                                            fullWidth
                                            size='large'
                                            sx={{
                                                justifyContent: 'flex-start',
                                                pl: 2,
                                                mt: 1,
                                            }}
                                            startIcon={<Add />}
                                            onMouseDown={() =>
                                                window.open('/newFee', '_blank')
                                            }
                                        >
                                            Add New Fee Type
                                        </Button>
                                        {children}
                                    </Paper>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6}>
                    <Grid item xs={12} lg={4}>
                        <InputLabel required>Amount</InputLabel>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <FormTextField
                            name={`fees[${index}].unitPrice`}
                            control={control}
                            type='number'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Box display='flex' justifyContent='flex-end'>
                <CustomButton
                    variant='contained'
                    color='warning'
                    onClick={handleDelete}
                >
                    Delete
                </CustomButton>
            </Box>
        </Stack>
    );
};

export default ExperienceFeesForm;

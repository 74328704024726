import React from 'react';
import { HostPayment } from '../../../app/Slices/SevenDayApiTypes';
import { Stack, Box, Typography, useMediaQuery, Grid } from '@mui/material';
import moment from 'moment';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StripedDataGrid from '../../shared/StripedDataGrid';
import { formatCurrencyShort, getPaymentStatus } from '../../../shared/utils';
import UserRole from '../../../shared/AuthRoles';

export const Payment = ({
    payment,
    serviceFeePercentage,
}: {
    payment: HostPayment;
    serviceFeePercentage: number;
}) => {
    const loggedInUser = UserRole();
    const isMobile = useMediaQuery('(max-width: 770px)');
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: 'moduleName',
            headerName: 'Experience',
            flex: 1,
            minWidth: 100,
            type: 'string',
            sortable: true,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 120,
            sortable: true,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: ({ value }) => formatCurrencyShort(value),
        },
        {
            field: 'ref',
            headerName: 'Ref.',
            width: 100,
            sortable: true,
        },
        {
            field: 'guestName',
            headerName: 'Guest',
            width: 100,
            type: 'string',
            sortable: true,
        },
        {
            field: 'bookedFor',
            headerName: 'Primary Guest',
            width: 120,
            type: 'string',
            sortable: true,
        },
        {
            field: 'startDate',
            headerName: 'Check-in',
            width: 120,
            type: 'string',
            sortable: true,
        },
        {
            field: 'endDate',
            headerName: 'Checkout',
            width: 120,
            type: 'string',
            sortable: true,
        },
    ];

    const rows = payment
        ? payment.bookings.map((booking) => {
              const {
                  id,
                  moduleName,
                  startDate,
                  endDate,
                  guestName,
                  bookedFor,
                  amount,
              } = booking;
              return {
                  id,
                  moduleName,
                  startDate: moment(startDate).format('D MMM, YYYY'),
                  endDate: moment(endDate).format('D MMM, YYYY'),
                  guestName,
                  bookedFor,
                  amount,
                  ref: `#${id}`,
              };
          })
        : [];

    const handleRowClick: GridEventListener<'rowClick'> = ({
        row: { id, moduleName },
    }) => {
        navigate(
            `/reservations/${moduleName.replace(/ /g, '').toLowerCase()}/${id}`,
            {
                state: { name: 'hostPayment' },
            }
        );
    };

    return (
        <Stack
            sx={{
                height: isMobile ? '100%' : '80%',
                width: '100%',
            }}
            spacing={2}
        >
            <Box>
                <Typography
                    variant='h5'
                    color='textPrimary'
                    sx={{
                        textDecoration: 'underline',
                        textDecorationColor: '#5cb5b3',
                        textUnderlineOffset: '8px',
                    }}
                >
                    Payment Reservations
                </Typography>
            </Box>
            <Grid container rowSpacing={1} paddingTop={'30px'}>
                {loggedInUser.role === 'admin' && (
                    <Grid container item>
                        <Grid item xs={5} lg={2}>
                            <Typography color='textPrimary'>Owner:</Typography>
                        </Grid>
                        <Grid item xs={6} md={1.5} textAlign={'right'}>
                            <Typography color='textPrimary'>
                                {payment.ownerName}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {payment.id && (
                    <Grid container item>
                        <Grid item xs={5} lg={2}>
                            <Typography color='textPrimary'>Ref:</Typography>
                        </Grid>
                        <Grid item xs={6} md={1.5} textAlign={'right'}>
                            <Typography color='textPrimary'>
                                #{payment.id}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid container item>
                    <Grid item xs={5} lg={2}>
                        <Typography color='textPrimary'>
                            Payment Period:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={1.5} textAlign={'right'}>
                        <Typography color='textPrimary'>
                            {`${moment(payment.periodStart).format(
                                'D MMM'
                            )} - ${moment(payment.periodEnd).format(
                                'D MMM, YYYY'
                            )}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={5} lg={2}>
                        <Typography color='textPrimary'>Status:</Typography>
                    </Grid>
                    <Grid item xs={6} md={1.5} textAlign={'right'}>
                        <Typography color='textPrimary'>
                            {getPaymentStatus(payment)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={5} lg={2}>
                        <Typography color='textPrimary'>Total:</Typography>
                    </Grid>
                    <Grid item xs={6} md={1.5} textAlign={'right'}>
                        <Typography color='textPrimary'>
                            {formatCurrencyShort(payment.bookingsTotal)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={6} md={5} lg={2}>
                        <Typography color='textPrimary'>
                            7DAY Service Fee ({serviceFeePercentage}%):
                        </Typography>
                    </Grid>
                    <Grid item xs={5} md={1.5} textAlign={'right'}>
                        <Typography color='textPrimary'>
                            {formatCurrencyShort(payment.serviceFeeAmount)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={5} lg={2}>
                        <Typography color='textPrimary'>Gross:</Typography>
                    </Grid>
                    <Grid item xs={6} md={1.5} textAlign={'right'}>
                        <Typography color='textPrimary'>
                            {formatCurrencyShort(payment.amount)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Box paddingTop={'30px'}>
                <StripedDataGrid
                    sx={{
                        boxShadow: 0,
                        border: 0,
                        '& .MuiDataGrid-cell': {
                            paddingLeft: '0',
                            paddingRight: '15px',
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                    }}
                    onRowClick={handleRowClick}
                    columns={columns}
                    rows={rows}
                ></StripedDataGrid>
            </Box>
            <Grid paddingTop={'30px'}>
                <CustomButton
                    onClick={() => navigate(-1)}
                    color='green'
                    sx={{
                        marginLeft: !isMobile ? '-8px' : '-18px',
                        '&:hover': { background: 'none' },
                    }}
                >
                    <ArrowBackIcon />
                    {isMobile ? null : (
                        <Typography
                            sx={{
                                fontSize: '12px',
                                paddingLeft: '5px',
                            }}
                        >
                            Back
                        </Typography>
                    )}
                </CustomButton>
            </Grid>
        </Stack>
    );
};

import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function MessagingOverview() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        If in-app messaging is enabled by the host of an experience, it will allow guests and
                        hosts to communicate about that particular experience and reservations.
                        For example, guests can ask hosts questions about a
                        listed experience before making a reservation, or
                        communicate a special requests for their upcoming
                        experience. Similarly hosts can send a message to the
                        guest about their reservation, such as directions to get
                        to the experience.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

import {
    Box,
    Grid,
    List,
    ListItem,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import React from 'react';
import { useGetPolicyListQuery } from '../../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../../shared/AuthRoles';
import { BaseExperiencePolicy, ExperiencePolicy, Policy } from '../../../types/policy';
import { CustomButton } from '../../../../layout/styledcomponents/CustomButton';
import { HelpOutline } from '@mui/icons-material';
import { useFormContext, useWatch } from 'react-hook-form';
import FormAutocomplete from '../../../shared/FormAutocomplete';
import FormCheckbox from '../../../shared/FormCheckbox';

interface Props {
    policy: BaseExperiencePolicy;
    remove: (index: number) => void,
    experienceType: string;
    index: number;
}

const ExperiencePoliciesForm = ({ 
    policy, 
    experienceType,
    remove, 
    index 
}: Props) => {
    const { control, getValues } = useFormContext();

    const experiencePolicies: ExperiencePolicy[] = useWatch({ control, name: 'policies' })

    const loggedInUser = UserRole();

    const { data: policies = [] } = useGetPolicyListQuery(
        loggedInUser?.company
    );

    const filteredPolicies = policies.filter(
        (p: Policy) =>
            p.moduleTypes.includes(experienceType) ||
            (p.moduleTypes.length === 0 &&
                !experiencePolicies
                    .map((policy) => policy.policyId)
                    .includes(p.id))
    );

    const options = filteredPolicies.map((policy: Policy) => ({
        label: policy.name,
        value: policy.id,
    }));

    const handleDelete = () => {
        remove(index)
    };

    return (
        <Box>
            <Grid container rowSpacing={2} columnSpacing={6}>
                <Grid item container xs={12} lg={6} spacing={1}>
                    <Grid item xs={12} lg={5}>
                        <Typography color='textPrimary'>
                            Policy
                            {!policy.policyId && (
                                <span style={{ color: 'red' }}>*</span>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                        <FormAutocomplete 
                            name={`policies[${index}].policyId`}
                            control={control}
                            options={options}
                            disabled={!experienceType}
                            placeholder={!experienceType ? 'Select type of experience' : ''}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={6} spacing={1}>
                    <Grid item xs={12} display='flex' gap={1}>
                        <FormCheckbox 
                            name={`policies[${index}].requiresConsent`}
                            control={control}
                            sx={{ p: 0 }}
                        />
                        <Stack direction='row' gap='5px' alignItems='center'>
                            <Typography color='textPrimary'>
                                Collect users consent?
                            </Typography>
                            <Tooltip
                                title={
                                    <Typography>
                                        <List>
                                            <ListItem>
                                                By ticking this, the user has to
                                                consent before the reservation can
                                                be processed.
                                            </ListItem>
                                        </List>
                                    </Typography>
                                }
                                placement='right'
                            >
                                <HelpOutline sx={{ fontSize: '16px' }}/>
                            </Tooltip>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <Box display='flex' justifyContent='flex-end'>
                <CustomButton
                    color='error'
                    variant='contained'
                    onClick={handleDelete}
                >
                    Delete
                </CustomButton>
            </Box>
        </Box>
    );
};

export default ExperiencePoliciesForm;

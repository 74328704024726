import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { GridEventListener } from '@mui/x-data-grid/models';
import { CustomChip } from '../../../layout/styledcomponents/CustomChip';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import { useNavigate } from 'react-router-dom';
import { useGetAllClientsQuery } from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectFilteredClientData } from '../../../app/Slices/ClientFilterSlice';
import React from 'react';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    fontFamily: ['Open Sans'].join(','),

    '& .MuiTablePagination-toolbar': {
        fontFamily: 'Open Sans',
        color: '#787878',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
    },
    '& .MuiTablePagination-displayedRows ': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-selectLabel': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '14px',
    },
    '& .MuiTablePagination-select': {
        fontFamily: 'Open Sans',
        color: '#787878',
        paddingTop: '4px',
    },

    '& .MuiDataGrid-columnHeader': {
        fontFamily: 'Open Sans',
        fontSize: '12px',
        fontWeight: 900,
        color: '#000',
        borderBottom: '1px solid #787878',
        // textTransform: 'upperCase'
    },
    [`& .${gridClasses.row}`]: {
        // backgroundColor: '#f6f8f9',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'none',
            color: '#5cb5b3',
            // fontWeight: 'bold',
            fontFamily: 'Open Sans',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
                fontFamily: 'Open Sans',
                color: '#787878',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#f6f8f9',
            fontFamily: 'Open Sans',
            color: '#5cb5b3',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'none',
                color: '#5cb5b3',
                fontFamily: 'Open Sans',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'none',
                    fontFamily: 'Open Sans',
                    // color: 'red',
                },
            },
        },
    },
}));

export default function ClientComponent() {
    const navigate = useNavigate();
    const { data: getClients } = useGetAllClientsQuery('/owners');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const filterResults: any = useSelector(selectFilteredClientData);

    const handleShowDetailsClick = (params: any) => {
        navigate(
            `/clients/${params?.row?.name.replace(/ /g, '').toLowerCase()}/${
                params?.row?.id
            }`,
            {
                state: { name: 'viewClient', params: params.row },
            }
        );
    };

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        navigate(
            `/clients/${params.row.name.replace(/ /g, '').toLowerCase()}/${
                params?.row.id
            }`,
            {
                state: { name: 'viewClient', params: params.row },
            }
        );
    };

    let clients = getClients?.filter((val: any) => {
        if (
            // filterResults?.role === undefined &&
            filterResults?.status === undefined
        ) {
            return val;
        } else if (
            // filterResults?.role !== undefined &&
            filterResults?.status !== undefined
        ) {
            if (
                // filterResults?.role === 'All' &&
                filterResults?.status === 'All'
            ) {
                return val;
            } else if (
                // filterResults?.role !== 'All' &&
                filterResults?.status !== 'All'
            ) {
                return (
                    // val?.role
                    //     ?.toLowerCase()
                    //     ?.includes(filterResults?.role?.toLowerCase()) &&
                    val?.isActive ===
                    (filterResults?.status === 'enabled' ? true : false)
                );
            } else if (
                // filterResults?.role === 'All' &&
                filterResults?.status !== 'All'
            ) {
                return (
                    val?.isActive ===
                    (filterResults?.status === 'enabled' ? true : false)
                );
            } else {
                return val;
            }
        } else {
            return val;
        }
    });

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Client',
            type: 'string',
            // width: isMobile ? 261 : isIpad ? 735 : 1327,
            flex: 1,
            minWidth: 150,
            sortable: true,
            renderCell: (params) => {
                return (
                    <List>
                        <ListItem sx={{ padding: '0!important' }}>
                            {isMobile ? null : (
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            bgcolor: '#E0E0E0',
                                        }}
                                    >
                                        {params?.value?.charAt(0)}
                                        {params?.row.lastName?.charAt(0)}
                                    </Avatar>
                                </ListItemAvatar>
                            )}
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {params?.value}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {params?.row.account.email}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                );
            },
        },
        {
            field: 'lastLoginAt',
            headerName: 'Last Login',
            width: isMobile ? 180 : isIpad ? 180 : 180,
            renderCell: (params) => {
                return (
                    <List>
                        <ListItem sx={{ padding: '0!important' }}>
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                        }}
                                    >
                                        {params?.value
                                            ? moment(params?.value).format(
                                                  'DD/MM/YYYY'
                                              )
                                            : '-'}
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            color: '#787878',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {params?.value
                                            ? moment(params?.value).format(
                                                  'h:mm a'
                                              )
                                            : ''}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                );
            },
            sortable: true,
        },
        {
            field: 'isActive',
            headerName: 'Status',
            width: isMobile ? 5 : isIpad ? 50 : 130,
            type: 'string',
            sortable: true,
            renderCell: (params) => {
                return isIpad || isMobile ? (
                    params.value === true ? (
                        <Tooltip title='Enabled'>
                            <DoneIcon sx={{ color: '#5cb5b3' }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title='Enabled'>
                            <BlockIcon sx={{ color: '#787878' }} />
                        </Tooltip>
                    )
                ) : (
                    <CustomChip
                        style={{
                            fontFamily: 'Open Sans',
                        }}
                        label={
                            params.value === true ? (
                                <Typography
                                    color='green'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                    }}
                                >
                                    Enabled
                                </Typography>
                            ) : (
                                <Typography
                                    color='red'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                    }}
                                >
                                    Disabled
                                </Typography>
                            )
                        }
                        color={params.value === true ? 'green' : 'red'}
                        variant='outlined'
                    />
                );
            },
        },
        {
            field: 'more',
            headerName: isMobile ? '' : 'Actions',
            width: isMobile ? 5 : isIpad ? 60 : 60,
            type: 'string',
            sortable: false,
            renderCell: (params) => {
                return (
                    <CustomButton
                        onClick={() => handleShowDetailsClick(params)}
                        sx={{
                            textTransform: 'lowercase',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#5cb5b3',
                                backgroundColor: 'transparent',
                                background: 'none',
                            },
                        }}
                    >
                        <MoreVertIcon sx={{ color: '#787878' }} />
                    </CustomButton>
                );
            },
        },
    ];

    return (
        <Box sx={{ height: 700, width: '100%' }}>
            <StripedDataGrid
                onRowClick={handleRowClick}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                sx={{
                    boxShadow: 0,
                    border: 0,
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={clients !== undefined ? clients : []}
                columns={columns}
                components={{
                    NoRowsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Clients
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please add a new client or
                                                adjust the filters
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            {' '}
                            <List>
                                <ListItem
                                    sx={{
                                        padding: '0!important',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                }}
                                            >
                                                No Results
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                    color: '#787878',
                                                    fontSize: '12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Please try again
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>{' '}
                        </Stack>
                    ),
                }}
            />
        </Box>
    );
}

import { zodResolver } from '@hookform/resolvers/zod';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Alert,
    Button,
    Card,
    CardContent,
    Collapse,
    Divider,
    FormHelperText,
    Grid,
    Paper,
    Popover,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import {
    useCalculateFeesMutation,
    useGetAssetCalendarAvailabilityQuery,
    useGetAvailabilityQuery,
} from '../../../../app/Slices/SevenDayApiSlice';
import UserRole from '../../../../shared/AuthRoles';
import { formatCurrency, formatTime } from '../../../../shared/utils';
import FormAutocomplete from '../../../shared/FormAutocomplete';
import FormCalendar, { CheckinDetails } from '../../../shared/FormCalendar';
import { CommercialDetails, FormValues } from '../../../types/experience';
import CalculateChargesContent from '../../Bookings/Fees/CalculateChargesContent';
import { getAvailableSpaces } from '../../Reservations/ReservationRequest/ReservationRequestContent';
import { TimeArray } from '../Owner/Property/TimeArray';

interface Props {
    experience: FormValues;
}

const Schema = z.object({
    date: z.object(
        {
            from: z.date(),
            to: z.date(),
        },
        { message: 'Date selection required' }
    ),
});

const OptionalSchemaProperty = z.object({
    checkin: z.string(),
    checkout: z.string(),
});
const OptionalSchemaCommercial = z.object({
    commercialSpaceType: z.string(),
});

const CalendarComponent = ({ experience }: Props) => {
    const loggedInUser = UserRole();
    const [charges, setCharges] = useState<any>();
    const [hidePrice, setHidePrice] = useState(true);
    const [calculateFees, { isLoading: isCalculating }] =
        useCalculateFeesMutation();

    const hasPerHourCharge = experience?.charges.find(
        (charge: any) => charge.type && charge.type.includes('Hour')
    );

    const [anchorElTotal, setAnchorElTotal] = useState(null);

    const open = Boolean(anchorElTotal);

    const handleClickTotal = (event: any) => {
        setAnchorElTotal(event.currentTarget);
    };

    const handleClosePriceBreakdown = () => {
        setAnchorElTotal(null);
    };

    const totalRef = useRef(null);

    const showPrice =
        !hidePrice &&
        charges?.bookingTotal?.total > 0 &&
        !loggedInUser?.role.includes('owner') &&
        !hasPerHourCharge;

    const schema =
        experience.type === 'property' && !loggedInUser?.role.includes('owner')
            ? Schema.merge(OptionalSchemaProperty)
            : experience.type === 'commercial' &&
              !loggedInUser?.role.includes('owner')
            ? Schema.merge(OptionalSchemaCommercial)
            : Schema;

    const methods = useForm({
        resolver: zodResolver(schema),
        mode: 'onChange',
        shouldUnregister: true,
    });
    const navigate = useNavigate();

    // disable if owner attempts to book another company's experience
    const disabled =
        loggedInUser?.role.includes('owner') &&
        experience.owner?.id !== loggedInUser?.company;

    const { control, setValue, setError, clearErrors, handleSubmit } = methods;

    const date = useWatch({ control, name: 'date' });
    const checkinTime = useWatch({ control, name: 'checkin' });
    const checkoutTime = useWatch({ control, name: 'checkout' });
    const commercialSpaceType = useWatch({
        control,
        name: 'commercialSpaceType',
    });

    const { data: availability, isFetching } =
        useGetAssetCalendarAvailabilityQuery({
            url: `/modules/${experience.id}/calendar`,
        });

    const [request, setRequest] = useState({});

    const { data: commercialAvailability } = useGetAvailabilityQuery(
        {
            url: `/modules/commercial/${experience?.id}/get-availability`,
            body: request,
        },
        { skip: !date }
    );

    useEffect(() => {
        setValue('checkin', experience?.checkinTime);
        setValue('checkout', experience?.checkoutTime);
    }, []);

    const availableSpaces = getAvailableSpaces(
        commercialAvailability,
        experience.details
    );

    const isCommercialDetails = (
        details: any
    ): details is CommercialDetails => {
        return (
            'desks' in details &&
            'privateOffices' in details &&
            'meetingRooms' in details
        );
    };

    const commercialHasExistingBooking =
        isCommercialDetails(experience?.details) &&
        (commercialAvailability?.desks < experience.details.desks ||
            commercialAvailability?.privateOffices <
                experience.details.privateOffices ||
            commercialAvailability?.meetingRooms <
                experience.details.meetingRooms);
    useEffect(() => {
        if (date?.from && date?.to) {
            setRequest({
                startDate: format(date?.from, 'yyyy-MM-dd'),
                endDate: format(date?.to, 'yyyy-MM-dd'),
            });
        }
    }, [date]);

    useEffect(() => {
        if (date?.from && date?.to) {
            calculateFees({
                bookerType: loggedInUser?.role.includes('owner')
                    ? 'owner'
                    : 'guest',
                module: { id: experience?.id },
                startDate: moment(date.from).format('YYYY-MM-DD'),
                endDate: moment(date.to).format('YYYY-MM-DD'),
                onBehalf: true,
                bookingInfo: {
                    moduleType: experience?.type,
                    visitType: 'business',
                    checkinTime:
                        experience?.type === 'property' ? checkinTime : '08:00',
                    checkoutTime:
                        experience?.type === 'property'
                            ? checkoutTime
                            : '17:00',
                    adults: 1,
                    children: 0,
                    overnightGuests: 1,
                    requiresCatering: false,
                    cateringNotes: '',
                    requiresBeverages: false,
                    beverageNotes: '',
                    requiresMassages: false,
                    massageNotes: '',
                    notes: '',
                    desks: commercialSpaceType === 'desk' ? 1 : 0,
                    privateOffices:
                        commercialSpaceType === 'privateOffice' ? 1 : 0,
                    meetingRooms: commercialSpaceType === 'meetingRoom' ? 1 : 0,
                    entireVenue:
                        commercialSpaceType === 'entireVenue' ? true : false,
                    guests: 1,
                    drivers: [],
                    driverSameAsBooker: true,
                },
                policies: [],
                staffAttending: [],
            }).then((data: any) => {
                if (data) {
                    if (data?.error) {
                        setHidePrice(true);
                        console.error('there was a problem calculating fees');
                    } else {
                        setHidePrice(false);
                        setCharges(data.data);
                    }
                }
            });
        } else {
            setHidePrice(true);
        }
    }, [checkinTime, checkoutTime, date, commercialSpaceType]);

    const [limitedDates, setLimitedDates] = useState<
        Record<string, CheckinDetails>
    >({});

    const [alert, setAlert] = useState<string>('');

    const [checkout, setCheckout] = useState<string>('');
    const [checkin, setCheckin] = useState<string>('');

    const handleSelect = (date?: DateRange) => {
        setAlert('');
        clearErrors('date');

        setValue('date', date);
        setCheckin('');
        setCheckout('');

        if (!date) return;

        const checkin = date.from && format(date.from, 'yyyy-MM-dd');
        const checkout = date.to && format(date.to, 'yyyy-MM-dd');

        let checkinDetails;
        let checkoutDetails;

        if (checkin) checkinDetails = limitedDates[checkin];
        if (checkout) checkoutDetails = limitedDates[checkout];

        if (checkinDetails?.checkinFrom) {
            setAlert(
                `Check-in available from ${formatTime(
                    checkinDetails.checkinFrom
                )}`
            );
            setValue('checkin', checkinDetails.checkinFrom);
            setCheckin(checkinDetails.checkinFrom);
        }

        if (
            (checkoutDetails?.checkoutBefore && !checkoutDetails.checkinFrom) ||
            (checkoutDetails?.checkoutBefore &&
                checkoutDetails.checkinFrom &&
                checkin !== checkout)
        ) {
            setAlert(
                `Checkout before ${formatTime(checkoutDetails.checkoutBefore)}`
            );
            setValue('checkout', checkoutDetails.checkoutBefore);
            setCheckout(checkoutDetails.checkoutBefore);
        }
    };

    useEffect(() => {
        // map so that checkin/checkout data is available in O(n)
        if (availability?.limited) {
            const mappedLimitedDates = availability.limited.reduce(
                (acc: Record<string, CheckinDetails>, day: any) => {
                    acc[day.date] = {
                        checkinFrom: day.checkinFrom,
                        checkoutBefore: day.checkoutBefore,
                    };
                    return acc;
                },
                {}
            );

            setLimitedDates(mappedLimitedDates);
        }
    }, [availability, date]);

    const endDate = new Date(
        new Date().setFullYear(
            new Date().getFullYear() +
                (loggedInUser?.role.includes('owner') ? 2 : 1)
        )
    );

    const getDateLabel = (date: Date) => {
        const formattedDate = date?.toLocaleDateString(undefined, {
            day: '2-digit',
            month: '2-digit',
        });

        return formattedDate ? `(${formattedDate})` : '';
    };

    const onSubmit = (data: FieldValues) => {
        if (
            !loggedInUser?.role.includes('owner') &&
            (data.date.to.getTime() - data.date.from.getTime()) /
                (60 * 60 * 1000) +
                24 <
                experience.minHireHours
        ) {
            setError('date', {
                message: `The minimum hire period is ${
                    experience.type === 'commercial'
                        ? `${experience?.minHireHours / 24}`
                        : experience?.minHireHours === 24
                        ? `${experience?.minHireHours / 24} day`
                        : experience?.minHireHours === 48
                        ? `${(experience?.minHireHours - 24) / 24}`
                        : experience?.minHireHours > 24
                        ? `${(experience?.minHireHours - 24) / 24} nights`
                        : `${experience?.minHireHours} ${
                              experience?.minHireHours === 1 ? 'hour' : 'hours'
                          }`
                }`,
            });
            return;
        }

        const times = {
            checkin: loggedInUser?.role.includes('owner')
                ? checkin
                : data.checkin,
            checkinFrom: checkin,
            checkout: loggedInUser?.role.includes('owner')
                ? checkout
                : data.checkout,
            checkoutBefore: checkout,
        };

        navigate(
            `/experiences/reservationRequest/${experience?.type.toLowerCase()}/${experience?.name
                .replace(/ /g, '')
                .toLowerCase()}/${experience?.id}`,
            {
                state: [
                    experience,
                    { startDate: data.date?.from, endDate: data.date?.to },
                    times,
                    commercialSpaceType,
                ],
            }
        );
    };

    const checkinOptions = TimeArray.filter((time) => {
        if (!date?.from) return false;

        if (date?.from.getTime() === date?.to?.getTime()) {
            return (
                time.value >= checkin &&
                time.value < experience.checkoutTime &&
                time.value >= experience.checkinTime &&
                (!checkoutTime || time.value < checkoutTime)
            );
        }

        return time.value >= checkin && time.value >= experience.checkinTime;
    });

    const checkoutOptions = TimeArray.filter((time) => {
        if (!date?.to) return false;

        if (date?.from.getTime() === date?.to.getTime() && checkinTime) {
            return (
                (!checkout || time.value <= checkout) &&
                time.value <= experience.checkoutTime &&
                time.value > checkinTime
            );
        }

        return (
            (!checkout || time.value <= checkout) &&
            time.value <= experience.checkoutTime
        );
    });

    return (
        availability && (
            <>
                <Card
                    sx={{ boxShadow: 2, borderRadius: 3, p: 2, width: '100%' }}
                >
                    <CardContent
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <FormProvider {...methods}>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                style={{ width: '100%' }}
                            >
                                <Stack gap={2} alignItems='center'>
                                    <FormCalendar
                                        name='date'
                                        control={control}
                                        experience={experience}
                                        availability={availability}
                                        onSelect={handleSelect}
                                        startMonth={new Date()}
                                        endMonth={endDate}
                                        captionLayout='dropdown'
                                        numberOfMonths={2}
                                        limitedDates={limitedDates}
                                    />
                                    <Collapse
                                        in={alert !== ''}
                                        sx={{ width: '100%' }}
                                    >
                                        <Alert severity='info'>{alert}</Alert>
                                    </Collapse>
                                    {experience.type === 'commercial' &&
                                        date &&
                                        !loggedInUser?.role.includes(
                                            'owner'
                                        ) && (
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <FormAutocomplete
                                                        name='commercialSpaceType'
                                                        control={control}
                                                        getOptionDisabled={(
                                                            option: any
                                                        ) =>
                                                            (option.value ===
                                                                'entireVenue' &&
                                                                commercialHasExistingBooking) ||
                                                            option.disabled
                                                        }
                                                        options={[
                                                            ...availableSpaces,
                                                            {
                                                                value: 'entireVenue',
                                                                label: `Entire Venue ${
                                                                    commercialHasExistingBooking
                                                                        ? `(unavailable)`
                                                                        : ``
                                                                }`,
                                                            },
                                                        ]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    {!loggedInUser?.role.includes('owner') &&
                                        experience.type === 'property' && (
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} lg={6}>
                                                    <FormAutocomplete
                                                        name='checkin'
                                                        control={control}
                                                        options={checkinOptions}
                                                        label={`Check-in ${getDateLabel(
                                                            date?.from
                                                        )}`}
                                                        noOptionsText='Select a date'
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <FormAutocomplete
                                                        name='checkout'
                                                        control={control}
                                                        options={
                                                            checkoutOptions
                                                        }
                                                        label={`Checkout ${getDateLabel(
                                                            date?.to
                                                        )}`}
                                                        noOptionsText='Select a date'
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    {experience?.type === 'commercial' &&
                                        date && (
                                            <Stack width='100%'>
                                                <Divider sx={{ mb: 1 }} />
                                                <Typography
                                                    sx={{
                                                        color: 'red',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {`Availability for ${date?.from?.toLocaleDateString()}${
                                                        date?.to?.toLocaleDateString() ===
                                                            date?.from?.toLocaleDateString() ||
                                                        !date?.to
                                                            ? ''
                                                            : ` - ${date?.to?.toLocaleDateString()}`
                                                    }:`}
                                                </Typography>
                                                {!commercialAvailability && (
                                                    <Skeleton
                                                        variant='text'
                                                        sx={{
                                                            fontSize: '1rem',
                                                        }}
                                                    />
                                                )}
                                                {commercialAvailability && (
                                                    <Typography
                                                        sx={{
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        {commercialAvailability?.desks >
                                                            0 && (
                                                            <>
                                                                {' '}
                                                                <span
                                                                    style={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                    }}
                                                                >
                                                                    Dedicated
                                                                    Desks:{' '}
                                                                    <strong>
                                                                        {
                                                                            commercialAvailability?.desks
                                                                        }
                                                                    </strong>
                                                                </span>
                                                                <br />
                                                            </>
                                                        )}
                                                        {commercialAvailability?.privateOffices >
                                                            0 && (
                                                            <>
                                                                {' '}
                                                                <span
                                                                    style={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                    }}
                                                                >
                                                                    {experience
                                                                        ?.details
                                                                        ?.subType ===
                                                                    `house`
                                                                        ? `Rooms: `
                                                                        : `Private
                                                                        Offices: `}
                                                                    <strong>
                                                                        {
                                                                            commercialAvailability?.privateOffices
                                                                        }
                                                                    </strong>
                                                                </span>
                                                                <br />
                                                            </>
                                                        )}
                                                        {commercialAvailability?.meetingRooms >
                                                            0 && (
                                                            <span
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                            >
                                                                Meeting Rooms:{' '}
                                                                <strong>
                                                                    {
                                                                        commercialAvailability?.meetingRooms
                                                                    }
                                                                </strong>
                                                            </span>
                                                        )}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        )}
                                    {disabled && (
                                        <Alert
                                            severity='error'
                                            sx={{ width: '100%' }}
                                        >
                                            You do not own this experience
                                        </Alert>
                                    )}
                                    <Collapse
                                        sx={{ width: '100%' }}
                                        in={showPrice}
                                    >
                                        <Grid
                                            onClick={handleClickTotal}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'flex-start',
                                            }}
                                            ref={totalRef}
                                        >
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '18px',
                                                        fontWeight: 'bold',
                                                        textDecoration:
                                                            'underline',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    Total{' '}
                                                </Typography>
                                                <HelpOutlineIcon
                                                    sx={{
                                                        color: (theme) =>
                                                            theme.palette.text
                                                                .primary,
                                                        fontSize: '16px',
                                                    }}
                                                />
                                            </Grid>

                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-end',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '18px',
                                                        fontWeight: 'bold',
                                                        textDecoration:
                                                            'underline',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    {formatCurrency(
                                                        charges?.bookingTotal
                                                            ?.total
                                                    )}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 'bold',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    Includes GST
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Popover
                                            open={open}
                                            onClose={handleClosePriceBreakdown}
                                            anchorEl={anchorElTotal}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Paper
                                                sx={{
                                                    p: 2,
                                                    minWidth: {
                                                        xl: '390px',
                                                    },
                                                }}
                                            >
                                                {' '}
                                                <CalculateChargesContent
                                                    fees={charges}
                                                    moduleId={experience?.id}
                                                    moduleType={
                                                        experience?.type
                                                    }
                                                />
                                            </Paper>
                                        </Popover>
                                    </Collapse>
                                    <Stack width='100%'>
                                        <Button
                                            variant='contained'
                                            type='submit'
                                            disabled={disabled}
                                        >
                                            Reserve
                                        </Button>
                                        {showPrice && (
                                            <FormHelperText>
                                                You won't be charged yet
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                </Stack>
                            </form>
                        </FormProvider>
                    </CardContent>
                </Card>
            </>
        )
    );
};

export default CalendarComponent;

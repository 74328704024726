import { Grid, useMediaQuery } from '@mui/material';
import { getAmenitiesIcon, getReadableAmenitiesName } from './AmenitiesMapping';
import React from 'react';

export default function AmenitiesComponent(assetInfo: any) {
    let value = assetInfo?.amenities;

    const motorbikeSpecificProperties = ['adjustableSeats', 'helmet', 'gloves'];
    const carSpecificProperties = [
        'ac',
        'keylessEntry',
        'sunroof',
        'laneAssist',
        'autoPark',
        'powerSeats',
        'remoteStart',
        'wirelessPhoneCharger',
        'rearEntertainmentSystem',
    ];

    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    return (
        <Grid
            direction={isMobile ? 'column' : 'row'}
            container
            spacing={1}
            sx={{
                width: isIpad ? '%' : '100%',
                // backgroundColor: 'yellow',
            }}
        >
            {(Object.keys(value) as string[])
                ?.filter((key) => value[key] === false || value[key] === true)
                .filter(
                    (key) =>
                        key !== 'dropoffLocationSameAsPickup' &&
                        key !== 'dropoffUsesNirovision' &&
                        key !== 'petsAllowed' &&
                        key !== 'smokingAllowed'
                )
                .filter((key) => {
                    if (value.subType === 'car') {
                        return !motorbikeSpecificProperties.includes(key);
                    } else if (value.subType === 'motorbike') {
                        return !carSpecificProperties.includes(key);
                    }
                    return true;
                })
                .map((key: any) => (
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            height: '50px',
                            alignItems: 'center',
                        }}
                        container
                        item
                        sm={4}
                        key={key}
                    >
                        <Grid
                            sx={{
                                width: '10%',
                                // color: value[key] === true ? null : 'red',
                            }}
                        >
                            {getAmenitiesIcon('icon', key)}
                        </Grid>
                        <Grid
                            sx={{
                                width: isMobile ? '90%' : '90%',
                                paddingLeft: isMobile
                                    ? '10px'
                                    : isIpad
                                    ? '20px'
                                    : '10px',
                                textDecoration:
                                    value[key] === true ? null : 'line-through',
                                color: '#787878',
                            }}
                        >
                            {' '}
                            {getReadableAmenitiesName('type', key)}
                        </Grid>
                    </Grid>
                ))}
        </Grid>
    );
}

import { Avatar, AvatarGroup, Box, Button, Tooltip } from '@mui/material'
import React, { useState }  from 'react'
import { Users } from '../../../types/messaging'
import { Settings } from '@mui/icons-material'
import UserRole from '../../../../shared/AuthRoles'
import MembersModal from './MembersModal'

interface ChannelMembersProps {
    channelId: string,
    users: Users
}

const ChannelMembers = ({ channelId, users }: ChannelMembersProps) => {
    const loggedInUser = UserRole();

    const [open, setOpen] = useState<boolean>(false);
    const [max, setMax] = useState(3);

    return (
        <>
            <Box 
                display='flex'
                alignItems='flex-start'
                flexDirection='column'
                gap={1}
            >
                <AvatarGroup 
                    max={max}
                    onMouseOver={() => setMax(100)}
                    onMouseOut={() => setMax(3)}
                >
                    {users && Object.entries(users).map(([id, user]) => (
                        <Tooltip title={user.name}>
                            <Avatar 
                                key={id} 
                                src={user.profilePicture?.src}
                            >
                                {user?.name?.toUpperCase().charAt(0)}
                            </Avatar>
                        </Tooltip>
                    ))}
                </AvatarGroup>
                {loggedInUser?.role.includes('owner_admin') && (
                    <>
                        <Button
                            onClick={() => setOpen(true)}
                            startIcon={<Settings />}
                        >
                            Members
                        </Button>
                        <MembersModal 
                            channelId={channelId}
                            users={users}
                            open={open} 
                            onClose={() => setOpen(false)}
                        />
                    </>
                )}
            </Box>
        </>
    )
}

export default ChannelMembers
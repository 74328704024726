import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface IFilterState {
    value: IFilterType;
}

interface IFilterType {
    dates?: any;
}

const initialState: IFilterState = {
    value: {
        dates: [],
    },
};
export const slice = createSlice({
    name: 'reservationDate',
    initialState: { ...initialState },
    reducers: {
        openFilterReservationDateAction: (state, action: PayloadAction<IFilterType>) => {
            state.value.dates =
                action.payload && action.payload.dates;
        },
    },
});
export const { openFilterReservationDateAction } = slice.actions;
export const selectFilteredReservationDateData = (state: RootState) =>
    state.reservationDate.value?.dates
export const FilterReservationDateReducer = slice.reducer;

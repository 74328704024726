import { Grid, InputLabel, Typography } from '@mui/material';
import React from 'react';
import FormCheckbox from '../../../shared/FormCheckbox';

const KitchenForm = ({ control }: { control: any }) => {
    return (
        <>
            <Grid item xs={12} sx={{ pt: 2 }}>
                <Typography color='textPrimary' fontWeight='bold'>
                    Amenities
                </Typography>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='fridge'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Fridge</InputLabel>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox name='oven' control={control} sx={{ p: 0 }} />
                    <InputLabel>Oven</InputLabel>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='microwave'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Microwave</InputLabel>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='dishwasher'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Dishwasher</InputLabel>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='coffeeMachine'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Coffee Machine</InputLabel>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='toaster'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Toaster</InputLabel>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='kettle'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Kettle</InputLabel>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='cookware'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Cookware</InputLabel>
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <FormCheckbox
                        name='cutlery'
                        control={control}
                        sx={{ p: 0 }}
                    />
                    <InputLabel>Cutlery</InputLabel>
                </Grid>
            </Grid>
        </>
    );
};

export default KitchenForm;

import { Route, Routes, useLocation } from 'react-router-dom';
import LoginPage from '../feature/pages/Other Pages/LoginPage';
import DefaultLayout from '../layout/DefaultLayout';
import DashboardPage from '../feature/pages/Dashboard Pages/DashboardPage';
import ReportsPage from '../feature/pages/Reports Page/ReportsPage';
import ProfilePage from '../feature/pages/Profile Pages/ProfilePage';
import IndividualExperiencePage from '../feature/pages/Experience Pages/Guest/IndividualExperiencePage';
import ReservationRequestPage from '../feature/pages/Reservation Pages/ReservationRequestPage';
import ReservationPage from '../feature/pages/Reservation Pages/ReservationPage';
import UsersPage from '../feature/pages/User Pages/UsersPage';
import OwnerExperiencesPage from '../feature/pages/Experience Pages/Owner/OwnerExperiencesPage';
import ErrorPage from '../feature/pages/ErrorPage';
import ExperiencesEditFormPage from '../feature/pages/Experience Pages/Owner/Forms/ExperiencesEditFormPage';
import ViewAllExperiencesPage from '../feature/pages/Experience Pages/Guest/ViewAllExperiencesPage';
import ViewOwnersExperiencesPage from '../feature/pages/Experience Pages/Owner/ViewOwnersExperiencesPage';
import ExperiencesNewFormPage from '../feature/pages/Experience Pages/Owner/Forms/ExperiencesNewFormPage';
import OwnersIndividualExperiencePage from '../feature/pages/Experience Pages/Owner/OwnersIndividualExperiencePage';
import ClientListPage from '../feature/pages/Client Pages/ClientListPage';
import UserFormPage from '../feature/pages/User Pages/UserFormPage';
import EditProfilePage from '../feature/pages/Profile Pages/EditProfilePage';
import ClientFormPage from '../feature/pages/Client Pages/ClientFormPage';
import React, { useEffect, useState } from 'react';
import ResetPasswordPage from '../feature/pages/Other Pages/ResetPasswordPage';
import ReservationConfirmationPage from '../feature/pages/Reservation Pages/ReservationConfirmationPage';
import ExistingReservationFormPage from '../feature/pages/Reservation Pages/ExistingReservationFormPage';
import PermissionDeniedPage from '../feature/pages/PermissionDenied';
import IndividualReportPage from '../feature/pages/Reports Page/IndividualReportPage';
import DashboardReportPage from '../feature/pages/Dashboard Pages/DashboardReportPage';
import HelpPage from '../feature/pages/Other Pages/HelpPage';
import GuestListPage from '../feature/pages/Guest Pages/GuestListPage';
import IndividualGuestPage from '../feature/pages/Guest Pages/IndividualGuestPage';
import ViewIndividualUser from '../feature/components/User Management/ViewIndividualUser';
import RequireAuth from '../feature/pages/Auth/RequireAuth';
import { useDispatch } from 'react-redux';
import FeesPage from '../feature/pages/Fees Page/FeesPage';
import AddFeePage from '../feature/pages/Fees Page/AddFeePage';
import EditFeePage from '../feature/pages/Fees Page/EditFeePage';
import VerifyEmailPage from '../feature/pages/Other Pages/VerifyEmailPage';
import BookingCapPage from '../feature/pages/Booking Caps Page/BookingCapPage';
import AddBookingCapPage from '../feature/pages/Booking Caps Page/AddBookingCapPage';
import EditBookingCapPage from '../feature/pages/Booking Caps Page/EditBookingCapPage';
import PoliciesPage from '../feature/pages/Policy Page/PoliciesPage';
import AddPolicyPage from '../feature/pages/Policy Page/AddPolicyPage';
import EditPolicyPage from '../feature/pages/Policy Page/EditPolicyPage';
import AdminCenterPage from '../feature/pages/Admin Center Page/AdminCenterPage';
import TeamsListPage from '../feature/pages/Teams Page/TeamsListPage';
import AddTeamsPage from '../feature/pages/Teams Page/AddTeamsPage';
import EditTeamsPage from '../feature/pages/Teams Page/EditTeamsPage';
import AiAssistantReservationsPage from '../feature/pages/Reservation Pages/AiAssistantReservationsPage';
import ManageAppFee from '../feature/pages/7DAY Admin Pages/App Fees/ManageAppFee';
import Messages from '../feature/pages/Messaging Pages/Messages';
import MessagingSettingsPage from '../feature/pages/Messaging Pages/MessagingSettingsPage';
import Billing from '../feature/pages/Billing Pages/Billing';
import IndividualBillPage from '../feature/pages/Billing Pages/IndividualBillPage';
import { BillsAndInvoicesPage } from '../feature/pages/7DAY Admin Pages/BillsAndInvoices/BillsAndInvoicesPage';
import GuestInvoicesReportPage from '../feature/pages/Reports Page/GuestInvoicesReportPage';
import { EarningsPage } from '../feature/pages/HostPaymentPages/EarningsPage';
import { HostPaymentPage } from '../feature/pages/HostPaymentPages/HostPaymentPage';

export const usePrevious = (value: any) => {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export const useLocationChange = (action: any) => {
    const location = useLocation();

    const prevLocation = usePrevious(location);
    React.useEffect(() => {
        action(location, prevLocation);
    }, [location]);
};

export default function CustomRouter() {
    const location = useLocation();
    const dispatch = useDispatch();
    // const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
    const [previousLocation, setPreviousLocation] = useState<string>('');
    const prevLocation = useLocation();

    useEffect(() => {
        setPreviousLocation(prevLocation?.pathname);
    }, []);

    return (
        <>
            {location.pathname === '/login' ||
            location.pathname === '/' ||
            location.pathname.includes('resetPassword') ||
            location.pathname.includes('verifyEmail') ? (
                <Routes>
                    <Route
                        path='/login'
                        element={
                            <LoginPage previousLocation={previousLocation} />
                        }
                    />
                    <Route
                        path='/'
                        element={
                            <LoginPage previousLocation={previousLocation} />
                        }
                    />
                    <Route
                        path='/resetPassword/:id'
                        element={<ResetPasswordPage />}
                    />
                    <Route
                        path='/verifyEmail/:userId/:token'
                        element={<VerifyEmailPage />}
                    />
                </Routes>
            ) : (
                <DefaultLayout>
                    <Routes>
                        {/* <Route path='/' element={<LoginPage />} /> */}
                        <Route element={<RequireAuth />}>
                            <Route path='/messages' element={<Messages />} />
                            <Route
                                path='/messaging'
                                element={<MessagingSettingsPage />}
                            />
                            <Route path='/billing' element={<Billing />} />
                            <Route
                                path='/bills/:id'
                                element={<IndividualBillPage />}
                            />
                            <Route
                                path='/permissionDenied'
                                element={<PermissionDeniedPage />}
                            />
                            <Route
                                path='/experiences'
                                element={<OwnerExperiencesPage />}
                            />
                            <Route
                                path='/viewExperiences/all'
                                element={<ViewAllExperiencesPage />}
                            />
                            {/* <Route
                                path='/viewExperiences/business'
                                element={<ViewAllExperiencesPage />}
                            /> */}
                            {/* <Route
                                path='/viewExperiences/personal'
                                element={<ViewAllExperiencesPage />}
                            /> */}
                            <Route
                                path='/viewExperiences/all/:id'
                                element={<ViewOwnersExperiencesPage />}
                            />
                            <Route
                                path='/viewExperiences/business/:id'
                                element={<ViewOwnersExperiencesPage />}
                            />
                            <Route
                                path='/viewExperiences/personal/:id'
                                element={<ViewOwnersExperiencesPage />}
                            />
                            <Route
                                path='/newExperience'
                                element={<ExperiencesNewFormPage />}
                            />
                            <Route
                                path='/editExperience/:id'
                                element={<ExperiencesEditFormPage />}
                            />
                            <Route
                                path='/viewExperiences/:id/:id'
                                element={<IndividualExperiencePage />}
                            />
                            <Route
                                path='/viewExperiences/:id/:id/:id'
                                element={<OwnersIndividualExperiencePage />}
                            />
                            <Route
                                path='/experiences/reservationRequest/:id/:id/:id'
                                element={<ReservationRequestPage />}
                            />
                            <Route
                                path='/reservationRequest/submitted/:id'
                                element={<ReservationConfirmationPage />}
                            />
                            <Route
                                path='/reservationRequest/submitted/ai-assistant/:id'
                                element={<ReservationConfirmationPage />}
                            />
                            <Route
                                path='/reservations'
                                element={<ReservationPage />}
                            />
                            <Route
                                path='/reservations/ai-assistant'
                                element={<AiAssistantReservationsPage />}
                            />
                            <Route
                                path='/reservations/ai-assistant/:id/'
                                element={<ExistingReservationFormPage />}
                            />
                            <Route
                                path='/reservations/:id/:id'
                                element={<ExistingReservationFormPage />}
                            />
                            <Route
                                path='/editReservation/:id/:id'
                                element={<ExistingReservationFormPage />}
                            />
                            <Route path='/reports' element={<ReportsPage />} />
                            <Route
                                path='/dashboard'
                                element={<DashboardPage />}
                            />

                            <Route
                                path='/dashboard/new-bookings'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/guests'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/guest-pending-bookings'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/scheduled/all'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/scheduled/current-week'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/scheduled/next-week'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/scheduled/today'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/scheduled/tomorrow'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/scheduled/current-month'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/completed/current-month'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/completed/current-year'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/user/user-pending-bookings'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/user/user-approved-bookings'
                                element={<DashboardReportPage />}
                            />
                            <Route
                                path='/dashboard/user/user-completed-bookings'
                                element={<DashboardReportPage />}
                            />
                            <Route />
                            <Route path='/users' element={<UsersPage />} />
                            <Route path='/newUser' element={<UserFormPage />} />
                            <Route
                                path='/users/:id/:id'
                                element={<ViewIndividualUser />}
                            />
                            <Route
                                path='/user/:id/:id'
                                element={<ViewIndividualUser />}
                            />
                            <Route
                                path='/editUser/:id/:id'
                                element={<UserFormPage />}
                            />
                            <Route
                                path='/adminCenter/host'
                                element={<AdminCenterPage />}
                            />
                            <Route
                                path='/adminCenter/guest'
                                element={<AdminCenterPage />}
                            />
                            <Route
                                path='/bookingCaps'
                                element={<BookingCapPage />}
                            />
                            <Route
                                path='/newBookingCap'
                                element={<AddBookingCapPage />}
                            />
                            <Route
                                path='/bookingCap/:id/edit'
                                element={<EditBookingCapPage />}
                            />
                            <Route path='/fees' element={<FeesPage />} />
                            <Route
                                path='/fees/:id/edit'
                                element={<EditFeePage />}
                            />
                            <Route path='/newFee' element={<AddFeePage />} />
                            <Route
                                path='/7dayfees'
                                element={<ManageAppFee />}
                            />
                            <Route
                                path='/bills-invoices'
                                element={<BillsAndInvoicesPage />}
                            />
                            <Route
                                path='/earnings'
                                element={<EarningsPage />}
                            />
                            <Route
                                path='/policies'
                                element={<PoliciesPage />}
                            />
                            <Route
                                path='/newPolicy'
                                element={<AddPolicyPage />}
                            />
                            <Route
                                path='/policies/:id'
                                element={<EditPolicyPage />}
                            />
                            <Route
                                path='/clients'
                                element={<ClientListPage />}
                            />
                            <Route
                                path='/newclient'
                                element={<ClientFormPage />}
                            />
                            <Route
                                path='/clients/:id/:id'
                                element={<ClientFormPage />}
                            />
                            <Route path='/guests' element={<GuestListPage />} />
                            <Route path='/teams' element={<TeamsListPage />} />
                            <Route path='/newTeam' element={<AddTeamsPage />} />
                            <Route
                                path='/team/:id/edit'
                                element={<EditTeamsPage />}
                            />
                            <Route
                                path='/guests/:id/users'
                                element={<IndividualGuestPage />}
                            />
                            <Route
                                path='/editClient/:id/:id'
                                element={<ClientFormPage />}
                            />
                            <Route path='/profile' element={<ProfilePage />} />
                            <Route
                                path='/reports/topGuests'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/topBookers'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/topExperiences'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/guestDetails'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/guests/:id/users'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/bookerDetails'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/cancellationReport'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/declinedReport'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/reservationsReport'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/monthlyReport'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/quarterlyReport'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/ytdReport'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/fyReport'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/reports/guestInvoices'
                                element={<GuestInvoicesReportPage />}
                            />
                            <Route
                                path='/reports/hostPayments'
                                element={<IndividualReportPage />}
                            />
                            <Route
                                path='/host-payment/:ownerId/:year/:month'
                                element={<HostPaymentPage />}
                            />
                            <Route
                                path='/editProfile'
                                element={<EditProfilePage />}
                            />
                            <Route
                                path='/documentation'
                                element={<HelpPage />}
                            />
                            <Route path='/support' element={<HelpPage />} />
                            {/* <Route path='/feedback' element={<HelpPage />} /> */}
                            <Route path='/privacy' element={<HelpPage />} />
                            <Route path='/terms' element={<HelpPage />} />
                            <Route path='*' element={<ErrorPage />} />
                        </Route>
                    </Routes>
                </DefaultLayout>
            )}
        </>
    );
}

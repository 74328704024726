import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AIReservationsAssistant from '../../components/Reservations/AIReservations/AIReservationsAssistant';

export default function AiAssistantReservationsPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container
            fixed
            maxWidth='xl'
            sx={{
                minWidth: '95%',
                paddingTop: isMobile ? '100px' : '125px',
                height: isMobile || isIpad ? 'auto' : '100%',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    height: isMobile ? '100%' : '80%',
                    justifyContent: 'left',
                    alignItems: 'center',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: isMobile ? '2%' : '5%',
                        justifyContent: 'left',
                        display: 'flex',
                    }}
                >
                    <CalendarMonthIcon
                        sx={{
                            color: '#5cb5b3',
                            paddingRight: '5px',
                            fontSize: '32px',
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h5'
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        AI Reservation Assistant
                    </Typography>
                </Grid>

                <Grid
                    sx={{
                        width: '100%',
                        minHeight: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <AIReservationsAssistant />
                </Grid>
            </Grid>
        </Container>
    );
}

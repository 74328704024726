import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    Grid,
    List,
    ListItem,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    useAddIndividualBookingCapMutation,
    useDeleteBookingCapMutation,
    useEditBookingCapMutation,
    useGetAllGuestsQuery,
    useGetBookingCapListQuery,
    useGetIndividualGuestsQuery,
    useGetIndividualOwnerAssetsQuery,
} from '../../../app/Slices/SevenDayApiSlice';
import { CustomButton } from '../../../layout/styledcomponents/CustomButton';
import { CustomIconButton } from '../../../layout/styledcomponents/CustomIconButton';
import { CustomTextField } from '../../../layout/styledcomponents/CustomTextField';
import DropdownFilter from '../../../layout/styledcomponents/filters/Dropdown';
import MultiSelectDropdownFilter from '../../../layout/styledcomponents/filters/MultiSelectDropdown';
import UserRole from '../../../shared/AuthRoles';
import { handleHttpError } from '../../../shared/utils';
import ConfirmationModal from '../../shared/ConfirmationModal';
import toast from '../../shared/Toast';
import { BookingCaps } from './BookingCapList';

interface Props {
    capProp?: BookingCaps;
}

const BookingCapForm = ({ capProp }: Props) => {
    const loggedInUser = UserRole();
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 770px)');
    const { data: allAssets } = useGetIndividualOwnerAssetsQuery({
        url: `owners/${loggedInUser?.company}/modules`,
        sort: 'name',
    });
    const { data: allGuests } = useGetAllGuestsQuery(`/guests`);
    // const [guestList, setGuestList] = useState<any>([]);
    const [assetList, setAssetList] = useState<any>([]);
    const [isDuplicateName, setDuplicateName] = useState(false);
    const [addNewBookingCap] = useAddIndividualBookingCapMutation();
    const [bookingCapName, setBookingCapName] = useState('');
    const [yearlyCap, setYearlyCap] = useState<any>(null);
    const [experienceTypes, setExperienceTypes] = useState<string[]>([]);
    const [experiences, setExperience] = useState<any>([]);
    const [visitType, setVisitType] = useState(null);
    const [guests, setGuests] = useState<string[]>([]);
    const [excludedUsers, setExcludedUsers] = useState<any>([]);
    const [excludedUserList, setExcludedUserList] = useState<any>([]);
    const [appliesTo, setAppliesTo] = useState('');
    const [excludeCompany, setExcludeCompany] = useState<any>();
    const { data: individualGuests } = useGetIndividualGuestsQuery(
        `/guests/${excludeCompany?.id}/users`
    );

    const navigate = useNavigate();
    const [editBookingCap] = useEditBookingCapMutation();
    const [deleteBookingCap] = useDeleteBookingCapMutation();
    const { data: getBookingCaps } = useGetBookingCapListQuery('/booking-caps');
    const [showModal, setShowModal] = useState(false);

    const bookingCapNames = getBookingCaps?.map(
        (cap: BookingCaps) => cap?.name
    );
    const handlebookingCapName = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const findDuplicate = bookingCapNames?.find(
            (bookingCapName: string) => bookingCapName === event?.target?.value
        );
        if (
            findDuplicate !== undefined &&
            findDuplicate !== getBookingCaps?.caps?.name
        ) {
            setDuplicateName(true);
            setBookingCapName('');
        } else {
            setDuplicateName(false);
            setBookingCapName(event.target?.value?.toLowerCase());
        }
    };

    const handleBookingCap = (event: any) => {
        const newAmount = Number(event.target.value);
        if (newAmount === 0) {
            setYearlyCap(undefined);
        } else {
            setYearlyCap(newAmount);
        }
    };
    const errors: any = [];

    if (bookingCapName?.length === 0) {
        errors.push('Name cannot be empty');
    }
    if (yearlyCap === 0 || yearlyCap === null || yearlyCap === undefined) {
        errors.push('Max No.Bookings cannot be empty');
    }
    if (experiences?.length === 0) {
        errors.push('Experiences cannot be empty');
    }
    if (experienceTypes?.length === 0) {
        errors.push('Experience Types cannot be empty');
    }
    // if (visitType === '' || visitType === undefined) {
    //     errors.push('Visit Type cannot be empty');
    // }
    if (guests?.length === 0) {
        errors.push('Guests cannot be empty');
    }
    if (appliesTo === '' || appliesTo === null || appliesTo === undefined) {
        errors.push('Applies to cannot be empty');
    }

    useEffect(() => {
        if (capProp && capProp?.name) {
            setBookingCapName(capProp?.name);
            setYearlyCap(capProp?.yearlyCap);
            setExperienceTypes(capProp?.moduleTypes);
            setExperience(capProp?.modules?.map((mod: any) => mod?.id));
            if (capProp?.moduleTypes && allAssets !== undefined) {
                let list = [];
                if (capProp?.moduleTypes?.length === 1) {
                    let oneVal = allAssets
                        ?.flat()
                        ?.filter((experience: any) => {
                            return experience?.type.includes(
                                capProp?.moduleTypes
                            );
                        });
                    list = oneVal;
                } else if (capProp?.moduleTypes?.length === 2) {
                    let twoVal = allAssets
                        ?.flat()
                        ?.filter((experience: any) => {
                            return (
                                experience?.type.includes(
                                    capProp?.moduleTypes[0]
                                ) ||
                                experience?.type.includes(
                                    capProp?.moduleTypes[1]
                                )
                            );
                        });
                    list = twoVal;
                } else {
                    let threeVal = allAssets
                        ?.flat()
                        ?.filter((experience: any) => {
                            return (
                                experience?.type.includes(
                                    capProp?.moduleTypes[0]
                                ) ||
                                experience?.type.includes(
                                    capProp?.moduleTypes[1]
                                ) ||
                                experience?.type.includes(
                                    capProp?.moduleTypes[2]
                                )
                            );
                        });
                    list = threeVal;
                }

                handleAssetList(list);
            }
            setVisitType(null);
            setAppliesTo(capProp?.appliesTo);

            // if (individualGuests !== undefined) {
            //     let indGuests = individualGuests?.map((guest: any) => ({
            //         value: guest.id,
            //         label: guest.name,
            //     }));

            // }
            setGuests(capProp?.guests?.map((guest: any) => guest?.id));

            let excludedUsersCapProp = capProp?.excludedUsers?.map(
                (guest: any) => {
                    return {
                        value: guest?.id,
                        label: `${guest?.firstName} ${guest?.lastName} - ${
                            guest?.email
                                ? `${guest?.email} (${guest?.companyName})`
                                : ''
                        }`,
                    };
                }
            );
            setExcludedUserList(excludedUsersCapProp);
            setExcludedUsers(
                capProp?.excludedUsers?.map((guest: any) => guest?.id)
            );
        }
    }, [capProp, allAssets]);

    // const handleVisitType = (value: any) => {
    //     setVisitType(value);
    // };

    const handleExperienceType = (value: any) => {
        setExperienceTypes(value);
        let list = [];
        if (experiences?.length !== 0) {
            removeExperienceType(value);
            if (value?.length === 1) {
                let oneVal = allAssets?.flat()?.filter((experience: any) => {
                    return experience?.type.includes(value);
                });
                list = oneVal;
            } else if (value?.length === 2) {
                let twoVal = allAssets?.flat()?.filter((experience: any) => {
                    return (
                        experience?.type.includes(value[0]) ||
                        experience?.type.includes(value[1])
                    );
                });
                list = twoVal;
            } else {
                let threeVal = allAssets?.flat()?.filter((experience: any) => {
                    return (
                        experience?.type.includes(value[0]) ||
                        experience?.type.includes(value[1]) ||
                        experience?.type.includes(value[2])
                    );
                });
                list = threeVal;
            }
        } else {
            if (value?.length === 1) {
                let oneVal = allAssets?.flat()?.filter((experience: any) => {
                    return experience?.type.includes(value);
                });
                list = oneVal;
            } else if (value?.length === 2) {
                let twoVal = allAssets?.flat()?.filter((experience: any) => {
                    return (
                        experience?.type.includes(value[0]) ||
                        experience?.type.includes(value[1])
                    );
                });
                list = twoVal;
            } else {
                let threeVal = allAssets?.flat()?.filter((experience: any) => {
                    return (
                        experience?.type.includes(value[0]) ||
                        experience?.type.includes(value[1]) ||
                        experience?.type.includes(value[2])
                    );
                });
                list = threeVal;
            }
        }

        handleAssetList(list);
    };

    const handleAssetList = (list: any) => {
        let assetList = list?.map((asset: any) => ({
            value: asset.id,
            label: asset.name,
        }));
        setAssetList(assetList);
    };

    const removeExperienceType = (value: any) => {
        let experiencesToUncheck: any = [];
        if (value?.length === 1) {
            let oneVal = allAssets?.filter((experience: any) => {
                return !experience?.type.includes(value);
            });
            experiencesToUncheck = oneVal;
        } else if (value?.length === 2) {
            let twoVal = allAssets?.filter((experience: any) => {
                return (
                    !experience?.type.includes(value[0]) &&
                    !experience?.type.includes(value[1])
                );
            });
            experiencesToUncheck = twoVal;
        } else {
            return;
        }

        let experienceIds = experiencesToUncheck?.map((i: any) => i.id);

        //remove elements from experiences array that do not match the unselected experience type
        let elements = experiences;
        elements = elements.filter(function (el: any) {
            return experienceIds.indexOf(el) < 0;
        });

        setExperience(elements);
    };

    const handleRemoveExcludedUser = (value: any) => {
        setExcludedUsers(excludedUsers?.filter((user: any) => user !== value));

        setExcludedUserList(
            excludedUserList?.filter((user: any) => user?.value !== value)
        );
    };

    const dispatch = useDispatch();

    const saveBookingCap = () => {
        if (capProp) {
            editBookingCap([
                capProp.id,
                {
                    name: bookingCapName,
                    yearlyCap: yearlyCap,
                    moduleTypes: experienceTypes,
                    modules: experiences?.map((mod: any) => ({
                        id: mod,
                    })),
                    visitType: visitType,
                    guests: guests?.map((guest: any) => ({
                        id: guest,
                    })),
                    excludedUsers: excludedUsers?.map((guest: any) => ({
                        id: guest,
                    })),
                    appliesTo: appliesTo,
                },
            ]).then((data: any) => {
                if (data?.error) {
                    const errorMessage = handleHttpError(data?.error);
                    toast.error(errorMessage);
                } else {
                    toast.success(
                        `Booking Cap: ${bookingCapName} has been updated`
                    );
                    navigate('/bookingCaps');
                }
            });
        } else {
            if (
                loggedInUser?.role === 'owner_company' ||
                loggedInUser?.role === 'owner_admin'
            ) {
                addNewBookingCap({
                    name: bookingCapName,
                    yearlyCap: yearlyCap,
                    moduleTypes: experienceTypes,
                    modules: experiences?.map((mod: any) => ({
                        id: mod,
                    })),
                    visitType: visitType,
                    guests: guests?.map((guest: any) => ({
                        id: guest,
                    })),
                    excludedUsers: excludedUsers?.map((guest: any) => ({
                        id: guest,
                    })),
                    appliesTo: appliesTo,
                }).then((data: any) => {
                    if (data?.error) {
                        const message = handleHttpError(data?.error);
                        toast.error(message);
                    } else {
                        toast.success(
                            `New booking cap: ${bookingCapName} has been created`
                        );
                        navigate('/bookingCaps');
                    }
                });
            } else {
                toast.error(
                    `You don't have the necessary rights to add booking caps`
                );
            }
        }
    };

    const handleDeleteCap = () => {
        deleteBookingCap([capProp?.id]).then((data: any) => {
            if (data.error) {
                toast.error(`Booking Cap: ${bookingCapName} cannot be deleted`);
            } else {
                toast.success(
                    `Booking Cap: ${bookingCapName} has been deleted`
                );
                navigate('/bookingCaps');
            }
        });
    };

    return (
        <Grid
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
            }}
        >
            <Grid
                sx={{
                    width: '100%',
                    justifyContent: 'left',
                    display: 'flex',
                }}
            >
                <Typography
                    component='h1'
                    variant='h5'
                    color='#787878'
                    sx={{
                        fontFamily: 'Open Sans',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        textDecoration: 'underline',
                        textDecorationColor: '#5cb5b3',
                        textUnderlineOffset: '8px',
                    }}
                >
                    {capProp ? `Edit Booking Cap` : ` New Booking Cap`}
                </Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid container item columnSpacing='50px' rowSpacing='10px'>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid item xs={12} lg={5}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Name:{' '}
                                {bookingCapName === '' ? (
                                    <span
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        *
                                    </span>
                                ) : null}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <CustomTextField
                                color={'green'}
                                error={isDuplicateName}
                                fullWidth={true}
                                label={
                                    isDuplicateName === true
                                        ? 'Error: Booking cap name already exists'
                                        : ''
                                }
                                value={!isDuplicateName ? bookingCapName : null}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handlebookingCapName}
                                inputProps={{
                                    style: {
                                        height: '10px',
                                        width: '350px',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid
                            item
                            xs={12}
                            lg={5}
                            sx={{
                                paddingBottom: isMobile || isIpad ? '5px' : '0',
                                display: 'flex',
                                gap: '2px',
                            }}
                        >
                            {' '}
                            <Grid>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Max No. Bookings per year:
                                </Typography>{' '}
                            </Grid>
                            <Grid>
                                <Tooltip
                                    title={
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            Maximum bookings per user or per
                                            company can be applied to
                                            experiences. These caps are for the
                                            entire year.
                                            <List>
                                                For Example:
                                                <ListItem>
                                                    USER: Max 2 bookings per
                                                    user per year for each
                                                    experience you select. The
                                                    user will be able to book
                                                    max two bookings per
                                                    experience over the 12
                                                    months. This number will be
                                                    reset each calendar year.
                                                </ListItem>
                                                <ListItem>
                                                    COMPANY: Max 100 bookings
                                                    per company per year for
                                                    each experience you select.
                                                    The company will be able to
                                                    book max 100 bookings per
                                                    experience over the 12
                                                    months. This number will be
                                                    reset each calendar year.
                                                    This means users can make as
                                                    many bookings as they want
                                                    until they reach the
                                                    combined company total of
                                                    100.
                                                </ListItem>
                                                You can select whether this cap
                                                applies to each user or company
                                                wide below.
                                            </List>
                                        </Typography>
                                    }
                                    placement='right'
                                >
                                    <HelpOutlineIcon
                                        sx={{
                                            color: '#787878',
                                            fontSize: '16px',
                                        }}
                                    />
                                </Tooltip>
                            </Grid>
                            {yearlyCap === null ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            {' '}
                            <CustomTextField
                                color={'green'}
                                error={
                                    yearlyCap === 0 || yearlyCap === undefined
                                }
                                fullWidth={true}
                                label={
                                    yearlyCap === 0 || yearlyCap === undefined
                                        ? 'Error: Cap cannot be 0'
                                        : ''
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type='number'
                                value={yearlyCap}
                                onChange={handleBookingCap}
                                inputProps={{
                                    style: {
                                        height: '10px',
                                        width: '350px',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item columnSpacing='50px' rowSpacing='10px'>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid item xs={12} lg={5}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    textAlign: 'left',
                                    fontWeight: 900,
                                    paddingBottom: '30px',
                                }}
                            >
                                Apply Cap To
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}></Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid item xs={12} lg={5}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            ></Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}></Grid>
                    </Grid>
                </Grid>
                <Grid container item columnSpacing='50px' rowSpacing='10px'>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid item xs={12} lg={5}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Experience Type:
                                {experienceTypes?.length === 0 ? (
                                    <span
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        *
                                    </span>
                                ) : null}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <MultiSelectDropdownFilter
                                id={'typeFilter'}
                                options={[
                                    {
                                        value: 'property',
                                        label: 'Property',
                                    },
                                    { value: 'boat', label: 'Boat' },
                                    {
                                        value: 'vehicle',
                                        label: 'Vehicle',
                                    },
                                    {
                                        value: 'activity',
                                        label: 'Activity',
                                    },
                                    {
                                        value: 'commercial',
                                        label: 'Commercial',
                                    },
                                ]}
                                handleChange={handleExperienceType}
                                selectedValue={experienceTypes}
                                backgroundColor={'white'}
                                border={'#C3C3C3'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        <Grid item xs={12} lg={5}>
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                sx={{
                                    paddingBottom:
                                        isMobile || isIpad ? '5px' : '0',
                                    display: 'flex',
                                    gap: '2px',
                                }}
                            >
                                {' '}
                                <Grid>
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Experience:
                                    </Typography>{' '}
                                </Grid>
                                <Grid>
                                    <Tooltip
                                        title={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                Select which experiences this
                                                cap applies to. Note that the
                                                cap applies to each individual
                                                experience. It is not an overall
                                                cap shared amoungst the selected
                                                experience.
                                            </Typography>
                                        }
                                        placement='right'
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                </Grid>
                                {experiences?.length === 0 ? (
                                    <span
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        *
                                    </span>
                                ) : null}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <MultiSelectDropdownFilter
                                disabled={experienceTypes.length === 0}
                                id={'experienceFilter'}
                                options={assetList}
                                handleChange={setExperience}
                                selectedValue={experiences}
                                backgroundColor={'white'}
                                border={'#C3C3C3'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item columnSpacing='50px' rowSpacing='10px'>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid
                            item
                            xs={12}
                            lg={5}
                            sx={{
                                paddingBottom: isMobile || isIpad ? '5px' : '0',
                                display: 'flex',
                                gap: '2px',
                            }}
                        >
                            <Grid>
                                <Typography
                                    color='#787878'
                                    sx={{
                                        fontFamily: 'Open Sans',
                                    }}
                                >
                                    Applies to:
                                </Typography>{' '}
                            </Grid>
                            <Grid>
                                <Tooltip
                                    title={
                                        <Typography
                                            sx={{
                                                fontFamily: 'Open Sans',
                                            }}
                                        >
                                            Select if the cap applies to the
                                            entire company and is shared
                                            amoungst all the users or if the cap
                                            applies to per person.
                                            <List>
                                                For Example:
                                                <ListItem>
                                                    USER: Max 2 bookings per
                                                    user per year for each
                                                    experience you select. The
                                                    user will be able to book
                                                    max two bookings per
                                                    experience over the 12
                                                    months. This number will be
                                                    reset each calendar year.
                                                </ListItem>
                                                <ListItem>
                                                    COMPANY: Max 100 bookings
                                                    per company per year for
                                                    each experience you select.
                                                    The compan will be able to
                                                    book max 100 bookings per
                                                    experience over the 12
                                                    months. This number will be
                                                    reset each calendar year.
                                                    This means users can make as
                                                    many bookings as they want
                                                    until they reach the
                                                    combined company total of
                                                    100.
                                                </ListItem>
                                            </List>
                                        </Typography>
                                    }
                                    placement='right'
                                >
                                    <HelpOutlineIcon
                                        sx={{
                                            color: '#787878',
                                            fontSize: '16px',
                                        }}
                                    />
                                </Tooltip>
                            </Grid>
                            {appliesTo?.length === 0 ? (
                                <span
                                    style={{
                                        color: 'red',
                                    }}
                                >
                                    *
                                </span>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <DropdownFilter
                                id={'visitType'}
                                options={[
                                    { value: 'company', label: 'Company' },
                                    { value: 'user', label: 'User' },
                                ]}
                                handleChange={setAppliesTo}
                                selectedValue={appliesTo}
                                backgroundColor={'white'}
                                border={'#C3C3C3'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid item xs={12} lg={5}>
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                sx={{
                                    paddingBottom:
                                        isMobile || isIpad ? '5px' : '0',
                                    display: 'flex',
                                    gap: '2px',
                                }}
                            >
                                {' '}
                                <Grid>
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                        }}
                                    >
                                        Guests:
                                    </Typography>{' '}
                                </Grid>
                                <Grid>
                                    <Tooltip
                                        title={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                Select which guests to apply the
                                                cap to. Note that if you do not
                                                select a guest, they will have
                                                unlimited bookings.
                                            </Typography>
                                        }
                                        placement='right'
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                </Grid>
                                {guests?.length === 0 ? (
                                    <span
                                        style={{
                                            color: 'red',
                                        }}
                                    >
                                        *
                                    </span>
                                ) : null}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <MultiSelectDropdownFilter
                                id={'allGuestsFilter'}
                                options={
                                    allGuests
                                        ? allGuests?.map((guest: any) => ({
                                              value: guest.id,
                                              label: guest.name,
                                          }))
                                        : []
                                }
                                handleChange={setGuests}
                                selectedValue={guests}
                                backgroundColor={'white'}
                                border={'#C3C3C3'}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item columnSpacing='50px' rowSpacing='10px'>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid item xs={12} lg={5}>
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                sx={{
                                    paddingBottom:
                                        isMobile || isIpad ? '5px' : '0',
                                    display: 'flex',
                                    gap: '2px',
                                }}
                            >
                                {' '}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={7}></Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid item xs={12} lg={5}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            ></Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}></Grid>
                    </Grid>
                </Grid>

                <Grid container item columnSpacing='50px' rowSpacing='10px'>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid item xs={12} lg={5}>
                            <Grid
                                item
                                xs={12}
                                lg={10}
                                sx={{
                                    paddingBottom:
                                        isMobile || isIpad ? '5px' : '0',
                                    display: 'flex',
                                    gap: '2px',
                                }}
                            >
                                {' '}
                                <Grid>
                                    <Typography
                                        color='#787878'
                                        sx={{
                                            fontFamily: 'Open Sans',
                                            textAlign: 'left',
                                            fontWeight: 900,
                                            paddingBottom: '30px',
                                        }}
                                    >
                                        Exclude Users:
                                    </Typography>{' '}
                                </Grid>
                                <Grid>
                                    <Tooltip
                                        title={
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Open Sans',
                                                }}
                                            >
                                                Exclude users such as VIPs from
                                                organsations. They will not have
                                                this cap applied to them when
                                                making a booking.
                                            </Typography>
                                        }
                                        placement='right'
                                    >
                                        <HelpOutlineIcon
                                            sx={{
                                                color: '#787878',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={7}></Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid item xs={12} lg={5}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            ></Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}></Grid>
                    </Grid>
                </Grid>
                <Grid container item columnSpacing='50px' rowSpacing='10px'>
                    <Grid item container xs={12} md={6}>
                        {' '}
                        <Grid item xs={12} lg={5}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Company:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <DropdownFilter
                                id={'excludeGuestCompanyFilter'}
                                options={
                                    allGuests
                                        ? allGuests?.map((guest: any) => ({
                                              value: guest,
                                              label: guest.name,
                                          }))
                                        : []
                                }
                                handleChange={setExcludeCompany}
                                selectedValue={excludeCompany?.name}
                                backgroundColor={'white'}
                                border={'#C3C3C3'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        <Grid item xs={12} lg={5}>
                            <Typography
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                }}
                            >
                                Users:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <MultiSelectDropdownFilter
                                id={'excludedUserList'}
                                disabled={excludeCompany === undefined}
                                options={individualGuests}
                                handleChange={setExcludedUsers}
                                selectedValue={excludedUsers}
                                backgroundColor={'white'}
                                border={'#C3C3C3'}
                                handleObject={setExcludedUserList}
                                selectedObject={excludedUserList}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {excludedUserList.length !== 0 ? (
                    <Grid container item columnSpacing='50px' rowSpacing='10px'>
                        <Grid item container xs={12} md={8}>
                            {' '}
                            <Grid item xs={12} lg={3.7}></Grid>
                            <Grid item xs={12} lg={7}>
                                {excludedUserList.map((user: any) => (
                                    <Grid
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Typography
                                            color='#787878'
                                            sx={{
                                                fontFamily: 'Open Sans',
                                                fontSize: isMobile
                                                    ? '12px'
                                                    : '16px',
                                            }}
                                        >
                                            <li>{user?.label}</li>
                                        </Typography>
                                        <Grid>
                                            <CustomIconButton
                                                onClick={() =>
                                                    handleRemoveExcludedUser(
                                                        user?.value
                                                    )
                                                }
                                                sx={{
                                                    width: '100%',
                                                    height: '25px',
                                                    display: 'flex',
                                                    flexDirection: 'row',

                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        color: 'red',
                                                        backgroundColor:
                                                            'transparent',
                                                        background: 'none',
                                                    },
                                                }}
                                            >
                                                <Grid
                                                    sx={{
                                                        width: '10%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                    }}
                                                >
                                                    <ClearIcon
                                                        sx={{
                                                            fontSize: '12px',
                                                        }}
                                                    />
                                                </Grid>
                                            </CustomIconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item container xs={2} md={2}>
                            {' '}
                            <Grid item xs={2} lg={2}></Grid>
                            <Grid item xs={2} lg={2}></Grid>
                        </Grid>
                    </Grid>
                ) : null}

                <Grid
                    container
                    item
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Grid
                        sx={{
                            display: 'flex',
                            gap: '10px',
                        }}
                    >
                        {capProp && (
                            <CustomButton
                                id='deleteBookingCap'
                                variant='contained'
                                color='warning'
                                onClick={() => {
                                    setShowModal(!showModal);
                                }}
                            >
                                Delete
                            </CustomButton>
                        )}

                        <CustomButton
                            variant='contained'
                            color='green'
                            onClick={saveBookingCap}
                            disabled={errors?.length > 0}
                        >
                            {capProp ? `Update` : ` Save`}
                        </CustomButton>
                    </Grid>

                    <ConfirmationModal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        onChange={handleDeleteCap}
                        message={'delete this booking cap'}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BookingCapForm;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface IState {
    value: IType;
}

interface IType {
    view?: any;
}

const initialState: IState = {
    value: {
        view: 'all',
    },
};
export const slice = createSlice({
    name: 'visitTypeSlice',
    initialState: { ...initialState },
    reducers: {
        openVisitTypeSliceAction: (state, action: PayloadAction<IType>) => {
            state.value.view =
                action.payload && action.payload.view;
        },
    },
});
export const { openVisitTypeSliceAction } = slice.actions;
export const selectVisitTypeSliceData = (state: RootState) =>
    state.visitTypeSlice.value
export const FilterVisitTypeSliceReducer = slice.reducer;
